import React, {Component} from 'react';
import PropTypes from "prop-types"

import {Form, FormGroup, FormControl, ControlLabel, Button} from 'react-bootstrap'

class SearchWidget extends Component {
static propTypes = {
  doSearch: PropTypes.func.isRequired
}

state = {q: '', searchLocation: 'customer', searchBlurb: 'Search Customer table Customer Name, Company Name, First and Last Names, Notes fields'}


  submitSearch = (e) => {
    e.preventDefault()
    this.props.doSearch(this.state.q, this.state.searchLocation)
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})

    let blurb = ''
    switch(e.target.value){
      case "customer":
        blurb ='Search Customer Name, Company Name, First and Last Names, Notes'
        break
      case "contacts":
        blurb ='Search Contacts...'
        break
      case "sites":
        blurb ='Search Customer Sites...'
        break
      default:
        blurb = ''
    }
    this.setState({searchBlurb: blurb})
  }

  render() {

    return (

          <Form inline onSubmit={this.submitSearch}>
            <FormGroup>
              <ControlLabel> Search for </ControlLabel>{' '}
              <FormControl type='text' placeholder="Search term" name='q' value={this.state.q}
                           onChange={this.handleChange}/>
            </FormGroup>

            {' '}
            <ControlLabel> in </ControlLabel>
            {' '}

            <FormControl componentClass="select" value={this.state.searchLocation} name='searchLocation'
                         onChange={this.handleChange}>
              <option value="customer">Customer info</option>
              <option value="contacts">Contact info</option>
              <option value="sites">Site info</option>
            </FormControl> {' '}

            <Button bsStyle='success' type="submit">Search</Button>
            <p>&nbsp;</p>
            <p>{this.state.searchBlurb}</p>
          </Form>



    )
  }
}

export default SearchWidget