import { PROPOSALS_FETCHED, FETCH_STICKIES, PROPOSAL_STATS_FETCHED} from './constants'

const initialState={
  fetchedProposals: [],
  fetchedProposalStats: [],
  stickies: [],
}

const reducer = (state=initialState, action) => {

  switch (action.type) {
    case PROPOSALS_FETCHED:
      return {...state, fetchedProposals: action.fetchedProposals}
    case PROPOSAL_STATS_FETCHED:
      return {...state, fetchedProposalStats: action.fetchedProposalStats}
    case FETCH_STICKIES:
      return { ...state, stickies: action.stickies }
    default:
      return state
  }
}

export default reducer