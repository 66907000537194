import {FETCH_APPLICATION_TYPE_LIST} from '../constants'

const initialState = {
    applicationTypesList: [],
    applicationTypes: {},
    applicationTypesListLastUpdated: 0,
};

export function applicationTypesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_APPLICATION_TYPE_LIST:
            return {
                ...state,
                applicationTypesList: action.applicationTypesList,
                applicationTypesListLastUpdated: action.applicationTypesListLastUpdated,
            };
        default:
            return state
    }
}
