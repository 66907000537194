export const [
  GET_CUSTOMER_BY_ID,
  FETCH_CUSTOMER_INVOICES,
  FETCH_PROPOSAL_INVOICES,
  UPDATE_SERVICE_STATUS,
  FETCH_CUSTOMER_SITES,
  UPDATE_SELECTED_SITE,
  UPDATE_CUSTOMER_SEARCH,
  SELECT_CUSTOMER,
  UPDATE_PROPOSAL_SEARCH,
  UPDATE_INVOICE_SEARCH,
  FETCH_PROPOSALS,
  SELECT_PROPOSAL,
  SELECT_INVOICE,
  FETCH_SEARCHED_CUSTOMERS,
  FETCH_SEARCHED_PROPOSALS,
  FETCH_SELECTED_SITEINFO,
  FETCH_PS_INFO,
  FETCH_INVOICE_PS_INFO,
  NEW_INVOICE,
  DELETED_INVOICE,
  UPDATE_INVOICE,
  ADD_INVOICE_PROPOSAL_SERVICES,
  REMOVE_INVOICE_PROPOSAL_SERVICE,
  POSSIBLE_INVOICE_NUMBER,
  VERIFY_INVOICE_NUMBER,
  INVOICE_MARK_PAID,
  CHANGED_INVOICED_DATE,
] = [
  "GET_CUSTOMER_BY_ID",
  "FETCH_CUSTOMER_INVOICES",
  "FETCH_PROPOSAL_INVOICES",
  "UPDATE_SERVICE_STATUS",
  "FETCH_CUSTOMER_SITES",
  "UPDATE_SELECTED_SITE",
  "UPDATE_CUSTOMER_SEARCH",
  "SELECT_CUSTOMER",
  "UPDATE_PROPOSAL_SEARCH",
  "UPDATE_INVOICE_SEARCH",
  "FETCH_PROPOSALS",
  "SELECT_PROPOSAL",
  "SELECT_INVOICE",
  "FETCH_SEARCHED_CUSTOMERS",
  "FETCH_SEARCHED_PROPOSALS",
  "FETCH_SELECTED_SITEINFO",
  "FETCH_PS_INFO",
  "FETCH_INVOICE_PS_INFO",
  "NEW_INVOICE",
  "DELETED_INVOICE",
  "UPDATE_INVOICE",
  "ADD_INVOICE_PROPOSAL_SERVICES",
  "REMOVE_INVOICE_PROPOSAL_SERVICE",
  "POSSIBLE_INVOICE_NUMBER",
  "VERIFY_INVOICE_NUMBER",
  "INVOICE_MARK_PAID",
  "CHANGED_INVOICED_DATE",
]
