import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/lib/ModalDialog';
import React, {Component} from 'react';
import './DraggableModalDialog.css';

export default class DraggableModalDialog extends Component {
    render() {
        return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
    }
}
