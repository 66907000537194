import {Button, Col, Modal, Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import React from "react";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import TextareaAutosize from "react-textarea-autosize";

class IssueModal extends ResourceComponent {
    constructor(props) {
        super(props);
        this.state = {
            resource: {},
            isSaveInProgress: false
        };
    }

    componentDidMount() {
        if (this.props.issue) {
            this.setState({resource: {...this.props.issue}});
        }
    }

    handleStatusChange = (name) => (e) => {
        const {resource} = this.state;
        resource.status = name === resource.status ? 'reported' : name;
        this.setState(resource);
    };

    render() {
        const {resource, isSaveInProgress} = this.state;
        const {issue} = this.props;
        return (
            <Modal
                {...this.props}
                bsSize="big"
                animation={true}
                dialogClassName='modal-smaller'
                onHide={() => this.props.onHide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="break-word-issue">{issue && `Issue: ${issue.title}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <div><strong className="small-margin">Status</strong></div>
                            <ColorCheckbox value={resource.status === 'fixed'}
                                           label="Fixed"
                                           onChange={this.handleStatusChange('fixed')}/>
                            <ColorCheckbox value={resource.status === 'in progress'}
                                           label="In progress"
                                           onChange={this.handleStatusChange('in progress')}/>
                        </Col>
                        <Col md={12} className='mt-15'>
                            <div><strong className="small-margin">Comment</strong></div>
                            <TextareaAutosize
                                style={{width: '100%'}}
                                name="comment"
                                value={resource.comment}
                                onChange={(e) => {
                                    let {resource} = this.state;
                                    resource.comment = e.target.value;
                                    this.setState({resource})
                                }}
                                minRows={3}
                                maxRows={10}
                                placeholder="Comment"
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            <Button bsSize="small" onClick={() => this.props.onHide()}>Close</Button>
                        </Col>
                        <Col md={6} className="text-right">
                            <Button
                                bsStyle="success"
                                bsSize="small"
                                disabled={isSaveInProgress}
                                onClick={() => {
                                    this.setState({isSaveInProgress: true})
                                    this.props.onSave(resource)
                                }}
                            >Save
                            </Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
        )
    }
}

export default IssueModal;
