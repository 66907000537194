import React, {Component} from 'react'
import {faCogs} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './ProgressBar.scss'


export default class ProgressBar extends Component {

    render() {
        const {progress} = this.props
        return (
            <div className='setup-progress-bar'><FontAwesomeIcon icon={faCogs} size={'5x'}/>
                <div className='bar-container'>
                    <div className='title'>General Setup Progress</div>
                    <div className='bar-border'>
                        <div className='bar' style={{width: `${progress}%`}}></div>
                    </div>
                    <div className='progress-text'>Progress: {progress}%</div>
                </div>
            </div>
        )
    }
}