import {START_RECORDING, START_RECORDING_CURRENT_TAB, STOP_RECORDING, SET_RECORDING_VIDEO, REMOVE_VIDEO, CLEAR_VIDEOS} from './constants'

const initialState = {
    isRecording: false,
    current_tab: false,
    videos: [],
}

export const recorderReducer = (state  = initialState, action) => {
    switch (action.type) {
        case START_RECORDING:
            return {...state, isRecording: true, current_tab: false}
        case START_RECORDING_CURRENT_TAB:
            return {...state, isRecording: true, current_tab: true}
        case STOP_RECORDING:
            return {...state, isRecording: false}
        case SET_RECORDING_VIDEO:
            return {...state, videos: [...state.videos, action.video[0]]}
        case REMOVE_VIDEO:
            return {...state, videos: state.videos.filter(video => video.id !== action.id)}
        case CLEAR_VIDEOS:
            return {...state, videos: []}
        default:
            return state
    }
}