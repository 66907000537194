import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types"
import * as myActions from "./RegisterPageApi";
import {
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Button,
    Well
} from 'react-bootstrap'
import {browserHistory, Link} from "react-router";
import {addAlert} from "../App/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './RegisterWidget.css'
import Dollars from "../../components/Dollars";
import {faCheckCircle, faScroll, faHandHoldingUsd, faFlag} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PaymentElement} from "./StripeCardRegister";
import MDSpinner from "react-md-spinner";
import {getApiKey} from "./RegisterPageApi";
import {isEmailValid} from "../../common/commonHandlers";

const actions = {...myActions, addAlert};

const RegisterWidget = ({...props}) => {
    const [errors, setErrors] = useState({
        company_name: '',
        address_1: '',
        country: '',
        city: '',
        state: '',
        zip: '',
        email_address: '',
        password: '',
        confirm_password: '',
        license_count: ''
    })
    const [company_name, setCompany_name] = useState("")
    const [address_1, setAddress_1] = useState("")
    const [country, setCountry] = useState("United States")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [phone_number, setPhone_number] = useState("")
    const [email_address, setEmail_address] = useState("")
    const [password, setPassword] = useState("")
    const [confirm_password, setConfirm_password] = useState("")
    const [embedded_background_color, setEmbedded_background_color] = useState('#d50000')
    const [view, setView] = useState("Credentials")
    const [userCreated, setUserCreated] = useState(null)
    const [license_count, setLicense_count] = useState(1)
    const [total_price, setTotal_price] = useState(0)
    const [isValidCard, setIsValidCard] = useState(false)
    const [stripePaymentMethod, setStripePaymentMethod] = useState()
    const [stripePublishableKey, setStripePublishableKey] = useState()
    const [activated, setActivated] = useState(false)
    const [token, setToken] = useState()
    const [loading, setLoading] = useState(false)
    const [firstUserPrice, setFirstUserPrice] = useState(0)
    const [userPrice, setUserPrice] = useState(0)

    const {getPrices, getApiKey} = props.actions;
    useEffect(() => {
        getPrices(prices => {
            setTotal_price(prices.first)
            setFirstUserPrice(prices.first)
            setUserPrice(prices.next)
        })
        getApiKey(res => {
            setStripePublishableKey(res.api_key)
        })
        if (window.location.href.includes('?token=')) {
            let url = window.location.href
            let tok = url.substr(url.length - 7)

            const {activateAccount} = props.actions
            activateAccount(tok, response => {
                if (response.activated) {
                    setActivated(true)
                    setToken(tok)
                    setView('Finish')
                } else {
                    setActivated(false)
                    setToken(tok)
                    setView('Finish')
                }
            })

        }
    }, [])
    const validateEmailAndPassword = (errors) => {
        let valid = true;
        if (errors.email_address.length > 0 || errors.password.length > 0 || errors.confirm_password.length > 0) {
            valid = false
        }
        if (email_address.length < 1 || password.length < 1 || confirm_password.length < 1) {
            valid = false
        }
        return valid
    }

    const validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        if (company_name.length < 1 || address_1.length < 1 || country.length < 1 || city.length < 1 || state.length < 1
            || zip.length < 1) {
            valid = false
        }
        return valid
    }
    const handleAddressChange = (e) => {
        let err = errors;
        const {value} = e.target;
        setAddress_1(value)
        err.address_1 = value.length < 1 ? 'Address is required.' : '';
        setErrors(err);
        validateForm(errors)
    }
    const handleNameChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setCompany_name(value)
        err.company_name = value.length < 1 ? 'Company name is required.' : ''
        setErrors(err);
        validateForm(errors)
    }
    const handleEmailChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setEmail_address(value)
        err.email_address =
            isEmailValid(value) ? '' : value.length < 1 ? 'Email is required.' : 'Email is not valid.'
        setErrors(err);
        validateForm(errors)
    }
    const handlePasswordChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setPassword(value)
        err.password =
            value.length < 6
                ? 'Password must be 6 characters long.'
                : '';
        err.confirm_password =
            confirm_password !== value
                ? "Passwords don't match."
                : '';
        setErrors(err);
        validateForm(errors)
    }
    const handleConfirmChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setConfirm_password(value)
        err.confirm_password =
            password !== value
                ? "Passwords don't match."
                : '';
        setErrors(err);
        validateForm(errors)
    }
    const handleZipChange = (e) => {
        e.preventDefault()
        const {fetchStateAndCityForCustomer} = props.actions;
        let err = errors;
        const {value} = e.target;
        setZip(value)
        if (value.length === 5) {
            fetchStateAndCityForCustomer(value, (response) => {
                setCity(response.city)
                setState(response.state)
            })
        }
        err.zip =
            value.length < 1
                ? 'Zip is required.'
                : '';
        setErrors(err);
        validateForm(errors)
    }
    const handleStateChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setState(value)
        err.state =
            value.length < 1
                ? 'State is required.'
                : '';
        setErrors(err);
        validateForm(errors)
    }

    const handleCityChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setCity(value)
        err.city =
            value.length < 1
                ? 'City is required.'
                : '';
        setErrors(err);
        validateForm(errors)
    }

    const handleCountryChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setCountry(value)
        err.country =
            value.length < 1
                ? 'Country is required.'
                : '';
        setErrors(err);
        validateForm(errors)
    }

    const checkValidation = (callback) => {
        const {checkUniqueEmail, checkUniqueName} = props.actions;
        const email = email_address
        const name = company_name
        let valid = true
        checkUniqueEmail(email, null, false, (response) => {
            setErrors({
                ...errors, email_address:
                    response.unique
                        ? ''
                        : 'Client email already exist.'
            });
            if (!response.unique) {
                valid = false
            }
            userCreated && view === 'Billing' ? checkUniqueName(name, (response) => {
                setErrors({
                    ...errors, company_name:
                        response.unique
                            ? ''
                            : 'Company name already exist.'
                });
                if (!response.unique) {
                    valid = false
                }
                callback(valid)
            }) : callback(valid)
        })
    }
    const createClient = e => {
        const {createClient} = props.actions;
        e.preventDefault();
        let new_client = {
            address_1: address_1,
            city: city,
            country: country,
            email_address: email_address,
            embedded_background_color: embedded_background_color,
            name: company_name,
            phone_number: phone_number,
            state: state,
            zip: zip,
        }

        let user_password = {
            password: password,
        }

        let register_id = {
            register_id: userCreated,
        }
        const subscriptionData = {
            name: company_name,
            amount: total_price,
            user_count: license_count,
            trial: false,
        }
        setLoading(true)
        createClient(new_client, user_password, register_id, subscriptionData, stripePaymentMethod, res => {
            if (res?.id) {
                setLoading(false)
                setView('Finish')
            } else {
                setLoading(false)
            }
        })
    };
    const registerUser = e => {
        const {createRegister} = props.actions;
        e.preventDefault();

        checkValidation((valid) => {
            if (valid ? userCreated ? view === 'Credentials' ? validateEmailAndPassword(errors) : validateForm(errors) : validateEmailAndPassword(errors) : false) {
                let new_register = {
                    id: userCreated && userCreated,
                    address_1: address_1,
                    city: city,
                    country: country,
                    email_address: email_address,
                    name: company_name,
                    phone_number: phone_number,
                    state: state,
                    zip: zip,
                }
                createRegister(new_register, created_register => {
                    if (created_register) {
                        setUserCreated(created_register.id)
                        userCreated ? view === 'Credentials' ? setView("Billing") : setView("Payment") : setView("Billing")
                    }
                })
            }
        })
    };


    return (
        <div>
            {view === 'Credentials' && <Well>
                <h4>Sign Up</h4>
                <p>&nbsp;</p>

                <form onSubmit={e => registerUser(e)}>
                    <FormGroup className='registration-progress-container'>
                        <Row className="registration-progress">
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-active'>
                                    <FontAwesomeIcon icon={faCheckCircle}
                                                     className="bigger"/>
                                </div>
                                <div>Credentials</div>
                            </Col>

                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faScroll}
                                                     className="bigger"/>
                                </div>
                                <div>Billing Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faHandHoldingUsd}
                                                     className="bigger"/>
                                </div>
                                <div>Payment Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faFlag}
                                                     className="bigger"/>
                                </div>
                                <div>Finish</div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Email</ControlLabel>

                        <FormControl
                            type="email"
                            name="email_address"
                            onChange={e => handleEmailChange(e)}
                            value={email_address}
                        />
                        {errors.email_address.length > 0 &&
                        <span className='error'>{errors.email_address}</span>}
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            name="password"
                            type="password"
                            onChange={e => handlePasswordChange(e)}
                            value={password}
                        />
                        {errors.password.length > 0 &&
                        <span className='error'>{errors.password}</span>}
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Confirm Password</ControlLabel>
                        <FormControl
                            name="confirm_password"
                            type="password"
                            onChange={e => handleConfirmChange(e)}
                            value={confirm_password}
                        />
                        {errors.confirm_password.length > 0 &&
                        <span className='error'>{errors.confirm_password}</span>}
                    </FormGroup>
                    <div className='button-container'>
                        <Button bsStyle="success" type="submit">
                            Next
                        </Button>
                    </div>
                </form>
                <p>&nbsp;</p>
                <p>Already have an account? <Link to="login">Login</Link></p>
            </Well>}
            {view === 'Billing' &&
            <Well>
                <h4>Sign Up</h4>
                <p>&nbsp;</p>
                <form onSubmit={e => registerUser(e)}>
                    <FormGroup className='registration-progress-container'>
                        <Row className="registration-progress">
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faCheckCircle}
                                                     className="bigger"/>
                                </div>
                                <div>Credentials</div>
                            </Col>

                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-active'>
                                    <FontAwesomeIcon icon={faScroll}
                                                     className="bigger"/>
                                </div>
                                <div>Billing Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faHandHoldingUsd}
                                                     className="bigger"/>
                                </div>
                                <div>Payment Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faFlag}
                                                     className="bigger"/>
                                </div>
                                <div>Finish</div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Company Name</ControlLabel>
                        <FormControl
                            name="company_name"
                            onChange={e => handleNameChange(e)}
                            value={company_name}
                        />
                        {errors.company_name.length > 0 &&
                        <span className='error'>{errors.company_name}</span>}
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Address</ControlLabel>
                        <FormControl
                            name="city"
                            onChange={e => handleAddressChange(e)}
                            value={address_1}
                        />
                        {errors.address_1.length > 0 &&
                        <span className='error'>{errors.address_1}</span>}
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Country</ControlLabel>
                        <FormControl
                            name="country"
                            onChange={e => handleCountryChange(e)}
                            value={country}
                        />
                        {errors.country.length > 0 &&
                        <span className='error'>{errors.country}</span>}
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <ControlLabel>City</ControlLabel>
                                <FormControl
                                    name="city"
                                    onChange={e => handleCityChange(e)}
                                    value={city}
                                />
                            </Col>
                            <Col md={3}>
                                <ControlLabel>State</ControlLabel>
                                <FormControl
                                    name="state"
                                    onChange={e => handleStateChange(e)}
                                    value={state}
                                />
                            </Col>
                            <Col md={3}>
                                <ControlLabel>Zip</ControlLabel>
                                <FormControl
                                    name="zip"
                                    onChange={e => handleZipChange(e)}
                                    value={zip}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    {errors.city.length > 0 &&
                    <span className='error'>{errors.city}</span>}
                    {errors.state.length > 0 &&
                    <span className='error'>{errors.state}</span>}
                    {errors.zip.length > 0 &&
                    <span className='error'>{errors.zip}</span>}
                    <FormGroup>
                        <ControlLabel>Phone</ControlLabel>
                        <FormControl
                            name="phone_number"
                            onChange={e => setPhone_number(e.target.value)}
                            value={phone_number}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={12} style={{textAlign: 'right'}}>
                                <h4><ControlLabel><strong>Total Price: <Dollars
                                    amount={total_price}/> / month</strong></ControlLabel></h4>
                                <h6>
                                    <Dollars amount={firstUserPrice}/> first user and <Dollars amount={userPrice}/> each
                                    next user
                                </h6>
                            </Col>
                        </Row>
                    </FormGroup>
                    <div className='button-container'>
                        <Button bsStyle="danger" type="button" onClick={() => {
                            setView('Credentials')
                        }}>
                            Previous
                        </Button>
                        <Button bsStyle="success" type="submit">
                            Next
                        </Button>
                    </div>
                </form>
            </Well>
            }
            {view === 'Payment' &&
            <Well>
                <h4>Sign Up</h4>
                <p>&nbsp;</p>
                <form onSubmit={e => createClient(e)}>
                    <FormGroup className='registration-progress-container'>
                        <Row className="registration-progress">
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faCheckCircle}
                                                     className="bigger"/>
                                </div>
                                <div>Credentials</div>
                            </Col>

                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faScroll}
                                                     className="bigger"/>
                                </div>
                                <div>Billing Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-active'>
                                    <FontAwesomeIcon icon={faHandHoldingUsd}
                                                     className="bigger"/>
                                </div>
                                <div>Payment Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faFlag}
                                                     className="bigger"/>
                                </div>
                                <div>Finish</div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <PaymentElement license_count={license_count} setIsValidCard={setIsValidCard}
                                        setStripePaymentMethod={setStripePaymentMethod}
                                        stripePublishableKey={stripePublishableKey} loading={loading}/>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={8}>
                            </Col>
                            <Col md={4} style={{textAlign: 'right'}}>
                                <h4><ControlLabel><strong>Total Price: <Dollars
                                    amount={total_price}/> / month</strong></ControlLabel></h4>
                            </Col>
                        </Row>
                    </FormGroup>
                    <div className='button-container'>
                        {!loading && <Button bsStyle="danger" type="button" onClick={() => {
                            setView('Billing')
                        }}>
                            Cancel
                        </Button>}
                        <Button bsStyle="success" type="submit" disabled={!isValidCard || loading}>
                            {loading ? <MDSpinner/> : 'Pay'}
                        </Button>

                    </div>

                </form>
            </Well>
            }
            {view === 'Finish' &&
            <Well>
                <h4>Sign Up</h4>
                <p>&nbsp;</p>
                <form>
                    <FormGroup className='registration-progress-container'>
                        <Row className="registration-progress">
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faCheckCircle}
                                                     className="bigger"/>
                                </div>
                                <div>Credentials</div>
                            </Col>

                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faScroll}
                                                     className="bigger"/>
                                </div>
                                <div>Billing Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-disabled'>
                                    <FontAwesomeIcon icon={faHandHoldingUsd}
                                                     className="bigger"/>
                                </div>
                                <div>Payment Info</div>
                            </Col>
                            <span className="registration-progress-bar"></span>
                            <Col md={2} className='progress-wrapper'>
                                <div className='registration-active'>
                                    <FontAwesomeIcon icon={faFlag}
                                                     className="bigger"/>
                                </div>
                                <div>Finish</div>
                            </Col>
                        </Row>
                    </FormGroup>
                </form>
                {activated ? <div className="register-finish">
                    <h4>Congratulations!</h4>
                    <h5>Now you can log in to Treehub</h5>
                    <Button bsStyle='success' onClick={() => browserHistory.push('/login')}>
                        Log In
                    </Button>
                </div> : token ?
                    <div className="register-finish">
                        <h4 style={{color: 'red'}}>Cannot activate an account</h4>
                        <h5>Please contact us for more information info@treehub.me</h5>
                    </div>
                    :
                    <div className="register-finish">
                        <h4>Done!</h4>
                        <h5>To activate your Treehub account click on the link in account activation email.</h5>
                    </div>
                }


            </Well>
            }
        </div>
    )
}

RegisterWidget.propTypes = {
    client: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,

}

function mapStateToProps(state) {
    return {
        client: state.auth.client,
        email: state.email,
        roles: state.auth.roles,
        password: state.password,
        auth: state.auth,
        state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWidget);

