import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as MyActions from "./actions"
import {addAlert} from "../App/actions";
import {showDock} from "../../components/dock/actions";
import {Button, Col, ControlLabel, FormControl, Modal, Row} from "react-bootstrap";
import CustomerViewMenu from "./CustomerViewMenu";
import {PaymentElement} from "../CustomerViewInvoice/Payment/StripeElements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Visa from './visa.svg'
import Master from './master.svg'
import American from './american.svg'
import CustomerViewTabs from "./CustomerViewTabs";
import Dollar from "../../components/Dollars";

const Actions = {...MyActions, addAlert, showDock}

const isMobile = window.screen.width < 1024

class CustomerViewWallet extends Component {
    state = {
        payment_methods: [],
        stripeCredentials: {},
        walletBalance: 0,
        amount: 0,
        showFoundsModal: false,
        showModal: false,
        loading: true,
    }
    getPaymentMethods = () => {
        this.props.actions.loadStripeCredentials(this.props.params.token, res => {
            this.setState({stripeCredentials: res})
        })
        this.props.actions.loadWalletBalance(this.props.params.token, res => {
            this.setState({walletBalance: res})
        })
        this.props.actions.loadPaymentMethods(this.props.params.token, res => {
            this.setState({payment_methods: res, loading: false})
        })
    }
    deletePaymentMethod = (card_id) => {
        this.setState({loading: true})
        this.props.actions.deletePaymentMethod(this.props.params.token, card_id, res => {
            res.status === 'ok' && this.getPaymentMethods()
        })
    }
    updateDefaultPaymentMethod = (card_id) => {
        this.setState({loading: true})
        this.props.actions.updateDefaultPaymentMethod(this.props.params.token, card_id, res => {
            res.status === 'ok' && this.getPaymentMethods()
        })
    }
    prepayServices = (card_token) => {
        this.setState({loading: true})
        this.props.actions.prepayServices(this.props.params.token, this.state.amount, card_token, res => {
            res.status === 'ok' && this.closeModal()
        })
    }
    componentWillMount = () => {
        if (this.props.params.token) {
            this.getPaymentMethods()
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }
    handleResult = (card_token) => {
        this.props.actions.savePaymentMethod(this.props.params.token, card_token, res => {
            res.status === 'ok' && this.closeModal()
        })

    };
    closeModal = () => {
        this.setState({showModal: false, showFoundsModal: false})
        this.getPaymentMethods()
    }

    render() {
        const {
            payment_methods,
            stripeCredentials,
            showFoundsModal,
            walletBalance,
            showModal,
            loading,
            amount
        } = this.state
        const {client, params} = this.props
        const clientLogo = client ? client.client_logo_selected : ''
        return (<>
                <div id="customerView" className={`${isMobile ? "customerView--Mob" : "customerView"}`}>
                    <Row className='customerView__header customerView__header--disable-grid'>
                        <Col md={2} className='company-logo'><img src={clientLogo}/></Col>
                        <Col md={8} className='align-center'>
                            <Row><span className='title'>Your Payment Methods</span></Row>
                            <Row>
                                <span className='subtitle'>
                                    {!loading && this.state.payment_methods.length < 1 && "IT LOOKS LIKE YOU HAVEN'T ADDED A PAYMENT METHOD YET"}
                                    {!loading && <p>BALANCE: <Dollar amount={walletBalance / 100}/></p>}
                                </span>
                            </Row>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    {isMobile
                        ? <Col md={12}>
                            <CustomerViewMenu token={params.token}/>
                        </Col>
                        : <Row className="customerView__tabs">
                            <Col md={12}>
                                <CustomerViewTabs token={params.token}/>
                            </Col>
                        </Row>
                    }
                    <Row className='customerView__content'>
                        <Col md={12} className='align-center'>
                            <Col className='items-container'>
                                {payment_methods.map(pm => {
                                    return <Row className='item'>
                                        <div className='item-header'>
                                            <div className='item-title'>{pm.brand}</div>
                                            {pm.default ? <div className='item-status' style={{
                                                color: '#517209',
                                                backgroundColor: '#ecf3db'
                                            }}>Default</div> : <div className='item-status-clickable'
                                                                    onClick={() => this.updateDefaultPaymentMethod(pm.card_id)}
                                                                    style={{
                                                                        color: '#414141',
                                                                        backgroundColor: '#f3f3f3'
                                                                    }}>Set Default</div>}
                                        </div>
                                        <div className='item-data'>
                                            <p>**** **** **** {pm.last4}</p>
                                            <div onClick={() => this.deletePaymentMethod(pm.card_id)}
                                                 className='item-status-clickable'> Delete
                                            </div>
                                        </div>
                                    </Row>
                                })}
                                <Row>
                                    <Col md={12} className='wallet-actions'>
                                        <p className='payment-info'>Saving a card on file means it can be automatically
                                            charged for future billings.</p>
                                        <Button className='add-new-button'
                                                onClick={() => this.setState({showModal: true})}>
                                            <FontAwesomeIcon className='font14 mr-11' icon={faPlus}/>
                                            <span>ADD PAYMENT METHOD</span>
                                        </Button>
                                        <Button className='add-new-button'
                                                onClick={() => this.setState({showFoundsModal: true})}>
                                            <FontAwesomeIcon className='font14 mr-11' icon={faPlus}/>
                                            <span>PRE-PAY SERVICES</span>
                                        </Button>
                                        <div className='cards-icons'>
                                            <img src={Visa} alt='visa'/>
                                            <img src={Master} alt='master'/>
                                            <img src={American} alt='american'/>
                                        </div>
                                        <Modal
                                            bsSize="small"
                                            animation={true}
                                            show={showModal}
                                            backdrop={false}
                                            onHide={this.closeModal}
                                            id="customer-payment-modal"
                                        >
                                            <Modal.Header closeButton>
                                                <Col xs={11}>
                                                    <Modal.Title>Add New Payment Method</Modal.Title>
                                                </Col>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Row>
                                                    <Col xs={12} className="text-center">
                                                        {stripeCredentials.stripe_publishable_key &&
                                                            <PaymentElement
                                                                apiKey={stripeCredentials.stripe_publishable_key}
                                                                handleResult={this.handleResult}
                                                                payWithStripe={true}
                                                                saveOnly={true}/>}
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Row>
                                                    <Col md={12} className="text-right">
                                                        <Button
                                                            bsSize="small"
                                                            onClick={this.closeModal}
                                                        >Close
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal
                                            bsSize="small"
                                            animation={true}
                                            show={showFoundsModal}
                                            backdrop={false}
                                            onHide={this.closeModal}
                                            id="customer-payment-modal"
                                        >
                                            <Modal.Header closeButton>
                                                <Col xs={11}>
                                                    <Modal.Title>Pre-pay services</Modal.Title>
                                                </Col>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {payment_methods.length > 0 ? <Row>
                                                    <Col xsOffset={3} xs={6} className="text-center">
                                                        <ControlLabel
                                                            className='full-width textStart'>Amount</ControlLabel>
                                                        <FormControl
                                                            name="amount"
                                                            type='number'
                                                            onChange={(e) => {
                                                                const value = e.target.value
                                                                const match_value = value ? value.toString().match(/^(\d+\.\d{0,2})/) : value
                                                                this.setState({amount: match_value ? match_value[1] : value})
                                                            }}
                                                            value={amount}
                                                        />
                                                        <Button className='pre-pay-button' disabled={amount < 0.5}
                                                                onClick={() => this.prepayServices(amount)}> {loading ?
                                                            <span>Loading...</span> : <span>Pay <Dollar
                                                                amount={amount}/></span>}</Button>
                                                    </Col>
                                                </Row> : <Row>
                                                    <Col xsOffset={3} xs={6} className="text-center">
                                                        <ControlLabel
                                                            className='full-width textStart'>Amount</ControlLabel>
                                                        <FormControl
                                                            name="amount"
                                                            type='number'
                                                            onChange={(e) => {
                                                                const value = e.target.value
                                                                const match_value = value ? value.toString().match(/^(\d+\.\d{0,2})/) : value
                                                                this.setState({amount: match_value ? match_value[1] : value})
                                                            }}
                                                            value={amount}
                                                        />
                                                    </Col>
                                                    <Col xs={12} className="text-center">
                                                        {stripeCredentials.stripe_publishable_key &&
                                                            <PaymentElement
                                                                apiKey={stripeCredentials.stripe_publishable_key}
                                                                handleResult={this.prepayServices}
                                                                payWithStripe={true}
                                                                amount={amount}/>}
                                                    </Col>
                                                </Row>}

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Row>
                                                    <Col md={12} className="text-right">
                                                        <Button
                                                            bsSize="small"
                                                            onClick={this.closeModal}
                                                        >Close
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Modal.Footer>
                                        </Modal>


                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        site: state.customerView.site
    }
}


const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewWallet)