import React from 'react';
import {Col, FormGroup, Row, FormControl} from "react-bootstrap";
import Select from "react-select";
import Datetime from "react-datetime";
import {defaultDateFormat, defaultTimeFormat, select} from "../../../../common/commonHandlers";
import ResourceComponent from "../../../../components/ResourceComponent";
import moment from "moment";
import {debounce} from "throttle-debounce";

class AdminTransactionsFilters extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(1000, this.updateCustomerFilter);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
    }

    componentDidMount() {
        this.setState({resource: this.props.resource}, () => {
            this.customerSearch = this.buildSearchComponent('customer_ids', this.props.onSearch);
        })
    }

    updateCustomerFilter = () => {
        this.props.onChangeSelectedCustomers(this.state.resource)
    }

    selectCustomerFilter = (e) => {
        let {resource} = this.props;
        this.props.onChangeSelectedCustomers(e)
        resource['customer_ids'] = e.map(x => x.value);
    };

    handleChangeCreatedFrom = (e, date) => {
        let {resource} = this.props;
        if (e) {
            resource[date] = moment(e).startOf('day');
        } else {
            resource[date] = null
        }
        this.setState(resource, this.search);
    };

    handleChangeCreatedTo = (e, date) => {
        let {resource} = this.props;
        if (e) {
            resource[date] = moment(e).endOf('day');
        } else {
            resource[date] = null
        }
        this.setState(resource, this.search);
    };

    render() {
        const {transaction_types, statuses, resource, onChangeFilters, selectAttr} = this.props;
        const {
            created_from, created_to, amount_from, amount_to, available_from, available_to,
            fee_amount_from, fee_amount_to, customer_ids
        } = resource;

        const customerOptions = this.customerSearch.searchOptions();

        return (
            <div>
                <Row className="mt-15">
                    <Col md={6}>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(statuses, resource.status)}
                                    options={statuses}
                                    placeholder="Status"
                                    isClearable
                                    onChange={selectAttr('status')}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(transaction_types, resource.type)}
                                    options={transaction_types}
                                    placeholder="Type"
                                    isClearable
                                    onChange={selectAttr('type')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                type="number"
                                placeholder="Amount from"
                                name="amount_from"
                                value={amount_from}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                type="number"
                                placeholder="Amount to"
                                name="amount_to"
                                value={amount_to}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr className={'marginBottom10'}/>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                type="number"
                                placeholder="Fee amount from"
                                name="fee_amount_from"
                                value={fee_amount_from}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                type="number"
                                placeholder="Fee amount to"
                                name="fee_amount_to"
                                value={fee_amount_to}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr className={'marginBottom10'}/>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={created_from}
                                className="created_from"
                                inputProps={{placeholder: 'Created from'}}
                                closeOnSelect
                                onChange={e => this.handleChangeCreatedFrom(e, 'created_from')}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={created_to}
                                className="created_to"
                                inputProps={{placeholder: 'Created to'}}
                                closeOnSelect
                                onChange={e => this.handleChangeCreatedTo(e, 'created_to')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={available_from}
                                className="available_from"
                                inputProps={{placeholder: 'Available from'}}
                                closeOnSelect
                                onChange={e => this.handleChangeCreatedFrom(e, 'available_from')}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={available_to}
                                className="available_to"
                                inputProps={{placeholder: 'Available to'}}
                                closeOnSelect
                                onChange={e => this.handleChangeCreatedTo(e, 'available_to')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Select className="Select bottom15 top15"
                                classNamePrefix="select"
                                value={select([...this.props.selectedCustomers, ...customerOptions], customer_ids)}
                                options={customerOptions}
                                placeholder="Customer"
                                isMulti
                                isClearable
                                onInputChange={this.customerSearch.search}
                                onChange={this.selectCustomerFilter}
                        />
                    </Col>
                </Row>
            </div>
        );
    };
}

export default AdminTransactionsFilters;