import React, {useEffect, useRef, useState} from 'react'
import JoditEditor from "jodit-react";
import {Button, Col, ControlLabel, Nav, NavItem, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import * as actions from './TermsAPI'
import {connect} from "react-redux";
import PageHeader from "../../../components/PageTitle";
import moment from "moment";
import Datetime from "react-datetime";
import './Terms.css'
import Select from "react-select";
import {select} from "../../../common/commonHandlers";
import {JODIT_BUTTONS} from "../../../constants";
import {MaterialSwitch} from "../../../components/MaterialSwitch/MaterialSwitch";
import {Jodit} from "jodit";
import appConfig from "../../../config/environment";
import {Editor} from "@tinymce/tinymce-react";

const isMobile = window.screen.width <= 1024;

const Terms = ({actions, name, client}) => {
    const editor = useRef(null)
    const tinyMceEditor = useRef(null)
    const [terms, setTerms] = useState(null)
    const [term, setTerm] = useState(null)
    const [content, setContent] = useState('')
    const [initialValue, setInitialValue] = useState('')
    const [isJodit, setIsJodit] = useState(false);
    const [releaseDate, setReleaseDate] = useState(null)
    const [advancedView, setAdvancedView] = useState(false)
    const [showHtmlModal, setShowHtmlModal] = useState(false)
    const [config, setConfig] = useState({
        readonly: false,
        toolbarInline: true,
        popup: {
            cells: [...JODIT_BUTTONS.basic.popupButtons],
        },
        height: 500,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_only_text",
        ...JODIT_BUTTONS.basic
    })

    const loadTerms = () => actions.getTerms(res => {
        setTerms(res.sort((a, b) => b.id - a.id))
    })

    const loadSelectedTerm = termId => {
        setTerm({content: 'loading...'})
        actions.getTerm(termId, res => {
            setTerm({...res, release_date: moment(res.release_date)})
            setReleaseDate(moment(res.release_date))
            setContent(res.content)
            setInitialValue(res.content)
        })
    }
    const saveTerm = () => actions.saveTerm({...term, content: content, release_date: releaseDate}, res => {
        setTerm({...res, release_date: moment(res.release_date)})
        setReleaseDate(moment(res.release_date))
        loadTerms()
    })

    const deleteTerm = termId => actions.deleteTerm(termId, () => loadTerms())

    const publishTerm = termId => actions.saveTerm({...term, content: content, release_date: releaseDate}, res => {
        actions.publishTerm(termId, res => {
            setTerm({...res, release_date: moment(res.release_date)})
            setReleaseDate(moment(res.release_date))
            loadTerms();
        })
    })

    const checkIfValidToPublish = () => (term.published || !term.id || !releaseDate || !term.target)

    useEffect(() => {
        loadTerms()
    }, [])

    useEffect(() => {
        if (advancedView) {
            setConfig({
                readonly: false,
                buttons: [
                    ...Jodit.defaultOptions.buttons.filter(b => b.group !== "source"),
                    {
                        name: 'source',
                        exec: (editor) => {
                            editor.selection.clear();
                            editor.selection.remove();
                            setTimeout(() => {
                                editor.setMode(editor.mode === 1 ? 2 : 1)
                            }, 100)
                        }
                    }
                ],
                popup: {
                    cells: [
                        ...JODIT_BUTTONS.advanced.popupButtons,
                        Jodit.defaultOptions.popup.cells[6],
                        Jodit.defaultOptions.popup.cells[7],
                        Jodit.defaultOptions.popup.cells[8]
                    ]
                },
                height: 500,
                colorPickerDefaultTab: "color",
                extraPlugins: ['tooltip'],
                showTooltipDelay: 0,
                askBeforePasteHTML: showHtmlModal,
                askBeforePasteFromWord: false,
                defaultActionOnPaste: "insert_only_text",
            })
        } else {
            setConfig({
                readonly: false,
                toolbarInline: true,
                popup: {
                    cells: [...JODIT_BUTTONS.basic.popupButtons],
                },
                height: 500,
                colorPickerDefaultTab: "color",
                extraPlugins: ['tooltip'],
                showTooltipDelay: 0,
                askBeforePasteHTML: false,
                askBeforePasteFromWord: false,
                defaultActionOnPaste: "insert_only_text",
                ...JODIT_BUTTONS.basic
            })
        }
    }, [advancedView, showHtmlModal])

    const options = [
        {value: 'all_users', label: 'All Users'},
        {value: 'admins', label: 'Admins Only'}
    ]

    return (<>
        <PageHeader pageName="Terms" pageDetail={`- ${name}`}/>
        <Row className='mb-35'>
            <Col xs={12}>
                <Button onClick={loadTerms} bsStyle="success" className="mr-3">Refresh Terms List</Button>
                <Button onClick={() => {
                    setTerm({})
                    setReleaseDate(null)
                }} bsStyle="info">New Term</Button>
            </Col>
        </Row>
        <Row>
            <Col xs={isMobile ? 12 : 2}>
                <div style={{height: isMobile ? '300px' : '600px', overflowY: 'scroll'}}>
                    <Nav
                        bsStyle="pills"
                        stacked>
                        {terms && terms.length > 0 && terms.map(t => (
                            <NavItem key={t.id} eventKey={t.id} onClick={() => loadSelectedTerm(t.id)}>
                                {t.release_date ? `Term ${t.id}: ${moment(t.release_date).format("MM/DD/YYYY hh:mm A")}` : `Term ${t.id}`}
                                <span
                                    className='font10'>{t?.published && '(Published)'}</span>
                            </NavItem>
                        ))}
                        {terms?.length === 0 && <p>No terms in database</p>}
                    </Nav>
                </div>
            </Col>
            {term && <Col xs={isMobile ? 12 : 10}>
                <Row className='bottom15 vertical-align align-text-end'
                     style={{flexDirection: isMobile ? "column" : "row"}}>
                    <Col xs={isMobile ? 12 : 2} disabled={term.published}>
                        <label>Release date:</label>
                        <Datetime
                            dateFormat={"YYYY/MM/DD"}
                            timeFormat={"hh:mm A"}
                            value={releaseDate}
                            inputProps={{placeholder: 'Release Date'}}
                            closeOnSelect
                            onChange={e => {
                                setReleaseDate(moment(e))
                                setTerm({...term, release_date: moment(e)})
                            }}
                        />
                    </Col>
                    <Col xs={isMobile ? 12 : 2} disabled={term.published}>
                        <label>Target:</label>
                        <Select className="Select full-width" classNamePrefix="select"
                                options={options}
                                placeholder="Select target"
                                disabled={term.published}
                                value={select(options, term.target)}
                                onChange={e => setTerm({...term, target: e.value})}
                        />
                    </Col>
                    <Col xs={isMobile ? 12 : 5} className='d-flex'>
                        <Col xs={6} className="padding10">
                            {term.created_at && <div>
                                <span
                                    className='bold mr-5'>Created at:</span>{moment(term.created_at).format("YYYY/MM/DD HH:mm:ss")}
                            </div>}
                        </Col>
                        <Col xs={6} className="padding10">
                            {term.updated_at && <div>
                                <span
                                    className='bold mr-5'>Updated at:</span>{moment(term.updated_at).format("YYYY/MM/DD HH:mm:ss")}
                            </div>}
                        </Col>
                    </Col>
                    <Col xs={isMobile ? 12 : 3} className="justify-flex-end">
                        <Button disabled={checkIfValidToPublish()}
                                className={`${term.published && 'arrow-disabled'} mr-7`}
                                onClick={() => {
                                    term.published = true
                                    publishTerm(term.id)
                                }}>Publish</Button>
                        <Button disabled={term.published} bsStyle="danger"
                                className={`${term.published && 'arrow-disabled'} mr-7`} onClick={() => {
                            setTerm({})
                            deleteTerm(term.id)
                        }}>Delete</Button>
                        <Button disabled={term.published} bsStyle="success"
                                className={term.published && 'arrow-disabled'}
                                onClick={() => saveTerm(term)}>
                            Save Terms
                        </Button>
                    </Col>
                </Row>
                {isMobile ? <hr className='mt10'/> : <hr className='mt0'/>}
                <Row className="jodit-container-col">
                    <div className="d-flex" style={{marginLeft: isMobile ? 10 : 0}}>
                        <MaterialSwitch
                            value={isJodit}
                            onChange={() => setIsJodit(prev => !prev)}
                            label={"TinyMCE"}
                            label2={"Jodit"}
                        />
                        {isJodit && <>
                            <MaterialSwitch
                                value={advancedView}
                                onChange={() => setAdvancedView(prev => !prev)}
                                label={"Advanced view"}
                            />
                            {advancedView && <MaterialSwitch
                                style={{marginLeft: 20}}
                                value={showHtmlModal}
                                onChange={() => setShowHtmlModal(prev => !prev)}
                                label={"Show HTML Modal"}
                            />}
                        </>}
                    </div>
                    {isJodit ? <JoditEditor
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1}
                        onBlur={newContent => setContent(newContent)}
                        onChange={newContent => setContent(newContent)}
                    /> : <>
                        <Editor
                            apiKey={appConfig.tinyMceKey}
                            onInit={(evt, editor) => tinyMceEditor.current = editor}
                            initialValue={initialValue}
                            value={content}
                            onEditorChange={(newContent) => setContent(newContent)}
                            init={{
                                convert_urls: false,
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                toolbar_mode: 'wrap',
                                mobile: {
                                    convert_urls: false,
                                    menubar: true,
                                    toolbar_mode: 'wrap',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                }
                            }}
                        />
                    </>}
                </Row>
            </Col>}
        </Row>
    </>);
};

const mapStateToProps = state => ({
    name: state.auth.name,
    client: state.client.customerInfo,
})

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch),})

export default connect(mapStateToProps, mapDispatchToProps)(Terms)
