import axios from "axios/index";
import {apiCall} from "./apiActionHandler";
import {addParams} from "./commonHandlers";

/*
* Example:
* resourcePath = 'email_templates';
* resourceApiName = 'email_template';
* resourceName = 'Email template';
* */
export default function createApiService(resourcePath, resourceApiName, resourceName) {

    const load = (params, callback) => async (dispatch, getState) => {
        const config = {
            url: addParams(`/api/${resourcePath}`, params)
        };

        apiCall(`${resourceName} loading`, config, async config => {
            const response = await axios.request(config);
            dispatch({type: `${resourcePath} loaded`, data: response.data});
            callback && callback(response.data);
        }, dispatch, getState, false);
    };

    const get = (id, callback) => async (dispatch, getState) => {
        const config = {
            url: `/api/${resourcePath}/${id}`
        };

        apiCall(`${resourceName} loading`, config, async config => {
            const response = await axios.request(config);
            callback && callback(response.data);
            dispatch({type: `${resourcePath} loaded by id`, data: response.data});
        }, dispatch, getState, false);
    };

    const save = (resource, callback) => async (dispatch, getState) => {
        const method = resource.id ? "PUT" : "POST";
        const url = resource.id ? `/api/${resourcePath}/${resource.id}` : `/api/${resourcePath}`;
        let data = {};
        data[resourceApiName] = resource;
        const config = {
            method,
            url,
            data
        };

        apiCall(`${resourceName} saving`, config, async config => {
            const response = await axios.request(config);
            callback && callback(response.data);
        }, dispatch, getState);

    };
    const remove = (resource, callback) => async (dispatch, getState) => {
        const config = {
            url: `/api/${resourcePath}/${resource.id}`,
            headers: {Authorization: getState().auth.token},
            method: 'DELETE'
        };


        apiCall(`${resourceName} removing`, config, async config => {
            const response = await axios.request(config);
            callback && callback(response.data);
        }, dispatch, getState);
    };
    return {load, get, save, remove}
}