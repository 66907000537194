export const [
    ADD_ALERT, CLEAR_ALERT, CLEAR_ALL_ALERTS, IS_PROCESSING, SET_USER_TERMS_STATUS,
    SET_ADMIN_TERMS_STATUS, SET_ADMIN_TERMS_REQUIRED, SET_OPTIONAL_GEOLOCATION, APP_STATE_LOADING,
    SET_REDIRECT_TO_SUBSCRIPTION_ROUTER, SET_SUBSCRIPTION_STATUS, SET_SUBSCRIPTION_REMINDER
] = [
    'ADD_ALERT',  'CLEAR_ALERT', 'CLEAR_ALL_ALERTS', 'IS_PROCESSING', 'SET_USER_TERMS_STATUS',
    'SET_ADMIN_TERMS_STATUS', 'SET_ADMIN_TERMS_REQUIRED', 'SET_OPTIONAL_GEOLOCATION', 'APP_STATE_LOADING',
    'SET_REDIRECT_TO_SUBSCRIPTION_ROUTER', 'SET_SUBSCRIPTION_STATUS', 'SET_SUBSCRIPTION_REMINDER'
]

