import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import * as FileSaver from "file-saver";
import {addAlert} from "../../App/actions";
import moment from "moment";
import {mapForSelect} from "../../../common/commonHandlers";
import {dataUpload} from "../../../common/upload";

export const fetchStripeTransactions = (resource, pageNav, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_transactions`,
        method: 'GET',
        params: {...resource, ...pageNav}
    };

    apiCall("Fetch stripe transactions", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchClientPayments = (resource, client_id, callback) => (dispatch, getState) => {
    resource.client_id = client_id;

    const config = {
        url: `/api/payments/get_payments`,
        method: 'GET',
        params: resource
    };

    apiCall("Fetch transactions", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const downloadPdf = (invoice) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${invoice.token}/invoice_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${invoice.client_name}-Invoice #${invoice.number}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(invoice ? invoice : {}), source: "invoice pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        throw exception
    }
};

export const downloadStripeTransactionsCsv = (params) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/stripe_transactions_csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `stripe-transactions-${moment().format()}.csv`);
    }, dispatch, getState);
};


export const downloadCsv = (params, client_name) => async (dispatch, getState) => {
    const config = {
        url: `/api/payments/csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `${client_name}-transactions-${moment().format()}.csv`);
    }, dispatch, getState);
};

export const getCustomersSearch = (searchText, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_customers/search_customers/`,
        params: {q: searchText}
    };

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchTransaction = (id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/search_transaction`,
        method: 'GET',
        params: {id}
    };

    apiCall("Search transaction", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};