import {Col, Modal, Row} from "react-bootstrap";
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const isMobile = window.screen.width <= 1024;

const HolidayRemovalModal = ({onDelete, onHide}) => {
    return (
        <Modal
            animation={false}
            show={true}
            onHide={onHide}
            className={"heightAuto maxHeight90 fontIOS"}
            bsSize="small"
        >
            <Modal.Header>
                <Modal.Title>Delete holiday</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} className="d-flex vcenter mt-20 mb-15">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className={`font20 alert-font-color mt-3 ${isMobile ? "" : "small-margin"}`}
                        />
                        <p className={`bottom0 ml-10 line_height17_5 ${isMobile ? "font12" : ""}`}>
                            Deleting this holiday will remove all files it contains. {isMobile && <br/>} Are you sure ?
                        </p>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col md={6} className="text-left">
                    </Col>
                    <Col md={6}>
                        <button type="button" onClick={() => onHide()} className="btn btn-default"
                                data-dismiss="modal">No
                        </button>
                        <button type="button" onClick={() => onDelete()} className="btn btn-success">Yes</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default HolidayRemovalModal;