import {combineReducers} from "redux"
import {routerReducer} from "react-router-redux"

import {authReducer} from "./containers/LoginPage/reducer"
import {appReducer} from "./containers/App/reducer"
import theme from "./containers/ClientDashboardPage/reducer"
import {arboristApprovals} from "./containers/ClientDashboardPage/arboristReducer"
import admin from "./containers/AdminPage/reducer"
import acceptProposals from "./containers/AcceptProposalsPage/reducer"
import proposal from "./containers/ProposalPage/reducer"
import proposals from "./containers/ProposalsListPage/reducer"
import stickies from "./containers/Stickies/reducer"
import sales from "./containers/SalesDashboardPage/reducer"
import pendingApprovals from "./containers/PendingApprovalsPage/reducer"
import client from "./containers/ClientsPage/reducer"
import mapView from "./containers/MapViewPage/reducer"
import assetModal from "./containers/AssetEditorModal/reducer"
import {recorderReducer} from './containers/Notifications/Issues/reducer'

import {plantsReducer} from "./reducers/plants" // top level reducer since its used in multiple Pages
import {equipmentReducer} from "./reducers/equipment" // top level reducer since its used in multiple Pages
import {applicationTypesReducer} from "./reducers/applicationTypes"

import docks from "./components/dock/reducer"
import emails from "./components/email/reducer"
import jsa from "./containers/JobSiteAnalysisPage/reducer"
import users from "./containers/AppWrapper/reducer"
import userStatus from "./containers/AppWrapper/contents/reducer"

import applicationSites from "./reducers/applicationSites"

import consumables from "./reducers/consumables"
import contactTypes from "./reducers/contactTypes"
import priorities from "./reducers/priorities"
import referalls from "./reducers/referalls"

import serviceTypes from "./reducers/serviceTypes"
import customerTypes from "./reducers/customerTypes"
import trucks from "./reducers/trucks"
import workOrders from "./reducers/workOrders"
import siteMaps from "./reducers/siteMaps"

import customerSearchResults from "./containers/CustomerSearchPage/reducer"
import customerInfo from "./containers/CustomerPage/CustomerInfo/reducer"
import salesArborists from "./reducers/salesArborists"

import servicesCrud from "./containers/ServicesPage/reducer"
import {servicesReducer} from "./reducers/services"
import employeesCrud from "./containers/EmployeesPage/reducer"
import {employeesReducer} from "./reducers/employees"
import customerView from "./containers/CustomerViewPage/reducer"

import changeServiceStatus from "./containers/ChangeServiceStatusPage/reducer"

import proposalServiceStatus from "./containers/ProposalServiceStatusPage/reducer"

import home from "./containers/HomePage/reducer"
import salesFunnel from "./containers/SalesFunnelPage/reducer"
import invoices from "./containers/InvoicesPage/reducer"
import activities from "./containers/ActivitiesPage/reducer"

import workHistory from "./containers/CustomerPage/WorkHistory/reducer"
import customerProposals from "./containers/CustomerPage/customerProposals/reducer"
import customerSites from "./containers/CustomerPage/CustomerSites/reducer"
import customerWorkOrders from "./containers/CustomerPage/CustomerWorkOrders/reducer"
import customerInvoices from "./containers/CustomerPage/CustomerInvoices/reducer"
import customerManageInvoices from "./containers/CustomerPage/CustomerManageInvoices/reducer"

import contactEditor from "./containers/ContactEditorModal/reducer"
import siteEditor from "./containers/SiteEditorModal/reducer"

import crewWorkOrders from "./containers/Scheduler/CrewMessages/reducer"
import {permissionReducer} from "./containers/PermissionController/PermissionControllerReducer";

const rootReducer = combineReducers({
    routing: routerReducer,
    theme,
    arboristApprovals,
    auth: authReducer,
    app: appReducer,
    perm: permissionReducer,
    userStatus,
    docks,
    emails,
    jsa,
    users,
    client,
    acceptProposals,
    proposal,
    proposals,
    stickies,
    sales,
    pendingApprovals,
    admin,
    mapView,
    assetModal,
    recorder: recorderReducer,
    plants: plantsReducer,
    equipment: equipmentReducer,
    applicationTypes: applicationTypesReducer,
    applicationSites,
    consumables,
    contactTypes,
    priorities,
    referalls,
    serviceTypes,
    customerTypes,
    trucks,
    workOrders,
    siteMaps,
    customerSearch: customerSearchResults,
    customerInfo,
    salesArborists,
    servicesCrud,
    services: servicesReducer,
    employeesCrud,
    employees: employeesReducer,
    customerView,
    changeServiceStatus,
    proposalServiceStatus,
    home,
    salesFunnel,
    invoices,
    activities,
    workHistory,
    customerProposals,
    customerSites,
    customerWorkOrders,
    customerInvoices,
    customerManageInvoices,
    contactEditor,
    siteEditor,
    crewWorkOrders
})

export default rootReducer
