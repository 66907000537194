import {Button, Col, FormControl, FormGroup, InputGroup, Modal, Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import React from "react";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {dateFormatter, dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import Dollars from "../../components/Dollars";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {connect} from "react-redux";


class Payments extends ResourceComponent {
    constructor(props) {
        super(props);
        this.state = {
            resource: {},
            editedRow: null
        };
    }

    componentDidMount() {
        this.setState({resource: {...this.props.show}})
    }


    removePayment = (row) => {
        const {resource} = this.state;
        const index = resource.payments.findIndex(p => p.id === row.id)
        const areYouSure = window.confirm(
            "Are you sure you want to delete this payment?"
        );
        if (areYouSure) {
            resource.payments.splice(index, 1)
            this.setState({resource: resource}, () => this.props.onDelete(row.id))
        }

    }


    buttonFormatter = (cell, row) => {
        return (
            <Row>

                {!row.charge_id &&
                    <div>
                        {this.state.editedRow === row.id ?
                            <Button bsStyle="success"
                                    onClick={() => this.setState({editedRow: null})}>
                                OK
                            </Button>
                            :
                            <Button bsStyle="warning"
                                    onClick={() => this.setState({editedRow: row.id}, this.dollarFormatEditedCell)}>
                                Edit
                            </Button>
                        }


                        <Button bsStyle="danger" onClick={() => this.removePayment(row)}>
                            Delete
                        </Button>
                    </div>}
                {row.status === "refund_requested" || (row.charge_id && this.props.roles.includes("owner") && row.status !== "refunded") &&
                    <Button bsStyle="success" onClick={() => this.props.onRefund(row.charge_id, row.invoice_id, row.amount)}>
                        Refund
                    </Button>}

            </Row>

        )
    };

    feeFormatter = (cell, row) => {
        const {resource} = this.state
        const fee = resource.client_fee && (resource.client_fee.includes('%') ? +resource.client_fee.slice(0, -1) / 100 * row.amount : resource.client_fee.includes('$') ? +resource.client_fee.substr(1) : null);
        return <Dollars amount={fee}/>
    };

    statusFormatter = (cell, row) => {
        if (row.status === "refund_requested") {
            return 'Refund requested'
        }
        if (row.status === "paid") {
            return 'Paid'
        } else if (row.status === "refunded") {
            return 'Refunded'
        }
    };

    onAfterSaveCell = () => {
        const {resource} = this.state;
        const index = resource.payments.findIndex(p => p.id === this.state.editedRow)

        let paid = 0;

        for (let p = 0; p < resource.payments.length; p++) {
            paid += parseFloat(resource.payments[p].amount)
        }
        if (paid <= parseFloat(resource.total)) {
            this.props.onUpdate(resource.payments[index])
        }
    }


    trClassFormat = (row, rowIdx) => {
        return this.state && row.id === this.state.editedRow && 'edited-row';
    }

    handleAmountChange(e) {
        let {payments} = this.state.resource
        payments.map((p) => {
            if (p.id === this.state.editedRow) {
                p.amount = e.target.value
            }
            return p
        })
        this.setState({resource: {...this.state.resource, payments}})
    }

    dollarFormatEditedCell = () => {
        let {payments} = this.state.resource
        payments.map((p) => {
            if (p.id === this.state.editedRow) {
                p.amount = parseFloat(p.amount.toString().replace(/[^0-9.]/g, '')).toFixed(2)
            }
            return p
        })
        this.setState({resource: {...this.state.resource, payments}})
    }

    dollarFormatOnBlur = (e) => {
        let {payments} = this.state.resource
        payments.map((p) => {
            if (p.id === this.state.editedRow) {
                p.amount = e.target.value ? parseFloat(e.target.value.toString().replace(/[^0-9.]/g, '')).toFixed(2)
                    : e.target.value;
            }
            return p
        })
        this.setState({resource: {...this.state.resource, payments}}, this.onAfterSaveCell)
    };

    render() {


        const {resource, editedRow} = this.state;

        return (

            <div className="modal-body top15">
                <Modal.Header>
                    <Modal.Title>Payment history</Modal.Title>
                </Modal.Header>
                <Row>
                    {resource && resource.payments && <Col xs={12}>
                        <BootstrapTable
                            ref="table"
                            data={resource.payments}
                            striped={true}
                            bordered={false}
                            hover={true}
                            remote
                            fetchInfo={{dataTotalSize: resource.payments.length}}
                            className="wrapped"
                            trClassName={this.trClassFormat}
                        >
                            <TableHeaderColumn
                                editable={false}
                                dataField="id"
                                hidden
                                width={"5%"}
                                isKey
                                columnClassName={this.trClassName}
                            />
                            <TableHeaderColumn
                                editable={false}
                                dataField="payment_date"
                                dataFormat={dateFormatter}
                                width={"20%"}
                            >
                                Payment date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="amount"
                                dataFormat={(cell, row) => {
                                    if (row.id === this.state.editedRow) {
                                        return <FormGroup>
                                            <InputGroup>
                                                <InputGroupAddon>
                                                    $
                                                </InputGroupAddon>
                                                <FormControl
                                                    name="payments"
                                                    type="text"
                                                    onChange={this.handleAmountChange.bind(this)}
                                                    onBlur={(e) => this.dollarFormatOnBlur.bind(this)(e)}
                                                    value={cell}
                                                    style={{"padding": "5px"}}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    } else {
                                        return dollarsFormatter(cell)
                                    }
                                }}
                                width={"15%"}
                                editTdAttr={{id: "amount-input"}}
                            >
                                Amount
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="customer_fee_amount"
                                dataFormat={dollarsFormatter}
                                width={"15%"}
                                editable={false}


                            >
                                Customer Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="transaction_fee_amount"
                                dataFormat={dollarsFormatter}
                                width={"15%"}
                                editable={false}

                            >
                                Client Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataFormat={this.statusFormatter}
                                dataField="status"
                                width={"15%"}
                                editable={false}
                            >
                                Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                editable={false}
                                dataFormat={this.buttonFormatter}
                                width={"15%"}
                            >
                                Options
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>}
                </Row>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.auth.roles
});
export default connect(mapStateToProps, undefined)(Payments);
