import {FETCH_PLANT_LIST} from '../constants'

const initialState = {
    plantList: [],
    plants: {},
    plantListLastUpdated: 0
};

export function plantsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PLANT_LIST:
            return {...state, plantList: action.plantList, plantListLastUpdated: action.plantListLastUpdated};
        default:
            return state
    }
}
