import { PROPOSALS_FETCHED} from './constants'

const initialState={
  fetchedProposals: [],
}

const reducer = (state=initialState, action) => {

  switch (action.type) {
    case PROPOSALS_FETCHED:
      console.log("executing propsal_loaded in propsoalPage reducer")
      return {...state, fetchedProposals: action.fetchedProposals}
    default:
      return state
  }
}

export default reducer