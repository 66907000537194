import React, {useEffect, useState} from "react";
import {Circle, InfoWindow, Marker, Polygon, Polyline, Rectangle} from "react-google-maps";
import {getColorIcon, getLabel} from "../MapViewPage/IconProvider";
import {checkRectangleCoordinates, colors} from "../../common/commonHandlers";
import PlantName from "../../components/PlantName";
import Attachment from "../../components/image/Attachment";
import {getBasicMarker} from "../../utilities";


const AssetMarker = ({asset, index, proposal_services, services, onClick, isOtherAssetSelected, selected, id}) => {
    const setShapeOpacity = (ass) => {
        const polygon = ass.polygons?.find(p => p.chosen)

        if (ass.shapeOpacity && polygon?.type_polygon === 'polyLine') {
            return 0.35
        } else if (ass.shapeOpacity) {
            return 0.2
        } else if (ass.opacity && polygon?.type_polygon === 'polyLine') {
            return 0.75
        } else if (ass.opacity) {
            return 0.5
        } else {
            return 1
        }
    }
    const polygon = asset.polygons && asset.polygons.find(p => p.chosen);
    const color = polygon && polygon.color ? colors[polygon.color] : colors[parseInt(asset.color)];
    const opacity = setShapeOpacity(asset)

    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        if (isOtherAssetSelected && isOpen) {
            setIsOpen(false);
        }
    }, [isOtherAssetSelected])

    const handleOpen = () => {
        setIsOpen(true)
        onClick(asset.id)
    };

    const handleClose = () => {
        setIsOpen(false)
        onClick(null)
    };

    const infoWindow = <InfoWindow onCloseClick={handleClose}>
        <div className="info-window">
            <div>
                <h6 className={`text-blue`}>#{asset.asset_number} {asset.plant_count > 1 ? ' [' + asset.plant_count + ']' : ""}
                    <PlantName
                        plant={asset.name}/></h6>
                {asset.asset_services && asset.asset_services.map((service, index) =>
                    <div
                        key={index}>{service}</div>
                )}
            </div>
            <div>
                {asset.photos?.length > 0 &&
                    asset.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(i =>
                        <Attachment key={i.id} image={i}/>)
                }
            </div>
        </div>
    </InfoWindow>;

    const renderPolygonMarker = () => {
        const points = polygon.polygon_points;
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : asset.lat
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : asset.lng
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }

        }

        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(asset.asset_label)}
                strokeColor={"#ffffff"}
                onClick={handleOpen}
                zIndex={1000}
            >
                {isOpen && infoWindow}
            </Marker>
        )
    }

    let asset_services = []
    isOpen && proposal_services.forEach(ps => {
        ps.assets.forEach(ass => {
            if (services && services.length > 0) {
                services.forEach(service => {
                    if (asset.id === ass.id && ps.service_number === service.service_number) {
                        asset_services.push(`#${ps.order_number_in_proposal} ${ps.name}`)
                        asset.asset_services = asset_services && asset_services.sort()
                    }
                })
            }
        })
    })
    if (polygon && polygon.type_polygon !== 'marker') {
        const coords = polygon.polygon_points && polygon.polygon_points.map(x => ({
            lat: x.latitude,
            lng: x.longitude,
            id: x.id,
        }));
        let polygonElement;
        if (polygon.type_polygon === 'polyLine') {
            polygonElement = <Polyline
                path={coords}
                key={index}
                options={{
                    strokeOpacity: opacity,
                    strokeColor: color,
                    fillColor: color
                }}
            />
        }
        else if (polygon.type_polygon === 'circle') {
            polygonElement = <Circle
                defaultOpacity={1}
                onClick={handleOpen}
                defaultCenter={{
                    lat: polygon.polygon_points[0].latitude,
                    lng: polygon.polygon_points[0].longitude
                }}
                options={{
                    fillOpacity: opacity,
                    strokeColor: color,
                    fillColor: color
                }}
                radius={polygon.radius}
                key={index}
            />
        }
        else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
            polygonElement = <Rectangle
                onClick={handleOpen}
                bounds={{
                    north: polygon.polygon_points[0].latitude,
                    south: polygon.polygon_points[3].latitude,
                    east: polygon.polygon_points[1].longitude,
                    west: polygon.polygon_points[0].longitude
                }}
                key={index}
                options={{
                    fillOpacity: opacity,
                    strokeColor: color,
                    fillColor: color
                }}
            />
        }
        else {
            polygonElement = <Polygon
                onClick={handleOpen}
                path={coords}
                key={index}
                options={{
                    fillOpacity: opacity,
                    strokeColor: color,
                    fillColor: color
                }}
            />
        }
        return <>{polygonElement}{renderPolygonMarker()}</>;
    } else {
        const myLoc = asset.name === "Here you are!"
        const lat = myLoc ? asset.latitude : asset.lat
        const lng = myLoc ? asset.longitude : asset.lng

        return <Marker
            key={index}
            opacity={asset.opacity || asset.removed ? 0.5 : 1}
            position={{lat: lat, lng: lng}}
            icon={myLoc ? asset.icon : getBasicMarker({selected: asset.selected, color: color, label: asset.asset_label})}
            title={asset.name}
            onClick={handleOpen}
            zIndex={asset.selected ? 999999 : index}
        >
            {isOpen && infoWindow}
        </Marker>
    }
};

const arePropsEqual = (prevProps, nextProps) => {
    if (prevProps.isOtherAssetSelected !== nextProps.isOtherAssetSelected || (prevProps.id !== nextProps.id) || prevProps.selected !== nextProps.selected) {
        return false;
    }
    return true;
}
export default React.memo(AssetMarker, arePropsEqual);