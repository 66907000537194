import React, { Component } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as MyActions from "./actions"
import Helmet from "react-helmet"
import { addAlert } from "../App/actions"
import * as ServiceActions from "../../actions/services"
import { fetchServiceTypesList } from "../../actions/serviceTypes"
import { fetchConsumablesList } from "../../actions/consumables"
import { fetchEquipmentList } from "../../actions/equipment"
import { fetchTrucksList } from "../../actions/trucks"
import { fetchApplicationSitesList } from "../../actions/applicationSites"
import { fetchApplicationTypesList } from "../../actions/applicationTypes"
import { fetchPlantList } from "../../actions/plants"
import {
  Grid,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  FormControl,
  ControlLabel,
  FormGroup,
  Checkbox,
  Glyphicon,
} from "react-bootstrap"
import PageHeader from "../../components/PageTitle"
//import {LinkContainer} from 'react-router-bootstrap'
import { humanize } from "../../utilities/index"

import moment from "moment-timezone"
import Datetime from "react-datetime";
const Actions = {
  ...MyActions,
  addAlert,
  fetchServiceTypesList,
  fetchConsumablesList,
  fetchEquipmentList,
  fetchTrucksList,
  fetchApplicationSitesList,
  fetchApplicationTypesList,
  fetchPlantList,
  ...ServiceActions,
}
class ServicesPage extends Component {
  static proTypes = {
    client: PropTypes.string.isRequired,
    services: PropTypes.array.isRequired,

    service: PropTypes.object,
    loadServiceForEditing: PropTypes.func.isRequired,
    fetchServiceList: PropTypes.func.isRequired,
    updateServiceAttribute: PropTypes.func.isRequired,
  }

  state = { allowAssociationsEditing: false, selected: [] }
  componentWillMount() {
    //consider checking the data to see if its stale enough to fetch?
    if (Date.now() - this.props.serviceListLastUpdated > 5 * 1000) {
      this.refreshServiceList()
    }
  }

  refreshServiceList = () => {
    this.props.actions.fetchServiceList()
    this.editService(null)
  }

  submitForm = (e, id) => {
    e.preventDefault()
    this.props.actions.saveOrCreateService(id)
    this.props.actions.fetchServiceList() //refresh the list
  }

  editService = serviceId => {
    //null means we're not editing any service. in that case dont do extra api calls
    const { actions } = this.props
    this.setState({ allowAssociationsEditing: false })
    actions.loadServiceForEditing(serviceId)
    if (serviceId !== null) {
      //TODO: check to see how stale the list is before fetching. if someone's 'browsing' then the UI might
      // feel slow because we're constantly fetching even if there's no changes
      // we can cache server or client side i guess
      actions.fetchServiceTypesList()
      actions.fetchConsumablesList()
      actions.fetchEquipmentList()
      actions.fetchTrucksList()
      actions.fetchApplicationSitesList()
      actions.fetchApplicationTypesList()
      actions.fetchPlantList()
    }
  }

  addNewService() {
    this.props.actions.createNewService()
    this.editService(0)
  }

  deleteService(id) {
    const action_confirmed = window.confirm('Do you REALLY want to delete this Service???')

    if (action_confirmed) {
      this.props.actions.deleteService(id)
      this.props.actions.fetchServiceList() //refresh the list
      this.editService(null)
     }
  }

  changeFilterText(txt) {
    this.props.actions.editFilterText(txt)
    this.editService(null)
  }

  renderTextField(service, attr) {
    const name = humanize(attr)
    return (
      <FormGroup bsSize="sm" key={attr}>
        <ControlLabel>{name}</ControlLabel>
        <FormControl
          type="text"
          placeholder={name}
          value={service[attr] || ""}
          onChange={e =>
            this.props.actions.updateServiceAttribute(
              service.id,
              attr,
              e.target.value
            )}
        />
      </FormGroup>
    )
  }

  renderTextArea(service, attr) {
    const name = humanize(attr)
    return (
      <FormGroup bsSize="sm" key={attr}>
        <ControlLabel>{name}</ControlLabel>
        <FormControl
          componentClass="textarea"
          placeholder={name}
          value={service[attr] || ""}
          onChange={e =>
            this.props.actions.updateServiceAttribute(
              service.id,
              attr,
              e.target.value
            )}
        />
      </FormGroup>
    )
  }

  renderCheckbox = (service, attr) => (
    <FormGroup bsSize="sm">
      <Checkbox
        checked={service[attr]}
        onChange={e =>
          this.props.actions.updateServiceAttribute(
            service.id,
            attr,
            e.target.checked
          )}
      >
        Disabled?
      </Checkbox>
    </FormGroup>
  )

  renderRemovalCheckbox(service, attr) {
    return (
      <FormGroup bsSize="sm">
        <Checkbox
          checked={service[attr] || false}
          onChange={e =>
            this.props.actions.updateServiceAttribute(
              service.id,
              attr,
              e.target.checked
            )}
        >
          Removal Service
        </Checkbox>
      </FormGroup>
    )
  }
  _updateServiceAssociationAttribute = e => {
    const selectedIds = [...e.target.options]
      .filter(o => o.selected)
      .map(o => o.value)

    this.props.actions.updateServiceAttribute(
      this.props.service.id,
      e.target.name,
      selectedIds.includes("0") ? [0] : selectedIds
    )
  }

  _renderAssociationMultiSelect = (
    label,
    values,
    optionList,
    onChange,
    changeAttr
  ) => (
    <FormGroup bsSize="sm">
      <ControlLabel>
        {label} ({values ? `${values.length} selected` : 0})
      </ControlLabel>
      <FormControl
        disabled={!this.state.allowAssociationsEditing}
        style={{ height: "200px" }}
        componentClass="select"
        multiple
        name={changeAttr}
        onChange={onChange}
        placeholder={`Select ${label}`}
        value={values && values.length === 0 ? ["0"] : values}
      >
        <option key={0} value={0}>No {label}</option>
        {optionList.map(e => {
          return <option key={e.id} value={e.id}>{e.name}</option>
        })}
      </FormControl>
    </FormGroup>
  )

  _onAddBlankDateRange = () => {
    this.props.actions.updateServiceAttribute(
      this.props.service.id,
      "service_date_ranges",
      this.props.service.service_date_ranges.concat([
        {
          from_date: moment().format(),
          to_date: moment().format(),
        },
      ])
    )
  }

  _updateDateRangeAttribute = (idx, attr, newVal) => {
    const dateRanges = [...this.props.service.service_date_ranges] //immutable
    dateRanges[idx][attr] = newVal

    this.props.actions.updateServiceAttribute(
      this.props.service.id,
      "service_date_ranges",
      dateRanges
    )
  }

  _removeDateRange = idx => {
    this.props.actions.updateServiceAttribute(
      this.props.service.id,
      "service_date_ranges",
      this.props.service.service_date_ranges.filter((_, i) => i !== idx)
    )
  }

  handleCheckboxChange = e => {
    this.setState({ allowAssociationsEditing: e.target.checked })
  }

  renderServiceDateRangesForm = service => (
    <div>
      <Row>
        <Col xs={12} className="text-center">
          <h5>Service Date Ranges</h5>
          <hr className="grey" />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {service.service_date_ranges &&
            service.service_date_ranges.map((dr, i) => (
              <Row key={i}>
                <Col xs={12}>
                  <Datetime
                      dateFormat="MMMM Do"
                      isClearable
                      timeFormat={null}
                      value={moment(dr.from_date)}
                      className="text-center"
                      inputProps={{placeholder: 'From Date'}}
                      onChange={m =>
                          this._updateDateRangeAttribute(
                              i,
                              "from_date",
                              moment(m).format()
                          )}/>

                  {" "} thru {" "}
                  <Datetime
                      dateFormat="MMMM Do"
                      isClearable
                      timeFormat={null}
                      value={moment(dr.to_date)}
                      className="text-center"
                      inputProps={{placeholder: 'To Date'}}
                      onChange={m =>
                          this._updateDateRangeAttribute(
                              i,
                              "to_date",
                              moment(m).format()
                          )}/>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this._removeDateRange(i)
                    }}
                  >
                    {" "}
                    <span className="text-danger">
                      <Glyphicon glyph="remove-sign" />
                    </span>
                    {" "}
                  </a>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          <Button bsStyle="info" onClick={this._onAddBlankDateRange}>
            Add Date Range
          </Button>
        </Col>
      </Row>
    </div>
  )

  renderServiceAssociationsForm = service => (
    <Row>
      <Col xs={12} className="text-center">
        <h5>Service Associations</h5>
        <label>
          <input
            type="checkbox"
            value={this.state.allowAssociationsEditing}
            onChange={this.handleCheckboxChange}
          />
          {" "}
          Allow Editing
        </label>
        <hr className="grey" />

        <Row>
          <Col xs={4}>
            {this._renderAssociationMultiSelect(
              "Consumables",
              service.service_consumables,
              this.props.consumablesList,
              this._updateServiceAssociationAttribute,
              "service_consumables"
            )}
          </Col>

          <Col xs={4}>
            {this._renderAssociationMultiSelect(
              "Equipment",
              service.service_equipment,
              this.props.equipmentList,
              this._updateServiceAssociationAttribute,
              "service_equipment"
            )}
          </Col>

          <Col xs={4}>
            {this._renderAssociationMultiSelect(
              "Trucks",
              service.service_trucks,
              this.props.trucksList,
              this._updateServiceAssociationAttribute,
              "service_trucks"
            )}
          </Col>

        </Row>
        <p>{" "}</p>
        <Row>
          <Col xs={4}>
            {this._renderAssociationMultiSelect(
              "App Site",
              service.service_application_sites,
              this.props.applicationSitesList,
              this._updateServiceAssociationAttribute,
              "service_application_sites"
            )}
          </Col>

          <Col xs={4}>
            {this._renderAssociationMultiSelect(
              "App Type",
              service.service_application_types,
              this.props.applicationTypesList,
              this._updateServiceAssociationAttribute,
              "service_application_types"
            )}
          </Col>

          <Col xs={4}>
            {this._renderAssociationMultiSelect(
              "Relevant Plants",
              service.service_relevant_plants,
              this.props.plantList,
              this._updateServiceAssociationAttribute,
              "service_relevant_plants"
            )}
          </Col>
        </Row>

      </Col>

    </Row>
  )

  renderServiceEditor = service => (
    <form onSubmit={e => this.submitForm(e, service.id)}>
      <Row>
        <Col xs={10}>
          <Row>
            <Col xs={8}>
              {this.renderTextField(service, "name")}
            </Col>

            <Col xs={4}>

              <FormGroup bsSize="sm">
                <ControlLabel>Service Type</ControlLabel>
                <FormControl
                  componentClass="select"
                  onChange={e =>
                    this.props.actions.updateServiceAttribute(
                      service.id,
                      "service_type_id",
                      e.target.value
                    )}
                  placeholder="Select Service Type"
                  value={service.service_type_id}
                >
                  <option key={0} value={0}>Select Service Type</option>
                  {this.props.serviceTypesList.map(e => {
                    return <option key={e.id} value={e.id}>{e.name}</option>
                  })}
                </FormControl>
              </FormGroup>

            </Col>

          </Row>

          <Row>
            <Col xs={12}>
              {
                <FormGroup bsSize="sm">
                  <ControlLabel>Description</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Description"
                    value={service.skills}
                    rows={5}
                    onChange={e =>
                      this.props.actions.updateServiceAttribute(
                        service.id,
                        "skills",
                        e.target.value
                      )}
                  />
                </FormGroup>
              }
              {this.renderRemovalCheckbox(service, 'removal_service')}
            </Col>

          </Row>

        </Col>
        <Col xs={2}>
          <FormGroup bsSize="sm">
            <ControlLabel>Number of Employees</ControlLabel>
            <FormControl
              type="text"
              placeholder="# Emp"
              value={service.employee_number || "1"}
              onChange={e =>
                this.props.actions.updateServiceAttribute(
                  service.id,
                  "employee_number",
                  e.target.value
                )}
              disabled={service.id > 0}
            />
          </FormGroup>
          <br />

          <FormGroup bsSize="sm">
            <ControlLabel>Estimated time per employee (hr)</ControlLabel>
            <FormControl
              type="text"
              placeholder="Est. Time"
              value={service.estimated_total_time || "1"}
              onChange={e =>
                this.props.actions.updateServiceAttribute(
                  service.id,
                  "estimated_total_time",
                  e.target.value
                )}
            />
          </FormGroup>

          <br />
          <FormGroup bsSize="sm">
            <ControlLabel>Total Time</ControlLabel>
            <FormControl
              type="text"
              placeholder="Total Time"
              value={service.estimated_total_time * service.employee_number}
              disabled
            />
          </FormGroup>
          <br />
          <FormGroup bsSize="sm">
            <ControlLabel>Rate</ControlLabel>
            <FormControl
              type="text"
              placeholder="Rate"
              value={service.rate || "0"}
              onChange={e =>
                this.props.actions.updateServiceAttribute(
                  service.id,
                  "rate",
                  e.target.value
                )}
            />
          </FormGroup>
          <br />
        </Col>

      </Row>

      {service.id > 0 && this.renderServiceAssociationsForm(service)}

      <br /> <br />

      {service.id > 0 && this.renderServiceDateRangesForm(service)}

      <br /> <br />

      <Row>
        <Col xs={12}>
          <Button
            bsStyle="danger"
            onClick={e => this.deleteService(service.id)}
            disabled={service.ps_id === null ? false : true }
          >
            Delete Service
          </Button>
          {service.id === 0
            ? <Button bsStyle="info" className="pull-right" type="submit">
                Save New Service
              </Button>
            : <Button bsStyle="success" className="pull-right" type="submit">
                Save Changes
              </Button>}
        </Col>
      </Row>

      <p>&nbsp;</p>

      <Row>
        <Col xs={12}>
          <p>
            Last updated
            {" "}
            {service.modified_at ? moment(service.modified_at).format("llll") : <i>Unknown</i>}
            {" "}
            by
            {" "}
            {service.modified_by}
          </p>
        </Col>

      </Row>
    </form>
  )

  render() {
    const { serviceList, service, editingServiceId, filterText } = this.props
    return (
      <Grid fluid>
        <Helmet title="Services" />
        <PageHeader
          pageName="Services"
          pageDetail="Manage Services."
        />

        <Row>
          <Col xs={12}>
            <Button onClick={e => this.refreshServiceList()} bsStyle="success">
              Refresh Services
            </Button>
            {" "}
            <Button onClick={e => this.addNewService()} bsStyle="info">
              New Service
            </Button>
          </Col>
        </Row>
        <p>&nbsp;</p>

        <Row>
          <Col xs={3}>
            <form>
              <FormGroup>
                <ControlLabel>Filter</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Filter Services"
                  value={filterText}
                  onChange={e => this.changeFilterText(e.target.value)}
                />
              </FormGroup>
            </form>
            <div style={{ height: "600px", overflowY: "scroll" }}>
              <Nav
                bsStyle="pills"
                stacked
                activeKey={editingServiceId}
                onSelect={this.editService}
              >
                {serviceList.map(p => (
                  <NavItem key={p.id} eventKey={p.id}>{p.name}</NavItem>
                ))}

                {filterText.length > 0 &&
                  serviceList.length === 0 &&
                  <p>No Services Match Your Filter</p>}
              </Nav>
            </div>
          </Col>
          <Col xs={9}>
            {service && this.renderServiceEditor(service)}
          </Col>
        </Row>

      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    serviceList: state.services.serviceList.filter(
      p =>
        p.name
          .toLowerCase()
          .indexOf(state.servicesCrud.filterText.toLowerCase()) !== -1
    ),
    serviceListLastUpdated: state.services.serviceListLastUpdated,
    filterText: state.servicesCrud.filterText,
    editingServiceId: state.servicesCrud.editingServiceId,
    service: state.services.services[
      `service_${state.servicesCrud.editingServiceId}`
    ],
    serviceTypesList: state.serviceTypes.serviceTypesList,
    consumablesList: state.consumables.consumablesList,
    equipmentList: state.equipment.equipmentList,
    trucksList: state.trucks.trucksList,
    applicationSitesList: state.applicationSites.applicationSitesList,
    applicationTypesList: state.applicationTypes.applicationTypesList,
    plantList: state.plants.plantList,
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(Actions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage)
