import React from "react";
import MemoizedMarker from "./MemoizedMarker";

const ProposalServiceMarkers = ({
                                    data,
                                    groupedBySite,
                                    route_today_site,
                                    events,
                                    resource,
                                    compareEvents,
                                    flattenPSEvents,
                                    personIdForMonth,
                                    scheduledServices,
                                    handleToggleOpen,
                                    handleToggleClose,
                                    groupBy,
                                    goToProposalStatus,
                                    checkedRoute,
                                    handleToggleRouteRemoveHelper,
                                    onAddToRoute,
                                    locationState,
                                    removePsFromWo,
                                    getColor,
                                    showExclusive,
                                    scheduled,
                                    activeMarkerData,
                                    reload,
                                }) => {


    return Object.keys(data).map((key, i) => {
        let markerNumber = 0;
        let todaySites = [];
        const proposals = [];
        let markerData = data[key].sort((a, b) => new Date(a.date_from) - new Date(b.date_from));
        const hasScheduledEvent = scheduled.some(sId => markerData.some(se => se.id === sId))
        const sites = groupBy(groupedBySite[markerData[0]?.site_id], (c) => c.proposal_no);


        markerData && markerData.length > 0 && groupedBySite[markerData[0]?.site_id].map(a => {
            let item = {};
            item.proposal_no = a.proposal_no;
            item.id = a.id;
            item.site_id = a.site_id;
            proposals.push(item);


            todaySites.push(route_today_site.map(x => x && Object.keys(x)).reduce((arr, b) => arr.concat(b), []))


            if (events && events.length > 0) {
                const sortedEvents = events.sort((a, b) => {
                    if (new Date(a.start).getTime() > new Date(b.start).getTime()) {
                        return 1
                    } else {
                        return -1
                    }
                })

                sortedEvents.forEach(e => {
                    if (e.site_id === a.site_id) {
                        const eventIndex = events.sort(compareEvents).findIndex(e => e.site_id === a.site_id)
                        e.event_no = eventIndex + 1
                        e.proposal_no = a.proposal_no
                        markerNumber = e.event_no;
                    }
                })
            }

        });
        let markerOrderNumber = 0
        let mixedMarker = false
        const scheduledMarkerData = markerData && markerData.length > 0 && markerData.filter(i => i.scheduler_event_id)
        if (scheduledMarkerData && scheduledMarkerData.length > 0 && scheduledMarkerData[0].latitude && scheduledMarkerData[0].scheduler_event_id && (resource.calendar_view_type !== "dayGridMonth" || (scheduledMarkerData[0].person_id && personIdForMonth === scheduledMarkerData[0].person_id) || (scheduledMarkerData[0].person_id && resource.person_id === scheduledMarkerData[0].person_id))) {
            const uniqEvents = [...new Map(flattenPSEvents.map(item => [item['scheduler_event_id'], item])).values()]
            markerOrderNumber = uniqEvents.findIndex(ps => scheduledMarkerData.some(e => ps.scheduler_event_id === e.scheduler_event_id)) + 1
            mixedMarker = flattenPSEvents.some(e => e.mixedMarker && e.site_id === data?.[key]?.[0]?.site_id)

        }
        const onInfoOpen = (callback) => handleToggleOpen(proposals, markerData[0], callback)
        return <MemoizedMarker key={`marker-${data?.[key]?.[0]?.site_id}`}
                               eventType={proposals[0]?.id === null}
                               index={i}
                               hasScheduledEvent={hasScheduledEvent}
                               getColor={getColor}
                               resource={resource}
                               onInfoOpen={onInfoOpen}
                               onInfoClose={handleToggleClose}
                               item={markerData} sites={sites} onInfoLinkClick={goToProposalStatus}
                               checkedRoute={checkedRoute}
                               removePsFromWo={removePsFromWo} showExclusive={showExclusive}
                               locationState={locationState} scheduledServices={scheduledServices}
                               handleToggleRouteRemoveHelper={handleToggleRouteRemoveHelper}
                               onAddToRoute={onAddToRoute}
                               markerOrderNumber={markerOrderNumber}
                               activeMarkerData={activeMarkerData}
                               reload={reload}
                               mixedMarker={mixedMarker}
        />

    })

}
export default ProposalServiceMarkers;



