import {
  FETCH_CUSTOMER_INFO,
  FETCH_SITES,
  FETCH_CONTACTS,
  UPDATE_SITE_FILTER_TEXT,
  UPDATE_CONTACT_FILTER_TEXT,
  FETCH_BILLING_CONTACT,
  UPDATE_CUSTOMER_ATTRIBUTE,
  UPDATE_BILLING_CONTACT_ATTR,
  UPDATE_CUSTOMER_SEARCH,
  SELECT_CUSTOMER,
  FETCH_SEARCHED_CUSTOMERS,
  FETCH_STATE_AND_CITY,
  DELETE_CUSTOMER,
} from "./constants"
import {
  deleteKeyFromObject,
} from '../../../utilities'
const initialState = {
  customerInfo: null,
  billingContact: null,
  sites: null,
  contacts: null,
  siteFilterText: "",
  contactFilterText: "",
  editingContactId: null,
  editingSiteId: null,
  customerSearch: null,
  foundCustomers: null,
  selectedCustomer: null,
  customerLocation: null,
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SITE_FILTER_TEXT:
      return { ...state, siteFilterText: action.siteFilterText }
    case UPDATE_CONTACT_FILTER_TEXT:
      return { ...state, contactFilterText: action.contactFilterText }
    case UPDATE_CUSTOMER_ATTRIBUTE:
    case FETCH_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.customerInfo,
        foundCustomers: [
          { id: action.customerInfo.id, name: action.customerInfo.name },
        ],
      }
    case FETCH_SITES:
      return { ...state, sites: action.sites }
    case FETCH_CONTACTS:
      return { ...state, contacts: action.contacts }

    case UPDATE_BILLING_CONTACT_ATTR:
    case FETCH_BILLING_CONTACT:
      return { ...state, billingContact: action.billingContact }
    case SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.selectedCustomer,
        billingContact: null,
        sites: null,
        contacts: null,
        editingContactId: null,
        editingSiteId: null,
      }
    case UPDATE_CUSTOMER_SEARCH:
      return {
        ...state,
        customerSearch: action.customerSearch,
      }
    case FETCH_SEARCHED_CUSTOMERS:
      return { ...state, foundCustomers: action.foundCustomers }
    case FETCH_STATE_AND_CITY:
      return { ...state, customerInfo: { ...state.customerInfo, state: action.location.state, city: action.location.city } }
    case DELETE_CUSTOMER:
      return {
        ...state,
        sites: deleteKeyFromObject(
          state.customers,
          'customers',
          action.id
        ),
      }
    default:
      return state
  }
}

export default reducer
