import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";

export const searchForCustomer = ({searchText, clientId}, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${clientId}/search_customers`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const transferCustomer = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/transfer_customer`,
        method: 'PUT',
        data: resource
    };

    apiCall("Customer has been transferred", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
export const copyCustomer = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/copy_customer`,
        method: 'PUT',
        data: resource
    };

    apiCall("Customer has been copied", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};