import React from 'react';
import {sortAssetsLabels} from "../../../common/commonHandlers";
import MemoizedAssetSelectRow from "./MemoizedAssetSelectRow";
import PlantName from "../../../components/PlantName";
import {List, AutoSizer} from "react-virtualized";

const assetLabel = (a) => {
    return (
        <span className="overflow-checkbox-label" >
                {a.label + ' - ' + (a.plant_count > 1 ? ' [' + a.plant_count + ']' : "")}
            <PlantName plant={a.plant_name}/>
            </span>
    )
}

const AssetsList = ({searchString, selectedId, assets, site, assetClick, checkPolygonsCoords, setAssetChecked, setState}) => {
    const listAssets = assets.sort((a, b) => sortAssetsLabels(a, b, 'label'));

    const rowRenderer = ({index, key, style}) => {
        const asset = listAssets[index];

        return <MemoizedAssetSelectRow
            searchString={searchString}
            everyOther={index % 2 === 0}
            style={style}
            key={key}
            selected={asset.selected}
            checked={asset.checked}
            asset={asset}
            site={site}
            assetClick={c => assetClick(c)}
            assetLabel={l => assetLabel(l)}
            checkPolygonsCoords={c => checkPolygonsCoords(c)}
            setAssetChecked={(asset) => setAssetChecked(asset)}
            setState={s => setState(s)}
            listAssets={listAssets}
        />
    }

    return <div className="assets top10" style={{height: "100%", minHeight: "300px"}}>
        <AutoSizer>
        {({width, height}) => (
            <List
                height={height}
                rowCount={listAssets.length}
                scrollToAlignment={"start"}
                rowHeight={50}
                scrollToIndex={selectedId ? listAssets.findIndex(l => l.id === selectedId) : null}
                rowRenderer={rowRenderer}
                width={width}
            />)}
        </AutoSizer>
    </div>
}

export default AssetsList;