import React, {useState, useEffect, useRef} from 'react'
import Draggable from 'react-draggable';
import {connect} from "react-redux";
import * as recorderActions from './actions';
import html2canvas from "html2canvas";
import RecordRTC from 'recordrtc'
import {bindActionCreators} from "redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showIssueDialog} from "../../AppWrapper/contents/actions";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {faPlay, faPause, faTimes, faCheck} from "@fortawesome/free-solid-svg-icons";
import './Issues.css'


const actions = {...recorderActions, showIssueDialog}


const RecorderToolbar = ({name, actions, recorder}) => {
    const [isPaused, setIsPaused] = useState(false)
    const {stopRecording, setRecordingVideo, showIssueDialog, uploadFiles, getIssueMedia} = actions
    const recorderRef = useRef(null);

    let stream = null
    let audio = null
    let mixedStream = null

    useEffect(() => {
        handleRecording()
    }, [])

    const setupStream = async () => {
        try {
            if(recorder.current_tab){
                stream = await navigator.mediaDevices.getDisplayMedia({video: true, preferCurrentTab: true})
            }
            else{
                stream = await navigator.mediaDevices.getDisplayMedia({video: true})
            }
            audio = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100
                }
            })
        } catch (err) {
            if (!stream) {
                runIssueDialog(false)
                stopRecording()
            }
        }
    }

    const onStartStream = (type) => {
        recorderRef.current = new RecordRTC(type);
        recorderRef.current.startRecording();
        type.getVideoTracks()[0].addEventListener('ended', () => handleFinish())
        const btn = document.querySelector('.finish-btn')
        if (btn) btn.addEventListener('click', () => {
            type.getVideoTracks()[0].stop()
        })
    }

    const handleRecording = async () => {
        await setupStream()

        if (stream && audio) {
            mixedStream = new MediaStream([...stream.getTracks(), ...audio.getTracks()]);
            onStartStream(mixedStream)
        } else if (stream) {
            onStartStream(stream)
        }
    }

    const getRecordedMedia = (id) => {
        getIssueMedia(id, video => {
            setRecordingVideo(video.content);
        })
    }

    const handleFinish = () => {
        recorderRef.current.stopRecording(() => {
            const file = recorderRef.current.getBlob()
            uploadFiles(file, ({id}) => {
                getRecordedMedia(id)
            })
            recorderRef.current.destroy()
            runIssueDialog(false)
            stopRecording()
        });
    }

    const handlePause = () => {
        setIsPaused(true)
        recorderRef.current.pauseRecording(() => setIsPaused(recorderRef.current.getState()))
    };

    const handleResume = () => {
        setIsPaused(false)
        recorderRef.current.resumeRecording();
    }

    const handleReset = () => {
        recorderRef.current.reset();
        stopRecording()
        runIssueDialog(false)
    }

    const excludeScreenElements = () => {
        const classNames = ['.gm-control-active', '.gm-svpc', '.gmnoprint', '#recorder-toolbar']

        classNames.map(className => {
            const elements = document.querySelectorAll(className)
            return [...elements].map(element => element.setAttribute('data-html2canvas-ignore', 'true'))
        })
    }

    const runIssueDialog = (setFile = true) => {
        excludeScreenElements()

        if (setFile) {
            html2canvas(document.body, {
                useCORS: true, onclone: document => {
                    const pagination = document.querySelector('.react-bs-table-pagination')
                    if (pagination) pagination.style.bottom = '-30px'
                }
            }).then(canvas => {
                canvas.toBlob((blob) => {
                    URL.createObjectURL(blob);
                    const file = new File([blob], 'screenshot.png', blob)
                    showIssueDialog(file);
                });
            });
        } else {
            showIssueDialog(null);
        }
    }

    const setUserInitials = (name) => {
        const splittedName = name.split(" ")
        let initials = 'ME'

        if (splittedName?.length > 1) initials = `${splittedName[0].charAt(0)}${splittedName[1].charAt(0)}`
        return initials
    }

    const renderTooltip = (text) => (
        <Tooltip id="tooltip">
            <span>{text}</span>
        </Tooltip>
    );

    const renderBarButton = (icon, text, onClick, className) => (
        <OverlayTrigger placement='top' overlay={renderTooltip(text)}>
            <Button className={`recorder-btn ${className}-btn`} onClick={onClick}>
                <FontAwesomeIcon icon={icon} className="mr-9 font16"/>
            </Button>
        </OverlayTrigger>
    )

    const finishDescription = 'Finish Recording'
    const cancelDescription = 'Cancel Recording'
    const resumeDescription = isPaused ? 'Resume Recording' : 'Pause Recording'

    const resumeIcon = isPaused ? faPlay : faPause
    const resumeFunc = isPaused ? handleResume : handlePause

    return (
        <Draggable bounds="body">
            <div id='recorder-toolbar'>
                <div className='recorder-toolkit-wrapper'>
                    <div className='recorder-user-avatar'>{setUserInitials(name)}</div>
                    <div className='recorder-tools'>
                        {renderBarButton(faTimes, cancelDescription, handleReset, 'reset')}
                        {renderBarButton(resumeIcon, resumeDescription, resumeFunc, 'resume')}
                        {renderBarButton(faCheck, finishDescription, handleFinish, 'finish')}
                    </div>
                </div>
            </div>
        </Draggable>
    )
}

const mapStateToProps = state => ({
    name: state.auth.name,
    recorder: state.recorder
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RecorderToolbar)