import {
    CLIENT_LIST_LOADED,
    EDIT_CLIENT_FILTER_TEXT,
    EDIT_USER_FILTER_TEXT,
    ADD_NEW_CLIENT,
    EDITING_CLIENT_ID,
    FETCH_CLIENT,
    FETCH_USERS,
    FETCH_EMPLOYEES,
    FETCH_ROLES,
    SAVED_USER_INFO,
    FETCH_CUSTOMER_INFO,
    USER_ACTIVITIES_LOADED,
    USER_ISSUES_LOADED,
    EDIT_USER_LIST_FILTER_TEXT
} from './constants'

import {insertKeyIntoObject} from '../../utilities'
//TODO: add this reducer to reducers

const initialState = {
    clientsList: [],
    filterText: '',
    filterUserText: '',
    filterUserListText: '',
    clients: {},
    clientsListLastUpdated: 0,
    editingClientlId: null,
    activityList: {activities: [], count: 0},
    issueList: []
};

const newClient = () => {
    return {
        id: 0,
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone_number: '',
        email_address: '',
        web_url: ''
    }
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT_LIST_LOADED:
            return {...state, clientsList: action.clientsList};
        case EDIT_CLIENT_FILTER_TEXT:
            return {...state, filterText: action.filterText};
        case EDIT_USER_FILTER_TEXT:
            return {...state, filterUserText: action.filterUserText};
        case ADD_NEW_CLIENT:
            return {
                ...state,
                userInfo: null,
                clients: insertKeyIntoObject(
                    state.clients,
                    'clients',
                    newClient()
                ),
            };
        case EDIT_USER_LIST_FILTER_TEXT:
            return {...state, filterUserListText: action.filterUserListText};
        case EDITING_CLIENT_ID:
            return {...state, editingClientId: action.editingClientId};
        case FETCH_CLIENT:
            return {
                ...state,
                clients: insertKeyIntoObject(
                    state.clients,
                    'clients',
                    action.clients
                ),
            };
        case FETCH_USERS:
            return {
                ...state,
                userInfo: action.userInfo
            };
        case FETCH_EMPLOYEES:
            return {
                ...state,
                employees: action.employees,
            };
        case FETCH_ROLES:
            return {
                ...state,
                roles: action.roles,
            };
        case SAVED_USER_INFO:
            return {...state, newUserId: action.id};
        case FETCH_CUSTOMER_INFO:
            return {
                ...state,
                customerInfo: action.customerInfo,
            };
        case USER_ACTIVITIES_LOADED:
            return {...state, activityList: action.activities};
        case USER_ISSUES_LOADED:
            return {...state, issueList: action.issues};
        default:
            return state
    }
}

