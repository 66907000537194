import { addAlert } from "../App/actions"
import axios from "axios"
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import * as FileSaver from "file-saver";
import {dataUpload} from "../../common/upload";

export const fetchServices = (from, to, onlyCompleted, filter ,page, per_page, callback) => async (
    dispatch,
    getState
) => {
  dispatch(
      addAlert({
        message: "This might take a minute or two.",
        mood: "info",
        dismissAfter: 1500,
      })
  )
  const store = getState()

  const config = {
    url: `/api/proposal_services/completed_services`,
    headers: {Authorization: store.auth.token},
    params: {
      page: page,
      per_page: per_page,
      start_date: from,
      end_date: to,
      only_completed: onlyCompleted, // completed status
      filter: filter
    },
  }

  apiCall("Rendering proposal email", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState, false);

}
export const reloadServices = (ps_ids, filter, from, to, callback) => (dispatch, getState) => {
  const config = {
    url: `/api/proposal_services/reload_services`,
    method: 'POST',
    data: {
      ps_ids: ps_ids,
      filter: filter,
      start_date: from,
      end_date: to,
    }
  };
  apiCall("Reload Services", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data);
  }, dispatch, getState, false);
};


export const createInvoices = (psIds, resource, from, to, callback) => async (dispatch, getState) => {
  const store = getState()
  const config = {
    method: 'POST',
    url: '/api/invoices/create_batch',
    headers: { 'Authorization': store.auth.token },
    data: {
      psid: psIds.join(','),
      start_date: from,
      end_date: to,
      ...resource
    }
  }

  apiCall("Creating new invoice", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState, false);
}

export const downloadPdf = (invoice, callback, errorCallback) => async (dispatch, getState) => {
  let config = {
    url: `/api/restricted/${invoice.token}/invoice_pdf`,
    method: 'GET',
    responseType: 'blob'
  };
  config = addAuthorizationHeader(config, getState);
  config = addTimeZoneHeader(config, getState);
  try {
    const response = await axios.request(config);
    const blob = new Blob([response.data], {
      type: 'application/pdf',
    });
    const fileName = `${invoice.client_name}-Invoice #${invoice.number}.pdf`
    blob.name = fileName
    dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(invoice ? invoice : {}), source: "invoice pdf"}, false)(dispatch, getState)
    FileSaver.saveAs(blob, fileName);
    callback && callback()
  } catch (exception) {
    dispatch(
        addAlert({
          message: "Unable to download pdf" + exception.toString(),
        })
    );
    errorCallback && errorCallback()
    throw exception
  }
};

export const loadTermTypes = (callback) => async (dispatch, getState) => {
  const config = {
    url: `/api/term_types`
  };

  apiCall("Loading data", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState, false);
};
export const loadEmployees = (callback) => async (dispatch, getState) => {
  const config = {
    url: `/api/search/all_employees?disabled=false`
  };

  apiCall("Loading data", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState, false);
};

export const getInvoiceSettings = (callback) => async (dispatch, getState) => {
  const config = {
    url: `/api/users/get_invoice_settings`
  };

  apiCall("Getting dashboard settings", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState, false);
};

export const saveInvoiceSettings = (resource, callback) => (dispatch, getState) => {
  const config = {
    url: `/api/users/update_invoice_settings`,
    method: 'PUT',
    data: {resource: resource}
  };
  apiCall("Saving dashboard settings", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data);
  }, dispatch, getState, false);
};

export const sendBulkEmail = (recipients, email, callback) => async (dispatch, getState) => {
  const config = {
    url: `/api/emails/send_bulk_email`,
    method: 'POST',
    data: {email: {...email, recipients}}
  };

  apiCall("Email sending", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState);
};

export const sendBulkNotification = (email, recipients,emailType, emailTemplateId, callback) => async (dispatch, getState) => {
  const config = {
    url: `/api/twilio/send_notification`,
    method: 'POST',
    data: {email: email, recipients: recipients, email_type: emailType, email_template_id: emailTemplateId}
  };

  apiCall("Notification sent", config, async config => {
    const response = await axios.request(config);
    callback && callback(response);
  }, dispatch, getState);
};

export const updateCompletedDate = (id, completed_date, callback) => async (dispatch, getState) => {
  const config = {
    url: `/api/proposal_services/${id}/update_work_order_proposal_service`,
    method: 'PUT',
    data: {completed_at: completed_date}
  };

  apiCall("Completed date updated", config, async config => {
    const response = await axios.request(config);
    callback && callback(response);
  }, dispatch, getState, false);
};

export const addPayment = (token, partialAmount, paymentDate, callback) => (dispatch, getState) => {
  const config = {
    url: `/api/payments/add`,
    method: 'POST',
    data: {
      token: token,
      partial_amount: partialAmount,
      payment_date: paymentDate
    }
  };
  apiCall("Creating payment", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState, false);
};

export const removePayment = (payment_id, callback) => (dispatch, getState) => {
  const config = {
    url: `/api/payments/remove_payment`,
    method: 'DELETE',
    params: {
      id: payment_id
    }
  };

  apiCall("Removing payment", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState);
};

export const updatePayment = (payment, callback) => async (dispatch, getState) => {
  const config = {
    method: "put",
    url: `/api/payments/update_amount`,
    data: {
      id: payment.id,
      amount: payment.amount
    },
  };

  apiCall("Updated payment amount", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState);
};
export const refund = (charge_id, callback) => (dispatch, getState) => {
  const config = {
    url: `/api/payments/refund`,
    method: 'PUT',
    data: {
      charge_id: charge_id
    }
  };
  apiCall("Refunding", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState, false);
};
export const updateInvoice = (id, invoice, callback) => async (dispatch, getState) => {
  const config = {
    method: "put",
    url: `/api/proposal_invoices/update_invoice?invoice_id=${id}`,
    data: {
      invoice
    },
  };

  apiCall("Updating invoice", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data);
  }, dispatch, getState);
};

export const updateProposalService = (ps_id, invoice_notes) => async (dispatch, getState) => {
  const config = {
    method: "put",
    url: `/api/proposal_services/${ps_id}`,
    data: {
      proposal_service: {
        id: ps_id,
        invoice_notes: invoice_notes
      }
    },
  };

  apiCall("Updated proposal service", config, async config => {
    const response = await axios.request(config);
  }, dispatch, getState, false);
};

export const removeInvoiceProposalService = (invoice_service, callback) => (dispatch, getState) => {
  const config = {
    url: `/api/proposal_invoices/remove_invoice_proposal_service/`,
    params: invoice_service,
    method: 'DELETE'
  };

  apiCall("Removing service from invoice", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState);
};

export const sendEmail = (email, callback, errorCallback) => async (dispatch, getState) => {
  const config = {
    url: `/api/emails/send_email`,
    method: 'POST',
    data: {email}
  };

  apiCall("Email sending", config, async config => {
    const response = await axios.request(config);
    callback(response);
  }, dispatch, getState, true, errorCallback);
};