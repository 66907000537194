import {Modal} from "react-bootstrap";
import React, {Component} from 'react';
import WorkOrdersFilter from "./WorkOrdersFilter";
import DraggableModalDialog from "./DraggableModalDialog";
import './WorkOrdersFilterModal.css';


class WorkOrdersFilterModal extends Component {

    render() {
        return (
            <Modal
                animation={false}
                id="work_order_modal"
                show={this.props.show}
                onHide={this.props.onHide}
                className={"maxHeight90"}
                backdrop={false}
                dialogComponentClass={DraggableModalDialog}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Calendar Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WorkOrdersFilter
                        onChange={this.props.onChange}
                        filter={this.props.filter}
                        workOrderSearchResults={this.props.workOrderSearchResults}
                        onWoSearch={() => this.props.onWoSearch()}
                        customerSearchResults={this.props.customerSearchResults}
                        onCustomerSearch={() => this.props.onCustomerSearch()}
                        citySearchResults={this.props.citySearchResults}
                        onCitySearch={() => this.props.onCitySearch()}
                        zipSearchResults={this.props.zipSearchResults}
                        onZipSearch={() => this.props.onZipSearch()}
                        disabled={!this.props.workOrdersLoaded}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" onClick={this.props.closeButton} className="btn btn-default"
                            data-dismiss="modal">Close
                    </button>
                    <button type="button" onClick={this.props.onHide} className="btn btn-success"
                            disabled={!this.props.workOrdersLoaded}>Save
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default WorkOrdersFilterModal;
