import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";

export const getCustomerFeedbacks = (page, per_page, filters, sort, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/new_customers/customer_feedbacks`,
        params: {page, per_page, ...filters, ...sort}
    }

    apiCall("Customer feedbacks load successfully", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};

export const saveClientSettings = (client, callback) => (dispatch, getState) => {
    let config = {
        url: `/api/clients/${client?.id}`,
        method: 'PUT',
        data: client
    };

    apiCall("Saving client", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForInvoices = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/invoices/search_invoices`,
        params: {q: searchText}
    };

    apiCall("Searching for invoices", config, async config => {
        const response = await axios.request(config);
        callback(response?.data?.map(x => ({value: x.id, label: x.number})));
    }, dispatch, getState, false);
};