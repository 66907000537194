import React, {useState} from "react";
import {Button, Col, FormControl, Modal, Row} from "react-bootstrap";

const EditTabModal = ({onHide, onSave, resource}) => {
    const [data, setData] = useState(resource?.title ? resource?.title : "")

    const onChange = (e) => {
        setData(e.target.value)
    }

    return <Modal
        show={true}
        animation={true}
        className={"heightAuto maxHeight90 fontIOS"}
        id='edit-tab-modal'
        onHide={() => onHide()}
        backdrop="static"
    >
        <Modal.Header>
            <Modal.Title>Edit Tab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12} className="paddingBottom10">
                    <h4>Title:</h4>
                    <FormControl
                        name="title"
                        placeholder="Title"
                        onChange={e => onChange(e)}
                        value={data}
                    />
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col md={6} className="text-left">
                    <Button
                        bsStyle="danger"
                        bsSize="small"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                </Col>
                <Col md={6} className="text-right">
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => onSave(data)}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default EditTabModal;