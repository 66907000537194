import {FETCH_CONSUMABLES_LIST} from '../constants'

const initialState = {
    consumablesList: [],
    consumables: {},
    consumablesListLastUpdated: 0,
    editingConsumableId: null,
    filterText: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONSUMABLES_LIST:
            return {
                ...state,
                consumablesList: action.consumablesList,
                consumablesListLastUpdated: action.consumablesListLastUpdated,
            };
        default:
            return state
    }
}
