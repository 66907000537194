import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Col, Glyphicon, Row} from "react-bootstrap";

const screen32 = window.innerWidth >= 1024 && window.innerHeight <= 600;
const isMobile = window.innerWidth <= 768;

class InlineEditable extends Component {
    state = {
        edit: false
    };

    constructor(props, context) {
        super(props, context);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.editMessage !== this.props.editMessage) && this.props.editMessage) {
            this.setState({edit: this.props.editMessage})
        }
    }

    handleSubmit = () => {
        this.props.handleSubmit && this.props.handleSubmit();
        this.setState({edit: false})
    };

    render() {
        const {edit} = this.state;
        const {value, form, icons, withSubmit, editable, className, color, toggleTitleInput, fullWidth, closeEditable, breakWord, onClick} = this.props;
        let width = withSubmit ? 10 : 12;
        if (fullWidth) {
            width = 12
        }
        return (
            <span className={className}>
                    <Row className="vcenter" style={{marginBottom: screen32 && 0, wordBreak: breakWord && 'break-word'}}>
                        <Col xs={icons ? 6 : width} style={{paddingRight: !isMobile && !fullWidth && 0}}>
                            {!edit && <span className={editable ? 'underline' : ''}
                                            onClick={() => {editable && this.setState({edit: true}, () => toggleTitleInput && toggleTitleInput(true)); onClick && onClick()}}>{value}</span>}
                            {edit && form(this.handleSubmit)}
                        </Col>
                        {icons && <Col xs={4} style={{paddingLeft: !isMobile && 0}}>{icons}</Col>}
                        {!fullWidth && withSubmit &&
                        <Col style={{color: color && true && color.includes('#') && color}} xs={1}>
                            {edit && <Glyphicon glyph="ok" className="pointer" onClick={() => {
                                this.handleSubmit()
                                toggleTitleInput && toggleTitleInput(false)
                                closeEditable && closeEditable(false)
                            }}
                            />}
                        </Col>}
                        {fullWidth && edit && <Col style={{color: color && true && color.includes('#') && color}} xs={1}>
                            <Glyphicon glyph="ok" className="pointer" onClick={() => {
                                this.handleSubmit()
                                toggleTitleInput && toggleTitleInput(false)
                            }}
                            />
                        </Col>}
                    </Row>
            </span>
        );
    }
}

InlineEditable.propTypes = {
    form: PropTypes.object,
    value: PropTypes.object,
    icons: PropTypes.object,
    editable: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func,
    withSubmit: PropTypes.bool
};

InlineEditable.defaultProps = {
    editable: true,
    withSubmit: true
};

export default InlineEditable;
