import axios from "axios"
import {apiCall} from "../../common/apiActionHandler";

export const getArborists = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/search/all_employees?disabled=true`
    }

    apiCall("Loading data", config, async config => {
        const response = await axios.request(config);
        callback({salesArborists: response.data});
    }, dispatch, getState, false);
};

export const fetchMyProposals = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/my_accepted`,
        params: filter
    };

    apiCall("Getting my accepted proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
