import React from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {browserHistory, Link} from "react-router"
import {
    Alert,
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup, Glyphicon,
    Grid,
    Nav,
    NavItem,
    OverlayTrigger,
    Row,
    Tooltip,
    Well,
} from "react-bootstrap"
import Helmet from "react-helmet"
import Select from "react-select"
import * as Actions from "./actions"
import ContactEditorModal from "../../ContactEditorModal"
import SiteEditorModal from "../../SiteEditorModal"
import CustomerHeader from "../../../components/CustomerHeader"
import moment from "moment-timezone"
import {defaultDateTimeFormat, select} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import {debounce} from "throttle-debounce";
import quickbooks from './quickbooks.png';
import SchedulerEventDialog from "../../Scheduler/SchedulerEventDialog";
import DateRangeFilter from "../../../components/DateRangeFilter";
import {GoogleMapLoader} from "../../../components/GoogleMapLoader";
import {GoogleMap, Marker} from "react-google-maps";
import {getColorCircleIcon} from "../../MapViewPage/IconProvider";
import './CustomerInfo.scss'
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDND} from "./constants";
import InfoModal from "../../../common/InfoModal";
import Dollars from "../../../components/Dollars";
import CustomerSalesChart from "./CustomerSalesChart";
import CustomerTimeline from "./CustomerTimeline";
import SendCredentialsModal from "./SendCredentialsModal/SendCredentialsModal";
import DuplicatesModal from "./DuplicatesModal";

const isMobile = window.screen.width <= 1024;

const SmallWell = ({title, className, children}) => (
    <Well className={className} bsSize="small">
        <Row>
            <Col xs={12} className="text-center"><h4>{title}</h4></Col>
        </Row>
        {children}
    </Well>
)

class CustomerInfoPage extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: {
                show_company_name: false,
                name: "New Customer",
                create_site_from_customer: true,
                country: "United States",
                city: '',
                state: '',
                files: [],
                customerSales: null,
            },
            employees: [],
            referrals: [],
            customerTypes: [],
            events: [],
            editingEventId: null,
            dateFilter: {from: moment().startOf('week').utc().format(), to: moment().endOf('week').utc().format()},
            showMoreEvents: true,
            filteredCustomerSites: [],
            infoModalOpen: false,
            chart: {
                show: false,
                series: [],
                labels: []
            },
            showDuplicatesModal: false,
            sendCredentialsModal: false,
            contactSelectBlocked: false
        };
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.delayedUpdateZip = debounce(1500, this.updateZip);
    }

    componentDidMount = () => {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        let {resource, dateFilter} = this.state;
        if(this.props.location?.query?.site_id){
            this.setState({site_from_query: +this.props.location?.query?.site_id})
        }
        this.props.actions.load(result => {
            this.setState(result);
            if (this.props.params && this.props.params.id) {
                resource.id = this.props.params.id;
                this.setState(resource, this.reload)
            }
        });
        this.loadCustomerEvents(this.props.params.id, dateFilter)
    }

    reload = () => {
        this.props.actions.loadCustomerInfo(this.state.resource.id, (result) => {
            this.setState({resource: result}, () => browserHistory.push(`/customer/info/${result.id}`))
        });
        this._loadCustomerInfo(this.state.resource.id)
        this.loadCustomerEvents(this.state.resource.id, this.state.dateFilter)
        this.props.actions.fetchCustomerSalesData(
            this.state.resource.id
            , (data) => {
                this.setState({
                        customerSales: data
                    },
                    () => {
                        this.setState({
                            chart: {
                                show: true,
                                series: [+this.state.customerSales.closedCount, +this.state.customerSales.openCount],
                                labels: ["Closed", "Open"]
                            }
                        })
                    })
            })
    }

    shouldLockBilling() {
        const {editingContactId, contacts, billingContact} = this.props
        const billingContactTypeId = billingContact?.contact_type_id

        let billingsCount = 0
        let currentBilling = null

        for (var key in contacts) {
            if (contacts[key].contact_type_id === billingContactTypeId) billingsCount += 1
            if (contacts[key].id === editingContactId) currentBilling = contacts[key]
        }

        if (currentBilling === null) {
            return ""
        } else {
            return currentBilling.contact_type_id === billingContactTypeId && billingsCount === 1
        }
    }

    deleteCustomer = (customerId) => {
        const action_confirmed = window.confirm('Are your sure? This cannot be reversed!')

        if (action_confirmed) {
            this.props.actions.deleteCustomer(customerId)
        }
    }

    uploadAttachment = (attachment) => {
        const {resource} = this.state;
        this.props.actions.uploadAttachment(attachment, resource.id, newFile => {
            newFile.order = resource.files.length + newFile.id
            this.setState({resource: {...resource, files: [...resource.files, newFile]}});
        })
    };

    deleteAttachment = (id) => {
        const {resource} = this.state;
        this.props.actions.deleteAttachment(id, () => {
            const updatedFiles = resource.files.filter(file => file.id !== id)
            this.setState({resource: {...resource, files: updatedFiles}});
        })
    }

    reorderAttachments = (orderedFiles) => {
        const order = JSON.stringify(orderedFiles.map((image) => image.id))
        this.props.actions.reorderAttachment(order, this.state.resource.id,
            () => {
                this.setState({files: orderedFiles})
            })
    };

    selectAttachment = (id) => {
        const {resource} = this.state;
        this.props.actions.selectAttachmentForPrint(id, () => {
            const updatedFiles = resource.files.map(file => {
                if (file.id === id) {
                    file.selected_for_proposal = !file.selected_for_proposal
                }
                return file
            })
            this.setState({files: updatedFiles});
        })
    }

    render() {
        const {
            editingContactId,
            editingSiteId,
            contacts
        } = this.props
        const {resource, editingEventId, events, dateFilter, customerSales, sendCredentialsModal,showDuplicatesModal} = this.state;
        const customerOptions = this.customerSearch.searchOptions();
        if (resource.first_name && resource.last_name) {
            customerOptions.push({
                value: resource.id,
                label: resource.show_company_name ? resource.company_name : `${resource.last_name}, ${resource.first_name}`
            })
        }

        const customerContacts = contacts && contacts.sort(function(x,y){ return x.contact_type == "Billing" ? -1 : y.contact_type == "Billing" ? 1 : 0; });

        return (
            <Grid fluid id="customer-info">
                <Helmet title="Customer Information"/>

                {this._renderHeader(resource, customerOptions)}
                {resource && resource.past_due ?
                    <h3 className="text-center" style={{color: 'red'}}>Past Due Invoices</h3> : null}
                <div className="custom-grid-container">
                    <div>

                            {this._renderBillingContact()}

                            {this._renderBillingAddress()}

                            {this._renderCustomerInfo()}

                    </div>
                    <div>
                        {resource.id ? this._renderContactsList() : null}
                        {resource.id ? this._renderSitesList() : null}


                    </div>
                    <div>
                        {this._renderScheduledEvents()}
                        {this._renderLastEdit()}
                    </div>
                    <div>
                        {this.renderCustomerSales(customerSales)}
                        {this.renderCustomerSiteMap()}
                    </div>
                </div>

                {editingContactId !== null
                    ? <ContactEditorModal
                        onSuccess={() => this.reload()}
                        customerId={resource.id}
                        lockBilling={this.shouldLockBilling()}
                    />
                    : null}

                {editingSiteId !== null
                    ? <SiteEditorModal
                        onSuccess={() => this.reload()}
                        getProposalsForInactive={this.props.actions.getProposalsForInactive}
                    />
                    : null}

                {editingEventId !== null
                    ? <SchedulerEventDialog
                        handleClose={() => {
                            this.loadCustomerEvents(this.props.params.id, dateFilter)
                            this.setState({editingEventId: null})
                        }}
                        event={this.getSelectedEvent(editingEventId)}
                    />
                    : null}
                {showDuplicatesModal &&
                    <DuplicatesModal
                        customer={resource}
                        findDuplicates={this.props.actions.showDuplicates}
                        onHide={() => this.setState({showDuplicatesModal: false})}
                        redirectFunction={() => browserHistory.push('/customer_search')}
                        mergeCustomer={this.props.actions.mergeCustomer}
                    />
                }
                {sendCredentialsModal &&
                    <SendCredentialsModal
                        sendCredentials={this.props.actions.sendCredentials}
                        showCredentialsModal={sendCredentialsModal}
                        hideCredentialsModal={() => this.setState({sendCredentialsModal: false})}
                        customerContacts={customerContacts}
                        editContact={this._editContact}
                        editingContactId={editingContactId}
                    />}

                {showDuplicatesModal &&
                    <DuplicatesModal
                        customer={resource}
                        findDuplicates={this.props.actions.showDuplicates}
                        onHide={() => this.setState({showDuplicatesModal: false})}
                    />
                }
                {this.state.infoModalOpen && resource.inactive &&
                    <InfoModal
                        isOpen={true}
                        closeModal={() => this.setState({infoModalOpen: false})}
                        title={'Warning'}
                        getProposals={this.props.actions.getProposalsForInactive}
                        searchId={{customer_id: this.state.resource?.id}}
                        text={<p>If you save a Customer with the Inactive option checked, all Open Proposals assigned to that customer will be marked as Declined.</p>}
                    />}
            </Grid>
        )
    }

    getSelectedEvent = (id) => {
        const {events} = this.state;
        const {event} = events.filter(e => e.event.id === id)[0]
        return {id: event.id, scheduler_event_resource_id: event.scheduler_event_resource_id}
    }
    _editSite = selected => {
        this.props.actions.editingSiteId(selected)
    }
    _editEvent = selected => {
        this.setState({editingEventId: selected})
    }

    _updateSiteFilterText = e => {
        this.props.actions.updateSiteFilterText(e.target.value)
    }

    _updateContactFilterText = e => {
        this.props.actions.updateContactFilterText(e.target.value)
    }

    _editContact = selected => {
        this.props.actions.editingContactId(selected)
    }

    _newContact = () => {
        this.props.actions.createNewContact(this.state.resource.id)
    }

    _newSite = () => {
        this.props.actions.createNewSite(this.state.resource.id)
    }

    updateZip = () => {
        const {resource} = this.state;
        this.props.actions.fetchStateAndCityForCustomer(resource.zip, (response) => {
            let {resource} = this.state;
            resource.zip = response.zipcode;
            resource.state = response.state ? response.state : resource.state;
            resource.city = response.city ? response.city : resource.city;
            this.setState(resource);
        })
    }

    blockContactSelect = () => {
        this.setState({contactSelectBlocked: true},()=>{
            setTimeout(function() {
                this.setState({contactSelectBlocked: false})
            }.bind(this), 4000)
        })
    }

    _renderContactsList = () => {
        const {editingContactId, contacts, contactFilterText, billingContact} = this.props

        if ([null, undefined].includes(contacts)) {
            return "No Contacts Loaded..."
        }

        const filteredContacts = this._superFilter(contacts, contactFilterText)
        const tooltipDisabled = (
            <Tooltip id="tooltip">
                <strong>For Billing Contact Use Customer Form To The Left</strong>
            </Tooltip>
        );

        const tooltipActive = (
            <Tooltip id="tooltip">
                <strong>Click Here To Edit!</strong>
            </Tooltip>
        );

        return (
            <Well
                bsSize="small"
                className="middle-column-height contacts"
            >

                <Row className="vertical-align">
                    <Col xs={4} className="reset-right-padding"><h4>{`${contacts.length} Contacts`}</h4></Col>
                    <Col xs={8} className="text-right reset-left-padding">
                        <Button
                            bsStyle="success"
                            bsSize="xsmall"
                            className="customer_button marginSide"
                            disabled={!contacts?.length > 0}
                            onClick={() => this.setState({sendCredentialsModal: true})}>
                            Send Credentials
                        </Button>
                        <Button
                            bsStyle="primary"
                            onClick={this._newContact}
                            bsSize="xsmall"
                            className="customer_button marginSide">
                            New Contact
                        </Button>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12}>
                        <FormGroup bsSize="small">
                            <FormControl
                                onChange={this._updateContactFilterText}
                                placeholder="Filter by person's name, email, phone, or contact type"
                                value={contactFilterText || ""}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="scroll-y container-h308">
                            {filteredContacts.map(c => {
                                return (
                                    <Nav
                                        key={c.id}
                                        bsStyle="pills"
                                        stacked
                                        activeKey={editingContactId}
                                        onSelect={(selected)=> {
                                            if(isMobile){
                                                setTimeout(function() {
                                                    if (billingContact?.id !== c.id && !this.state.contactSelectBlocked) {
                                                        this._editContact(selected)
                                                    }
                                                }.bind(this), 500)
                                            }
                                            else {
                                                if(billingContact?.id !== c.id){
                                                    this._editContact(selected)
                                                }
                                            }

                                        }}
                                    >
                                        <NavItem
                                            key={c.id}
                                            eventKey={c.id}
                                        >
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={billingContact?.id === c.id
                                                    ? tooltipDisabled
                                                    : tooltipActive}
                                            >
                                                <Row
                                                    style={billingContact?.id === c.id
                                                        ? {color: '#28a745'}
                                                        : null}
                                                >
                                                    <Col xs={6}>
                                                        {c.person_name}<br/>
                                                        {c.person_email}
                                                    </Col>
                                                    <Col xs={3}>
                                                        {isMobile ?
                                                            <div onClick={()=>{this.blockContactSelect();
                                                                window.open("tel:"+c.person_phone.replace(/\D/g,''),"_self");}}>
                                                                {c.person_phone}</div>
                                                            : <>{c.person_phone}</>
                                                        }
                                                    </Col>

                                                    <Col xs={3}>
                                                        {c.contact_type}
                                                    </Col>

                                                </Row>
                                            </OverlayTrigger>

                                        </NavItem>

                                        {contactFilterText.length > 0 &&
                                        filteredContacts.length === 0 &&
                                        <p>No Contact Matches Your Filter</p>}
                                    </Nav>
                                )
                            })}
                        </div>
                    </Col>
                </Row>

            </Well>
        )
    }

    _renderSitesList = () => {
        const {editingSiteId, sites, siteFilterText} = this.props
        if (sites && this.state.site_from_query){
            this._editSite(this.state.site_from_query)
            this.setState({site_from_query: null})
        }
        if ([null, undefined].includes(sites)) {
            return "No Sites loaded..."
        }

        const filteredSites = this._superFilter(sites, siteFilterText)

        const tooltipActive = (
            <Tooltip id="tooltip">
                <strong>Click Here To Edit!</strong>
            </Tooltip>
        );

        return (
            <Well
                bsSize="small"
                className="middle-column-height sites"
            >

                <Row>
                    <Col xs={6}><h4>{`${sites.length} Sites`}</h4></Col>
                    <Col xs={6} className="text-right">
                        <Button bsStyle="primary" onClick={this._newSite} bsSize="xsmall">
                            New Site
                        </Button>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12}>
                        <FormGroup bsSize="small">
                            <FormControl
                                onChange={this._updateSiteFilterText}
                                placeholder="Filter by site's name, address, or contact name"
                                value={siteFilterText || ""}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Nav
                            bsStyle="pills"
                            stacked
                            activeKey={editingSiteId}
                            onSelect={this._editSite}
                            className="scroll-y container-h308"
                        >
                            {filteredSites && filteredSites.map(s => (
                                <NavItem key={s.id} eventKey={s.id} style={{backgroundColor: s.inactive ? "#ffebee" : ''}}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipActive}
                                    >
                                        <Row>
                                            <Col xs={6}>
                                                {s.name}<br/>
                                                <span style={{color: "#bbbbbb"}}>
                          {s.site_contact_person_name}
                        </span>
                                            </Col>
                                            <Col className="text-right" xs={6}>
                                                {s.full_address}
                                            </Col>
                                            <span style={{color: "green", paddingLeft: "15px"}}
                                                  className='columnDirection'>
                          <small>Recent Arborist: {s && s.last_site_arborist !== null ? s.last_site_arborist : "Sorry - not, sure"}</small>
                                                <small style={{color: '#777777'}}>Site ID: {s.id}</small>
                      </span>

                                        </Row>
                                    </OverlayTrigger>
                                </NavItem>
                            ))}

                            {siteFilterText.length > 0 &&
                            filteredSites.length === 0 &&
                            <p>No Site Matches Your Filter</p>}
                        </Nav>
                    </Col>
                </Row>

            </Well>
        )
    }

    _superFilter = (list, filterValue) => {
        list = list || []
        if (
            [null, undefined, ""].includes(filterValue) ||
            filterValue.toString().length < 1
        ) {
            // console.info("filterValue is blank. returning unfiltered list")
            return list
        }

        const filtered = list.filter(item => {
            // console.info("Checking item", item)
            return Object.keys(item).some(key => {
                //  console.info("checking key", key)
                return (
                    item[key] &&
                    item[key]
                        .toString()
                        .toLowerCase()
                        .indexOf(filterValue.toLowerCase()) !== -1
                )
            })
        })
        // console.info("Returning filtered list", filtered)
        return filtered
    }

    _renderBillingContact = () => {
        const {contacts} = this.props
        const {resource} = this.state;
        return (
            <SmallWell title="Billing Contact">
                {contacts && contacts.length === 0
                    ? <Alert bsStyle="warning">
                        <p>
                            <em>
                                <strong>Note:</strong>
                                {" "}
                                There are no contacts for this Customer. Please create at least 1 Contact immediately
                                below or via the New Contact button. Here is the Customer's info:
                            </em>
                        </p>
                        <p><strong>First Name:</strong> {resource.first_name}</p>
                        <p><strong>Last Name:</strong> {resource.last_name}</p>
                        <p><strong>Phone No:</strong> {resource.phone_number}</p>
                        <p><strong>Alternate Phone:</strong> {resource.phone_number2}</p>
                        <p><strong>Email:</strong> {resource.email_address}</p>
                        <p><strong>Alternate Email:</strong> {resource.email_address2}</p>
                    </Alert>
                    : null}
                <form>
                    <Row>
                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Company Name
                                </ControlLabel>
                                <FormControl
                                    name="company_name"
                                    placeholder="Company Name"
                                    onChange={this.updateResourceAttr}
                                    value={resource.company_name}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <br/>
                            <FormGroup bsSize="small" className='no-margin'>
                                <Checkbox
                                    name="inactive"
                                    onChange={(e) => this.updateResourceAttr(e, this.setState({infoModalOpen: true}))}
                                    inline
                                    checked={resource.inactive}
                                >
                                    {" "}
                                    Inactive
                                </Checkbox>
                            </FormGroup>
                            <FormGroup bsSize="small" className='no-margin'>
                                <Checkbox
                                    name="show_company_name"
                                    onChange={this.updateResourceAttr}
                                    inline
                                    checked={resource.show_company_name}
                                >
                                    {" "}
                                    List by company name
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    First Name*
                                </ControlLabel>
                                <FormControl
                                    name="first_name"
                                    placeholder="First Name"
                                    onChange={this.updateResourceAttr}
                                    value={resource.first_name}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Last Name*
                                </ControlLabel>
                                <FormControl
                                    name="last_name"
                                    placeholder="Last Name"
                                    onChange={this.updateResourceAttr}
                                    value={resource.last_name}
                                />
                            </FormGroup>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>

                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Phone 1*
                                </ControlLabel>
                                {(isMobile && resource.phone_number) &&
                                <a className="ml-4" href={"tel:"+resource.phone_number.replace(/\D/g,'')}
                                   style={{color: "#209f1e"}}><i className="fa fa-phone" aria-hidden="true"/></a>}
                                <FormControl
                                    name="phone_number"
                                    placeholder="Phone"
                                    onChange={this.updateResourceAttr}
                                    value={
                                        resource.phone_number}
                                />
                            </FormGroup>

                        </Col>

                        <Col xs={6}>

                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Phone 2
                                </ControlLabel>
                                {(isMobile && resource.phone_number2) &&
                                <a className="ml-4" href={"tel:"+resource.phone_number.replace(/\D/g,'')}
                                   style={{color: "#209f1e"}}><i className="fa fa-phone" aria-hidden="true" /></a>}
                                <FormControl
                                    className="form-control-xs"
                                    name="phone_number2"
                                    placeholder="Phone 2"
                                    onChange={this.updateResourceAttr}
                                    value={
                                        resource.phone_number2
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Email 1
                                </ControlLabel>
                                <FormControl
                                    name="email_address"
                                    placeholder="Email"
                                    onChange={this.updateResourceAttr}
                                    value={
                                        resource.email_address
                                    }
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Email 2
                                </ControlLabel>
                                <FormControl
                                    name="email_address2"
                                    placeholder="Email 2"
                                    onChange={this.updateResourceAttr}
                                    value={
                                        resource.email_address2
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </SmallWell>
        )
    }

    _renderSaveButton = () => {
        const {resource} = this.state;
        return (
            <Button
                type="submit"
                onClick={this.saveAll}
                disabled={!resource.first_name || !resource.last_name ||
                !resource.phone_number || !resource.address_1 || !resource.city ||
                !resource.state || !resource.zip || !resource.country
                }
                bsSize="xsmall"
                bsStyle="success"
            >
                Save Customer
            </Button>
        )
    }

    _renderDeleteButton = () => {
        const {resource} = this.state;
        return (
            <Button
                bsSize="xsmall"
                bsStyle="danger"
                onClick={() => this.deleteCustomer(resource.id)}
                disabled={resource && resource.has_asset ? true : false}
            >
                Delete Customer
            </Button>
        )
    }

    _renderBillingAddress = () => {
        const {resource} = this.state;
        return (
            <SmallWell title="Billing Address">
                <form>

                    <Row>
                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Address 1*
                                </ControlLabel>
                                <FormControl
                                    name="address_1"
                                    placeholder="Address 1"
                                    onChange={this.updateResourceAttr}
                                    value={resource.address_1}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Address 2
                                </ControlLabel>
                                <FormControl
                                    name="address_2"
                                    placeholder="Address 2"
                                    onChange={this.updateResourceAttr}
                                    value={resource.address_2}
                                />
                            </FormGroup>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    City*
                                </ControlLabel>
                                <FormControl
                                    name="city"
                                    placeholder="City"
                                    onChange={this.updateResourceAttr}
                                    value={resource.city}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    County
                                </ControlLabel>
                                <FormControl
                                    name="county"
                                    placeholder="County"
                                    onChange={this.updateResourceAttr}
                                    value={resource.county}
                                />
                            </FormGroup>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={3} className='no-right-padding'>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    State*
                                </ControlLabel>
                                <FormControl
                                    name="state"
                                    placeholder="State"
                                    onChange={this.updateResourceAttr}
                                    value={resource.state}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3} className='pl-5'>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Zip*
                                </ControlLabel>
                                <FormControl
                                    name="zip"
                                    placeholder="ZIP"
                                    onChange={e => {
                                        let {resource} = this.state;
                                        resource.zip = e.target.value;
                                        this.setState(resource, this.delayedUpdateZip)
                                    }}
                                    value={resource.zip}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>
                                    Country*
                                </ControlLabel>
                                <FormControl
                                    name="country"
                                    placeholder="Country"
                                    onChange={this.updateResourceAttr}
                                    value={resource.country}
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            {!resource.id
                                ? <FormGroup bsSize="small">
                                    <Checkbox
                                        name="create_site_from_customer"
                                        onChange={this.updateResourceAttr}
                                        inline
                                        checked={
                                            resource.create_site_from_customer
                                        }
                                    >
                                        {" "}
                                        Create New Site at address
                                    </Checkbox>
                                </FormGroup>
                                : null}
                        </Col>
                    </Row>
                </form>
            </SmallWell>
        )
    }

    _renderCustomerInfo = () => {
        const {resource, referrals, customerTypes, employees} = this.state;
        return (
            <SmallWell title="Customer Info">
                <form>
                    <Row>
                        <Col md={6}>
                            <FormGroup bsSize="sm">
                                <ControlLabel>Customer Type</ControlLabel><br/>
                                <Select
                                        value={select(customerTypes, resource.customer_type_id)}
                                        options={customerTypes}
                                        onChange={this.selectResourceAttr('customer_type_id')}
                                        placeholder="Customer Type"
                                />
                            </FormGroup>
                            <FormGroup bsSize="sm">
                                <ControlLabel>Referral Type</ControlLabel><br/>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(referrals, resource.referral)}
                                        options={referrals}
                                        onChange={this.selectResourceAttr('referral')}
                                        placeholder="Referral"
                                />
                            </FormGroup>
                            <FormGroup bsSize="sm">
                                <ControlLabel>Assigned Arborist</ControlLabel><br/>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(employees, resource.person_id)}
                                        options={employees}
                                        onChange={this.selectResourceAttr('person_id')}
                                        placeholder="Sales Arborist"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className="mt-20">
                            <FormGroup bsSize="small">
                                <Checkbox
                                    name="opt_out"
                                    onChange={this.updateResourceAttr}
                                    inline
                                    checked={resource ? resource.opt_out : false}
                                >
                                    {" "}
                                    Opted Out
                                </Checkbox>
                            </FormGroup>
                            <FormGroup bsSize="small">
                                <Checkbox
                                    name="quickbooks_sync"
                                    onChange={this.updateResourceAttr}
                                    inline
                                    checked={resource && resource.quickbooks_sync}
                                >
                                    {" "}
                                    Sync with QuickBooks
                                </Checkbox>
                            </FormGroup>
                            <div className="d-flex align-center">
                                <div className="mr-5">
                                    <img src={quickbooks}/>
                                </div>
                                <Link to={`/quickbooks?customer_id=${resource.id}`}>
                                    Show in Quickbooks
                                </Link>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col xs={12} className="text-right">
                            {this._renderAttachmentField()}
                        </Col>
                    </Row>

                </form>
            </SmallWell>
        )
    }

    _renderAttachmentField = () => {
        const {resource} = this.state
        return <Well className='well_custom_padding'>
            <Row>
                <Col xs={12} className="text-left"><h4>Attachments</h4></Col>
            </Row>
            <Row>
                <Col md={12}>
                    {resource.id ?
                        <div className="dropzone_with_thumbnails">
                            <Dropzone onDrop={this.uploadAttachment} className="dropzone pointer border_none">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                            <ReorderingDragAndDrop files={resource.files.sort((a, b) => a.order - b.order)}
                                                   deleteAttachment={this.deleteAttachment}
                                                   reorderAttachments={this.reorderAttachments}
                                                   numberOfColumns={boxesPerRowReorderingDND}
                                                   selectAttachment={this.selectAttachment}/>
                        </div>
                        :
                        <p className='text-left'>Create user first!</p>
                    }
                </Col>
            </Row>
        </Well>
    }

    _renderScheduledEvents = () => {
        const {resource} = this.state;
        return resource.id ? <Well bsSize="small" className="full-height customer-notes">
            <CustomerTimeline customerId={resource.id}/>
        </Well> : null
    }

    _renderLastEdit = () => {
        const {resource} = this.state;
        return (
            <Well bsSize="small last-edit">
                <small>Last Edit
                    On: {moment(resource.modified_at).format(defaultDateTimeFormat)}{" "} by {resource.last_modified_by ? resource.last_modified_by : "Not sure"}</small><br/>
                <small>Customer
                    Since: {resource.created_at ? `${moment(resource.created_at).format(defaultDateTimeFormat)}` : "before March 2018"}{" "} by {resource.created_by}</small><br/>
                <small>Customer ID: {resource.id}</small>
            </Well>
        )
    }

    renderCustomerSiteMap = () => {
        const {resource} = this.state
        const {siteFilterText} = this.props

        let mapParams = []
        let filteredSites = []

        if (resource.customer_sites?.length > 0) {
            mapParams = resource.customer_sites.map(({longitude, latitude}) => ({longitude, latitude}))
            filteredSites = this._superFilter(resource.customer_sites, siteFilterText)
        }

        if (resource.customer_sites?.length > 0) {
            return (
                <SmallWell title="Sites Map" className="map-container">
                    <GoogleMapLoader
                        containerElement={
                            <div
                                id="usersMap"
                                className="remaining map"
                            />
                        }
                        googleMapElement={
                            <GoogleMap
                                ref={(map) => {
                                    const bounds = new window.google.maps.LatLngBounds();
                                    mapParams.map(ass => (
                                        bounds.extend(new window.google.maps.LatLng(ass.latitude, ass.longitude))
                                    ));
                                    map && map.fitBounds(bounds)
                                }}
                                mapTypeId={"hybrid"}
                                tilt={0}
                            >
                                {filteredSites?.length > 0 && filteredSites.map((site, i) => {
                                    return site.latitude && (<Marker
                                        key={site.id}
                                        position={{
                                            lat: site.latitude,
                                            lng: site.longitude
                                        }}
                                        icon={getColorCircleIcon(site.color || '#ffa726', (i + 1).toString())}
                                        title={site.full_address}
                                        zIndex={site.selected ? 1000 : 1}
                                    />)
                                })
                                }
                            </GoogleMap>
                        }
                    />
                </SmallWell>)
        }
    }

    _renderFilterToolbar = () => {
        return <DateRangeFilter
            promisedStart={moment().startOf('week')}
            promisedEnd={moment().endOf('week')}
            upsertPromise={(id, start, end) => this.handleDateFilterChange(start, end)}
            serviceNo={null}
            index={1}
            format="MM/DD/YY"
        />
    }

    handleDateFilterChange = (start_date, end_date) => {
        const from = this.getDateFromString(start_date);
        const to = this.getDateFromString(end_date);
        this.setState({
            dateFilter: {
                from: moment(from).utc().format(),
                to: moment(to).utc().format()
            }
        }, () => {
            this.loadCustomerEvents(this.props.params.id, this.state.dateFilter)
        })
    }

    getDateFromString = (date) => {
        const dateArray = date.split('/').map((s) => s.charAt(0) === '0' ? Number(s.substring(1)) : Number(s));
        return new Date(2000 + dateArray[2], dateArray[1] - 1, dateArray[0], 0, 0, 0)
    }
    loadCustomerEvents = (customerId, filter) => {
        this.props.actions.loadCustomerEvents(customerId, ([response, is_more]) => {
            const events = response;
            if (events.length) {
                const wo_ids = events.filter(({event}) => event.scheduler_event_type_id === 1)
                    .map(({event}) => event.scheduler_event_resource_id)
                this.props.actions.loadWorkOrdersInfo(wo_ids, (work_orders) => {
                    const newEvents = events.map(e => wo_ids.includes(e.event.scheduler_event_resource_id) && e.event.scheduler_event_type_id === 1 ?
                        ({
                            ...e,
                            event: {...e.event, ...(work_orders.find(wo => wo.wo_id === e.event.scheduler_event_resource_id))}
                        }) :
                        ({...e})
                    )
                    this.setState({events: newEvents})
                })
            }
            this.setState({events: [], showMoreEvents: is_more})


        }, filter)
    }
    _loadCustomerInfo = customerId => {
        console.info("Loading customerId", customerId)
        this.props.actions.fetchCustomerInfo(customerId)
        this.props.actions.fetchBillingContact(customerId)
    }

    saveAll = (e) => {
        e.preventDefault();
        let {resource} = this.state;
        this.props.actions.saveCustomer(resource, (response) => {
            browserHistory.push(`/customer/info/${response.id}`);
            resource.id = response.id;
            this.setState(resource, this.reload)
            if (resource.quickbooks_sync) {
                this.props.actions.updateCustomer(resource, 'right')
            }
        });
    }

    _renderHeader = (customer, customerOptions) => (
        <Row>
            <Col xs={12}>
                <CustomerHeader
                    customerId={parseInt(customer.id, 10)}
                    customerList={customerOptions}
                    onUpdateCustomerSearch={this.customerSearch.search}
                    onSelectCustomer={this.selectResourceAttr('id', this.reload)}
                    haveSites={customer?.customer_sites}
                    title="Customer Info"
                    saveButton={this._renderSaveButton()}
                    deleteButton={this._renderDeleteButton()}
                    optOut={
                        customer.opt_out ? true : false
                    }
                    salesArborist={
                        customer.sales_arborist ? customer.sales_arborist : null
                    }
                    showDuplicates={() => this.setState({showDuplicatesModal: true})}
                />
            </Col>
        </Row>
    )


    renderCustomerSales = () => {
        const {customerSales} = this.state
        return (<>{customerSales ?
            <Well className="customer-sales-data">
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={4} className="text-left text-primary">
                                {customerSales ? "Close Rate " : null}
                                <br/>
                                {customerSales ? "By Number: " : null}
                                <span>
                            {customerSales && customerSales.closeRateByNumber ?
                                `${customerSales.closeRateByNumber}%`
                                : null}
                            </span>
                                <br/>
                                {customerSales ? "By Dollars: " : null}
                                <span>
                            {customerSales && customerSales.closeRateByDollars ?
                                `${customerSales.closeRateByDollars}%`
                                : null}
                              </span>
                            </Col>
                            <Col xs={2} className="text-right text-warning">
                                <Row>
                                    {customerSales ?
                                        `Bids: ${customerSales.bidsCount}` : null}
                                    <br/>
                                    {customerSales
                                        ? <Dollars
                                            amount={customerSales.total}
                                        />
                                        : null}
                                </Row>
                                <Row>
                                    {customerSales ? `Tree` : null}
                                    <br/>
                                    {customerSales
                                        ? <Dollars
                                            amount={customerSales.totalTreeCare}
                                        />
                                        : null}
                                </Row>
                                <Row>
                                    {customerSales ? `PHC` : null}
                                    <br/>
                                    {customerSales
                                        ? <Dollars
                                            amount={customerSales.totalPhc}
                                        />
                                        : null}
                                </Row>
                            </Col>
                            <Row>
                                <Col xs={6}>
                                    <Row>
                                        <Col xs={6} className="text-right text-info no-padding">
                                            {customerSales ? `Closed: ${customerSales.closedCount}` : null}
                                            <br/>
                                            {customerSales ?
                                                <Dollars
                                                    amount={customerSales.totalClosedServices}
                                                />
                                                : null}
                                        </Col>
                                        <Col xs={6} className="text-right text-success">
                                            {customerSales ? `Open: ${customerSales.openCount}` : null}
                                            <br/>
                                            {customerSales ?
                                                <Dollars
                                                    amount={customerSales.totalOpenServices}
                                                />
                                                : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} className="text-right text-info no-padding">
                                            {customerSales ? `Tree` : null}
                                            <br/>
                                            {customerSales ?
                                                <Dollars
                                                    amount={customerSales.totalClosedTreeServices}
                                                />
                                                : null}
                                        </Col>
                                        <Col xs={6} className="text-right text-success">
                                            {customerSales ? `Tree` : null}
                                            <br/>
                                            {customerSales ?
                                                <Dollars
                                                    amount={customerSales.totalOpenTreeServices}
                                                />
                                                : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} className="text-right text-info no-padding">
                                            {customerSales ? `PHC` : null}
                                            <br/>
                                            {customerSales ?
                                                <Dollars
                                                    amount={customerSales.totalClosedPhcServices}
                                                />
                                                : null}
                                        </Col>
                                        <Col xs={6} className="text-right text-success">
                                            {customerSales ? `PHC` : null}
                                            <br/>
                                            {customerSales ?
                                                <Dollars
                                                    amount={customerSales.totalOpenPhcServices}
                                                />
                                                : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <h6 className="text-left text-danger">
                                    {customerSales ?
                                        "Declined Services: "
                                        : null}
                                    {customerSales ?
                                        <Dollars
                                            amount={customerSales.totalDeclinedServices}
                                        />
                                        : null}
                                </h6>
                            </Col>
                            <Col xs={12} md={6}>
                                {this.state.chart.show &&
                                !(this.state.customerSales.closedCount === 0 && this.state.customerSales.openCount === 0) ?
                                    <CustomerSalesChart series={this.state.chart.series} labels={this.state.chart.labels}/> : null
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Well>
            :
            null
        }</>)
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customerInfo.customerInfo,
        sites: state.customerInfo.sites,
        siteFilterText: state.customerInfo.siteFilterText,
        contacts: state.customerInfo.contacts,
        contactFilterText: state.customerInfo.contactFilterText,
        billingContact: state.customerInfo.billingContact,
        editingSiteId: state.siteEditor.editingSiteId,
        editingContactId: state.contactEditor.editingContactId,
        foundCustomers: state.customerInfo.foundCustomers,
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoPage)
