import React from 'react';
import {FormGroup, InputGroup, FormControl, Button} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const lSizeScreen = window.screen.width > 1400;

class PresetSearch extends ResourceComponent {
    state = {
        inputValue: ""
    }

    componentDidMount() {
        const {inputValue} = this.state
        const {q} = this.props

        if (q && q.length > 0 && (inputValue !== q)) {
            this.setState({inputValue: q})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {inputValue} = this.state;
        const {q} = this.props;

        if (this.state.inputValue && prevState.inputValue && prevState.inputValue !== this.state.inputValue) {
            this.props.setPresetSearch(inputValue)
        }
        if (prevProps.q !== q && q === "") {
            this.setState({inputValue: ""})
        }
        if (prevProps.q !== q && q !== "") {
            this.setState({inputValue: q})
        }
    }

    render() {
        const {newSchedulerView} = this.props;
        const {inputValue} = this.state;


        if (!newSchedulerView) {
            const calendarBtn = document.querySelector(`.fc-toolbar-chunk`)
            let marginLeft
            if (calendarBtn) {
                marginLeft = calendarBtn && calendarBtn.offsetWidth + (lSizeScreen ? 30 : 10);
            }

            return (
                <FormGroup className="classic-input-search" style={{marginLeft: marginLeft}}>
                    <InputGroup>
                        <FormControl type="text" name="q" placeholder="Search events" value={inputValue} onChange={e => this.setState({inputValue: e.target.value})} />
                        <InputGroup.Button>
                            <Button
                                className="search-events-clear-btn"
                                onClick={() => this.setState({inputValue: ''})}
                            >
                                <FontAwesomeIcon icon={faTimes} className="small-margin"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            )
        }
    }
}

export default PresetSearch;
