const initialState = {
    active: null,
    proposal: null,
    email: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SHOW_DOCK':
            return {
                ...state,
                active: action.name,
                proposal: action.proposal,
                email: action.email,
                reloadDate: new Date()
            };
        case 'RELOAD_DOCK':
            return {...state, reloadDate: new Date()};
        default:
            return state
    }
}

