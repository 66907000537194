import {apiCall} from "../../../../common/apiActionHandler";
import axios from "axios";

export const fetchProposalVersionHistory = (proposalId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposal_version_history`,
        method: 'get',
        params: {
            proposal_id: proposalId
        }
    };
    apiCall("Fetching version history", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};