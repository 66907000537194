import React from "react";
import {Col, FormGroup, Row} from "react-bootstrap";
import Select from "react-select";
import {defaultDateFormat, defaultTimeFormat, select} from "../../common/commonHandlers";
import Datetime from "react-datetime";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import ResourceComponent from "../../components/ResourceComponent";
import Dollars from "../../components/Dollars";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";
import PromiseUi from "../../components/PromiseUi";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;
const xsMobile = window.screen.width <= 340;

class BottomLeftPanelMobile extends ResourceComponent {

    render() {
        const {
            service_types,
            statusDateFilterFormatter,
            resource,
            dashboardState,
            selectedStatus,
            employees,
            durations
        } = this.props;
        const {date_name} = resource;

        const acceptedFilters = [
            {label: 'Total Accepted', value: 'total'},
            {label: 'Accepted', value: 'accepted'},
            {label: 'Declined', value: 'declined'},
            {label: 'Open', value: 'open'},
            {label: 'Pure Accepted', value: 'pure_accepted'},
            {label: 'Pure Declined', value: 'pure_declined'}];

        const backlogFilterStatuses = [
            {value: 'ACCEPTED', label: 'Accepted'},
            {value: 'IN_WORK_ORDER', label: 'In Work Order'},
            {value: 'SCHEDULED', label: 'Scheduled'},
            {value: 'PROPOSED', label: 'Proposed'}
        ];

        const completedFilterStatuses = [
            {value: 'COMPLETED', label: 'Completed'},
            {value: 'INVOICED', label: 'Invoiced'},
            {value: 'PAYMENT', label: 'Payment'},
            {value: 'PAID', label: 'Paid'}
        ];

        let statuses;
        if (resource?.selectedStatus === 'backlog') {
            statuses = backlogFilterStatuses
        } else if (resource?.selectedStatus === 'completed') {
            statuses = completedFilterStatuses
        }

        const formatOptionLabel = ({value, label, customAbbreviation}) => (
            <Row style={{display: "flex", fontSize: xsMobile ? 9 : smMobile ? 10 : null}}>
                <Col xs={3}>
                    {acceptedFilters.find(f => f.value === value).label}
                </Col>
                <Col xs={5} style={{color: "#3cc6e2"}}>
                    Amount: <Dollars amount={label.amount}/>
                </Col>
                <Col xs={3} style={{color: "#000"}}>
                    Count: {label.count}
                </Col>
                <Col xs={1} style={{marginLeft: "10px", color: "#ccc"}}>
                    {customAbbreviation}
                </Col>
            </Row>
        );

        const {customerInputSearch, zipInputSearch, cityInputSearch, proposalInputSearch} = this.props;

        let backlogProposedFilter = false
        if (resource.ps_status && resource.ps_status.length > 0 && resource.ps_status.some(s => s === "PROPOSED" || s === "ACCEPTED" || s === "IN_WORK_ORDER" || s === "SCHEDULED")) {
            backlogProposedFilter = true
        }

        return (
            <div>
                {
                    selectedStatus === 'Paid' && <Row className={'modalLeadsFilter'}>
                        <Col md={12}
                             className={"bottom15 no-left-padding no-right-padding"}>
                            <span className="font11">{statusDateFilterFormatter(selectedStatus)}</span>
                            <PromiseUi
                                promisedStart={resource.from}
                                promisedEnd={resource.to}
                                upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'from', 'to', true)}
                                proposalServiceId={null}
                                serviceNo={null}
                                startPlaceholder="From"
                                endPlaceholder="To"
                                index={1}
                            />
                        </Col>
                    </Row>
                }
                <Row className="bottom10 no-margin">
                    <Col md={12}
                         className={"bottom15 no-left-padding no-right-padding top15"}>
                        <Select className="Select" classNamePrefix="select"
                                value={select(employees, resource.sales_arborist)}
                                options={employees}
                                onChange={this.props.selectResourceAttr('sales_arborist', this.props.reloadReport)}
                                isMulti
                                isClearable
                                placeholder="Sales Arborist"
                        />
                    </Col>
                </Row>
                <Row className="bottom10 no-margin">
                    <Col md={12}
                         className={"bottom15 no-left-padding no-right-padding"}>
                        <Select className="Select" classNamePrefix="select"
                                value={select(service_types, resource.service_type)}
                                options={service_types}
                                isClearable
                                isMulti
                                onChange={this.selectResourceAttr('service_type', this.props.reloadReport)}
                                placeholder="Service Types"
                        />
                    </Col>
                </Row>
                {selectedStatus === 'Sent' &&
                <span>
                        <Row className={'modalLeadsFilter'}>
                        <Col md={12} className="mt5 bottom15 no-padding">
                            <Select className="Select" classNamePrefix="select"
                                    name="date_name"
                                    value={select(durations, date_name)}
                                    options={durations}
                                    onChange={this.props.selectResourceAttr('date_name', this.props.reloadReport)}
                            />
                        </Col>
                    </Row>
                        <div>
                             {resource.date_name && resource.date_name === "custom" &&
                             <Row className={'modalLeadsFilter'}>
                                 <Col className='no-left-margin no-right-margin bottom15'>
                                     <span className="font11">Sent date</span>
                                     <PromiseUi
                                         promisedStart={resource.sent_from}
                                         promisedEnd={resource.sent_to}
                                         upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'sent_from', 'sent_to', true)}
                                         proposalServiceId={null}
                                         serviceNo={null}
                                         startPlaceholder="Sent From"
                                         endPlaceholder="Sent To"
                                         timeDelay={300}
                                         index={1}
                                     />
                                 </Col>
                             </Row>
                             }
                        </div>
                             <hr className={'mb-10-mt-0 ml-15 mr-15'}/>
                    <Row className="bottom10 no-left-margin">
                        <Col md={12}>
                        <ColorCheckbox value={resource.onlyProposed}
                                       label='Show only proposed'
                                       onChange={this.props.selectCheckboxAttr('onlyProposed', this.props.reloadReport)}
                        />
                        </Col>
                        </Row>
                        </span>
                }

                <div>
                    <Row className="backlog-border padding-top-bottom-8">
                        <Col md={12}>
                            <span>{selectedStatus} Filter</span>
                        </Col>
                    </Row>
                    {["backlog", "completed", "invoiced", "paid"].indexOf(resource.selectedStatus) > -1 &&
                    <>
                        <Row className="bottom10 no-margin">
                            <Col md={12}
                                 className={"bottom5 no-left-padding no-right-padding"}>
                                <span className="font11">Promised date</span>
                                <PromiseUi
                                    promisedStart={resource.promisedFrom}
                                    promisedEnd={resource.promisedTo}
                                    upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'promisedFrom', 'promisedTo')}
                                    proposalServiceId={null}
                                    serviceNo={null}
                                    index={selectedStatus === 'Paid' ? 2 : 1}
                                />
                            </Col>
                        </Row>
                        <Row className="bottom10 no-margin">
                            <Col
                                className={`${backlogProposedFilter ? 'bottom5' : 'bottom15'} no-left-padding no-right-padding`}
                                md={12}>
                                <span className="font11">Accepted date</span>
                                <PromiseUi
                                    promisedStart={resource.scheduledFrom}
                                    promisedEnd={resource.scheduledTo}
                                    upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'scheduledFrom', 'scheduledTo')}
                                    proposalServiceId={null}
                                    serviceNo={null}
                                    startPlaceholder="S From"
                                    endPlaceholder="S To"
                                    index={selectedStatus === 'Paid' ? 3 : 2}
                                />
                            </Col>
                        </Row>
                    </>}
                    {(resource.selectedStatus === "backlog" && backlogProposedFilter || ["proposed", "completed", "invoiced", "paid"].indexOf(resource.selectedStatus) > -1) &&
                    <Row className="bottom10 no-margin">
                        <Col className="bottom15 no-left-padding no-right-padding" md={12}>
                            <span className="font11">Sent date</span>
                            <PromiseUi
                                promisedStart={resource.sent_from}
                                promisedEnd={resource.sent_to}
                                upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'sent_from', 'sent_to')}
                                proposalServiceId={null}
                                serviceNo={null}
                                startPlaceholder="Sent From"
                                endPlaceholder="Sent To"
                                timeDelay={300}
                                index={resource.selectedStatus === 'proposed' ? 1 : 3}
                            />
                        </Col>
                    </Row>}
                    <hr className={'bottom10 mt10 mr-15 ml-15'}/>
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={"bottom15 no-left-padding no-right-padding"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.services, resource.service)}
                                        options={dashboardState.services}
                                        isClearable
                                        isMulti
                                        onChange={this.props.selectResourceAttr('service')}
                                        placeholder="Service"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {["backlog", "completed"].indexOf(resource.selectedStatus) > -1 &&
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={"bottom15 no-left-padding no-right-padding"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(statuses, resource.selectedStatus === 'backlog' ? resource.ps_status : resource.ps_completed_statuses)}
                                        options={statuses}
                                        isMulti
                                        isClearable
                                        onChange={this.props.selectResourceAttr(resource.selectedStatus === 'backlog' ? 'ps_status' : 'ps_completed_statuses')}
                                        placeholder="Status"
                                />
                            </FormGroup>
                        </Col>
                    </Row>}
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(customerInputSearch.customerOptions, resource.customer_id)}
                                        options={customerInputSearch.customerOptions}
                                        isClearable
                                        isMulti
                                        onInputChange={customerInputSearch.onInputChange}
                                        onChange={this.props.selectResourceAttr('customer_id')}
                                        placeholder="Customer"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}
                             className={smMobile ? "bottom15 no-left-padding no-right-padding" : "bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.customer_types, resource.customer_type)}
                                        options={dashboardState.customer_types}
                                        isClearable
                                        isMulti
                                        onChange={this.props.selectResourceAttr('customer_type')}
                                        placeholder="Customer type"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={smMobile ? "bottom15 no-left-padding no-right-padding" : "bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.reasons, resource.reason)}
                                        options={dashboardState.reasons}
                                        isClearable
                                        isMulti
                                        onChange={this.props.selectResourceAttr('reason')}
                                        placeholder="Reason"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom25 no-left-padding no-right-padding" : "mt-15 bottom25"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.equipments, resource.equipment)}
                                        options={dashboardState.equipments}
                                        isClearable
                                        isMulti
                                        onChange={this.props.selectResourceAttr('equipment')}
                                        placeholder="Equipment"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(cityInputSearch.cityOptions, resource.city)}
                                        options={cityInputSearch.cityOptions}
                                        isClearable
                                        isMulti
                                        onInputChange={cityInputSearch.onInputChange}
                                        onChange={this.props.selectResourceAttr('city')}
                                        placeholder="City"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(zipInputSearch.zipOptions, resource.zip)}
                                        options={zipInputSearch.zipOptions}
                                        isClearable
                                        isMulti
                                        onInputChange={zipInputSearch.onInputChange}
                                        onChange={this.props.selectResourceAttr('zip')}
                                        placeholder="Zip"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={smMobile ? "bottom15 no-left-padding no-right-padding" : "bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.priorities, resource.priority)}
                                        options={dashboardState.priorities}
                                        isMulti
                                        isClearable
                                        onChange={this.props.selectResourceAttr('priority')}
                                        placeholder="Priority"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.plants, resource.plant)}
                                        options={dashboardState.plants}
                                        isMulti
                                        isClearable
                                        onChange={this.props.selectResourceAttr('plant')}
                                        placeholder="Plants"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.employees, resource.arborist_id)}
                                        options={dashboardState.employees}
                                        isClearable
                                        onChange={this.props.selectResourceAttr('arborist_id')}
                                        placeholder="Arborist"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(proposalInputSearch.proposalOptions, resource.proposal_id)}
                                        options={proposalInputSearch.proposalOptions}
                                        isClearable
                                        isMulti
                                        onInputChange={proposalInputSearch.onInputChange}
                                        onChange={this.props.selectResourceAttr('proposal_id')}
                                        placeholder="Proposal #"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className={'bottom10 mt10 mr-15 ml-15'}/>
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select
                                    className="Select"
                                    classNamePrefix="select"
                                    value={select(dashboardState.services, resource.ex_service)}
                                    options={dashboardState.services}
                                    isClearable
                                    isMulti
                                    onChange={this.props.selectResourceAttr('ex_service')}
                                    placeholder="Excluded Services"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select
                                    className="Select"
                                    classNamePrefix="select"
                                    value={select(dashboardState.priorities, resource.ex_priority)}
                                    options={dashboardState.priorities}
                                    isClearable
                                    isMulti
                                    onChange={this.props.selectResourceAttr('ex_priority')}
                                    placeholder="Excluded Priority"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="bottom10 no-margin">
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.equipments, resource.ex_equipment)}
                                        options={dashboardState.equipments}
                                        isClearable
                                        isMulti
                                        onChange={this.props.selectResourceAttr('ex_equipment')}
                                        placeholder="Excluded Equipment"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}
                             className={smMobile ? "mt-15 bottom15 no-left-padding no-right-padding" : "mt-15 bottom15"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(dashboardState.customer_types, resource.ex_customer_type)}
                                        options={dashboardState.customer_types}
                                        isClearable
                                        isMulti
                                        onChange={this.props.selectResourceAttr('ex_customer_type')}
                                        placeholder="Excluded Customer Type"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default BottomLeftPanelMobile;
