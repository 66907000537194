import React, {Component} from 'react'
import  {Panel, Button } from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import Dollars from '../../components/Dollars'

export default class Approvable extends Component{

  render(){
    const {dollars, numProposals} = this.props
    return(

    <Panel bsStyle='default' className="text-center">

      <h3 className="text-success">Approvable</h3>
      <br/>
      <h4><Dollars amount={dollars}/></h4>
      <p>&nbsp;</p>

      <LinkContainer to="/pending_approvals">
        <Button bsStyle="success" bsSize="small">{numProposals || 0} Proposals to approve</Button>
      </LinkContainer>

    </Panel>


    )
  }
}


Approvable.defaultProps = {
  dollars: 12,
  numProposals: 3
}