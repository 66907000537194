import React, {Component} from 'react'
import {Link} from "react-router";
import './ProgressSection.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Circle} from 'rc-progress';

export default class ProgressSection extends Component {

    render() {
        const {title, data, percent, setActiveTab} = this.props
        return (
            <div className='progress-section'>

                <div className='left-side'>
                    <div className='title'>{title}</div>
                    <div className='progress-container'>
                        <Circle percent={percent} strokeWidth="20" trailWidth="20" strokeColor="green"
                                strokeLinecap='butt'/>
                        <div className='percent'> {percent}%</div>
                    </div>
                </div>
                <div className='right-side'>
                    <div className='header'>
                        <div className='title'>Category</div>
                        <div className='status'> Status</div>
                    </div>
                    <ul className='progress-list'>
                        {data.map(i => {
                            return <li className='progress-list-item'>{(i.link && setActiveTab) ?
                                <span className='link' onClick={() => setActiveTab(+i.link)}>{i.name}</span> :
                                i.link ? <Link to={i.link}>{i.name}</Link> : i.name}
                                <div className='dotted-line'></div>
                                <div className='item-status'><FontAwesomeIcon size={'lg'}
                                                                              color={i.done ? '#378805' : '#d3d3d3'}
                                                                              icon={faCheckCircle}/> {i.done && 'Complete'}
                                </div>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}