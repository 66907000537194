import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const loadIssues = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/issues/get_user_issues`,
        params: filter
    };

    apiCall("Loading issues", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
