import {
  FETCH_MY_PROPOSALS,
  UPDATE_PROPOSAL_COMPLETED_END,
  UPDATE_PROPOSAL_COMPLETED_START,
  UPDATE_STATUS,
} from "./constants"

const initialState = {
  myProposals: [],
  proposalProposedStart: null,
  proposalProposedEnd: null,
  status: "all",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROPOSAL_COMPLETED_END:
      return {
        ...state,
        proposalProposedEnd: action.proposalProposedEnd,
        myProposals: null,
      }
    case UPDATE_PROPOSAL_COMPLETED_START:
      return {
        ...state,
        proposalProposedStart: action.proposalProposedStart,
        myProposals: null,
      }
    case FETCH_MY_PROPOSALS:
      return { ...state, myProposals: action.myProposals }
      case UPDATE_STATUS:
      return {...state, status: action.status}
    default:
      return state
  }
}
export default reducer
