import axios from "axios/index";
import {mapForSelect} from "../../common/commonHandlers";
import {apiCall} from "../../common/apiActionHandler";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload, handleUpload} from "../../common/upload";
import {addAlert} from "../App/actions";

export const addOption = (fieldName, fieldData, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/${fieldName}${fieldName.charAt(fieldName.length - 1) === 's' ? 'es' : 's'}`,
        method: 'POST',
        data: {[fieldName]: fieldData}
    };

    apiCall("Adding option", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const removeOption = (fieldName, id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/${fieldName}${fieldName.charAt(fieldName.length - 1) === 's' ? 'es' : 's'}/${id}`,
        method: 'DELETE'
    };

    apiCall("Delete option", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};
export const updateOption = (fieldName, id, option, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/${fieldName}${fieldName.charAt(fieldName.length - 1) === 's' ? 'es' : 's'}/${id}`,
        data: {[fieldName]: option},
        method: 'PUT'
    };

    apiCall("Option updated", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};
export const updateOptionsOrder = (fieldName, updatedOrder, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/${fieldName}${fieldName.charAt(fieldName.length - 1) === 's' ? 'es' : 's'}/update_order`,
        params: {updated_order: updatedOrder},
        method: 'PUT'
    };

    apiCall("Option updated", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};
export const uploadTruckAttachment = (id, acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/truck_files?truck_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};
export const uploadTruckFinancialAttachment = (id, acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/truck_financial_files?truck_financial_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};
export const uploadTruckServiceAttachment = (id, acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/truck_service_files?truck_service_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteTruckServiceAttachment = (id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/truck_service_files/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}
export const deleteTruckFinancialAttachment = (id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/truck_financial_files/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}

export const deleteTruckAttachment = (id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/truck_files/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}

export const getTrucksData = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/trucks/get_trucks_new_service_data`,
        method: 'GET'
    };

    apiCall("Truck loaded", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const createNewTruckService = (truck, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/trucks/${truck.id}/create_truck_new_service`,
        data: {truck},
        method: 'POST'
    };

    apiCall("Truck service created", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};
export const deleteTruckService = (truck_id, service_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/trucks/${truck_id}/delete_truck_service`,
        data: {service_id},
        method: 'DELETE'
    };

    apiCall("Truck service deleted", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};
export const reorderFinancialAttachment = (newImageOrder, truck_financial_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/trucks/update_financial_doc_order`,
        method: 'PUT',
        data: {new_doc_order: newImageOrder, id: truck_financial_id}
    };

    apiCall("Reordering attachments", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const reorderServiceAttachment = (newImageOrder, truck_mechanical_service_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/trucks/update_service_doc_order`,
        method: 'PUT',
        data: {new_doc_order: newImageOrder, id: truck_mechanical_service_id}
    };

    apiCall("Reordering attachments", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const reorderAttachment = (newImageOrder, truck_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/trucks/update_doc_order`,
        method: 'PUT',
        data: {new_doc_order: newImageOrder, id: truck_id}
    };

    apiCall("Reordering attachments", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const saveTruckFinancialNote = (note, truck_financial_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/truck_financial_notes_histories/${note?.id ? note.id : ''}`,
        data: {...note, truck_financial_id},
        method: note?.id ? 'PUT' : 'POST'
    };

    apiCall("Saving truck financial note", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};


export const deleteTruckFinancialNote = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/truck_financial_notes_histories/${id}`
    }

    apiCall("Deleting truck financial note", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};

export const truckFinancialTimelineData = (truck_financial_id, filters, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/trucks/truck_financial_timeline_data`,
        params: {truck_financial_id, filters}
    };

    apiCall("Loading Data", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const saveDownloadedFile = (blob, params, source) => (dispatch, getState) => {
    dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(params ? params : {}), source: source}, false)(dispatch, getState)
}