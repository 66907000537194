import React from 'react';
import moment from "moment";

const CustomerViewInvoiceHeader = ({invoice, newMessages, stickiesIds, clientLogo, renderPageHeader, isMobile}) => {

    const logo = () => {
        return <div className="cVh__logo">
            <img src={clientLogo && clientLogo} alt="Client Logo"/>
        </div>
    }

    const companyDetails = () => {
        return <div className="cVh__company-details text-success">
            <strong><p>{invoice.client_name}</p></strong>
            <strong><p>{invoice.client_address}</p></strong>
            <strong><p>{invoice.client_city}, {invoice.client_state} {invoice.client_zip}</p></strong>
            <strong><p>{invoice.client_phone}</p></strong>
            <strong><p>{invoice.client_email}</p></strong>
        </div>
    }

    const arborist = () => {
        return <div className="cVh__arborist text-success">
            <strong><p>Arborist:</p></strong>
            <p>{invoice.sales_arborist_name}</p>
            <p>{invoice.arborist_phone}</p>
            <p>{invoice.arborist_email}</p>
        </div>
    }

    const invoiceDetails = () => {
        return <div className="cVh__invoice-details cVh__divide text-success">
            <div className="cVh__divide--align-right">
                <strong><p>Proposal No.</p></strong>
                <strong><p>Date:</p></strong>
                <strong><p>Invoice No.</p></strong>
                <strong><p>Date:</p></strong>
            </div>
            <div className="cVh__divide--align-left ">
                <p>{invoice.proposal_no}</p>
                <p>{moment(invoice.proposal_date).format('YYYY/MM/DD')}</p>
                <p>{invoice.number}</p>
                <p>{moment(invoice.invoiced_at).format('YYYY/MM/DD')}</p>
            </div>
        </div>
    }

    const customerInfo = () => {
        return <div className="cVh__customer-info cVh__divide text-danger">
            <div className="cVh__divide--align-right">
                <strong><p>Customer:</p></strong>
                <strong><p>Site:</p></strong>
            </div>
            <div className="cVh__divide--align-left">
                <p>{invoice.customer_full_name}</p>
                <p>{invoice.site_name}</p>
            </div>
        </div>
    }

    const customerSite = () => {
        return <div className="cVh__customer-site cVh__divide-gap-column text-danger">
            <div className="cVh__divide--align-right">
                <strong><p>Site Address</p></strong>
            </div>
            <div className="cVh__divide--align-left">
                <p>{invoice.site_full_address}</p>
            </div>
            <div className="cVh__divide--align-right">
                <strong><p>Purchase Order</p></strong>
            </div>
            <div className="cVh__divide--align-left">
                <p>{invoice.purchase_order}</p>
            </div>
        </div>
    }

    const actions = () => {
        return <div className="cVh__actions">
            {renderPageHeader(invoice, newMessages, stickiesIds)}
        </div>
    }

    return (
        <div className="customerView__header-wrapper">
            <div
                className={`fit-content-height proposal-details full-width
                ${isMobile
                    ? "customerView__header"
                    : "customerView__header hidden-sm hidden-xs"}`
                }
            >
                {logo()}
                {companyDetails()}
                {arborist()}
                {invoiceDetails()}
                {customerInfo()}
                {customerSite()}
            </div>
            <div className="customerView__header proposal-actions justify-flex-end">
                {actions()}
            </div>
        </div>
    );
};

export default CustomerViewInvoiceHeader;