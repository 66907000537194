import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {dataUpload} from "../../common/upload";

export const checkIfAssigned = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/priorities/check_is_assigned`,
        params: {id: id}
    };

    apiCall("Check is assigned", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data.is_assigned);
    }, dispatch, getState, false);
};

export const fetchFilterData = (id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/priorities/filter_data/`,
        params: {id: id}
    }

    apiCall("Fetch filter data", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const getFilteredData = (id, callback, params) => async (dispatch, getState) => {
    const config = {
        url: `/api/priorities/${id}/`,
        params: {
            arborist: params?.arborist,
            service: params?.service,
            service_type: params?.service_type,
            proposal: params?.proposal,
            customer: params?.customer,
            customer_type: params?.customer_type,
            accepted_to: params?.accepted_to,
            accepted_from: params?.accepted_from,
            completedProposal: params?.completedProposal,
            invoicedProposal: params?.invoicedProposal,
            paidProposal: params?.paidProposal,
        }
    }

    apiCall("Filtered data", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}
export const getEmployees = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/employees?disabled=false`
    }

    apiCall("Getting employees", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const getPsStatuses = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposal_service_statuses`,
        params: {all: true}
    }

    apiCall("Getting statuses", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const saveDownloadedFile = (blob, params) => (dispatch, getState) => {
    params = {
        arborist: params?.arborist,
        service: params?.service,
        service_type: params?.service_type,
        proposal: params?.proposal,
        customer: params?.customer,
        customer_type: params?.customer_type,
        accepted_to: params?.accepted_to,
        accepted_from: params?.accepted_from,
        completedProposal: params?.completedProposal,
        invoicedProposal: params?.invoicedProposal,
        paidProposal: params?.paidProposal,
    }
    dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(params ? params : {}), source: "service priorities"}, false)(dispatch, getState)
};