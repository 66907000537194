import React from 'react';
import {Row} from "react-bootstrap";

const CustomerRequests = ({requests}) => {
    return (
        <>
            {requests.map((r, index) => (
                <Row className='item' key={index}>
                    <div className='item-header'>
                        <div className='item-title'>Requested on {r.requested}</div>
                    </div>
                    <div className='item-data'>
                        {r.site &&
                            <div className='flex'>
                                <p className='bold mr-5'>Address:</p>
                                <p>{r.site}</p>
                            </div>
                        }
                        {r.referral &&
                            <div className='flex'>
                                <p className='bold mr-5'>Referral</p>
                                <p>{r.referral}</p>
                            </div>
                        }
                        {r.reason &&
                            <div className='flex'>
                                <p className='bold mr-5'>Reason:</p>
                                <p>{r.reason}</p>
                            </div>
                        }
                        {r.notes &&
                            <div className='flex'>
                                <p className='bold mr-5'>Notes:</p>
                                <p>{r.notes}</p>
                            </div>
                        }
                    </div>
                </Row>
            ))}
        </>
    );
};

export default CustomerRequests;