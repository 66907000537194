import {mapPersonSearch} from "../../../common/commonHandlers";
import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios/index";
import {TOGGLE_FULL_SCREEN} from "../constants";

export const loadProposal = (id, loadAssetServices, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/${id}`
    };
    apiCall("Loading proposal", config, async config => {
        const response = await axios.request(config);
        const proposal = response.data;
        const detailsConfig =
            [
                {url: `/api/customers/name_zip_city_search`, params: {id: proposal.customer_id}},
                {url: `/api/new_sites/${proposal.site_id}`, params: {load_asset_services: loadAssetServices}},
                {url: `/api/new_proposals/proposal_polygon`, params: id && {id}},
            ];
        apiCall("Loading customer", detailsConfig, async detailsConfig => {
            let actions = detailsConfig.map(c => axios.request(c));
            const all = await axios.all(actions);
            const site = all[1].data;
            callback({
                customerSearchResults: all[0].data,
                site,
                assets: site.assets,
                resource: proposal,
                proposalPolygon: all[2].data,
            });
        }, dispatch, getState, false);
    }, dispatch, getState, false);
};

export const reloadProposal = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/${id}`
    };
    apiCall("Reloading proposal", config, async config => {
        const response = await axios.request(config);
        const proposal = response.data;
        callback({resource: proposal});
    }, dispatch, getState, false);
};

export const loadLead = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/leads/${id}`
    };
    apiCall("Loading lead", config, async config => {
        const response = await axios.request(config);
        const lead = response.data;
        const detailsConfig =
            [
                {url: `/api/customers/name_zip_city_search`, params: {id: lead.customer_id}},
                {url: `/api/new_sites/${lead.site_id}`},
            ];
        apiCall("Loading customer", detailsConfig, async detailsConfig => {
            let actions = detailsConfig.map(c => axios.request(c));
            const all = await axios.all(actions);
            const site = all[1].data;
            callback({
                customerSearchResults: all[0].data,
                site,
                assets: site.assets,
                lead
            });
        }, dispatch, getState, false);
    }, dispatch, getState, false);
};

export const load = (customerId, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/customers/name_zip_city_search`, params: customerId ? {id: customerId} : {q: ''}},
        {url: `/api/new_services?bundle_service=false`},
        {url: `/api/new_services?bundle_service=true`},
        {url: `/api/priorities/search`},
        {url: `/api/equipments/search`},
        {url: `/api/user_profile`},
        {url: '/api/proposal_service_statuses?all=true'}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const employees = mapPersonSearch(all[0].data);
        callback({
            employees,
            customerSearchResults: all[1].data,
            services: all[2].data,
            bundleServices: all[3].data,
            priorities: all[4].data,
            equipments: all[5].data,
            user: all[6].data,
            proposalServiceStatuses: all[7].data.map(x => ({value: x.id, label: x.label})),
        });
    }, dispatch, getState, false);
};


export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const loadSite = (siteId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}`
    };

    apiCall("Loading site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchLastAddedService = (proposal, callback) => (dispatch, getState) => {
    const config = {
        url: '/api/new_proposals/fetch_last_added_service',
        params: {id: proposal.id}
    };

    apiCall("Fetch last service number", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const saveProposal = (proposal, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/${proposal.id || ''}`,
        data: {proposal},
        method: proposal.id ? 'PUT' : 'POST',
    };

    apiCall("Saving proposal", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const duplicatePS = (proposal_service, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/duplicate`,
        data: {proposal_service},
        method: 'POST',
    };

    apiCall("Duplicating proposal service", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
}

export const savePSOrder = (proposal_services, show_msg = true, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/update_ps_service_order`,
        data: {
            proposal_services: proposal_services
        },
        method: 'PUT',
    };

    apiCall("Updating proposal service order", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, show_msg);
};

export const saveBSOrder = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/update_bs_service_order`,
        data: {
            id: resource.id,
            is_bundle_service: resource.is_bundle_service,
            bundle_services: resource.bundle_services
        },
        method: 'PUT',
    };

    apiCall("Updating proposal service order", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState);
};


export const setServiceProposed = (proposal_service, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/${proposal_service.id}/set_service_proposed`,
        data: {proposal_service},
        method: 'PUT'
    };

    apiCall("Saving proposal service", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const deleteProposalService = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/${id}`,
        method: 'delete'
    };

    apiCall("Proposal Service Deleting", config, async config => {
        await axios.request(config);
        callback && callback()
    }, dispatch, getState, true, callback);
};


export const saveDuplicates = (siteId, assetId, markers, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/new_assets/${assetId}/save_duplicates`,
        method: "post",
        data: {
            duplicates: {
                positions: markers.map(marker => ({
                    lat: marker.getPosition().lat(),
                    lng: marker.getPosition().lng()
                })),
                asset_id: assetId
            }
        }
    };

    apiCall("Saving duplicates", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const saveMap = (siteId, assets, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/new_assets/save_map`,
        method: "post",
        data: {assets}
    };

    apiCall("Saving map", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};


export const deleteProposal = (id, deleteWithLead, callback) => (dispatch, getState) => {
    const config = {
        method: "delete",
        url: `/api/proposals/${id}`,
        params: {deleteWithLead}
    };

    apiCall("Deleting proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const toggleFullScreen = (isFullScreen) => (dispatch) => {
    dispatch({
        type: TOGGLE_FULL_SCREEN,
        fullScreen: isFullScreen
    })
}

export const getCustomer = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/${id}/info`
    };

    apiCall("Fetch customer", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const getCustomerLastProposalSite = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/${id}/last_proposal_site`
    };

    apiCall("Fetch last proposal site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const getCustomerLastProposalArborist = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/${id}/last_proposal_arborist`
    };

    apiCall("Fetch last proposal site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const selectCustomerAttachmentForPrint = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customer_files/${id}/select_for_print`,
        method: 'PUT'
    };

    apiCall("Updating file", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const selectSiteAttachmentForPrint = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/site_files/${id}/select_for_print`,
        method: 'PUT'
    };

    apiCall("Updating file", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const updateAssetMovementLockSettings = (id) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_asset_movement_lock_settings`,
        method: 'PUT',
        data: {id}
    };

    apiCall("Updating file", config, async config => {
        const result = await axios.request(config);
    }, dispatch, getState, false);
};

export const fetchAssetsForKMZExport = (siteId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/load_assets_for_kmz_export`,
    };
    apiCall("Loading assets for KMZ", config, async config => {
        const response = await axios.request(config);
        const assets = response.data;
            callback(assets);
    }, dispatch, getState, false);
}