import {
    Button,
    ButtonGroup,
    Checkbox, Col,
    ControlLabel,
    DropdownButton,
    FormGroup,
    MenuItem,
    Row
} from "react-bootstrap";
import React, {useState, useEffect, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEnvelope, faEnvelopeOpenText, faMailBulk} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import {select} from "../../common/commonHandlers";
import {bindActionCreators} from "redux";
import * as actions from './NotificationsApi'
import {connect} from "react-redux";
import {CrewWorkOrderMessage} from "../CrewWorkOrdersPage/CrewWorkOrderMessage";
import {browserHistory} from "react-router";
import {debounce} from "throttle-debounce";


const CrewMessages = ({auth, docks, ...props}) => {
    const [messageType, setMessageType] = useState('unread')
    const [messages, setMessages] = useState([])
    const [employees, setEmployees] = useState([])
    const [personId, setPersonId] = useState(null)
    const [showDisabledUsers, setShowDisabledUsers] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [workOrderId, setWorkOrderId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const searchRef = useRef()

    const {
        getEmployees,
        getCrewMessages,
        getUserStatus,
        markAsReadCrewMessages,
        markAsReadCrewMessagesWO,
        getShowDisabledUsers
    } = props.actions

    useEffect(() => {
        if (docks.active === 'crew messages') {
            getShowDisabledUsers(({show_disabled_users_for_notes}) => {
                setShowDisabledUsers(show_disabled_users_for_notes)
            })
        }
    }, [docks.active])

    useEffect(() => {
        fetchCrewUserMessages(messageType, personId ? personId : null, workOrderId, messages => {
            setMessages(messages)
        })
    }, [messageType, personId, workOrderId])

    useEffect(() => {
        getStatus()
    }, [messages])


    useEffect(() => {
        const {saveShowDisabledUsers} = props.actions
        if (docks.active === 'crew messages') {
            saveShowDisabledUsers(showDisabledUsers, ({show_disabled_users}) => {
                fetchEmployees(show_disabled_users)
            })
        }
    }, [showDisabledUsers])

    useEffect(() => {
        fetchEmployees(showDisabledUsers)
    },[])

    const fetchEmployees = (showDisabledUsers) => {
        getEmployees(showDisabledUsers, data => {
            setEmployees(data.employees)
        })
    }

    const setMessageTypeTitle = (type) => {
        const icon = type === 'unread' ? faEnvelope : 'read' ? faEnvelopeOpenText : faMailBulk
        const name = type.charAt(0).toUpperCase() + type.slice(1)
        const messagesNumber = messages?.length > 25 ? '25+' : messages?.length

        return (
            <div><FontAwesomeIcon icon={icon} className='font16 mr-7'/>
                <span>{name} messages ({messagesNumber})</span>
            </div>
        )
    }

    const fetchCrewUserMessages = (type, personId, workOrderId) => {
        getCrewMessages(type, personId, workOrderId, messages => {
            setMessages(messages)
        })
    }

    const showWorkOrderThread = (work_order_id) => {
        browserHistory.push(`/crew_work_orders?showThread=true&work_order_id=${work_order_id}`)
    }

    const employeeOptions = employees.filter(x => x.label !== 'Not assigned');

    const proposalSearch = e => {
        if (e && e.length > 2) {
            setSearchResults([{value: 1, label: ''}])
            setIsLoading(true)
            const params = {
                type: 'crew_messages',
                person_id: personId,
                is_search_work_order: true,
                read_type: messageType
            }
            props.actions.search(e, params, res => {
                setSearchResults(res.map(x => ({value: x.wo_id, label: x.work_order_no})))
                setIsLoading(false)
                if (searchRef) {
                    searchRef.current.focus()
                }
            })
        }
    }

    const onProposalSearch = debounce(1000, proposalSearch)

    const markMessageAsRead = (id) => {
        if (messageType === 'unread' && messages?.length > 0) {
            setMessages(messages.filter(m => m.id !== id))
        }
    }

    const getStatus = () => {
        if (auth?.user_id && auth?.client_id) {
            getUserStatus(auth.user_id, auth.client_id)
        }
    }

    const handleNumberOnlyInput = e => {
        if (['Enter', 'NumpadEnter'].includes(e.nativeEvent.code)) {
            e.preventDefault();
        }
    };

    return (
        <div className='message-dock'>
            <div className="margin10 top27">
                <ButtonGroup justified>
                    <Button href="#" className='font12'
                            disabled={messages?.length === 0 || messages?.length > 0 && messages.every(m => m.read === true)}
                            onClick={() => {
                                markAsReadCrewMessages(null, true, () => fetchCrewUserMessages(messageType, personId, messages => {
                                    setMessages(messages)
                                }))
                            }}>
                        <span className='mr-7'>Mark all as read</span>
                        <FontAwesomeIcon icon={faCheck} className='font14'/>
                    </Button>
                    <DropdownButton className='vhCenter no-caret-in-dropdown vertical-align font12'
                                    title={setMessageTypeTitle(messageType)}
                                    id="bg-justified-dropdown">
                        <MenuItem eventKey="1"
                                  onClick={() => setMessageType('unread')}>
                            <div>
                                <FontAwesomeIcon icon={faEnvelope} className='font16 mr-7'/>
                                <span>Unread messages</span>
                            </div>
                        </MenuItem>
                        <MenuItem eventKey="2" className='vertical-align'
                                  onClick={() => setMessageType('read')}>
                            <FontAwesomeIcon icon={faEnvelopeOpenText} className='font16 mr-7'/>
                            <span>Read messages</span>
                        </MenuItem>
                        <MenuItem eventKey="2" className='vertical-align'
                                  onClick={() => setMessageType('all')}>
                            <FontAwesomeIcon icon={faMailBulk} className='font16 mr-7'/>
                            <span>All messages</span>
                        </MenuItem>
                    </DropdownButton>
                </ButtonGroup>
            </div>
            <Row>
                <Col xs={12}>
                    <form className="form-inline">
                        <FormGroup>
                            <ControlLabel>Author</ControlLabel>
                            <Select className="Select" classNamePrefix="select"
                                    options={employeeOptions}
                                    value={select(employeeOptions, personId)}
                                    isClearable
                                    placeholder='Search by author'
                                    onChange={e => {
                                        if (e) {
                                            setPersonId(e.value)
                                        } else {
                                            setPersonId(null)
                                        }
                                    }}
                            />
                        </FormGroup>
                    </form>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <form className="form-inline">
                        <FormGroup>
                            <ControlLabel>Search</ControlLabel>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    options={searchResults}
                                    ref={searchRef}
                                    onKeyDown={handleNumberOnlyInput}
                                    key={searchResults ? searchResults.length : 0}
                                    openMenuOnFocus={searchResults?.length > 0 && !workOrderId}
                                    value={select(searchResults, workOrderId)}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    isClearable
                                    onInputChange={onProposalSearch}
                                    onChange={e => {
                                        if (e) {
                                            setWorkOrderId(e?.value)
                                        } else {
                                            setWorkOrderId(null)
                                        }
                                    }}
                            />
                        </FormGroup>
                    </form>
                </Col>
            </Row>
            <Row>
                <form className="form-inline">
                    <Col md={3}/>
                    <Col md={9}>
                        <FormGroup className='d-flex'>
                                     <span className="vertical-align">
                                        <ControlLabel className='full-width'>Disabled users</ControlLabel>
                                        <Checkbox
                                            name="showDisabledUsers"
                                            className='no-right-padding no-left-padding'
                                            onChange={e => setShowDisabledUsers(e.target.checked)}
                                            checked={showDisabledUsers}
                                        />
                                        </span>
                        </FormGroup>
                    </Col>
                </form>
            </Row>
            <div className="margin10 top32 messages-dock-container">
                {messages && messages.length > 0 && messages.map((message) => {
                    return <CrewWorkOrderMessage
                        message={message}
                        employees={employees}
                        markAsRead={markAsReadCrewMessages}
                        markThreadAsRead={markAsReadCrewMessagesWO}
                        type={messageType}
                        showWorkOrderThread={showWorkOrderThread}
                        getMessages={fetchCrewUserMessages}
                        markMessageAsRead={markMessageAsRead}
                        getUserStatus={getStatus}
                    />
                })}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        userStatus: state.userStatus.userStatus,
        docks: state.docks,
        users: state.users.users,
        email: state.auth.email,
        state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CrewMessages);
