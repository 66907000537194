import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {FETCH_CUSTOMER_INFO} from "../ClientsPage/constants";
import {addAlert} from "../App/actions";

export const createClient = (client, user_password, register_id,subscription,stripe_payment_method, callback) => async (dispatch, getState) => {

    const config = {
        method: 'POST',
        url: `/api/sign_up/register`,
        data: {client, user_password, register_id, subscription, stripe_payment_method}
    };
        apiCall("Creating client", config, async config => {
            const response = await axios.request(config);
            callback && callback(response.data);
        }, dispatch, getState, false, callback);
};
export const createRegister = (register, callback) => async (dispatch, getState) => {
    const config = {
        method: register.id ? 'PUT' : 'POST',
        url: register.id ? `/api/sign_up/register_update_data/${register.id}` : `/api/sign_up/register_save_data`,
        data: {register}
    };

    apiCall("Saved client data", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const checkUniqueName = (name, callback) => async (dispatch, getState) => {

    const config = {
        method: 'GET',
        url: `/api/sign_up/check_unique_name`,
        params: {name}
    };

    apiCall("Check unique name", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const checkUniqueEmail = (email, user_id, employee_check, callback) => async (dispatch, getState) => {

    const config = {
        method: 'GET',
        url: `/api/sign_up/check_unique_email`,
        params: {email, user_id, employee_check}
    };

    apiCall("Check unique email", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState,false);
};

export const fetchStateAndCityForCustomer = (zipcode, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sign_up/zipcode_search`,
        params: {
            zipcode: zipcode
        }
    };
    apiCall("Fetching state and city", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}

export const activateAccount = (token, callback) => async (dispatch, getState) => {

    const config = {
        method: 'PUT',
        url: `/api/sign_up/activate_account`,
        params: {token}
    };

    apiCall("Account activated", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState,false);
};

export const getPrices = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_prices`,
        method: 'GET'
    };
    apiCall("Get prices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};
export const getApiKey= (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_stripe_api_key`,
        method: 'GET'
    };
    apiCall("Get api key", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};