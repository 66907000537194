import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import * as MyActions from "./actions"
import {addAlert} from "../App/actions"
import {showJSADialog} from "../JobSiteAnalysisPage/actions"
import {showEmailDialog} from "../../components/email/actions"
import {fetchSalesArborists} from "../../actions/salesArborists"
import {downloadPdf} from "../CustomerViewPage/actions"
import {
    Button,
    Col,
    FormControl,
    FormGroup,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    NavItem,
    Panel,
    PanelFooter,
    Row
} from "react-bootstrap"

import ProposalForm from "./ProposalForm"
import ProposalServiceForm from "./ProposalServiceForm"

import AssetList from "./AssetList"
import AssetEditorModal from "../AssetEditorModal"

import moment from "moment"

import {LinkContainer} from "react-router-bootstrap"

import Map from "./Map"
import SortableServiceList from "./SortableServiceList"
import Dollars from "../../components/Dollars"
import Select from "react-select"
import {Link} from "react-router";
import {select} from "../../common/commonHandlers";

const Actions = {...MyActions, addAlert, downloadPdf, fetchSalesArborists, showEmailDialog, showJSADialog};

// using some little inline style helpers to make the app look okay
const grid = 8;
const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'none',
    padding: grid,
});

class MapViewPage extends Component {

    componentWillMount() {
        let customerId = this.props.location.query.customer_id;
        let siteId = this.props.location.query.site_id;

        let salesArboristId;
        if (this.props.auth.roles.some(r => r === 'sales_arborist')) {
            salesArboristId = this.props.auth.employee_id;
        }

        this.state = {
            mapCenter: {lat: 40.011796, lng: -105.214286},
            expandProposalInfoPanel: false,
            inActiveEditMode: false,
            active: true,
            salesArboristId,
            search: ''
        };

        if (customerId && siteId) {
            this.props.actions.loadCustomer(customerId, siteId);
        }
        if (document) {
            document.body.className = 'mapview-fixed-height-body'
        }
    }

    componentWillUnmount() {
        if (document) {
            document.body.className = null
        }
    }

    componentDidMount() {
        console.log("Mapview Mounted")
        //we always need this stuff no matter if we do a new or edit existing
        this.props.actions.fetchPriorities()
        this.props.actions.fetchServices()
        this.props.actions.fetchEquipmentList()

        if (this.props.params.proposalId) {
            //we transitioned form another page to mapview along with a proposal id to edit
            this.loadProposalAndRelatedData(this.props.params.proposalId)
        } else {
            //we transitioned from another page to a new proposal form
            this.props.actions.resetMapView()
        }
    }

    componentWillReceiveProps(newProps) {
        // console.log("Mapview Will Received Props")
        if (
            newProps.params.proposalId &&
            newProps.params.proposalId !== this.props.params.proposalId
        ) {
            //we transitioned from editing one proposal to a different proposal (the duplicate button triggers this)
            this.loadProposalAndRelatedData(newProps.params.proposalId)
        } else if (
            newProps.params.proposalId === undefined &&
            this.props.params.proposalId
        ) {
            //we transitioned from editing existing to creating a new proposal
            this.props.actions.resetMapView() //this blanks out the proposal form only
        }
        if (newProps.proposal && !newProps.proposal.salesArboristId) {
            newProps.proposal.salesArboristId = this.state.salesArboristId;
            this.setState({proposal: newProps.proposal});
        }

        if (this.props.haveOpenService !== newProps.haveOpenService) {
            this.forceUpdate()
        }

    }

    /*
    shouldComponentUpdate = (nextProps, nextState) => {
      if(nextProps.proposalServices &&
        nextProps.proposalServices.length > 0 &&
        nextProps.proposalServices.length == this.props.proposalServices.length) {

        if(nextProps.siteAssets &&
          nextProps.siteAssets.length > 0 &&
          nextProps.siteAssets.length == this.props.siteAssets.length) {
          return false
        }
      }
      return true
    }
    */

    loadProposalAndRelatedData = proposalId => {
        this.props.actions
            .loadProposalForEditing(proposalId)
            .then(response => {
                //Lets pretend that we dont know the proposalid until this api call returns successfully
                //just to demonstrate redux action/promise chaining
                //For some reason the modal screws things up and proposal becomes nil
                this.props.actions.fetchProposalServices(proposalId)
                this.props.actions.fetchAssetsForSite()
                this.props.actions.fetchSiteInfo()
            })
            .catch(error => {
                console.log(
                    "Unable to fetch the proposal. Error given was " + error.toString()
                )
            })
    }

    onAssetClick = asset => {
        //   this.setState({mapCenter: {lat: asset.latitude, lng: asset.longitude}})
        // asset.selected = !asset.selected
        this.panTo(asset.latitude, asset.longitude)

        if (!asset.__locked) {
            this.props.actions.onAssetLocked(asset.id)
        } else {
            this.props.actions.onAssetUnlocked(asset.id)
        }

    }

    // mouseOverAsset(asset){
    //   this.setState({highlightedMarkerId: asset.id})
    // }
    //
    //
    // mouseOutAsset(){
    //   this.setState({highlightedMarkerId: null})
    // }

    panTo = (lat, lng) => {
        //window._googleMapComponent.props.map.setCenter({lat: lat, lng: lng})
        window._googleMapComponent.panTo &&
        window._googleMapComponent.panTo({lat: lat, lng: lng})
    }

    onMapDragEnd = () => {
        //we'll access the global instance of the map becuase the event handler doesn't want to send what we need
        //  const gmaps = window._googleMapComponent.props.map
        // this.setState({mapCenter: {lat: gmaps.getCenter().lat(), lng: gmaps.getCenter().lng()}})
    }

    onMarkerAdd = marker => {
        console.log("map clicked lat ", marker.getPosition().lat())
        this.props.actions.editNewMarker(
            marker.getPosition().lat(),
            marker.getPosition().lng()
        )
        marker.setMap(null) //we want to override google maps adding the marker to its dom since we're using data/react to control whats on the map
        return false
    }

    expandProposalInfoPanel = (e) => {
        e.preventDefault();
        this.toggleProposalInfoPanelState()
    }

    toggleProposalInfoPanelState = () => {
        this.setState({
            expandProposalInfoPanel: this.state.expandProposalInfoPanel ? false : true
        })
    }

    closeProposalInfoPanelState = () => {
        this.setState({
            expandProposalInfoPanel: false
        })
    }

    toggleInActiveEditMode = () => {
        if (this.state.inActiveEditMode) {
            this.handleSaveSiteMap()
        }

        this.setState({
            inActiveEditMode: this.state.inActiveEditMode ? false : true
        })
    }

    renderProposalTotal = (proposalServices, proposal) => {
        if (proposalServices === undefined) {
            return null
        }

        const total = proposalServices.reduce((total, ps) => {
            return total + (ps.serviceStatusName !== "Not Offered" ? ps.serviceCost : 0)
        }, 0)

        return (
            <strong>
                Total: {<Dollars amount={total + total * proposal.tax}/>}
            </strong>
        )
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        // reorder proposal services
        this.props.actions.reorderProposalServices(result.source.index, result.destination.index)
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (newIndex != oldIndex) {
            // reorder proposal services
            this.props.actions.reorderProposalServices(oldIndex, newIndex)
        }
        // this.setState({
        //   items: arrayMove(this.state.items, oldIndex, newIndex),
        // })
    }

    handleSaveSiteMap = () => {
        const {site, siteAssets} = this.props

        const map = window._googleMapComponent
        if (map) {
            const center = map.getCenter()
            let siteMap = site

            // save site info

            if (siteMap) {
                const latitude = center.lat()
                const longitude = center.lng()
                const zoom = map.getZoom()
                const mapType = map.getMapTypeId()
                if (siteMap.default_zoom != zoom ||
                    siteMap.map_type != mapType ||
                    Math.abs(siteMap.latitude - latitude) > 0.00001 ||
                    Math.abs(siteMap.longitude - longitude) > 0.00001) {

                    siteMap.default_zoom = zoom
                    siteMap.map_type = mapType
                    siteMap.latitude = latitude
                    siteMap.longitude = longitude
                    // update map setting
                    siteMap.id = site.id
                    this.props.actions.updateSiteInfo(siteMap)
                }
            }

            // save markers info
            siteAssets.forEach(asset => {
                this.props.actions.saveMarkerPosition(asset)
            })
        }
    }
    showEmailDialog = () => {
        const {
            proposal,
            email
        } = this.props;
        this.props.actions.showEmailDialog(
            {
                emailType: "proposal",
                referenceId: proposal.id,
                recipient: proposal.siteContactEmail,
                defaultEmail: email
            });
    };

    render() {
        const {
            username,
            salesArboristSearch,
            salesArborists,

            selectedCustomer,
            customerList,
            customerSearch,

            siteSearch,
            customerSites,
            siteAssets,

            proposal,
            proposalServices,
            newProposalService,

            serviceList,
            equipmentList,
            priorities,

            editingAssetId,

            siteAssetsDbhs,
            site,
            haveOpenService,
            email
        } = this.props
        const {active, search} = this.state;
        let filteredAssets = active !== null ? siteAssets.filter(a => a.disabled === !active) : siteAssets;
        filteredAssets = search !== '' ? filteredAssets.filter(a => {
            return (a.plant_name && a.plant_name.toLowerCase().includes(search.toLowerCase()))
                || a.label && a.label.toLowerCase().includes(search.toLowerCase());
        }) : filteredAssets;

        let haveOpenServices = false
        for (var serviceId in haveOpenService) {
            haveOpenServices = haveOpenServices || haveOpenService[serviceId]
        }
        const isReorderable = !haveOpenServices && proposalServices.reduce((status, ps) =>
            status && ps.serviceStatusName === 'Not Offered' || ps.serviceStatusName === 'Proposed' || ps.serviceStatusName === 'Draft', true)

        const headerStyle = {
            fontSize: 12,
            cursor: "row-resize",
        }

        const proposalHeaderRow = (
            <div>
                <Row className="mobile-padding">
                    <Col md={2}>
                        {proposal && proposal.id ?
                            <Nav>
                                <NavDropdown
                                    eventKey={2}
                                    title={`${proposal.customerName} > ${proposal.siteName}`}
                                    id={`proposal-menu`}
                                    className="menu-show-fixer proposal-menu-padding-reset"
                                >
                                    <LinkContainer to={`/customer/info/${proposal.customerId}`}>
                                        <MenuItem>Info</MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to={`/customer/proposals/${proposal.customerId}`}>
                                        <MenuItem bsSize="small">Proposals</MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to={`/customer/sites/${proposal.customerId}`}>
                                        <MenuItem bsSize="small">Sites</MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to={`/customer/work_orders/${proposal.customerId}`}>
                                        <MenuItem bsSize="small">Work Orders</MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to={`/customer/invoices/${proposal.customerId}`}>
                                        <MenuItem bsSize="small">Invoices</MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to={`/customer/work_history/${proposal.customerId}`}>
                                        <MenuItem>Work History</MenuItem>
                                    </LinkContainer>
                                </NavDropdown>
                            </Nav> : `Create a New Proposal`}
                    </Col>
                    <Col md={2} xs={6}>
                        <strong>Arborist: </strong>
                        {proposal.salesArboristName}
                    </Col>
                    <Col md={1} xs={6} className="text-success">
                        {this.renderProposalTotal(proposalServices, proposal)}
                        <div></div>
                    </Col>
                    <Col md={2} xs={6}>
                        <Button
                            className="show-for-large"
                            bsStyle="primary"
                            bsSize="xsmall"
                            onClick={this.toggleProposalInfoPanelState}
                            disabled={this.props.params.proposalId === undefined ? true : false}
                        >
                            {this.state.expandProposalInfoPanel ? "Close Proposal Information " : "Open Proposal Information"}
                        </Button>
                    </Col>
                    <Col md={5}>
                        <Row>
                            <Col md={1} xs={6} className={"text-left"}>
                                <Button
                                    bsSize="xs"
                                    onClick={this.showEmailDialog}
                                    bsStyle="success"
                                >
                                    Email
                                </Button>
                            </Col>
                            <Col md={1} xs={6} className={"text-left"}>
                                <Button
                                    bsSize="xs"
                                    onClick={() => this.props.actions.showJSADialog({siteId: site.id, ignoreAddMessage: true})}
                                    bsStyle="success"
                                >
                                    JSA
                                </Button>
                            </Col>
                            <Col md={1} xs={6} className={"text-left"}>
                                <Button bsStyle="info"
                                        bsSize="xs"
                                        onClick={() =>this.props.actions.downloadPdf(proposal)
                                        }>
                                    PDF
                                </Button>
                            </Col>
                            <Col md={2} xs={12} className={"text-right"}>
                                <Nav>
                                    <NavDropdown
                                        eventKey={2}
                                        title={`Proposal #${proposal.proposalNo}`}
                                        id={`proposal-menu`}
                                        className="menu-show-fixer proposal-menu-padding-reset">
                                        <LinkContainer to={`/print_proposal/${proposal.id}`}>
                                            <MenuItem bsSize="small"><h5 className="text-success">Print Proposal</h5>
                                            </MenuItem>
                                        </LinkContainer>
                                        <LinkContainer to={`/proposal_service_status/${proposal.id}`}>
                                            <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                                        </LinkContainer>
                                        <LinkContainer to={`/work_orders/${proposal.id}`}>
                                            <MenuItem>Manage Work Orders</MenuItem>
                                        </LinkContainer>
                                        <NavItem>
                                            <Button
                                                onClick={() => this.props.actions.duplicateProposal()}
                                                bsStyle="warning"
                                                bsSize="xsmall"
                                            >
                                                Duplicate Proposal
                                            </Button>
                                        </NavItem>
                                        <li className="pointer">
                                            <Link onClick={() => this.props.actions.moveToProposed(proposal.id,
                                                () => this.loadProposalAndRelatedData(proposal.id))}>
                                                Move to proposed</Link>
                                        </li>
                                    </NavDropdown>
                                </Nav>

                            </Col>
                            <Col md={2} xs={6} className={"text-left"}>
                                {` created ${moment(proposal.proposalDate).format("l")}`}
                            </Col>
                            <Col md={2} xs={6} className={"text-left pointer"}>
                                <Link to={`mapview/${proposal.id}`}><b>MODERN VIEW</b></Link>
                            </Col>
                            <Col md={2} xs={6} className={"text-center"}>
                                <Button
                                    bsStyle="info"
                                    bsSize="small"
                                    onClick={this.toggleInActiveEditMode}
                                >
                                    {this.state.inActiveEditMode ? "Save Map" : "Edit Map"}
                                </Button>
                            </Col>
                            <div className="clearfix"></div>
                            <div className="proposal-info-btn-styles">
                                <Col xs={12}>
                                    <Button
                                        className="show-for-small"
                                        bsStyle="primary"
                                        bsSize="xsmall"
                                        onClick={this.expandProposalInfoPanel}
                                        disabled={this.props.params.proposalId === undefined ? true : false}
                                    >
                                        {this.state.expandProposalInfoPanel ? "Close Proposal Information " : "Open Proposal Information"}
                                    </Button>
                                </Col>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        )

        const titleStyle = {
            fontSize: 12,
            cursor: "pointer",
            shadow: "none",
        }

        const _newPS = (
            <Panel
                collapsible
                bsClass="ps-default-panel-wrap"
                defaultExpanded={false}
                header={
                    <Row>
                        <Col md={6} xs={6}>
                            <p style={titleStyle}>
                                <Button
                                    onClick={() => this.props.actions.onAddNewPs()}
                                    bsSize="sm"
                                    bsStyle="success"
                                >
                                    Add New Proposal Service
                                </Button>
                            </p>
                        </Col>
                        <Col md={6} xs={6} className="text-success text-right">
                            {this.renderProposalTotal(proposalServices, proposal)}
                        </Col>
                    </Row>
                }
            />
        )

        const options = [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}];
        return (
            <Grid fluid>
                    {proposalHeaderRow}
                    <Panel
                        collapsible
                        expanded={this.props.params.proposalId === undefined ? true : this.state.expandProposalInfoPanel}
                    >
                        <ProposalForm
                            proposal={proposal}
                            leadId={this.props.location.query.lead_id}
                            updateAttribute={this.props.actions.updateAttribute}
                            salesArboristSearch={salesArboristSearch}
                            updateSalesArborist={this.props.actions.updateSalesArborist}
                            salesArborists={salesArborists}
                            loadSalesArborists={this.props.actions.fetchSalesArborists}
                            selectSalesArborist={this.props.actions.selectSalesArborist}
                            customerSearch={customerSearch}
                            customerList={customerList}
                            selectedCustomer={selectedCustomer}
                            updateCustomerSearch={this.props.actions.updateCustomerSearch}
                            onSelectedCustomer={this.props.actions.onSelectedCustomer}
                            site={siteSearch}
                            customerSites={customerSites}
                            onUpdateSite={this.props.actions.updateSite}
                            onSelectSite={this.props.actions.selectSite}
                            updateProposalDate={this.props.actions.updateProposalDate}
                            submitProposal={this.props.actions.submitProposal}
                            deleteProposal={this.props.actions.deleteProposal}
                            toggleProposalInfoPanelState={this.toggleProposalInfoPanelState}
                            closeProposalInfoPanelState={this.closeProposalInfoPanelState}
                        />
                    </Panel>

                <Row>
                    <Col md={5}>
                        <Panel
                            footer={_newPS}
                            className="mapview-proposal-services remaining"
                            id="mapview-proposal-services"
                        >
                            <Row style={{padding: 0}}>
                                <Col md={12} style={{padding: 0}}>
                                    <div className="vertical-responsive-height">
                                        <Row>
                                            {proposal &&
                                            proposal.id &&
                                            <Col md={12}>
                                                <h5>
                                                    {Array.isArray(proposalServices)
                                                        ? proposalServices.length
                                                        : "0"}
                                                    {" "}
                                                    Proposal Services
                                                </h5>
                                                {/* check if services are sortable */}
                                                {!isReorderable && Array.isArray(proposalServices) &&
                                                proposalServices.map(ps => {
                                                    return (
                                                        <ProposalServiceForm
                                                            key={ps.id}
                                                            proposalService={ps}
                                                            siteAssets={siteAssets}
                                                            serviceList={serviceList}
                                                            updateService={this.props.actions.updateService}
                                                            selectService={this.props.actions.selectService}
                                                            equipmentList={equipmentList}
                                                            addEquipment={this.props.actions.addEquipment}
                                                            removeEquipment={this.props.actions.removeEquipment}
                                                            priorities={priorities}
                                                            haveOpenService={!!haveOpenService[ps.id]}
                                                            updatePsAttribute={
                                                                this.props.actions.updatePsAttribute
                                                            }
                                                            updateHaveOpenService={
                                                                this.props.actions.updateHaveOpenService
                                                            }
                                                            savePsChanges={this.props.actions.savePsChanges}
                                                            addAssetsToPs={this.props.actions.addAssetsToPs}
                                                            removeAssetFromPs={
                                                                this.props.actions.removeAssetFromPs
                                                            }
                                                            siteAssetsDbhs={siteAssetsDbhs}
                                                            movePs={
                                                                proposal.isAcceptable
                                                                    ? this.props.actions.movePs
                                                                    : undefined
                                                            }
                                                            deleteProposalService={
                                                                this.props.actions.deleteProposalService
                                                            }
                                                            onAssetMouseOver={this.props.actions.onAssetMouseOver}
                                                            onAssetMouseOut={this.props.actions.onAssetMouseOut}
                                                            onAddNewPs={this.props.actions.onAddNewPs}
                                                            onAssetClick={this.onAssetClick}
                                                        />
                                                    )
                                                })}

                                                {isReorderable && <SortableServiceList/>}

                                                <ProposalServiceForm
                                                    key={
                                                        newProposalService === undefined
                                                            ? 0
                                                            : newProposalService.id
                                                    }
                                                    proposalService={newProposalService}
                                                    siteAssets={siteAssets}
                                                    serviceList={serviceList}
                                                    updateService={this.props.actions.updateService}
                                                    selectService={this.props.actions.selectService}
                                                    equipmentList={equipmentList}
                                                    addEquipment={this.props.actions.addEquipment}
                                                    removeEquipment={this.props.actions.removeEquipment}
                                                    priorities={priorities}
                                                    updatePsAttribute={this.props.actions.updatePsAttribute}
                                                    savePsChanges={this.props.actions.savePsChanges}
                                                    addAssetsToPs={this.props.actions.addAssetsToPs}
                                                    removeAssetFromPs={this.props.actions.removeAssetFromPs}
                                                    siteAssetsDbhs={siteAssetsDbhs}
                                                    deleteProposalService={() => {
                                                    }}
                                                    updateHaveOpenService={
                                                        this.props.actions.updateHaveOpenService
                                                    }
                                                    onAddNewPs={this.props.actions.onAddNewPs}
                                                />
                                            </Col>
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Panel>
                    </Col>


                    <Col md={2} className="assets-list">
                        <FormGroup bsSize="small">
                            <Select className="Select" classNamePrefix="select"
                                name="active"
                                value={select(options, active)}
                                options={options}
                                onChange={e => this.setState({active: e ? e.value : null})}
                                placeholder="All"
                            />
                        </FormGroup>
                        <FormGroup bsSize="small">
                            <FormControl
                                type="text"
                                placeholder="Search..."
                                value={search}
                                onChange={e => this.setState({search: e.target.value})}
                            />
                        </FormGroup>
                        <AssetList
                            assetList={filteredAssets}
                            onAssetClick={this.onAssetClick.bind(this)}
                            onAssetDoubleClick={this.props.actions.onAssetDoubleClick}
                            updateAssetAttribute={this.props.actions.updateAssetAttribute}
                            onMouseOver={this.props.actions.onAssetMouseOver}
                            onMouseOut={this.props.actions.onAssetMouseOut}
                        />
                    </Col>

                    <Col md={5}>
                        <div className="vertical-responsive-map">
                            <Map
                                site={site}
                                onMapDragEnd={this.onMapDragEnd.bind(this)}
                                siteAssets={filteredAssets}
                                onMarkerMouseOut={this.props.actions.onMarkerMouseOut}
                                onMarkerMouseOver={this.props.actions.onMarkerMouseOver}
                                onMarkerClick={this.props.actions.onMarkerClick}
                                onMarkerDragEnd={this.props.actions.onMarkerDragEnd}
                                onMarkerAdd={this.onMarkerAdd}
                                onMarkersAdd={(assetId, markers) => {
                                    this.props.actions.saveDuplicates(assetId, markers, () => {
                                        this.props.actions.fetchAssetsForSite();
                                    });
                                }}
                                editable={this.state.inActiveEditMode}
                                client={this.props.client}
                            />
                        </div>
                    </Col>
                </Row>
                {editingAssetId !== null
                    ? <AssetEditorModal
                        editingAssetId={editingAssetId}
                        doneEditingAsset={this.props.actions.doneEditingAsset}
                        updateAssetAttribute={this.props.actions.updateAssetAttribute}
                    />
                    : null}
            </Grid>
        )
    }
}

//TODO: the proposalDate should have the users's TZ offset applied otherwise teh date maybe 1 day off.

function mapStateToProps(state, ownProps) {
    return {
        username: state.auth.email,
        salesArboristSearch: state.mapView.salesArboristSearch,
        salesArborists: state.salesArborists.salesArboristsList,
        customerList: state.mapView.customerSearchList,
        customerSearch: state.mapView.customerSearch,
        siteSearch: state.mapView.customerSiteSearch,
        customerSites: state.mapView.customerSites,
        siteAssets: state.mapView.siteAssets,

        proposal: state.mapView.proposal,
        proposalServices: state.mapView.proposalServices.filter(ps => ps.id > 0),
        newProposalService: state.mapView.proposalServices.find(ps => ps.id === 0),

        serviceList: state.mapView.services,
        equipmentList: state.mapView.equipments,

        priorities: state.mapView.priorities,
        auth: state.auth,

        editingAssetId: state.mapView.editingAssetId,
        selectedCustomer: state.mapView.selectedCustomer,
        siteAssetsDbhs: state.mapView.siteAssetsDbhs,
        haveOpenService: state.mapView.haveOpenService,
        site: state.mapView.sites[`site_${state.mapView.proposal.siteId}`],
        email: state.auth.email,
        client: state.client.customerInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(MapViewPage)
