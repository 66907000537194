import React from "react"


const PlantName = ({plant}) => (
    <span>
        {
            plant && plant.indexOf(', ') > -1 ?
                <p style={{margin: 0, display: 'inline'}}>{plant.split(',')[0]}, <i>{plant.split(',')[1]}</i></p> : plant
        }

    </span>
)


export default PlantName;


