import React from 'react';
import {Button, Col, FormGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import './TransferPlant.css';
import Select from "react-select";
import {select} from "../../../common/commonHandlers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExchangeAlt, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import ResourceComponent from "../../../components/ResourceComponent";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "./TransferPlantAPI";
import PlantName from "../../../components/PlantName";


class TransferPlant extends ResourceComponent {

    constructor(props) {
        super(props);
        this.destinationPlantSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.sourcePlantSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.state = {
            resource: {
                source_plant_id: null,
                destination_plant_id: null,
            },
        }
    }

    componentDidMount() {
        this.sourcePlantSearch = this.buildSearchComponent('source_plant_id', this.props.actions.searchForPlant);
        this.destinationPlantSearch = this.buildSearchComponent('destination_plant_id', this.props.actions.searchForPlant);

    }

    transferPlant = (e, resource) => {
        e.preventDefault();
        this.props.actions.transferPlant(resource, callback => this.setState(prevState => ({
            resource: {
                ...prevState.resource,
                source_plant_id: null,
                destination_plant_id: null,
            }
        })))
    };


    render() {

        const formatOptionLabel = ({value, label, customAbbreviation}) => (
            <div style={{display: "flex"}}>
                <PlantName plant={label}/>
                <div style={{marginLeft: "10px", color: "#ccc"}}>
                    {customAbbreviation}
                </div>
            </div>
        );

        const {resource} = this.state;
        const {source_plant_id, destination_plant_id} = resource;
        const sourcePlantOptions = this.sourcePlantSearch.searchOptions();
        const destinationPlantOptions = this.destinationPlantSearch.searchOptions();

        const tooltip = (
            <Tooltip id="tooltip">
                <div className="text-left">
                    <strong>Plant
                        transfer</strong><span> - option that allow transfer plant assets to another plant</span>
                </div>
            </Tooltip>
        );

        return (
            <div id="transfer_plant">
                <h4 className="transfer_plant_title">
                    <span className="mr-5">Transfer Plant</span>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mb2 ml-4"/>
                    </OverlayTrigger>
                </h4>
                <div className="transfer_plant_inputs">
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(sourcePlantOptions, source_plant_id)}
                                        options={sourcePlantOptions}
                                        formatOptionLabel={formatOptionLabel}
                                        placeholder="Select plant to transfer"
                                        isClearable
                                        onInputChange={this.sourcePlantSearch.search}
                                        onChange={this.selectResourceAttr('source_plant_id', this.handleFilterChange)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="transfer_plant_input">
                        <Col md={10}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(destinationPlantOptions, destination_plant_id)}
                                        options={destinationPlantOptions}
                                        formatOptionLabel={formatOptionLabel}
                                        placeholder="Select destination plant"
                                        isClearable
                                        onInputChange={this.destinationPlantSearch.search}
                                        onChange={this.selectResourceAttr('destination_plant_id', this.handleFilterChange)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={10} className='text-right'>
                            <Button className="transfer_btn"
                                    disabled={!source_plant_id || !destination_plant_id}
                                    onClick={e => this.transferPlant(e, resource)}>
                                <div className="vcenter">
                                    <FontAwesomeIcon icon={faExchangeAlt} className="mr-9 font16"/>
                                    <span>Transfer Plant</span>
                                </div>
                            </Button>
                        </Col>
                        <Col md={2}/>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientsList: state.admin.clientsList,
        state
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferPlant);