import React, {useState} from 'react';
import {Col, Modal, Row, Button, FormGroup, ControlLabel, FormControl, Form, Glyphicon} from "react-bootstrap";
import {resendVerifyEmail} from "../HomePage/actions";

export const SendgridModal = ({show, closeModal, sendgridEmailWasSend, verifySender, resendVerifyEmail}) => {

    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [sendgridError, setSendgridError] = useState('')
    const [sendgridMessage, setSendgridMessage] = useState('')
    const [emailSend, setEmailSend] = useState(false)
    return (
        <Modal
            animation={false}
            show={show}
            onHide={closeModal}
            className={"heightAuto maxHeight90 sendgridModal fontIOS"}
            backdrop="static"
            bsSize="xs"
        >
            <Modal.Header>
                <Modal.Title>Verify Sender Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={sendgridEmailWasSend ? 12 : 6}>
                        <h4>Important information</h4>
                        {sendgridEmailWasSend ?
                            <p>Please check your mailbox and follow the verification instruction or resend verification
                                email by clicking <strong>Resend</strong> button.</p> :
                            <>
                                <p>If the email address is not verified, your email will be sent from a default Treehub email.
                                    If you want to use your own email address: </p>
                                <p>Please fill out the required form accurately
                                    and completely,
                                    and forward received message to address <b>info@treehub.me</b>
                                </p>
                                <p>If you want to verify domain, please contact us <b>info@treehub.me</b></p>
                            </>
                            }
                    </Col>
                    <Col md={sendgridEmailWasSend ? 12 : 6}>
                        <Form onSubmit={(e) => {
                            e.preventDefault()
                            verifySender({address, city, country}, res => {
                                if (res.error) {
                                    setSendgridError(res.error)
                                    setSendgridMessage('')
                                } else {
                                    if (res.verified) {
                                        setSendgridMessage('Your email is verified')
                                        setSendgridError('')
                                        setEmailSend(true)
                                    } else {
                                        setSendgridMessage('Please, confirm verification on your mailbox')
                                        setSendgridError('')
                                        setEmailSend(true)
                                    }
                                }
                            })
                        }}>
                            {!sendgridEmailWasSend && <>
                                <FormGroup>
                                    <ControlLabel>Address</ControlLabel>
                                    <FormControl
                                        required
                                        type="text"
                                        className='font16'
                                        placeholder="Address"
                                        value={address}
                                        onChange={e => setAddress(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>City</ControlLabel>
                                    <FormControl
                                        required
                                        type="text"
                                        className='font16'
                                        placeholder="City"
                                        value={city}
                                        onChange={e => setCity(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Country</ControlLabel>
                                    <FormControl
                                        required
                                        type="text"
                                        className='font16'
                                        placeholder="Country"
                                        value={country}
                                        onChange={e => setCountry(e.target.value)}
                                    />
                                </FormGroup>
                            </>}
                            {sendgridError && <p className="text-danger">{sendgridError}</p>}
                            {sendgridMessage && <p className="text-success">{sendgridMessage}</p>}
                            <Row>
                                <Col md={12} className='mb-15 justify-flex-end flex-gap-10'>
                                    <Button type="button" onClick={closeModal}
                                            data-dismiss="modal" bsStyle="primary">Close
                                    </Button>

                                    {sendgridEmailWasSend ? <Button
                                            disabled={emailSend}
                                            onClick={() => resendVerifyEmail(res => {
                                                if (res.error) {
                                                    setSendgridError(res.error)
                                                    setSendgridMessage('')
                                                } else {
                                                    setSendgridMessage('Please, confirm verification on your mailbox')
                                                    setSendgridError('')
                                                    setEmailSend(true)
                                                }
                                            })}>Resend</Button> :
                                        <Button bsStyle='success' disabled={emailSend} type="submit">Verify</Button>}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
