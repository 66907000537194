import {ControlLabel, FormControl, FormGroup, Modal} from "react-bootstrap";
import React from 'react';
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {merge} from "../../common/commonHandlers";
import createApiService from "../../common/clientActionsBuilder";
import ResourceComponent from "../../components/ResourceComponent";

const api = createApiService('reasons', 'reason', 'Reason');

class ReasonModal extends ResourceComponent {
    defaultValues = {name: '', description: '', disabled: false};
    state = {};

    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
        if (this.props.modal) {
            this.handleAdd()
        }
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    handleAdd = () => {
        let resource = {resource: {id: null}};
        this.setState(resource);
    };

    render() {
        const {resource, resources} = this.state;
        const {modal} = this.props;
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={() => {
                    this.setState({resource: this.defaultValues});
                    this.props.onHide();
                }}
                className={"heightAuto maxHeight90"}
            >
                <Modal.Header>
                    <Modal.Title>New Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {resource ?
                        <div>
                            <FormGroup>
                                <ControlLabel>
                                    Name
                                </ControlLabel>
                                <FormControl
                                    name="name"
                                    onChange={this.updateResourceAttr}
                                    value={resource.name}
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>
                                    Description
                                </ControlLabel>
                                <FormControl
                                    name="description"
                                    type="text"
                                    onChange={this.updateResourceAttr}
                                    value={resource.description}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ColorCheckbox value={resource.disabled}
                                               label="Disabled"
                                               onChange={this.selectCheckboxAttr('disabled')}/>
                            </FormGroup>
                        </div>
                        : null}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={() => {
                        this.props.onHide()
                        this.setState({resource: this.defaultValues})
                    }} className="btn btn-default" data-dismiss="modal">Close
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            if (!resource.disabled) resource.disabled = false;
                            this.props.actions.save(resource, () => {
                                this.reloadResources();
                                this.props.refresh();
                            });
                            this.props.onHide();
                        }}
                        className="btn btn-success">
                        Save
                    </button>

                </Modal.Footer>
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(ReasonModal)
