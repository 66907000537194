import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";
import './DisableAllUsersModal.scss';

export default class DisableAllUsersModal extends Component {

    render() {
        return (
            <Modal
                id="disable-all-users-modal"
                animation={true}
                show={this.props.show}
                className={"heightAuto"}
                bsSize="normal"
            >
                <Modal.Header>
                    <Modal.Title>Disable all users</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    You are about to disable all users in the client. This will also result in disabling the client. Do you wish to proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel} bsStyle="default"
                            data-dismiss="modal">Cancel
                    </Button>
                    <Button
                        bsStyle="danger"
                        onClick={this.props.onSave}>
                        Disable all users
                    </Button>
                </Modal.Footer>
            </Modal>
        )
            ;
    }
}