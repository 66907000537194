import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const getAppSettings = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/app_settings?id=1`,
        method: 'GET'
    };

    apiCall("Get app settings successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const updateAppSettings = (settings, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/app_settings/update_app_settings?id=1`,
        method: 'PUT',
        data: {settings}
    };

    apiCall("App settings changed successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const migratePhotos = () => (dispatch, getState) => {
    const config = {
        url: `/api/app_settings/migrate_photos`,
        method: 'PUT',
        date: {}
    };
    apiCall("App settings changed successfully", config, async config => {
        await axios.request(config);
    }, dispatch, getState)
}