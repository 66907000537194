import React, {Component} from 'react';
import PropTypes from "prop-types"
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Image,
    Modal,
    Nav,
    NavItem,
    Row,
    Tab,
    Tabs
} from "react-bootstrap";
import {connect} from "react-redux";
import PlantName from "../components/PlantName";
import {MasterDetailParent} from "./MasterDetailParent";
import './MasterDetail.scss'
import moment from "moment-timezone";
import {MaterialSwitch} from "../components/MaterialSwitch/MaterialSwitch";

const isMobile = window.screen.width < 768;

class MasterDetail extends Component {
    state = {key: 1};

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.resource!==this.props.resource && !this.props.resources.find(r => r.disabled)){
            this.setState({key: 1})
        }
    }

    handleSelect = e => {
        let resource = {resource: Object.assign({}, e)};
        this.setState(resource);
        this.props.select(resource);
    };

    handleAdd = () => {
        let resource = {resource: {id: null, links: []}};
        this.setState(resource);
        this.props.select(resource);
    };

    render() {
        let {
            resourceName,
            resourceNamePlural,
            resources,
            resource,
            isServiceView,
            downloadCsv,
            resetResource,
            withToggle,
            toggleData
        } = this.props;
        let {filterText, key} = this.state;

        if (filterText && filterText.length > 0) {
            resources = resources.filter(x => x.name
                .toLowerCase().includes(filterText.toLowerCase()));
        }

        if (key && key === 1) {
            resources = resources.filter(x => x.disabled !== true)
        }

        if (key && key === 2) {
            resources = resources.filter(x => x.disabled)
        }

        if (key === 2 && !resources.find((res) => res.disabled)) {
            this.setState({key: 1})
        }

        return (<div className="top25">
            <Row className="no-margin">
                <Col md={2}>
                    {withToggle ? <div>
                            <h3 className="no-top-margin pointer"
                                onClick={() => resetResource && resetResource()}>{resourceNamePlural}</h3>
                            <MaterialSwitch
                                value={toggleData.value}
                                onChange={() => toggleData.onChange()}
                                label={toggleData.labelLeft}
                                label2={toggleData.labelRight}
                            />
                    </div> :
                    <h3 className="no-top-margin pointer"
                        onClick={() => resetResource && resetResource()}>{resourceNamePlural}</h3>}
                    <Button onClick={() => this.handleAdd()} bsStyle="info mr-10 mt-3">
                        New {resourceName}
                    </Button>
                    {resetResource && <Button onClick={() => resetResource()} bsStyle="info mr-10 mt-3">
                        All {resourceNamePlural}
                    </Button>}
                    {isServiceView &&
                        <Button
                            onClick={() => downloadCsv()} bsStyle="success" className="ml-6">
                            Export CSV
                        </Button>
                    }
                    {<Tabs className="top25" defaultActiveKey={1} animation={false} activeKey={this.state.key}
                              onSelect={k => {
                                  this.setState({key: k})
                              }}>
                            <Tab eventKey={1} title="Active"></Tab>
                            {this.props.resources.find(r => r.disabled) && <Tab eventKey={2} title="Archived"></Tab>}
                        </Tabs>}
                    <form className="top25">
                        <FormGroup>
                            <ControlLabel>Filter</ControlLabel>
                            <FormControl
                                type="text"
                                placeholder={`Filter ${resourceNamePlural}`}
                                onChange={e => this.setState({filterText: e.target.value})}
                            />
                        </FormGroup>
                    </form>
                    <div style={{maxHeight: isMobile ? "400px" : "600px", overflowY: "auto"}}>
                        <Nav
                            bsStyle="pills"
                            stacked
                            onSelect={this.handleSelect}
                        >
                            {resources && resources.sort((a, b) => a.name.localeCompare(b.name)).map(u => (
                                <NavItem key={u.id} eventKey={u}
                                         active={resource && resource.id === u.id}>{resourceName = "Plant" ?
                                    <PlantName plant={u.name}/> : u.name}</NavItem>
                            ))}

                        </Nav>
                    </div>
                </Col>
                <Col md={10}>
                    {this.renderDetail()}
                </Col>
            </Row>
        </div>);
    }

    _save(e) {
        e.preventDefault();
        this.props.save(e);
    }

    renderDetail = () => {
        const {
            resource,
            resourceName,
            roles,
            resources,
            showParent,
            resources_all,
            filtersData,
            handleTabSelect,
            buttonsBottom,
            blockSave
        } = this.props;

        return resource ? (
            <form onSubmit={e => this._save(e)} className='detail-form'>
                <Row>
                    <Col md={12}>
                        <h4>{resource.id ? resourceName === "Plant" ?
                            <PlantName plant={resource.name}/> : resource.name : `New ${resourceName}`}</h4>
                    </Col>
                </Row>
                {this.props.children}
                <Row className={`${buttonsBottom ? 'buttons-bottom' : ''}`}>
                    {isMobile ? <>
                        {!resource.shared && <Col md={12} sm={12}>
                            <Row style={{marginBottom: 10}}>
                                <Col md={12} sm={12}>
                                {roles && roles.includes('admin') && <Button disabled={this.props.blockDelete} onClick={(e) => {
                                    const choice = window.confirm('Please be sure! All associated data will be deleted!');
                                    if (choice) {
                                        e.stopPropagation();
                                        this.props.delete(e)
                                    }
                                }}>
                                    Delete {this.props.resourceName}
                                </Button>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} sm={12}>
                                <Button bsStyle="info" type="submit" disabled={blockSave}>
                                    Save {this.props.resourceName}
                                </Button>
                                </Col>
                            </Row>
                        </Col>
                        }
                    </> : <>
                    {!resource.shared && <Col md={12}>
                        {roles && roles.includes('admin') && <Button disabled={this.props.blockDelete} onClick={(e) => {
                            const choice = window.confirm('Please be sure! All associated data will be deleted!');
                            if (choice) {
                                e.stopPropagation();
                                this.props.delete(e)
                            }
                        }}>
                            Delete {this.props.resourceName}
                        </Button>}
                        <Button bsStyle="info" className="pull-right" type="submit" disabled={blockSave}>
                            Save {this.props.resourceName}
                        </Button>
                    </Col>
                    }
                    </>}
                </Row>
            </form>
        ) : showParent && (<MasterDetailParent handleChildTabSelect={handleTabSelect} setResource={this.handleSelect}
                                               resources={resources} resources_all={resources_all}
                                               filtersData={filtersData} saveNewCsvDownload={this.props.saveNewCsvDownload}/>)
    }
}

MasterDetail.propTypes = {
    resourceName: PropTypes.string.isRequired,
    resourceNamePlural: PropTypes.string.isRequired,
    resources: PropTypes.array.isRequired,
    resources_all: PropTypes.array,
    resource: PropTypes.object,
    children: PropTypes.object,
    save: PropTypes.func,
    delete: PropTypes.func,
    select: PropTypes.func,
    showParent: PropTypes.bool,
    resetResource: PropTypes.func,
    handleTabSelect: PropTypes.func,
    buttonsBottom: PropTypes.bool,
    blockSave: PropTypes.bool,
    withToggle: PropTypes.bool,
    toggleData: PropTypes.object,
    blockDelete: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    roles: state.auth.roles
});

export default connect(mapStateToProps, undefined)(MasterDetail)