import React, {Component} from 'react';
import PropTypes from "prop-types"
import {browserHistory, Link} from "react-router";
import {Col, Panel, Row} from "react-bootstrap";

class ProposalLeadMenu extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const {proposalId, id} = this.props;
        return (
            <div>
                <Row className="top-menu">
                    <Col md={6}>
                        <h2 className="inline no-top-margin">{id ? 'Lead' : 'Leads'}</h2>
                        <Link onClick={() => (id && browserHistory.push(`/proposal_lead/${id}`))}
                              className={`${window.location.href.includes('/time') ? '' : 'active'} small-margin`}>{id ? `Lead #${id}` : 'New Lead'}</Link>
                        <Link
                            className={`${window.location.href.includes('/time') ? 'active' : ''} ${id ? '' : 'disabled'} small-margin`}
                            onClick={() => (id && browserHistory.push(`/proposal_lead/${id}/time`))}>Find a time</Link>
                    </Col>
                    <Col md={2} className="text-right">
                        <Link className="text-danger small-margin" onClick={() => {
                            !window.location.href.includes('/time') ? browserHistory.push(`/mapview/${proposalId}`) : browserHistory.push(`/proposal_lead/${id}`);
                        }}>
                            Cancel
                        </Link>
                        <Link className="text-primary small-margin" onClick={this.props.save}>
                            Save
                        </Link>
                    </Col>
                </Row>
                <Panel
                    collapsible
                    expanded={false}
                    className="bottom0"
                />
            </div>
        );
    }
}

ProposalLeadMenu.propTypes = {
    save: PropTypes.func.isRequired,
    id: PropTypes.object.isRequired,
    proposalId: PropTypes.object.isRequired
};

ProposalLeadMenu.defaultProps = {
    //myProp: <defaultValue>
};

export default ProposalLeadMenu;
