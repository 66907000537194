import {FETCH_PLANT_LIST} from '../constants'

import axios from 'axios'

// TODO: DEPRECATED
export const fetchPlantList = () => (dispatch, getState) => {
    const store = getState();
    const config = {
        url: '/api/plants',
        headers: {'Authorization': store.auth.token},
    };

    return axios.request(config)
        .then(response => {
            dispatch({type: FETCH_PLANT_LIST, plantList: response.data, plantListLastUpdated: Date.now()});
        });
};