import React, {useState} from 'react';
import AssetMarker from "./AssetMarker";


const AssetMarkers = ({assets, services, proposal_services}) => {
    const [selectedAssetId, setSelectedAssetId] = useState(null);
    return assets.map((asset, i) => {
        return <AssetMarker asset={asset} proposal_services={proposal_services} services={services} index={i}
                            selected={asset.selected}
                            id={asset.id}
                            onClick={setSelectedAssetId}
                            isOtherAssetSelected={selectedAssetId && selectedAssetId !== asset.id}/>
    })

}
export default AssetMarkers;