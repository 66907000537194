import React, {useEffect, useRef, useState} from "react";
import moment from "moment-timezone";

const timeFormatter = (seconds) => moment.utc(seconds * 1000).format('HH:mm:ss')

const TotalTime = ({timeFn}) => {
    const timer = useRef();
    const [timeCounter, setTimeCounter] = useState(0);

    const updateCounter = () => {
        const now = moment();
        const timeString = now.format('HH:mm:ss');
        setTimeCounter(moment.duration(timeString).asSeconds());
    }
    useEffect(() => {
        timer.current = setInterval(updateCounter, 1000)

        return () => timer.current && clearInterval(timer.current)
    }, [])

    return timeFormatter(timeFn(timeCounter));
}

export default TotalTime;