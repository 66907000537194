import {FETCH_APPLICATION_TYPE_LIST} from '../constants'

import axios from 'axios'

// TODO: DEPRECATED
export const fetchApplicationTypesList = () => (dispatch, getState) => {
    const store = getState();
    const config = {
        url: '/api/application_types',
        headers: {'Authorization': store.auth.token},
    };

    return axios.request(config)
        .then(response => {
            dispatch({
                type: FETCH_APPLICATION_TYPE_LIST,
                applicationTypesList: response.data,
                applicationTypesListLastUpdated: Date.now()
            });
        });
};