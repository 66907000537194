import React from "react";
import {Col} from "react-bootstrap";
import DefaultReportView from "./DefaultReportView";

const CustomerReportTab = ({getRevenueReport}) => {
    const reportOptions = [
        {value: 'customers', label: 'Customers'},
        {value: 'prospective_customers', label: 'Prospective Customers'},
        {value: 'prospective_customers_all', label:'Prospective Customers All'},
    ]

    return <DefaultReportView
        title="Customer Reports"
        options={reportOptions}
        getRevenueReport={getRevenueReport}
    >
        <Col xs={12}>
            <p><b>Customers</b> - Customers that currently have at least one proposal service in ACCEPTED status in proposal accepted date range.</p>
            <p><b>Prospective Customers</b> - Customers that currently have at least one proposal service in DRAFT or PROPOSED status and have never had any services in ACCEPTED status in the past in proposal created at date range.</p>
            <p><b>Prospective Customers All</b> - Customers that have never had any services in ACCEPTED status in the past in customer created at date range.</p>
        </Col>
    </DefaultReportView>
}

export default CustomerReportTab;