import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";


export const loadProposals = (filters, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/referral_report/${filters.referral_id}`,
        params: filters
    }

    apiCall("Proposals load successfully", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};