import {
    FETCH_BILLING_CONTACT,
    FETCH_CONTACTS,
    FETCH_CUSTOMER_INFO,
    FETCH_CUSTOMER_SITES,
    FETCH_PROPOSALS,
    FETCH_RECENT_SERVICES,
    FETCH_SEARCHED_CUSTOMERS,
    FETCH_SELECTED_SITEINFO,
    FETCH_SITES,
    FETCH_SITES_ASSETS,
    GET_CUSTOMER_BY_ID,
    SELECT_CUSTOMER,
    UPDATE_CUSTOMER_SEARCH,
    UPDATE_SELECTED_SITE,
    UPDATE_SERVICE_STATUS,
} from "./constants"

import {EDITING_ASSET_ID, FETCHED_SITE_ASSETS,} from "../../MapViewPage/constants"

import axios from "axios"
import {addAlert} from "../../App/actions"
import {fetchProposalServiceStatuses,} from "../../ProposalServiceStatusPage/actions"
import {browserHistory} from "react-router"
import {doneEditingAsset, fetchDbhs, onMarkerClick, updateAssetAttribute,} from "../../MapViewPage/actions"

import {fetchCustomerTypesList} from "../../../actions/customerTypes"
import {fetchReferallsList} from "../../../actions/referalls"
import {fetchSalesArborists} from "../../../actions/salesArborists"
import {createNewSite, editingSiteId,} from "../../SiteEditorModal/actions"
import {
    deleteSiteMap,
    fetchSiteMaps,
    saveSiteMap,
    toggleIncludeSiteMap,
    updateSiteMap,
} from "../../../actions/siteMaps"
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../../common/apiActionHandler";
import * as FileSaver from "file-saver";
import {mapForSelect} from "../../../common/commonHandlers";

export {editingSiteId, createNewSite}

export {
    fetchSiteMaps,
    deleteSiteMap,
    updateSiteMap,
    saveSiteMap,
    toggleIncludeSiteMap,
}

export const updateServiceStatus = serviceStatus => {
    return {type: UPDATE_SERVICE_STATUS, serviceStatus}
}

export {onMarkerClick}
export {doneEditingAsset}
export {updateAssetAttribute}

export const updateSelectedSite = selectedSite => dispatch => {
    dispatch({type: UPDATE_SELECTED_SITE, selectedSite})
    dispatch(fetchSelectedSiteInfo())
    dispatch(fetchCustomerAssets())
}

export const updateCustomerSearch = customerSearch => dispatch => {
    dispatch({type: UPDATE_CUSTOMER_SEARCH, customerSearch})
    dispatch(searchForCustomer())
}

export const updateSelectedCustomer = selectedCustomer => dispatch => {
    dispatch({type: SELECT_CUSTOMER, selectedCustomer})
    browserHistory.push(`/customer/sites/${selectedCustomer.value}`)
}

export const dismissAssetEditorModal = () => ({
    type: EDITING_ASSET_ID,
    editingAssetHistoryId: null,
})

export const applyHistoryFilter = () => (dispatch, getState) => {
    const store = getState()
    dispatch(fetchCustomerById(store.customerProposals.customer.id))
}

export const fetchCustomerAssets = () => async (dispatch, getState) => {
    const store = getState()
    const selectedSite = store.customerProposals.selectedSite

    let siteIds = []
    if (selectedSite === null) {
        siteIds = store.customerProposals.customerSites.map(site => site.id)
    } else {
        siteIds = [selectedSite]
    }

    if (siteIds.length === 0) {
        console.info(
            "There are no Site Ids to fetch Assets for. This customer probably doesn't have a Site. Sending API request anyway."
        )
    } else {
        const config = {
            method: "get",
            url: `/api/assets`,
            headers: {Authorization: store.auth.token},
            params: {
                site_id: siteIds,
            },
        }

        try {
            const response = await axios.request(config)
            dispatch({type: FETCH_SITES_ASSETS, sitesAssets: response.data})
            dispatch({type: FETCHED_SITE_ASSETS, siteAssets: response.data}) //mapview which is what asset editor depends on :(
            dispatch(fetchDbhs()) //asset editor depends on this too
        } catch (exception) {
            dispatch(
                addAlert({
                    message: "Unable to fetch the customer because " + exception.response?.data?.error,
                })
            )
            throw exception
        }
    }
}

export const fetchCustomerById = customerId => async (dispatch, getState) => {
    const store = getState()

    const config = {
        url: `/api/customers/${customerId}/info`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)

        dispatch({type: GET_CUSTOMER_BY_ID, customer: response.data})
        dispatch(fetchProposals(customerId))
        dispatch(fetchProposalServiceStatuses("all"))
        const _ = await dispatch(fetchCustomerSites())
        dispatch(fetchCustomerAssets())
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the customer because " + exception.toString(),
            })
        )
        throw exception
    }
}

const fetchProposals = () => async (dispatch, getState) => {
    const store = getState()
    const customerId = store.customerProposals.customer.id
    const siteId = store.customerProposals.selectedSite
    // const proposalId = store.custostomerProposals.serviceStatus

    const config = {
        url: `/api/proposals/customer_proposals/`,
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
            site_id: siteId,
        },
    }

    try {
        const response = await axios.request(config)

        dispatch({type: FETCH_PROPOSALS, customerProposals: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch proposals because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const fetchCustomerSites = () => async (dispatch, getState) => {
    const store = getState()
    const customerId = store.customerProposals.customer.id
    const config = {
        url: "/api/sites",
        headers: {Authorization: store.auth.token},
        params: {customer_id: customerId},
    }

    try {
        const response = await axios.request(config)

        dispatch({type: FETCH_CUSTOMER_SITES, customerSites: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch customer sites because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

const fetchSelectedSiteInfo = () => async (dispatch, getState) => {
    const store = getState()
    const selectedSite = store.customerProposals.selectedSite
    if (isNaN(parseInt(selectedSite, 10))) {
        return false
    }

    const config = {
        url: `/api/sites/${selectedSite}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SELECTED_SITEINFO, selectedSiteInfo: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch customer site because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const searchForCustomer = () => async (dispatch, getState) => {
    const store = getState()
    const searchQuery = store.customerProposals.customerSearch

    if (searchQuery.length < 2) {
        return false
    }

    const config = {
        method: "get",
        url: `/api/customers`,
        headers: {Authorization: store.auth.token},
        params: {
            q: searchQuery,
        },
    }
    try {
        const response = await axios.request(config)
        dispatch({
            type: FETCH_SEARCHED_CUSTOMERS,
            foundCustomers: response.data,
        })
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to search for customers because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const getSites = customerId => async (dispatch, getState) => {
    dispatch(fetchSites(customerId))
}
export const fetchCustomerInfo = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/customers/${customerId}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: response.data})
        dispatch(fetchCustomerTypesList())
        dispatch(fetchReferallsList())
        dispatch(fetchSalesArborists())
        dispatch(fetchSites(customerId))
        dispatch(fetchContacts(customerId))
        const billingContact = await dispatch(fetchBillingContact(customerId))
        // console.log("Billing contact is", billingContact)
        if (billingContact === null) {
            dispatch(_injectBlankBillingContact(customerId))
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Customer not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

const _injectBlankBillingContact = customer_id => dispatch => {
    console.log("injecting billing contact")
    const billingContact = {
        id: 0,
        // contact_type_id: "",
        customer_id,
        first_name: "",
        last_name: "",
        person_phone: "",
        person_phone2: "",
        person_email: "",
        person_email2: "",
    }

    //we'll purposely leave out __dirty because we're goin to use the presence to detedct if an attempt was made to create one
    dispatch({type: FETCH_BILLING_CONTACT, billingContact})
}

export const fetchBillingContact = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/customer_contacts/billing_contact",
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_BILLING_CONTACT, billingContact: response.data})
        return response.data
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your billing contact because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const fetchSites = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/sites/customer_list`,
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SITES, sites: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your sites because " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const fetchContacts = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/customer_contacts/rolodex",
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CONTACTS, contacts: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your contacts because " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}


export const updateSiteInfo = (siteMap) => async (dispatch, getState) => {
    const store = getState()
    const isNew = siteMap.id === 0 ? true : false
    const config = {
        method: isNew ? "POST" : "PUT",
        url: isNew ? "/api/sites" : `/api/sites/${siteMap.id}`,
        headers: {Authorization: store.auth.token},
        data: {site: {...siteMap, id: undefined, __dirty: undefined}},
    }

    try {
        const response = await axios.request(config)

        dispatch(
            addAlert({
                message: `${isNew ? "Created" : "Edited"} site!`,
                mood: "success",
                dismissAfter: 1500,
            })
        )

        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not ${isNew ? "CREATE" : "EDIT"} the site ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const toggleIncludeSite = (siteId) => async (dispatch, getState) => {
    const store = getState()
    const targetSite = store.customerProposals.selectedSiteInfo &&
        store.customerProposals.selectedSiteInfo[
            `site_${store.customerProposals.selectedSite}`
            ]
    const site = {
        ...targetSite,
        id: siteId,
        include_when_print: !targetSite.include_when_print
    }

    const config = {
        method: "PUT",
        url: `/api/sites/${site.id}`,
        headers: {Authorization: store.auth.token},
        data: {site: {...site, id: undefined, __dirty: undefined}},
    }

    try {
        const response = await axios.request(config)
        let newSite = {}
        newSite[`site_${site.id}`] = site
        dispatch({type: FETCH_SELECTED_SITEINFO, selectedSiteInfo: newSite})
        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not toggle the site ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const getSite = (siteId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/${siteId}`
    };

    apiCall("Searching for site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const getSiteAssets = (site_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/assets/get_site_assets/`,
        params: {site_id: site_id}
    };

    apiCall("Searching for asset", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveMarkersRenderingType = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/site_maps/show_as_markers`,
        method: 'PUT',
        data: {resource}
    };
    apiCall("Saving markers type", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const fetchOrdinances = (site_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/ordinance_and_laws/`,
        method: 'GET',
        params: {site_id: site_id}
    };
    apiCall("Fetch site ordinances", config, async config => {
        const response = await axios.request(config);
        callback && callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};


export const generateReport = (site, assets_ids, report_type, personal_pronoun, dripline, lead_date, ordinance_id, callback) => async (dispatch, getState) => {
    let config = {
        url: `/api/sites/generate_report`,
        method: 'POST',
        responseType: 'blob',
        data: {assets_ids, report_type, personal_pronoun, dripline, lead_date, ordinance_id},
        params: {
            site_id: site.id,
            size: '600x600',
            zoom: 2,
            maptype: 'hybrid',
            mainMap: true,
            center: true,
            fit_bounds: true
        },
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/docx',
        });
        FileSaver.saveAs(blob, `${report_type} - ${site.address}.docx`);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download docx" + exception.toString()
            })
        );
        callback && callback()
        throw exception
    }
};