import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {merge, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const api = createApiService('factor_types', 'factor_type', 'Factor type');

class FactorTypes extends ResourceComponent {
    defaultValues = {name: '', description: '', disabled: false};
    state = {};

    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;
        if ((prevState.resources !== resources) && resource) {
            const selected = resources.find(r => r.id === resource.id)
            this.setState(() => ({resource: {...resource, modified_at_date: selected.modified_at_date}}))
        }
    }

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    render() {
        const {resource, resources} = this.state;
        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Factor type"
                        resourceNamePlural="Factor types"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                this.props.actions.save(resource, this.reloadResources)
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource &&
                        <div>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Name
                                        </ControlLabel>
                                        <FormControl
                                            name="name"
                                            onChange={this.updateResourceAttr}
                                            value={resource.name}
                                        />
                                        {this.state.errorMessage && <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>
                                            Description
                                        </ControlLabel>
                                        <FormControl
                                            name="description"
                                            type="text"
                                            onChange={this.updateResourceAttr}
                                            value={resource.description}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.disabled}
                                                       label="Disabled"
                                                       onChange={this.selectCheckboxAttr('disabled')}/>
                                    </FormGroup>
                                    <FormGroup>
                                        Last
                                        updated {resource.modified_at_date ? moment(resource.modified_at_date).format('llll') : <i>Unknown</i>} by {resource.modified_by_name}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(FactorTypes)
