import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import createApiService from "../../../common/clientActionsBuilder";
import {Button, Col, ControlLabel, Form, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import * as serviceConfigurationApi from "./ServiceConfigurationApi";

const api = createApiService('clients', 'client', 'Client');
const actions = {...api, ...serviceConfigurationApi};

class ServiceConfiguration extends ResourceComponent {
    state = {
        resource: {},
        credentials: {},
        google_calendars: {}
    };

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.props.actions.get(this.props.id, resource => this.setState({resource}));
        this.props.actions.loadStripeCredentials(this.props.clientId, result => {
            this.setState({credentials: result})
        });
    };

    render() {
        const {resource, credentials, google_calendars} = this.state;
        return (
            <Grid fluid className="top25">
                <Form horizontal onSubmit={e => {
                    e.preventDefault();
                    this.props.actions.save(resource);
                }}>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col mdOffset={4}>
                                    <Col md={8}>
                                        <h4>Google Calendar</h4>
                                    </Col>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup validationState={resource.gcal_authorized ? 'success' : ''}>
                                <Col componentClass={ControlLabel} md={4}>
                                    Google Calendar Authorization
                                </Col>
                                <Col md={6}>

                                    <FormControl
                                        name="gcal_token"
                                        value={resource.gcal_authorized ? 'Authorized' : 'Not Authorized'}
                                    />
                                    {resource.gcal_authorized && <FormControl.Feedback/>}

                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col mdOffset={4} md={2}>
                                    <Button block onClick={() =>
                                        this.props.actions.getAuthUrl(this.props.id, response => {
                                            window.location = response.url;
                                        })
                                    }>
                                        {resource.gcal_authorized ? 'Revoke Token' : 'Authorize'}
                                    </Button>
                                </Col>
                                <Col md={2}>
                                    <Button
                                        block
                                        onClick={() =>
                                            this.props.actions.deleteToken(this.props.id, this.reload)
                                        }
                                        disabled={!resource.gcal_authorized}
                                    >
                                        Delete token
                                    </Button>
                                </Col>
                                <Col md={2}>
                                    <Button
                                        block
                                        onClick={() =>
                                            this.props.actions.createTestEvent(this.props.id)
                                        }
                                        disabled={!resource.gcal_authorized}
                                    >
                                        Create Test Event
                                    </Button>
                                </Col>
                                <Col md={2}>
                                    <Button
                                        block
                                        onClick={() =>
                                            this.props.actions.syncCalendar(this.props.id)
                                        }
                                        disabled={!resource.gcal_authorized}
                                    >
                                        Sync
                                    </Button>
                                </Col>
                                <Col mdOffset={4} md={2}>
                                    <Button
                                        className='top15'
                                        block
                                        onClick={() =>
                                            this.props.actions.getSyncCalendar(this.props.id, res => this.setState({google_calendars: res}))
                                        }
                                        disabled={!resource.gcal_authorized}
                                    >
                                        Check
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    {Object.keys(google_calendars).length > 0 &&
                        <Row className="bottom20">
                            <Col mdOffset={3} md={3}>
                                <p className="bold">Employee Emails</p>
                                {google_calendars?.employee_emails?.map(e => {
                                    const is_sync = google_calendars?.syncing && google_calendars?.syncing?.length > 0 && google_calendars?.syncing.includes(e)
                                    return <p className="no-margin" style={{color: !is_sync && 'red'}}>{e}</p>
                                })}
                            </Col>
                            <Col md={3}>
                                <p className="bold">Google Calendars</p>
                                {google_calendars?.google_calendars?.map(e => {
                                    const is_sync = google_calendars?.syncing && google_calendars?.syncing?.length > 0 && google_calendars?.syncing.includes(e)
                                    return <p className="no-margin" style={{color: !is_sync && 'red'}}>{e}</p>
                                })}
                            </Col>
                            <Col md={3}>
                                <p className="bold">Sync</p>
                                {google_calendars?.syncing?.map(e => {
                                    return <p className="no-margin">{e}</p>
                                })}
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Sync interval(days back/days ahead)
                                </Col>
                                <Col md={3}>
                                    <FormControl
                                        name="gcal_sync_days_back"
                                        onChange={this.updateResourceAttr}
                                        value={resource.gcal_sync_days_back}
                                    />
                                </Col>
                                <Col md={3}>
                                    <FormControl
                                        name="gcal_sync_days_ahead"
                                        onChange={this.updateResourceAttr}
                                        value={resource.gcal_sync_days_ahead}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col mdOffset={3} md={1}>
                            <Button
                                block
                                type="submit"
                                bsStyle="success"
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                    {credentials.stripe_enabled && <Row>
                        <Row className="top50">
                            <Col md={9}>
                                <FormGroup>
                                    <Col mdOffset={4}>
                                        <Col md={8}>
                                            <h4>Payments</h4>
                                        </Col>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Connect with Stripe
                                </Col>
                                <Col md={6}>
                                    {credentials.stripe_user_id ?
                                        <a onClick={() => this.props.actions.disconnectStripe(() => this.reload())}>
                                            <Button className="stripe_disconnect_btn">
                                                <span className="vertical-align"><i
                                                    className="fa fa-cc-stripe small-margin font18" aria-hidden="true"/><span
                                                    className="mb2">Disconnect</span></span>
                                            </Button>
                                        </a>
                                        :
                                        <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&amp;client_id=${this.props.stripe_connect_client_id}&amp;scope=read_write`}>
                                            <Button className="stripe_connect_btn">
                                                <span className="vertical-align"><i
                                                    className="fa fa-cc-stripe small-margin font18" aria-hidden="true"/><span
                                                    className="mb2">Connect with Stripe</span></span>
                                            </Button>
                                        </a>
                                    }
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>}
                </Form>
            </Grid>
        );
    }
}

ServiceConfiguration.propTypes = {
    id: PropTypes.string.isRequired
};

ServiceConfiguration.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        stripe_connect_client_id: state.client.customerInfo.stripe_connect_client_id,
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceConfiguration);

