import React from "react";
import DefaultReportView from "./DefaultReportView";
import {Col} from "react-bootstrap";

const CompletedReport = ({getRevenueReport}) => {
    const reportOptions = [
        {value: 'completed_by_service_type', label: 'Completed by Service Type'},
        {value: 'completed_by_service_by_customer', label:'Completed by Service by Customer'},
    ]

    return <DefaultReportView
        title="Completed Reports"
        options={reportOptions}
        getRevenueReport={getRevenueReport}
    >
        <Col xs={12}>
            <p><b>Selected dates</b> apply to the completed service date or the completed scheduler events dates.</p>
        </Col>
    </DefaultReportView>
}

export default CompletedReport;