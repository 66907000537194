import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const changeUserPassword = (token, password, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/restricted/change_user_password`,
        params: {token, ...password}
    };

    apiCall("Changing user password", config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState);
};