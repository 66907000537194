import {PENDING_APPROVALS_LOADED, APPROVAL_SUCCESS} from "./constants"
import {upsertPromise} from "../ProposalServiceStatusPage/actions"
import {addAlert} from "../App/actions"
import axios from "axios"

export {upsertPromise}

export function fetchPendingApprovals(page, per_page) {
    return function (dispatch, getState) {
        const store = getState()
        const config = {
            method: "get",
            url: `/api/approvals/pending_approval`,
            headers: {Authorization: store.auth.token},
            params: {page, per_page}
        }

        axios
            .request(config)
            .then(response => {
                dispatch({
                    type: PENDING_APPROVALS_LOADED,
                    arboristApprovals: response.data,
                })
            })
            .catch(error => {
            })
    }
}

export function approveProposal(proposalId) {
    return function (dispatch, getState) {
        const store = getState()
        const config = {
            method: "put",
            url: `/api/approvals/approve_proposal`,
            headers: {Authorization: store.auth.token},
            data: {
                proposal_to_approve: proposalId,
            },
        }

        axios
            .request(config)
            .then(response => {
                dispatch({type: APPROVAL_SUCCESS, proposalId}) //optimistically removes it
                dispatch(
                    addAlert({
                        message: "Proposal Approved!",
                        mood: "success",
                        dismissAfter: 1500,
                    })
                )
                dispatch(fetchPendingApprovals()) //in case something didn't get approved
            })
            .catch(error => {
                console.log(error.response)
                if (error.response && error.response.status === 422) {
                    dispatch(addAlert({message: error.response.data.error}))
                }
            })
    }
}
