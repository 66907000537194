import {FETCH_REFERALLS_LIST} from '../constants'


const initialState = {
    referallsList: [],
    referalls: {},
    referallsListLastUpdated: 0,
    editingReferallId: null,
    filterText: '',
};

// TODO: deprecated
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_REFERALLS_LIST:
            return {
                ...state,
                referallsList: action.referallsList,
                referallsListLastUpdated: action.referallsListLastUpdated,
            };
        default:
            return state
    }
}
