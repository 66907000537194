import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Glyphicon, Grid, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import moment from "moment";
import * as api from "./QuickbooksInvoicesApi";
import ResourceComponent from "../../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltLeft, faLongArrowAltRight, faAngleUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import InlineEditable from "../../Scheduler/InlineEditable";
import Select from "react-select";
import {defaultDateFormat, select} from "../../../common/commonHandlers";
import './QuickbooksInvoices.css';
import MDSpinner from "react-md-spinner";
import Dollars from "../../../components/Dollars";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import ErrorInfoModal from "../ErrorInfoModal";
import {Link} from "react-router";

const actions = {...api};

class QuickbooksInvoices extends ResourceComponent {
    state = {
        resource: {
            from: moment().utc().subtract(7, "days").startOf('day'),
            to: moment().utc().endOf('day'),
            showSynchronized: false,
            invoice_number: null,
            qb_invoice: null,
            page: 1,
            per_page: 25
        },
        collapsedRows: [],
        content: [],
        rowLoading: null,
        errorModal: {show: false, message: ''},
    }

    constructor(props, context) {
        super(props, context);
        this.invoiceSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.qbInvoiceSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    }

    componentDidMount() {
        this.invoiceSearch = this.buildSearchComponent('invoice_number', this.props.actions.searchForInvoice);
        this.qbInvoiceSearch = this.buildSearchComponent('qb_invoice', this.props.actions.searchForQbInvoice);
        if (window.location.href.indexOf("invoice_id") === -1) {
            this.sync();
        }
        this.props.onRef(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {eventKey, selectedKey, dateTo, dateFrom, showSynchronized} = this.props;
        const {resource, collapsedRows} = this.state;
        this.props.onRef(this)
        if (dateFrom && dateTo &&
            eventKey === selectedKey &&
            (prevProps.dateTo !== dateTo ||
                prevProps.dateFrom !== dateFrom ||
                prevProps.showSynchronized !== showSynchronized)) {
            resource["from"] = dateFrom;
            resource["to"] = dateTo;
            resource["showSynchronized"] = showSynchronized;
            this.setState({resource: resource}, this.sync);
        }
        if (prevProps.eventKey !== eventKey || prevProps.selectedKey !== selectedKey) {
            if (eventKey === selectedKey) {
                this.sync();
            }
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    assignInvoice = (invoice, index) => {
        const {content} = this.state;
        let obj = {
            invoiced_at: invoice.invoiced_at,
            number: invoice.number,
            due_date: invoice.due_date,
            customer_id: invoice.customer_id,
            qb_customer_id: invoice.qb_customer_id,
            customer_name: invoice.customer_name,
            quickbooks_id: invoice.quickbooks_id,
            quickbooks_sync_token: invoice.quickbooks_sync_token,
            modified_at: invoice.modified_at,
            proposal_services: invoice.proposal_services,
            isAssigned: true
        };
        content[index]["th"] = obj
        this.setState({
            content: content
        });
    }

    assignQbInvoice = (invoice, index) => {
        const {content} = this.state;
        content[index]["qb"] = {
            invoiced_at: invoice.invoiced_at,
            number: invoice.number,
            due_date: invoice.due_date,
            customer_id: invoice.customer_id,
            qb_customer_id: invoice.qb_customer_id,
            customer_name: invoice.customer_name,
            quickbooks_id: invoice.quickbooks_id,
            quickbooks_sync_token: invoice.quickbooks_sync_token,
            modified_at: invoice.modified_at,
            proposal_services: invoice.proposal_services,
            isAssigned: true
        };
        this.setState({
            content: content
        });
    }

    handleSearchQbInvoice = (resource, index) => {
        if (resource[`qb_invoice_${index}`])
            this.props.actions.getQbInvoiceNyNumber(resource[`qb_invoice_${index}`], (response) =>
                this.assignQbInvoice(response.content[0], index))
    }

    handleSearchInvoice = (resource, index, callback = null) => {
        if (resource[`invoice_number_${index}`])
            this.props.actions.getInvoice(resource[`invoice_number_${index}`], (response) => {
                this.assignInvoice(response, index)
                callback && callback(response)
            })
    }

    searchInvoice = (index) => {
        const invoiceOptions = this.invoiceSearch.searchOptions();
        const {resource, content} = this.state;
        return (
            <div>{
                content[index]["th"] ? <Row>
                        <Col md={11}>
                            <InlineEditable
                                style={{minWidth: "50%"}}
                                className="pointer"
                                fullWidth={true}
                                value={this.invoiceDataField(content[index]["th"], content[index]["qb"], false, "left", index, true)}
                                form={(done) =>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(invoiceOptions, resource[`invoice_number_${index}`])}
                                            options={invoiceOptions}
                                            placeholder="Invoice"
                                            isClearable
                                            onInputChange={this.invoiceSearch.search}
                                            onChange={this.selectResourceAttr(`invoice_number_${index}`,
                                                () => this.handleSearchInvoice(resource, index)
                                            )}
                                    />
                                }
                            />
                        </Col>
                        <Col md={1}>
                            <div onClick={() => {
                                let rows = this.state.collapsedRows
                                if (rows.includes(index)) {
                                    rows = rows.filter(r => r !== index)
                                } else {
                                    rows.push(index)
                                }
                                this.setState({collapsedRows: rows})
                            }}>
                                {this.state.collapsedRows.includes(index) ? <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleUp}/> : <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleDown}/>}
                            </div>
                        </Col>
                    </Row> :
                    <Row>
                        <Col xs={12} className="hover-cell">
                            <InlineEditable
                                style={{minWidth: "50%"}}
                                className="pointer"
                                value={<h4><b>Not Assigned</b></h4>}
                                form={(done) =>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(invoiceOptions, resource[`invoice_number_${index}`])}
                                            options={invoiceOptions}
                                            placeholder="Invoice"
                                            isClearable
                                            onInputChange={this.invoiceSearch.search}
                                            onChange={this.selectResourceAttr(`invoice_number_${index}`,
                                                () => this.handleSearchInvoice(resource, index)
                                            )}
                                    />
                                }
                            />
                        </Col>
                    </Row>
            }

            </div>
        );
    }

    searchQbInvoice = (index) => {
        const invoiceQbOptions = this.qbInvoiceSearch.searchOptions();
        const {resource, content} = this.state;
        return (
            <div>{
                content[index]["qb"] ? <Row><Col md={11}>
                        <InlineEditable
                            style={{minWidth: "50%"}}
                            className="pointer"
                            fullWidth={true}
                            value={this.invoiceDataField(content[index]["qb"], content[index]["th"], false, "right", index, true)}
                            form={(done) =>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(invoiceQbOptions, resource[`qb_invoice_${index}`])}
                                        options={invoiceQbOptions}
                                        placeholder="Invoice"
                                        isClearable
                                        onInputChange={this.qbInvoiceSearch.search}
                                        onChange={this.selectResourceAttr(`qb_invoice_${index}`,
                                            () => this.handleSearchQbInvoice(resource, index)
                                        )}
                                />
                            }
                        />
                    </Col>
                        <Col md={1}>
                            <div onClick={() => {
                                let rows = this.state.collapsedRows
                                if (rows.includes(index)) {
                                    rows = rows.filter(r => r !== index)
                                } else {
                                    rows.push(index)
                                }
                                this.setState({collapsedRows: rows})
                            }}>
                                {this.state.collapsedRows.includes(index) ? <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleUp}/> : <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleDown}/>}
                            </div>
                        </Col>
                    </Row> :
                    <Row>
                        <Col xs={12} className="hover-cell">
                            <InlineEditable
                                style={{minWidth: "50%"}}
                                className="pointer"
                                value={<h4><b>Not Assigned</b></h4>}
                                handleSubmit={() => console.log('SUBMIT INLINE EDIT')}
                                form={(done) =>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(invoiceQbOptions, resource[`qb_invoice_${index}`])}
                                            options={invoiceQbOptions}
                                            placeholder="Invoice"
                                            isClearable
                                            onInputChange={this.qbInvoiceSearch.search}
                                            onChange={this.selectResourceAttr(`qb_invoice_${index}`,
                                                () => this.handleSearchQbInvoice(resource, index)
                                            )}
                                    />
                                }
                            />
                        </Col>
                    </Row>
            }

            </div>
        );
    }

    sync = () => {
        const {resource} = this.state;
        const {invoiceFilterType, invoiceQbFilter, invoiceThFilter, page} = this.props;
        this.props.actions.syncInvoices({
            ...resource,
            invoiceFilterType: invoiceFilterType,
            invoiceQbFilter: invoiceQbFilter,
            invoiceThFilter: invoiceThFilter,
            page: page ? page : resource.page
        }, response => this.setState(response, () => {
            this.props.setIsNotLoading();
        }));
    }

    syncQbInvoice = (qbId, index, updateResponse) => {
        let {content} = this.state;
        if (updateResponse?.data?.errorMessage) {
            const errorModal = {
                show: true,
                message: updateResponse?.data?.errorMessage
            }
            this.setState({errorModal})
        }
        this.props.actions.getQbInvoice(qbId,
            (result) => {
                content[index].th = result.content[0].invoice;
                content.rowLoading = null;
                this.setState(content);
            },
            res => {
                if (res?.data?.message) {
                    const errorModal = {
                        show: true,
                        message: res?.data?.message
                    }
                    this.setState({errorModal})
                }
                content.rowLoading = null;
                this.setState(content);
            }
        )
    }

    syncThInvoice = (thId, index, updateResponse) => {
        let {content} = this.state;
        if (updateResponse?.data?.errorMessage) {
            const errorModal = {
                show: true,
                message: updateResponse?.data?.errorMessage
            }
            this.setState({errorModal})
        }
        this.props.actions.getInvoice(thId,
            (result) => {
                content[index].th = result;
                if (result.quickbooks_id) {
                    this.props.actions.getQbInvoice(result.quickbooks_id, (response) => {
                        content[index].qb = response.content[0];
                        content.rowLoading = null;
                        this.setState(content);
                    })
                } else {
                    content.rowLoading = null;
                    this.setState(content);
                }
            },
            res => {
                if (res?.data?.message) {
                    const errorModal = {
                        show: true,
                        message: res?.data?.message
                    }
                    this.setState({errorModal})
                }
                content.rowLoading = null;
                this.setState(content);
            }
        )
    }

    createProposalServices = (service, template) => {
        return (
            <tr className="text-center">
                <td style={{color: template && service.service_name && service.service_name === template.service_name ? "green" : "red"}}>{service.service_name}</td>
                <td style={{color: template && service.man_hours && +service.man_hours === +template.man_hours ? "green" : "red"}}>{+service.man_hours}</td>
                <td style={{color: template && service.service_total && service.service_total === template.service_total ? "green" : "red"}}>
                    <Dollars amount={service.service_total}/></td>
            </tr>
        );
    }

    nameFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.proposal_services && template.proposal_services[index] && template.proposal_services[index].service_name === cell ? "green" : "red"}}>{cell}</p>
    }

    quantityFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.proposal_services && template.proposal_services[index] && +template.proposal_services[index].man_hours === +cell ? "green" : "red"}}>{+cell}</p>
    }

    notesFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.proposal_services && template.proposal_services[index] && template.proposal_services[index]?.proposal_service_notes?.trim() === cell?.trim() ? "green" : "red"}}>{cell?.trim()}</p>
    }

    totalFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.proposal_services && template.proposal_services[index] && template.proposal_services[index].service_total === cell ? "green" : "red"}}>
            <Dollars amount={cell}/></p>
    }

    idFormatter = (cell, row) => {
        return row.service_id ? row.service_id : row.quickbooks_id;
    }

    invoiceDataField = (invoice, template, withSave = false, column, index, isEditable) => {
        const inv = invoice && moment.utc(invoice.invoiced_at).format(defaultDateFormat).toString()
        const temp = template && moment.utc(template.invoiced_at).format(defaultDateFormat).toString()
        const test = inv === temp
        return (
            <Row>
                {this.state.collapsedRows.includes(index) ? <Col md={isEditable ? 12 : 11}>
                    <Row className="bottom5">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                            Invoice:
                        </Col>
                        <Col xs={9} className="text-left">
                            {column === 'left' ?
                                <OverlayTrigger
                                    placement='right'
                                    overlay={
                                        <Tooltip id='tooltip-customer-info'>
                                            Show invoice
                                        </Tooltip>
                                    }
                                >
                                    <Link target="_blank" to={`/proposals/${invoice?.proposal_id}/invoices`}>
                                        <span style={{color: template && invoice.number && template.number && +invoice.number === +template.number ? "green" : "red"}}>
                                            {invoice.number}
                                        </span>
                                    </Link>
                                </OverlayTrigger>
                                :
                                <span style={{color: template && invoice.number && template.number && +invoice.number === +template.number ? "green" : "red"}}>
                                    {invoice.number}
                                </span>
                            }
                        </Col>
                    </Row>
                    <Row className="bottom5">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                            Proposal No:
                        </Col>
                        <Col xs={9} className="text-left">
                            <span style={{color: template && invoice.proposal_no && template.proposal_no && +invoice.proposal_no === +template.proposal_no ? "green" : "red"}}>{invoice.proposal_no}</span>
                        </Col>
                    </Row>
                    <Row className="bottom5">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                            Sales Rep:
                        </Col>
                        <Col xs={9} className="text-left">
                            <span style={{color: template && invoice.sales_rep && template.sales_rep && invoice.sales_rep === template.sales_rep ? "green" : "red"}}>{invoice.sales_rep}</span>
                        </Col>
                    </Row>
                    <Row className="bottom5">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                            Customer:
                        </Col>
                        <Col xs={9} className="text-left">
                            {column === 'left' ?
                                <OverlayTrigger
                                    placement='right'
                                    overlay={
                                        <Tooltip id='tooltip-customer-info'>
                                            Show customer
                                        </Tooltip>
                                    }
                                >
                                    <Link target="_blank" to={`/customer/info/${invoice?.customer_id}`}>
                                        <span style={{color: template && invoice.customer_name?.trim() === template.customer_name?.trim() ? "green" : "red"}}>
                                            {invoice.customer_name && invoice.customer_name}
                                        </span>
                                    </Link>
                                </OverlayTrigger>
                                :
                                <span style={{color: template && invoice.customer_name?.trim() === template.customer_name?.trim() ? "green" : "red"}}>
                                    {invoice.customer_name && invoice.customer_name}
                                </span>
                            }
                        </Col>
                    </Row>
                    <Row className="bottom5">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                            Invoiced at:
                        </Col>
                        <Col xs={9} className="text-left">
                            <span style={{color: template && invoice.invoiced_at && template.invoiced_at && moment.utc(invoice.invoiced_at).format(defaultDateFormat).toString() === moment.utc(template.invoiced_at).format(defaultDateFormat).toString() ? "green" : "red"}}>{moment.utc(invoice.invoiced_at).format(defaultDateFormat)}</span>
                        </Col>
                    </Row>
                    <Row className="bottom5">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                            Due date:
                        </Col>
                        <Col xs={9} className="text-left">
                            <span style={{color: template && invoice.due_date && template.due_date && moment.utc(invoice.due_date).format(defaultDateFormat).toString() === moment.utc(template.due_date).format(defaultDateFormat).toString() ? "green" : "red"}}>{invoice.due_date && moment.utc(invoice.due_date).format(defaultDateFormat)}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                            Proposal services:
                        </Col>
                        <Col xs={9}>
                            {invoice.proposal_services && invoice.proposal_services.length > 0 &&
                                <BootstrapTable
                                    ref="table"
                                    data={invoice.proposal_services.sort((a, b) => b.service_name - a.service_name)}
                                    striped={false}
                                    bordered
                                    remote
                                    className="wrapped"
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden
                                        isKey
                                        dataFormat={(cell, row, enumObj, index) => this.idFormatter(cell, row)}
                                    />
                                    <TableHeaderColumn
                                        className='align-center'
                                        columnClassName='align-center'
                                        dataField="service_name"
                                        dataFormat={(cell, row, enumObj, index) => this.nameFormatter(cell, row, enumObj, index, template)}
                                    >
                                        Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        className='align-center'
                                        columnClassName='align-center'
                                        dataField="proposal_service_notes"
                                        dataFormat={(cell, row, enumObj, index) => this.notesFormatter(cell, row, enumObj, index, template)}
                                    >
                                        Notes
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        className='align-center'
                                        columnClassName='align-center'
                                        dataField="service_total"
                                        dataFormat={(cell, row, enumObj, index) => this.totalFormatter(cell, row, enumObj, index, template)}
                                    >
                                        Total
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            }
                        </Col>
                    </Row>
                    {invoice.quickbooks_sync_status &&
                        <Row>
                            <Col xs={12} className={`justify-flex-end`}>
                                <div className={`colorRed`}>
                                    <Glyphicon glyph="exclamation-sign"/>
                                    <span className={`small-left-padding`}>{invoice.quickbooks_sync_descripton}</span>
                                </div>
                            </Col>
                        </Row>
                    }
                    {withSave &&
                        <div className="text-right">
                            <Button disabled>
                                Save
                            </Button>
                        </div>
                    }
                </Col> : <Col md={isEditable ? 12 : 11}>
                    <Row>
                        <Col xs={6} className="text-left">
                            {column === 'left' ?
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip id='tooltip-customer-info'>
                                            Show customer
                                        </Tooltip>
                                    }
                                >
                                    <Link
                                        target="_blank" to={`/customer/info/${invoice?.customer_id}`}
                                        style={{color: template && invoice.customer_name?.trim() === template.customer_name?.trim() ? "green" : "red"}}
                                    >
                                        <span style={{color: template && invoice.customer_name?.trim() === template.customer_name?.trim() ? "green" : "red"}}>
                                            {invoice.customer_name && invoice.customer_name}
                                        </span>
                                    </Link>
                                </OverlayTrigger>
                                :
                                <span style={{color: template && invoice.customer_name?.trim() === template.customer_name?.trim() ? "green" : "red"}}>
                                    {invoice.customer_name && invoice.customer_name}
                                </span>
                            }
                        </Col>
                        <Col xs={3} className="text-left">
                            <p>Number:
                                <br/>
                                {column === 'left' ?
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-customer-info'>
                                                Show invoice
                                            </Tooltip>
                                        }
                                    >
                                        <Link target="_blank" to={`/proposals/${invoice?.proposal_id}/invoices`}>
                                            <span style={{color: template && +invoice.number === +template.number ? "green" : "red"}}>
                                                {invoice.number && invoice.number}
                                            </span>
                                        </Link>
                                    </OverlayTrigger>
                                    :
                                    <span style={{color: template && +invoice.number === +template.number ? "green" : "red"}}>
                                        {invoice.number && invoice.number}
                                    </span>
                                }
                            </p>
                        </Col>
                        <Col xs={3} className="text-left">
                            <p>Invoiced at: <span
                                style={{color: template && template.invoiced_at && invoice && invoice.invoiced_at && moment.utc(invoice.invoiced_at).format(defaultDateFormat).toString() === moment.utc(template.invoiced_at).format(defaultDateFormat).toString() ? "green" : "red"}}>{invoice.invoiced_at && moment.utc(invoice.invoiced_at).format(defaultDateFormat).toString()}</span>
                            </p>
                        </Col>
                    </Row>
                </Col>
                }
                {!isEditable && <Col md={1}>
                    <div onClick={() => {
                        let rows = this.state.collapsedRows
                        if (rows.includes(index)) {
                            rows = rows.filter(r => r !== index)
                        } else {
                            rows.push(index)
                        }
                        this.setState({collapsedRows: rows})
                    }}>
                        {this.state.collapsedRows.includes(index) ? <FontAwesomeIcon
                            className={`pointer`}
                            size='2x'
                            icon={faAngleUp}/> : <FontAwesomeIcon
                            className={`pointer`}
                            size='2x'
                            icon={faAngleDown}/>}
                    </div>
                </Col>}
            </Row>
        )
    }

    invoiceFormatter = (cell, row, enumObject, index, direction = null) => {
        if (direction === 'right') {
            return (cell && !cell.isAssigned) ? this.invoiceDataField(cell, row.th, false, direction, index) : this.searchQbInvoice(index)
        } else if (direction === 'left') {
            return (cell && !cell.isAssigned) ? this.invoiceDataField(cell, row.qb, false, direction, index) : this.searchInvoice(index)
        }
    }

    choiceFormatter = (cell, row, enumObject, index) => {
        const {
            rowLoading
        } = this.state;
        const items = row;
        let thServicesSyncStatus = items.th && items.th.proposal_services && items.th.proposal_services.length > 0 && items.th.proposal_services.filter(th => !th.quickbooks_id).length === 0;
        return (
            rowLoading !== null && rowLoading == index ?
                <div><MDSpinner/></div>
                :
                <div>
                    {items.th && <div>
                        <FontAwesomeIcon
                            className={`pointer ${((items.th && !items.th.qb_customer_id) || !thServicesSyncStatus) && 'arrow-disabled'}`}
                            icon={faLongArrowAltRight}
                            size="2x"
                            data-toggle={items.th && !items.th.qb_customer_id && 'tooltip'}
                            title={items.th && !items.th.qb_customer_id ? 'You must sync customer first!' : !thServicesSyncStatus && 'You must sync services first!'}
                            onClick={() => {
                                items.th && items.th.qb_customer_id && thServicesSyncStatus &&
                                this.setState({rowLoading: index}, () => {
                                    this.props.actions.updateInvoice(items, 'right', (res) => this.syncThInvoice(items.th.id, index, res))
                                })
                            }}
                        />
                    </div>}
                </div>
        )
    }

    render() {
        const {
            content, total, errorModal
        } = this.state;
        const {page, per_page} = this.state.resource;
        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} className={`top25 no-padding`}>
                        <BootstrapTable
                            ref="table"
                            data={content}
                            striped={false}
                            bordered
                            hover={true}
                            pagination={true}
                            remote
                            fetchInfo={{dataTotalSize: total}}
                            className="wrapped"
                            trClassName={this.rowSynchronizedClass}
                            options={
                                {
                                    onPageChange: (page, per_page) => {
                                        let {resource} = this.state;
                                        resource = {...resource, page, per_page};
                                        this.setState({resource}, this.sync);
                                    },
                                    page,
                                    sizePerPageList: [{
                                        text: '10', value: 10
                                    }, {
                                        text: '25', value: 25
                                    },
                                        {
                                            text: '30', value: 30
                                        },
                                        {
                                            text: '50', value: 50
                                        },
                                        {
                                            text: '100', value: 100
                                        },
                                        {
                                            text: '200', value: 200
                                        },
                                        {
                                            text: 'All', value: total
                                        }],
                                    sizePerPage: per_page,
                                    sizePerPageDropDown: this.renderSizePerPageDropDown,
                                }
                            }
                            selectRow={{
                                mode: "hidden",
                                clickToSelect: false,
                                onSelect: this.onRowSelect,
                                onSelectAll: this.onSelectAll,
                            }}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                width={"5%"}
                                isKey
                            />
                            <TableHeaderColumn
                                width={"45%"}
                                className='align-center'
                                columnClassName='align-center'
                                dataField="th"
                                dataFormat={(cell, row, enumObj, index) => this.invoiceFormatter(cell, row, enumObj, index, 'left')}
                            >
                                TreeHub Invoices
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"10%"}
                                dataFormat={this.choiceFormatter}
                                className='align-center'
                                columnClassName='align-center'
                            >
                                Choice
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="qb"
                                className='align-center'
                                columnClassName='align-center'
                                dataFormat={(cell, row, enumObj, index) => this.invoiceFormatter(cell, row, enumObj, index, 'right')}
                                width={"45%"}
                            >
                                QuickBooks Invoices
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
                {errorModal?.show &&
                    <ErrorInfoModal
                        type="Customer"
                        message={errorModal?.message}
                        errorSites={errorModal?.error_sites}
                        onHide={() => {
                            const errorModal = {
                                show: false,
                                message: ''
                            }
                            this.setState({errorModal})
                        }}
                    />
                }
            </Grid>
        );
    }
}

QuickbooksInvoices.propTypes = {
    //myProp: PropTypes.object.isRequired
};

QuickbooksInvoices.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickbooksInvoices);
