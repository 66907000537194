import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import { addAlert } from "../App/actions"
import * as MyActions from "./actions"
import { Link } from "react-router"
import Dollars from "../../components/Dollars"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import {
  dateFormatter,
  _proposalLinkFormatter,
  options,
  _customerNameLink,
} from "../../components/BootStrapTableCellFormatters";
import {
  Grid,
  Row,
  Col,
  Button,
  FormGroup,
  MenuItem,
  Nav,
  NavDropdown,
  Glyphicon,
} from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import PageHeader from "../../components/PageTitle"
import moment from "moment-timezone"
import {saveNewCsvDownload} from "../../common/commonHandlers";
const Actions = { ...MyActions, addAlert, saveNewCsvDownload };

class Activities extends Component {
  state = {
    isFiltered: true,
    showFilters: false,
    stickyFromDate: moment().startOf('month').format('YYYY-MM-DD'),
    stickyToDate: moment().endOf('month').format('YYYY-MM-DD'),
  };

  tableRef = React.createRef()

  componentDidMount() {
      this.fetchStickies(null);
  }

    render() {
    const {
      stickies,
      invoicedStart,
      invoicedEnd,
      status,
    } = this.props;

    const {
      updateInvoicedStart,
      updateInvoicedEnd,
      updateInvoiceTypeSelection
    } = this.props.actions;

    const {
      selectedInvoices,
      invoicePaidDate,
    } = this.state;

    return (
      <Grid fluid>
        <Helmet title="Activity" />
        <PageHeader
          pageName="Activity"
          pageDetail={`${stickies ? ` found ${stickies.length} activities logged in the last 90 days.` : "looking for activity..."} `}
        />
        <form onSubmit={this.fetchStickies}>
          <Row>
            <Col md={5}>
              <Col md={2}>
                </Col>
                {' '}
                <Button bsSize="small" type="submit" bsStyle="success">
                  Find Activity
                </Button>
            </Col>
            <Col md={2}>
              { stickies && stickies.length ?
                <Button bsSize="small" bsStyle="warning" onClick={() => this.toggleColumnFilters()}>Filter Rows</Button>
                :
                null
              }
            </Col>
            <Col md={3}>

            </Col>
            <Col md={2}>

            </Col>

          </Row>
        </form>
        <p>&nbsp;</p>

        <Row>
          <Col md={12}>
            {stickies ? this._renderTable(stickies) : null}
          </Col>
        </Row>

        <Row>
          <Col md={12} />
        </Row>

      </Grid>
    )
  }

    toggleColumnFilters = () => {
      this.setState({
        showFilters: !this.state.showFilters
      })
    };

  fetchStickies = e => {
    if (e) e.preventDefault();
    this.props.actions.fetchStickies();
  };

  _dollarFormatter = cell => <Dollars amount={cell} />;

_renderTable = data => {
    const { showFilters, stickyToDate, stickyFromDate } = this.state;

    return (
      <BootstrapTable
        ref={this.tableRef}
        data={data}
        striped={true}
        bordered={false}
        hover={true}
        exportCSV={true}
        pagination={true}
        search={showFilters}
        csvFileName={() => {
            const fileName = `customer-stickies-${moment().format()}.csv`
            if (this.tableRef?.current) {
                this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "activities")
            }
            return fileName
        }}
        options={options}
      >
        <TableHeaderColumn hidden isKey dataField="id">
          Invoice Id
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          filter={showFilters ? { type: "TextFilter" } : null}
          dataSort={true}
          dataField="action_type"
        >
          Activity Type
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          filter={showFilters ? { type: "TextFilter" } : null}
          dataSort={true}
          columnClassName='menu-show-fixer'
          dataFormat={_proposalLinkFormatter}
          dataField="proposal_no"
        >
          Proposal No
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataSort={true}
          filter={showFilters ? { type: "TextFilter", delay: 500 } : null}
          dataField="user"
        >
          Created by
        </TableHeaderColumn>
        <TableHeaderColumn
          width="30%"
          tdStyle={ { whiteSpace: 'normal' } }
          thStyle={ { whiteSpace: 'normal' } }
          dataSort={true}
          filter={showFilters ? { type: "TextFilter", delay: 500 } : null}
          dataField="body"
        >
          Message/Body
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          tdStyle={ { whiteSpace: 'normal' } }
          thStyle={ { whiteSpace: 'normal' } }
          dataSort={true}
          dataFormat={_customerNameLink}
          filter={showFilters ? { type: "TextFilter", delay: 500 } : null}
          columnClassName="menu-show-fixer"
          dataField="customer_name"
        >
          Customer
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataSort={true}
          dataFormat={dateFormatter}
          dataField="created_at"
        >
          Created Date
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataSort={true}
          dataFormat={dateFormatter}
          dataField="last_follow_up_date"
        >
          Last Follow Up
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataSort={true}
          dataFormat={dateFormatter}
          dataField="follow_up_date"
        >
          Follow Up
        </TableHeaderColumn>
      </BootstrapTable>
    )
  };

  _linkFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            eventKey={2}
            title={`${row.customerName}`}
            id={`proposal-menu`}
            className="menu-show-fixer proposal-menu-padding-reset"
        >
            <LinkContainer to={`/customer/info/${row.customerId}`}>
              <MenuItem bsSize="small">Info</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/proposals/${row.proposalId}`}>
              <MenuItem bsSize="small">Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/sites/${row.customerId}`}>
              <MenuItem bsSize="small">Sites</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_orders/${row.customerId}`}>
              <MenuItem bsSize="small">Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/invoices/${row.customerId}`}>
              <MenuItem bsSize="small">Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_history/${row.customerId}`}>
              <MenuItem bsSize="small">Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
  );

  _proposalLinkFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            eventKey={2}
            title={cell}
            id={`proposal-menu`}
            className="menu-show-fixer proposal-menu-padding-reset"
        >
            <LinkContainer to={`/mapview/${row.stickable_id}`}>
              <MenuItem bsSize="small">Edit Proposal</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/proposal_service_status/${row.stickable_id}`}>
              <MenuItem bsSize="small">Proposal Statuses</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/print_proposal/${row.stickable_id}`}>
              <MenuItem bsSize="small">Print Proposal</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
  );

  _acceptFormatter = (cell, row) => (
    <Link to={`/proposal_service_status/${row.proposalId}`}>
      <span className='text-success'><Glyphicon glyph='ok'/></span>
    </Link>
  );

  _blankFormatter = (cell, row) => (
    <Link to={`/proposal_service_status/${row.proposalId}`}>
      <span className='text-success'></span>
    </Link>
  )
}

function mapStateToProps(state) {
  return {
    stickies: state.activities.stickies,
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(Actions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities)
