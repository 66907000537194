import {apiCall} from "../../../../common/apiActionHandler";
import axios from "axios";

export const createLinkToken = (invoiceNo, callback) => (dispatch, getState) => {
    const config = {
        method: 'post',
        url: `/api/stripe/create_plaid_link_token?invoice_no=${invoiceNo}`,
    };

    apiCall("Create plaid link token", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const payWithPlaid = (amount, customer, invoiceToken, partialAmount, stripeFee, callback) => (dispatch, getState) => {
    const config = {
        method: 'post',
        url: '/api/stripe/plaid_pay',
        data: {
            amount,
            customer,
            invoiceToken,
            partialAmount,
            stripeFee
        }
    };

    apiCall("Pay with plaid", config, async config => {
        const response = await axios.request(config);
        callback(response);
    }, dispatch, getState, false);
};
