import React, {useEffect} from "react"
import {Row} from "react-bootstrap";
import moment from "moment-timezone";
import TimelineEvent from "./TimelineElements/TimelineEvent";
import TimelineCrewMessage from "./TimelineElements/TimelineCrewMessage";
import TimelineNote from "./TimelineElements/TimelineNote";
import {defaultDateFormat} from "../../../../common/commonHandlers";
import TimelineAxisDate from "./TimelineElements/TimelineAxisDate";
import MDSpinner from "react-md-spinner";

const CustomerTimeline = ({onSuccess, customerId, saveNote, deleteCustomerNote, timelineData, currentUserPersonId, employees, ready}) => {
    let messages = document.getElementById("timeline-body")
    useEffect(() => {
        if (messages) {
            messages.scrollTop = messages.scrollHeight;
        }
    }, [messages])
    const employee = person_id => employees.find(e => +e.value === +person_id)

    return (ready ? <div id="customer-timeline">
            <Row id="timeline-body">
                {ready ? <div className={`${timelineData.length > 0 ? "timeline" : "full-height"}`}>
                    <div>
                        {timelineData.length > 0 ? timelineData.map((el, i) => {
                            const elementEmployee = el.data.person_id && employee(el.data.person_id)
                            const elementColor = elementEmployee && elementEmployee.color ? elementEmployee.color : "#dadada"
                            const toSetNextDay = timelineData[i - 1] ? moment(timelineData[i - 1].data.created_at).format(defaultDateFormat) !== moment(el.data.created_at).format(defaultDateFormat) : true
                            if (el.type === 'event') {
                                return <>
                                    {toSetNextDay && <TimelineAxisDate date={el.data.created_at}/>}
                                    <TimelineEvent
                                        event={el.data}
                                        site={el.site}
                                        wo={el.wo}
                                        proposal={el.proposal}
                                        leadId={el.lead_id}
                                        eventType={el.event_type}
                                        color={elementColor}
                                        employeeName={elementEmployee?.label}
                                        employeeDisabled={elementEmployee?.disabled}
                                        employeePrimaryPhoto={elementEmployee?.images?.length > 0 && elementEmployee?.images.find(i => i.primary === true)}
                                        onSuccess={() => onSuccess(customerId)}
                                        createdBy={el.created_by}
                                    />
                                </>
                            } else if (el.type === 'crew message') {
                                return <>
                                    {toSetNextDay && <TimelineAxisDate date={el.data.created_at}/>}
                                    <TimelineCrewMessage
                                        message={el.data}
                                        images={el.images}
                                        color={elementColor}
                                        employeeName={elementEmployee?.label}
                                        employeeDisabled={elementEmployee?.disabled}
                                        employeePrimaryPhoto={elementEmployee?.images?.length > 0 && elementEmployee?.images.find(i => i.primary === true)}
                                    />
                                </>
                            } else if (el.type === 'note') {
                                return <>
                                    {toSetNextDay && <TimelineAxisDate date={el.data.created_at}/>}
                                    <TimelineNote
                                        note={el.data}
                                        color={elementColor}
                                        employeeName={elementEmployee?.label}
                                        employeeDisabled={elementEmployee?.disabled}
                                        currentUserPersonId={currentUserPersonId}
                                        onSave={note => saveNote(note)}
                                        onDelete={deleteCustomerNote}
                                        employeePrimaryPhoto={elementEmployee?.images?.length > 0 && elementEmployee?.images.find(i => i.primary === true)}
                                    />
                                </>
                            }
                        }) : <p className="no-data">Nothing to report...</p>}
                    </div>
                    <TimelineNote
                        employeeName={employee(currentUserPersonId)?.label}
                        employeeDisabled={employee(currentUserPersonId)?.disabled}
                        color={employee(currentUserPersonId)?.color}
                        onSave={note => saveNote(note)}
                        employeePrimaryPhoto={employee(currentUserPersonId)?.images?.length > 0 && employee(currentUserPersonId)?.images.find(i => i.primary === true)}
                        customerId={customerId}
                    />
                </div> : <div className="vhCenter full-height">
                    <MDSpinner size={70} borderSize={2}/>
                </div>}
            </Row>
        </div> : <div className="customer-timeline vhCenter">
            <MDSpinner size={70} borderSize={2}/>
        </div>
    )
}

export default CustomerTimeline;