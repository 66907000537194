import React, {useEffect, useRef, useState} from 'react';
import {Recorder} from './js/Recorder';
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone} from "@fortawesome/free-solid-svg-icons";
import MDSpinner from "react-md-spinner"; // Adjust the path as needed
import './AudioRecorder.scss'

const AudioRecorder = ({setRecordedFile, onClose, isVoiceProcess}) => {
    const recorderRef = useRef(null);
    const audioContextRef = useRef(null);
    const [firstRecord, setFirstRecord] = useState(true);
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null)

    const startRecording = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({audio: true});
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContextRef.current.createMediaStreamSource(mediaStream);
            recorderRef.current = new Recorder(source);

            // Start recording
            recorderRef.current.record();
            setAudioBlob(null)
            setIsRecording(true)
            setFirstRecord(false)
        } catch (error) {
            // Handle error
            console.error('Error accessing user media:', error);
        }
    };

    const stopRecording = () => {
        // Stop recording
        if (recorderRef.current) {
            recorderRef.current.stop();
            recorderRef.current.exportWAV(handleExportAudio);
            setIsRecording(false)
        }
    };

    const handleExportAudio = (blob) => {
        setAudioBlob(blob)
    };
    const sendRecord = () => {

        console.log('send recording');
        setRecordedFile(audioBlob)
    };
    return (
        <Modal
            animation={false}
            show={true}
            className={"recorder heightAuto"}
            backdrop={false}
        >
            <Modal.Title>Voice Recorder</Modal.Title>
            <Modal.Body>
                <div className='icon-container'>
                    <FontAwesomeIcon icon={faMicrophone} className="bigger"/>
                </div>
                {isVoiceProcess ? <div><MDSpinner size={20}/>Processing...</div> : <div className='buttons'>
                    <Button bsStyle={`${isRecording ? 'primary' : firstRecord ? 'success' : 'primary'}`}
                            onClick={isRecording ? stopRecording : startRecording}>{isRecording ? 'Stop' : firstRecord ? 'Start' : 'Reset'}</Button>
                    {!audioBlob && <Button bsStyle="danger" onClick={() => {
                        recorderRef.current && recorderRef.current.stop();
                        onClose()
                    }}>Cancel</Button>}
                    {audioBlob && <Button bsStyle="success" onClick={sendRecord}>Confirm</Button>}
                        </div>}

                        </Modal.Body>

                        </Modal>
                        );
                    };

export default AudioRecorder;
