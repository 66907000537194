import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";

export const loadData = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/referral_report`,
        params: filter
    };

    apiCall("Loading referral report data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
