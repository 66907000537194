import {Component} from "react";

export default class DragComponent extends Component {


    checkY = (panel, this_panel, y) => {
        if ((panel.y >= y && panel.y <= y + this_panel.height) ||
            (panel.y <= y && panel.y + panel.height >= y + this_panel.height) || (panel.y + panel.height > y &&
                panel.y + panel.height < y + this_panel.height)) {
            return false;
        } else {
            return true;
        }
    }

    checkX = (panel, this_panel, x) => {
        if ((panel.x >= x && panel.x <= x + this_panel.width) ||
            (panel.x <= x && panel.x + panel.width >= x + this_panel.width) || (panel.x + panel.width > x &&
                panel.x + panel.width < x + this_panel.width)) {
            return false;
        } else {
            return true;
        }
    }

    isThereCollicion = (this_panel, panel) => {
        if (((this_panel.x > panel.x && this_panel.x < panel.x + panel.width) || (this_panel.x + this_panel.width > panel.x && this_panel.x + this_panel.width < panel.x + panel.width) ||
            (this_panel.x < panel.x && this_panel.x + this_panel.width > panel.x + panel.width)) &&
            ((this_panel.y > panel.y && this_panel.y < panel.y + panel.height) || (this_panel.y + this_panel.height > panel.y && this_panel.y + this_panel.height < panel.y + panel.height) ||
                (this_panel.y < panel.y && this_panel.y + this_panel.height > panel.y + panel.height))) {
            return true;
        } else {
            return false;
        }
    }

    generateNewSizeForOneCollision = (this_panel, old_this_panel, distances, panels) => {

        let newPanel = {x: this_panel.x, y: this_panel.y, height: this_panel.height, width: this_panel.width};
        panels.map((panel, index) => {
            if (distances[index] !== null && (newPanel.y !== old_this_panel.y || newPanel.width !== old_this_panel.width) && newPanel.x === old_this_panel.x) {
                if (distances[index]["left"] < distances[index]["bottom"]) {
                    this.setState({
                        x: newPanel.x,
                        y: newPanel.y,
                        width: newPanel.width - 1 - distances[index]["left"],
                        height: newPanel.height
                    });
                    newPanel = {
                        x: this_panel.x,
                        y: this_panel.y,
                        height: this_panel.height,
                        width: newPanel.width - 1 - distances[index]["left"]
                    }
                } else {
                    this.setState({
                        x: newPanel.x,
                        y: newPanel.y + distances[index]["bottom"] + 1,
                        width: newPanel.width,
                        height: newPanel.height - distances[index]["bottom"] - 1
                    });
                    newPanel = {
                        x: this_panel.x,
                        y: newPanel.y + distances[index]["bottom"] + 1,
                        height: newPanel.height - distances[index]["bottom"] - 1,
                        width: newPanel.width
                    }
                }
            } else if (distances[index] !== null && (newPanel.x !== old_this_panel.x || newPanel.height !== old_this_panel.height) && newPanel.y === old_this_panel.y) {
                if (distances[index]["right"] < distances[index]["top"]) {
                    this.setState({
                        x: newPanel.x + 1 + distances[index]["right"],
                        y: newPanel.y,
                        width: newPanel.width - 1 - distances[index]["right"],
                        height: newPanel.height
                    });
                    newPanel = {
                        x: newPanel.x + 1 + distances[index]["right"],
                        y: this_panel.y,
                        height: this_panel.height,
                        width: newPanel.width - 1 - distances[index]["right"]
                    }
                } else {
                    this.setState({
                        x: newPanel.x,
                        y: newPanel.y,
                        width: newPanel.width,
                        height: newPanel.height - 1 - distances[index]["top"]
                    });
                    newPanel = {
                        x: newPanel.x,
                        y: this_panel.y,
                        height: newPanel.height - 1 - distances[index]["top"],
                        width: newPanel.width
                    }
                }
            } else if (distances[index] !== null && newPanel.x !== old_this_panel.x && newPanel.y !== old_this_panel.y) {
                if (distances[index]["right"] < distances[index]["bottom"]) {
                    this.setState({
                        x: newPanel.x + 1 + distances[index]["right"],
                        y: newPanel.y,
                        width: newPanel.width - 1 - distances[index]["right"],
                        height: newPanel.height
                    });
                    newPanel = {
                        x: newPanel.x + 1 + distances[index]["right"],
                        y: this_panel.y,
                        height: this_panel.height,
                        width: newPanel.width - 1 - distances[index]["right"]
                    }
                } else {
                    this.setState({
                        x: newPanel.x,
                        y: newPanel.y + distances[index]["bottom"] + 1,
                        width: newPanel.width,
                        height: newPanel.height - distances[index]["bottom"] - 1
                    });
                    newPanel = {
                        x: this_panel.x,
                        y: newPanel.y + distances[index]["bottom"] + 1,
                        height: newPanel.height - distances[index]["bottom"] - 1,
                        width: newPanel.width
                    }
                }
            }
        });
    }

    loopIfThereIsCollision = (panels, this_panel, x, y) => {
        console.log(this.state)

        let newPosition = {x, y};
        let collisions = 0;
        let distances = [];
        panels.map((panel, index) => {
            let distance = [];
            if (this.isThereCollicion(this_panel, panel)) {
                collisions += 1;
                const top = Math.abs(panel.y - newPosition.y - this_panel.height);
                const bottom = Math.abs(panel.y + panel.height - this_panel.y);
                const right = Math.abs(panel.x + panel.width - this_panel.x);
                const left = Math.abs(panel.x - newPosition.x - this_panel.width);
                distance = [["top", top], ["bottom", bottom], ["right", right], ["left", left]];
            }
            distances.push(distance.sort((a, b) => a[1] > b[1] ? 1 : -1));
        });
        if (collisions <= 1) {
            this.generateNewPositionForOneCollision(distances, panels, this_panel, x, y);
        } else {
            this.generateNewPositionForManyCollision(distances, panels, this_panel, x, y);
        }
    }


    generateNewPositionForManyCollision = (distances, panels, this_panel, x, y) => {
        console.log(this.state)

        let newPosition = {x, y};
        panels.map((panel, index) => {
            let setNewPosition = false;
            for (let i = 0; i < distances[index].length; i++) {
                if (distances[index][i][0] === "left" && panel.x > this_panel.width) {
                    this.setState({
                        x: panel.x - this_panel.width - 1,
                        y: newPosition.y
                    });
                    newPosition = {x: panel.x - this_panel.width - 1, y: newPosition.y};
                    setNewPosition = true;
                    if (setNewPosition) break;
                } else if (distances[index][i][0] === "right" && this.props.windowWidth - panel.x - panel.width >= this_panel.width) {
                    this.setState({
                        y: newPosition.y,
                        x: panel.x + panel.width + 1
                    });
                    newPosition = {x: panel.x + panel.width + 1, y: newPosition.y};
                    setNewPosition = true;
                    if (setNewPosition) break;
                } else if (distances[index][i][0] === "top" && panel.y > this_panel.height) {
                    this.setState({
                        y: panel.y - this_panel.height - 1,
                        x: newPosition.x
                    });
                    newPosition = {x: newPosition.x, y: panel.y - this_panel.height - 1};
                    setNewPosition = true;
                    if (setNewPosition) break;
                } else if (distances[index][i][0] === "bottom" && this.props.parentRef.offsetHeight - panel.y - panel.height >= this_panel.height) {
                    this.setState({
                        y: panel.y + panel.height + 1,
                        x: newPosition.x
                    })
                    newPosition = {x: newPosition.x, y: panel.y + panel.height + 1};
                    setNewPosition = true;
                    if (setNewPosition) break;
                }
                ;
            }
        })
        let isCollosion = false;
        panels.map((panel, index) => {
            if (this.isThereCollicion({
                x: newPosition.x,
                y: newPosition.y,
                width: this.state.width,
                height: this.state.height
            }, panel)) {
                isCollosion = true;
            }
        });
        if (isCollosion) {
            this.loopIfThereIsCollision(panels, {
                x: newPosition.x,
                y: newPosition.y,
                width: this.state.width,
                height: this.state.height
            }, newPosition.x, newPosition.y);
        }
    }


    checkOverlap = (x, y, width, height, el_x, el_y, el_width, el_height) => {
        if (((x < el_x && x + width < el_x) || (x > el_x + el_width
            && x + el_width > el_x + el_width)) ||
            ((y < el_y && y + height < el_y) || (y > el_y + el_height
                && y + height > el_y + el_height))) {
            return true;
        } else {
            return false;
        }
    }

    loopIdThereIsCollisionForResize = (panels, this_panel, old_this_panel) => {
        let newPosition = {x: this_panel.x, y: this_panel.y};
        let distances = [];
        panels.map((panel, index) => {
            let distance = null;
            if (this.isThereCollicion(this_panel, panel)) {
                const top = Math.abs(panel.y - newPosition.y - this_panel.height);
                const bottom = Math.abs(panel.y + panel.height - this_panel.y);
                const right = Math.abs(panel.x + panel.width - this_panel.x);
                const left = Math.abs(panel.x - newPosition.x - this_panel.width);
                distance = {top: top, bottom: bottom, right: right, left: left};
            }
            distances.push(distance);
        });
        this.generateNewSizeForOneCollision(this_panel, old_this_panel, distances, panels);
    }

    panelsLoop = (this_panel, panels) => {
        let score = true;
        panels.map(panel => {
            if (panel.show) {
                const x = this.checkOverlap(this_panel.x, this_panel.y, this_panel.width, this_panel.height, panel.x, panel.y, panel.width, panel.height);
                if (x === false) score = false;
            }
        });
        return score;
    }

    generateNewPositionForOneCollision = (distances, panels, this_panel, x, y) => {
        console.log(this.state)

        let newPosition = {x, y};
        panels.map((panel, index) => {
            let setNewPosition = false;
            for (let i = 0; i < distances[index].length; i++) {
                if (distances[index][i][0] === "left" && panel.x > this_panel.width) {
                    panels.map(_panel => {
                        if (_panel.x !== panel.x && panel.x - _panel.x - _panel.width > this_panel.width || this.checkY(_panel, this_panel, newPosition.y)) {
                            this.setState({
                                x: panel.x - this_panel.width - 1,
                                y: newPosition.y
                            });
                            newPosition = {x: panel.x - this_panel.width - 1, y: newPosition.y};
                            setNewPosition = true;
                        }
                    });
                    if (setNewPosition) break;
                } else if (distances[index][i][0] === "right" && this.props.windowWidth - panel.x - panel.width >= this_panel.width) {
                    panels.map(_panel => {
                        if (_panel.x !== panel.x && Math.abs(_panel.x - panel.x - panel.width) > this_panel.width || this.checkY(_panel, this_panel, newPosition.y)) {
                            this.setState({
                                y: newPosition.y,
                                x: panel.x + panel.width + 1
                            });
                            newPosition = {x: panel.x + panel.width + 1, y: newPosition.y};
                            setNewPosition = true;
                        }
                    });
                    if (setNewPosition) break;
                } else if (distances[index][i][0] === "top" && (i > 0 ? distances[index][i - 1][0] !== "bottom" : true) && panel.y > this_panel.height) {
                    panels.map(_panel => {
                        if (_panel.y !== panel.y && panel.y - _panel.y - _panel.height > this_panel.height || panel.y < _panel.y || this.checkX(_panel, this_panel, newPosition.x)) {
                            this.setState({
                                y: panel.y - this_panel.height - 1,
                                x: newPosition.x
                            });
                            newPosition = {x: newPosition.x, y: panel.y - this_panel.height - 1};
                            setNewPosition = true;
                        }
                    });
                    if (setNewPosition) break;
                } else if (distances[index][i][0] === "bottom" && this.props.parentRef.offsetHeight - panel.y - panel.height >= this_panel.height) {
                    panels.map(_panel => {
                        if (_panel.y !== panel.y && Math.abs(_panel.y - panel.y - panel.height) > this_panel.height || panel.y > _panel.y || this.checkX(_panel, this_panel, newPosition.x)) {
                            this.setState({
                                y: panel.y + panel.height + 1,
                                x: newPosition.x
                            })
                            newPosition = {x: newPosition.x, y: panel.y + panel.height + 1};
                            setNewPosition = true;
                        }
                    });
                    if (setNewPosition) break;
                }
                ;
            }
        });
    }




}