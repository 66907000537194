import {dataUpload} from "../../../../common/upload";
import {apiCall} from "../../../../common/apiActionHandler";
import axios from "axios";

export const uploadAttachment = (acceptedFiles, id, callback) => (dispatch, getState) => {
    dataUpload(`/api/equipment_answers_files/upload?id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteAttachment = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/equipment_answers_files/${id}`,
    };

    apiCall("Deleting attachment", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true);
}

export const getAnswerFiles = (ids, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/equipment_answers_files/get_all_answer_files`,
        params: {ids}
    };

    apiCall("Getting Answer files", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
