import React, {Component} from 'react';
import stopTriggeringClickOnDoubleClick from "../../common/stopTriggeringClickOnDoubleClick";
import {Marker} from "react-google-maps";
import {getColorIcon} from "../MapViewPage/IconProvider";
import {browserHistory} from "react-router";
import {getBasicAssetMarker, getBasicMarker} from "../../utilities";

class SiteMarker extends Component {
    render() {
        const {
            site: s,
            selectedSiteId,
            selectSiteMarker,
            idx,
            onClick,
            onDoubleClick,
            token,
            setSelectedSite
        } = this.props
        return (
            <Marker
                key={s.id}
                position={{
                    lat: s.latitude,
                    lng: s.longitude
                }}
                icon={getBasicMarker({
                    selected: false,
                    color: selectedSiteId === s.id ? '#f45732' : '#ffff00',
                    label: (idx + 1).toString()
                })}
                title={`${s.name}\n${s.full_address}`}
                onClick={() => onClick(() => selectSiteMarker(s.id))}
                onDblClick={() => onDoubleClick(() => {
                    setSelectedSite(s.id, token, () => {
                        browserHistory.push(`/customer_view/${token}/proposals`)
                    })
                })}
                zIndex={selectedSiteId === s.id ? 1000 : 1}
            />

        );
    }
}

export default stopTriggeringClickOnDoubleClick(SiteMarker);