import React, {Component} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import * as MyActions from "./actions"
import ProposalServiceForm from "./ProposalServiceForm"

const Actions = {...MyActions}

class SortableServiceList extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // return false
        // if(nextProps.siteAssets &&
        //     nextProps.siteAssets.length > 0 &&
        //   	this.props.siteAssets.length > 0 &&
        //   	nextProps.siteAssets != this.props.siteAssets) {
        //     return false
        //   }
        return true
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (newIndex != oldIndex) {
            // reorder proposal services
            this.props.actions.reorderProposalServices(oldIndex, newIndex)
        }
    }

    render() {
        const {
            username,
            salesArboristSearch,
            salesArborists,

            selectedCustomer,
            customerList,
            customerSearch,

            siteSearch,
            customerSites,
            siteAssets,

            proposal,
            proposalServices,
            newProposalService,

            serviceList,
            equipmentList,
            priorities,

            editingAssetId,

            siteAssetsDbhs,
            site,
        } = this.props

        const SortableItem = SortableElement(({value}) =>
            <ProposalServiceForm
                key={value.id}
                proposalService={value}
                siteAssets={siteAssets}
                serviceList={serviceList}
                updateService={this.props.actions.updateService}
                selectService={this.props.actions.selectService}
                equipmentList={equipmentList}
                addEquipment={this.props.actions.addEquipment}
                removeEquipment={this.props.actions.removeEquipment}
                priorities={priorities}
                haveOpenService={false}
                updatePsAttribute={
                    this.props.actions.updatePsAttribute
                }
                updateHaveOpenService={
                    this.props.actions.updateHaveOpenService
                }
                savePsChanges={this.props.actions.savePsChanges}
                addAssetsToPs={this.props.actions.addAssetsToPs}
                removeAssetFromPs={
                    this.props.actions.removeAssetFromPs
                }
                siteAssetsDbhs={siteAssetsDbhs}
                movePs={
                    proposal.isAcceptable
                        ? this.props.actions.movePs
                        : undefined
                }
                deleteProposalService={
                    this.props.actions.deleteProposalService
                }
                onAssetMouseOver={this.props.actions.onAssetMouseOver}
                onAssetMouseOut={this.props.actions.onAssetMouseOut}
                onAddNewPs={this.props.actions.onAddNewPs}
            />
        )
        const SortableList = SortableContainer(({items}) => {
            return (
                <div key={`services-${items.length}`}>
                    {items.map((service, index) => (
                        <SortableItem key={`item-${index}`} index={index} value={service}/>
                    ))}
                </div>
            )
        })
        if (Array.isArray(proposalServices)) {
            return (
                <SortableList key='xxx' pressDelay={200} items={proposalServices} onSortEnd={this.onSortEnd}/>
            )
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        username: state.auth.email,
        salesArboristSearch: state.mapView.salesArboristSearch,
        salesArborists: state.salesArborists.salesArboristsList,
        customerList: state.mapView.customerSearchList,
        customerSearch: state.mapView.customerSearch,
        siteSearch: state.mapView.customerSiteSearch,
        customerSites: state.mapView.customerSites,
        siteAssets: state.mapView.siteAssets,

        proposal: state.mapView.proposal,
        proposalServices: state.mapView.proposalServices.filter(ps => ps.id > 0),
        newProposalService: state.mapView.proposalServices.find(ps => ps.id === 0),

        serviceList: state.mapView.services,
        equipmentList: state.mapView.equipments,

        priorities: state.mapView.priorities,

        editingAssetId: state.mapView.editingAssetId,
        selectedCustomer: state.mapView.selectedCustomer,
        siteAssetsDbhs: state.mapView.siteAssetsDbhs,
        site: state.mapView.sites[`site_${state.mapView.proposal.siteId}`],
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(SortableServiceList)
