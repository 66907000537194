import React from "react";
import {GoogleMap, Marker} from "react-google-maps";
import {getColorCircleIcon} from "../../../MapViewPage/IconProvider";
import {GoogleMapLoader} from "../../../../components/GoogleMapLoader";

const CustomerSitesMap = ({sites}) => {
    let mapParams = []

    if (sites?.length > 0) {
        mapParams = sites.map(({longitude, latitude}) => ({longitude, latitude}))
    }

    return <GoogleMapLoader
        containerElement={<div className="full-height"/>}
        googleMapElement={
            <GoogleMap
                ref={(map) => {
                    const bounds = new window.google.maps.LatLngBounds();
                    mapParams.map(ass => (
                        bounds.extend(new window.google.maps.LatLng(ass.latitude, ass.longitude))
                    ));
                    map && map.fitBounds(bounds)
                }}
                mapTypeId={"hybrid"}
                tilt={0}
            >
                {sites?.length > 0 && sites.map((site, i) => {
                    return site.latitude && (<Marker
                        key={site.id}
                        position={{
                            lat: site.latitude,
                            lng: site.longitude
                        }}
                        icon={getColorCircleIcon(site.color || '#ffa726', (i + 1).toString())}
                        title={site.full_address}
                        zIndex={site.selected ? 1000 : 1}
                    />)
                })}
            </GoogleMap>
        }
    />
}

export default CustomerSitesMap;