import React from "react";
import {Col, Nav, NavItem, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import MDSpinner from "react-md-spinner";

const isMobile = window.screen.width <= 1024;

const CustomerContactsList = ({customerContacts, onSelect, billingContact, ready, disabled}) => {
    const editContact = id => {
        onSelect({show: true, id: id})
    }

    const colorType = (contact_type) => {
        if (contact_type) {
            return <div className={`color-contact-type ${contact_type.toLowerCase().replace(/(\s|\/)/g, "-")}-type`}>
                {contact_type}
            </div>
        }
    }

    const customerRow = c => {
        return <div className={`customer-contact ${disabled ? "disabled" : ""}`}>
            <div className="customer-contact-row">
                <div className="customer-contact-name">
                    <div className='vertical-align'>
                        <b>{c.person_name}</b>
                        {colorType(c.contact_type)}
                    </div>
                    <div>
                        <span>{c.person_email}</span>
                    </div>
                </div>
                <div style={{fontWeight: 500}}>
                    {isMobile ?
                        <div onClick={(e)=>{
                            e.stopPropagation()
                            window.open("tel:"+c.person_phone.replace(/\D/g,''),"_self");}}>
                            {c.person_phone}</div>
                        : <>{c.person_phone}</>
                    }
                </div>
            </div>
        </div>
    }

    const sortedCustomerContacts = customerContacts.sort(function(x,y){ return x.contact_type == "Billing" ? -1 : y.contact_type == "Billing" ? 1 : 0; })

    return ready ? <Row>
            <Col xs={12} className="no-padding">
                <div className="customer_list">
                    {sortedCustomerContacts.map(c => {
                        return (
                            <Nav
                                key={c.id}
                                bsStyle="pills"
                                stacked
                                onSelect={c => c !== billingContact?.id && !disabled && editContact(c)}
                            >
                                <NavItem
                                    key={c.id}
                                    eventKey={c.id}
                                >
                                    {billingContact?.id === c.id ?
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip"><strong>For Billing Contact Use Customer Form To
                                                The Left</strong></Tooltip>}
                                        >
                                            <Row className="vertical-align" style={{color: 'rgb(40, 167, 69)'}}>
                                                {customerRow(c)}
                                            </Row>
                                        </OverlayTrigger>
                                        :
                                        <Row className="vertical-align">
                                            {customerRow(c)}
                                        </Row>
                                    }
                                </NavItem>
                            </Nav>
                        )
                    })}
                    {customerContacts.length === 0 && <p className="text-center">No Contact Matches</p>}
                </div>
            </Col>
        </Row>
        :
        <div className="vhCenter full-height">
            <MDSpinner size={70} borderSize={2}/>
        </div>
}

export default CustomerContactsList;