import React, {useEffect, useState} from "react";
import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import {select} from "../../../../common/commonHandlers";
import './CustomerContactsEditModal.scss'
const isMobile = window.screen.width <= 1024;

const CustomerContactsEditModal = ({editedContact, contactTypes, onHide, onSave, customerId, onSuccess, onDelete, communication_preference_options}) => {
    const [contact, setContact] = useState({...editedContact, customer_id: customerId})
    const [submitted, setSubmitted] = useState(false)

    const inputElement = (label, value, require = false, invalid) => {
        return <div>
            <FormGroup bsSize="small">
                <ControlLabel>
                    {label}{require && "*"}
                </ControlLabel>{((isMobile && contact[value]) && (value==="person_phone" || value==="person_phone2")) &&  <a className="ml-4" href={"tel:"+contact[value].replace(/\D/g,'')}
                                                                                                                                   style={{color: "#209f1e"}}><i className="fa fa-phone" aria-hidden="true"/></a>}
                <FormControl
                    placeholder={label}
                    className={`form-control-xs ${(submitted && invalid) ? 'invalid' : ''}`}
                    value={contact && contact[value]}
                    onChange={e => setContact({...contact, [value]: e.target.value})}
                />
            </FormGroup>
        </div>
    }
    const isEmailVaild = (value) => {
        return /^$|^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
    }
    const isPhoneValid = (value) => {
        return /^(?:\+\d{1,3}\s?)?(?:\d{3}|\(\d{3}\))[-.\s]?\d{3}[-.\s]?\d{4}(?:\s(?:x|ext)\s\d+)?$|^$/.test(value)
    }
    const isContactValid = () => {
        return contact &&
            contact?.contact_type_id &&
            contact?.first_name?.trim().length > 0 &&
            isPhoneValid(contact?.person_phone || '') &&
            isPhoneValid(contact?.person_phone2 || '') &&
            isEmailVaild(contact?.person_email || '') &&
            isEmailVaild(contact?.person_email2 || '')
    }

    const saveContact = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if(isContactValid()){
            onSave(contact, () => {
                onHide()
                onSuccess()
            })
        }
    }

    const deleteContact = (e) => {
        e.preventDefault()
        onDelete(contact.id, () => {
            onHide()
            onSuccess()
        })
    }

    return <Modal
        bsSize="large"
        show={true}
        onHide={onHide}
        backdrop="static"
        className="heightAuto fontIOS"
        id='customer-contact-edit-modal'
    >
        <form onSubmit={saveContact}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {contact && contact?.id > 0 ? "Editing" : "New"} Contact
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={6}>
                        <ControlLabel>
                            Contact Type*
                        </ControlLabel>
                        <Select className={`Select ${(submitted && !contact?.contact_type_id) ? 'invalid' : '' }`} classNamePrefix="select"
                                name="contact_type_id"
                                onChange={e => setContact({...contact, contact_type_id: e.value, contact_type: e.label})}
                                placeholder="Contact Type"
                                clearable={false}
                                value={select(contactTypes, contact?.contact_type_id)}
                                options={(!contact?.first_name || !contact?.last_name || !contact?.person_phone) ? contactTypes.filter(ct => ct.label !== 'Billing') : contactTypes}
                        />
                    </Col>
                    {contact?.contact_type?.toLowerCase() === "site contact" && <Col xs={6}>
                        <ControlLabel>
                            Communication preference
                        </ControlLabel>
                        <Select className={`Select`} classNamePrefix="select"
                                name="contact_type_id"
                                onChange={e => setContact({...contact, communication_preference: e.value})}
                                placeholder="Communication preference"
                                clearable={false}
                                value={select(communication_preference_options, contact?.communication_preference)}
                                options={communication_preference_options}
                        />
                    </Col>}
                </Row>
                <Row>
                    <Col xs={6}>
                        {inputElement("First Name", "first_name", true, !(contact?.first_name?.trim().length > 0))}
                    </Col>
                    <Col xs={6}>
                        {inputElement("Last Name", "last_name")}
                    </Col>
                    <Col xs={6}>
                        {inputElement("Phone 1", "person_phone", false, contact.person_phone && !isPhoneValid(contact?.person_phone))}
                    </Col>
                    <Col xs={6}>
                        {inputElement("Phone 2", "person_phone2", false, contact.person_phone2 && !isPhoneValid(contact?.person_phone2))}
                    </Col>
                    <Col xs={6}>
                        {inputElement("Email 1", "person_email", false, contact.person_email && !isEmailVaild(contact.person_email))}
                    </Col>
                    <Col xs={6}>
                        {inputElement("Email 2", "person_email2", false, contact.person_email2 && !isEmailVaild(contact.person_email2))}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className="vertical-align">
                    <Col className="text-left" xs={6}>
                        <Button
                            bsSize="xs"
                            bsStyle="danger"
                            onClick={deleteContact}
                            disabled={contact?.has_sites || !contact?.id > 0}
                        >
                            Delete
                        </Button>
                        <Button
                            bsSize="xs"
                            bsStyle="warning"
                            onClick={onHide}
                        >
                            Cancel
                        </Button>
                    </Col>
                        <Col className="text-right" xs={6}>
                            <Button
                                bsSize="xs"
                                bsStyle="success"
                                type="submit"
                            >
                                Save Contact
                            </Button>
                        </Col>
                </Row>
            </Modal.Footer>
        </form>
    </Modal>
}

export default CustomerContactsEditModal;