import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Form, FormControl, FormGroup, Button} from 'react-bootstrap'

class SearchByProposalNo extends Component {
  searchByProposalNo(e) {
    e.preventDefault()
    this.props.onSearch();//the action will get the search value from the store
  }



  render() {
    const {proposalNo, updateProposalNo} = this.props

    return (
      <Form inline onSubmit={(e) => this.searchByProposalNo(e)}>
      <FormGroup bsSize="small">
        <FormControl
      type="text"
      value={proposalNo}
      placeholder="Proposal No"
      onChange={(e) => updateProposalNo(e.target.value)}
      />
      </FormGroup>
      {' '}
      <Button onClick={(e) => this.searchByProposalNo(e)} type='submit' bsSize="small" bsStyle="success">Search</Button>
      </Form>
    )

  }
}

SearchByProposalNo.propTypes = {
  proposalNo: PropTypes.string.isRequired,
  updateProposalNo: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default SearchByProposalNo
