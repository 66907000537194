import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import ResourceComponent from "./ResourceComponent";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker} from 'react-dates';
import './PromiseUi.css';
import {debounce} from "throttle-debounce";
import {defaultDateFormat} from "../common/commonHandlers";

class DateRangeFilter extends ResourceComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null,
        };
        this.delayedSave = debounce(props.timeDelay ? props.timeDelay : 1000, this.save);
    }

    update = (id, startDate, endDate) => {
        const proposalStartDate = startDate ? startDate : "";
        const proposalEndDate = endDate ? endDate : "";
        this.props.upsertPromise(id ? id : null, proposalStartDate, proposalEndDate)
    };

    getDate = (date, input) => {
        let datearray = input.split("/");
        let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
        let result = null;
        if (input.length === 10 && !date) {
            result = null;
        } else if (input.length > 5) {
            result = parsed;
        } else {
            result = date;
        }

        return result;
    };

    save = () => {
        const {proposalServiceId} = this.props;
        let {startDate, endDate} = this.state;
        if (proposalServiceId) {
            this.update(proposalServiceId, startDate, endDate);
        } else {
            const index = (this.props.index - 1) * 2;
            let start = document.getElementsByClassName('DateInput_input')[0 + index].value;
            let end = document.getElementsByClassName('DateInput_input')[1 + index].value;
            this.update(null, this.getDate(startDate, start), this.getDate(endDate, end));
        }
    };

    componentDidMount() {
        const {startDate, endDate, focusedInput} = this.state;

        const serviceNo = (this.props.serviceNo - 1) * 2

        const waitUntil = require('async-wait-until');
        const timeOfStart = Date.now();

        this.setState({startDate: this.props.promisedStart, endDate: this.props.promisedEnd})

        waitUntil(() => {
            const timePassed = Date.now() - timeOfStart;
            const datePickerElement = document.getElementsByClassName('DateInput_input');

            return (timePassed < 500)
            && datePickerElement.length > 0
                ? true
                : new Error('Async operation failed');
        }, 3000)
            .then((result) => {
                document.getElementsByClassName('DateInput_input')[serviceNo + 0].addEventListener('keydown', (e) => {
                    if (e.keyCode === 9) {
                        if (focusedInput !== 'endDate') {
                            setTimeout(() => {
                                this.setState({focusedInput: "endDate"})
                            }, 50)
                        }
                    }
                    if (e.keyCode === 13) {
                        this.setState({focusedInput: null}, () => {
                            document.getElementsByClassName('DateInput_input')[serviceNo + 0].blur();
                            document.getElementsByClassName('DateRangePicker_picker')[serviceNo + 0].style.display = "none";
                        });
                    }
                });
                document.getElementsByClassName('DateInput_input')[serviceNo + 1].addEventListener('keydown', (e) => {
                    if (e.keyCode === 9) {
                        if (focusedInput !== 'startDate') {
                            setTimeout(() => {
                                this.setState({focusedInput: "startDate"})
                            }, 50)
                        }
                    }
                    if (e.keyCode === 13) {
                        this.setState({focusedInput: null}, () => {
                            document.getElementsByClassName('DateInput_input')[serviceNo + 1].blur();
                            document.getElementsByClassName('DateRangePicker_picker')[serviceNo + 0].style.display = "none";
                        });
                    }
                })
            })
            .catch((error) => {
                console.log('Async operation failed: ', error);
            });
    }

    render() {
        const {startDate, endDate} = this.state;
        const {startPlaceholder, endPlaceholder, customClassName, anchorDirection} = this.props;

        const orientation = window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'

        let startDatePlaceholder;
        let endDatePlaceholder;

        if (startPlaceholder) {
            startDatePlaceholder = startPlaceholder
        } else {
            startDatePlaceholder = "P From"
        }

        if (endPlaceholder) {
            endDatePlaceholder = endPlaceholder
        } else {
            endDatePlaceholder = "P To"
        }


        return (
            <div className={customClassName}>
                <DateRangePicker
                    anchorDirection={anchorDirection}
                    startDate={startDate ? moment.utc(startDate) : ""}
                    startDateId={'P From'}
                    endDate={endDate ? moment.utc(endDate) : ""}
                    endDateId={'P To'}
                    orientation={orientation}
                    showClearDates={true}
                    reopenPickerOnClearDate={true}
                    onDatesChange={({startDate, endDate}) => this.setState({startDate, endDate}, this.delayedSave)}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => {
                        this.setState({focusedInput})
                    }}
                    startDatePlaceholderText={startDatePlaceholder}
                    endDatePlaceholderText={endDatePlaceholder}
                    isOutsideRange={() => {
                        return false
                    }}
                    minimumNights={0}
                    displayFormat={this.props.format || defaultDateFormat}
                />
            </div>
        )
    }
};

DateRangeFilter.propTypes = {
    proposalServiceId: PropTypes.number.isRequired,
    promisedStart: PropTypes.string,
    promisedEnd: PropTypes.string,
    upsertPromise: PropTypes.func.isRequired,
};
export default DateRangeFilter
