import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {addAlert} from "../App/actions";

export const getCompanyInfo = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/company_info`
    };

    apiCall("Getting company info", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getTotalNumber = (category, from, to, byInvoice, callback) => async (dispatch, getState) => {

    const config = {
        url: `/api/quickbooks/get_total_number`,
        params: {category, from, to, byInvoice}
    }
    apiCall('Get total number', config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);

}

export const createImport = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/create_import`,
        method: 'POST',
    }
    apiCall('Create import', config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);

}

export const checkProgress = (importId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/get_import_progress`,
        method: 'GET',
        params: {import_id: importId}
    }
    apiCall('Getting progress', config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);

}


export const synchronizeManyRecords = (category, direction, importId, filters, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_many_records`,
        method: 'PUT',
        data: {category, direction, import_id: importId, filters}
    };

    apiCall("Updating records", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false, errorCallback);
};

export const synchronizeSelectedCustomer = (customers, direction, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_selected_customers`,
        method: 'PUT',
        data: {customers: customers, direction: direction}
    };

    apiCall("Updating records", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false, errorCallback);
};

export const getUsers = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users`
    };

    apiCall("Getting users", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getQuickbooksLogs = (filters, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/get_quickbooks_logs`,
        params: filters
    };

    apiCall("Getting quickbooks logs", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};