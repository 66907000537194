export const typeTable = [
    {value: 1, label: "Liquid"},
    {value: 2, label: "Weight"},
    {value: 3, label: "Length"},
    {value: 4, label: "Powder"},
    {value: 5, label: "Unit"}
]

/*
Base units:
    Liquid: Cups
    Weight: Gram
    Length: Centimeter
    Powder: Cups
 */

export const unitsTable = [
    {
        label: "Liquid",
        options:
            [
                {value: 1, label: "Gallon", group: 1, factor: 16},
                {value: 2, label: "Liters", group: 1, factor: 4.22675},
                {value: 3, label: "Quarts", group: 1, factor: 4},
                {value: 4, label: "Pints", group: 1, factor: 2},
                {value: 5, label: "Cups", group: 1, factor: 1},
                {value: 9, label: "Teaspoons", group: 1, factor: 0.02083},
                {value: 10, label: "Tablespoons", group: 1, factor: 0.0625},
                {value: 6, label: "Pound", group: 2, factor: 0.52356},
                {value: 7, label: "Ounce", group: 2, factor: 0.125},
                {value: 8, label: "Gram", group: 2, factor: 0.004},
            ]
    },
    {
        label: "Weight",
        options:
            [
                {value: 1, label: "Gallon", group: 4, factor: 3785},
                {value: 2, label: "Liters", group: 4, factor: 1000},
                {value: 3, label: "Quarts", group: 4, factor: 946},
                {value: 4, label: "Pints", group: 4, factor: 480},
                {value: 5, label: "Cups", group: 4, factor: 250},
                {value: 9, label: "Teaspoons", group: 4, factor: 5.69},
                {value: 10, label: "Tablespoons", group: 4, factor: 14.175},
                {value: 6, label: "Pound", group: 2, factor: 453.59237},
                {value: 7, label: "Ounce", group: 2, factor: 28.34952},
                {value: 8, label: "Gram", group: 2, factor: 1},
            ]
    },
    {
        label: "Length",
        options:
            [

                {value: 11, label: "Inch", group: 3, factor: 2.54 },
                {value: 12, label: "Foot", group: 3, factor: 30.48},
                {value: 13, label: "Yard", group: 3, factor: 91.44},
                {value: 14, label: "Centimeter", group: 3, factor: 1},
                {value: 15, label: "Meter", group: 3, factor: 100},

            ]
    },
    {
        label: "Powder",
        options:
            [
                {value: 1, label: "Gallon", group: 4, factor: 16},
                {value: 2, label: "Liters", group: 4, factor: 4.22675},
                {value: 3, label: "Quarts", group: 4, factor: 4},
                {value: 4, label: "Pints", group: 4, factor: 2},
                {value: 5, label: "Cups", group: 4, factor: 1},
                {value: 9, label: "Teaspoons", group: 4, factor: 0.02083},
                {value: 10, label: "Tablespoons", group: 4, factor: 0.0625},
                {value: 6, label: "Pound", group: 2, factor: 0.52356},
                {value: 7, label: "Ounce", group: 2, factor: 0.125},
                {value: 8, label: "Gram", group: 2, factor: 0.004},
            ]
    },
    {
        label: "Unit",
        options:
            [
                {value: 23, label: "Piece", group: 5, factor: 1},
                {value: 24, label: "Case", group: 5, factor: 1}
            ]
    }
]