import React, {Component} from "react";
import {connect} from 'react-redux';
import {addLocaleData, IntlProvider} from 'react-intl';
import 'intl';
import 'intl/locale-data/jsonp/en.js';
import {addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import en from '../../translations/en.json';
import esTranslation from '../../translations/es.json';
import es from 'react-intl/locale-data/es';
import {logout} from '../../actions';
import {browserHistory} from "react-router";

const mapStateToProps = (state) => ({
    locale: state.userStatus.userStatus.locale,
    isAuthenticated: state.auth.isAuthenticated,
    userId: state.auth.user_id,
    token: state.auth.token
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

class I18NRedux extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timestamp: null
        };
        addLocaleData([...es]);
    }

    async checkActivity() {
        const {isAuthenticated, userId, token} = this.props;
        if (isAuthenticated) {
            let config = {
                url: `/api/users/${userId}/get_activity`,
                method: 'GET',
                headers: {
                    Authorization: token
                }
            };
            config = addTimeZoneHeader(config);
            apiCall("Checking activity", config, async config => {
                const response = await axios.request(config);
                if (response.data.seconds_ago > 86400) {
                    this.props.logout();
                    browserHistory.push('/login?unauthorized');
                } else {
                    let config = {
                        url: `/api/users/${userId}/refresh_activity`,
                        method: 'PUT',
                        headers: {
                            Authorization: token
                        }
                    };
                    config = addTimeZoneHeader(config);
                    apiCall("Refreshing activity", config, async config => {
                        axios.request(config);
                    }, null, null, false);
                }
            }, null, null, false);
        }
    }

    componentDidMount() {
        this.checkActivity();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isAuthenticated) {
            const timePassed = this.state.timestamp ? Math.floor((Date.now() - this.state.timestamp) / 1000) : null;
            if (!this.state.timestamp || timePassed > 300) {
                this.setState({timestamp: Date.now()}, () => {
                    this.checkActivity();
                });
            }
        }
    }

    render() {
        const messages = {
            'en-US': en,
            es: esTranslation
        };
        return (
            <IntlProvider locale={this.props.locale} messages={messages[this.props.locale]}>
                {this.props.children}
            </IntlProvider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(I18NRedux);
