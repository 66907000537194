import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../../common/upload";

export const getCustomers = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_customers`,
        params: filter
    };

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getCustomersSearch = (searchText, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_customers/search_customers/`,
        params: {q: searchText}
    };

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/customer_types`},
        {url: `/api/plants`},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            customerTypes: mapForSelect(all[1].data),
            plants: mapForSelect(all[2].data),
        });
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const sendBulkEmail = (recipients, email, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/emails/send_bulk_email`,
        method: 'POST',
        data: {email: {...email, recipients}}
    };

    apiCall("Email sending", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const downloadCsv = (params) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_customers/csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `customers-${moment().format()}.csv`);

        blob.name = `customers-${moment().format()}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(params ? params : {}), source: "customer_search"}, false)(dispatch, getState)
    }, dispatch, getState);
};