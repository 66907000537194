import {FETCH_EQUIPMENT_LIST} from '../constants'

import axios from 'axios'

//TODO: DEPRECATED
export const fetchEquipmentList = () => (dispatch, getState) => {
    const store = getState()
    const config = {
        url: '/api/equipments',
        headers: {'Authorization': store.auth.token},
    };

    return axios.request(config)
        .then(response => {
            dispatch({type: FETCH_EQUIPMENT_LIST, equipmentList: response.data, equipmentListLastUpdated: Date.now()});
        });
};
