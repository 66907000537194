import React from "react";
import {Button, Col, Modal} from "react-bootstrap";
import ColorCheckbox from "../../../../Scheduler/ColorCheckbox";

const TimelineFilterModal = ({onHide, customerId, filters, onChange, onSuccess, onSaveFilters}) => {
    return <Modal
        show={true}
        onHide={onHide}
        className={"heightAuto fontIOS"}>
        <Modal.Header>
            <Modal.Title>
                Timeline Filter
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Col xs={4}>
                <ColorCheckbox
                    label={"Show Events"}
                    value={filters.showEvents}
                    onChange={() => onChange("showEvents")}
                />
            </Col>
            <Col xs={4}>
                <ColorCheckbox
                    label={"Show Crew Message"}
                    value={filters.showCrewMessage}
                    onChange={() => onChange("showCrewMessage")}
                />
            </Col>
            <Col xs={4}>
                <ColorCheckbox
                    label={"Show Customer Notes"}
                    value={filters.showNotes}
                    onChange={() => onChange("showNotes")}
                />
            </Col>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle="warning"
                onClick={onHide}
            >
                Cancel
            </Button>
            <Button
                bsStyle="success"
                onClick={() => {
                    onHide()
                    onSuccess(customerId)
                    onSaveFilters()
                }}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

export default TimelineFilterModal;