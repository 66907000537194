import {FETCH_CONSUMABLES_LIST} from '../constants'

import axios from 'axios'

// TODO: DEPRECATED
export const fetchConsumablesList = () => (dispatch, getState) => {
    const store = getState();
    const config = {
        url: '/api/consumables',
        headers: {Authorization: store.auth.token},
    };

    return axios.request(config).then(response => {
        dispatch({
            type: FETCH_CONSUMABLES_LIST,
            consumablesList: response.data,
            consumablesListLastUpdated: Date.now(),
        })
    });
};