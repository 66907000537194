import React from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import {addAlert} from "../App/actions"
import * as MyActions from "./RenewalsApi"
import {CSVLink} from "react-csv"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {Button, Col, ControlLabel, FormGroup, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import PageHeader from "../../components/PageTitle"
import moment from "moment/moment"
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";
import {defaultDateFormat} from "../../common/commonHandlers";

const Actions = {...MyActions, addAlert}

class RenewalPage extends ResourceComponent {

  state = {
    resource: {},
    renewals: []
  }

  componentDidMount() {
      this.fetchOptOuts();
  }

  fetchOptOuts = () => {
      this.props.actions.fetchOptOuts(response => {
          this.setState({optOuts: response})
      })
  }

    render() {
        const {renewals, optOuts} = this.state;
        const {serviceCompletedStart, serviceCompletedEnd, proposalCutoff} = this.state.resource;

        return (
            <Grid fluid>
                <Helmet title="Renewals"/>
                <PageHeader
                    pageName="Renewals"
                    pageDetail={`${renewals ? renewals.length : ""} Previous Customers with No Future Work`}
                />

                <form onSubmit={this.fetchRenewals}>
                    <Row>
                        <Col xs={4}>

                            <FormGroup bsSize="sm">
                                <ControlLabel>Work Completed Between</ControlLabel>
                                <br/>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={serviceCompletedStart ? moment(serviceCompletedStart) : ""}
                                    inputProps={{placeholder: 'Start Date'}}
                                    onChange={this.dateResourceAttr("serviceCompletedStart")}/>
                                {" "}
                                and{" "}
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={serviceCompletedEnd ? moment(serviceCompletedEnd) : ""}
                                    inputProps={{placeholder: 'End Date'}}
                                    onChange={this.dateResourceAttr("serviceCompletedEnd")}/>
                            </FormGroup>

                        </Col>
                        <Col xs={2}>
                            <FormGroup bsSize="sm">
                                <ControlLabel>
                                    Proposed/Not Offered after
                                </ControlLabel>
                                <br/>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={proposalCutoff ? moment(proposalCutoff) : ""}
                                    inputProps={{placeholder: 'Created as of'}}
                                    onChange={this.dateResourceAttr("proposalCutoff")}/>
                            </FormGroup>
                        </Col>

                        <Col xs={2}>
                            {/*jank way of valigning button to bottom*/}
                            <FormGroup bsSize="sm">
                                <ControlLabel>
                                    {" "}&nbsp;
                                </ControlLabel>
                                <br/>
                                <Button bsSize="small" type="submit" bsStyle="success">
                                    Refresh Renewals
                                    List
                                </Button>
                            </FormGroup>

                        </Col>

                        <Col xs={2}>
                            <FormGroup bsSize="sm">
                                <ControlLabel>
                                    {" "}&nbsp;
                                </ControlLabel>
                                <br/>
                                {renewals && renewals.length > 0 && this.props.roles.find(role => role === "admin")
                                    ? <CSVLink
                                        className="btn btn-info btn-sm"
                                        filename={`renewals-${moment().format()}.csv`}
                                        separator={";"}
                                        onClick={(event) => {
                                            fetch(event.target.href)
                                                .then(async response => {
                                                    const blob = await response.blob()
                                                    blob.name = `renewals-${moment().format()}.csv`
                                                    this.props.actions.saveDownloadedFile(blob, this.state.resource, 'renewals')
                                                })
                                        }}
                                        data={optOuts && optOuts.length > 0 && renewals.filter(r => !optOuts.includes(r.id))}
                                    >
                                        Opt-In's CSV
                                    </CSVLink>
                                    : <Button disabled bsSize="small" bsStyle="info">
                                        Fetch Data First
                                    </Button>}
                            </FormGroup>

                        </Col>

                        <Col xs={2}>
                            <p>&nbsp;</p>

                            {renewals && renewals.length > 0 ? this._renderOptOutMenu() : null}

                        </Col>

                    </Row>
                </form>
                <p>&nbsp;</p>

                <Row>
                    <Col xs={12}>
                        {renewals && renewals.length > 0 ? this._renderTable(renewals) : null}
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}/>
                </Row>

            </Grid>
        )
    }

    fetchRenewals = (e = null) => {
        e && e.preventDefault();
        const {resource} = this.state;
        this.props.actions.fetchRenewals(resource, response => {
            this.setState({renewals: response});
        })
    }
    _renderOptOutMenu = () => {
        const {resource} = this.state;
        let all = <span>All</span>
        let optin = <span>Opted-In</span>
        let optout = <span>Opted-Out</span>
        const allLink = (
            <a
                style={{cursor: "pointer"}}
                onClick={() => {
                    let {resource} = this.state;
                    resource.opt = null;
                    this.setState(resource)
                }}
            >
                All
            </a>
        )

        const optinLink = (
            <a
                style={{cursor: "pointer"}}
                onClick={() => {
                    let {resource} = this.state;
                    resource.opt = "optin";
                    this.setState(resource)
                }}
            >
                Opted-In
            </a>
        )

        const optoutLink = (
            <a
                style={{cursor: "pointer"}}
                onClick={() => {
                    let {resource} = this.state;
                    resource.opt = "optout";
                    this.setState(resource)}}
            >

                Opted-Out
            </a>
        )

        switch (resource.opt) {
            case "optin":
                all = allLink
                optout = optoutLink
                break
            case "optout":
                all = allLink
                optin = optinLink
                break
            default:
                optin = optinLink
                optout = optoutLink
        }

        return <span>Show {all} | {optin} | {optout}</span>
    }

    _renderTable = data => {
        let filteredData = null
        const {optOuts} = this.state;
        const {opt} = this.state.resource;
        switch (opt) {
            case "optin":
                filteredData = (data && optOuts && optOuts.length > 0) ? data.filter(d => !optOuts.includes(d.id)) : null
                break
            case "optout":
                filteredData = (data && optOuts && optOuts.length > 0) ? data.filter(d => optOuts.includes(d.id)) : null
                break
            default:
                filteredData = data
        }
        return (
            <BootstrapTable
                data={filteredData}
                striped={true}
                bordered={false}
                hover={true}
            >
                <TableHeaderColumn dataField="id" isKey={true} dataSort={true}>
                    Customer Id
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="name"
                    dataFormat={this._linkFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Name
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="company"
                    dataFormat={this._linkFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Company
                </TableHeaderColumn>

                <TableHeaderColumn dataField="email" dataSort={true}>
                    Email
                </TableHeaderColumn>

                <TableHeaderColumn dataField="arborist" dataSort={true}>
                    Arborist
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="id"
                    dataFormat={this._optOutCheckboxFormatter}
                    dataSort={true}
                >
                    Opted Out?
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }
    _optCustomer = e => {
        const customerId = e.target.value
        const optedOut = e.target.checked
        this.props.actions.optCustomer(optedOut, customerId, this.fetchOptOuts)
    }

    _optOutCheckboxFormatter = (cell, row) => {
        const {optOuts} = this.state
        let checked = false
        if (![null, undefined].includes(optOuts)) {
            if (optOuts && optOuts.length > 0 && optOuts.includes(parseInt(cell, 10))) {
                checked = true
            }
        }

        return (
            <input
                type="checkbox"
                value={cell}
                onChange={this._optCustomer}
                checked={checked}
            />
        )
    }

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
            >
                <LinkContainer to={`/customer/info/${row.id}`}>
                    <MenuItem bsSize="small">Customer Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.id}`}>
                    <MenuItem bsSize="small">Customer Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.id}`}>
                    <MenuItem bsSize="small">Customer Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.id}`}>
                    <MenuItem bsSize="small">Customer Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.id}`}>
                    <MenuItem bsSize="small">Customer Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )
}

function mapStateToProps(state) {
    return {
        roles: state.auth.roles
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalPage)
