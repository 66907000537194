import React, {useEffect, useRef, useState} from 'react'
import {Col} from "react-bootstrap";
import {bindActionCreators} from "redux";
import * as actions from './TextMessagesApi'
import {connect} from "react-redux";
import PageHeader from "../../../components/PageTitle";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import '../Emails/Emails.scss'
import {dateFormatter} from "../../../components/BootStrapTableCellFormatters";
import moment from "moment/moment";
import {defaultDateFormat, updateExternalLinks, saveNewCsvDownload} from "../../../common/commonHandlers";

const isMobile = window.screen.width <= 1024;

const TextMessages = ({actions}) => {
        const [textMessages, setTextMessages] = useState([])
        const [page, setPage] = useState(1)
        const [per_page, setPer_page] = useState(25)
        const [total, setTotal] = useState(0)

        const tableRef = useRef()

        const loadTextMessages = () => actions.getTextMessages(page, per_page, res => {
            setTextMessages(res.text_messages)
            setTotal(res.total_text_messages)
        })

        useEffect(() => {
            loadTextMessages()
        }, [page, per_page])

        return (
            <div id='emailsList'>
                <PageHeader pageName="Text messages" pageDetail={`${total} records`}/>
                <Col md={12} className="remaining vertical-responsive-height no-padding" style={{width: isMobile ? "90vw" : ""}}>
                    <BootstrapTable
                        ref={tableRef}
                        data={textMessages}
                        striped={true}
                        bordered={false}
                        hover={true}
                        search={true}
                        exportCSV={true}
                        pagination={true}
                        csvFileName={() => {
                            const fileName = `text-messages-${moment().format(defaultDateFormat)}.csv`
                            if (tableRef?.current) {
                                actions.saveNewCsvDownload(tableRef.current, fileName, null, "text messages")
                            }
                            return fileName
                        }}
                        options={{
                            onPageChange: (page, per_page) => {
                                setPage(page)
                                setPer_page(per_page)
                            },
                            page,
                            sizePerPageList: [
                                {
                                    text: '10', value: 10
                                },
                                {
                                    text: '25', value: 25
                                },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                },
                                {
                                    text: 'All', value: 200
                                }],
                            sizePerPage: per_page,
                        }}
                    >
                        <TableHeaderColumn hidden isKey dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} search={true}
                                           tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}} dataField="phone_number"
                                           width={isMobile ? '100px' : '10%'}>
                            Recipient Number
                        </TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} search={true}
                                           tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}} dataField="user_email"
                                           width={isMobile ? '100px' : '10%'}>
                            Recipient Mail
                        </TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} search={true}
                                           tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}} dataField="message"
                                           width={isMobile ? '300px' : '40%'}
                                           dataFormat={cell => {
                                               cell = updateExternalLinks(null, cell)
                                               return cell
                                           }}>
                            Message
                        </TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} search={true}
                                           tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}} dataField="created_at"
                                           width={isMobile ? '100px' : '10%'} dataFormat={dateFormatter}
                                           csvFormat={dateFormatter}>
                            Send date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} search={true}
                                           tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}} dataField="user"
                                           width={isMobile ? '150px' : '15%'}>
                            Sender
                        </TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} search={true}
                                           tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}} dataField="client"
                                           width={isMobile ? '150px' : '15%'}>
                            Client
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </div>
        );
    }
;

const mapStateToProps = state => (
    {
        name: state.auth.name,
        client: state.client.customerInfo,
    }
)

const mapDispatchToProps = dispatch => (
    {
        actions: bindActionCreators({...actions, saveNewCsvDownload}, dispatch),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(TextMessages)