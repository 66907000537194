import {
    ADD_BLANK_HISTORY,
    CLEAR_PLANT_DATA,
    EDIT_ASSET_HISTORY,
    FETCH_ASSET_HISTORY,
    FETCH_IMAGES_INFO,
    FETCH_SERVICE_HISTORY,
    REMOVE_BLANK_HISTORY,
    UPDATE_HISTORY_ATTRIBUTE,
} from "./constants"

import {EDITING_ASSET_ID, FETCHED_SITE_ASSETS} from "../MapViewPage/constants"

import {addAlert} from "../App/actions"
import {fetchPlantList} from "../../actions/plants"

import axios from "axios"

import request from "superagent"

import moment from "moment"

import {fetchAssetsForSite} from "../MapViewPage/actions"
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";

export {fetchAssetsForSite}
export {fetchPlantList}

export const getPlants = (callback) => (dispatch, getState) => {
    const config = [
        {url: '/api/plants'},
        {url: '/api/recent_selects?name=plant'}
    ];

    apiCall("Loading plants", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback(
            [
                {label: 'Recently used', options: all[1].data},
                {label: 'All plants', options: mapForSelect(all[0].data)}
            ]);
    }, dispatch, getState, false);
};

export const updateHistoryAttribute = (attribute, value) => (
    dispatch,
    getState
) => {
    console.log(
        "Updating history attribute fo the one being edited",
        attribute,
        value
    )
    const store = getState()
    const assetKey = `plant_stat_history_${store.assetModal.editingAssetHistoryId}` //wow we're really interwined with this reducer. maybe it shoudl be top level
    const assetHistory = store.assetModal.assetHistory // a reference

    //TODO: this should be a 1 liner
    const modifiedAssetHistory = {
        ...assetHistory,
        [assetKey]: {...assetHistory[assetKey], [attribute]: value},
    } //super spread
    //   modifiedAssetHistory[attribute] = value ;//edit the prop
    //   const newAssetHistory = {...assetHistory, [assetKey]: modifiedAssetHistory};//copy the main object and overwirte the old

    dispatch({
        type: UPDATE_HISTORY_ATTRIBUTE,
        assetHistory: modifiedAssetHistory,
    })

    //TODO: update the asset via api or button.or set dirty
}

export const submitAsset = () => (dispatch, getState) => {
    const store = getState()
    const assetIdToSubmit = store.mapView.editingAssetId
    const asset = store.mapView.siteAssets.find(
        a => a.id === parseInt(assetIdToSubmit, 10)
    )

    if (isNaN(parseInt(assetIdToSubmit, 10)) || asset === undefined) {
        return dispatch(
            addAlert({message: "I don't know which asset to submit!"})
        )
    }

    const config = {
        method: assetIdToSubmit === 0 ? "POST" : "PUT",
        url: assetIdToSubmit === 0
            ? "/api/assets"
            : `/api/assets/${assetIdToSubmit}`,
        headers: {Authorization: store.auth.token},
        data: {
            asset_object: {
                site_id: asset.site_id,
                parent_asset_id: asset.parent_asset_id,
                asset_kind: asset.asset_kind,
                label: asset.label,
                note: asset.note,
                plant_count: asset.plant_count,
                latitude: asset.latitude,
                longitude: asset.longitude,
                description: asset.description,
                plant_id: asset.plant_id,
                stem_count: asset.stem_count,
                disabled: asset.disabled,
            },
        },
    }

    return axios
        .request(config)
        .then(response => {
            dispatch(
                addAlert({
                    message: `Plant ${assetIdToSubmit === 0 ? "Added" : "Updated"}!`,
                    mood: "success",
                    dismissAfter: 1500,
                })
            )
            //TODO: these shoudl be chained
            dispatch(fetchAssetsForSite(response.data.site_id)).then(() => {
                const editingAssetId = assetIdToSubmit === 0 ? response.data.id : null
                dispatch({type: EDITING_ASSET_ID, editingAssetId})
            })
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: `Unable to save because: ${error.response.data}`,
                    mood: "danger",
                    dismissAfter: 3500,
                })
            )
        })
}

export const fetchPlantHistory = () => (dispatch, getState) => {
    const store = getState()
    const assetId = store.mapView.editingAssetId
    const config = {
        method: "get",
        url: "/api/plant_stats_histories",
        headers: {Authorization: store.auth.token},
        params: {
            asset_id: assetId,
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_ASSET_HISTORY, assetHistory: response.data})
    })
}

export const fetchPlantImageInfo = () => (dispatch, getState) => {
    const store = getState()
    const assetId = store.mapView.editingAssetId
    const config = {
        method: "get",
        url: "/api/images",
        headers: {Authorization: store.auth.token},
        params: {
            asset_id: assetId,
            no_parent_images: true
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_IMAGES_INFO, imagesInfo: response.data})
    })
}

export const clearPlantData = () => {
    //when you load a 2nd + new plant, the modal opens and while the ajax calls are happening
    //for a split second you're seeing the old data from the previous plant.
    //we'll clear out the previous data and blank out data from these calls
    // fetchPlantHistory()
    //  fetchServiceHistoryForPlant()
    // fetchPlantImageInfo()
    console.log("clearing existing plant data")
    return {type: CLEAR_PLANT_DATA}
}

export const cancelHistoryEdit = () => (dispatch, getState) => {
    console.log("cancelling history edit")
    dispatch({type: EDIT_ASSET_HISTORY, editingAssetHistoryId: null})
    //delete the id of 0 in case they canceled out of creating a new one
    const store = getState()
    let currentAssetHistories = Object.assign({}, store.assetModal.assetHistory) //copy it
    delete currentAssetHistories["plant_stat_history_0"]
    dispatch({type: REMOVE_BLANK_HISTORY, assetHistory: currentAssetHistories})
}

export const submitPlantStatsHistory = () => (dispatch, getState) => {
    const store = getState()
    const plantStatHistory =
        store.assetModal.assetHistory[
            `plant_stat_history_${store.assetModal.editingAssetHistoryId}`
            ]
    const config = {
        method: plantStatHistory.id === 0 ? "post" : "put",
        url: "/api/plant_stats_histories" +
            (plantStatHistory.id === 0 ? "" : `/${plantStatHistory.id}`),
        headers: {Authorization: store.auth.token},
        data: {
            plant_stats_history: plantStatHistory,
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: EDIT_ASSET_HISTORY, editingAssetHistoryId: null})
        dispatch(fetchPlantHistory())
        const message = plantStatHistory.id === 0
            ? "Added Plant History!"
            : "Updated Plant History!"
        dispatch(
            addAlert({message: message, mood: "success", dismissAfter: 1500})
        )
    })
}

export const fetchServiceHistoryForPlant = () => (dispatch, getState) => {
    const store = getState()
    const assetId = store.mapView.editingAssetId
    const config = {
        method: "get",
        url: "/api/proposal_services/asset_service_history",
        headers: {Authorization: store.auth.token},
        params: {
            asset_id: assetId,
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_SERVICE_HISTORY, serviceHistory: response.data})
    })
}

export const editAssetHistory = id => {
    return {type: EDIT_ASSET_HISTORY, editingAssetHistoryId: id}
}

export const addBlankAssetHistory = () => (dispatch, getState) => {
    const store = getState()
    const assetHistories = store.assetModal.assetHistory
    const blankHistory = {
        id: 0,
        asset_id: store.mapView.editingAssetId,
        height: "0",
        width: "0",
        dbh: "0",
        review_date: moment().format(),
        stem_count: "0",
    }

    let assetHistory = {
        ...assetHistories,
        ["plant_stat_history_0"]: blankHistory,
    }
    // assetHistory['plant_stat_history_0'] = blankHistory

    dispatch({type: ADD_BLANK_HISTORY, assetHistory})
    dispatch({type: EDIT_ASSET_HISTORY, editingAssetHistoryId: 0}) //maybe we should do a random number or something
    //inject a blank assetHistory into the list
    //set that id as the one we're currently editing
}

export const deleteAssetImage = id => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "DELETE",
        url: `/api/images/${id}`,
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch(fetchPlantImageInfo())
        dispatch(
            addAlert({
                message: "Photo Deleted!",
                mood: "success",
                dismissAfter: 1500,
            })
        )
    })
}

export const uploadAssetImage = acceptedFiles => (dispatch, getState) => {
    dispatch(
        addAlert({
            message: "Uploading. Please wait for the photo to appear...",
            mood: "warning",
            dismissAfter: 3000,
        })
    )
    const store = getState()
    const assetId = store.mapView.editingAssetId

    console.log("In upload Asset Image and baseUrl = ", store.auth.baseUrl)

    let req = request
        .post(`${store.auth.baseUrl}/api/images?asset_object_id=${assetId}`)
        .set("Authorization", store.auth.token)

    req.attach("tree", acceptedFiles[0])

    req.then(
        response => {
            dispatch(fetchPlantImageInfo())
            dispatch(
                addAlert({
                    message: "Photo(s) Added!",
                    mood: "success",
                    dismissAfter: 1500,
                })
            )
        },
        error => {
            dispatch(addAlert({ message: error.toString() }))
            console.error("Image upload failed", error)
        }
    )
}

export const deleteAsset = (asset_id) => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "DELETE",
        url: `/api/assets/${asset_id}`,
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        /* Maybe we need to put this in a method */
        const config = {
            method: "get",
            url: `/api/assets`,
            headers: {Authorization: store.auth.token},
            params: {
                site_id: store.mapView.proposal.siteId,
            },
        }
        return axios.request(config).then(response => {
            dispatch({type: FETCHED_SITE_ASSETS, siteAssets: response.data})
            dispatch(
                addAlert({
                    message: "Asset Deleted!",
                    mood: "warning",
                    dismissAfter: 1500,
                })
            )
            return response
        })

    })
}

export const loadHazards = (assetId, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/asset_hazards?asset_id=${assetId}`},
        {url: `/api/hazards`}
    ];

    apiCall("Loading job site analysis", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            assetHazards: all[0].data,
            hazards: mapForSelect(all[1].data)
        });
    }, dispatch, getState, false);
};