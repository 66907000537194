import { FETCH_WORK_ORDER, WORK_ORDER_SEARCH_TEXT } from "../constants"
import { addAlert } from "../containers/App/actions"
import { fetchSiteMaps } from "./siteMaps"
import axios from "axios"
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../common/apiActionHandler";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../common/upload";
import {defaultDateFormat} from "../common/commonHandlers";

export { fetchSiteMaps }
export const updateWorkOrderSearchText = workOrderSearchText => {
  return { type: WORK_ORDER_SEARCH_TEXT, workOrderSearchText }
}

export const findWorkOrder = () => (dispatch, getState) => {
  //Loads a wo for the print page
  const store = getState()
  const q = store.workOrders.workOrderSearchText

  if (q === undefined || q === null || q.length < 1) {
    return dispatch(
      addAlert({
        message: "Please enter a work order number to search for.",
        mood: "warning",
        dismissAfter: 3000,
      })
    )
  }

  const config = {
    url: "/api/work_orders/search",
    params: { q: q },
    headers: { Authorization: store.auth.token },
  }

  return axios
    .request(config)
    .then(response => {
      dispatch({ type: FETCH_WORK_ORDER, workOrders: response.data })
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        dispatch(
          addAlert({
            message: `Work Order Number ${q} was not found.`,
            mood: "warning",
            dismissAfter: 3000,
          })
        )
      }else{
        console.error(error)
      }
    })
}

export const getWorkOrder = (workOrderNumber, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_order_print/${workOrderNumber}`
    };

    apiCall("Getting work order", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getWorkOrders = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/work_order_print/work_orders_print_all`,
        params: filter
    };

    apiCall("Getting work orders", config, async config => {
        const response = await axios.request(config);
        callback(response.data.workOrders);
    }, dispatch, getState, false);
};


export const saveLogoSettings = (client_id, height, position) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/change_logo_settings`,
        method: 'PUT',
        data: {
            client_id: client_id,
            logo_height: height,
            logo_position: position,
        }
    };

    apiCall("Saving logo settings", config, async config => {
        await axios.request(config);
    }, dispatch, getState);
};

export const downloadPdf = (work_order, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${work_order.wo_token}/wo_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${work_order.client_name}-WorkOrder #${work_order.work_order_no}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(work_order ? work_order : {}), source: "work order pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const downloadAllPdf = (filters, employee, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/wo_all_pdf`,
        method: 'GET',
        responseType: 'blob',
        params: filters
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${moment(filters.scheduler_event_date_from).format(defaultDateFormat)}-WorkOrder #${employee}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(filters ? filters : {}), source: "work order all pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const updateWorkOrderPrintOptions = (woId, value, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'PUT',
        url: `/api/work_order_print/${woId}/print_options`,
        headers: {Authorization: token},
        params: {
            value
        },
    };

    try {
        const response = await axios.request(config);
        callback  && callback(response)
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update work order print option" + exception.toString(),
            })
        );
        throw exception
    }
};

export const updateAllWorkOrderPrintOptions = (filter, value, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    let config = {
        method: 'PUT',
        url: "/api/work_order_print/print_options_all",
        headers: {Authorization: token},
        params: {value, scheduler_event_date_from: filter.scheduler_event_date_from, person_id: filter.person_id},
    };
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        callback  && callback(response)
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update work order print option" + exception.toString(),
            })
        );
        throw exception
    }
};

export const updateUserWorkOrderPrintOptions = (userId, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${userId}/work_order_print_options`,
        data: {
            work_order_print_options: value
        },
        method: 'PUT'
    };

    apiCall("Make print options default", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const resetWorkOrderPrintOptions = (woId, value) => (dispatch, getState) => {
    const config = {
        url: `/api/work_order_print/${woId}/reset_print_options`,
        data: {
            reset_print_options: value
        },
        method: 'PUT'
    };

    apiCall("Reset print options to default", config, async config => {
        const result = await axios.request(config);
    }, dispatch, getState);
};

export const fetchUser = (userId, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'GET',
        url: `/api/users/${userId}`,
        headers: {Authorization: token},
    };

    try {
        const response = await axios.request(config);
        callback(response.data);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the user " + exception.toString(),
            })
        );
        throw exception
    }
};

export const fetchCustomerInfo = (callback) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/clients/${store.auth.client_id}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        callback(response.data)
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the client info " + exception.toString(),
            })
        );
        throw exception
    }
}