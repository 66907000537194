//global constants used in > 1 places in the app.
//most likely there will be another identical constant defined causing unexpected reducers to fire

export const UPDATE_PRIORITY_ATTR = 'UPDATE_PRIORITY_ATTR'
export const FETCH_PRIORITY = 'FETCH_PRIORITY'
export const EDIT_PRIORITY_FILTER_TEXT = 'EDIT_PRIORITY_FILTER_TEXT'
export const EDITING_PRIORITY_ID = 'EDITING_PRIORITY_ID'
export const DELETE_PRIORITY = 'DELETE_PRIORITY'
export const ADD_NEW_PRIORITY = 'ADD_NEW_PRIORITY'
export const FETCH_PLANT_LIST = 'FETCH_PLANT_LIST'
export const FETCH_FACTORS_LIST = 'FETCH_FACTORS_LIST'
export const FETCH_FACTOR_TYPES_LIST = 'FETCH_FACTOR_TYPES_LIST'
export const FETCH_EQUIPMENT_LIST = 'FETCH_EQUIPMENT_LIST'
export const FETCH_CHECKLIST_MODAL = 'FETCH_CHECKLIST_MODAL'
export const FETCH_APPLICATION_TYPE_LIST = 'FETCH_APPLICATION_TYPE_LIST'
export const FETCH_APPLICATION_SITE_LIST = 'FETCH_APPLICATION_SITE_LIST'
export const FETCH_CONSUMABLES_LIST = 'FETCH_CONSUMABLES_LIST'
export const FETCH_CONTACT_TYPES_LIST = 'FETCH_CONTACT_TYPES_LIST'
export const FETCH_PRIORITIES_LIST = 'FETCH_PRIORITIES_LIST'
export const FETCH_REFERALLS_LIST = 'FETCH_REFERALLS_LIST'
export const FETCH_SERVICE_TYPES_LIST = 'FETCH_SERVICE_TYPES_LIST'
export const FETCH_CUSTOMER_TYPES_LIST = 'FETCH_CUSTOMER_TYPES_LIST'
export const FETCH_TRUCKS_LIST = 'FETCH_TRUCKS_LIST'
export const FETCH_WORK_ORDER = 'FETCH_WORK_ORDER'
export const WORK_ORDER_SEARCH_TEXT = 'WORK_ORDER_SEARCH_TEXT'
export const UPDATE_PROPOSAL_SEARCH_TEXT = 'UPDATE_PROPOSAL_SEARCH_TEXT'
export const PRINT_WO = 'PRINT_WO'
export const ADD_TO_WO = 'ADD_TO_WO'
export const SELECT_PROPOSAL_SERVICE = 'SELECT_PROPOSAL_SERVICE'
export const UN_SELECT_PROPOSAL_SERVICE = 'UN_SELECT_PROPOSAL_SERVICE'
export const REMOVE_SELECT_PROPOSAL_SERVICE = 'REMOVE_SELECT_PROPOSAL_SERVICE'
export const REMOVE_PS_FROM_WO = 'REMOVE_PS_FROM_WO'
export const FETCH_PROPOSAL_INFO = 'FETCH_PROPOSAL_INFO'
export const FETCH_PS_INFO = 'FETCH_PS_INFO'
export const UPDATE_PS_INFO_ATTR = 'UPDATE_PS_INFO_ATTR'
export const PS_TO_WO_MOD_SUCCESS = 'PS_TO_WO_MOD_SUCCESS'
export const FETCH_SALES_ARBORISTS = 'FETCH_SALES_ARBORISTS'
export const FETCH_SERVICE_LIST = 'FETCH_SERVICE_LIST'
export const FETCH_SERVICE = 'FETCH_SERVICE'
export const UPDATE_SERVICE_ATTR = 'UPDATE_SERVICE_ATTR'
export const ADD_NEW_SERVICE = 'ADD_NEW_SERVICE'
export const DELETE_SERVICE = 'DELETE_SERVICE'
export const FETCH_EMPLOYEE_LIST = 'FETCH_EMPLOYEE_LIST'
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_ATTR = 'UPDATE_EMPLOYEE_ATTR'
export const ADD_NEW_EMPLOYEE = 'ADD_NEW_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const EDITING_EMPLOYEE_ID = 'EDITING_EMPLOYEE_ID'
export const FETCH_CUSTOMER_SITE_MAPS = 'FETCH_CUSTOMER_SITE_MAPS'
export const CREATE_CUSTOMER_SITE_MAP = 'CREATE_CUSTOMER_SITE_MAP'
export const UPDATE_CUSTOMER_SITE_MAP = 'UPDATE_CUSTOMER_SITE_MAP'
export const DELETE_CUSTOMER_SITE_MAP = 'DELETE_CUSTOMER_SITE_MAP'

export const ROLES = {
    superAdmin: "it_engineering",
    admin: "admin",
    crew: "crew",
    subcontractor: "subcontractor",
    scheduler: "scheduler",
    sales_arborist: "sales_arborist",
    user: "user"
}
export const STRIPE_PAGES = {
    subscriptionRenew: "subscription_renew",
    subscriptionExpired: "subscription_expired",
    subscriptionReminder: "subscription_reminder"
}

export const UPLOAD_FORMAT_TIME_ONLY = "TIME_ONLY"
export const UPLOAD_FORMAT_DATE_ONLY = "DATE_ONLY"
export const UPLOAD_FORMAT_FULL = "FULL_ONLY"

export const JODIT_BUTTONS = {
    basic: {
        buttonsXS: ['bold', 'italic', 'underline', '|',
            'ul', 'ol', '|'],
        buttonsSM: ['bold', 'italic', 'underline', '|',
            'ul', 'ol', '|'],
        buttonsMD: ['bold', 'italic', 'underline', '|',
            'ul', 'ol', '|'],
        buttons: ['bold', 'italic', 'underline', '|',
            'ul', 'ol', '|'],
        popupButtons: ['bold', 'italic', 'underline', '|',
            'ul', 'ol', '|']
    },
    advanced: {
        popupButtons: ['bold', 'italic', 'underline', '|',
            'ul', 'ol', 'eraser', '|',
            'fontsize', 'brush', 'paragraph', '---', 'image', '\n',
            'link', '|', 'align', '|', 'undo', 'redo', '|', 'copyformat',
            'fullsize']
    }
}