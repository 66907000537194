import React, {Component} from 'react';
import PropTypes from "prop-types"
import './Attachment.css'
import {Glyphicon} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faRedo, faUndo} from "@fortawesome/free-solid-svg-icons";

const isMobile = window.screen.width < 768;

class Attachment extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            hover: false,
            longTouchTimeout: null
        }
    }

    toggleHover = (hover) => {
        !isMobile && this.setState({hover: hover})
    }
    handleTouchStart = () => {
        this.setState({
            longTouchTimeout: setTimeout(() => {
                this.props.setAsMain && this.props.setAsMain(this.props.image.id)
                this.props.setAsLogo && this.props.setAsLogo(this.props.image.id)
            }, 500)
        })
    }
    handleTouchEnd = () => {
        clearTimeout(this.state.longTouchTimeout)
    }

    renderRotatingImg = (i, direction) => <a className={`small rotate-attachement-icon ${direction}`}
                                             onClick={() => this.props.rotateImage(i.id, direction, () => {
                                                 this.props.reloadData();
                                             })}
                                             style={{display: this.state.hover ? "" : "none"}}
    >
        <FontAwesomeIcon icon={direction === 'left' ? faUndo : faRedo}/>
    </a>

    render() {
        const i = this.props.image;
        const showName = this.props.showName
        const {lockedCrewEditAccess, isIssueModal, allowRemove, downloadButton, downloadResource} = this.props;

        const AttachmentName = (file) => {
            return <a href={file.original} target="_blank" title="Open in a new window" className="attachment-filename">
        <span className="attachment_name" target="_blank">
            {file?.created_at}
            {file?.name?.substring(0, 20)}
            {file?.name?.length > 20 && '...'}
        </span>
            </a>
        }

        return (
            <div onMouseEnter={() => this.toggleHover(true)}
                 onMouseLeave={() => this.toggleHover(false)}
                 onTouchStart={this.handleTouchStart}
                 onTouchEnd={this.handleTouchEnd}
                 key={i.id} className={`attachment-preview ${isIssueModal && 'issue-image-attachment'}`}>
                {this.props.disableRedirect ?
                    <div>
                        <img
                            style={{boxShadow: i.client_header && ' rgba(0, 0, 0, 0.36) 0px 30px 30px -2px'}}
                            role="presentation"
                            src={i.mini}
                            className="img-thumbnail disable-drag"
                        />
                    </div>
                    :
                    <a
                        href={i.original}
                        title="Open in a new window"
                        target="_blank"
                    >
                        <img
                            style={{boxShadow: (i.client_header || i.client_logo_selected) && ' rgba(0, 0, 0, 0.36) 0px 30px 30px -2px'}}
                            role="presentation"
                            src={i.mini}
                            className="img-thumbnail"
                        />
                    </a>}
                {showName ? AttachmentName(i) : null}
                {!lockedCrewEditAccess && this.props.deleteImage &&
                <a
                    className="remove-attachement-icon"
                    title="Delete this image"
                    onClick={() => this.props.deleteImage(i.id)}
                >
                    {allowRemove && <span className="text-danger">
                      <Glyphicon glyph="remove-sign" className="pointer"/>
                    </span>}
                </a>
                }
                {downloadButton && <a title={'Download'} className='download-attachement-icon'
                                      onClick={() => downloadResource(i.original, i.name)}>
                    <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                </a>}
                {!lockedCrewEditAccess && this.props.rotateImage &&
                <div className="mt-4">
                    {this.renderRotatingImg(i, 'right')}
                    {this.renderRotatingImg(i, 'left')}
                </div>
                }
                <div>
                    {this.props.setAsMain && isMobile && <a
                        className="change-header-icon"
                        title="Set as header"
                        onClick={() => this.props.setAsMain(i.id)}
                    >
                        <span className={`text-primary ${i.client_header || i.primary ? "" : "text-gray"}`}>
                            <Glyphicon glyph="heart" className="pointer"/>
                        </span>
                    </a>
                    }
                    {this.props.setAsMain && !isMobile && <a
                        className="change-header-icon"
                        title="Set as header"
                        onClick={() => this.props.setAsMain(i.id)}
                        style={{display: this.state.hover || i.client_header || i.primary ? "" : "none"}}
                    >
                        <span className="text-primary">
                            <Glyphicon glyph="heart" className="pointer"/>
                        </span>
                    </a>
                    }
                    {this.props.setAsLogo && isMobile && <a
                        className="change-header-icon"
                        title="Set as logo"
                        onClick={() => this.props.setAsLogo(i.id)}
                    >
                        <span className={`text-primary ${i.client_logo_selected || i.primary ? "" : "text-gray"}`}>
                            <Glyphicon glyph="heart" className="pointer"/>
                        </span>
                    </a>
                    }
                    {this.props.setAsLogo && !isMobile && <a
                        className="change-header-icon"
                        title="Set as logo"
                        onClick={() => this.props.setAsLogo(i.id)}
                        style={{display: this.state.hover || i.client_logo_selected || i.primary ? "" : "none"}}
                    >
                        <span className="text-primary">
                            <Glyphicon glyph="heart" className="pointer"/>
                        </span>
                    </a>
                    }
                    {!this.props.disableNBSP && <>&nbsp;</>}
                </div>
            </div>
        );
    }
}

Attachment.propTypes = {
    deleteImage: PropTypes.func,
    rotateImage: PropTypes.func,
    reloadData: PropTypes.func,
    disableNBSP: PropTypes.bool,
    disableRedirect: PropTypes.bool,
    image: PropTypes.object.isRequired
};

Attachment.defaultProps = {
    disableRedirect: false
    //myProp: <defaultValue>
};

export default Attachment;
