import React from "react";
import moment from "moment-timezone";
import {defaultDateTimeFormatComma} from "../../../../../common/commonHandlers";

const TimelineAxisDate = ({date}) => {
    return <div className="timeline-axis-date well">
        {moment(date).fromNow()} ({moment(date).format(defaultDateTimeFormatComma)})
    </div>
}

export default TimelineAxisDate;