import React from 'react'
import PropTypes from "prop-types"
import { FormattedDate } from 'react-intl'
import moment from "moment";
import {defaultDateFormat} from "../common/commonHandlers";

const ThDate = ({ dateString }) => {
  if (dateString === undefined || dateString === '' || dateString === null) {
    return <span>--</span>
  }
  return moment(dateString).format(defaultDateFormat)
}

ThDate.propTypes = {
  dateString: PropTypes.string,
}

export default ThDate
