import {
    FETCH_APPLICATION_SITE_LIST
} from '../constants'

import axios from 'axios'

//TODO: DEPRECATED
export const fetchApplicationSitesList = () => (dispatch, getState) => {
    const store = getState();
    const config = {
        url: '/api/application_sites',
        headers: {'Authorization': store.auth.token},
    };

    return axios.request(config)
        .then(response => {
            dispatch({
                type: FETCH_APPLICATION_SITE_LIST,
                applicationSitesList: response.data,
                applicationSitesListLastUpdated: Date.now()
            });
        });
};
