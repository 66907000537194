import {
  UPDATE_CUSTOMER_SEARCH,
  FETCHED_CUSTOMER_SEARCH,
  FETCHED_CUSTOMER_SITES,
  UPDATE_SALES_ARBORIST_SEARCH,
  SELECT_CUSTOMER,
  UPDATE_SITE_SEARCH,
  FETCHED_SITE_ASSETS,
  UPDATE_ASSET,
  UPDATE_PROPOSAL,
  RESET_MAP_VIEW,
  FETCH_PROPOSAL_SERVICES,
  FETCH_PS_EQUIPMENT,
  FETCH_SERVICES,
  FETCH_PRIORITIES,
  EDITING_ASSET_ID,
  UPDATE_PROPOSAL_SERVICE,
  DELETE_PROPOSAL_SERVICE,
  FETCH_DBHS,
  ADD_NEW_PS,
  FETCH_SITE_INFO,
  ADD_NEW_ASSET,
  CLEAR_BLANK_ASSET,
  UPDATE_EXPAND_SERVICE,
  UPDATE_HAVE_OPEN_SERVICE,
  TOGGLE_FULL_SCREEN,
} from "./constants"
import {PROPOSAL_SEARCH_SUCCESS} from "../SalesDashboardPage/constants"

const initialState = {
  salesArboristSearch: "",
  customerSearch: "",
  selectedCustomer: {},
  customerSearchList: [],
  fullScreen: false,
  customerSiteSearch: "",
  customerSites: [],

  sites: {},
  siteAssets: [],

  equipments: [],
  services: [],
  priorities: [],
  proposal: {},

  proposalServices: [],

  editingAssetId: null,
  siteAssetsDbhs: {},

  haveOpenService: {},
}

//we're initializing some things so that the UI doesnt balk
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SALES_ARBORIST_SEARCH:
      return { ...state, salesArboristSearch: action.salesArboristSearch }

    case FETCHED_CUSTOMER_SEARCH:
      return { ...state, customerSearchList: action.customerSearchList }
    case UPDATE_CUSTOMER_SEARCH:
      return { ...state, customerSearch: action.customerSearch }
    case SELECT_CUSTOMER:
      return { ...state, selectedCustomer: action.selectedCustomer }

    case UPDATE_HAVE_OPEN_SERVICE:
      return {
        ...state,
        haveOpenService: {
          ...state.haveOpenService,
          [action.serviceId]: !state.haveOpenService[action.serviceId] || false
        }
      }
    case FETCHED_CUSTOMER_SITES:
      return { ...state, customerSites: action.customerSites }

    case UPDATE_SITE_SEARCH:
      return { ...state, customerSiteSearch: action.customerSiteSearch }

    case FETCHED_SITE_ASSETS:
    case ADD_NEW_ASSET:
    case CLEAR_BLANK_ASSET:
    case UPDATE_ASSET:
      return { ...state, siteAssets: action.siteAssets }

    case UPDATE_PROPOSAL:
      return { ...state, proposal: action.proposal }

    case FETCH_PROPOSAL_SERVICES:
    case UPDATE_PROPOSAL_SERVICE:
    case ADD_NEW_PS:
      return { ...state, proposalServices: action.proposalServices }
    case DELETE_PROPOSAL_SERVICE:
      return {
        ...state,
        proposalServices: state.proposalServices.filter(
          ps => ps.id !== action.psId
        ),
      } //optimistic until api returns
    case RESET_MAP_VIEW:
      return {
        ...initialState,
        proposal: action.proposal,
        equipments: state.equipments,
        services: state.services,
        priorities: state.priorities,
        salesArborists: state.salesArborists,
      }
    case FETCH_PS_EQUIPMENT:
      return { ...state, equipments: action.equipments }
    case FETCH_SERVICES:
      return { ...state, services: action.services }
    case FETCH_PRIORITIES:
      return { ...state, priorities: action.priorities }

    case EDITING_ASSET_ID:
      return { ...state, editingAssetId: action.editingAssetId }
    case FETCH_DBHS:
      return { ...state, siteAssetsDbhs: action.siteAssetsDbhs }
    case FETCH_SITE_INFO:
      return { ...state, sites: action.sites }
    case PROPOSAL_SEARCH_SUCCESS:
      return {...initialState}
    case TOGGLE_FULL_SCREEN:
      return {...state, fullScreen: action.fullScreen}
    default:
      return state
  }
}
