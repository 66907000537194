import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import createApiService from "../../../common/clientActionsBuilder";
import {Button, Col, ControlLabel, Form, FormControl, FormGroup, Grid, Modal, Panel, Row} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import * as ReportDocumentsApi from "./ReportDocumentsApi";
import Dropzone from "react-dropzone";
import AttachmentThumbnail from "../../../components/ReorderingDragAndDrop/AttachmentThumbnail/AttachmentThumbnail";
import Select from "react-select";
import {select} from "../../../common/commonHandlers";
import TA_TEMPLATE from './ReportDocumentTemplates/TA_TEMPLATE.docx'
import TCR_TEMPLATE from './ReportDocumentTemplates/TCR_TEMPLATE.docx'
import TRA_TEMPLATE from './ReportDocumentTemplates/TRA_TEMPLATE.docx'
import TPP_TEMPLATE from './ReportDocumentTemplates/TPP_TEMPLATE.docx'

const api = createApiService('clients', 'client', 'Client');
const actions = {...api, ...ReportDocumentsApi};
const reportTypes = [{value: 'TRA', label: 'Tree Risk Assessment'},
    {value: 'TPP', label: 'Tree Protection Plan'},
    {value: 'TCR', label: 'Tree Condition Report'},
    {value: 'TA', label: 'Tree Appraisal'},
]
class ReportDocuments extends ResourceComponent {
    state = {
        reportTemplates: [],
        showTypeSelectModal: false,
        files: {},
        reportType: null,
        error: null
    };

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.props.actions.getReportDocuments(this.props.clientId, res => {
            this.setState({reportTemplates: res.data.report_templates})
        })
    };

    uploadAttachment = () => {
        let {files} = this.state
        const myNewFile = new File([files[0]], `${this.state.reportType}_TEMPLATE.docx`, {type: files[0].type});
        this.props.actions.uploadClientAttachment(this.props.clientId, myNewFile, () => {
            this.setState({showTypeSelectModal: false})
            this.reload()
        })
    }

    deleteAttachment = (id) => {
        this.props.actions.deleteClientAttachment(id, () => {
            this.reload()
        })
    }

    render() {
        const {reportTemplates} = this.state;
        return (<>
                <Grid fluid className="top25">
                    <Row className='no-margin'>
                        <Panel>
                            <p>Report Templates</p>
                            <Col md={3}>
                                <Dropzone
                                    onDrop={files => {
                                        if (files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                                            this.setState({showTypeSelectModal: true, files: files, error: null})
                                        } else {
                                            this.setState({error: 'Please upload file in .docx format.'})
                                        }
                                    }}>
                                    {this.state.error ? <p className='text-danger padding4'>
                                        {this.state.error}
                                    </p> : <p className='padding4'>Drop a file here or click to select a file to upload.</p>}
                                </Dropzone>
                            </Col>
                            <Col md={9} className='d-flex'>
                                {!reportTemplates || reportTemplates.length === 0 ? "No templates found. Please upload some." : ""}
                                {reportTemplates && reportTemplates.map(doc => {
                                    return <AttachmentThumbnail
                                        key={doc.id}
                                        file={doc}
                                        deleteAttachment={this.deleteAttachment}
                                    />

                                })}
                            </Col>
                        </Panel>
                    </Row>
                    <Row className='no-margin'>
                        <Panel>
                            <Col md={4} className='d-flex columnDirection'>
                                <h4>Templates:</h4>
                                <div className='d-flex'>
                                    <a href={TA_TEMPLATE} download="TA_TEMPLATE.docx">
                                        <AttachmentThumbnail
                                            file={{name: "TA_TEMPLATE.docx"}}
                                            allowRemove={false}
                                        />
                                    </a>
                                    <a href={TCR_TEMPLATE} download="TCR_TEMPLATE.docx">
                                        <AttachmentThumbnail
                                            file={{name: "TCR_TEMPLATE.docx"}}
                                            allowRemove={false}
                                        />
                                    </a>
                                    <a href={TRA_TEMPLATE} download="TRA_TEMPLATE.docx">
                                        <AttachmentThumbnail
                                            file={{name: "TRA_TEMPLATE.docx"}}
                                            allowRemove={false}
                                        />
                                    </a>
                                    <a href={TPP_TEMPLATE} download="TPP_TEMPLATE.docx">
                                        <AttachmentThumbnail
                                            file={{name: "TPP_TEMPLATE.docx"}}
                                            allowRemove={false}
                                        />
                                    </a>
                                </div>
                            </Col>
                            <Col md={8}>
                                <Col md={4} className='d-flex columnDirection'>
                                    <h4>Content variables:</h4>
                                    <p><b>LEAD_DATE</b> - Lead date</p>
                                    <p><b>DATE</b> - Current date</p>
                                    <p><b>CLIENT_NAME</b> - Customer name / Customer name with company name</p>
                                    <p><b>CLIENT_LINE_1</b> - Customer name only</p>
                                    <p><b>CLIENT_LINE_2</b> - Customer company name if exist</p>
                                    <p><b>SITE_FULL_ADDRESS</b> - Full name of site</p>
                                    <p><b>SITE_ADDRESS_1</b> - Site address first line</p>
                                    <p><b>SITE_ADDRESS_2</b> - Site address second line</p>
                                    <p><b>ARBORIST_NAME</b> - Name of user that generates report</p>
                                    <p><b>ASSIGNED_ARBORIST</b> - Assigned arborist name</p>
                                    <p><b>PRONOUN_1</b> - First form of personal pronoun</p>
                                    <p><b>PRONOUN_2</b> - Second form of personal pronoun</p>
                                    <p><b>PRONOUN_3</b> - Third form of personal pronoun</p>
                                    <p><b><i>SELECTED_TREES_LIST</i></b> - Generates DBH and names of selected assets (it must be Italic to work correctly)</p>
                                    <p><b>SELECTED_TREES_NUMBERS</b> - Generates # of selected assets</p>
                                    <p><b>ORDINANCE_VARIABLE</b> - Generates all ordinance data</p>
                                    <p><b>TREE_REMOVAL_GUIDELINES</b> - Generates Tree Removal Guidelines</p>
                                    <p><b>TREE_PRUNING_GUIDELINES</b> - Generates Tree Pruning Guidelines</p>
                                    <p><b>TREE_PRESERVATION_GUIDELINES</b> - Tree Preservation Guidelines</p>
                                    <p><b>FACTORS_DESCRIPTIONS</b> - Generates description of assets factors</p>
                                    <p><b>IS_ARE_VARIABLE</b> - Depend of selected number of asset returns is/are</p>
                                    <p><b>TREE_VARIABLE</b> - Depend of selected number of asset returns tree/trees</p>
                                    <p><b>TREE_CAPITALIZE_VARIABLE</b> - Depend of selected number of asset returns Tree/Trees</p>
                                    <p><b>S_VAR</b> - If selected multiple assets returns "s" or disappear</p>
                                    <p><b>S_VAR1</b> - If selected single asset returns "s" otherwise disappear</p>
                                    <p><b>S_VAR2</b> - If selected single asset returns "s'" if more "'s"</p>
                                    <p><b>A_VAR</b> - If selected single asset returns " a " otherwise disappear eg. textA_VARtext</p>
                                    <p><b>ES_VAR</b> - Depend of selected number of asset returns "es" or disappear</p>
                                    <p><b>QUANTITY_VAR</b> - Depend of selected number of asset returns 1: "a", 2: "two", 3 to 9: "several", 10 or more: "multiple"</p>
                                    <p><b>GRAND_PROTECT_VAR</b> - Depend of selected assets DBH returns: "Protected" if all less than 24", "Grand" if all greater or equal than 24", "Protected and Grand" in other cases</p>
                                    <p><b>ASSETS_HAZARDS_NAMES</b> - Generates asset hazard names</p>
                                </Col>
                                <Col md={4} className='d-flex columnDirection'>
                                    <h4>Header variables:</h4>
                                    <p><b>DATE</b> - Current date</p>
                                    <p><b>ARBORIST_NAME</b> - Name of user that generates report</p>
                                </Col>
                                <Col md={4} className='d-flex columnDirection'>
                                    <h4>Footer variables:</h4>
                                    <p><b>SITE_ADDRESS_1</b> - Site address first line</p>
                                </Col>
                            </Col>
                        </Panel>
                    </Row>
                </Grid>
                {this.state.showTypeSelectModal && <Modal
                    bsSize="small"
                    animation={false}
                    show={true}
                    className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                    onHide={() => this.setState({showTypeSelectModal: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Template Type
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <b>Report Type</b>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(reportTypes, this.state.reportType)}
                                    options={reportTypes}
                                    onChange={(e) => this.setState({reportType: e?.value})}
                                    placeholder="Report Type"
                                    isClearable
                                    required
                            />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col className="text-left" xs={6}>
                                <Button
                                    bsSize="small"
                                    bsStyle="warning"
                                    onClick={() => this.setState({showTypeSelectModal: false})}
                                >
                                    Cancel
                                </Button>
                            </Col>

                            <Col className="text-right" xs={6}>
                                <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    disabled={!this.state.reportType}
                                    onClick={() => this.uploadAttachment(this.state.files)}
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>}
            </>
        );
    }
}

ReportDocuments.propTypes = {
    id: PropTypes.string.isRequired
};

ReportDocuments.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        stripe_connect_client_id: state.client.customerInfo.stripe_connect_client_id,
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDocuments);

