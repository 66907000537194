import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const organizationalData = (callback) => (dispatch, getState) => {
    const store = getState()
    const client_id = store?.auth?.client_id
    const config = {
        url: `/api/organizational_chart/`,
        params: {client_id}
    };

    apiCall("Loading data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveChart = (structure, chartOptions, callback) => (dispatch, getState) => {
    const store = getState()
    const client_id = store?.auth?.client_id
    const config = {
        method: "PUT",
        url: `/api/organizational_chart/save_chart`,
        data: {structure, chartOptions, client_id}
    };

    apiCall("Saving data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};