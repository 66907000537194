import React, {useState, useEffect, useRef} from 'react';
import {CardElement, Elements, injectStripe, StripeProvider} from 'react-stripe-elements';
import {
    Row,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Tooltip,
    OverlayTrigger,
    ProgressBar,Alert
} from "react-bootstrap";
import * as moment from "moment";
import './StripeCard.css'
import {browserHistory} from "react-router";
import StripePowered from "../../../../images/Powered by Stripe - black.svg";
import Dollars from "../../../../components/Dollars";
import {defaultDateFormat} from "../../../../common/commonHandlers";
const smMobile = window.screen.width <= 450;

const createOptions = () => {
    return {
        style: {
            base: {
                width: '100%',
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Open Sans, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

const _CardForm = ({
                       stripeCredentials,
                       stripe,
                       hideModal,
                       loadCredentials,
                       subscriptionData,
                       subscriptionAction,
                       subscription,
                       activeUsers,
                       setIsSubscriptionActivated,
                       selectedPrice,
                       firstUserPrice
                   }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [name, setName] = useState("")
    const [usersLimit, setUsersLimit] = useState(false)
    const [showRenewAlert, setShowRenewAlert] = useState(false)
    const [stripePaymentMethod, setStripePaymentMethod] = useState()
    const [isCompleted, setIsCompleted] = useState(false)
    const [isValidCard, setIsValidCard] = useState()
    const [numberOfUsers, setNumberOfUsers] = useState(activeUsers)
    const [total_price, setTotalPrice] = useState((activeUsers - 1) * selectedPrice + firstUserPrice)
    const cardElement = useRef()

    const sub = subscription?.stripe_subscription
    const subscriptionId = stripeCredentials?.stripe_subscription?.subscription_id
    const isSubscriptionActive = sub?.status === 'active' || sub?.status === 'trialing'
    const isTrial = stripeCredentials?.stripe_subscription?.trial
    const trialUsersLimit = stripeCredentials?.stripe_subscription?.trial_users_limit
    const stripeCredentialsPlanId = stripeCredentials?.stripe_subscription?.stripe_plan
    const subscriptionUserName = stripeCredentials?.stripe_subscription?.username
    const subscriptionUserCount = stripeCredentials?.stripe_subscription?.user_count
    const activeLabel = `${activeUsers}/${subscriptionUserCount ? subscriptionUserCount : 0}`
    const expirationDesc = stripeCredentials?.stripe_subscription?.auto_renew ? "Next payment date:" : "Your subscription will expire:"
    const tooltipDescriptions = {
        users: {
            title: 'Users',
            description: 'Number of users who will use the app'
        },
        name: {
            title: 'Name',
            description: 'Your firstname and lastname on credit card'
        },
        card: {
            title: 'Card',
            description: 'Your card number'
        },
        expired: {
            title: 'Trial',
            description: "Your trial has been propably expired or cancelled. If you want to renew trial, please contact admin by clicking 'Renew request'"
        },
        trial: {
            title: 'Trial',
            description: `Free trial subscription for 14 days for maximum ${trialUsersLimit} users. It doesn't require fill form fields`
        }
    }

    const handleCardChange = async (e) => {
        if (e.complete && name.length < 1 && numberOfUsers < 1) {
            const {error, paymentMethod} = await stripe.createPaymentMethod('card', cardElement.current._element);
            if (error) {
                setErrorMessage(error.message)
            } else {
                setStripePaymentMethod(paymentMethod)
                setIsCompleted(true)
                setIsValidCard(false)
            }
        } else if (e.complete && name.length > 0 && numberOfUsers > 0) {
            const {error, paymentMethod} = await stripe.createPaymentMethod('card', cardElement.current._element);
            if (error) {
                setErrorMessage(error.message)
            } else {
                setStripePaymentMethod(paymentMethod)
                setIsCompleted(true)
                setIsValidCard(true)
            }
        } else {
            setIsCompleted(false)
            setIsValidCard(false)
        }
        e.error ? setErrorMessage(e.error.message) : setErrorMessage('')
    }

    const handleSubmit = e => {
        e.preventDefault();
        setIsLoading(true)
        subscriptionData.user_count = numberOfUsers
        subscriptionAction.createSubscription(subscriptionData, stripePaymentMethod, response => {
            if (response) {
                hideModal()
                loadCredentials()
                setIsLoading(false)
                browserHistory.push('/login')
            }
        })
    };

    // TODO fix this, we have app tooltips
    const renderTooltip = (title, description) => (
        <Tooltip id="tooltip">
            <div className="text-left">
                <strong>{title}</strong><span> - {description}</span>
            </div>
        </Tooltip>
    );

    const generateNumberOfUsersInfo = () =>
        <FormGroup bsSize="small" className='credential_input'>
            <ControlLabel className='d-flex'>
                <p className='bold bottom0'><span className='colorRed mr-3'>*</span>SELECTED NUMBER OF USERS:{numberOfUsers}</p>
                <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                                overlay={renderTooltip(tooltipDescriptions.users.title, tooltipDescriptions.users.description)}>
                    <Glyphicon glyph="info-sign text-info" className="notification ml-4 pointer"/>
                </OverlayTrigger>
            </ControlLabel>
            <FormControl
                type='text'
                placeholder='Number of users'
                value={activeUsers}
                disabled={true}
            />
        </FormGroup>

    const generateUsersProgressBar = () =>
        <div className='active-users-notitification'>
            <ProgressBar className='active-users-progress-bar' bsStyle="info"
                         now={(activeUsers / subscriptionUserCount) * 100}/>
            <p className='colorRed font10'>{activeLabel} users is actually active</p>
        </div>

    const generateSubscriptionNameField = () =>
        <FormGroup bsSize="medium" className='credential_input'>
            <ControlLabel className='d-flex'>
                <p className='bold bottom0'><span className='colorRed mr-3'>*</span>FULL NAME</p>
                <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                                overlay={renderTooltip(tooltipDescriptions.name.title, tooltipDescriptions.name.description)}>
                    <Glyphicon glyph="info-sign text-info" className="notification ml-4 pointer"/>
                </OverlayTrigger>
            </ControlLabel>
            <FormControl
                type='text'
                placeholder='First and last name'
                value={name}
                onChange={e => setName(e.target.value)}
            />
        </FormGroup>

    const generateCardNumberField = () =>
        <FormGroup bsSize="small" className='credential_input'>
            <ControlLabel className='d-flex'>
                <p className='bold bottom0'><span className='colorRed mr-3'>*</span>CARD</p>
                <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                                overlay={renderTooltip(tooltipDescriptions.card.title, tooltipDescriptions.card.description)}>
                    <Glyphicon glyph="info-sign text-info" className="notification ml-4 pointer"/>
                </OverlayTrigger>
            </ControlLabel>
            <CardElement
                ref={cardElement}
                onChange={(e) => handleCardChange(e)}
                {...createOptions()}
            />
        </FormGroup>

    useEffect(() => {
        const isSubscriptionActive = sub?.status === 'active' || sub?.status === 'trialing'
        setIsSubscriptionActivated(isSubscriptionActive)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (setShowRenewAlert) {
            let hideRenewAlert = setTimeout(() => setShowRenewAlert(false), 5000);
            return () => {
                clearTimeout(hideRenewAlert);
            };
        }
    }, [showRenewAlert])

    return (
        <div className="CardSubscritionDemo">
            {
                <form onSubmit={!isSubscriptionActive && handleSubmit}>

                    {subscriptionId && isSubscriptionActive && activeUsers ? <div>
                        <Row>
                            {generateUsersProgressBar()}
                            {usersLimit && !subscriptionId &&
                            <p className='limit-users-notification'>The current number of active users
                                is {activeUsers?.length}</p>}
                        </Row>
                        {showRenewAlert &&
                        <Row>
                            <Alert bsStyle="success">
                                <h4>Your request has been sent successfully</h4>
                                <p>We will keep you informed of the progress</p>
                            </Alert>
                        </Row>}
                    </div> : <div>
                        <Row>
                            {generateNumberOfUsersInfo()}
                        </Row>
                        <Row className='mt-40'>
                            {generateSubscriptionNameField()}
                        </Row>
                        <Row className='mt-40'>
                            {generateCardNumberField()}
                        </Row>
                        <Row className='textCenter' >
                            <FormGroup bsSize="small" className='credential_input stripe-powered'>
                                <img src={StripePowered} alt='Stripe' style={{width: 150}}/>
                            </FormGroup>
                        </Row>
                        <Row className='textCenter mt-40'>
                            <div className="error text-danger" role="alert">
                                <strong>{errorMessage}</strong>
                            </div>
                        </Row>
                    </div>}
                    <Row>
                        {subscriptionId && isSubscriptionActive ?
                            <button className='full-width' style={{background: '#DD1923'}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsLoading(true)
                                        subscriptionAction.cancelSubscription({
                                            subscription_id: subscriptionId
                                        }, (res) => {
                                            res.subscription_canceled && browserHistory.push('/login')
                                            setIsLoading(false)
                                        })
                                    }}
                            >
                                {!isLoading ? <div><span>{`Cancel Subscription`}</span>
                                </div> : "Loading..."}
                            </button>
                            :
                            <div className='text-right'>
                                <button type='submit'
                                        className={`full-width ${!isValidCard && 'subscribe-btn-disabled'}`}>
                                    {!isLoading ? <div><span>{isValidCard ? <strong>Pay <Dollars
                                        amount={total_price}/></strong> : `Subscribe`}</span>
                                    </div> : "Loading..."}
                                </button>
                            </div>
                        }
                        {subscriptionId && isSubscriptionActive &&
                        <div className='hright trial-version-expired'>
                            <p>{expirationDesc}</p>
                            <p className='ml-4'>{moment.unix(sub?.current_period_end).format(defaultDateFormat)}</p>
                        </div>}
                    </Row>
                </form>
            }
        </div>
    );
}

const CardForm = injectStripe(_CardForm);

export const PaymentElement = ({
                                   stripeCredentials,
                                   hideModal,
                                   subscriptionData,
                                   loadCredentials,
                                   subscriptionAction,
                                   subscription,
                                   clientName,
                                   authenticate,
                                   error,
                                   activeUsers,
                                   setIsSubscriptionActivated,
                                   selectedPrice,
                                   firstUserPrice
                               }) => (
    <StripeProvider apiKey={stripeCredentials.stripe_publishable_key}>
        <Elements>
            <CardForm
                stripeCredentials={stripeCredentials}
                error={error}
                hideModal={hideModal}
                loadCredentials={loadCredentials}
                subscriptionData={subscriptionData}
                subscriptionAction={subscriptionAction}
                subscription={subscription}
                clientName={clientName}
                authenticate={authenticate}
                activeUsers={activeUsers}
                setIsSubscriptionActivated={setIsSubscriptionActivated}
                selectedPrice={selectedPrice}
                firstUserPrice={firstUserPrice}
            />
        </Elements>
    </StripeProvider>
);
