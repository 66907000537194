import {locales} from "./constants";


const initialState = {
    userStatus: {
        subscription_active: true,
        locale: locales.EN,
    },
    showTaskDialog: false,
    showIssueDialog: false,
    screen: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SHOW_TASK_DIALOG':
            return {...state, showTaskDialog: true};
        case 'HIDE_TASK_DIALOG':
            return {...state, showTaskDialog: false};
        case 'SHOW_ISSUE_DIALOG':
            return {...state, showIssueDialog: true, screen: action.screen};
        case 'HIDE_ISSUE_DIALOG':
            return {...state, showIssueDialog: false};
        case 'GOT_USER_STATUS':
            return {...state, userStatus: action.userStatus};
        case 'CHANGE_LOCALE':
            return {...state, userStatus: {...state.userStatus, locale: action.locale}}
        default:
            return state
    }
}

