import React, {Component} from 'react';
import PropTypes from "prop-types"
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as MyActions from './actions'
import {addAlert} from '../App/actions'
import {colors, rolesDescriptions, select} from "../../common/commonHandlers";
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Nav,
    NavItem,
    OverlayTrigger,
    Radio,
    Row,
    Tab,
    Tabs,
    Tooltip,
    Well
} from 'react-bootstrap'
import PageHeader from '../../components/PageTitle'
import Helmet from 'react-helmet'
import Select from "react-select"
import Secured from "../../common/Secured";
import {humanize} from '../../utilities/index'
import ActivityList from './ActivityList'
import IssueList from './IssueList/IssueList'
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import TransferCustomer from './TransferCustomer/TransferCustomer';
import ClientTransactions from './Transactions/ClientTransactions'
import AdminTransactions from './Transactions/AdminTransactions'
import {geolocated} from "react-geolocated";
import {debounce} from 'throttle-debounce';
import UsersMap from "./UsersMap/UsersMap";
import {CirclePicker} from 'react-color'

const Actions = {...MyActions, addAlert};

class AdminPage extends Component {
    static propTypes = {
        clientsList: PropTypes.array.isRequired,
        editingClient: PropTypes.object
    };

    constructor(props, context) {
        super(props, context);
        this.delayedSaveUserCapacity = debounce(500, this.changeUserCapacity);
        this.state = {
            isAdminPaymentActive: false,
            isClientPaymentActive: false,
            userCapacity: null,
            usersList: [],
            showPicker: false
        };
    }

    componentWillMount() {
        this.refreshClientsList();
        this.props.actions.fetchUserActivities();
        this.props.actions.fetchUserIssues();
        this.props.actions.fetchClientInfo(this.props.userClientId);
        this.fetchUsersList();
    }

    componentDidMount() {
        this.setState({isAdminPaymentActive: true, isClientPaymentActive: true})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.filterUserListText !== prevProps.filterUserListText) {
            this.fetchUsersList()
        }
    }

    isAdmin() {
        try {
            return (this.props.roles.length === 1 && this.props.roles[0] === 'it_engineering')
        } catch (e) {
            console.error('Admin check failed:', e);
            return false
        }
    }

    refreshClientsList() {
        this.props.actions.fetchClientsList()
        this.editClient(null)
    }

    refreshUsersList() {
        this.fetchUsersList()
        this.selectUser(null)
    }

    fetchUsersList() {
        const filterText = this.props.filterUserListText;
        this.props.actions.fetchAllUsers((users) => {
            const filteredUsers = filterText ? users.filter(u => {
                return (u.first_name && u.first_name.toLowerCase().indexOf(
                    filterText.toLowerCase()) !== -1) || (u.last_name && u.last_name.toLowerCase().indexOf(
                    filterText.toLowerCase()) !== -1)
            }) : users;
            this.setState({
                usersList: filteredUsers
            })
        })
    }

    addNewClient() {
        this.props.actions.createNewClient()
        this.editClient(0)
    }

    deleteClient(id) {
        this.props.actions.deleteClient(id)
        this.props.actions.fetchClientsList() //refresh the list
        this.editClient(null)
    }

    changeFilterText(txt) {
        this.props.actions.clientFilterText(txt)
        this.editClient(null)
    }

    changeUserFilterText(txt) {
        this.props.actions.clientUserFilterText(txt)
    }

    changeUserListFilterText(txt) {
        this.props.actions.userListFilterText(txt)
    }

    editClient(clientId) {
        this.props.actions.loadClientForEditing(clientId, (client) => {
            this.setState({userCapacity: client.user_capacity})
        })
        this.setState({
            clientId,
            user: null
        });
        if (clientId != 0 && clientId != null) {
            this.props.actions.fetchCustomerInfo(clientId, () => {
                this.setState({
                    enableStripePayments: this.props.client.enable_payments,
                    transactionFeeEnabled: !!this.props.client.transaction_fee,
                    enableRefunds: this.props.client.refunds_enabled,
                    transactionFeeType: this.props.client.transaction_fee && (this.props.client.transaction_fee.includes('%') ? 'Percentage' : this.props.client.transaction_fee.includes('$') ? "Amount" : null),
                    transactionFeeValue: this.props.client.transaction_fee && (this.props.client.transaction_fee.includes('%') ? +this.props.client.transaction_fee.slice(0, -1) : this.props.client.transaction_fee.includes('$') ? +this.props.client.transaction_fee.substr(1) : null),
                    stripeProcessingFee: !!this.props.client.stripe_processing_fee,
                    customerFeeEnabled: !!this.props.client.customer_fee,
                    customerFeeType: this.props.client.customer_fee && (this.props.client.customer_fee.includes('%') ? 'Percentage' : this.props.client.customer_fee.includes('$') ? "Amount" : null),
                    customerFeeValue: this.props.client.customer_fee && (this.props.client.customer_fee.includes('%') ? +this.props.client.customer_fee.slice(0, -1) : this.props.client.customer_fee.includes('$') ? +this.props.client.customer_fee.substr(1) : null)
                });
            });
            this.props.actions.fetchUsers(clientId)
            this.props.actions.fetchRoles()
            this.props.actions.fetchEmployees(clientId)
        }
    }

    selectUser(userId) {
        this.setState({
            selectedUserId: userId,
        });
    }

    renderTextField(client, attr) {
        const name = humanize(attr)
        return (
            <FormGroup bsSize="sm" key={attr}>
                <ControlLabel>{name}</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={name}
                    value={client[attr] || ''}
                    onChange={e =>
                        this.props.actions.updateClientAttribute(
                            client.id,
                            attr,
                            e.target.value
                        )}
                />
            </FormGroup>
        )
    }

    updatePayments = () => {
        let params = {};
        if (this.state.enableStripePayments) {
            params.enableStripePayments = this.state.enableStripePayments;
        } else {
            params.transactionFeeType = null;
        }
        if (this.state.enableRefunds) {
            params.enableRefunds = this.state.enableRefunds;
        } else {
            params.enableRefunds = false;
        }
        if (this.state.transactionFeeEnabled) {
            params.transactionFeeType = this.state.transactionFeeType;
            params.transactionFeeValue = this.state.transactionFeeValue;
        } else {
            params.transactionFeeType = null;
            params.transactionFeeValue = null;
        }
        if (this.state.customerFeeEnabled) {
            params.customerFeeType = this.state.customerFeeType;
            params.customerFeeValue = this.state.customerFeeValue;
        } else {
            params.customerFeeType = null;
            params.customerFeeValue = null;
        }
        if (this.state.stripeProcessingFee) {
            params.stripeProcessingFee = this.state.stripeProcessingFee
        } else {
            params.stripeProcessingFee = null
        }

        params.id = this.props.client.id;
        this.props.actions.updatePayments(params, () => {
            this.props.actions.fetchCustomerInfo(this.props.client.id, () => {
                this.setState({
                    enableStripePayments: this.props.client.enable_payments,
                    transactionFeeEnabled: !!this.props.client.transaction_fee,
                    enableRefunds: this.props.client.refunds_enabled,
                    transactionFeeType: this.props.client.transaction_fee && (this.props.client.transaction_fee.includes('%') ? 'Percentage' : this.props.client.transaction_fee.includes('$') ? "Amount" : null),
                    transactionFeeValue: this.props.client.transaction_fee && (this.props.client.transaction_fee.includes('%') ? +this.props.client.transaction_fee.slice(0, -1) : this.props.client.transaction_fee.includes('$') ? +this.props.client.transaction_fee.substr(1) : null),
                    stripeProcessingFee: !!this.props.client.stripe_processing_fee,
                    customerFeeEnabled: !!this.props.client.customer_fee,
                    customerFeeType: this.props.client.customer_fee && (this.props.client.customer_fee.includes('%') ? 'Percentage' : this.props.client.customer_fee.includes('$') ? "Amount" : null),
                    customerFeeValue: this.props.client.customer_fee && (this.props.client.customer_fee.includes('%') ? +this.props.client.customer_fee.slice(0, -1) : this.props.client.customer_fee.includes('$') ? +this.props.client.customer_fee.substr(1) : null)
                });
            })
        })
    };

    changeUserCapacity = () => {
        this.props.actions.changeUserCapacity(this.state.userCapacity, this.state.clientId)
    }

    renderClientEditor(client) {
        const {users} = this.props
        const {user, showPicker} = this.state
        return (
            <Tabs defaultActiveKey={1} animation={false} id="noanim-tab-example">
                <Tab eventKey={1} title="Organization Info">
                    <Grid fluid className="top25">
                        <Row>
                            <Col sm={12}>
                                <form onSubmit={this._saveAll}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Company Name
                                                </ControlLabel>
                                                <FormControl
                                                    name="name"
                                                    placeholder="Company Name"
                                                    onChange={this._updateClientAttr}
                                                    value={client.name}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <br/>
                                            <FormGroup bsSize="small">
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Address 1
                                                </ControlLabel>
                                                <FormControl
                                                    name="address_1"
                                                    placeholder="Address 1"
                                                    onChange={this._updateClientAttr}
                                                    value={client.address_1}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Address 2
                                                </ControlLabel>
                                                <FormControl
                                                    name="address_2"
                                                    placeholder="Last Name"
                                                    onChange={this._updateClientAttr}
                                                    value={client.address_2}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>

                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    City
                                                </ControlLabel>
                                                <FormControl
                                                    name="city"
                                                    placeholder="City"
                                                    onChange={this._updateClientAttr}
                                                    value={client.city}
                                                />
                                            </FormGroup>

                                        </Col>

                                        <Col md={6}>

                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    State
                                                </ControlLabel>
                                                <FormControl
                                                    name="state"
                                                    placeholder="State"
                                                    onChange={this._updateClientAttr}
                                                    value={client.state}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Zip
                                                </ControlLabel>
                                                <FormControl
                                                    name="zip"
                                                    placeholder="Zip"
                                                    onChange={this._updateClientAttr}
                                                    value={client.zip}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Country
                                                </ControlLabel>
                                                <FormControl
                                                    name="country"
                                                    placeholder="Country"
                                                    onChange={this._updateClientAttr}
                                                    value={client.country}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Phone
                                                </ControlLabel>
                                                <FormControl
                                                    name="phone_number"
                                                    placeholder="Phone"
                                                    onChange={this._updateClientAttr}
                                                    value={client.phone_number}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Email
                                                </ControlLabel>
                                                <FormControl
                                                    name="email_address"
                                                    placeholder="Email"
                                                    onChange={this._updateClientAttr}
                                                    value={client.email_address}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Special Info For Forms
                                                </ControlLabel>
                                                <FormControl
                                                    name="web_url"
                                                    placeholder="Keep license numbers or anything you may want to have on a document here"
                                                    onChange={this._updateClientAttr}
                                                    value={client.web_url}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Company Color
                                                </ControlLabel>
                                                <div
                                                    className={`c${colors.findIndex(c => c === this.props.editingClient["embedded_background_color"])} 
                                                color-picker pointer visible bigger`}
                                                    onClick={() => this.setState({showPicker: !showPicker})}/>
                                                {showPicker &&
                                                    <CirclePicker width={400} colors={colors} onChange={(e) => {
                                                        this._updateClientColor(e.hex)
                                                        this.setState({showPicker: false});
                                                    }}/>}
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} className="text-right">
                                            {this._renderActionButton()}
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </Grid>
                </Tab>
                <Tab eventKey={2} title="Users">
                    <div className="top25">
                        <Row>
                            <Col md={6}>
                                <Button onClick={() => this.handleUserSelect({id: null, email: '', roles: []})}
                                        bsStyle="info">
                                    New User
                                </Button>
                            </Col>
                            <Col md={3}>
                                {users && <span>Users: {users.length}</span>}
                            </Col>
                            <Col md={3}>
                                <form>
                                    <FormGroup>
                                        <ControlLabel>Max users count</ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Users count"
                                            value={this.state.userCapacity}
                                            onChange={e => {
                                                this.setState({userCapacity: e.target.value}, this.delayedSaveUserCapacity)
                                            }}
                                        />
                                    </FormGroup>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <form className="top25">
                                    <FormGroup>
                                        <ControlLabel>Filter</ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Filter employees"
                                            onChange={e => this.changeUserFilterText(e.target.value)}
                                        />
                                    </FormGroup>
                                </form>
                                <div style={{height: "600px", overflowY: "scroll"}}>
                                    <Nav
                                        bsStyle="pills"
                                        stacked
                                        onSelect={this.handleUserSelect}
                                    >
                                        {users && users.map(u => (
                                            <NavItem key={u.id} eventKey={u}
                                                     active={user && user.id === u.id}>{u.email}</NavItem>
                                        ))}

                                    </Nav>
                                </div>
                            </Col>
                            <Col md={9}>
                                {this.renderUserEditor()}
                            </Col>
                        </Row>
                    </div>
                </Tab>
                <Tab eventKey={3} title="Payments">
                    <h3><strong>Payments</strong></h3>
                    <FormGroup bsSize="small">
                        <h4 className='text-primary top25'>
                            <ColorCheckbox value={client.enable_payments}
                                           label='Enable Stripe Payments'
                                           className='vertical-align'
                                           onChange={e => {
                                               this.setState({enableStripePayments: !this.state.enableStripePayments})
                                           }}
                            />
                        </h4>
                    </FormGroup>
                    <hr/>
                    {this.state.enableStripePayments && <div>
                        <Row>
                            <Col md={3}>
                                <h4 style={{color: "#d9230f", marginLeft: 30}}>
                                    Transaction fee
                                </h4>
                                <div className='top15 left25'>
                                    <FormGroup className='vertical-align'>
                                        <Radio
                                            checked={this.state.transactionFeeType === 'Amount'}
                                            onChange={(e) => {
                                                this.setState({transactionFeeType: 'Amount', transactionFeeValue: 0})
                                            }}
                                            name={'Amount'}
                                        >
                                            Amount
                                        </Radio>
                                        {
                                            this.state.transactionFeeType === 'Amount' &&
                                            <FormGroup className='vertical-align bottom0'>
                                                <ControlLabel className='small-margin'>$</ControlLabel>
                                                <FormControl
                                                    name="amount"
                                                    type='number'
                                                    onChange={(e) => {
                                                        this.setState({transactionFeeValue: e.target.value})
                                                    }}
                                                    value={this.state.transactionFeeValue}
                                                />
                                            </FormGroup>
                                        }
                                    </FormGroup>
                                    <br/>
                                    <FormGroup className='vertical-align'>
                                        <Radio
                                            checked={this.state.transactionFeeType === 'Percentage'}
                                            onChange={(e) => {
                                                this.setState({
                                                    transactionFeeType: 'Percentage',
                                                    transactionFeeValue: 0
                                                })
                                            }}
                                            name={'Percentage'}
                                        >
                                            Percentage
                                        </Radio>
                                        {
                                            this.state.transactionFeeType === 'Percentage' &&
                                            <FormGroup className='vertical-align bottom0'>
                                                <FormControl
                                                    name="percentage"
                                                    type='number'
                                                    onChange={(e) => {
                                                        this.setState({transactionFeeValue: e.target.value.length !== 0 ? e.target.value : 0})
                                                    }}
                                                    value={this.state.transactionFeeValue}
                                                    className='small-margin'
                                                />
                                                <ControlLabel>%</ControlLabel>
                                            </FormGroup>
                                        }
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col md={7}>
                                <Well className="flex-column-start">
                                    <p>Fee, which goes to the account of the application owner (to the platform
                                        account). It is added to the total invoice amount.</p>
                                    <ul>
                                        <li className="mt10">Amount - value determined in advance</li>
                                        <li>Percentage - value calculated from the invoice value</li>
                                    </ul>
                                </Well>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <FormGroup bsSize="small">
                                    <h4 className='text-primary top25'>
                                        <ColorCheckbox value={this.state.customerFeeEnabled}
                                                       label='Apply Customer Fee'
                                                       className='vertical-align'
                                                       onChange={e => {
                                                           this.setState({customerFeeEnabled: !this.state.customerFeeEnabled})
                                                       }}
                                        />
                                    </h4>
                                </FormGroup>
                                {
                                    this.state.customerFeeEnabled &&
                                    <div className='top15 left25'>
                                        <FormGroup className='vertical-align'>
                                            <Radio
                                                checked={this.state.customerFeeType === 'Amount'}
                                                onChange={(e) => {
                                                    this.setState({customerFeeType: 'Amount', customerFeeValue: 0})
                                                }}
                                                name={'Customer_Amount'}
                                            >
                                                Amount
                                            </Radio>
                                            {
                                                this.state.customerFeeType === 'Amount' &&
                                                <FormGroup className='vertical-align bottom0'>
                                                    <ControlLabel className='small-margin'>$</ControlLabel>
                                                    <FormControl
                                                        name="amount"
                                                        type='number'
                                                        onChange={(e) => {
                                                            this.setState({customerFeeValue: e.target.value})
                                                        }}
                                                        value={this.state.customerFeeValue}
                                                    />
                                                </FormGroup>
                                            }
                                        </FormGroup>
                                        <br/>
                                        <FormGroup className='vertical-align'>
                                            <Radio
                                                checked={this.state.customerFeeType === 'Percentage'}
                                                onChange={(e) => {
                                                    this.setState({customerFeeType: 'Percentage', customerFeeValue: 0})
                                                }}
                                                name={'Customer_Percentage'}
                                            >
                                                Percentage
                                            </Radio>
                                            {
                                                this.state.customerFeeType === 'Percentage' &&
                                                <FormGroup className='vertical-align bottom0'>
                                                    <FormControl
                                                        name="percentage"
                                                        type='number'
                                                        onChange={(e) => {
                                                            this.setState({customerFeeValue: e.target.value})
                                                        }}
                                                        value={this.state.customerFeeValue}
                                                        className='small-margin'
                                                    />
                                                    <ControlLabel>%</ControlLabel>
                                                </FormGroup>
                                            }
                                        </FormGroup>
                                    </div>
                                }
                            </Col>
                            <Col md={7} className="mt-20">
                                <Well className="flex-column-start">
                                    <p>The fee that goes to the application client's account (to the connected account).
                                        It is added to the total invoice amount.</p>
                                    <ul>
                                        <li className="mt10">Amount - value determined in advance</li>
                                        <li>Percentage - value calculated from the invoice value</li>
                                    </ul>
                                </Well>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <FormGroup bsSize="small">
                                    <h4 className='text-primary top25'>
                                        <ColorCheckbox value={this.state.stripeProcessingFee}
                                                       label='Pass the Stripe processing fee on to customers'
                                                       className='vertical-align'
                                                       onChange={e => {
                                                           this.setState({stripeProcessingFee: !this.state.stripeProcessingFee})
                                                       }}
                                        />
                                    </h4>
                                    <p className="left25">
                                        Stripe processing fee is <strong>2.9% + 30&#162;</strong>
                                    </p>
                                </FormGroup>
                            </Col>
                            <Col md={7} className="mt-20">
                                <Well className="flex-column-start">
                                    <p>You can pass your Stripe fees on to your customers by including the fee into the
                                        final charge amount.
                                        It is important to ensure that this action complies with any applicable laws
                                        that pertain to your business.
                                        If you are unsure whether such laws apply, consult with your legal counsel.</p>
                                </Well>
                            </Col>
                        </Row>
                        <h4 className='text-primary top25'>
                            <ColorCheckbox value={this.state.enableRefunds}
                                           label='Enable Refunds'
                                           className='vertical-align'
                                           onChange={() => {
                                               this.setState({enableRefunds: !this.state.enableRefunds})
                                           }}
                            />
                        </h4>
                    </div>}
                    <Button bsStyle="info" className="pull-right" onClick={this.updatePayments}>
                        Save
                    </Button>
                </Tab>
                <Tab eventKey={4} title='Transactions'>
                    <ClientTransactions isAdminPaymentActive={this.state.isAdminPaymentActive}/>
                </Tab>
                <Tab eventKey={5} title="Transfer Customer">
                    <TransferCustomer/>
                </Tab>
            </Tabs>
        )
    }

    renderUserEditor = () => {
        const {user} = this.state;
        const {employees, rolesUsers, isGeolocationAvailable, isGeolocationEnabled, coords, client} = this.props;
        const employeesOptions = employees.map(e => ({label: e.name, value: e.person_id}));
        return user && (<form onSubmit={e => this._saveUser(e)}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <h4 className="text-primary">
                                    {user.first_name ? `${user.first_name} ${user.last_name}` : 'New user'}
                                </h4>
                                <hr/>
                            </Col>
                            <Col md={6}>
                                <Select className="Select" classNamePrefix="select"
                                        placeholder="Employee"
                                        simpleValue={true}
                                        value={select(employeesOptions, user.person_id)}
                                        onChange={selected => {
                                            this.state.user.person_id = selected.value
                                            this.setState({user: this.state.user})
                                        }}
                                        options={employeesOptions}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>
                                        Email
                                    </ControlLabel>
                                    <FormControl
                                        name="email"
                                        placeholder="email"
                                        onChange={this._updateUserAttr}
                                        value={user.email}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                Roles
                                {user && rolesUsers && rolesUsers.map(
                                    r => {
                                        const checkbox = (
                                            <FormGroup key={r.id}>
                                                <Checkbox
                                                    name="role_name"
                                                    onChange={this._handleSelectedRole(r.id)}
                                                    inline
                                                    checked={user.roles.includes(r.id)}
                                                >
                                                    {" "}
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>
                                                        <div>{rolesDescriptions[r.role_name]}</div>
                                                    </Tooltip>}>
                                                        <div>
                                                            {r.role_name}
                                                        </div>
                                                    </OverlayTrigger>
                                                </Checkbox></FormGroup>);
                                        return r.role_name === 'it_engineering' ? (
                                            <Secured key={r.id} roles="it_engineering">{checkbox}</Secured>) : checkbox;
                                    }
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>
                                        New Password
                                    </ControlLabel>
                                    <FormControl
                                        name="password"
                                        type="password"
                                        placeholder="password"
                                        value={user.password}
                                        onChange={this._updateUserAttr}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>
                                        Password Confirmation
                                    </ControlLabel>
                                    <FormControl
                                        name="password_confirmation"
                                        type="password"
                                        placeholder="password confirmation"
                                        value={user.password_confirmation}
                                        onChange={this._updateUserAttr}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Checkbox
                                        name="disabled"
                                        onChange={this._checkUserAttr}
                                        inline
                                        checked={user.disabled}
                                    >
                                        disabled
                                    </Checkbox>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {user.id && <div className="center-block imper_token">
                            <Button onClick={() => this.deleteUser(user.id)} className="pull-left">
                                Delete User
                            </Button>
                            <Button
                                onClick={() => this.props.actions.impersonate(user.id, isGeolocationAvailable && isGeolocationEnabled && coords && coords, () => this.props.actions.fetchClientInfo(client.id))}>
                                Impersonate
                            </Button>
                            <Button onClick={() => this.props.actions.refreshToken(user.id)}>
                                Refresh Token
                            </Button>
                        </div>}
                        <Button bsStyle="info" className="pull-right" type="submit">
                            Save User
                        </Button>
                    </Col>
                </Row>
            </form>
        )
    }

    _saveAll = async e => {
        e.preventDefault();
        this.props.actions.saveOrCreateClient(this.props.editingClient)
    };

    _updateClientAttr = e => {
        let value = {};
        this.props.editingClient[e.target.name] = e.target.value
        this.setState(value);
    }

    _updateClientColor = color => {
        let value = {};
        this.props.editingClient["embedded_background_color"] = color
        this.setState(value);
    }

    _renderActionButton = () => (
        <Button
            type="submit"
            bsSize="xsmall"
            bsStyle="success"
        >
            Save
        </Button>
    )

    handleUserSelect = (e) => {
        e.password_confirmation = ''
        e.password = ''
        this.setState(
            {
                user: Object.assign({}, e)
            }
        )
    }

    _updateUserAttr = e => {
        this.state.user[e.target.name] = e.target.value
        this.setState({user: this.state.user});
    }

    _checkUserAttr = e => {
        this.state.user[e.target.name] = e.target.checked;
        this.setState({user: this.state.user});
    };

    deleteUser = userId => {
        this.props.actions.deleteUser(userId, this.props.editingClientId)
        this.setState({user: null});
    }

    _handleSelectedRole = roleId => e => {
        if (e.target.checked) {
            this.state.user.roles.push(roleId)
        } else {
            this.state.user.roles = this.state.user.roles.filter(r => r !== roleId)
        }
        this.setState({user: this.state.user});
    }

    _saveUser = async e => {
        e.preventDefault();
        this.props.actions.saveUserInfo(this.state.user, this.props.editingClientId)
    };

    render() {
        if (!this.isAdmin()) {
            console.warn("Non-admin tried to render Admin page or we're about to impersonate.")
            return null
        }

        const {
            name, clientsList, editingClient, editingClientId, filterText, filterUserText,
            filterUserListText, activityList, issueList, users, employees
        } = this.props;
        const {usersList, selectedUserId} = this.state;

        return (
            <Grid fluid>
                <Helmet title="Admin"/>

                <Tabs defaultActiveKey={1} animation={false} id="noanim-tab-example">
                    <Tab eventKey={1} title="Users">
                        <PageHeader pageName="Admin" pageDetail={`- ${name}`}/>

                        <Row>
                            <Col xs={12}>
                                <Button onClick={e => this.refreshClientsList()} bsStyle="success">
                                    Refresh Clients List
                                </Button>
                                {' '}
                                <Button onClick={e => this.addNewClient()} bsStyle="info">
                                    New Client
                                </Button>
                            </Col>
                        </Row>
                        <p>&nbsp;</p>

                        <Row>
                            <Col xs={3}>
                                <form>
                                    <FormGroup>
                                        <ControlLabel>Filter {clientsList.length} Clients</ControlLabel>
                                        <FormControl type="text" placeholder="Filter Clients" value={filterText}
                                                     onChange={e => this.changeFilterText(e.target.value)}/>
                                    </FormGroup>
                                </form>
                                <div style={{height: '600px', overflowY: 'scroll'}}>
                                    <Nav
                                        bsStyle="pills"
                                        stacked
                                        activeKey={editingClientId}
                                        onSelect={this.editClient.bind(this)}>
                                        {clientsList.map(p => (
                                            <NavItem key={p.id} eventKey={p.id}>{p.name}</NavItem>
                                        ))}
                                        {filterText.length > 0 && clientsList.length === 0 &&
                                            <p>No Client Matches With Your Filter</p>}
                                    </Nav>
                                </div>
                            </Col>
                            <Col xs={9}>
                                {editingClient && this.renderClientEditor(editingClient)}
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey={2} title="Activity">
                        <ActivityList
                            activityList={activityList}
                            onRefresh={this.props.actions.fetchUserActivities}
                            onTruncate={this.props.actions.truncateActivities}
                        />
                    </Tab>
                    <Tab eventKey={3} title="Issues">
                        <IssueList
                            issueList={issueList}
                            deleteIssue={this.props.actions.deleteIssue}
                        />
                    </Tab>
                    <Tab eventKey={4} title="Transactions">
                        <AdminTransactions isClientPaymentActive={this.state.isClientPaymentActive}/>
                    </Tab>
                    <Tab eventKey={5} title="Users Map">
                        <PageHeader pageName="Admin" pageDetail={`- ${name}`}/>

                        <Row>
                            <Col xs={12}>
                                <Button onClick={e => this.refreshUsersList()} bsStyle="success">
                                    Refresh Users List
                                </Button>
                            </Col>
                        </Row>
                        <p>&nbsp;</p>

                        <Row>
                            <Col xs={3}>
                                <form>
                                    <FormGroup>
                                        <ControlLabel>Filter {usersList.length} Users</ControlLabel>
                                        <FormControl type="text" placeholder="Filter Clients" value={filterUserListText}
                                                     onChange={e => this.changeUserListFilterText(e.target.value)}/>
                                    </FormGroup>
                                </form>
                                <div style={{height: '600px', overflowY: 'scroll'}}>
                                    <Nav
                                        bsStyle="pills"
                                        stacked
                                        activeKey={selectedUserId}
                                        onSelect={this.selectUser.bind(this)}>
                                        {usersList && usersList.map(p => (
                                            <NavItem key={p.id}
                                                     eventKey={p.id}>{`${p.first_name} ${p.last_name}`}</NavItem>
                                        ))}
                                        {filterUserListText.length > 0 && clientsList.length === 0 &&
                                            <p>No Client Matches With Your Filter</p>}
                                    </Nav>
                                </div>
                            </Col>
                            <Col xs={9}>
                                <UsersMap selectedUserId={selectedUserId} users={usersList}/>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Grid>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        name: state.auth.name,
        roles: state.auth.roles,
        userClientId: state.auth.client_id,
        clientsList: state.admin.clientsList.filter(
            p => p.name.toLowerCase().indexOf(state.admin.filterText.toLowerCase()) !== -1
        ),
        activityList: state.admin.activityList,
        issueList: state.admin.issueList,
        filterText: state.admin.filterText,
        filterUserText: state.admin.filterUserText,
        filterUserListText: state.admin.filterUserListText,
        editingClientId: state.admin.editingClientId,

        editingClient: state.admin.clients[
            `clients_${state.admin.editingClientId}`
            ],
        users: state.client.userInfo && state.client.userInfo.filter(
            u => u.email.toLowerCase().indexOf(state.admin.filterUserText.toLowerCase()) !== -1
        ),
        rolesUsers: state.client.roles,
        employees: state.client.employees,
        client: state.client.customerInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(AdminPage));
