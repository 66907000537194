import React from 'react';
import {Row} from "react-bootstrap";
import moment from "moment";

const CustomerAppointments = ({appointments}) => {
    return (
        <>
            {appointments.map((a, index) => (
                <>
                    <Row className='item' key={index}>
                        <div className='item-header'>
                            <div
                                className='item-title'>{moment(a.start_date).format('dddd, MM/DD/YYYY')}
                            </div>
                        </div>
                        <div className='item-data'>
                            <div className='flex'>
                                <p className='bold mr-5'>Time:</p>
                                <p>{`${moment(a.start_date).format('h:mmA')} - ${moment(a.end_date).format('h:mmA')}`}</p>
                            </div>
                            <div className='flex'>
                                <p className='bold mr-5'>Address:</p>
                                <p>{a.site}</p>
                            </div>
                            <div className='flex'>
                                <p className='bold mr-5'>Assigned Person:</p>
                                <p>{a.assigned_person}</p>
                            </div>
                            <div className='flex'>
                                <p className='bold mr-5'>Event Type:</p>
                                <p>{a.event_type}</p>
                            </div>
                        </div>
                    </Row>
                </>
            ))}
        </>
    );
};

export default CustomerAppointments;