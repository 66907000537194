import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Stickies from "../Stickies/Stickies";
import {Button, Checkbox, Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import {getUserStatus} from "../AppWrapper/contents/actions"
import * as notificationsActions from "./NotificationsApi"
import Select from "react-select"
import Secured from "../../common/Secured";
import Notifications from "./Notifications";
import {showEmailDialog} from "../../components/email/actions"

const actions = {...notificationsActions, getUserStatus, showEmailDialog};

class Messages extends Notifications {
    searchRef = React.createRef();
    state = {
        stickies: [],
        unreadOnly: true,
        unknownUser: false,
        showDisabledUsers: false,
        searchResult: null,
        searchResults: [],
        isLoading: false
    };

    markAsRead = () => {
        const {user_id} = this.props.auth;
        const {employee} = this.state;

        this.props.actions.markAsRead(user_id, 'messages', employee.value, () => {
            this.loadStickies();
            this.props.actions.getUserStatus(user_id);
        });
    };

    getNewCount = stickies => {
        let unread = stickies.filter(s => !s.read).length;
        let result = Math.min(unread, this.props.count);
        result += result === this.props.count ? '+' : '';
        return result;
    };

    componentWillMount() {
        if (this.props && this.props.searchResult) {
            this.setState({searchResult: this.props.searchResult})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showDisabledUsers} = this.state;

        if (prevState.showDisabledUsers !== showDisabledUsers) {
            this.props.actions.saveShowDisabledUsers(showDisabledUsers, ({show_disabled_users}) => {
                this.props.actions.getActiveEmployees(show_disabled_users, employees => {
                    const unknown = {id: "Unknown", label: "Unknown"}
                    const clientEmployees = [...employees, unknown]
                    this.setState({employees: clientEmployees})
                })
            })
        }

        if (prevProps.reloadDate !== this.props.reloadDate){
            this.loadStickies()
        }
    }

    render() {
        const {stickies, employee, unreadOnly, searchResult, searchResults, unknownUser, showDisabledUsers, isLoading} = this.state;
        const {type, isProposalView, auth} = this.props;
        const {user_id} = this.props.auth;

        stickies.forEach(s => {
            s.read = s.read_message;
            s.replies.forEach(r => {
                r.read = r.read_message
            })
        });

        const employees = this.setActiveEmployees(auth?.employee_id)

        return (
            <div>
                <Stickies stickies={stickies}
                          isProposalView={isProposalView}
                          save={
                              (sticky, data, callback) => {
                                  this.props.actions.addNote(sticky, data, () => {
                                      this.loadStickies();
                                      this.props.actions.getUserStatus(user_id);
                                      callback();
                                  });
                              }}
                          remove={!isProposalView ? sticky => this.handleRemove(sticky, user_id) : null}
                          hide={sticky => this.props.actions.hideNote(sticky, () => {
                              this.loadStickies();
                              this.props.actions.getUserStatus(user_id);
                          })}
                          read={sticky => this.props.actions.readNote(sticky, () => {
                              this.loadStickies();
                              this.props.actions.getUserStatus(user_id);
                          })}
                          notify={sticky => {
                              this.setState({sticky}, this.showEmailDialog);
                          }}
                          type={type}
                          stickable={searchResult}
                >
                    {!isProposalView && <div>
                        <Grid fluid>
                            <Row>
                                {unknownUser ?
                                    <Col md={12}>
                                        <p className="margin10 padding-top-bottom-8">
                                            {`You have ${stickies.length} ${type}`}
                                        </p>
                                    </Col>
                                    :
                                    <Col md={12}>
                                        <p className="margin10">
                                            {`You have ${this.getNewCount(stickies)} unread ${type}`}
                                            <Button bsStyle="link" onClick={this.markAsRead}>Mark all as read</Button>
                                        </p>
                                    </Col>
                                }
                                <form className="form-inline">
                                    <Col md={12}>
                                        <Secured roles="admin">
                                            <FormGroup>
                                                <ControlLabel>Created by</ControlLabel>
                                                <Select className="Select" classNamePrefix="select" id="userSelect"
                                                        value={employee}
                                                        isClearable
                                                        options={employees}
                                                        onChange={this.handleEmployeeSelect}
                                                />
                                            </FormGroup>
                                        </Secured>
                                    </Col>
                                    {
                                        unknownUser ? null :
                                            <>
                                                <Col md={3}/>
                                                <Col md={9}>
                                                    <FormGroup className='d-flex'>
                                         <span className="vertical-align mr-15">
                                        <ControlLabel className='full-width'>Unread only</ControlLabel>
                                        <Checkbox
                                            name="unread"
                                            className='no-right-padding no-left-padding'
                                            onChange={this.handleUnreadChange}
                                            checked={unreadOnly}
                                        />
                                         </span>
                                                        <span className="vertical-align">
                                        <ControlLabel className='full-width'>Disabled users</ControlLabel>
                                        <Checkbox
                                            name="showDisabledUsers"
                                            className='no-right-padding no-left-padding'
                                            onChange={e => {
                                                this.setState({showDisabledUsers: e.target.checked})
                                            }}
                                            checked={showDisabledUsers}
                                        />
                                        </span>
                                                    </FormGroup>
                                                </Col></>}
                                    <Col md={12}>
                                        <FormGroup>
                                            {
                                                unknownUser ? null :
                                                    <span>
                                                        <ControlLabel>Search</ControlLabel>
                                            <Select className="Select" classNamePrefix="select"
                                                    ref={this.searchRef}
                                                    value={searchResult}
                                                    options={searchResults}
                                                    key={searchResults ? searchResults.length : 0}
                                                    openMenuOnFocus={searchResults?.length > 0 && !searchResult}
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isLoading={isLoading}
                                                    onInputChange={e => this.handleSearchInputChange(e, {
                                                        type: 'messages',
                                                        unread_only: unreadOnly,
                                                        employee_id: employee?.value
                                                    })}
                                                    onChange={this.handleSearchResultSelect}
                                            />
                                                    </span>
                                            }
                                        </FormGroup>
                                    </Col>
                                </form>
                            </Row>
                        </Grid>
                    </div>}
                </Stickies>
            </div>
        );
    }
}

Messages.propTypes = {
    count: PropTypes.number.isRequired,
    type: PropTypes.string,
    users: PropTypes.array
};

Messages.defaultProps = {
    count: 10,
    type: 'messages',
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        userStatus: state.userStatus.userStatus,
        docks: state.docks,
        users: state.users.users,
        email: state.auth.email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
