import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const updateIssue = (issue, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/issues/${issue.id}`,
        method: 'PUT',
        data: {issue}
    };

    apiCall("Updating issue", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};