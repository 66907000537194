import React, {useState} from "react";
import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";

const NewTabModal = ({onHide, onSave, existingTabsNames}) => {
    const [name, setName] = useState("")
    const [error, setError] = useState(false)

    return <Modal
        show={true}
        onHide={onHide}
        className="heightAuto maxHeight90 modalLeadsFilterBody fontIOS"
        id="new-holiday-tab-modal"
    >
        <Modal.Header closeButton>
            <Modal.Title>Add New Tab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <ControlLabel>
                            Name
                        </ControlLabel>
                        <FormControl
                            name="name"
                            placeholder="Name"
                            className={error ? "invalid" : ""}
                            onChange={e => {
                                setName(e.target.value)
                                setError(false)
                            }}
                            value={name}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle="info"
                onClick={() => onHide()}
            >
                Cancel
            </Button>
            <Button
                bsStyle="success"
                onClick={() => {
                    if (name && name.length > 0 && !existingTabsNames.includes(name.toLowerCase())) {
                        onSave(name)
                        onHide()
                    } else {
                        setError(true)
                    }
                }}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

export default NewTabModal;