import React, {useEffect, useState} from 'react'
import {Button, Col, Nav, NavItem, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import * as actions from './UnfinishedRegistrationApi'
import {connect} from "react-redux";
import PageHeader from "../../../components/PageTitle";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import './UnfinishedRegistration.scss'
import {dateFormatter} from "../../../components/BootStrapTableCellFormatters";

const isMobile = window.screen.width <= 1024;

const UnfinishedRegistration = ({actions}) => {
    const [unfinishedRegistrations, setUnfinishedRegistrations] = useState([])
    const [pagination, setPagination] = useState({page: 1, per_page: 25})
    const [sorting, setSorting] = useState({sort: 'desc', sort_by: 'updated_at'})
    const [total, setTotal] = useState(0)

    const loadUnfinishedRegistrations = () => actions.getUnfinishedRegistrations(pagination, sorting, res => {
        setUnfinishedRegistrations(res.unfinished_registrations)
        setTotal(res.total)
    })


    useEffect(() => {
        loadUnfinishedRegistrations()
    }, [])

    useEffect(() => {
        if (pagination.page === 0) {
            setPagination({...pagination, page: 1})
            loadUnfinishedRegistrations()
        } else {
            loadUnfinishedRegistrations()
        }
    }, [pagination, sorting])

    const renderSizePerPageDropDown = (props) => {
        return (
            <div className="btn-group dropup">
                <Button bsStyle="info dropdown-toggle" id="dropdownMenu2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{props.currSizePerPage}</span>
                    <span className="ml-6 caret"></span>
                </Button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {[5, 10, 25, 50].map(item =>
                        <li>
                            <a
                                className='table-pagination dropdown-item'
                                onClick={() => props.changeSizePerPage(item)}
                            >{item}</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    return (<div id='unfinishedRegistrations'>
        <PageHeader pageName="Unfinished Registrations" pageDetail={`Treehub`}/>
        <Col md={12} className="remaining vertical-responsive-height no-padding"
             style={{width: isMobile ? "90vw" : ""}}>
            <BootstrapTable
                data={unfinishedRegistrations}
                pagination={true}
                striped={true}
                bordered={false}
                hover={true}
                className="wrapped word-wrap"
                remote
                fetchInfo={{dataTotalSize: total}}
                options={{
                    onPageChange: (page, per_page) => {
                        setPagination({page, per_page})
                    },
                    sizePerPageDropDown: renderSizePerPageDropDown,
                    page: pagination.page,
                    sizePerPage: pagination.per_page,
                    sortName: sorting.sort_by,
                    sortOrder: sorting.sort,
                    onSortChange: (sort_by, sort) => {
                        setSorting({sort, sort_by})
                    }
                }}
            >
                <TableHeaderColumn hidden isKey dataField="id">
                    Id
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="name" width={isMobile ? '150' : "10%"}>
                    Name
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="email_address" width={isMobile ? '150' : "10%"}>
                    Email address
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="phone_number" width={isMobile ? '150' : "10%"}>
                    Phone number
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="address_1" width={isMobile ? '150' : "10%"}>
                    Address
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="city" width={isMobile ? '150' : "10%"}>
                    City
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="state" width={isMobile ? '150' : "10%"}>
                    State
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="zip" width={isMobile ? '150' : "10%"}>
                    Zip
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="country" width={isMobile ? '150' : "10%"}>
                    Country
                </TableHeaderColumn>
                <TableHeaderColumn dataSort dataFormat={dateFormatter} dataField="updated_at"
                                   width={isMobile ? '150' : "10%"}>
                    Last registration attempt
                </TableHeaderColumn>
            </BootstrapTable>
        </Col>
    </div>);
};

const mapStateToProps = state => ({
    name: state.auth.name,
    client: state.client.customerInfo,
})

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch),})

export default connect(mapStateToProps, mapDispatchToProps)(UnfinishedRegistration)