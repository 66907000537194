import { EDITING_EMPLOYEE_ID, EDIT_EMPLOYEE_FILTER_TEXT } from './constants'

import { fetchEmployee, saveEmployee, fetchEmployeeList } from '../../actions/employees'
import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {UPDATE_ROLES} from "../LoginPage/constants";

export const loadEmployeeForEditing = (editingEmployeeId, callback) => dispatch => {
    dispatch({ type: EDITING_EMPLOYEE_ID, editingEmployeeId })

    switch (editingEmployeeId) {
        case null:
            console.log('Not editing any employee')
            break
        case 0:
            console.log('This employee id is 0, not going to fetch it!')
            break
        default:
            console.log('This employee id is FOUND and we are winning!')
            dispatch(fetchEmployee(editingEmployeeId, callback))
    }
}

export const saveOrCreateEmployee = (id, callback) => (dispatch, getState) => {
    dispatch(saveEmployee(id)).then(async response => {
        await callback && callback(response.data)
        dispatch(loadEmployeeForEditing(response.data.id)) //for paranoia
        dispatch(fetchEmployeeList())
    })
}

export const loadEmployeesSettings = (callback) => (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/employees/get_settings`
    };

    apiCall("Getting employees settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const saveEmployeesSettings = (data, callback) => (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/employees/save_settings`,
        data: {settings: data}
    };

    apiCall("Saving employees settings", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const editFilterText = filterText => {
    return { type: EDIT_EMPLOYEE_FILTER_TEXT, filterText }
}
export const checkIfHaveRedirects = (employee_id, callback) => (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/employees/check_if_have_redirects`,
        params: {employee_id: employee_id}
    };

    apiCall("Getting employees settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const requestPasswordChange = (id, type = null) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/employees/request_password_change`,
        data: {id, type}
    };

    apiCall(type ? "Send Invitation" : "Request password change", config, async config => {
        const response = await axios.request(config);
    }, dispatch, getState);
};

export const updateRolesInState = (roles) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_ROLES,
        roles: roles
    });
};

export const saveSignature = (user_id, signature) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/employees/save_signature`,
        data: {user_id, signature}
    };

    apiCall("Saving signature", config, async config => {
        const response = await axios.request(config);
    }, dispatch, getState);
}
