import React from "react";
import {Pie} from 'react-chartjs-2';
import './CustomerSalesChart.css'

export default class CustomerSalesChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {}
        }
    }

    componentDidMount() {
        this.setState ({
            data: {
                labels: this.props.labels,
                datasets: [{
                    label: this.props.labels,
                    backgroundColor: [
                        "#3a87ad",
                        "#468847",
                    ],
                    borderColor: [
                        "#3a87ad",
                        "#468847",
                    ],
                    data: this.props.series,
                    datalabels: {
                        formatter: (value, ctx) => {
                            let sum = 0;
                            let dataArray = ctx.chart.data.datasets[0].data;
                            dataArray.map(data => {
                                sum += data;
                            });
                            let percentage = (value * 100 / sum).toFixed(0);
                            return percentage!=="0" ? percentage + "%" : '';
                        },
                        color: '#fff',
                    }
                }],
            },
            options: {
                animation: false,
                maintainAspectRatio: false,
                responsive: true,
                tooltips: {
                    enabled: false
                },
                rotation: (0.833 * Math.PI)
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.series !== this.props.series){
            this.setState({
                data: {
                    datasets: [{...this.state.data.datasets[0], data: this.props.series}]
                }
            })
        }
    }

    render() {
        const {data, options} = this.state
        const noData = data?.datasets?.length>0 && data?.datasets[0]?.data?.length>0
            && data?.datasets[0]?.data[0] === 0 && data?.datasets[0]?.data[1] === 0
        return (
            <div className={noData ? "chart vhCenter" : "chart"}>
                {noData ?
                    <b>No data for chart</b> :
                    <Pie data={data} options={options}/>}
            </div>)
    }
}
