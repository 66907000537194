import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, Grid, Panel, Row} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import * as api from "./IssuesViewApi";
import ResourceComponent from "../../components/ResourceComponent";
import {showEmailDialog} from "../../components/email/actions"
import {showTaskDialog} from "../../containers/AppWrapper/contents/actions";
import {dateFormatter} from "../../components/BootStrapTableCellFormatters";
import './IssuesView.css'

const isMobile = window.screen.width <= 1024;
const actions = {...api, showEmailDialog, showTaskDialog};

class IssuesView extends ResourceComponent {
    state = {
        resource: {
            sort: 'ASC',
            sort_by: 'created_at',
            page: 1,
            per_page: 10,
        },
        issues: []
    };

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        const {resource} = this.state;
        this.props.actions.loadIssues({...resource}, results => {
            this.setState({issues: results.content, total: results.total, loaded: false});
        });
    };

    rowFormatter = (cell, row) => {
        return <div>
            <Row className={"padding-top-bottom-4"}>
                <Col xs={6}>Title</Col>
                <Col xs={6}>{row.title}</Col>
            </Row>
            <Row className={"padding-top-bottom-4"}>
                <Col xs={6}>Description</Col>
                <Col xs={6}>{row.description}</Col>
            </Row>
            <Row className={"padding-top-bottom-4"}>
                <Col xs={6}>Created At</Col>
                <Col xs={6}>{dateFormatter(row.created_at)}</Col>
            </Row>
            <Row className={"padding-top-bottom-4"}>
                <Col xs={6}>Updated At</Col>
                <Col xs={6}>{dateFormatter(row.updated_at)}</Col>
            </Row>
            <Row className={"padding-top-bottom-4"}>
                <Col xs={6}>Created By</Col>
                <Col xs={6}>{row.person_full_name}</Col>
            </Row>
            <Row className={"padding-top-bottom-4"}>
                <Col xs={6}>Status</Col>
                <Col xs={6}>{row.status}</Col>
            </Row>
            <Row className={"padding-top-bottom-4"}>
                <Col xs={6}>Comment</Col>
                <Col xs={6}>{row.comment}</Col>
            </Row>
        </div>
    };

    render() {
        const {
            total, resource, issues
        } = this.state;
        const {page, per_page, sort, sort_by} = resource;
        return (
            <Grid fluid id='issue-view'>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter">
                        <h2 className="no-top-margin small-margin">Issues</h2>
                        <h5 className="text-primary">{total} returned</h5>
                    </Col>
                    <Col md={2}/>
                </Row>
                <Row className='top10'>
                    <Panel
                        collapsible
                        expanded={false}
                    >
                    </Panel>
                </Row>
                <Row>
                    {isMobile ?
                        <Col md={12} className="remaining vertical-responsive-height">
                            <BootstrapTable
                                ref="table"
                                data={issues}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={true}
                                remote
                                fetchInfo={{dataTotalSize: total}}
                                className="wrapped break-word-issue"
                                trClassName={this.selectedMarkerClass}
                                options={
                                    {
                                        onPageChange: (page, per_page) => {
                                            let {resource} = this.state;
                                            resource = {...resource, page, per_page};
                                            this.setState({resource}, this.reload);
                                        },
                                        page,
                                        sizePerPage: per_page,
                                        sortName: sort_by,
                                        sortOrder: sort,
                                        onSortChange: (sort_by, sort) => {
                                            let {resource} = this.state;
                                            resource = {...resource, sort_by, sort};
                                            this.setState({resource}, this.reload);
                                        }
                                    }
                                }
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataFormat={this.rowFormatter}
                                >
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        :
                        <Col md={12} className="remaining vertical-responsive-height">
                            <BootstrapTable
                                ref="table"
                                data={issues}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={true}
                                remote
                                fetchInfo={{dataTotalSize: total}}
                                className="wrapped break-word-issue"
                                trClassName={this.selectedMarkerClass}
                                options={
                                    {
                                        sizePerPageDropDown: this.renderSizePerPageDropDown,
                                        onPageChange: (page, per_page) => {
                                            let {resource} = this.state;
                                            resource = {...resource, page, per_page};
                                            this.setState({resource}, this.reload);
                                        },
                                        sizePerPageList: [{
                                            text: '10', value: 10
                                        }, {
                                            text: '25', value: 25
                                        },
                                            {
                                                text: '30', value: 30
                                            },
                                            {
                                                text: '50', value: 50
                                            },
                                            {
                                                text: '100', value: 100
                                            },
                                            {
                                                text: '200', value: 200
                                            },
                                            {
                                                text: 'All', value: total
                                            }],
                                        page,
                                        sizePerPage: per_page,
                                        sortName: sort_by,
                                        sortOrder: sort,
                                        onSortChange: (sort_by, sort) => {
                                            let {resource} = this.state;
                                            resource = {...resource, sort_by, sort};
                                            this.setState({resource}, this.reload);
                                        }
                                    }
                                }
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    width={"5%"}
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataField="title"
                                    dataSort
                                    width={"10%"}
                                >
                                    Title
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="description"
                                    dataSort
                                    width={"10%"}
                                >
                                    Description
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="created_at"
                                    dataFormat={dateFormatter}
                                    dataSort
                                    width={"10%"}
                                >
                                    Created At
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="updated_at"
                                    dataFormat={dateFormatter}
                                    dataSort
                                    width={"10%"}
                                >
                                    Updated At
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="person_full_name"
                                    dataSort
                                    width={"10%"}
                                >
                                    Created By
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="status"
                                    dataSort
                                    width={"10%"}
                                >
                                    Status
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="comment"
                                    dataSort
                                    width={"10%"}
                                >
                                    Comment
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>}
                </Row>
            </Grid>
        );
    }
}

IssuesView.propTypes = {
    //myProp: PropTypes.object.isRequired
};

IssuesView.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuesView);