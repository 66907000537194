import React from 'react'
import {Button, Col, Grid} from "react-bootstrap";
import Helmet from "react-helmet";
import PageTitle from "../../components/PageTitle";
import Logo from "../HomePage/HomePageLogo.png";
import {browserHistory} from "react-router";

export const CustomerViewLogout = () => {

    return (
        <Grid fluid>
            <Helmet title="Logout"/>
            <PageTitle pageLogo={Logo} pageName="TreeHub" pageDetail="logout"/>
            <Col xs={12} md={6} mdOffset={3} className='text-center'>
                <h1>You’ve been successfully logged out</h1>
                <Button className="mt-15" bsStyle="success" onClick={() => browserHistory.push(`/`)}>
                    Login
                </Button>
            </Col>
        </Grid>
    )


}


export default CustomerViewLogout;