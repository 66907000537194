import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";
import {addAlert} from "../../App/actions";

export const getService = (service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_services/get_service`,
        params: {id: service_id}
    };

    apiCall("Searching for service", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getQbService = (service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/get_service`,
        params: {id: service_id}
    };

    apiCall("Searching for quickbooks service", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForService = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_services/search`,
        params: {q: searchText}
    };

    apiCall("Searching for services", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForQbService = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/search_for_service`,
        params: {search_text: searchText}
    };

    apiCall("Searching for quickbooks services", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const syncServices = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_services`,
        params: filter
    };

    apiCall("Synchronizing services", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const updateService = (services, direction, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_service`,
        method: 'PUT',
        data: {data: {...services}, direction: direction}
    };

    apiCall("Updating service", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, true, errorCallback);

};

export const synchronizeManyServices = (services, direction, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_many_services`,
        method: 'PUT',
        data: {services, direction}
    };

    apiCall("Updating services", config, async config => {
        const response = await axios.request(config);
        callback && callback()
        addAlert({
            message: response.data.message,
            mood: "success",
            dismissAfter: 3500,
        })
    }, dispatch, getState);
};

export const disassociateService = (id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/disassociate_service`,
        method: 'PUT',
        params: {id}
    };

    apiCall("Disassociate service", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};