import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Glyphicon, Grid, OverlayTrigger, Panel, Row, Tooltip} from "react-bootstrap";
import * as api from "./BacklogApi";
import ResourceComponent from "../../components/ResourceComponent";
import {showEmailDialog} from "../../components/email/actions"
import {browserHistory} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import Select from "react-select";
import {defaultDateFormat, select} from "../../common/commonHandlers";
import buildURL from 'axios/lib/helpers/buildURL';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import Dollars from "../../components/Dollars";
import moment from "moment";
import ReactDock from "react-dock";
import {LinkContainer} from "react-router-bootstrap";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

const actions = {...api, showEmailDialog};
const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;
const queryString = require('query-string');

class Backlog extends ResourceComponent {

    search = (uri) => {
        let {resource, reports_settings} = this.state;
        resource.reports = []
        if (uri && uri.from && uri.to) {
            resource.from = uri.from
            resource.to = uri.to
        }
        const url = buildURL('/backlog', resource);
        this.setState({resource: resource, visibleColumns: reports_settings.backlog.columns}, () => {
            this.props.actions.loadReport(resource, results => {
                resource.reports = results.reports;

                resource.reports.map(report => {
                    report.single_report.push(this.createTotal(report.single_report));
                });
                this.setState({main_report: results.main_report, reports: results.reports}, () => {
                    browserHistory.push(url);
                });
            });
        });
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            main_report: {},
            reports: [],
            service_types: [],
            resource: {
                label: null
            },
            filterVisible: true,
            dateFilterOptions: ['Custom', 'Today', 'Tomorrow', 'This Week', 'Rest of this week', 'This Month', 'Rest of this month', 'This Quarter', 'Rest of this quarter', 'This Year', 'Rest of this year', 'Next Week', 'Next Month', 'Next Quarter', 'Next Year', 'Next 30 days', 'Next 60 days', 'Next 90 days', 'Next 180 days'],
            employees: [],
            customFilter: false,
            showDock: false,
            visibleColumns: [],
            columnOptions: ['Scheduled', 'Scheduled Hours', 'Promised', 'Promised Hours', 'Total', 'Total Hours'],
            defaultFilter: 'Last Month',
            reports_settings: {}
        };
    }

    componentDidMount() {
        let {resource, customFilter, customDockFilter} = this.state;
        const url = buildURL('/backlog', resource);
        const parsed = this.props.location && queryString.parse(this.props.location.search);
        this.props.actions.load(result => this.setState(result, () => {
            if (Object.keys(parsed) && Object.keys(parsed).length > 0) {
                resource.from = parsed['from'] && moment(parsed['from']);
                resource.to = parsed['to'] && moment(parsed['to']);
                resource.label = parsed['label'] && parsed['label'];
                resource.service_type = parsed['service_type'] && +parsed['service_type'];
                resource.arborist_id = parsed['arborist_id'] && +parsed['arborist_id'];
                customFilter = parsed['label'] === 'Custom';

            } else {
                if (result.reports_settings.backlog.default_filter === 'Custom') {
                    resource.from = result.reports_settings.backlog.from;
                    resource.to = result.reports_settings.backlog.to;
                }
                resource.label = result.reports_settings && result.reports_settings.backlog.default_filter;
                customDockFilter = result.reports_settings.backlog.default_filter === 'Custom';
                customFilter = result.reports_settings.backlog.default_filter === 'Custom';
            }
            this.setState({resource: resource, customFilter: customFilter, customDockFilter: customDockFilter}, () => {
                this.props.actions.loadReport(resource, results => {
                    this.setState({main_report: results.main_report, reports: results.reports}, () => {
                        browserHistory.push(url);
                    });
                });
                this.search(parsed);
            });

        }));
    }

    renderDescriptionTooltip = (description) => (
        <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                        overlay={this.renderTooltip(description)}>
            <FontAwesomeIcon icon={faInfoCircle} className={isMobile ? "ml-6 font12 pointer" : "ml-6 font14 pointer"}/>
        </OverlayTrigger>
    )

    rowFormatter = (cell, row) => {
        const {visibleColumns} = this.state;
        return <div>
            <Row>
                <Col xs={6}>Rep</Col>
                <Col xs={6}>{row.person_full_name}</Col>
            </Row>
            {visibleColumns.includes('Scheduled') &&
            <Row>
                <Col xs={6}>$ Scheduled</Col>
                <Col xs={6}><Dollars amount={row.scheduled}/></Col>
            </Row>}
            {visibleColumns.includes('Scheduled Hours') &&
            <Row>
                <Col xs={6}>Scheduled Hours</Col>
                <Col xs={6}>{row.scheduled_hours}</Col>
            </Row>}
            {visibleColumns.includes('Promised') &&
            <Row>
                <Col xs={6}>$ Promised</Col>
                <Col xs={6}><Dollars amount={row.promised}/></Col>
            </Row>}
            {visibleColumns.includes('Promised Hours') &&
            <Row>
                <Col xs={6}>Promised Hours</Col>
                <Col xs={6}>{row.promised}</Col>
            </Row>}
            {visibleColumns.includes('Total') &&
            <Row>
                <Col xs={6}>$ Total</Col>
                <Col xs={6}><Dollars amount={row.total}/></Col>
            </Row>}
            {visibleColumns.includes('Total Hours') &&
            <Row>
                <Col xs={6}>Total Hours</Col>
                <Col xs={6}>{row.total_hours}</Col>
            </Row>}
        </div>
    };

    handleButtonsFilterChange = e => {
        let {resource} = this.state;
        if (e.value === 'Custom') {
            this.setState({customFilter: true}, () => {
                resource.label = e.value;
                resource.from = moment().startOf('month')
                resource.to = moment().endOf('month')
                this.setState({resource}, this.search);
            });
        } else {
            this.setState({customFilter: false}, () => {
                resource.label = e.value;
                resource.from = null;
                resource.to = null;
                this.setState({resource}, this.search);
            });
        }
    };

    saveReportSettings = () => {
        const {reports_settings} = this.state;
        this.props.actions.saveFilter(reports_settings, this.search)
    };

    handleDefaultFilterChange = e => {
        let {reports_settings} = this.state;
        reports_settings.backlog.default_filter = e.value;
        this.setState({reports_settings, customDockFilter: e.value === 'Custom'}, this.saveReportSettings);
    };

    handleDefaultFilterFromChange = e => {
        let {reports_settings} = this.state;
        reports_settings.backlog.from = moment(e);
        this.setState(reports_settings, this.saveReportSettings);
    };

    handleDefaultFilterToChange = e => {
        let {reports_settings} = this.state;
        reports_settings.backlog.to = moment(e);
        this.setState(reports_settings, this.saveReportSettings);
    };

    handleColumnOptionsChange = e => {
        let {visibleColumns, reports_settings} = this.state;
        reports_settings.backlog.columns = e.map(x => x.value);
        visibleColumns = e.map(x => x.value);
        this.setState({visibleColumns: visibleColumns, reports_settings: reports_settings}, this.saveReportSettings);
    };

    personFormatter = (cell, row) => {
        const {resource} = this.state;
        const customFilterUrl = `/backlog/${row.person_id}?label=${resource.label}&from=${resource.from}&to=${resource.to}`
        const url = `/backlog/${row.person_id}?label=${resource.label}`

        return row.person_full_name === 'Total' ? <span>{cell}</span> :
            <LinkContainer to={`${resource.label === "Custom" ? customFilterUrl : url}`}>
                <span className={'text-blue pointer'}>{cell}</span>
            </LinkContainer>
    };

    personFormatterWithType = (cell, row) => {
        const {resource} = this.state;
        const customFilterUrl = `/backlog/${row.person_id}?service_type=${row.service_type}&label=${resource.label}&from=${resource.from}&to=${resource.to}`
        const url = `/backlog/${row.person_id}?service_type=${row.service_type}&label=${resource.label}`

        return row.person_full_name === 'Total' ? <span>{cell}</span> : <LinkContainer
            to={`${resource.label === "Custom" ? customFilterUrl : url}`}>
            <span className={'text-blue pointer'}>{cell}</span>
        </LinkContainer>
    };

    createTotal = (data) => {
        return {
            person_full_name: 'Total',
            scheduled: data.reduce((a, b) => a + (+b['scheduled'] || 0), 0).toFixed(2),
            scheduled_hours: data.reduce((a, b) => a + (+b['scheduled_hours'] || 0), 0),
            promised: data.reduce((a, b) => a + (+b['promised'] || 0), 0).toFixed(2),
            promised_hours: data.reduce((a, b) => a + (+b['promised_hours'] || 0), 0),
            total: data.reduce((a, b) => a + (+b['total'] || 0), 0).toFixed(2),
            total_hours: data.reduce((a, b) => a + (+b['total_hours'] || 0), 0)
        }
    };

    hoursConverter = (hour, decimalPlaces) => {
        if (hour && hour.toString().includes(".")) {
            hour = parseFloat(hour)
            return hour.toFixed(decimalPlaces)
        }
        return hour
    }

    renderTooltip = (description) => (
        <Tooltip id="tooltip">
            {Array.isArray(description) ?
                <div className="columnDirection text-left">
                    <p className={smMobile ? "bottom15 text-orange font10" : "bottom15 text-orange font12"}>{description[0].title}</p>
                    {description[0] && description[0].cases.map((desc_case, i) => (
                        <li className={smMobile ? "list_style_none bottom10 font10" : "list_style_none bottom10 font12"}>
                            <span className="mr-5">#{i + 1}</span>
                            <span>{desc_case}</span>
                        </li>
                    ))}
                </div>
                : isMobile ?
                    <div>
                        {Object.entries(description).map(x => (
                            <div className="text-left bottom5">
                                <p className={smMobile ? "text-left bottom5 text-orange font10" : "text-left bottom5 text-orange font12"}>{x[0].charAt(0).toUpperCase() + x[0].slice(1)}</p>
                                <span className={smMobile ? "text-left font10" : "text-left font12"}>{x[1]}</span>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="text-left font12">
                        {description}
                    </div>
            }
        </Tooltip>
    );

    render() {
        const {
            filterVisible, resource, dateFilterOptions, customFilter, customDockFilter, employees, main_report, service_types, reports, showDock, columnOptions, visibleColumns, reports_settings
        } = this.state;
        let dateOptionsSelect = dateFilterOptions.map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });
        let columnOptionsSelect = columnOptions.map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });
        const defaultFilter = reports_settings.backlog && reports_settings.backlog.default_filter;

        if (main_report && main_report.length > 0) {
            main_report.forEach(report => {
                report.promised_hours = this.hoursConverter(report.promised_hours, 2)
                report.scheduled_hours = this.hoursConverter(report.scheduled_hours, 2)
                report.total_hours = this.hoursConverter(report.total_hours, 2)
            })
        }

        if (reports && reports.length > 0) {
            reports.forEach(report => {
                report.single_report.forEach(r => {
                    r.promised_hours = this.hoursConverter(r.promised_hours, 2)
                    r.scheduled_hours = this.hoursConverter(r.scheduled_hours, 2)
                    r.total_hours = this.hoursConverter(r.total_hours, 2)
                })
            })
        }

        const columnDescription = {
            rep: 'Shows Proposals No with work scheduled or with promised dates within date range',
            scheduled: 'Total $ amount scheduled within filter date range (in Scheduled, Invoiced and Paid status)',
            scheduledHrs: 'Total hours of work scheduled within filter date range',
            promised: 'Total $ amount of work not yet scheduled but In Work Order or Accepted status with promised dates within filter date range',
            promisedHrs: 'Total hours of work not yet scheduled but In Work Order or Accepted status with promised dates within filter date range',
            total: 'Total amount of work Scheduled and Promised',
            totalHrs: 'Total hours of work Scheduled and Promised',
        }

        const mainViewDescription = [{
            title: 'Backlog view shows records when one of the cases is true:',
            cases: [
                'Scheduler_event date_from/date_to covers the searched range',
                'Promised_dates from/to covers the searched range and proposal_service is in accepted status',
                'Promised_dates from/to covers the searched range and proposal_service is in in_work_order status'
            ]
        }]

        return (
            <Grid fluid>
                <Row className="vcenter">
                    <Col md={6} className="vcenter full-width">
                        <h2 className={smMobile ? "no-top-margin" : "no-top-margin mr-9"}>Backlog</h2>
                        <span>Work that is scheduled or promised</span>
                        <span>{this.renderDescriptionTooltip(mainViewDescription)}</span>
                    </Col>
                    <Col md={6} className={smMobile ? "d-flex mt-20" : "justify-flex-end full-width"}>
                        <Col className={smMobile ? "text-left no-right-padding no-left-padding" : "text-left"}>
                            <Button
                                className="pointer no-top-margin mr-7"
                                bsStyle="warning"
                                onClick={() => {
                                    this.setState({filterVisible: !filterVisible})
                                }}>
                                Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="pointer no-top-margin"
                                bsStyle="warning"
                                onClick={() => {
                                    this.setState({showDock: !showDock})
                                }}>
                                Customize
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Panel
                        collapsible
                        expanded={filterVisible}
                    >
                        <Row>
                            <Col md={2} className={`bottom10`}>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(dateOptionsSelect, resource.label)}
                                        options={dateOptionsSelect}
                                        placeholder="Date"
                                        onChange={this.handleButtonsFilterChange}
                                />
                            </Col>
                            {
                                customFilter &&
                                <Col md={4} className={`small-right-padding`}>
                                    <Datetime
                                        dateFormat={defaultDateFormat}
                                        isClearable
                                        timeFormat={null}
                                        value={moment(resource.from)}
                                        className="half small-margin"
                                        inputProps={{placeholder: 'From'}}
                                        onChange={this.dateResourceAttr('from', this.search)}/>
                                    <Datetime
                                        dateFormat={defaultDateFormat}
                                        isClearable
                                        timeFormat={null}
                                        value={moment(resource.to)}
                                        className="half small-margin"
                                        inputProps={{placeholder: 'To'}}
                                        onChange={this.dateResourceAttr('to', this.search)}
                                    />
                                </Col>
                            }
                            <Col md={2} className={isMobile && 'bottom10'}>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(employees, resource.arborist_id)}
                                        options={employees}
                                        isMulti
                                        isClearable
                                        onChange={this.selectResourceAttr('arborist_id', this.search)}
                                        placeholder="Arborist"
                                />
                            </Col>
                            <Col md={2}>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(service_types, resource.service_type)}
                                        options={service_types}
                                        isClearable
                                        onChange={this.selectResourceAttr('service_type', this.search)}
                                        placeholder="Service Type"
                                />
                            </Col>
                        </Row>

                    </Panel>
                </Row>
                <Row>
                    {isMobile ?
                        <Col xs={12}>
                            <div className="vhCenter">
                                <h4 className='text-center'>All</h4>
                                {this.renderDescriptionTooltip(columnDescription)}
                            </div>
                            <BootstrapTable
                                ref="table"
                                data={main_report && main_report.length > 0 && main_report}
                                striped={true}
                                bordered={false}
                                hover={true}
                                remote
                                className="wrapped"
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataFormat={this.rowFormatter}
                                >
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        :
                        <Col xs={12}>
                            <div className="textCenter">
                                <h4 className='text-center'>All</h4>
                            </div>
                            <BootstrapTable
                                ref="table"
                                data={main_report && main_report.length > 0 && main_report}
                                striped={true}
                                bordered={false}
                                hover={true}
                                remote
                                className="wrapped"
                            >
                                <TableHeaderColumn
                                    dataField="person_full_name"
                                    dataFormat={this.personFormatter}
                                    width={"10%"}
                                    isKey
                                >
                                    Rep
                                    {this.renderDescriptionTooltip(columnDescription.rep)}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="scheduled"
                                    hidden={!visibleColumns.includes('Scheduled')}
                                    dataFormat={dollarsFormatter}
                                    width={"10%"}
                                >
                                    $ Scheduled
                                    {this.renderDescriptionTooltip(columnDescription.scheduled)}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="scheduled_hours"
                                    hidden={!visibleColumns.includes('Scheduled Hours')}
                                    width={"10%"}
                                >
                                    Scheduled Hours
                                    {this.renderDescriptionTooltip(columnDescription.scheduledHrs)}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="promised"
                                    dataFormat={dollarsFormatter}
                                    hidden={!visibleColumns.includes('Promised')}
                                    width={"10%"}
                                >
                                    $ Promised
                                    {this.renderDescriptionTooltip(columnDescription.promised)}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="promised_hours"
                                    hidden={!visibleColumns.includes('Promised Hours')}
                                    width={"10%"}
                                >
                                    Promised Hours
                                    {this.renderDescriptionTooltip(columnDescription.promisedHrs)}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total"
                                    dataFormat={dollarsFormatter}
                                    hidden={!visibleColumns.includes('Total')}
                                    width={"10%"}
                                >
                                    $ Total
                                    {this.renderDescriptionTooltip(columnDescription.total)}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_hours"
                                    hidden={!visibleColumns.includes('Total Hours')}
                                    width={"10%"}
                                >
                                    Total Hrs
                                    {this.renderDescriptionTooltip(columnDescription.totalHrs)}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>}
                </Row>
                {Object.keys(reports) && Object.keys(reports).length > 0 && Object.keys(reports).map(function (key, index) {
                        return <Row>
                            {isMobile ?
                                <Col xs={12} key={index}>
                                    <h4 className='text-center'>{reports[key].service_type}</h4>
                                    <BootstrapTable
                                        ref="table"
                                        data={reports[key].single_report}
                                        striped={true}
                                        bordered={false}
                                        hover={true}
                                        remote
                                        className="wrapped"
                                    >
                                        <TableHeaderColumn
                                            dataField="id"
                                            hidden
                                            isKey
                                        />
                                        <TableHeaderColumn
                                            dataFormat={this.rowFormatter}
                                        >
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>
                                :
                                <Col xs={12} key={index}>
                                    <h4 className='text-center'>{reports[key].service_type}</h4>
                                    <BootstrapTable
                                        ref="table"
                                        data={reports[key].single_report}
                                        striped={true}
                                        bordered={false}
                                        hover={true}
                                        remote
                                        className="wrapped"
                                    >
                                        <TableHeaderColumn
                                            dataField="person_full_name"
                                            dataFormat={this.personFormatterWithType}
                                            width={"10%"}
                                            isKey
                                        >
                                            Rep
                                            {this.renderDescriptionTooltip(columnDescription.rep)}
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="scheduled"
                                            hidden={!visibleColumns.includes('Scheduled')}
                                            dataFormat={dollarsFormatter}
                                            width={"10%"}
                                        >
                                            $ Scheduled
                                            {this.renderDescriptionTooltip(columnDescription.scheduled)}
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="scheduled_hours"
                                            hidden={!visibleColumns.includes('Scheduled Hours')}
                                            width={"10%"}
                                        >
                                            Scheduled Hours
                                            {this.renderDescriptionTooltip(columnDescription.scheduledHrs)}
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="promised"
                                            hidden={!visibleColumns.includes('Promised')}
                                            dataFormat={dollarsFormatter}
                                            width={"10%"}
                                        >
                                            $ Promised
                                            {this.renderDescriptionTooltip(columnDescription.promised)}
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="promised_hours"
                                            hidden={!visibleColumns.includes('Promised Hours')}
                                            width={"10%"}
                                        >
                                            Promised Hours
                                            {this.renderDescriptionTooltip(columnDescription.promisedHrs)}
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            hidden={!visibleColumns.includes('Total')}
                                            dataFormat={dollarsFormatter}
                                            width={"10%"}
                                        >
                                            $ Total
                                            {this.renderDescriptionTooltip(columnDescription.total)}
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total_hours"
                                            hidden={!visibleColumns.includes('Total Hours')}
                                            width={"10%"}
                                        >
                                            Total Hrs
                                            {this.renderDescriptionTooltip(columnDescription.totalHrs)}
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>}
                        </Row>
                    }.bind(this)
                )}
                <ReactDock position='right'
                           isVisible={showDock}
                           dimMode="none"
                           defaultSize={window.innerWidth > 990 ? 0.6 : 0.9}
                           dockStyle={{overflow: 'hidden'}}
                           zIndex={1031}
                >
                    <div className="close-dock">
                        <h4 className="margin10 pull-left">Customize Report</h4>
                        <div className="pull-right small-margin">
                            <Glyphicon glyph='remove' className="pointer margin10"
                                       onClick={() => this.setState({showDock: null})}/>
                        </div>
                    </div>
                    <div className="margin10 top50">
                        <h5 className='top80'>Set default report period</h5>
                        <Select className="Select"
                                classNamePrefix="small-select"
                                value={select(dateOptionsSelect, defaultFilter)}
                                options={dateOptionsSelect}
                                placeholder="Date"
                                onChange={this.handleDefaultFilterChange}
                        />
                        {
                            customDockFilter &&
                            <div className={`small-right-padding`}>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={moment(reports_settings.backlog.from)}
                                    className="half small-margin"
                                    inputProps={{placeholder: 'From'}}
                                    onChange={this.handleDefaultFilterFromChange}/>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={moment(reports_settings.backlog.to)}
                                    className="half small-margin"
                                    inputProps={{placeholder: 'To'}}
                                    onChange={this.handleDefaultFilterToChange}
                                />
                            </div>
                        }
                        <h5 className='top40'>Columns to show</h5>
                        <Select className="Select"
                                classNamePrefix="small-select"
                                value={select(columnOptionsSelect, visibleColumns)}
                                options={columnOptionsSelect}
                                isMulti
                                placeholder="Columns"
                                onChange={this.handleColumnOptionsChange}
                        />
                    </div>
                </ReactDock>
            </Grid>
        );
    }
}

Backlog.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Backlog.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Backlog);
