import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {addAlert} from "../App/actions"
import * as MyActions from "./NewWorkOrderPrintApi"
import {Button, Checkbox, Col, FormControl, FormGroup, Grid, Panel, Radio, Row, Well,} from "react-bootstrap"
import Helmet from "react-helmet"
import PageHeader from "../../components/PageTitle"
import {NewWorkOrderPrintOutput} from "./NewWorkOrderPrintOutput";
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import {fetchSiteMaps} from '../../actions/siteMaps';
import MDSpinner from "react-md-spinner";

const Actions = {...MyActions, addAlert, fetchSiteMaps};

const styles = {
    printWidget: {
        float: 'left',
        width: '175px',
        height: '120px',
        marginRight: '20px',
    },
    clearFix: {
        clear: 'both',
    },
}

class NewWorkOrderPrint extends Component {
    state = {
        work_order: {},
        include_maps: {},
        showLogoHeader: false,
        logoHeight: null,
        logoPosition: "center",
        downloadInProgress: false,
    };

    handleFormChange = e => {
        let value = {};
        if (e.target.name === 'Plant Health Care' || e.target.name === 'Tree Care') {
            value = {format: e.target.name};
            this.state.work_order.print_options['format'] = e.target.name;
        } else {
            let checked = e.target.type === "checkbox"
                ? e.target.checked
                : e.target.value === "true";
            value = {
                [e.target.name]: checked,
            };
            this.state.work_order.print_options[e.target.name] = checked;
        }
        this.setState({work_order: this.state.work_order});
        this.props.actions.updateWorkOrderPrintOptions(this.props.params.woId, value);
    };

    handleSaveSettings = () => {
        const {logoHeight, showLogoHeader, logoPosition, work_order} = this.state
        this.props.actions.saveLogoSettings(
            work_order.client_id, showLogoHeader, logoHeight, logoPosition)
    }

    renderPrintOptionsHeader = () => {
        const {workOrder, downloadInProgress} = this.state;
        const printOptions = workOrder && workOrder.print_options;
        return (printOptions && <Panel
                bsStyle="primary"
                collapsible
                defaultExpanded={true}
                header="Print Options"
                className="hidden-print well-filter"
            >
                <Row>
                    <Col xs={12}>
                        <p>
                            The Work Order can be customized before printing. Checked items are
                            included in the printout.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Well bsSize="small">
                            <h5>Format</h5>
                            <FormGroup>
                                <Radio
                                    checked={printOptions.format === 'Plant Health Care'}
                                    onChange={this.handleFormChange}
                                    name={'Plant Health Care'}
                                    inline
                                >
                                    Plant Health Care
                                </Radio>
                                <br/>
                                <Radio
                                    checked={printOptions.format === 'Tree Care'}
                                    onChange={this.handleFormChange}
                                    name={'Tree Care'}
                                    inline
                                >
                                    Tree Care
                                </Radio>
                            </FormGroup>
                        </Well>
                        <Well bsSize="small">
                            <h5>Assets</h5>
                            <FormGroup>
                                <Checkbox
                                    name="locationDescription"
                                    checked={printOptions.locationDescription}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Location Description
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="dbh"
                                    checked={printOptions.dbh}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    DBH
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="treeHazards"
                                    checked={printOptions.treeHazards}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Tree Hazards
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Notes</h5>
                            <FormGroup>
                                <Checkbox
                                    name="assetNotes"
                                    checked={printOptions.assetNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="siteHazards"
                                    checked={printOptions.siteHazards}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Site Hazards
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="jsa"
                                    checked={printOptions.jsa}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    JSA
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="serviceNotes"
                                    checked={printOptions.serviceNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="workOrderNotes"
                                    checked={printOptions.workOrderNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Work Order Notes
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Services</h5>
                            <FormGroup>
                                <Checkbox
                                    name="servicePriorities"
                                    checked={printOptions.servicePriorities}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Priorities
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Maps</h5>
                            <FormGroup>
                                <Checkbox
                                    name="maps"
                                    checked={printOptions.maps}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    <div>
                                        <div>Maps</div>
                                        <Checkbox
                                            name="fitBounds"
                                            checked={printOptions.fitBounds}
                                            disabled={!printOptions.maps}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Fit Bounds
                                        </Checkbox>
                                    </div>
                                </Checkbox>
                                {workOrder.site_maps.map((sitemap, i) => (
                                        <div key={sitemap.id}>
                                            <Checkbox
                                                name={`maps_${sitemap.id}`}
                                                checked={printOptions[`maps_${sitemap.id}`]}
                                                onChange={this.handleFormChange}
                                                inline
                                            >
                                                Sitemap {i + 1}
                                            </Checkbox>
                                        </div>
                                    )
                                )}
                                <h5>Include When Printing</h5>
                                <Checkbox
                                    name="printMap"
                                    checked={printOptions.printMap}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Main Map
                                </Checkbox>
                                {workOrder.site_maps.map((sitemap, i) => (
                                        <div key={sitemap.id}>
                                            <Checkbox
                                                name={`printMap${sitemap.id}`}
                                                checked={printOptions[`printMap${sitemap.id}`]}
                                                onChange={this.handleFormChange}
                                                inline
                                            >
                                                Sitemap {i + 1}
                                            </Checkbox>
                                        </div>
                                    )
                                )}
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Prices</h5>
                            <FormGroup>
                                <Checkbox
                                    name="proposalTotal"
                                    checked={printOptions.proposalTotal}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Proposal Total
                                </Checkbox>

                                <br/>
                                <Checkbox
                                    name="priceEachService"
                                    checked={printOptions.priceEachService}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Price for each Service
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Options</h5>
                            <FormGroup>
                                <Checkbox
                                    name="largeAssetPhotos"
                                    checked={printOptions.largeAssetPhotos}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Large Asset Photos
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="estimatedHours"
                                    checked={printOptions.estimatedHours}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Estimated Hours
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="promisedDates"
                                    checked={printOptions.promisedDates}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Promised Dates
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showLogoHeader"
                                    checked={this.state.showLogoHeader}
                                    onChange={() => this.setState({showLogoHeader: !this.state.showLogoHeader}, () => this.handleSaveSettings())}
                                    inline
                                >
                                    Show Company Logo
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showPageNumbers"
                                    checked={printOptions.showPageNumbers}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Page Numbers
                                </Checkbox>
                            </FormGroup>
                        </Well>
                        {this.state.showLogoHeader &&
                        <Well bsSize="small" style={styles.printWidget} className="panel-boxes">
                            <h5>Logo options</h5>
                            <FormGroup>
                                <FormControl
                                    name="logoHeight"
                                    type="text"
                                    placeholder="Height"
                                    value={this.state.logoHeight ? this.state.logoHeight : ''}
                                    onChange={e => this.setState({logoHeight: e.target.value})}
                                    onBlur={this.handleSaveSettings}
                                />
                                <Radio
                                    name="logoPosition"
                                    checked={this.state.logoPosition === 'left'}
                                    onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                    value={'left'}
                                    inline
                                >Left</Radio>
                                <br/>
                                <Radio
                                    name="logoPosition"
                                    checked={this.state.logoPosition === 'center'}
                                    onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                    value={'center'}
                                    inline
                                >Center</Radio>
                                <br/>
                                <Radio
                                    name="logoPosition"
                                    checked={this.state.logoPosition === 'right'}
                                    onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                    value={'right'}
                                    inline
                                >Right</Radio>
                                <br/>
                            </FormGroup>
                        </Well>}
                        <div>
                            <Button
                                bsSize="small"
                                disabled={downloadInProgress}
                                onClick={() => this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(workOrder,
                                    () => this.setState({downloadInProgress: false}),
                                    () => this.setState({downloadInProgress: false})
                                ))}
                                bsStyle="primary"
                            >
                                {downloadInProgress ? <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Download PDF'}
                            </Button>
                            <br/>
                            <Button
                                bsSize="small"
                                disabled={downloadInProgress}
                                onClick={() => window.print()}
                                bsStyle="primary"
                            >
                                Print
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Panel>
        )
    };

    componentDidMount() {
        const id = this.props.params.woId;
        if (id) {
            this.reload(id);
        }
    };

    reload(id) {
        this.props.actions.getWorkOrder(id, (result) => {
            let work_order = result;
            work_order.print_options = JSON.parse(result.print_options);
            this.setState({work_order: work_order}, () => {
            });
        });
    }

    render() {
        const {work_order} = this.state;
        if (!work_order) {
            return <div>Loading...</div>
        }
        return (
            <div>
                <Grid fluid style={{maxWidth: "100vw"}}>
                    <div className="hidden-print">
                        <ProposalMenu
                            id={work_order.proposal_id}
                            expandButton={false}
                        />
                    </div>
                    <Col md={12}>
                        <Helmet>
                            <title>{`WorkOrder #${work_order.work_order_no}`}</title>
                            <style type="text/css">
                                {`
                          @page {
                          size: 8.5in 11in;
                          margin: 0.25in 0.25in 0.25in 0.25in;
                          }
                       `}
                            </style>
                        </Helmet>
                    </Col>
                    <div className="hidden-print">
                        <PageHeader
                            pageName="Print Work Order"
                            pageDetail={`WorkOrder #${work_order.work_order_no}`}
                        />
                    </div>
                    {this.renderPrintOptionsHeader()}
                </Grid>
                <br/>
                <NewWorkOrderPrintOutput work_order={work_order && work_order}
                                         options={work_order && work_order.print_options}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        email: state.auth.email,
        customerSiteMaps: state.siteMaps.siteMaps
    }
};

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
};

export default connect(mapStateToProps, mapDispatchToProps)(NewWorkOrderPrint)
