import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";

const ConfirmProposalDeletionModal = ({onHide, onDelete}) => {
    const [deleteWithLead, setDeleteWithLead] = useState(false)

    return <Modal
        show={true}
        animation={true}
        className={"heightAuto maxHeight90 fontIOS"}
        id='confirm-proposal-deleted-modal'
        onHide={() => onHide()}
    >
        <Modal.Header>
            <Modal.Title>Confirm deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 className='text-danger'>Are you sure you want to delete this proposal?</h4>
            <Row>
                <Col xs={12}>
                    <ColorCheckbox
                        value={deleteWithLead}
                        label='Delete proposal and lead'
                        className='vertical-align'
                        onChange={() => setDeleteWithLead(!deleteWithLead)}
                    />
                    <ColorCheckbox
                        value={!deleteWithLead}
                        label='Delete proposal only'
                        className='vertical-align'
                        onChange={() => setDeleteWithLead(!deleteWithLead)}
                    />
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col md={6} className="text-left">
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                </Col>
                <Col md={6} className="text-right">
                    <Button
                        bsStyle="danger"
                        bsSize="small"
                        onClick={() => onDelete(deleteWithLead)}
                    >
                        Delete Proposal
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default ConfirmProposalDeletionModal;