import {FETCH_PRINT_INVOICE} from './constants'
import axios from 'axios'
import {addAlert} from "../App/actions"
import * as FileSaver from "file-saver";
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import {dataUpload} from "../../common/upload";

export const fetchMap = (url, token, callback) => {
    const config = {
        method: 'get',
        url,
        headers: {'Authorization': token},
        responseType: "blob"
    };

    axios.request(config)
        .then(response => {
            const reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function () {
                const imageDataUrl = reader.result;
                callback(imageDataUrl);
            }
        })
};

export const fetchInvoice = (invoiceToken, callback, forPdf = null) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${invoiceToken}/load_invoice${forPdf ? '?for_pdf=true' : ''}`,
    };

    apiCall("Fetching invoice", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
        dispatch({type: FETCH_PRINT_INVOICE, invoice: response.data})
    }, dispatch, getState, false);
};

export const addNote = (invoiceToken, note, callback) => async dispatch => {
    const config = {
        url: `/api/restricted/${invoiceToken}/add_note`,
        data: {
            note
        },
        method: 'PUT'
    };
    apiCall("Sending the message", config, async config => {
        const response = await axios.request(config);
        dispatch(getConversation(invoiceToken, list => callback && callback(response.data, list)));
    }, dispatch);
};

export const downloadPdf = (invoice, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${invoice.token}/invoice_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${invoice.client_name}-Invoice #${invoice.number}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(invoice ? invoice : {}), source: "invoice pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const downloadWOPdf = (wo_token, work_order_no, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${wo_token}/wo_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `WO #${work_order_no}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify({wo_token, work_order_no}), source: "work order pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const getConversation = (invoiceToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${invoiceToken}/conversation`,
        method: 'GET'
    };

    apiCall("Getting conversation", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const readUserMessages = (token, stickiesIds, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${token}/read_user_message`,
        data: {stickiesIds},
        method: 'PUT'
    };
    apiCall("Mark all user messages as read", config, async config => {
        const response = await axios.request(config);
        callback()
    }, dispatch, getState, false);
};

