import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {Editor} from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

import config from "../../config/environment";
import {JODIT_BUTTONS} from "../../constants";
import {MaterialSwitch} from "../../components/MaterialSwitch/MaterialSwitch";

const SignatureModal = ({userName, signature, show, onHide, onSave}) => {
    const [localSignature, setLocalSignature] = useState('');
    const [isJodit, setIsJodit] = useState(false);

    const tinyMceRef = useRef(null);
    const editor = useRef(null);

    useEffect(() => {
        setLocalSignature(signature);
    }, [signature]);

    const joditConfig = useMemo(
        () => ({
            readonly: false,
            toolbarInline: true,
            popup: {
                cells: [...JODIT_BUTTONS.basic.popupButtons],
            },
            height: 500,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_only_text",
            ...JODIT_BUTTONS.basic
        }),
        []
    );

    return <Modal
        animation={false}
        show={show}
        onHide={onHide}
        className={"heightAuto maxHeight90 signature-modal"}
        enforceFocus={false}
    >
        <Modal.Header>
            <Modal.Title>
                <div className='flex space-between'>
                    <span>Email signature of {userName}</span>
                    <MaterialSwitch
                        value={isJodit}
                        onChange={() => setIsJodit(prev => !prev)}
                        label={"TinyMCE"}
                        label2={"Jodit"}
                    />
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='padding-top-bottom-8'>
                {isJodit ? <JoditEditor
                    ref={editor}
                    value={localSignature}
                    config={joditConfig}
                    tabIndex={1}
                    onBlur={newContent => setLocalSignature(newContent)}
                    onChange={newContent => setLocalSignature(newContent)}
                /> : <Editor
                    apiKey={config.tinyMceKey}
                    onInit={(evt, editor) => tinyMceRef.current = editor}
                    initialValue={signature}
                    value={localSignature}
                    onEditorChange={(newValue) => setLocalSignature(newValue)}
                    init={{
                        convert_urls: false,
                        height: 500,
                        menubar: true,
                        paste_retain_style_properties: "all",
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'paste'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        forced_root_block: 'div',
                        paste_preprocess: function(plugin, args) {
                            args.content = args.content.replace(/<\/?p[^>]*>/g, function(tag) {
                                return tag.replace('p', 'div');
                            });
                        }
                    }}

                />}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                Close
            </Button>
            <Button variant="primary" onClick={() => onSave(localSignature)}>
                Save Changes
            </Button>
        </Modal.Footer>
    </Modal>
}

export default SignatureModal;