import axios from 'axios'
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";

export const getActivities = (params, callback) => async (dispatch, getState) => {
    const config = {
        url: '/api/new_activities',
        params: params
    };

    apiCall("Getting activities", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const config = {
        url: `/api/search/all_employees`
    };

    apiCall("Loading data", config, async config => {
        const response = await axios.request(config);
        callback({employees: response.data});
    }, dispatch, getState, false);
};
