const initialState = {
    active: null,
    options: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SHOW_JSA_DIALOG':
            return {...state, active: true, options: action.options};
        case 'HIDE_JSA_DIALOG':
            return {...initialState};
        default:
            return state
    }
}

