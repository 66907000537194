import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Glyphicon, Grid, Panel, Row, Well} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import * as api from "./ReportsApi";
import {LinkContainer} from "react-router-bootstrap";
import './Reports.css';
import {defaultDateFormat} from "../../common/commonHandlers";
import moment from "moment";
import DateRangeFilter from "../../components/DateRangeFilter";
import {generateCCExcel} from "./generateCCExcel";

const actions = {...api};

class Reports extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            reports_favourites: {},
            from: null,
            to: null,
        };
    }

    reload = () => {
        this.props.actions.load(result => this.setState(result));
    };

    componentDidMount() {
        this.reload();
    };

    saveReportSettings = (report) => {
        let {reports_favourites} = this.state;
        if (!reports_favourites.includes(report)) {
            reports_favourites.push(report);
        } else {
            const index = reports_favourites.indexOf(report);
            if (index > -1) {
                reports_favourites.splice(index, 1);
            }
        }
        this.setState(reports_favourites, () => {
            this.props.actions.saveFilter(reports_favourites, this.reload)
        })

    };

    getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return parsed;
        } else {
            return null;
        }
    }

    handleRangeDateChange = (start, end) => {
        const from = start && moment(this.getDate(start)).startOf('day').format(defaultDateFormat);
        const to = end && moment(this.getDate(end)).endOf('day').format(defaultDateFormat);
        this.setState({from, to})
    }

    downloadChemicalComplianceCSV = async () => {
        const from = this.state.from ? moment(this.state.from).format(defaultDateFormat) : null;
        const to = this.state.to ? moment(this.state.to).format(defaultDateFormat) : null;
        this.props.actions.downloadChemicalComplianceCSV(
            from,
            to,
            async (data) => await generateCCExcel(data, from, to)
        )
    }

    render() {
        const {
            reports_favourites
        } = this.state;
        return (
            <Grid fluid>
                <Row className="vcenter">
                    <Col md={6} className="vcenter">
                        <h2 className="no-top-margin small-margin">Reports</h2>
                    </Col>
                    <Col md={3} className="text-left">
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>
                <Row>
                    <Panel
                        collapsible
                        expanded={false}
                    >
                    </Panel>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Well>
                            <h4>Chemical Compliance Report</h4>
                            <div className={`report-link`}>
                                <DateRangeFilter
                                    customClassName="customer-DateRangeFilter"
                                    promisedStart={this.state.from ? moment(this.state.from) : null}
                                    promisedEnd={this.state.to ? moment(this.state.to) : null}
                                    upsertPromise={(id, start, end) => this.handleRangeDateChange(start, end)}
                                    serviceNo={null}
                                    index={1}
                                    timeDelay={1}
                                    format={defaultDateFormat}
                                />
                                <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    onClick={() => this.downloadChemicalComplianceCSV()}
                                    type="button"
                                >
                                    Download CSV
                                </Button>
                            </div>
                        </Well>
                    </Col>
                    <Col xs={12}>
                        <Well>
                            <h4>Favourites</h4>
                            {reports_favourites && reports_favourites.length > 0 && reports_favourites.map( report => {
                                return <div className={`report-link`}>
                                    <LinkContainer to={`/${report.toLocaleLowerCase().split(' ').join('_')}`} className={`pointer`}>
                                        <span>{report}</span>
                                    </LinkContainer>
                                    <Glyphicon glyph='heart' className={`small-margin pointer ${reports_favourites.includes(report) && `report-favourite`}`} onClick={() => this.saveReportSettings(report)}/>
                                </div>
                            })}
                        </Well>
                    </Col>
                    <Col xs={12}>
                        <Well>
                            <h4>Invoices</h4>
                        </Well>
                    </Col>
                    <Col xs={12}>
                        <Well>
                            <h4>Sales</h4>
                            <div className={`report-link`}>
                                <LinkContainer to={`/sales_cross_view`} className={`pointer`}>
                                    <span>Sales Cross View </span>
                                </LinkContainer>
                                <Glyphicon glyph='heart' className={`small-margin pointer ${reports_favourites && reports_favourites.length > 0 && reports_favourites.includes('Sales Cross View') && `report-favourite`}`} onClick={() => this.saveReportSettings('Sales Cross View')}/>
                            </div>
                            <div className={`report-link`}>
                                <LinkContainer to={`/sales_actual`} className={`pointer`}>
                                    <span>Sales Actual</span>
                                </LinkContainer>
                                <Glyphicon glyph='heart' className={`small-margin pointer ${reports_favourites && reports_favourites.length > 0 && reports_favourites.includes('Sales Actual') && `report-favourite`}`} onClick={() => this.saveReportSettings('Sales Actual')}/>
                            </div>
                            <div className={`report-link`}>
                                <LinkContainer to={`/service_sales_actual`} className={`pointer`}>
                                    <span>Service Sales Actual</span>
                                </LinkContainer>
                                <Glyphicon glyph='heart' className={`small-margin pointer ${reports_favourites && reports_favourites.length > 0 && reports_favourites.includes('Service Sales Actual') && `report-favourite`}`} onClick={() => this.saveReportSettings('Service Sales Actual')}/>
                            </div>
                            <div className={`report-link`}>
                                <LinkContainer to={`/backlog`} className={`pointer`}>
                                    <span>Backlog</span>
                                </LinkContainer>
                                <Glyphicon glyph='heart' className={`small-margin pointer ${reports_favourites && reports_favourites.length > 0 && reports_favourites.includes('Backlog') && `report-favourite`}`} onClick={() => this.saveReportSettings('Backlog')}/>
                            </div>
                        </Well>
                    </Col>
                    <Col xs={12}>
                        <Well>
                            <h4>Assets</h4>
                            <div className={`report-link`}>
                                <LinkContainer to={`/lead_appointments`} className={`pointer`}>
                                    <span>Lead Appointments</span>
                                </LinkContainer>
                                <Glyphicon glyph='heart' className={`small-margin pointer ${reports_favourites && reports_favourites.length > 0 && reports_favourites.includes('Lead Appointments') && `report-favourite`}`} onClick={() => this.saveReportSettings('Lead Appointments')}/>
                            </div>
                            <div className={`report-link`}>
                                <LinkContainer to={`/follow_ups`} className={`pointer`}>
                                    <span>Follow Ups</span>
                                </LinkContainer>
                                <Glyphicon glyph='heart' className={`small-margin pointer ${reports_favourites && reports_favourites.length > 0 && reports_favourites.includes('Follow Ups') && `report-favourite`}`} onClick={() => this.saveReportSettings('Follow Ups')}/>
                            </div>
                        </Well>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

Reports.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Reports.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);