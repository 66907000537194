//We are using plants in > 1 place/page/feature so we'll put them as a top level reducer like rails-like redux folder layouts

import {
  FETCH_SERVICE_LIST,
  FETCH_SERVICE,
  UPDATE_SERVICE_ATTR,
  ADD_NEW_SERVICE,
  DELETE_SERVICE,
} from '../constants'

const initialState = {
  serviceList: [],
  services: {},
  serviceListLastUpdated: 0,
}

//add/overwrite object to object i.e. {} => { 'something_3': {boots: 'cat'} }
const insertKeyIntoObject = (obj, kind, key) => {
  return { ...obj, [`${kind}_${key['id']}`]: key }
}

//edit key of object
const updateObjectAttribute = (obj, kind, id, attr, val) => {
  return {
    ...obj,
    [`${kind}_${id}`]: { ...obj[`${kind}_${id}`], [attr]: val },
  }
}

const newService = () => {
  return {
    id: 0,
    name: 'New Service',
    employee_number: 1,
    estimated_total_time: 1,
    rate: 186,
    skills: 'Service Description',
  }
}

const deleteKeyFromObject = (obj, kind, id) => {
  //destructure like let { x,y, ...restOfThem} = this.props and do nothing with the throwaway
  let { [`${kind}_${id}`]: unwanted, ...restOfThem } = obj
  console.log('removed ', unwanted)
  console.log('returning', restOfThem)
  return restOfThem
}

export function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVICE_LIST:
      return {
        ...state,
        serviceList: action.serviceList,
        serviceListLastUpdated: action.serviceListLastUpdated,
      }
    case FETCH_SERVICE:
      return {
        ...state,
        services: insertKeyIntoObject(
          state.services,
          'service',
          action.service
        ),
      }
    case UPDATE_SERVICE_ATTR:
      return {
        ...state,
        services: updateObjectAttribute(
          state.services,
          'service',
          action.id,
          action.attr,
          action.val
        ),
      }
    case ADD_NEW_SERVICE:
      return {
        ...state,
        services: insertKeyIntoObject(state.services, 'service', newService()),
      }
    case DELETE_SERVICE:
      return {
        ...state,
        services: deleteKeyFromObject(state.services, 'service', action.id),
      }
    default:
      return state
  }
}
