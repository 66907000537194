import axios from "axios/index";
import {mapForSelect} from "../../common/commonHandlers";
import {apiCall} from "../../common/apiActionHandler";

export const loadFactorSelects = callback => (dispatch, getState) => {
    const config = [
        {url: '/api/factor_types'},
        {url: '/api/plants'},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            factor_types: mapForSelect(all[0].data),
            plants: mapForSelect(all[1].data),
        });
    }, dispatch, getState, false);
};
