import ExcelJS from "exceljs";
import {saveAs} from "file-saver";
import moment from "moment";
import {
    getCountiesInState,
    getCountyCode,
    getFullStateName
} from "../../utilities/counties";
import _ from "lodash";

const backgroundColor = 'FFA0FFFF';

const dataHeaders = [
    'COL 1 EPA REG NUMBER *',
    'COL 2 PRODUCT NAME *',
    'COL 3 QUANTITY USED *',
    'COL 4 UNITS *',
    'COL 5 DATE OF APPLICATION *',
    'COL 6 END DATE OF APPLICATION',
    'COL 7 COUNTY CODE *',
    'COL 8 ADDRESS *',
    'COL 9 MUNICIPALITY *',
    'COL 10 ZIP CODE *',
    'COL 11 DOSAGE RATE',
    'COL 12 METHOD OF APPLICATION',
    'COL 13 TARGET ORGANISM(S)',
    'COL 14 PLACE OF APPLICATION',
    'COL 15 ACTIVE INGREDIENTS',
    'County Codes for COL 7',
];
// row-column
const boldCells = new Map()
    .set('1-1', true)
    .set('3-1', true)
    .set('3-2', true)
    .set('3-6', true)
    .set('6-1', true)
    .set('6-2', true)
    .set('10-1', true)
    .set('10-2', true)
    .set('10-3', true)
    .set('10-4', true)
    .set('10-5', true)
    .set('10-7', true)
    .set('10-8', true)
    .set('10-9', true)
    .set('10-10', true)
    .set('10-15', true)
    .set('10-16', true)
    .set('10-17', true)

const whiteCells = new Map()
    .set('4-1', true)
    .set('4-2', true)
    .set('4-6', true)
    .set('7-1', true)
    .set('7-2', true)

const columnWidths = [100, 120, 40, 30, 60, 60, 35, 120, 90, 40, 50, 70, 50, 70, 60, 40, 40];

const getNBlankCells = (n) => {
    const cells = [];
    for (let i = 0; i < n; i++) {
        cells.push('');
    }
    return cells;
}

const maxWidth = dataHeaders.length + 1;

export const generateCCExcel = async (data, from, to) => {
    const workbook = new ExcelJS.Workbook();

    const dataGroupedByState = _.groupBy(data.chemical_compliance_entries, 'state');
    const companyName = data.company_name || '';
    const entityID = data.entity_id || '';


    let reportYear = '';

    if (from && to) {
        if (moment(from).format('MM/DD') === '01/01' && moment(to).format('MM/DD') === '12/31') {
            reportYear = moment(from).format('YYYY');
        } else {
            reportYear = moment(from).format('MM/DD/YYYY') + ' - ' + moment(to).format('MM/DD/YYYY');
        }
    } else {
        if (from) {
            reportYear = moment(from).format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY');
        }
        if (to) {
            reportYear = moment().format('MM/DD/YYYY') + ' - ' + moment(to).format('MM/DD/YYYY');
        }
        if (!from && !to) {
            reportYear = 'All';
        }
    }

    Object.keys(dataGroupedByState).forEach(stateKey => {
        const worksheet = workbook.addWorksheet(`Chemical Compliance Report ${stateKey}`);

        worksheet.addRow(['APPLICATOR/TECHNICIAN PESTICIDE REPORT', ...getNBlankCells(maxWidth - 1)]);
        worksheet.addRow([...getNBlankCells(maxWidth)])

        worksheet.addRow(['Report Year', "Business Name", ...getNBlankCells(3), "Business Reg. #", ...getNBlankCells(maxWidth - 6)])
        worksheet.addRow([...getNBlankCells(6), '(if applicable)', ...getNBlankCells(maxWidth - 7)])

        worksheet.addRow([...getNBlankCells(maxWidth)])

        worksheet.addRow(['Certification ID #', "Applicator Name", ...getNBlankCells(maxWidth - 2)])
        worksheet.addRow([...getNBlankCells(2), ...getNBlankCells(maxWidth - 2)])

        worksheet.addRow([...getNBlankCells(2), 'Did you make Applications?', ...getNBlankCells(2),  'Yes', ...getNBlankCells(maxWidth - 6)])

        worksheet.addRow([
            '* - Required Column (each cell must contain data - NO ditto marks)',
            ...getNBlankCells(5),
            '|----------------------- Location of Pesticide Application ---------------------------------------|',
            ...getNBlankCells(maxWidth - 7)
        ])

        worksheet.addRow(dataHeaders);

        worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
            row.eachCell((cell, colNumber) => {
                const key = `${rowNumber}-${colNumber}`;
                cell.font = {name: "Arial"};

                if (key === '1-1') {
                    cell.font = {...cell.font, size: 16};
                } else {
                    cell.font = {...cell.font, size: 10};
                }

                if (key === '2-8') {
                    cell.style.alignment = {horizontal: 'right'};
                }

                if (key === '4-7') {
                    cell.font = {...cell.font, size: 8};
                }

                if (key === '8-3') {
                    cell.font = {...cell.font, size: 12, italic: true};
                    cell.style.alignment = {horizontal: 'right'};
                }

                if (key === '8-6') {
                    cell.font = {...cell.font, size: 12, bold: true};
                    cell.style.alignment = {horizontal: 'center', vertical: 'center'};
                    cell.border = {
                        top: {style: 'thick'},
                        left: {style: 'thick'},
                        bottom: {style: 'thick'},
                        right: {style: 'thick'}
                    };
                }

                if (boldCells.has(key)) {
                    cell.font = {...cell.font, bold: true};
                }

                if (whiteCells.has(key)) {
                    cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFFFFFF'}};
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    };
                } else {
                    if (key === '8-6') {
                        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};
                    } else {
                        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: backgroundColor}};
                    }
                }

                if (rowNumber === 10) {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    };
                    cell.font = {...cell.font, size: 9}
                    cell.style.alignment = {wrapText: true, horizontal: 'center', vertical: 'top'};
                    if (colNumber === 6 || colNumber === 11 || colNumber === 12 || colNumber === 13 || colNumber === 14) {
                        cell.font = {...cell.font, size: 7}
                    }
                }
            })
        })

        worksheet.mergeCells('G9:J9');
        worksheet.getCell('G9').style.alignment = {horizontal: 'center'};

        worksheet.mergeCells('B3:E3');
        worksheet.mergeCells('B4:E4');
        worksheet.getCell('B4').value = companyName;
        worksheet.getCell('F4').value = entityID;
        worksheet.getCell('A4').value = reportYear;

        worksheet.getCell('A3').style.alignment = {horizontal: 'center'};
        worksheet.getCell('B3').style.alignment = {horizontal: 'center'};
        worksheet.getCell('A4').style.alignment = {horizontal: 'center'};
        worksheet.getCell('B4').style.alignment = {horizontal: 'center'};

        worksheet.mergeCells('B6:E6');
        worksheet.mergeCells('B7:E7');
        worksheet.getCell('B6').style.alignment = {horizontal: 'center'};
        worksheet.getCell('B7').style.alignment = {horizontal: 'center'};

        worksheet.mergeCells('P10:Q10');
        worksheet.getCell('P10').style.alignment = {horizontal: 'center', vertical: 'top'};
        worksheet.getCell('P10').style.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};

        worksheet.getCell('I2').font = {color: {argb: 'FF0000FF'}, underline: true};

        worksheet.mergeCells('A1:P1');
        worksheet.mergeCells('C8:E8');
        worksheet.getCell('C8').style.alignment = {horizontal: 'right', vertical: 'center'};

        worksheet.mergeCells('F3:G3')
        worksheet.mergeCells('A9:B9')

        worksheet.getCell('A9').value = {
            'richText': [
                {'font': {'size': 10,'name': 'Arial'}, 'text': '* - '},
                {'font': {'size': 10,'name': 'Arial', 'bold': true }, 'text': 'Required '},
                {'font': {'size': 10,'name': 'Arial'}, 'text': 'Column (each cell must contain data - '},
                {'font': {'size': 10,'name': 'Arial', 'bold': true }, 'text': 'NO'},
                {'font': {'size': 10,'name': 'Arial'}, 'text': ' ditto marks'},
            ]
        };

        worksheet.columns.forEach((column, index) => {
            column.width = columnWidths[index] / 4;
        });

        const data = dataGroupedByState[stateKey];

        const getCCrow = (index, fieldName) => {
            if(!data[index]) return '';

            return data[index][fieldName];
        }

        const state = getCCrow(0, 'state');
        const countiesInState = getCountiesInState(getFullStateName(state));

        const ccLength = data.length;
        const countyCodesLength = countiesInState.length;

        const maxLength = ccLength > countyCodesLength ? ccLength : countyCodesLength;

        for(let index = 0; index < maxLength; index++) {
            worksheet.addRow([
                getCCrow(index, 'epa_number'),
                getCCrow(index, 'product_name'),
                getCCrow(index, 'quantity_used'),
                getCCrow(index, 'unit'),
                getCCrow(index, 'date_of_application') ? moment(getCCrow(index, 'date_of_application')).format('MM/DD/YYYY HH:mm') : '',
                getCCrow(index, 'end_date_of_application') ? moment(getCCrow(index, 'end_date_of_application')).format('MM/DD/YYYY HH:mm') : '',
                getCCrow(index, 'county') ? getCountyCode(getCCrow(index, 'county'), countiesInState) : '',
                getCCrow(index, 'address'),
                getCCrow(index, 'municipality'),
                getCCrow(index, 'zip_code'),
                getCCrow(index, 'dosage_rate'),
                getCCrow(index, 'method_of_application'),
                getCCrow(index, 'targeted_organisms'),
                getCCrow(index, 'place_of_application'),
                getCCrow(index, 'active_ingredients'),
                // County Codes
                index + 1,
                countiesInState[index] ? countiesInState[index]["County"].replace(" County", "") : '',
            ]);
            worksheet.getRow(index+11).getCell(16).style.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};
            worksheet.getRow(index+11).getCell(17).style.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};
        }
    });

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(dataBlob, 'chemical-compliance-report.xlsx');
}