import {addAlert} from "./containers/App/actions";
import {SIGN_OUT_USER} from "./containers/LoginPage/constants";
import {browserHistory} from "react-router";

export const logout = (url = null) => async (dispatch, getState) => {
    const store = getState()
    //leave the client, email as-is for UX because its not common to change them (perhaps we should use client-id? or a 3 letter designation instaed of the whole client?)
    await dispatch({
        type: SIGN_OUT_USER,
        isAuthenticated: false,
        client: store.auth.client,
        email: store.auth.email,
        name: '',
        token: '',
        password: '',
        isCustomerAuthenticated: false,
        clientAppVersion: store.auth.clientAppVersion,
        newClientAppVersion: store.auth.newClientAppVersion,
    })
}
