import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from "../../LeadsPage/FindTimeApi";
import SchedulerEventDialog from "../../Scheduler/SchedulerEventDialog";
import {browserHistory} from "react-router";
import {Grid} from "react-bootstrap";
import ProposalLeadMenu from "./ProposalLeadMenu";
import ProposalMenu from "./ProposalMenu";

class ProposalFindTime extends Component {

    state = {lead: {}};

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.props.actions.getLead(this.props.params.id, lead => this.setState({lead}))
    }

    render() {
        const id = this.props.params.id;
        const eventId = this.props.params.eventId;
        const {lead} = this.state;
        return (
            <Grid fluid>
                <ProposalMenu
                    id={lead && lead.proposal_id}
                    expandButton={false}
                    onReload={this.handleProposalIdChange}
                />
                <ProposalLeadMenu id={id} proposalId={lead.proposal_id} save={() => this.save()}/>
                <SchedulerEventDialog
                    inModal={false}
                    event={{
                        id: eventId,
                        person_id: lead.person_id,
                        scheduler_event_type_resource: 'Lead',
                        scheduler_event_resource_id: id,
                    }}
                    save={save => {
                        this.save = save
                    }}
                    handleClose={() => {
                        browserHistory.push(`/proposal_lead/${id}`);
                    }}
                />
            </Grid>
        );
    }
}

ProposalFindTime.propTypes = {};

ProposalFindTime.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalFindTime);
