import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios/index";

export const getUserShowLocation = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users/get_show_location_status`
    };

    apiCall("Getting show my location on proposal map status", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const setUserShowLocation = (show, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users/update_show_location_status`,
        method: "put",
        data: {show}
    };

    apiCall("Setting show my location on proposal map status", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}