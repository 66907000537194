import React, {Component} from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, FormControl, FormGroup, Grid, Modal, Row, Table} from "react-bootstrap";
import * as actions from './actions'
import * as api from "./JobSiteAnalysisApi";
import createApiService from "../../common/clientActionsBuilder";
import SiteHazardTable from "./SiteHazardTable";
import Attachment from "../../components/image/Attachment";
import {getHazard, deleteHazard} from '../Scheduler/CrewMessages/actions'
import NewAttachment from "../../components/image/Attachment/NewAttachment";

const siteHazardApi = createApiService('site_hazards', 'site_hazard', 'Site Hazard');

const Actions = {
    ...api, ...actions,
    saveSiteHazard: siteHazardApi.save,
    deleteSiteHazard: siteHazardApi.remove,
    getHazard,
    deleteHazard,
};

class JobSiteAnalysis extends Component {
    state = {siteHazards: [], assetHazards: [], description: ""};

    constructor(props, context) {
        super(props, context);
        this.reload();
    }

    reload = (callback, response) => {
        this.props.actions.load(this.props.siteId, data => {
            if (data.siteHazards) {
                data.siteHazards = data.siteHazards.map(sh => {
                    if (sh.image_order) {
                        let order = JSON.parse(sh.image_order);
                        sh.images = sh.images.map(img => {
                            img.order = order.findIndex((o) => o === img.id)
                            return img
                        })
                    } else {
                        sh.images = sh.images.map((img, index) => {
                            img.order = index
                            return img
                        })
                    }
                    return sh
                })
            }
            if (data.assetHazards) {
                data.assetHazards = data.assetHazards.map(ah => {
                    if (ah.image_order) {
                        let order = JSON.parse(ah.image_order);
                        ah.images = ah.images.map(img => {
                            img.order = order.findIndex((o) => o === img.id)
                            return img
                        })
                    } else {
                        ah.images = ah.images.map((img, index) => {
                            img.order = index
                            return img
                        })
                    }
                    ah.images = ah.images.sort((a, b) => a.order - b.order)
                    return ah
                })
            }
            this.setState(data);
            callback && callback(response);
        });
    };
    reorderHazardImages = (imageId, siteHazard) => {
        const length = siteHazard.images.length
        let nexImgIndex
        let newImages = siteHazard.images.map((img, i) => {
            if (img.id === imageId) {
                if (img.order < length - 1) {
                    nexImgIndex = i + 1
                    img.order += 1
                }
            }
            return img;
        });

        if (nexImgIndex) {
            let nextImage = newImages[nexImgIndex]
            nextImage.order -= 1
        }
        siteHazard.images = newImages
        this.saveSiteHazard(siteHazard)
    }

    reorderAssetHazardImages = (imageId, hazardId) => {
        const {assetHazards} = this.state
        let assetHazard = this.state.assetHazards.find(ah => ah.id === hazardId)
        let assetHazardIndex=assetHazards.indexOf(assetHazard)
        const length = assetHazard.images.length
        let nexImgIndex
        let newImages = assetHazard.images.map((img, i) => {
            if (img.id === imageId) {
                if (img.order < length - 1) {
                    nexImgIndex = i + 1
                    img.order += 1
                }
            }
            return img;
        });
        if (nexImgIndex) {
            let nextImage = assetHazard.images[nexImgIndex]
            nextImage.order -= 1
        }
        assetHazard = {...assetHazard, images: newImages.sort((a, b) => a.order - b.order)}
        assetHazard = {...assetHazard, image_order : JSON.stringify(assetHazard.images.map(i => i.id))}
        assetHazards[assetHazardIndex] = assetHazard
        this.setState({assetHazards: assetHazards},()=>{
            this.props.actions.saveAssetHazard(assetHazard.id, assetHazard)
        })
    }

    saveSiteHazard = (resource, callback, fromImageUpload = false) => {
        const jsaConfig = this.props.state.jsa.options;
        resource.image_order = JSON.stringify(resource.images.sort((a, b) => a.order - b.order).map(i => i.id))
        this.props.actions.saveSiteHazard(resource, response => {
            if(fromImageUpload) {
                this.reload(callback, response);
                return;
            }

            if(!jsaConfig.ignoreAddMessage) {
                this.props.actions.getHazard({
                    site_hazard_id: response.id,
                    hazard_id: resource.hazard_id,
                    siteId: this.props.siteId
                })
            }
            this.reload(callback, response)
        });
    };

    updateDescription = () => {
        this.props.actions.updateDescription(this.props.siteId, this.state.description, this.props.actions.hideJSADialog);
    };

    render() {
        const {siteId, lockedCrewEditAccess} = this.props;
        const {siteHazards, assetHazards, hazards, description} = this.state;
        return (
            <Modal
                bsSize="large"
                animation={false}
                show={true}
                onHide={this.props.actions.hideJSADialog}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Job Site Analysis
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid fluid>
                        <Row>
                            <Col xs={6}><h4>{siteHazards.length} Site Hazards</h4></Col>
                            <Col xs={6}><h4>{assetHazards.length} Tree Hazards</h4></Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <FormControl
                                        componentClass="textarea"
                                        rows={10}
                                        placeholder={"Job Site Analysis description"}
                                        value={description}
                                        onChange={e => this.setState({description: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                {hazards && <SiteHazardTable saveSiteHazard={this.saveSiteHazard}
                                                             deleteSiteHazard={(resource, callback) => {
                                                                 this.props.actions.deleteSiteHazard(resource, () => {
                                                                     const deletedHazard = this.state.siteHazards.find(s => s.id === resource.id);
                                                                     this.props.actions.deleteHazard({
                                                                         site_hazard_id: deletedHazard.id,
                                                                         hazard_id: deletedHazard.hazard_id,
                                                                         siteId: this.props.siteId,
                                                                         name: deletedHazard.hazard_name
                                                                     })
                                                                     this.reload(callback);
                                                                 });
                                                             }}
                                                             reorderHazardImages={this.reorderHazardImages}
                                                             setSiteHazards={this.setSiteHazards}
                                                             removeImage={this.props.actions.removeImage}
                                                             rotateImage={this.props.actions.rotateImage}
                                                             reloadData={this.reload}
                                                             uploadAttachment={this.props.actions.uploadAttachment}
                                                             siteHazards={siteHazards}
                                                             hazards={hazards}
                                                             siteId={siteId}
                                                             oldAttachment={!!window.location.href.includes('crew_work_orders')}
                                                             setCwoAndProposalVisibleSiteHazard={this.props.actions.setCwoAndProposalVisibleSiteHazard}
                                                             lockedCrewEditAccess={lockedCrewEditAccess}
                                />
                                }
                            </Col>
                            <Col sm={6}>
                                <Table striped hover>
                                    <thead>
                                    <tr>
                                        <th>Asset</th>
                                        <th>Hazard</th>
                                        <th>Image</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {assetHazards.map((h, idx) =>
                                        <tr key={h.id}>
                                            <td>#{h.asset_number} {h.asset_name}</td>
                                            <td>{h.hazard_name}</td>
                                            <td>
                                                {!!window.location.href.includes('crew_work_orders') ? h.images.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(i =>
                                                        <Attachment
                                                            image={i}/>) :
                                                    h.images.filter(i => lockedCrewEditAccess ? i.cwo_and_proposal_display === true : i).map(i =>
                                                        <NewAttachment
                                                            key={i.id}
                                                            image={i}
                                                            lockedCrewEditAccess={lockedCrewEditAccess}
                                                            deleteImage={imageId => {
                                                                this.props.actions.removeImage(imageId)
                                                                let {assetHazards} = this.state;
                                                                const selectedAssetHazard = this.state.assetHazards.find(x => assetHazards.indexOf(x) === idx)
                                                                if (selectedAssetHazard && selectedAssetHazard.images && selectedAssetHazard.images.length > 0) {
                                                                    selectedAssetHazard.images = selectedAssetHazard.images.filter(x => x.id !== i.id)
                                                                }
                                                                assetHazards[idx].images = selectedAssetHazard.images
                                                                this.setState({
                                                                    assetHazards: assetHazards
                                                                })
                                                            }}
                                                            reorderImages={() => this.reorderAssetHazardImages(i.id, h.id)}
                                                            setVisible={visible => {
                                                                this.props.actions.setCwoAndProposalVisibleHazard(this.props.siteId, assetHazards[idx].id, i.id, visible, (res) => {
                                                                    const newImages = assetHazards[idx].images.map(img => {
                                                                        if (img.id === i.id)
                                                                            return ({
                                                                                ...img,
                                                                                cwo_and_proposal_display: res.cwo_and_proposal_display
                                                                            })
                                                                        return img
                                                                    })
                                                                    let newAssetHazards = assetHazards
                                                                    newAssetHazards[idx].images = newImages;
                                                                    this.setState({
                                                                        assetHazards: newAssetHazards
                                                                    })
                                                                })
                                                            }}
                                                            rotateImage={this.props.actions.rotateImage}
                                                            reloadData={this.reload}
                                                            uploadAttachment={this.props.actions.uploadAttachment}
                                                            saveImage={(file, callback) => {
                                                                this.props.actions.uploadHazardAttachment(h.id, file, data => {
                                                                    data = {
                                                                        ...data,
                                                                        order: h.images.length,
                                                                        cwo_and_proposal_display: true
                                                                    }
                                                                    assetHazards[idx].images.push(data);
                                                                    assetHazards[idx].images = assetHazards[idx].images.sort((a, b) => a.order - b.order)
                                                                    assetHazards[idx]= {...assetHazards[idx], image_order : JSON.stringify(assetHazards[idx].images.map(i => i.id))}
                                                                    this.setState({assetHazards},()=>{
                                                                        this.props.actions.saveAssetHazard(assetHazards[idx].id, assetHazards[idx])
                                                                    });
                                                                })

                                                            }}
                                                        />
                                                    )}
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="text-left" md={6} xs={12}>
                            <Button
                                bsSize="small"
                                bsStyle="warning"
                                onClick={this.props.actions.hideJSADialog}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col className="text-right" md={6} xs={12}>
                            <Button
                                bsSize="small"
                                bsStyle="success"
                                onClick={this.updateDescription}
                                type="submit"
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

JobSiteAnalysis.propTypes = {
    siteId: PropTypes.number.isRequired
};

JobSiteAnalysis.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobSiteAnalysis);
