export const [
  FETCH_MY_PROPOSALS,
  UPDATE_PROPOSAL_COMPLETED_END,
  UPDATE_PROPOSAL_COMPLETED_START,
  UPDATE_STATUS,
] = [
  "FETCH_MY_PROPOSALS",
  "UPDATE_PROPOSAL_COMPLETED_END",
  "UPDATE_PROPOSAL_COMPLETED_START",
  "UPDATE_STATUS",
]
