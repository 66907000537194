import React, {useState, useRef} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const ImageLightBox = ({images, isImageModalOpened, toggleImageModal}) => {
    const [photoIndex, setPhotoIndex] = useState(0)
    const lightboxRef = useRef(null)

    if (isImageModalOpened) {
        return (
            <Lightbox
                ref={lightboxRef}
                mainSrc={images[photoIndex]}
                clickOutsideToClose={false}
                onCloseRequest={() => toggleImageModal(false)}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
        );
    } else {
        return null
    }
}
