import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

const DutyCard = ({duty, i, allowEdit, colorBackgroundText, editDuty, deleteDuty}) => {
    return <>
        <div className="duty-title-wrapper">
            {colorBackgroundText(duty.title)}
            {allowEdit && <div>
                <FontAwesomeIcon icon={faEdit} onClick={() => editDuty()} className="mr-3 pointer"/>
                <FontAwesomeIcon icon={faTrash} onClick={() => deleteDuty()} className="pointer"/>
            </div>}
        </div>
        {duty.description.replace(/(<[^>]*>|&nbsp;)/g, '').length > 1 && <div className="duty-description-wrapper">
            <div className="duty-description" dangerouslySetInnerHTML={{__html: duty.description}}/>
        </div>}
    </>
}

export default DutyCard;