import axios from "axios"
import {apiCall} from "../../common/apiActionHandler";

export const fetchActiveCustomers = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/active_customers`,
        params: filter
    };

    apiCall("Getting active customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
