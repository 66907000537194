import {dataUpload} from "../../../../common/upload";
import {apiCall} from "../../../../common/apiActionHandler";
import axios from "axios";

export const getClientDocuments = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/client_documents`,
        params: resource
    }

    apiCall("Getting client documents", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const uploadClientFile = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/client_files`, acceptedFiles, callback)(dispatch, getState);
};

export const saveClientDocument = (resource, create_history, callback) => async (dispatch, getState) => {
    const config = {
        url: resource?.id ? `/api/client_documents/${resource?.id}` : `/api/client_documents`,
        method: resource?.id ? 'PUT' : 'POST',
        data: {...resource, create_history}
    };

    apiCall("Save client document", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const deleteClientDocument = (id, softDelete, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/client_documents/${id}`,
        params: {softDelete}
    }

    apiCall("Deleting client document", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const deleteClientFile = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/client_files/${id}`
    }

    apiCall("Deleting client file", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const updateClient = (client, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${client.id}`,
        method: 'PUT',
        data: client
    };
    apiCall("Updating client", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState, false);
};

export const updateOrder = (resource, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/client_documents/update_order`,
        method: 'PUT',
        data: {resource}
    };

    apiCall("Save client documents", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const restoreDocument = (id, tab, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/client_documents/restore_document`,
        method: 'PUT',
        data: {id, tab}
    };

    apiCall("Restore client document", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};