import axios from "axios/index";
import {colors} from "../../common/commonHandlers";

export function getIcon(asset, highlighted, label = false) {
    let color = asset.plant_color ? colors[asset.plant_color] && colors[asset.plant_color].replace('#', '') : '93c54b';
    if (highlighted || asset.__highlighted === true || asset.__active === true || asset.__locked === true) {
        color = 'FF9E00'
    }
    return {
        labelOrigin: {x: 15, y: 15},
        url: `${axios.defaults.baseURL}/api/public_images/marker?color=${color}&label=${label ? label : ''}`
    };
}

export function getColorIcon(color, with_opacity = false, label = "", with_circle, gradient, highlight) {
    return {
        labelOrigin: {x: 15, y: 15},
        url: `${axios.defaults.baseURL}/api/public_images/marker?color=${color ? color.replace('#', '') : '3a87ad'}${with_opacity ? '&opacity=0.8' : ''}&label=${label ? label : ''}&with_circle=${with_circle ? with_circle : false}&gradient=${gradient ? gradient : false}&highlight=${highlight ? highlight : false}`
    };
}

export function getMixedMarkerIcon() {
    return {
        labelOrigin: {x: 15, y: 15},
        url: `${axios.defaults.baseURL}/api/public_images/marker?color=$3a87ad&label=MIX&gradient=true}`
    };
}

export function getColorCircleIcon(color, label) {
    return {url: `${axios.defaults.baseURL}/api/public_images/circle?color=${color ? color.replace('#', '') : '3a87ad'}&label=${label}`};
}

export function getLabel(label) {
    const width = Math.ceil(label.length * 12.5) + 5

    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="40" viewBox="-1 -12 22 47"><g style="overflow:hidden;text-anchor:middle;font-size:25;font-weight:700;font-family:Arial"><text x="10" y="30" style="fill:white;stroke:#000;stroke-width:1.3">${label}</text></g></svg>`

    return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg)};
}

export function getBigIcon(color, highlight, label) {
    return {url: `${axios.defaults.baseURL}/api/public_images/marker?color=${color ? color.replace('#', '') : '3a87ad'}&label=${label}&big=true&highlight=${highlight ? highlight : false}`};
}

export function getLeadIcon() {
    return {url: `${axios.defaults.baseURL}/api/public_images/marker?color=FF9E00`};
}
