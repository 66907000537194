import React, {useEffect, useState} from "react"
import * as CustomerActions from './actions'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Row, Button, Modal} from "react-bootstrap";
import moment from "moment-timezone";
import DateRangeFilter from "../../../components/DateRangeFilter";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import "./CustomerTimeline.scss"
import TimelineEvent from "./TimelineElements/TimelineEvent";
import TimelineCrewMessage from "./TimelineElements/TimelineCrewMessage";
import TimelineNote from "./TimelineElements/TimelineNote";
import {defaultDateFormat} from "../../../common/commonHandlers";
import * as CustomerTimelineActions from './CustomerTimelineApi'
import TimelineAxisDate from "./TimelineElements/TimelineAxisDate";
import MDSpinner from "react-md-spinner";

const actions = {...CustomerActions, ...CustomerTimelineActions}

const CustomerTimeline = ({actions, customerId}) => {
    const [ready, setReady] = useState(false)
    const [timelineData, setTimelineData] = useState([])
    const [currentUserPersonId, setCurrentUserPersonId] = useState(null)
    const [filters, setFilters] = useState({showEvents: true, showCrewMessage: true, showNotes: true, date_from: null, date_to: null})
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [employees, setEmployees] = useState([])

    const reload = () => {
        setReady(false)
        actions.customerTimelineData(customerId, filters, result => {
            setTimelineData(result.timeline_result)
            setReady(true)
            setCurrentUserPersonId(result.current_user_person_id)
            setEmployees(result.employees)
            let messages = document.getElementById("timeline-body")
            messages.scrollTop = messages.scrollHeight;
        })
    }

    useEffect(() => {
        reload()
    }, [filters.date_from, filters.date_to, customerId])

    const saveNote = note => {
        actions.saveCustomerNote(note, customerId, res => {
            if(res?.customer_note) {
                const index = timelineData.findIndex(el => el.type === 'note' && el.data.id === res.customer_note.id)
                const newNote = {data: res?.customer_note, type: "note"}
                if(index !== -1) {
                    timelineData.splice(index, 1, newNote)
                    setTimelineData(timelineData.concat([]))
                } else {
                    setTimelineData(timelineData.concat(newNote))
                    let messages = document.getElementById("timeline-body")
                    messages.scrollTop = messages.scrollHeight;
                }
            }
        })
    }

    const getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return parsed;
        } else {
            return null;
        }
    }

    const handleRangeDateChange = (start, end) => {
        const from = start && moment(getDate(start)).format('DD/MM/YYYY');
        const to = end && moment(getDate(end)).format('DD/MM/YYYY');
        setFilters({...filters, date_from: from && from, date_to: to && to, all_records: true})
    }

    const employee = person_id => employees.find(e => +e.value === +person_id)

    return (ready ? <div className="customer-timeline">
            <Row>
                <Col xs={12} className="text-center"><h4>Customer Notes</h4></Col>
            </Row>
            <Row className="vertical-align">
                <Col xs={9} className="text-center">
                    <DateRangeFilter
                        customClassName="customer-DateRangeFilter"
                        promisedStart={!filters.all_records ? moment(timelineData[0]?.data?.created_at).format(defaultDateFormat) : getDate(filters.date_from)}
                        promisedEnd={!filters.all_records ? moment().format(defaultDateFormat) : getDate(filters.date_to)}
                        upsertPromise={(id, start, end) => handleRangeDateChange(start, end)}
                        serviceNo={null}
                        index={1}
                        format="MM/DD/YY"
                    />
                </Col>
                <Col xs={3} className="text-center filter-button">
                    <Button
                        bsSize="xsmall"
                        bsStyle="warning"
                        onClick={() => setShowFilterModal(true)}
                    >
                        Filter
                    </Button>

                </Col>
            </Row>
            <Row id="timeline-body">
                {ready ? <div className={`${timelineData.length > 0 && "timeline"}`}>
                    {timelineData.length > 0 ? timelineData.map((el, i) => {
                        const elementEmployee = employee(el.data.person_id)
                        const elementColor = elementEmployee && elementEmployee.color ? elementEmployee.color : "#dadada"
                        const toSetNextDay = timelineData[i - 1] ? moment(timelineData[i - 1].data.created_at).format(defaultDateFormat) !== moment(el.data.created_at).format(defaultDateFormat) : true
                        if (el.type === 'event') {
                            return <>
                                {toSetNextDay && <TimelineAxisDate date={el.data.created_at}/>}
                                <TimelineEvent
                                    event={el.data}
                                    site={el.site}
                                    wo={el.wo}
                                    proposal={el.proposal}
                                    leadId={el.lead_id}
                                    eventType={el.event_type}
                                    color={elementColor}
                                    employeeName={elementEmployee?.label}
                                    employeeDisabled={elementEmployee?.disabled}/>
                            </>
                        } else if (el.type === 'crew message') {
                            return <>
                                {toSetNextDay && <TimelineAxisDate date={el.data.created_at}/>}
                                <TimelineCrewMessage
                                    message={el.data}
                                    images={el.images}
                                    color={elementColor}
                                    employeeName={elementEmployee?.label}
                                    employeeDisabled={elementEmployee?.disabled}/>
                            </>
                        } else if (el.type === 'note') {
                            return <>
                                {toSetNextDay && <TimelineAxisDate date={el.data.created_at}/>}
                                <TimelineNote
                                    note={el.data}
                                    color={elementColor}
                                    employeeName={elementEmployee?.label}
                                    employeeDisabled={elementEmployee?.disabled}
                                    currentUserPersonId={currentUserPersonId}
                                    onSave={note => saveNote(note)}
                                    onDelete={id => actions.deleteCustomerNote(id, () => reload())}/>
                            </>
                        }
                    }) : <p className="no-data">Nothing to report...</p>}
                    <TimelineNote
                        employeeName={employee(currentUserPersonId)?.label}
                        employeeDisabled={employee(currentUserPersonId)?.disabled}
                        color={employee(currentUserPersonId)?.color}
                        onSave={note => saveNote(note)}/>
                </div> : <div className="vhCenter full-height">
                    <MDSpinner size={70} borderSize={2}/>
                </div>}
            </Row>
            {showFilterModal && <Modal
                show={showFilterModal}
                onHide={() => setShowFilterModal(false)}
                className={"heightAuto fontIOS"}>
                <Modal.Header>
                    <Modal.Title>
                        Timeline Filter
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col xs={4}>
                        <ColorCheckbox
                            label={"Show Events"}
                            value={filters.showEvents}
                            onChange={() => setFilters({...filters, showEvents: !filters.showEvents})}
                        />
                    </Col>
                    <Col xs={4}>
                        <ColorCheckbox
                            label={"Show Crew Message"}
                            value={filters.showCrewMessage}
                            onChange={() => setFilters({...filters, showCrewMessage: !filters.showCrewMessage})}
                        />
                    </Col>
                    <Col xs={4}>
                        <ColorCheckbox
                            label={"Show Customer Notes"}
                            value={filters.showNotes}
                            onChange={() => setFilters({...filters, showNotes: !filters.showNotes})}
                        />
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="warning"
                        onClick={() => setShowFilterModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        bsStyle="success"
                        onClick={() => {
                            setShowFilterModal(false)
                            reload()
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>}
        </div> : <div className="customer-timeline vhCenter">
            <MDSpinner size={70} borderSize={2}/>
        </div>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTimeline)