import React from 'react';
import './AdminTransactions.css';
import * as actions from '.././TransactionsAPI'
import AdminTransactionsTable from './AdminTransactionsTable';
import {Button, Col, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import LeadsFilterModal from "../../../LeadsPage/LeadsFilterModal";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import AdminTransactionsFilters from './AdminTransactionsFilters';
import {SizePerPageDropDown} from "react-bootstrap-table";
import ResourceComponent from "../../../../components/ResourceComponent";
import {debounce} from "throttle-debounce";
import moment from "moment-timezone";
import SearchTransactionModal from "./SearchTransactionModal";

class AdminTransactions extends ResourceComponent {
    constructor(props) {
        super(props);

        this.delayedSearch = debounce(500, this.search);

        this.state = {
            isModalOpen: false,
            isSearchTransactionModalOpen: false,
            transactions: [],
            lockPageSwitch: true,
            prev_before: null,
            next_after: null,
            next_after_fallback: null,
            resource: {
                sort: 'ASC',
                sort_by: 'created_at',
                page: 1,
                per_page: 25,
                created_from: null,
                created_to: null,
                available_from: null,
                available_to: null,
                amount_from: null,
                amount_to: null,
                fee_amount_from: null,
                fee_amount_to: null,
                customer_ids: []
            },
            transaction_types: [
                {value: "payout", label: "Payout"},
                {value: "payment", label: "Payment"},
                {value: "charge", label: "Charge"},
                {value: "refund", label: "Refund"},
                {value: "payment_refund", label: "Payment refund"},
                {value: "transfer_refund", label: "Transfer refund"},
                {value: "refund_failure", label: "Refund failure"},
                {value: "payout_cancel", label: "Payout cancel"},
                {value: "payout_failure", label: "Payout failure"},
                {value: "application_fee", label: "Application fee"},
                {value: "application_fee_refund", label: "Application fee refund"},
                {value: "stripe_fee", label: "Convenience fee"}
            ],
            statuses: [
                {value: "pending", label: "Pending"},
                {value: "available", label: "Available"}
            ],
            filterSelectedCustomers: []
        };
    }

    search() {
        this.getAdminTransactions();
    }

    onChangeFilters = (key, value) => {
        const {resource} = this.state;
        this.setState(() => ({resource: {...resource, [key]: value}}))
    };

    onChangeSelectedCustomers = (selectedCustomers) =>{
        this.setState({filterSelectedCustomers: selectedCustomers})
    }

    onChangeResource = (value) => {
        this.setState(() => ({resource: value}), this.delayedSearch)
    };

    onPageChange = (page, per_page) => {
        const prevPage = this.state.resource.page;
        const prevPerPage = this.state.resource.per_page
        this.setState({resource : {...this.state.resource, page, per_page}}, ()=> {
            if(prevPerPage!==per_page){
                this.getAdminTransactions()
            }
            else{
                if(prevPage < page){
                    this.getAdminTransactions(false, true)
                }
                else if(prevPage > page) {
                    this.getAdminTransactions(true, false)
                }
                else{
                    this.getAdminTransactions()
                }
            }

        });
    };

    toggleModal = (e) => {
        e.preventDefault();
        const {isModalOpen} = this.state;
        this.setState({isModalOpen: !isModalOpen});
    };

    getAdminTransactions = (isPrevPage=false, isNextPage=false) => {
        const {resource, prev_before, next_after} = this.state;
        let pageNav;
        if(isPrevPage){
            pageNav={prev_before}
        }
        else if(isNextPage){
            pageNav={next_after}
        }
        this.props.actions.fetchStripeTransactions(resource, pageNav, response => {
            const {transactions} = response
            if(!isPrevPage && !isNextPage){
                this.setState({resource: {...resource, page: 1}});
            }
            this.setState({transactions});
            if(transactions.length > 0){
                if(next_after){
                    this.setState({
                        next_after_fallback: next_after
                    })
                }
                this.setState({
                    next_after: response.next_after,
                    lockPageSwitch: false
                },()=>{
                    if(this.state.resource.page>1){
                        this.setState({
                            prev_before: response.prev_before
                        })
                    }
                });

            }
            else if(isNextPage){
                this.setState({
                    resource: {...this.state.resource, page: this.state.resource.page-1},
                    next_after: this.state.next_after_fallback
                }, ()=>{this.getAdminTransactions(false, true)})
            }
        })

    };

    componentDidMount() {
        const {resource} = this.state;
        this.setState({
            resource: {
                ...resource,
                created_from: moment().subtract(30, "days").startOf('day'),
                created_to: moment().endOf('day')
            }
        },this.getAdminTransactions)


    }

    onToggleDropDown = (toggleDropDown) => toggleDropDown();

    renderSizePerPageDropDown = (props) => {
        const {per_page} = this.state.resource;
        const {toggleDropDown} = props;

        return (
            <SizePerPageDropDown
                className='my-size-per-page'
                variation='dropup'
                onClick={() => this.onToggleDropDown(toggleDropDown)}
                currSizePerPage={per_page}
            />
        );
    };

    modalInfoContent = () => (
        <div className="vcenter">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-9 font20"/>
            <span className="font10">Maximum number of fetching stripe records is 100. It is recommended first set one of date filters to refine searches</span>
        </div>
    );

    renderPaginationPanel = (props) => {
        const {page, per_page} = this.state.resource
        const {lockPageSwitch} = this.state

        const switchToPreviousPage = () =>{
            if(!lockPageSwitch){
                this.setState({lockPageSwitch: true},()=> {this.onPageChange(page-1,per_page)})
            }
        }

        const switchToNextPage = () =>{
            if(!lockPageSwitch){
                this.setState({lockPageSwitch: true},()=> {this.onPageChange(page+1, per_page)})
            }
        }
        return (
            <>
                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    { props.components.sizePerPageDropdown }
                </div>
                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6" style={{display: 'block'}}>
                    <ul className="react-bootstrap-table-page-btns-ul pagination">
                        <li className={"page-item "+`${page===1 || lockPageSwitch ? 'disabled': 'pointer'}`} title="previous page">
                            <span onClick={()=>{switchToPreviousPage()}} className="page-link">&lt;</span>
                        </li>
                        <li className="page-item"><span className="page-link">{page}</span></li>
                        <li className={"page-item "+`${lockPageSwitch ? 'disabled': 'pointer'}`} title="next page">
                            <span onClick={()=>{switchToNextPage()}} className="page-link">&gt;</span>
                        </li>
                    </ul>
                </div>
            </>
        );
    }

    indexN = (cell, row, enumObject, index) => {
        const {page, per_page} = this.state.resource
        return(
            <div>{(page*per_page-per_page)+index + 1}</div>
        )
    }

    render() {
        const {isModalOpen, transactions, resources, resource, transaction_types, statuses, filterSelectedCustomers, isSearchTransactionModalOpen} = this.state;
        const transactionsTotal = transactions && transactions.length;

        return (
            <div id="admin_transactions">
                <Row>
                    <Col md={6} className="transactions_header_wrapper">
                        <h4 className="transactions_title">Transactions</h4>
                        <h5 className="transactions_count">{transactionsTotal} returned</h5>
                    </Col>
                    <Col md={6} className="justify-flex-end">
                        <Button bsStyle="success" className="transactions_btn mr-7"
                                onClick={() => this.setState({isSearchTransactionModalOpen: !isSearchTransactionModalOpen})}>
                            Search Stripe transaction
                            <FontAwesomeIcon icon="search" className="small-margin ml-6 no-right-margin"/>
                        </Button>
                        <Button bsStyle="success" className="transactions_btn mr-7"
                                onClick={() => this.props.actions.downloadStripeTransactionsCsv(resources)}>
                            Export to CSV
                        </Button>
                        <Button className="transactions_btn"
                                onClick={e => this.toggleModal(e)}> Filter
                            <FontAwesomeIcon icon="search" className="small-margin ml-6 no-right-margin"/>
                        </Button>
                    </Col>
                </Row>
                <AdminTransactionsTable
                    transactions={transactions}
                    resource={resource}
                    onChangeResource={this.onChangeResource}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    per_page={this.state.per_page}
                    renderSizePerPageDropDown={this.renderSizePerPageDropDown}
                    renderPaginationPanel={this.renderPaginationPanel}
                    indexN={this.indexN}
                />
                {isModalOpen &&
                <LeadsFilterModal
                    title="Transactions Filter"
                    show={isModalOpen}
                    onHide={() => this.setState({isModalOpen: !isModalOpen}, () => this.getAdminTransactions())}
                    closeButton={() => this.setState({isModalOpen: !isModalOpen})}
                    infoContent={this.modalInfoContent()}
                >
                    <AdminTransactionsFilters
                        selectedCustomers={filterSelectedCustomers}
                        transaction_types={transaction_types}
                        statuses={statuses}
                        resource={resource}
                        selectAttr={this.selectResourceAttr}
                        onChangeFilters={this.onChangeFilters}
                        onChangeSelectedCustomers={this.onChangeSelectedCustomers}
                        onSearch={this.props.actions.getCustomersSearch}
                    />
                </LeadsFilterModal>
                }
                {isSearchTransactionModalOpen &&
                    <SearchTransactionModal
                        onHide={() => this.setState({isSearchTransactionModalOpen: !isSearchTransactionModalOpen})}
                        searchTransaction={this.props.actions.searchTransaction}
                    />
                }
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(null, mapDispatchToProps)(AdminTransactions);