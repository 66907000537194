import {apiCall} from "../../../../common/apiActionHandler";
import axios from "axios";
import {addAlert} from "../../../App/actions";
import {DELETED_USER, SAVED_USER_INFO} from "../../constants";
import {fetchUsers} from "../../actions";
import {handleUpload} from "../../../../common/upload";
import {UPDATE_ROLES} from "../../../LoginPage/constants";

export const load = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/search/all_employees`
    };

    apiCall("Loading data", config, async config => {
        const response = await axios.request(config);
        callback({employees: response.data});
    }, dispatch, getState, false);
};

export const loadUserActivities = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/user_activities/`,
        params
    };
    apiCall("Loading user activities", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadUser = (user_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${user_id}`,
        params: {all_data: true}
    };
    apiCall("Loading user", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadUsers = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users`,
        params
    };
    apiCall("Loading users", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const deleteUser = (userId, callback) => async (dispatch, getState) => {

    const store = getState();
    const config = {
        method: "DELETE",
        url: `/api/users/${userId}`,
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        dispatch(
            addAlert({
                message: "User deleted",
                mood: "success",
                dismissAfter: 1000,
            })
        );
        dispatch({type: DELETED_USER});
        const clientId = store.client.customerInfo.id;
        dispatch(fetchUsers(clientId));
        callback && callback();
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not deleted because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }
};

export const saveUserInfo = (user, callback) => async (dispatch, getState) => {
    const store = getState()
    delete user.disabled;
    const clientId = store.client.customerInfo.id
    const userId = user.id;
    const method = userId ? "PUT" : "POST";
    const url = userId ? `/api/users/${userId}` : `/api/users/`;
    const config = {
        method,
        url,
        data: {user}
    };

    apiCall(userId ? "User updated" : "User saved", config, async config => {
        const response = await axios.request(config);
        response.data.id && dispatch({type: SAVED_USER_INFO, id: response.data.id});
        dispatch(fetchUsers(clientId));
        callback && callback(response.data);
    }, dispatch, getState);
};

export const validateUser = user => (dispatch) => {
    if (!user.person_id) {
        dispatch(
            addAlert({
                message: "Employee missing",
                mood: "danger",
                dismissAfter: 1000,
            })
        );
        return false;
    }
    return true;
};

export function fetchUserActivities(callback) {
    return function (dispatch, getState) {
        const store = getState();
        const config = {
            method: 'get',
            url: `/api/admin/activities`,
            headers: {Authorization: store.auth.token},
        };
        apiCall("Loading user activities", config, async config => {
            const response = await axios.request(config);
            callback && callback(response.data);
        }, dispatch, getState, false);
    }
}

export const uploadImages = (acceptedFiles, user_id, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload?user_id=${user_id}`, acceptedFiles, callback)(dispatch, getState);
};

export const uploadAttachment = (acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload('/api/emails/upload_attachment', acceptedFiles, callback)(dispatch, getState);
};


export const deleteDevice = (params, callback) => (dispatch, getState) => {
    const config = {
        method: "delete",
        url: `/api/access_tokens/${params.user_id}`,
        params
    };

    apiCall("Deleting device", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const loadDevices = (id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/access_tokens/${id}`,
    };

    apiCall("Getting devices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const rotateImage = (id, direction, callback) => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "POST",
        url: "/api/images/rotate",
        headers: {Authorization: store.auth.token},
        params: {id, direction}
    }

    return axios.request(config).then(response => {
        callback && callback(response);
        dispatch(
            addAlert({
                message: response.data.message,
                mood: "success",
                dismissAfter: 1500,
            })
        )
    })
}

export const setAsPrimary = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "PUT",
        url: `/api/users/${id}/set_primary_image`,
    };

    apiCall("Setting image as primary", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
export const getActiveUsersCount = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/count_client_active_users`,
        method: 'GET'
    };
    apiCall("Active users count get successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const loadStripeCredentials = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/stripe_credentials`,
        method: 'GET',
    };
    apiCall("Loading stripe credentials", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const updateSubscription = (subscription, callback) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: '/api/stripe/update_subscription',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {subscription}
    }

    apiCall("Subscription Updated", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}
export const getClientPrice = (client_price_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_client_price`,
        method: 'GET',
        params: {
            client_price_id: client_price_id
        }
    };
    apiCall("Get client price", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getStripeSettings = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_stripe_settings`,
        method: 'GET'
    };
    apiCall("Get stripe settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadNotSelectedEmployee = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/search/not_assigned_employees`
    };

    apiCall("Get employees", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const updateRolesInState = (roles) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_ROLES,
        roles: roles
    });
};
