import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Grid} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import * as api from "./FollowUpsListApi";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {LinkContainer} from "react-router-bootstrap";
import moment from "moment"
import {defaultDateTimeFormat} from "../../common/commonHandlers";

const actions = {...api};

class FollowUpsList extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: {}
        };
    }

    reload = () => {
        const query = this.props.location.query;
        const filters = {
            person_id: this.props.params.id,
            label: query.label,
            service_type: query.service_type ? parseInt(query.service_type) : null,

        };
        this.props.actions.loadReport(filters, result => this.setState({resource: result.main_report}));
    };

    componentDidMount() {
        this.reload();
    };

    proposalFormatter = (cell, row) => {
        return <LinkContainer to={`/mapview/${row.proposal_id}`}>
            <span className={'text-blue pointer'}>{row.proposal_no}</span>
        </LinkContainer>
    };

    dateFormatter = (cell, row) => {
        return cell ? moment(cell).format(defaultDateTimeFormat) : '-';
    };


    render() {
        const {
            resource
        } = this.state;
        return (
            <Grid fluid>
                <BootstrapTable
                    ref="table"
                    data={resource && resource.length > 0 && resource}
                    striped={true}
                    bordered={false}
                    hover={true}
                    remote
                    className="wrapped"
                >
                    <TableHeaderColumn
                        dataField="proposal_no"
                        dataFormat={this.proposalFormatter}
                        width={"10%"}
                        isKey
                    >
                        Proposal No
                    </TableHeaderColumn>

                </BootstrapTable>
            </Grid>
        );
    }
}

FollowUpsList.propTypes = {
    //myProp: PropTypes.object.isRequired
};

FollowUpsList.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpsList);