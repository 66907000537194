import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../common/commonHandlers";

export const loadReport = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sales_actual`,
        params: filter
    };

    apiCall("Getting sales report", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees?disabled=true`},
        {url: `/api/sales_cross_view/service_types`},
        {url: `/api/users/get_reports_settings`},
        {url: `/api/services`},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const services = all[3].data.filter(s => !s.disabled).sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
        callback({
            employees: all[0].data,
            service_types: mapForSelect(all[1].data),
            reports_settings: all[2].data,
            services: mapForSelect(services),
        });
    }, dispatch, getState, false);
};

export const saveFilter = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_reports_settings`,
        method: 'PUT',
        data: {resource}
    };
    apiCall("Saving reports settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
