import moment from "moment-timezone";

const multiSelectFilter = (data, dataFiledName, filterValue) => {
    if (filterValue.length > 0) {
        return data.filter((data_val) => filterValue.includes(data_val[dataFiledName]))
    } else {
        return data
    }

}

const isObject = (obj) => (typeof obj === 'object' && !Array.isArray(obj) && obj !== null)

const dateFilter = (data, dataFiledName, filterValue) => {
    if (isObject(filterValue.from) && isObject(filterValue.to) && filterValue.from.isSameOrBefore(filterValue.to)) {
        return data.filter((data_val) => {
            return moment(data_val[dataFiledName]).isBetween(filterValue.from.startOf('day'), filterValue.to.endOf('day'), null, '[]')
        })
    } else if (isObject(filterValue.from)) {
        return data.filter((data_val) => moment(data_val[dataFiledName]).isSameOrAfter(filterValue.from.startOf('day')))
    } else if (isObject(filterValue.to)) {
        return data.filter((data_val) => moment(data_val[dataFiledName]).isSameOrBefore(filterValue.to.endOf('day')))
    } else {
        return data
    }
}

const numberFilter = (data, dataFiledName, filterValue) => {
    if (Number.isFinite(filterValue.from) && Number.isFinite(filterValue.to)) {
        return data.filter((data_val) => (
            (parseFloat(data_val[dataFiledName]?.toString().replace(/[^0-9.-]/g, '')) || 0) >= filterValue.from &&
            (parseFloat(data_val[dataFiledName]?.toString().replace(/[^0-9.-]/g, '')) || 0) <= filterValue.to))
    } else if (Number.isFinite(filterValue.from)) {
        return data.filter((data_val) => (parseFloat(data_val[dataFiledName]?.toString().replace(/[^0-9.-]/g, '')) || 0) >= filterValue.from)
    } else if (Number.isFinite(filterValue.to)) {
        return data.filter((data_val) => (parseFloat(data_val[dataFiledName]?.toString().replace(/[^0-9.-]/g, '')) || 0) <= filterValue.to)
    } else {
        return data
    }

}

const tableFilter = (data, filterValue) => {
    let outputData = data

    for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
            switch (filterValue[key].type) {
                case 'multiselect':
                    outputData = multiSelectFilter(outputData, `${key}_id`, filterValue[key].id)
                    break;
                case 'date':
                    outputData = dateFilter(outputData, key, filterValue[key].value)
                    break;
                case 'number':
                    outputData = numberFilter(outputData, key, filterValue[key].value)
                    break;
                default:
                    break;
            }
        }
    }

    return outputData
}

export default tableFilter;

