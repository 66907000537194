import axios from "axios"

import {addAlert} from "../App/actions"
import {
    EDITING_SITE_ID,
    FETCH_SITE,
    CREATE_NEW_SITE,
    UPDATE_SITE_ATTR,
    FETCH_SITE_CONTACT,
    FETCH_STATE_AND_CITY,
    DELETE_SITE, FETCH_SEARCHED_CUSTOMERS_MODAL,
} from "./constants"

import {FETCH_SITES} from "../CustomerPage/CustomerInfo/constants"
import {FETCH_SEARCHED_CUSTOMERS, UPDATE_CUSTOMER_SEARCH} from "../CustomerPage/CustomerSites/constants";
import {apiCall} from "../../common/apiActionHandler";
import {dataUpload} from "../../common/upload";
import {mapForSelect} from "../../common/commonHandlers";

//import { fetchCustomerContacts } from "../CustomerPage/CustomerInfo/actions"
export const createNewSite = customerId => {
    return {type: CREATE_NEW_SITE, customerId}
}

const _fetchSite = () => async (dispatch, getState) => {
    const store = getState()
    const siteId = store.siteEditor.editingSiteId
    if (siteId === null || siteId < 1) {
        console.info("Site Id was not sensical so not fetching")
        return false
    }

    const config = {
        url: `/api/sites/${siteId}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SITE, site: response.data})
        dispatch(_fetchSiteContact())
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch the site " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

const validateForm = site => {
    const requiredFields = [
        "id",
        "customer_id",
        "customer_contact_id",
        "name",
        "address",
        "city",
        "state",
        "zip",
        "latitude",
        "longitude",
        "full_address",
    ]

    const invalidFields = requiredFields.some(prop => {
        if (
            site[prop] === null ||
            site[prop] === undefined ||
            site[prop].toString().trim().length < 1
        ) {
            return true
        } else {
            return false
        }
    })

    if (invalidFields) {
        return false
    }

    if (site.customer_contact_id.toString() === "0") {
        return false
    }

    return true
}

export const fetchStateAndCity = zipcode => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/customers/zipcode_search",
        headers: {Authorization: store.auth.token},
        params: {
            zipcode: zipcode,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_STATE_AND_CITY, location: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your state and city from zipcode because " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const geocodeAddress = async (address, token) => {
    const config = {
        url: "/api/sites/geocode",
        headers: {Authorization: token},
        params: {
            address,
        },
    };
    try {
        const response = await axios.request(config);
        const addresses = response.data && response.data.results

        let site_addresses;
        if (addresses && addresses.length > 0) {
            const keys = ["USA", "United States"]
            const countryAddress = addresses.filter(r => r.formatted_address && (r.formatted_address.includes(keys[0]) || r.formatted_address.includes(keys[1])))

            if (countryAddress && countryAddress.length > 0) {
                site_addresses = countryAddress
            } else {
                site_addresses = response.data.results
            }
        } else {
            site_addresses = response.data.results
        }
        return site_addresses
    } catch (error) {
        throw error
    }
};

export const saveSite = () => async (dispatch, getState) => {
    const store = getState()
    const siteKey = Object.keys(store.siteEditor.site)[0]
    let site = {...store.siteEditor.site[siteKey]}
    site.full_address = `${site.address}, ${site.city}, ${site.state}, ${site.zip}`
    const customerId = store?.customerInfo?.customerInfo?.id

    try {
        const coords = await geocodeAddress(site.full_address, getState().auth.token)
        site.latitude = coords[0].geometry.location.lat
        site.longitude = coords[0].geometry.location.lng
    } catch (error) {
        dispatch(
            addAlert({
                message: "Unable to geocode the address. Please modify it and try again! " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 3500,
            })
        )
        throw error
        return false
    }

    const isValid = validateForm(site)
    if (!isValid) {
        dispatch(
            addAlert({
                message: "Some required fields are missing. Please check the form and try again.",
                mood: "danger",
                dismissAfter: 3500,
            })
        )
        return false
    }

    const isNew = site.id === 0 ? true : false

    const config = {
        method: isNew ? "POST" : "PUT",
        url: isNew ? "/api/sites" : `/api/sites/${site.id}`,
        headers: {Authorization: store.auth.token},
        data: {site: {...site, id: undefined, __dirty: undefined}, customer_id: customerId},
    }

    try {
        const _ = await axios.request(config)
        dispatch(
            addAlert({
                message: `${isNew ? "Created" : "Edited"} site!`,
                mood: "success",
                dismissAfter: 1500,
            })
        )
        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not ${isNew ? "CREATE" : "EDIT"} the site ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

const _fetchSiteContact = () => async (dispatch, getState) => {
    const store = getState()
    const site_id = store.siteEditor.editingSiteId

    if (site_id.toString() === "0") {
        console.log("Not fetching site contact because this is a new site")
        return false
    }

    const config = {
        url: "/api/site_contacts/search",
        headers: {Authorization: store.auth.token},
        params: {site_id},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SITE_CONTACT, siteContact: response.data})
        if (response.data && response.data.customer_contact_id) {
            dispatch(
                updateSiteAttr("customer_contact_id", response.data.customer_contact_id)
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not fetch the Site Contact ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const updateSiteAttr = (attr, newVal) => (dispatch, getState) => {
    console.log("updating site attr", attr, newVal)
    const store = getState()
    const siteKey = Object.keys(store.siteEditor.site)[0]
    let site = {...store.siteEditor.site[siteKey]}
    site[attr] = newVal
    site["__dirty"] = true
    site = {[siteKey]: site}
    dispatch({type: UPDATE_SITE_ATTR, site})
}

export const initializeEditor = () => async dispatch => {
    //get the site and its contact. when both come back then stuff the site contact into the site so we can edit in 1 place
    dispatch(_fetchSite())
}

export const editingSiteId = editingSiteId => ({
    type: EDITING_SITE_ID,
    editingSiteId,
})

//DELETE SITE
export const deleteSite = (id) => (dispatch, getState) => {

    if (id === 0) {
        //delete it locally assuming it will be deleted on the server
        dispatch({type: DELETE_SITE, id})
        return true
    }

    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/sites/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'warning', dismissAfter: 1500}))
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Site! It likely has Assets or Proposals...',
                mood: 'warning',
                dismissAfter: 3500
            }))
            dispatch(addAlert({message: `Could not Delete site ${response}`, mood: 'danger', dismissAfter: 2000}))
        })
}

export const updateCustomerSearch = customerSearch => dispatch => {
    dispatch({type: UPDATE_CUSTOMER_SEARCH, customerSearch})
    dispatch(searchForCustomer())
}

export const searchForCustomer = () => async (dispatch, getState) => {
    const store = getState()
    const searchQuery = store.customerProposals.customerSearch

    if (searchQuery.length < 2) {
        return false
    }

    const config = {
        method: "get",
        url: `/api/customers`,
        headers: {Authorization: store.auth.token},
        params: {
            q: searchQuery,
        },
    }
    try {
        const response = await axios.request(config)
        dispatch({
            type: FETCH_SEARCHED_CUSTOMERS_MODAL,
            foundCustomers: response.data,
        })
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to search for customers because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const fetchSiteContacts = (site_id, customer_id, callback) => (dispatch, getState) => {
    const config = {
        method: "get",
        url: "api/site_contacts/search",
        params: {site_id: site_id, customer_id: customer_id}
    }

    apiCall("Fetch site contacts", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const fetchContacts = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: "/api/customer_contacts/rolodex",
        params: {customer_id: customerId}
    }

    apiCall("Getting contacts", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const uploadAttachment = (acceptedFiles, id, callback) => (dispatch, getState) => {
    dataUpload(`/api/site_files?site_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteAttachment = (id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/site_files/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}

export const reorderAttachment = (newImageOrder, site_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sites/${site_id}/update_doc_order`,
        method: 'PUT',
        data: {new_doc_order: newImageOrder}
    };

    apiCall("Reordering attachments", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const selectAttachmentForPrint = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/site_files/${id}/select_for_proposal`,
        method: 'PUT'
    };

    apiCall("Updating file", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const fetchOrdinances = (zip, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/ordinance_and_laws/`,
        method: 'GET',
        params: {zip: zip}
    };
    apiCall("Fetch site ordinances", config, async config => {
        const response = await axios.request(config);
        callback && callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const getMovedProposals = (site_id, new_customer_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/get_moved_proposals`,
        params: {site_id, new_customer_id}
    };

    apiCall("Getting proposals", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};