import {
    SET_ADMIN_TERMS_REQUIRED,
    SET_ADMIN_TERMS_STATUS,
    SET_USER_TERMS_STATUS,
    SET_OPTIONAL_GEOLOCATION,
    APP_STATE_LOADING,
    SET_REDIRECT_TO_SUBSCRIPTION_ROUTER,
    SET_SUBSCRIPTION_STATUS,
    SET_SUBSCRIPTION_REMINDER
} from '../App/constants'

const initialState = {
    userTerms: false,
    adminTerms: false,
    adminTermsRequired: true,
    optionalGeolocation: true,
    loading: true,
    redirectRequired: false
}

export function permissionReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_TERMS_STATUS:
            return {...state, userTerms: action.status}
        case SET_ADMIN_TERMS_STATUS:
            return {...state, adminTerms: action.status}
        case SET_OPTIONAL_GEOLOCATION:
            return {...state, optionalGeolocation: action.status}
        case APP_STATE_LOADING:
            return {...state, loading: action.status}
        case SET_ADMIN_TERMS_REQUIRED:
            return {...state, adminTermsRequired: action.status}
        case SET_REDIRECT_TO_SUBSCRIPTION_ROUTER:
            return {...state, redirectRequired: action.status}
        case SET_SUBSCRIPTION_STATUS:
            return {...state, redirectRequired: action.status}
        case SET_SUBSCRIPTION_REMINDER:
            return {...state, redirectRequired: action.status}
        default:
            return state
    }
}
