import {FETCH_CONTACT_TYPES_LIST} from '../constants'

const initialState = {
    contactTypesList: [],
    contactTypes: {},
    contactTypesListLastUpdated: 0,
    editingContactTypeId: null,
    filterText: '',
};
// DEPRECATED
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTACT_TYPES_LIST:
            return {
                ...state,
                contactTypesList: action.contactTypesList,
                contactTypesListLastUpdated: action.contactTypesListLastUpdated,
            };
        default:
            return state
    }
}
