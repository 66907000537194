const initialState = {
    hazard: null,
    deletedHazard: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_HAZARD_INFO':
            return {hazard: action.hazard};
        case 'DELETE_HAZARD':
            return {deletedHazard: action.hazard};
        default:
            return state
    }
}
