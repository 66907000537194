import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import * as Actions from './actions'
import {Button, Col, ControlLabel, FormGroup, Grid, Row} from 'react-bootstrap'
import PageHeader from '../../components/PageTitle'
import {LinkContainer} from 'react-router-bootstrap'
import Helmet from 'react-helmet'
import Select from "react-select";

class ClientDashboard extends Component {

    render() {
        const {client, theme} = this.props;
        const themes = ['cerulean', 'cosmo', 'cyborg', 'darkly', 'flatly',
            'journal', 'lumen', 'paper', 'readable', 'sandstone', 'simplex',
            'slate', 'solar', 'spacelab', 'superhero', 'united', 'yeti'].map(x => ({value: x, label: x}));
        return (
            <Grid fluid>
                <Helmet title="Client Dashboard"/>
                <PageHeader
                    pageName="Client Dashboard"
                    pageDetail=""
                />

                <Row>
                    <Col xs={6}>
                        <LinkContainer to={`clients/${client}`}>
                            <Button>
                                Preferences
                            </Button>
                        </LinkContainer>
                    </Col>
                </Row>
                <p>&nbsp;</p>
                <Row>
                    <Col xs={2}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>Theme</ControlLabel><br/>
                            <Select className="Select" classNamePrefix="select"
                                value={{value: theme, label: theme}}
                                options={themes}
                                onChange={e => this.props.actions.updateTheme(e.value)}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme.theme,
        client: state.auth.client_id
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard)
