import {FETCH_TRUCKS_LIST} from '../constants'

import axios from 'axios'

// TODO: DEPRECATED
export const fetchTrucksList = () => (dispatch, getState) => {
    const store = getState();
    const config = {
        url: '/api/trucks',
        headers: {Authorization: store.auth.token},
    };

    return axios.request(config).then(response => {
        dispatch({
            type: FETCH_TRUCKS_LIST,
            trucksList: response.data,
            trucksListLastUpdated: Date.now(),
        })
    })
};