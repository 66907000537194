import * as FileSaver from "file-saver";
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../../common/apiActionHandler";
import {addAlert} from "../../App/actions";
import axios from "axios/index";
import {mapForSelect} from "../../../common/commonHandlers";
import moment from "moment";
import Compress from "compress.js";
import {dataUpload} from "../../../common/upload";


export const downloadPdf = (proposal, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${proposal.token}/pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${proposal.download_name}-Proposal #${proposal.proposal_no}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(proposal ? proposal : {}), source: "proposal pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback();
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to generate pdf.",
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const showJSADialog = (options) => async dispatch => {
    dispatch({type: 'SHOW_JSA_DIALOG', options});
};

export const showEmailDialog = (options) => async dispatch => {
    dispatch({type: 'SHOW_EMAIL_DIALOG', options});
};

export const duplicateProposal = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: `/api/proposals/${id}/duplicate`
    };

    apiCall("Duplicating proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const moveToProposed = (proposalId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposals/${proposalId}/propose`,
        method: "post",
    };

    apiCall("Moving to proposed", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const searchForProposal = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/search`,
        params: {q: searchText}
    };

    apiCall("Searching for proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data.map(x => ({value: x.id, label: x.name, lead_id: x.lead_id})));
    }, dispatch, getState, false);
};

export const load = (id, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/new_proposals/search`, params: id ? {id} : {q: ''}},
        {url: `/api/new_proposals/proposal_menu`, params: id && {id}},
        {url: `/api/time_track_types`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            proposals: all[0].data.map(x => ({value: x.id, label: `Proposal #${x.name}`})),
            proposalMenu: all[1].data,
            time_track_types: mapForSelect(all[2].data),

        });
    }, dispatch, getState, false);
};
export const downloadCsv = (params) => async (dispatch, getState) => {
    const config = {
        url: `/api/assets/sheet.xlsx`,
        params: {site_id: params},
        responseType: 'blob'

    };
    apiCall("Downloading xlsx", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/xlsx',
        });
        FileSaver.saveAs(blob, `asset-${moment().format()}.xlsx`);

        blob.name = `asset-${moment().format()}.xlsx`
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(params ? params : {}), source: "assets"}, false)(dispatch, getState)
    }, dispatch, getState);
};
export const changeDisplayAssets = (proposal_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/${proposal_id}/change_display_assets`,
        method: 'put',
    };
    apiCall("Changed display assets", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};