//import deepFreeze  from 'deep-freeze'


const initialState = {
    theme: 'simplex'
}

export default function theme(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_THEME':
            return {...state, theme: action.theme, previousTheme: state.theme};
        default:
            return state;
    }
}


