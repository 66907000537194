import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {colors, merge, select, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Select from "react-select";
import {CirclePicker} from "react-color";

const scheduler_event_types = createApiService('scheduler_event_types', 'scheduler_event_type', 'Scheduler event type');

const api = {...scheduler_event_types};

class SchedulerEventTypes extends ResourceComponent {
    defaultValues = {
        name: '',
        resource: null,
        color: '',
        resource_required: false,
        assign_order: null
    };
    state = {};

    updateResources = resources => {
        this.defaultValues.assign_order = Math.max(...resources.map(x => x.assign_order).filter(n => n)) + 1;
        this.setState({resources})
    };

    componentDidMount = () => {
        const {client_id} = this.props;
        this.props.actions.load({}, this.updateResources);
    };

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    render() {
        const {roles} = this.props;
        const {resource, resources, showPicker} = this.state;
        const options = [
            {value: 'WorkOrder', label: 'Work Order'},
            {value: 'Lead', label: 'Lead'}
        ];
        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Scheduler event type"
                        resourceNamePlural="Scheduler event types"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                this.props.actions.save(resource, this.reloadResources)
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource && <div>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Name
                                        </ControlLabel>
                                        <FormControl
                                            name="name"
                                            onChange={this.updateResourceAttr}
                                            value={resource.name}
                                        />
                                        {this.state.errorMessage && <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Context resource</ControlLabel>
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(options, resource.resource)}
                                                options={options}
                                                isClearable
                                                defaultValue={null}
                                                onChange={this.selectResourceAttr('resource')}
                                        />
                                    </FormGroup>
                                    {resource.resource &&
                                    <FormGroup>
                                        <ColorCheckbox value={resource.resource_required}
                                                       label="Context resource required"
                                                       onChange={this.selectCheckboxAttr('resource_required')}/>
                                    </FormGroup>
                                    }
                                    {(roles && roles.includes('admin')) && <FormGroup>
                                        <ControlLabel>
                                            Color
                                        </ControlLabel>

                                        <div className={`c${resource.color} color-picker pointer visible bigger`}
                                             onClick={() => this.setState({showPicker: !showPicker})}/>
                                        {showPicker && <CirclePicker width={400} colors={colors} onChange={e => {
                                            this.setState({showPicker: false}, () => {
                                                this.selectCheckboxAttr('color')(colors.findIndex(c => c === e.hex));
                                            });
                                        }}/>}
                                    </FormGroup>}
                                    <FormGroup>
                                        <ControlLabel>
                                            Assign order
                                        </ControlLabel>
                                        <FormControl
                                            name="assign_order"
                                            type="number"
                                            onChange={this.updateResourceAttr}
                                            value={resource.assign_order}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

const mapStateToProps = (state) => ({
    roles: state.auth.roles,
    client_id: state.auth.client_id
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerEventTypes)
