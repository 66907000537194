import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../common/commonHandlers";

export const load = (id, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/crew_work_orders/${id}`, params: {scheduler_event_id: id}},
        {url: `/api/time_track_types`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            resource: all[1].data,
            time_track_types: mapForSelect(all[2].data)
        });
    }, dispatch, getState, false);
};

export const loadCrewWorkOrders = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/${id}`,
        params: {
            scheduler_event_id: id
        }
    };
    apiCall("Loading work orders", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const showEmailDialog = (options) => async dispatch => {
    dispatch({type: 'SHOW_EMAIL_DIALOG', options});
};

export const showJSADialog = (options) => async dispatch => {
    dispatch({type: 'SHOW_JSA_DIALOG', options});
};


export const saveCrewWorkOrder = (work_order, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/${work_order.id}`,
        data: {work_order},
        method: 'PUT',
        params: {
            scheduler_event_id: work_order.scheduler_event_id
        }
    };

    apiCall("Saving crew work order", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const updateSiteNotes = (site_id, site_notes, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/${site_id}/update_site_notes`,
        method: 'POST',
        data: {
            notes: site_notes
        }
    };

    apiCall("Saving site notes", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const addTimeTrack = (work_order_id, time_track_type_id, person_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/add_time_track`,
        method: 'POST',
        data: {
            work_order_id: work_order_id,
            time_track_type_id: time_track_type_id,
            person_id: person_id
        }
    };

    apiCall("Add Time track", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const removeTimeTrack = (time_track_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/time_tracks/${time_track_id}`,
        method: 'DELETE'
    };

    apiCall("Remove Time track", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const startTimeTracks = (time_track_ids, start_time, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/start_time_tracks`,
        method: 'POST',
        data: {
            time_track_ids: time_track_ids,
            start_time: start_time
        }
    };

    apiCall("Start time", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const stopTimeTracks = (time_track_ids, stop_time, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/stop_time_tracks`,
        method: 'POST',
        data: {
            time_track_ids: time_track_ids,
            stop_time: stop_time
        }
    };

    apiCall("Stop time", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const updateJobStatus = (scheduler_event_id, stop_time, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/stop_time_tracks`,
        method: 'POST',
        data: {
            scheduler_event_id: scheduler_event_id,
            stop_time: stop_time
        }
    };

    apiCall("Update job status", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const getCrew = (scheduler_events_ids, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/get_crew`,
        method: 'POST',
        data: {scheduler_events_ids}
    };

    apiCall("Getting event", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const createConsumption = (proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/create_ps_consumption`,
        method: 'POST',
        data: {
            proposal_service_id
        }
    };

    apiCall("Creating consumables consumption", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
}

export const loadConsumables = (proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/ps_consumables?proposal_service_id=` + proposal_service_id,
    };

    apiCall("Load consumables", config, async config => {
        const result = await axios.request(config);
        callback && callback({consumables: result.data.consumables});
    }, dispatch, getState, false);
};

export const sendConsumablesConsumption = (consumption, costs, selectedMeasure, proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/add_ps_consumption`,
        method: 'POST',
        data: {
            consumption,
            costs,
            selectedMeasure,
            proposal_service_id
        }
    };

    apiCall("Saving consumables consumption", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const deleteConsumption = (proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/delete_ps_consumption`,
        method: 'DELETE',
        data: {
            proposal_service_id,
        }
    };

    apiCall("Saving consumables consumption", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};