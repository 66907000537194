import {FETCH_CUSTOMER_TYPES_LIST} from '../constants'

import axios from 'axios'

//TODO: DEPRECATED
export const fetchCustomerTypesList = () => (dispatch, getState) => {
    const store = getState();
    const config = {
        url: '/api/customer_types',
        headers: {Authorization: store.auth.token},
    };

    return axios.request(config).then(response => {
        dispatch({
            type: FETCH_CUSTOMER_TYPES_LIST,
            customerTypesList: response.data,
            customerTypesListLastUpdated: Date.now(),
        })
    })
};
