import React, {useEffect, useState} from "react";
import {Button, Col, Modal, OverlayTrigger, Row, Tab, Tabs, Tooltip} from "react-bootstrap";
import "./EquipmentChecklistModal.scss"
import {equipmentAnswerTypes, equipmentDefaultAnswer} from "../../common/commonHandlers";

const EquipmentChecklistModal = ({onHide, getEquipmentsChecklist, saveEquipmentsQuestions, getUserStatus}) => {
    const [showChecklist, setShowChecklist] = useState(false)
    const [equipmentsQuestionsLists, setEquipmentsQuestionsLists] = useState([])
    const [invalidAnswer, setInvalidAnswer] = useState(false)
    const [key, setKey] = useState(0)

    useEffect(() => {
        if (showChecklist) {
            getEquipmentsChecklist(res => {
                setEquipmentsQuestionsLists(res)
            })
        }
    }, [showChecklist])

    return <Modal
        id="equipment-checklist"
        show={true}
        className="heightAuto maxHeight90 fontIOS"
        dialogClassName={showChecklist ? 'show-checklist' : 'hide-checklist'}
    >
        <Modal.Header>
            <Modal.Title>Equipment Checklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {showChecklist ?
                <Tabs activeKey={key}
                      id="checklist-tab"
                      className="mt-15 mb-15"
                      mountOnEnter={true}
                      onSelect={key => setKey(key)}
                      animation
                >
                    {equipmentsQuestionsLists.map((list, i) => {
                        return <Tab eventKey={i} title={list.work_order_no}>
                            {Object.keys(list.equipment_questions).map(key => {
                                return <div style={{maxHeight: '500px'}}>
                                    <h4>Equipment: {key}</h4>
                                    {list.equipment_questions[key].map((q, index) => {
                                        return <Row className="question-row">
                                            <Col xs={12}>
                                                {equipmentAnswerTypes(
                                                    index + 1,
                                                    q.content.answer_type,
                                                    q.content.question,
                                                    q.content.options,
                                                    q.answer ? q.answer : equipmentDefaultAnswer(q.content.answer_type),
                                                    (res) => {
                                                        list.equipment_questions[key][index].answer = res
                                                        setEquipmentsQuestionsLists([...equipmentsQuestionsLists])
                                                        if (res?.startDate === 'Invalid date' || res?.endDate === 'Invalid date') {
                                                            setInvalidAnswer(true)
                                                        } else {
                                                            setInvalidAnswer(false)
                                                        }
                                                    },
                                                    null, false, false, q.id
                                                )}
                                            </Col>
                                        </Row>
                                    })}
                                </div>
                            })}
                        </Tab>
                    })}
                </Tabs>
                :
                <Row>
                    <Col xs={12} className="text-center">
                        <h4>Do you want to complete the checklist now?</h4>
                    </Col>
                    <Col xs={12} className="text-center bottom10">
                        <Row>
                            <Col xs={6} className="text-right">
                                <Button
                                    bsStyle="warning"
                                    onClick={() => onHide()}
                                >
                                    No, remind me later
                                </Button>
                            </Col>
                            <Col xs={6} className="text-left">
                                <Button
                                    bsStyle="success"
                                    onClick={() => setShowChecklist(true)}
                                >
                                    Yes, I will do it now
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </Modal.Body>
        <Modal.Footer>
            {showChecklist &&
                <>
                    <Button
                        bsStyle="warning"
                        onClick={() => onHide()}
                    >
                        Remind me later
                    </Button>
                    {invalidAnswer ?
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">One of the selected dates is incorrect</Tooltip>}>
                            <span className="small-button-margin-left">
                                <Button
                                    bsStyle="success"
                                    disabled={true}
                                >
                                    Submit
                                </Button>
                            </span>
                        </OverlayTrigger>
                        :
                        <Button
                            bsStyle="success"
                            onClick={() => saveEquipmentsQuestions(equipmentsQuestionsLists, () => {
                                getUserStatus()
                                onHide()
                            })}
                        >
                            Submit
                        </Button>
                    }

                </>
            }
        </Modal.Footer>
    </Modal>
}

export default EquipmentChecklistModal;