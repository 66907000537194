import React from "react";
import DefaultReportView from "./DefaultReportView";

const UnbilledHoursReportTab = ({getRevenueReport}) => {
    const reportOptions = [
        {value: 'unbilled_hours', label: 'Unbilled Hours'},
    ]

    return <DefaultReportView
        title="Unbilled Hours"
        options={reportOptions}
        getRevenueReport={getRevenueReport}
    />
}

export default UnbilledHoursReportTab;