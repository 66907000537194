import React from 'react';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Dollars from "../../../../components/Dollars";
import {Col} from "react-bootstrap";
import './AdminTransactions.css';
import ResourceComponent from "../../../../components/ResourceComponent";
import moment from "moment";

const isMobile = window.screen.width <= 1024;

class AdminTransactionsTable extends ResourceComponent {

    valueFormatter = (cell, amount) => {
        const valueColorStyle = amount > 0 ? 'green' : amount === 0 ? null : 'red';
        return <div className={{color: valueColorStyle}}><Dollars amount={amount}/></div>
    };

    invoiceNoFormatter = (cell, row) => {
        return row.invoice_no ?
            <span>{row.invoice_no}</span> : <span>N/A</span>
    };

    customerFormatter = (cell, row) => {
        return row.customer_name ?
            <span>{`${row.customer_name}`}</span> : <span>N/A</span>
    }

    dateFormatter = cell => moment.unix(cell).format("YYYY/MM/DD");

    render() {
        const {transactions, resource, onChangeResource} = this.props;

        return (
            <Col md={12} className="remaining vertical-responsive-height no-padding" style={{width: "90vw"}}>
                <BootstrapTable
                    ref="table"
                    data={transactions}
                    striped={true}
                    bordered={false}
                    hover={true}
                    remote
                    fetchInfo={{dataTotalSize: transactions.length}}
                    pagination={transactions && transactions.length > 0}
                    trStyle={this.rowStyleFormat}
                    className="wrapped"
                    trClassName={this.selectedMarkerClass}
                    options={
                        {
                            sortName: resource.sort_by,
                            sizePerPageDropDown: this.props.renderSizePerPageDropDown,
                            sizePerPage: this.props.per_page,
                            sortOrder: resource.sort,
                            onPageChange: this.props.onPageChange,
                            onSortChange: (sort_by, sort) => onChangeResource({...resource, sort_by, sort}),
                            paginationPanel: this.props.renderPaginationPanel,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                }],
                        }
                    }
                >
                    <TableHeaderColumn
                        dataField="id"
                        hidden
                        isKey
                    />
                    <TableHeaderColumn
                        dataField="any"
                        dataFormat={this.props.indexN}
                        width={isMobile ? "50" : "3%"}
                    >
                        #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="any"
                        dataFormat={this.customerFormatter}
                        width={isMobile ? "100" : "5%"}
                    >
                        Customer
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="invoice_no"
                        dataFormat={this.invoiceNoFormatter}
                        width={isMobile ? "100" : "5%"}
                    >
                        #Invoice
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="type"
                        dataSort
                        width={isMobile ? "120" : "7%"}
                        className={'word-break'}
                    >
                        Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="net"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.net / 100)}
                        width={isMobile ? "120" : "7%"}
                        className={'word-break'}
                    >
                        Net
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="amount"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.amount / 100)}
                        width={isMobile ? "110" : "6%"}
                        className={'word-break'}
                    >
                        Amount
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fee"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.fee / 100)}
                        width={isMobile ? "100" : "5%"}
                        className={'word-break'}
                    >
                        Fee
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="description"
                        width={isMobile ? "200" : "10%"}
                        className={'word-break'}
                    >
                        Description
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="created"
                        dataSort
                        dataFormat={(cell, row) => this.dateFormatter(cell, row)}
                        width={isMobile ? "130" : "8%"}
                        className={'word-break'}
                    >
                        Created at
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="available_on"
                        dataSort
                        dataFormat={(cell, row) => this.dateFormatter(cell, row)}
                        width={isMobile ? "130" : "8%"}
                        className={'word-break'}
                    >
                        Available on
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="status"
                        dataSort
                        dataFormat={(cell, row) => <span className="uppercase">{row.status}</span>}
                        width={isMobile ? "120" : "7%"}
                        className={'word-break'}
                    >
                        Status
                    </TableHeaderColumn>
                </BootstrapTable>
            </Col>
        )
    }
}

export default AdminTransactionsTable;