import {
  FETCH_STICKIES,
} from "./constants"

const initialState = {
  stickies: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STICKIES:
      return { ...state, stickies: action.stickies }
    default:
      return state
  }
}
export default reducer
