import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {dataUpload} from "../../../common/upload";
import {addAlert} from "../../App/actions";


export const getReportDocuments = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/report_templates`,
        method: 'GET',
        params: {client_id}
    };

    apiCall("Get client report templates", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const uploadClientAttachment = (id, acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/client_files?client_id=${id}&file_type=report`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteClientAttachment = (id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/client_files/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}