

import {SA_STATS_SUCCESS, PROPOSAL_SEARCH_SUCCESS, PROPOSAL_SEARCH_FAILED,UPDATE_SEARCH_PROPOSAL_NO
} from './constants'

//TO

const initialState = {
  numProposals: 0,
  dollars: 0,
  numProposed: 0,
  dollarsProposed: 0,
  searchStatusMessage: '',
  foundProposalId: null,
  searchProposalNo: '',
  leads: 0,
  dollarsAccepted: 0,
  confirmedLeads: 0,
  followUpCount: 0,
  initialSendCount: 0,
  followUpToDo: 0,
  nextWeeksLeads: 0,
  confirmedNext: 0,
}

export default function reducer(state = initialState, action){
  switch(action.type){
    case SA_STATS_SUCCESS:
      return {...state,
        numProposals: action.numProposals,
        dollars: action.dollars,
        numProposed: action.numProposed,
        dollarsProposed: action.dollarsProposed,
        leads: action.leads,
        dollarsAccepted: action.dollarsAccepted,
        confirmedLeads: action.confirmedLeads,
        followUpCount: action.followUpCount,
        initialSendCount: action.initialSendCount,
        followUpToDo: action.followUpToDo,
        nextWeeksLeads: action.nextWeeksLeads,
        confirmedNext: action.confirmedNext,
      }
    case UPDATE_SEARCH_PROPOSAL_NO:
      return {...state, searchProposalNo: action.searchProposalNo}
    case PROPOSAL_SEARCH_FAILED:
    case PROPOSAL_SEARCH_SUCCESS:
       // clear state first but we need to know what state is coming here
       return {...state, searchStatusMessage: action.searchStatusMessage, foundProposalId: action.foundProposalId }
    default:
      return state
  }
}
