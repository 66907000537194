import axios from "axios"
import {addAlert} from "../../App/actions"
import {geocodeAddress} from "../../SiteEditorModal/actions"
import {apiCall} from "../../../common/apiActionHandler";
import {mapForSelect} from "../../../common/commonHandlers";
import {dataUpload} from "../../../common/upload";
export {addAlert}


export const loadCustomerInfo = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/customers/${customerId}`,
        method: 'GET'
    };

    apiCall("Loading customer", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const store = getState()
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/customer_types`},
        {url: `/api/referrals`, params: {disabled: false}},
        {url: `/api/contact_types`},
        {url: `/api/clients/${store?.auth?.client_id}`},
        {url: `/api/users/${store?.auth.user_id}`, params: {all_data: true}},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            customerTypes: mapForSelect(all[1].data),
            referrals: mapForSelect(all[2].data),
            contactTypes: mapForSelect(all[3].data),
            roles: store?.auth?.roles,
            client: all[4].data,
            user: all[5].data
        });
    }, dispatch, getState, false);
};

export const uploadAttachment = (acceptedFiles, id, callback) => (dispatch, getState) => {
    dataUpload(`/api/customer_files?customer_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteAttachment = (id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/customer_files/${id}`,
    };

    apiCall("Deleting attachment", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback);
}

export const reorderAttachment = (newImageOrder, customer_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/customers/${customer_id}/update_doc_order`,
        method: 'PUT',
        data: {new_doc_order: newImageOrder}
    };

    apiCall("Reordering attachments", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const selectAttachmentForPrint = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customer_files/${id}/select_for_proposal`,
        method: 'PUT'
    };

    apiCall("Updating file", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const saveCustomer = (customer, callback, errorCallback) => async (dispatch, getState) => {

    for (const key in customer) {
        if (customer.hasOwnProperty(key) && typeof customer[key] === 'string') {
            customer[key] = customer[key].trim();
        }
    }

    customer.full_address = `${customer.address_1}, ${customer.city}, ${customer.state}, ${customer.zip}`
    try {
        const coords = await geocodeAddress(customer.full_address, getState().auth.token)
        customer.latitude = coords[0].geometry.location.lat
        customer.longitude = coords[0].geometry.location.lng
    } catch (error) {
        dispatch(
            addAlert({
                message: "Unable to geocode the address. Please modify it and try again! " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 3500,
            })
        )
        throw error
        return false
    }

    const config = {
        url: customer.id ? `/api/customers/${customer.id}` : '/api/customers',
        data: {customer},
        method: customer.id ? 'PUT' : 'POST'
    };

    apiCall("Saving customer", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, true, errorCallback);
};

export const updateCustomer = (customer, direction, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_customer`,
        method: 'PUT',
        data: {customer: {...customer}, direction: direction}
    };

    apiCall("Updating customer", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, true, errorCallback);
};

export const fetchContacts = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/customer_contacts/rolodex`,
        params: { customer_id: customerId},
    };

    apiCall("Getting customer contacts", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const saveContact = (contact, callback) => async (dispatch, getState) => {
    const config = {
        method: contact.id ? "PUT" : "POST",
        url: contact.id ? `/api/customer_contacts/${contact.id}` : '/api/customer_contacts',
        data: {contact},
    }

    apiCall("Save customer contact", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}

export const deleteContact = (contactId, callback) => (dispatch, getState) => {
    const config={
        method: 'delete',
        url:  `/api/customer_contacts/${contactId}`,
    }

    apiCall("Deleted customer contact", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}

export const fetchSites = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sites/customer_list`,
        params: {customer_id: customerId},
    }

    apiCall("Getting customer contact", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const uploadSiteAttachment = (acceptedFiles, id, callback) => (dispatch, getState) => {
    dataUpload(`/api/site_files?site_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteSiteAttachment = (id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/site_files/${id}`
    }

    apiCall("Deleting attachment", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback)
}

export const reorderSieAttachment = (newImageOrder, site_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sites/${site_id}/update_doc_order`,
        method: 'PUT',
        data: {new_doc_order: newImageOrder}
    };

    apiCall("Reordering attachments", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const selectSiteAttachmentForPrint = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/site_files/${id}/select_for_proposal`,
        method: 'PUT'
    };

    apiCall("Updating file", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const deleteSite = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/sites/${id}`
    }

    apiCall("Deleting site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true)
}

export const fetchStateAndCityForCustomer = (zipcode, callback) => async (dispatch, getState) => {
    const config = {
        url: "/api/customers/zipcode_search",
        params: {
            zipcode: zipcode
        }
    };
    apiCall("Fetching state and city", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const saveSite = (site, newCustomerId, callback, errorCallback) => async (dispatch, getState) => {
    try {
        if (site) {
            site.full_address = `${site?.address}, ${site?.city}, ${site?.state}, ${site?.zip}`
            const coords = await geocodeAddress(site?.full_address, getState().auth.token)
            site.latitude = coords[0].geometry.location.lat
            site.longitude = coords[0].geometry.location.lng
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Unable to geocode the address. Please modify it and try again! " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 3500,
            })
        )
        throw error
        return false
    }

    const config = {
        method: site?.id ? 'PUT' : 'POST',
        url: site?.id ? `/api/sites/${site?.id}` : '/api/sites',
        data: {site: {...site, customer_id: newCustomerId}, customer_id: site?.customer_id},
    }

    apiCall("Save site", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, true, errorCallback);
}

export const customerTimelineData = (customer_id, filters, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/customer_timeline_data`,
        params: {customer_id, filters}
    };

    apiCall("Loading Data", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const saveCustomerNote = (note, customer_id, callback) => (dispatch, getState) => {
    const config = {
        url: note?.id ? `/api/customer_notes_histories/${note?.id}` : '/api/customer_notes_histories',
        data: {...note, customer_id},
        method: note?.id ? 'PUT' : 'POST'
    };

    apiCall("Saving customer note", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const deleteCustomerNote = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/customer_notes_histories/${id}`
    }

    apiCall("Customer note deleted", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState);
};

export const fetchCustomerSalesData = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/customer_sales_data`,
        params: {customer_id: customerId}
    };

    apiCall("Getting customer sales data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const deleteCustomer = (customerId, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/customers/${customerId}`,
    }

    apiCall("Customer deleted", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
}

export const showDuplicates = (value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/search_duplicates`,
        params: {...value}
    };

    apiCall("Show Duplicates", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const sendCredentials = (ids, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/send_credentials`,
        method: 'POST',
        data: {ids: ids}
    };

    apiCall("Send Credentials", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const getMovedProposals = (site_id, new_customer_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/get_moved_proposals`,
        params: {site_id, new_customer_id}
    };

    apiCall("Getting proposals", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const getLeadList = (site_id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/get_site_leads`,
        params: {site_id}
    };

    apiCall("Getting leads", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false, errorCallback);
};

export const fetchOrdinances = (zip, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/ordinance_and_laws/`,
        method: 'GET',
        params: {zip: zip}
    };
    apiCall("Fetch site ordinances", config, async config => {
        const response = await axios.request(config);
        callback && callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};


export const getProposalsForInactive = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/get_proposals_for_inactive`,
        params: id
    };

    apiCall("Getting Proposals For Inactive", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const saveUserTimelineFilters = (user, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${user.id}/timeline_filters`,
        method: 'PUT',
        data: user
    };

    apiCall("Save User", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const mergeCustomer = (customer_id, merging_customer_id, callback) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/customers/merge_customer`,
        data: {customer_id, merging_customer_id}
    };

    apiCall("Show Duplicates", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const getCustomerAttachments = (id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/customer_files/${id}/get_customer_files`
    };

    apiCall("Getting customer attachments", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
}

export const deleteLead = (id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/leads/${id}`,
        method: 'DELETE'
    };

    apiCall("Deleting lead", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState, true, errorCallback);
};

export const mergeCustomerSites = (site_id, sites_ids, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/customers/merge_customer_sites`,
        data: {site_id, sites_ids}
    };

    apiCall("Merging sites", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState, true, errorCallback);
}