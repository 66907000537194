import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row, Tab, Tabs} from "react-bootstrap";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDNDTrucks} from "../CustomerPage/CustomerInfo/constants";
import * as actions from "./OrdinanceAndLawsApi";
import _ from 'lodash'
import './OrdinanceAndLaws.scss'
import {Creatable} from "react-select";
import {OrdinanceAndLawsModal} from "./OrdinanceAndLawsModal";
import moment from "moment/moment";
import {merge} from "../../common/commonHandlers";

const ordinance_and_laws = createApiService('ordinance_and_laws', 'ordinance_and_law', 'Ordinance & Laws');
const api = {
    ...ordinance_and_laws,
    ...actions,
};

class OrdinanceAndLaws extends ResourceComponent {
    defaultValues = {
        name: '',
        disabled: false,
        links: [],
        zip_codes: [],
        titles: [],
        descriptions: [],
    };
    state = {
        resource: {
            name: '',
            links: [],
            zip_codes: [],
            titles: [],
            descriptions: [],
            reminder_date: '',
            phone: null,
            email: null
        },
        addNewLink: false,
        newLinkUrl: '',
        newLinkName: '',
        ordinanceLawsNames: [],
        showMainModal: false,
        showResourceModal: false,
        errorMessage: ''
    };

    updateResources = resources => {
        this.setState({resources})
        this.checkReminders()
    };

    checkReminders = (resource) => {
        const {resources} = this.state;
        const date = moment().format('YYYY-MM-DD')
        const reminderData = resources.filter(res => res.reminder_date <= date)
        if (reminderData.length !== 0 && this.state.resource.id === undefined) {
            this.setState({
                ordinanceLawsNames: reminderData.map(r => r.name),
                showMainModal: true
            })
        } else if (resource?.id && resource?.reminder_date <= date) {
            this.setState({
                showResourceModal: true,
            })
        }
    }

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };

    reload = () => {
        this.props.actions.load({}, this.updateResources);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;

        if ((prevState.resources !== resources) && resource) {
            let updatedResource = _.cloneDeep(resources.find(r => r.id === resource?.id))
            let updatedToMerge = resources.find(r => r.id === resource?.id)
            const merged = _.merge({..._.omitBy(updatedToMerge, _.isNull)}, {..._.omitBy(prevState.resource, _.isNull)})
            updatedResource = {
                ...merged,
                files: updatedResource?.files,
            }
            this.setState(() => ({resource: updatedResource}))
        }
    }

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues), key: 0});
        this.checkNameValue(e.resource.name)
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    addLink = (type) => {
        const {resource, newLinkUrl, newLinkName} = this.state
        resource.links.push({url: newLinkUrl, name: newLinkName, type: type})
        this.setState({resource: resource, addNewLink: false})
    }
    removeLink = (idx_to_delete, type) => {
        const {resource} = this.state
        resource.links = resource.links?.filter(l => l.type !== type).concat(resource.links?.filter(l => l.type === type).filter((l, idx) => idx !== idx_to_delete))
        this.setState({resource: resource, addNewLink: false})
    }
    handleTabSelect = (key) => {
        const {name} = this.state.resource
        this.checkNameValue(name)
        name ? this.setState({key}) : this.setState({key: 0})
    }
    updateTitle = (e, type) => {
        this.state.resource[e.target.name] = [...this.state.resource[e.target.name]?.filter(t => t.type !== type), {
            title: e.target.value,
            type: type
        }];
        this.setState({resource: this.state.resource});
    };
    updateDescription = (e, type) => {
        this.state.resource[e.target.name] = [...this.state.resource[e.target.name]?.filter(t => t.type !== type), {
            description: e.target.value,
            type: type
        }];
        this.setState({resource: this.state.resource});
    };
    handleNumberOnlyInput = e => {
        if (!['Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
            'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9',
            'Backspace', 'Enter'].includes(e.nativeEvent.code)) {
            e.preventDefault();
        }
        if (e.target.value.length > 4 && !['Backspace', 'Enter'].includes(e.nativeEvent.code)) {
            e.preventDefault()
        }
    };
    checkNameValue = (name) => {
        if (name === '' || name === null) {
            this.setState({errorMessage: 'Please provide a name'})
        } else {
            this.setState({errorMessage: ''})
        }
    }

    renderTreeRemovalGuidelines = () => {
        const {resource} = this.state;
        return (
            <div>
                <Row>
                    <Col md={6} className='margin-top-bottom-20'>
                        <FormGroup>
                            <ControlLabel>
                                Title
                            </ControlLabel>
                            <FormControl
                                name="titles"
                                placeholder={'title'}
                                onChange={(e) => this.updateTitle(e, 'removal')}
                                value={resource.titles?.filter(t => t.type === 'removal')[0]?.title || ''}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                Description
                            </ControlLabel>
                            <FormControl
                                style={{"min-width": '100%'}}
                                name="descriptions"
                                placeholder={'description'}
                                componentClass='textarea'
                                rows={8}
                                onChange={(e) => this.updateDescription(e, 'removal')}
                                value={resource.descriptions?.filter(t => t.type === 'removal')[0]?.description || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} className='margin-top-bottom-20'>
                        <FormGroup>
                            <ControlLabel>
                                Links
                            </ControlLabel>
                            <div>
                                {resource.links?.filter(l => l.type === 'removal').map((l, idx) =>
                                    <span className={'pr-10'}
                                          key={idx}><a href={l.url}
                                                       target='_blank'>{l.name}</a>
                                                        <span onClick={() => this.removeLink(idx, 'removal')}><Glyphicon
                                                            glyph='remove' className="pointer pl-5"/></span></span>)}
                            </div>
                            {!this.state.addNewLink && <p><Button onClick={() => this.setState({
                                addNewLink: true,
                                newLinkUrl: '',
                                newLinkName: ''
                            })}>Add new</Button></p>}
                            {this.state.addNewLink && <Row>
                                <Col md={5}>
                                    <FormControl
                                        name="newLinkUrl"
                                        placeholder="https://example.com"
                                        onChange={e => this.setState({newLinkUrl: e.target.value})}
                                        value={this.state.newLinkUrl || ''}
                                    />
                                </Col>
                                <Col md={5}>
                                    <FormControl
                                        name="newLinkName"
                                        placeholder='displayed name'
                                        onChange={e => this.setState({newLinkName: e.target.value})}
                                        value={this.state.newLinkName || ''}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        disabled={!this.state.newLinkUrl || !this.state.newLinkName}
                                        onClick={() => this.addLink('removal')}>Save
                                        link</Button>
                                </Col>
                            </Row>}
                        </FormGroup>
                        {resource.id &&
                            <div className="dropzone_with_thumbnails">
                                <Dropzone
                                    onDrop={files => this.props.actions.uploadOrdinanceAndLawAttachment(resource.id, 'removal', files, () => {
                                        this.reload()
                                    })} className="dropzone pointer border_none">
                                    <p>Drop a file here or click to select a file to
                                        upload.</p>
                                </Dropzone>
                                <ReorderingDragAndDrop
                                    files={resource.files?.filter(f => f.file_type === 'removal').sort((a, b) => a.order - b.order)}
                                    deleteAttachment={(tp) => this.props.actions.deleteOrdinanceAndLawAttachment(tp, () => {
                                        this.reload()
                                    })}
                                    reorderAttachments={(orderedFiles) => {
                                        const order = JSON.stringify(orderedFiles.map((image) => image.id))
                                        this.props.actions.reorderAttachment(order, resource.id, 'removal',
                                            () => {
                                                this.reload()
                                            })
                                    }}
                                    numberOfColumns={boxesPerRowReorderingDNDTrucks}
                                />
                            </div>}
                    </Col>
                </Row>
            </div>
        )
    }
    renderTreePruningGuidelines = () => {
        const {resource} = this.state;
        return (
            <div>
                <Row>
                    <Col md={6} className='margin-top-bottom-20'>
                        <FormGroup>
                            <ControlLabel>
                                Title
                            </ControlLabel>
                            <FormControl
                                name="titles"
                                placeholder={'title'}
                                onChange={(e) => this.updateTitle(e, 'pruning')}
                                value={resource.titles?.filter(t => t.type === 'pruning')[0]?.title || ''}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                Description
                            </ControlLabel>
                            <FormControl
                                style={{"min-width": '100%'}}
                                name="descriptions"
                                placeholder={'description'}
                                componentClass='textarea'
                                rows={8}
                                onChange={(e) => this.updateDescription(e, 'pruning')}
                                value={resource.descriptions?.filter(t => t.type === 'pruning')[0]?.description || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} className='margin-top-bottom-20'>
                        <FormGroup>
                            <ControlLabel>
                                Links
                            </ControlLabel>
                            <div>
                                {resource.links?.filter(l => l.type === 'pruning').map((l, idx) =>
                                    <span className={'pr-10'}
                                          key={idx}><a href={l.url}
                                                       target='_blank'>{l.name}</a>
                                                        <span onClick={() => this.removeLink(idx, 'pruning')}><Glyphicon
                                                            glyph='remove' className="pointer pl-5"/></span></span>)}
                            </div>
                            {!this.state.addNewLink && <p><Button onClick={() => this.setState({
                                addNewLink: true,
                                newLinkUrl: '',
                                newLinkName: ''
                            })}>Add new</Button></p>}
                            {this.state.addNewLink && <Row>
                                <Col md={5}>
                                    <FormControl
                                        name="newLinkUrl"
                                        placeholder="https://example.com"
                                        onChange={e => this.setState({newLinkUrl: e.target.value})}
                                        value={this.state.newLinkUrl || ''}
                                    />
                                </Col>
                                <Col md={5}>
                                    <FormControl
                                        name="newLinkName"
                                        placeholder='displayed name'
                                        onChange={e => this.setState({newLinkName: e.target.value})}
                                        value={this.state.newLinkName || ''}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        disabled={!this.state.newLinkUrl || !this.state.newLinkName}
                                        onClick={() => this.addLink('pruning')}>Save
                                        link</Button>
                                </Col>
                            </Row>}
                        </FormGroup>
                        {resource.id &&
                            <div className="dropzone_with_thumbnails">
                                <Dropzone
                                    onDrop={files => this.props.actions.uploadOrdinanceAndLawAttachment(resource.id, 'pruning', files, () => {
                                        this.reload()
                                    })} className="dropzone pointer border_none">
                                    <p>Drop a file here or click to select a file to
                                        upload.</p>
                                </Dropzone>
                                <ReorderingDragAndDrop
                                    files={resource.files?.filter(f => f.file_type === 'pruning').sort((a, b) => a.order - b.order)}
                                    deleteAttachment={(tp) => this.props.actions.deleteOrdinanceAndLawAttachment(tp, () => {
                                        this.reload()
                                    })}
                                    reorderAttachments={(orderedFiles) => {
                                        const order = JSON.stringify(orderedFiles.map((image) => image.id))
                                        this.props.actions.reorderAttachment(order, resource.id, 'pruning',
                                            () => {
                                                this.reload()
                                            })
                                    }}
                                    numberOfColumns={boxesPerRowReorderingDNDTrucks}
                                />
                            </div>}
                    </Col>
                </Row>
            </div>
        )
    }
    renderTreePreservationGuidelines = () => {
        const {resource} = this.state;
        return (
            <div>
                <Row>
                    <Col md={6} className='margin-top-bottom-20'>
                        <FormGroup>
                            <ControlLabel>
                                Title
                            </ControlLabel>
                            <FormControl
                                name="titles"
                                placeholder={'title'}
                                onChange={(e) => this.updateTitle(e, 'preservation')}
                                value={resource.titles?.filter(t => t.type === 'preservation')[0]?.title || ''}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                Description
                            </ControlLabel>
                            <FormControl
                                style={{"min-width": '100%'}}
                                name="descriptions"
                                placeholder={'description'}
                                componentClass='textarea'
                                rows={8}
                                onChange={(e) => this.updateDescription(e, 'preservation')}
                                value={resource.descriptions?.filter(t => t.type === 'preservation')[0]?.description || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} className='margin-top-bottom-20'>
                        <FormGroup>
                            <ControlLabel>
                                Links
                            </ControlLabel>
                            <div>
                                {resource.links?.filter(l => l.type === 'preservation').map((l, idx) =>
                                    <span className={'pr-10'}
                                          key={idx}><a href={l.url}
                                                       target='_blank'>{l.name}</a>
                                                        <span
                                                            onClick={() => this.removeLink(idx, 'preservation')}><Glyphicon
                                                            glyph='remove' className="pointer pl-5"/></span></span>)}
                            </div>
                            {!this.state.addNewLink && <p><Button onClick={() => this.setState({
                                addNewLink: true,
                                newLinkUrl: '',
                                newLinkName: ''
                            })}>Add new</Button></p>}
                            {this.state.addNewLink && <Row>
                                <Col md={5}>
                                    <FormControl
                                        name="newLinkUrl"
                                        placeholder="https://example.com"
                                        onChange={e => this.setState({newLinkUrl: e.target.value})}
                                        value={this.state.newLinkUrl || ''}
                                    />
                                </Col>
                                <Col md={5}>
                                    <FormControl
                                        name="newLinkName"
                                        placeholder='displayed name'
                                        onChange={e => this.setState({newLinkName: e.target.value})}
                                        value={this.state.newLinkName || ''}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        disabled={!this.state.newLinkUrl || !this.state.newLinkName}
                                        onClick={() => this.addLink('preservation')}>Save
                                        link</Button>
                                </Col>
                            </Row>}
                        </FormGroup>
                        {resource.id &&
                            <div className="dropzone_with_thumbnails">
                                <Dropzone
                                    onDrop={files => this.props.actions.uploadOrdinanceAndLawAttachment(resource.id, 'preservation', files, () => {
                                        this.reload()
                                    })} className="dropzone pointer border_none">
                                    <p>Drop a file here or click to select a file to
                                        upload.</p>
                                </Dropzone>
                                <ReorderingDragAndDrop
                                    files={resource.files?.filter(f => f.file_type === 'preservation').sort((a, b) => a.order - b.order)}
                                    deleteAttachment={(tp) => this.props.actions.deleteOrdinanceAndLawAttachment(tp, () => {
                                        this.reload()
                                    })}
                                    reorderAttachments={(orderedFiles) => {
                                        const order = JSON.stringify(orderedFiles.map((image) => image.id))
                                        this.props.actions.reorderAttachment(order, resource.id, 'preservation',
                                            () => {
                                                this.reload()
                                            })
                                    }}
                                    numberOfColumns={boxesPerRowReorderingDNDTrucks}
                                />
                            </div>}
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const {
            resource,
            resources,
            showMainModal,
            showResourceModal,
            ordinanceLawsNames,
            errorMessage
        } = this.state;
        if (!errorMessage && resources && resource && resources.some(r => r.name === resource.name && r.disabled === false && resource.disabled === false && r.id !== resource.id)) {
            this.setState({errorMessage: 'Name already exist.'})
        }
        const date = moment().format('YYYY-MM-DD')
        return (
            resources ?
                <Grid fluid>
                    <OrdinanceAndLawsModal show={showMainModal} ordinanceLawsNames={ordinanceLawsNames}
                                           onHide={() => {
                                               this.setState({showMainModal: false})
                                           }}
                    />
                    <MasterDetail
                        resourceName="Ordinance"
                        resourceNamePlural="Ordinances"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        blockSave={!resource.name || errorMessage}
                        save={() => {
                            this.checkReminders(resource)
                            this.checkNameValue(resource.name)
                            resource.name && resource?.reminder_date > date || resource?.id === undefined ?
                                this.props.actions.save(resource, this.reloadResources) :
                                this.reload()
                        }}
                        delete={() => this.props.actions.remove(resource, this.reloadResources)}>
                        {resource &&
                            <Tabs activeKey={this.state.key}
                                  id="controlled-tab-example"
                                  mountOnEnter={true}
                                  onSelect={this.handleTabSelect}
                                  animation={false} id="noanim-tab-example">
                                <Tab eventKey={0} title={`${resource.name || 'New Ordinance'}`}>
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className={'name-field margin-top-20'}>
                                                    <ControlLabel>
                                                        Name
                                                    </ControlLabel>
                                                    <FormControl
                                                        className={errorMessage ? "error-border" : ""}
                                                        name="name"
                                                        placeholder={'city/municipality'}
                                                        onChange={(e) => {
                                                            this.updateResourceAttr(e)
                                                            this.checkNameValue(e.target.value)
                                                        }}
                                                        value={resource.name || ''}
                                                    />
                                                    {errorMessage &&
                                                        <span className="error-message">{errorMessage}</span>}
                                                </FormGroup>
                                                <FormGroup className='margin-top-bottom-20'>
                                                    <ControlLabel>
                                                        Email
                                                    </ControlLabel>
                                                    <FormControl
                                                        type="email"
                                                        name="email"
                                                        placeholder={'email'}
                                                        onChange={this.updateResourceAttr}
                                                        value={resource.email || ''}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ColorCheckbox value={resource.disabled}
                                                                   label="Disabled"
                                                                   onChange={this.selectCheckboxAttr('disabled')}/>
                                                </FormGroup>
                                                <FormGroup>
                                                    Last
                                                    updated {resource.modified_at ? moment(resource.modified_at).format('llll') : <i>Unknown</i>} by {resource.modified_by_name}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className={'margin-top-20'}>
                                                    <ControlLabel>
                                                        Zip codes
                                                    </ControlLabel>
                                                    <Creatable
                                                        isClearable
                                                        isMulti
                                                        options={resource.zip_codes}
                                                        value={resource.zip_codes}
                                                        onKeyDown={this.handleNumberOnlyInput}
                                                        onChange={e => {
                                                            if (e[e.length - 1]?.label && !resource.zip_codes?.find(z => z.label === e[e.length - 1].label)) {
                                                                resource.zip_codes.push({
                                                                    value: e[e.length - 1].label,
                                                                    label: e[e.length - 1].label
                                                                })
                                                                this.setState({resource: resource})
                                                            } else {
                                                                resource.zip_codes = e
                                                                this.setState({resource: resource})
                                                            }
                                                        }}/>
                                                </FormGroup>
                                                <FormGroup className='margin-top-bottom-20'>
                                                    <ControlLabel>
                                                        Phone
                                                    </ControlLabel>
                                                    <FormControl
                                                        name="phone"
                                                        placeholder={'phone'}
                                                        onChange={this.updateResourceAttr}
                                                        value={resource.phone || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                                <Tab eventKey={1} title={'Tree Removal guidelines'}
                                     className={!resource.name ? 'blocked-tab' : ''}>
                                    {this.renderTreeRemovalGuidelines()}
                                </Tab>
                                <Tab eventKey={2} title={'Tree Pruning guidelines'}
                                     className={!resource.name ? 'blocked-tab' : ''}>
                                    {this.renderTreePruningGuidelines()}
                                </Tab>
                                <Tab eventKey={3} title={'Tree Preservation guidelines'}
                                     className={!resource.name ? 'blocked-tab' : ''}>
                                    {this.renderTreePreservationGuidelines()}
                                </Tab>
                            </Tabs>
                        }
                        <OrdinanceAndLawsModal id={resource.id} show={showResourceModal}
                                               onConfirm={() => {
                                                   resource.reminder_date = moment(resource.reminder_date).add(1, 'year').format('YYYY-MM-DD');
                                                   this.setState({resource: resource, showResourceModal: false})
                                                   this.props.actions.save(resource, this.reloadResources)
                                               }}
                                               onHide={() => {
                                                   this.setState({showResourceModal: false})
                                               }}
                                               onRemind={() => {
                                                   resource.reminder_date = moment().add(3, 'days').format('YYYY-MM-DD');
                                                   this.setState({resource: resource, showResourceModal: false})
                                                   this.props.actions.save(resource, this.reloadResources)
                                               }}
                        />
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(OrdinanceAndLaws)
