import React, {Component} from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    ButtonToolbar,
    Col,
    ControlLabel,
    FormGroup,
    Glyphicon,
    Grid,
    Row,
    ToggleButton,
    ToggleButtonGroup,
    Well
} from "react-bootstrap";
import {getUserStatus} from "../AppWrapper/contents/actions"
import * as notificationsActions from "./NotificationsApi"
import Select from "react-select"
import {showEmailDialog} from "../../components/email/actions"
import FollowUps from "./FollowUps";
import {Link} from "react-router";
import Activities from "./Activities";
import Messages from "./Messages";

const actions = {...notificationsActions, getUserStatus, showEmailDialog};

class ProposalNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stickies: [], option: 'Follow ups'
        };
    }

    handleOptionChange = e => {
        this.setState({option: e}, ()=>this.handleLoadProposalStickies({...this.state.searchResult, type: e.toLowerCase()}))
    };

    handleLoadProposalStickies = proposal => {
        this.setState({searchResult: proposal});
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.docks && nextProps.docks.proposal && Object.keys(nextProps.docks.proposal).length > 0) {
            this.handleLoadProposalStickies(nextProps.docks.proposal);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.docks && this.props.docks.proposal && Object.keys(this.props.docks.proposal).length > 0
            && prevProps.docks.proposal !== this.props.docks.proposal){
            this.handleLoadProposalStickies(this.props.docks.proposal);
        }

    }

    render() {
        const {stickies, searchResult, searchResults, option} = this.state;
        const {email} = this.props;
        const {reloadDate} = this.props.docks
        stickies.forEach(s => {
            s.read = s.read_message;
            s.replies.forEach(r => {
                r.read = r.read_message
            })
        });
        const isProposalView = this.props.docks && this.props.docks.proposal && Object.keys(this.props.docks.proposal).length > 0;
        const options = ['Activities', 'Follow ups', 'Messages'];
        return (<div>
                <Grid fluid>
                    <Row>
                        <Col md={12} className={`hcenter`}>
                            <ButtonToolbar className={`margin10`}>
                                <ToggleButtonGroup type="radio"
                                                   name="options"
                                                   value={this.state.option}
                                                   onChange={this.handleOptionChange}
                                >
                                    {options.map((o, i) =>
                                        <ToggleButton
                                            key={i}
                                            value={o}
                                            className={`width-auto`}
                                        >
                                            {o}
                                        </ToggleButton>)
                                    }
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                    <Row>
                        <Well className={`no-margin`}>
                            <h4 className='vcenter'>
                                <span>{`Proposal: ${searchResult && searchResult.label}`}</span>
                                <Link onClick={() => this.props.actions.showEmailDialog({
                                    emailType: "proposal",
                                    referenceId: this.props.docks.proposal.value,
                                    recipient: this.props.docks.email,
                                    defaultEmail: email
                                })}
                                      className='justify-flex-end width55'
                                ><Glyphicon glyph="envelope"
                                            className="bigger pointer"/></Link>
                            </h4>
                        </Well>
                    </Row>
                </Grid>
                {option === 'Follow ups' && <div>
                    <FollowUps count={25} type="follow ups" isProposalView={isProposalView}
                               searchResult={searchResult} reloadDate={reloadDate}/>
                </div>}

                {option === 'Activities' && <div>
                    <Activities count={25} type="activities" isProposalView={isProposalView}
                                searchResult={searchResult} reloadDate={reloadDate}/>
                </div>}

                {option === 'Messages' && <div>
                    <Messages count={25} type="messages" isProposalView={isProposalView}
                              searchResult={searchResult} reloadDate={reloadDate}/>
                </div>}

            </div>
        );
    }
}

ProposalNotes.propTypes = {
    count: PropTypes.number.isRequired,
    type: PropTypes.string,
    users: PropTypes.array
};

ProposalNotes.defaultProps = {
    count: 10,
    type: 'proposal notes'
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        userStatus: state.userStatus.userStatus,
        docks: state.docks,
        users: state.users.users,
        email: state.auth.email
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalNotes);
