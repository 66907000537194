import React from "react";
import ProposalServiceMarkers from "./ProposalServiceMarkers";


const Markers = ({proposal_services, ...props}) => {
    return proposal_services && proposal_services.length > 0 && proposal_services.map((data, index) => {
        return <ProposalServiceMarkers key={'psm'+index} {...props} data={data}/>
    })
}
export default Markers;
