import React, { Component} from 'react'
import PropTypes from "prop-types"

import {Alert} from 'react-bootstrap'

const styles = {
  opacity: 0.9,
}

export default class CiAlert extends Component {

  componentDidMount(){
      //if we want to auto dismiss this component then we'll run the kill myself method after the specified milliseconds
      const dismissAfter = this.props.dismissAfter > 0 ? this.props.dismissAfter : 5000
      if (dismissAfter > 0){
        this.deleteMe = window.setInterval(this.autoDismiss, dismissAfter)
      }
  }

  autoDismiss = () => {
    clearInterval(this.deleteMe) ;// since we only want to run this once, we'll immediately remove the timer
    this.props.onDismiss(this.props.id); //this just deletes the text in state which causes the component to rerender maybe
  }

  dismissAlert = (id) =>{
    this.props.onDismiss(id)
  }

  render(){
    const { message, mood, id } = this.props
    return (
        <div className="alerts">
            <Alert style={styles} bsStyle={mood} onDismiss={() => this.dismissAlert(id)}>
              <h5>{message}</h5>
            </Alert>
            </div>
        )
  }
}

//NOTE: consider that there are animations for a few hundred ms when setting the dismiss after to a non-zero value
//0 = dont autodismiss
//>0 = autodismiss
CiAlert.propTypes = {
  id: PropTypes.number.isRequired,
  mood: PropTypes.oneOf(['info', 'warning', 'danger', 'success']),
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  dismissAfter: function(props, propName, componentName) {
      if (props['dismissAfter'] < 0){
        return new Error("dismissAfter must be a positive integer in milliseconds. > 3000 msec = good ux.")
      }
    },
  }

CiAlert.defaultProps = {
  id: 0,
  mood: "danger",
  message: "Oops! You didn't specify a message for the alert!",
  onDismiss: () => { alert("Implement an action to change State to cause me to not re-render.") },
  dismissAfter: 0
}

