//just a bunch of exported functions for you to import and use wherever
//to use, in your js file add: import { humanize } from '../util'

import {colors, reverseColor} from "../common/commonHandlers";

export function humanize(str) {
  //TODO: create composed function rather than this
  const humanized = toTitleCase(unDasherize(str))

  switch (humanized) {
    case "Tanks Number":
      return "Number of Tanks"
    default:
      return humanized
  }
}

export function unDasherize(str) {
  return str.replace(/_|-/g, " ").trim()
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function sortByProperty(arr) {
  let sorted = arr.slice().sort(function(a, b) {
    if (humanize(a.app) > humanize(b.app)) {
      return 1
    }
    if (humanize(a.app) < humanize(b.app)) {
      return -1
    }
    return 0
  })
  return sorted
}

//add/overwrite object to object i.e. {} => { 'something_3': {boots: 'cat'} }
export const insertKeyIntoObject = (obj, kind, key) => {
  return { ...obj, [`${kind}_${key["id"]}`]: key }
}

//edit key of object
export const updateObjectAttribute = (obj, kind, id, attr, val) => {
  return { ...obj, [`${kind}_${id}`]: { ...obj[`${kind}_${id}`], [attr]: val } }
}

export const deleteKeyFromObject = (obj, kind, id) => {
  let { [`${kind}_${id}`]: _, ...restOfThem } = obj
  return restOfThem
}

export const cancelablePromise = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
        value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
        error => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

export const delay = n => new Promise(resolve => setTimeout(resolve, n));

export const getBasicMarker = ({selected, color, label = "", textColor = "#000", fontWeight = 400}) => {
  const markerDims = {w: 30, h: 45}

  textColor = reverseColor(color)
  if(label !== "") {
    label = label.replace("&", "&amp;")
  }

  const marker = `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                  <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" 
                    fill="${selected ? '#f8e500' : color}" stroke="black"
                    transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"/>
                  <text xml:space="preserve" font-weight="${fontWeight}" fill="${textColor}" style="font-style:normal;font-size:8px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill-opacity:1;stroke:none" x="10.055" y="5.334">
                    <tspan x="31" y="8" style="font-size:14px;text-align:center;text-anchor:middle">${label}</tspan>
                  </text>
                </svg>`

  return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(marker)}
}

export const getBasicAssetMarker = ({asset, highlighted = false, label = "", textColor = "#000", fontWeight = 400}) => {
  let color = asset.plant_color ? colors[asset.plant_color] && colors[asset.plant_color] : '#93c54b';
  if (highlighted || asset.__highlighted === true || asset.__active === true || asset.__locked === true) {
    color = '#FF9E00'
  }
  textColor = reverseColor(color)

  const markerDims = {w: 30, h: 45}

  let baseFontSize = 14
  let y = 8;

  if(label !== "") {
    if(label.length > 2) {
      baseFontSize = 12
      y = 6
    } else if(label.length > 3) {
      baseFontSize = 10
      y = 6
    }
    label = label.replace("&", "&amp;")
  }

  const marker = `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                  <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" 
                    fill="${color}" stroke="black"
                    transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"/>
                  <text fill="${textColor}" font-weight="${fontWeight}" xml:space="preserve" style="font-style:normal;font-size:8px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill-opacity:1;stroke:none" x="10.055" y="5.334">
                    <tspan x="31" y="${y}" style="font-size:${baseFontSize}px;text-align:center;text-anchor:middle">${label}</tspan>
                  </text>
                </svg>`

  return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(marker)}
}

export const getAdvancedAssetMarker = ({color, label = "", with_opacity, with_circle, gradient}) => {
  const markerDims = {w: 30, h: 45}

  let baseFontSize = 14
  let y = 8;

  if(label !== "") {
    if(label.length > 2) {
      baseFontSize = 12
      y = 6
    } else if(label.length > 3) {
      baseFontSize = 10
      y = 6
    }
    label = label.replace("&", "&amp;")
  }

  const markers = {
    baseMarker: `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                  <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" 
                    fill="${color}" stroke="black"
                    transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"/>
                  <text xml:space="preserve" style="font-style:normal;font-weight:400;font-size:10px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill:#000;fill-opacity:1;stroke:none" x="10.055" y="5.334">
                    <tspan x="36" y="${y}" style="font-size:${baseFontSize}px;text-align:center;text-anchor:middle">${label}</tspan>
                  </text>
                </svg>`,
    circleMarker: `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                      <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" 
                        style="display:inline;opacity:1;fill:${color};fill-opacity:1;stroke:#000;stroke-width:1.30877256;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                        transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"
                      />
                      <ellipse cx="10.045" cy="2.219" rx="10.038" ry="9.827" style="fill:#fff;stroke-width:1.11443377"/>
                      <text xml:space="preserve" style="font-style:normal;font-weight:400;font-size:8px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill:#000;fill-opacity:1;stroke:none" x="10.055" y="5.334">
                        <tspan x="35" y="5" style="font-size:10px;text-align:center;text-anchor:middle">${label}</tspan>
                      </text>
                   </svg>`,
    circleGradientMarker: `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                              <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" 
                                style="display:inline;opacity:1;fill:${color};fill-opacity:1;stroke:#000;stroke-width:1.30877256;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                                transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"
                              />
                              <ellipse cx="10.045" cy="2.219" rx="8.749" ry="8.679" style="opacity:1;fill:#fff;stroke:#000;stroke-width:2.63411473;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
                              <text xml:space="preserve" style="font-style:normal;font-weight:400;font-size:8px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill:#000;fill-opacity:1;stroke:none" x="10.055" y="5.334">
                                <tspan x="10.055" y="5.334" style="font-size:10px;text-align:center;text-anchor:middle">${label}</tspan>
                              </text>
                              <path style="fill:green;stroke-width:1.11443377" d="M20.082 2.22a10.038 9.827 0 0 1-.021.642"/>
                           </svg>`
  }

  if(with_circle && gradient) {
    return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markers.circleGradientMarker)}
  }
  if(with_circle) {
    return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markers.circleMarker)}
  }

  return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markers.baseMarker)}
}

