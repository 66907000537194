import React from "react";
import {Col, Row} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

const isMobile = window.screen.width <= 1024;

const EditedHolidayTab = ({holidays, resource, onSortChange, rowStyleFormat, rowFormatter, indexN, dateFormatter, optionsFormatter}) => {
    return isMobile ?
        <Row>
            <Col md={12} className="remaining vertical-responsive-height">
                <BootstrapTable
                    data={holidays}
                    striped={true}
                    bordered={false}
                    trStyle={rowStyleFormat}
                    hover={true}
                    remote
                    className="wrapped"
                >
                    <TableHeaderColumn
                        dataField="id"
                        hidden
                        isKey
                    />
                    <TableHeaderColumn
                        dataField="id"
                        dataFormat={rowFormatter}
                    />
                </BootstrapTable>
            </Col>
        </Row>
        :
        <Row style={{height: 0}}>
            <BootstrapTable
                data={holidays}
                striped={true}
                bordered={false}
                hover={true}
                trStyle={rowStyleFormat}
                remote
                className="wrapped"
                options={
                    {
                        sortName: resource.sort_by,
                        sortOrder: resource.sort,
                        onSortChange: onSortChange
                    }
                }
            >
                <TableHeaderColumn
                    dataField="id"
                    hidden
                    isKey
                />
                <TableHeaderColumn
                    dataField="any"
                    dataFormat={indexN}
                    width={"5%"}
                >
                    #
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="name"
                    width={"25%"}
                >
                    Name
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="date"
                    dataSort
                    width={"20%"}
                    dataFormat={dateFormatter}
                >
                    Date
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    width={"15%"}
                    dataFormat={optionsFormatter}
                >
                    Options
                </TableHeaderColumn>
            </BootstrapTable>
        </Row>
}

export default EditedHolidayTab;