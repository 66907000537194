import React, {useEffect, useState} from 'react';
import './BundleServices.scss'
import PropTypes from "prop-types";
import {Panel} from "react-bootstrap";
import BundleServicesHeader from "./BundleServicesHeader";
import PlantName from "../../components/PlantName";
import {sortAssetsLabels} from "../../common/commonHandlers";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";


const BundleServices = ({
                            bundleServices,
                            view,
                            onDelete,
                            markedCheckboxes,
                            proposalServiceId,
                            onUpdateWoNote,
                            promisedDates,
                            dateTimeCompletedAt,
                            isDeletable,
                            getAllAssets,
                            allEquipment,
                            draggable,
                            resource,
                            onSaveBSOrder
                        }) => {
    const [woNotes, setWoNotes] = useState([])

    const handleWoNote = (e) => {
        const {value: userInput, name: id} = e.target

        let textArea = {value: '', id: ''}
        textArea.value = userInput
        textArea.id = Number(id)

        const woNoteIndex = woNotes.findIndex(wo => wo.id === Number(id))
        if (woNoteIndex < 0) {
            setWoNotes([...woNotes, textArea])
            return
        }

        const newWoNotes = [...woNotes]
        newWoNotes[woNoteIndex].value = userInput
        setWoNotes(newWoNotes)
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        let orderedBundleServices = bundleServices.sort((a, b) => a.service_number - b.service_number)

        let oldOrderBundleServices = bundleServices.sort((a, b) => a.service_number - b.service_number).map(bs => bs.service_number)
        const newOrderBundleServices = reorder(orderedBundleServices, result.source.index, result.destination.index)

        bundleServices = newOrderBundleServices?.map((bs, i) => {
            bs.service_number = oldOrderBundleServices[i]
            return bs
        })

        resource.bundle_services = bundleServices
        onSaveBSOrder(resource)
    }

    const panelBundleService = (bs) => {
        return <Panel
            header={BundleServicesHeader(bs, view, onDelete, isDeletable, markedCheckboxes, proposalServiceId, promisedDates, dateTimeCompletedAt, getAllAssets)}
            collapsible={true}
            defaultExpanded={false}
        >
            {view === "MapViewPage" ?
                <div className='bundle-service-body-map-view'>
                    <div>
                        <strong>Service: </strong>
                        <span>{bs.name}</span>
                    </div>
                    {bs.priorities.length > 0 && <div>
                        <strong>Priorities: </strong>
                        <span>{bs.priorities.map(p => p.name).join(', ')}</span>
                    </div>}
                    <div className='bundle-service-body-map-view__assets'>
                        <strong>Assets: </strong>
                        <table>
                            {bs.asset_ids.map(id => getAllAssets().find(a => a.id === id)).sort((a, b) => a.label - b.label).map(asset => {
                                return (
                                    <>
                                        {asset &&
                                            <tr key={asset.id}>
                                                <td>
                                                    {asset.label}{" "}{<PlantName
                                                    plant={asset.plant_name}/>}{" "}
                                                    {asset.plant_count > 1 ? `[${asset.plant_count}]` : null}{" "}
                                                </td>
                                                <td>{asset.plant_dbh ? `DBH: ${asset.plant_dbh}` : ''}</td>
                                            </tr>
                                        }

                                    </>
                                )
                            })}
                        </table>
                    </div>
                    {bs.notes &&
                        <div>
                            <strong>Proposal Service Notes: </strong>
                            <span>{bs.notes}</span>
                        </div>}
                    {bs.wo_notes && <div>
                        <strong>Work Order Notes: </strong>
                        <span>{bs.wo_notes}</span>
                    </div>}
                    <div className='bundle-service-body-map-view__price'>
                        <div>
                            <strong>Man Hours: </strong>
                            <span>{bs.man_hours}</span>
                        </div>
                        <div>
                            <strong>Rate: </strong>
                            <span>{bs.ave_salary_rate}</span>
                        </div>
                        <div>
                            <strong>Service Total: </strong>
                            <span>{bs.service_total}</span>
                        </div>
                    </div>
                    {bs.equipment_ids.length > 0 && <div>
                        <strong>Optional Equipment: </strong>
                        <span>{bs.equipment_ids.map(eq_id => {
                            const eq = allEquipment.find(x => x.value === eq_id)
                            return eq.label
                        }).join(', ')}</span>
                    </div>}
                </div>
                :
                <div className='bundle-service-body'>
                    <div className='bundle-service-body__trees'>
                        <p><strong>Trees:</strong></p>
                        {bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map(a => {
                            return (
                                <span><p>{`#${a.asset_number} ${a.name}`} </p></span>
                            )
                        })}
                    </div>
                    <div className='bundle-service-body__service-notes'>
                        <strong>Service Notes:</strong>
                        <p>{bs.notes}</p>
                    </div>
                    <div className='bundle-service-body__wo-notes'>
                        <strong>WO Notes:</strong>
                        {view === "ProposalServicesList" && woNotes.length > 0
                            ? (
                                <textarea
                                    onChange={(e) => handleWoNote(e)}
                                    name={bs.id}
                                    onBlur={(e) => onUpdateWoNote(bs.id, e.target.value)}
                                    value={woNotes.find(wo => wo.id === bs.id).value}
                                >
                                                </textarea>
                            )
                            : <p>{bs.wo_notes}</p>
                        }
                    </div>
                </div>}
        </Panel>
    }

    useEffect(() => {
        const items = []
        bundleServices.map(bs => {
            return items.push({value: bs.wo_notes, id: bs.id})
        })
        setWoNotes(items)
    }, [])


    return (
        <div className='c-bundle-services'>
            <heading>
                {view !== "MapViewPage" && <p>Bundle Services:</p>}
            </heading>
            {draggable ?
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {bundleServices.sort((a, b) => a.service_number - b.service_number).map((bs, index) => (
                                    <Draggable key={bs.id} draggableId={`${bs.id}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {panelBundleService(bs)}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                :
                bundleServices.sort((a, b) => a.service_number - b.service_number).map((bs, i) => {
                    return panelBundleService(bs)
                })
            }

        </div>
    );
};

BundleServices.propTypes = {
    bundleServices: PropTypes.array.isRequired,
    isDeletable: PropTypes.bool,
    getAllAssets: PropTypes.func,
    allEquipment: PropTypes.object
}

BundleServices.defaultProps = {
    isDeletable: true
}

export default BundleServices;