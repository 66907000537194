import {colors, reverseColor, rolesDescriptions, schedulerEventStatuses} from "../../../common/commonHandlers";
import Dollar, {dollar} from "../../../components/Dollars";
import React, {useEffect} from "react";
import './EventContent.scss';
import moment from "moment/moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const EventContent = ({event, filters, employees, proposalSites}) => {

    let eventData = event.event.extendedProps;
    let eventColor = null;
    let borderColor = null;
    let textColor = null;
    const employee = employees.find(u => u.value === eventData.person_id);
    if (filters) {
        const user = filters.users.find(u => u.value === eventData.person_id);
        if (filters.color === 'employee') {
            const color = user ? (user.color === "default" ? '#3a87ad' : typeof user.color === 'number' ? colors[user.color] : user.color ? user.color : employee ? employee.color : '#3a87ad' ) : '#3a87ad'
            eventColor = eventData.selected ? '#f8e500' : eventData.highlighted ? 'radial-gradient(circle, #fff500, #ffe400, #ffd300, #ffc200, #ffb100)' : color
            borderColor = eventData.selected ? '#f8e500' : eventData.highlighted ? 'radial-gradient(circle, #fff500, #ffe400, #ffd300, #ffc200, #ffb100)' : color
            textColor = eventData.selected ? 'black' : eventData.highlighted ? 'black' : reverseColor(color)
        } else if (filters.color === 'type') {
            eventColor = colors[parseInt(eventData.scheduler_event_type_color)];
            borderColor = colors[parseInt(eventData.scheduler_event_type_color)];
            textColor = reverseColor(colors[parseInt(eventData.scheduler_event_type_color)]);
        } else {
            eventColor = schedulerEventStatuses().find(x => x.value === eventData.status).color;
            borderColor = schedulerEventStatuses().find(x => x.value === eventData.status).color;
            textColor = reverseColor(schedulerEventStatuses().find(x => x.value === eventData.status).color);
        }
    } else {
        eventColor = eventData.selected ? '#f8e500' : eventData.highlighted ? 'radial-gradient(circle, #fff500, #ffe400, #ffd300, #ffc200, #ffb100)' : (employee ? employee.color : '#3a87ad');
        borderColor = eventData.selected ? '#f8e500' : eventData.highlighted ? 'radial-gradient(circle, #fff500, #ffe400, #ffd300, #ffc200, #ffb100)' : (employee ? employee.color : '#3a87ad');
        textColor = eventData.selected ? 'black' : eventData.highlighted ? 'black' : (employee && employee.color ? reverseColor(employee.color) : reverseColor('#3a87ad'));
    }
    let duration
    let timeDiff = eventData.hours ? moment.duration(parseFloat(eventData.hours.toString().length > 3 ? eventData.hours.toFixed(2) : eventData.hours), 'hours') : moment.duration(moment(event.event.end).diff(moment(event.event.start)));
    const hr = Math.floor(timeDiff.asHours());
    const min = Math.floor(timeDiff.asMinutes()) - hr * 60;
    if (isNaN(hr)) {
        duration = 'Invalid date'
    } else {
        duration = ` ${hr} hr ${min} min`.replace(' 0 hr', '').replace(' 0 min', '')
    }

    let isMarkerSelected = false
    if (proposalSites && proposalSites.length > 0) {
        proposalSites.map(x => {
            if (x === (eventData && eventData.site_id)) {
                isMarkerSelected = true;
            }
        })
    }

    const renderEvent=()=>{
         return <div className={`event-content ${event.view.type === 'dayGridMonth' && 'month'}`}
             style={{
                 background: isMarkerSelected ? "#ff8c00" : eventColor,
                 borderColor: isMarkerSelected ? "#ff8c00" : borderColor,
                 color: textColor,
                 height: '100%',
             }}>
            {!event.event.allDay &&
            <React.Fragment>
                <small>
                    <span>{moment(event.event.start).format('h:mm')} - {moment(event.event.end).format('h:mm')}</span>{' '}
                    <span>{duration}</span>
                </small>
                <br/>
            </React.Fragment>}
            {!eventData.work_order_no ? eventData.name && <b>{eventData.name}</b> :
                <b>WO #{eventData.work_order_no} {dollar(eventData.price)}</b>}
            {' '}
            {eventData.customer_name && (!eventData.location_address ?
                <small>
                    <strong>{eventData.customer_name}</strong><br/>
                    {eventData.site_name}{' '}
                    {eventData.site_street}<br/>
                    {eventData.site_city}{' '}
                    {eventData.site_state}{' '}
                    {eventData.site_zip}
                </small> :
                <small>
                    <strong>{eventData.customer_name}<br/>
                    </strong>{eventData.location_address}
                </small>)}<br/>
            <strong>{eventData.source_name || eventData.person_name}</strong>
        </div>
    }

    return <>{eventData.work_order_no ?
        <OverlayTrigger placement="top" overlay={
            <Tooltip id="user-tooltip">
                <b>Services:</b>
                {eventData.services && eventData.services.map((s, i) =>
                    <div>#{s.service_no} {s.service_name} {s.est_hours && <>{s.est_hours}hr</>} <Dollar
                        amount={s.price}/>
                        {s.bs_services && <ul>
                            {s.bs_services.map((bs_s, i) => <li key={i}>{bs_s}</li>)}
                        </ul>}
                    </div>
                )}
            </Tooltip>}>
        {renderEvent()}
        </OverlayTrigger>:
        <>{renderEvent()}</>
    }</>
}

export default EventContent

