import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as AlertActions from '../App/actions'
import SecuredCustomer from "../../common/SecuredCustomer";

const Actions = Object.assign({}, AlertActions)

export default function (WrappedComponent) {

    class CustomerAuth extends Component {
        render() {
            return <SecuredCustomer token={this.props.params.token} isCustomerAuthenticated={this.props.isCustomerAuthenticated}>
                    <WrappedComponent {...this.props} />
                </SecuredCustomer>

        }
    }

    function mapStateToProps(state) {
        return {
            isCustomerAuthenticated: state.auth.isCustomerAuthenticated,
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(Actions, dispatch)
        }
    }


    return connect(mapStateToProps, mapDispatchToProps)(CustomerAuth)
}
