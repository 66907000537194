import axios from "axios"
import {apiCall} from "../../common/apiActionHandler";
import {dataUpload} from "../../common/upload";

export const optCustomer = (optMeOut, customerId, callback) => async (dispatch, getState) => {
    const customer_id = optMeOut ? customerId : undefined
    const method = optMeOut ? "POST" : "DELETE";
    const url = optMeOut ? "/api/opt_outs" : `/api/opt_outs/${customerId}`;
    const config = {
        method,
        url,
        data: {customer_id}
    };

    apiCall(customer_id ? "OptOut created" : "OptOut deleted", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const fetchRenewals = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/renewals`,
        params: filter
    };

    apiCall("Getting renewals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const fetchOptOuts = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/opt_outs`
    };

    apiCall("Getting optouts", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveDownloadedFile = (blob, params, source) => (dispatch, getState) => {
    dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(params ? params : {}), source: source}, false)(dispatch, getState)
}