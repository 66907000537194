import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Image,
    Row,
    Tooltip,
    OverlayTrigger,
    InputGroup, Tab, Tabs, Button, Modal
} from "react-bootstrap";
import {merge, select, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import Select from "react-select";
import {dollar} from "../../components/Dollars";
import Dropzone from "react-dropzone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import * as actions from "./ConsumablesApi"
import documentIcon from "../../images/document.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import csvIcon from "../../images/csv.png";
import pdfIcon from "../../images/pdf.png";
import wordIcon from "../../images/word.png";
import txtIcon from "../../images/txt.png";
import zipIcon from "../../images/zip.png";
import moment from "moment-timezone";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import ServicePrioritiesFilters from "./ServicePrioritiesFilters";
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";
import {dateFormatter, dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import {typeTable, unitsTable as consumables_units, unitsTable} from "../../common/ConsumablesHelper";
import LabelWithText from "../../components/LabelWithText";

const consumables = createApiService('consumables', 'consumable', 'Consumable');
const api = {
    ...consumables,
    ...actions
}

const isMobile = window.screen.width <= 1024;

const documentIcons = {
    csv: csvIcon,
    pdf: pdfIcon,
    docx: wordIcon,
    txt: txtIcon,
    zip: zipIcon
}

class Consumables extends ResourceComponent {
    defaultValues = {name: '', epa: '', description: '', disabled: false, distributor: '', volume: '', activeIngredients: []};
    state = {
        allowEditingChange: false,
        price: 0,
        priceEdit: false,
        plants: [],
        factors: [],
        services: [],
        filterModalShow: false,
        availableActiveIngredients: [],
        activeIngredientModal: {
            name: '',
            percentage: '0'
        },
        showCreateNewActiveIngredientModal: false,
    };

    updateResources = resources => {
        this.setState({
            resources: resources,
            resource: resources.find(r => r.id === this.state.resource?.id) || this.state.resource
        }, this.checkForDuplicateName)
    }

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
        this.props.actions.loadConsumables(res => this.setState({plants: res.plants, factors: res.factors, services: res.services, availableActiveIngredients: res.active_ingredients || []}))
    };

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues), key: 0}, () => {
            this.calculateCost()
            this.checkForDuplicateName()
        });
    };

    reload = () => {
        this.props.actions.load({}, this.updateResources);
    }

    handleSelectType = e => {
        const {resource} = this.state
        if (e !== null) {
            this.setState({
                resource: {
                    ...resource,
                    consumables_types_id: e.value,
                    consumables_measurement_id: null,
                    measurement_dilution_rate: null,
                    cost: 0
                },
                cost: 0
            })
        }
        if (e === null) {
            this.setState({
                resource: {
                    ...resource,
                    consumables_types_id: null,
                    consumables_measurement_id: null,
                    measurement_dilution_rate: null,
                    cost: 0
                },
                cost: 0
            })
        }
    }

    isEmptyValue = (value, forceTwoDigit = false) => {
        if (value === undefined || value === null) {
            return ""
        }
        if ((typeof (value) === 'string') && forceTwoDigit && value.split('.')[1]?.length > 2) {
            return parseFloat(value).toFixed(2)
        } else {
            return value
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;
        if ((prevState.resources !== resources) && resource) {
            if (resources.length > prevState.resources.length) {
                resource.id = resource?.id ? resource.id : Math.max.apply(Math, resources.map((o) => o.id))
            }
            let updatedResource = _.cloneDeep(resources.find(r => r.id === resource?.id))
            let updatedToMerge = resources.find(r => r.id === resource?.id)
            let merged = {..._.omitBy(updatedToMerge, _.isNull), ..._.omitBy(prevState.resource, _.isNull)}
            updatedResource = {
                ...merged,
                consumable_files: updatedResource?.consumable_files,
                audit_activity: updatedResource?.audit_activity,
                modified_at_date: updatedResource?.modified_at_date,
                modified_by_name: updatedResource?.modified_by_name
            }
            this.setState({resources, resource: updatedResource?.id && updatedResource});
        }
    }

    calculateCost = () => {
        const {resource} = this.state
        if (
            resource?.consumables_types_id &&
            resource?.consumables_measurement_id &&
            resource?.dilution_rate &&
            resource?.per &&
            resource?.volume &&
            resource?.measurement_dilution_rate &&
            resource?.measurement_per &&
            resource?.price
        ) {
            const baseUnit = unitsTable[resource.consumables_types_id - 1].options.find(el => el.value === resource.consumables_measurement_id)
            const newUnit = unitsTable[resource.consumables_types_id - 1].options.find(el => el.value === resource.measurement_dilution_rate)

            let newCost = ((resource.dilution_rate * newUnit.factor) * parseFloat(resource.price.replace(/[^0-9.-]/g, '')) / (resource.volume * baseUnit.factor))
            if (newCost !== Infinity) {
                this.setState(() => ({cost: newCost}))
            }
        }else{
            this.setState(() => ({cost: 0}))
        }
    }

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    handleTabSelect = (key) => {
        const {resource} = this.state
        this.setState({key: key});
        if (key === 1) {
            this.props.actions.get(resource.id, this.reloadResources)
        }
    }

    proposalFormatter = (cell, row) => (
        <a href={`/mapview/${row.proposal_id}`} target={'_blank'}>
            <span className={'text-blue pointer'}>{row.proposal_no}</span>
        </a>);

    costFormatter = (cell, row) => {
        return cell ? dollarsFormatter(cell) : ""

    }


    unitsFormatter = (cell, row) => {
        let unit_name;
        consumables_units.map(cu=>{
            cu.options.map(o=>{
                if(o.value===row.units_used){
                    unit_name=o.label;
                }
            })
        })
        return (
        <span>{row.amount_used}{" "}{unit_name}</span>)};

    checkForDuplicateName = () =>{
        let {resource} = this.state
        let name = this.state.resource?.name
        let id = this.state.resource?.id
        if(name && !id){
            this.props.actions.checkForDuplicateName(resource, (response)=>{
                if(response.status===208){
                    this.setState({isDuplicateNameError: true})
                }
                else if(response.status===202){
                    this.setState({isDuplicateNameError: false})
                }
            })
        }else{
            this.setState({isDuplicateNameError: false})
        }
    }

    handleCreateNewActiveIngredient = () => {
        this.props.actions.createActiveIngredient(this.state.activeIngredientModal, (res) => {
            this.setState({
                showCreateNewActiveIngredientModal: false,
                availableActiveIngredients: [...this.state.availableActiveIngredients, res],
                activeIngredientModal: {
                    name: '',
                    percentage: '0'
                },
            })
        })
    }

    render() {
        const {resource, resources, isDuplicateNameError} = this.state;
        return (
            resources ?
                <Grid fluid className="consumables">
                    <MasterDetail
                        resourceName="Consumable"
                        resourceNamePlural="Consumables"
                        resources={resources}
                        resource={resource}
                        buttonsBottom={true}
                        select={this.handleSelect}
                        blockSave={isDuplicateNameError}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                this.setState({oldResource: this.state.resource});
                                this.props.actions.save({...resource, cost: this.state.cost}, this.reload)
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource &&
                        <Tabs activeKey={this.state.key}
                              id="controlled-tab-example"
                              mountOnEnter={true}
                              onSelect={this.handleTabSelect}
                              animation={false} id="noanim-tab-example">
                            <Tab eventKey={0} title={`${resource.name || 'New Consumable'}`}>
                            <div>
                                <Row>
                                    <Col md={4} className="jumbotronAlign">
                                        <FormGroup className={isDuplicateNameError && "bottom0"}>
                                            <ControlLabel>
                                                {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.name ? <OverlayTrigger placement="top"
                                                                overlay={<Tooltip id="tooltip">
                                                                    Last
                                                                    updated: {moment(JSON.parse(resource?.audit_activity)?.name).format('llll')}
                                                                </Tooltip>}>
                                                    <span>Brand Name</span>
                                                </OverlayTrigger> : <span>Brand Name</span>}
                                            </ControlLabel>
                                            <FormControl
                                                name="name"
                                                onChange={this.updateResourceAttr}
                                                onBlur={this.checkForDuplicateName}
                                                value={resource.name}
                                            />
                                            {this.state.errorMessage && <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>
                                                {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.epa ? <OverlayTrigger placement="top"
                                                                                                                                          overlay={<Tooltip id="tooltip">
                                                                                                                                              Last
                                                                                                                                              updated: {moment(JSON.parse(resource?.audit_activity)?.epa).format('llll')}
                                                                                                                                          </Tooltip>}>
                                                    <span>EPA #</span>
                                                </OverlayTrigger> : <span>EPA #</span>}
                                            </ControlLabel>
                                            <FormControl
                                                name="epa"
                                                onChange={this.updateResourceAttr}
                                                value={resource.epa}
                                            />
                                        </FormGroup>
                                        {isDuplicateNameError && <p className="errorText" style={{"margin-bottom": "15px"}}>Consumable name already taken</p>}
                                        <FormGroup>
                                            <ControlLabel>
                                                {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.description ? <OverlayTrigger placement="top"
                                                                overlay={<Tooltip id="tooltip">
                                                                    Last
                                                                    updated: {moment(JSON.parse(resource?.audit_activity)?.description).format('llll')}
                                                                </Tooltip>}>
                                                    <span>Description</span>
                                                </OverlayTrigger> : <span>Description</span>}
                                            </ControlLabel>
                                            <FormControl
                                                name="description"
                                                componentClass="textarea"
                                                rows={3}
                                                onChange={this.updateResourceAttr}
                                                value={resource.description}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={8} className="jumbotronAlign">
                                        <Col md={12} className="mobile-padding">
                                            <Col md={4} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.distributor ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last updated: {moment(JSON.parse(resource?.audit_activity)?.distributor).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Distributor</span>
                                                        </OverlayTrigger> : <span>Distributor</span>}
                                                    </ControlLabel>
                                                    <FormControl
                                                        name="distributor"
                                                        onChange={this.updateResourceAttr}
                                                        value={resource.distributor}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.consumables_types_id ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.consumables_types_id).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Type</span>
                                                        </OverlayTrigger> : <span>Type</span>}
                                                    </ControlLabel>
                                                    <Select className="Select" classNamePrefix="select"
                                                            value={select(typeTable, resource.consumables_types_id)}
                                                            options={typeTable}
                                                            onChange={this.handleSelectType}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.volume ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.volume).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Volume</span>
                                                        </OverlayTrigger> : <span>Volume</span>}
                                                    </ControlLabel>
                                                    <FormControl
                                                        type="number"
                                                        name="volume"
                                                        onChange={(e) => this.updateResourceAttr(e, this.calculateCost)}
                                                        value={resource.volume}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.consumables_measurement_id ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.consumables_measurement_id).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Measurement</span>
                                                        </OverlayTrigger> : <span>Measurement</span>}
                                                    </ControlLabel>
                                                    <Select className="Select" classNamePrefix="select"
                                                            value={resource.consumables_types_id === null || resource.consumables_types_id === undefined ? select([], '') : select(unitsTable[resource.consumables_types_id - 1].options, resource.consumables_measurement_id)}
                                                            options={resource.consumables_types_id === null || resource.consumables_types_id === undefined ? [] : unitsTable[resource.consumables_types_id - 1].options}
                                                            onChange={(e) => this.selectResourceAttr("consumables_measurement_id", this.calculateCost)(e)}
                                                            isDisabled={resource.consumables_types_id === null || resource.consumables_types_id === undefined}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.price ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.price).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Price</span>
                                                        </OverlayTrigger> : <span>Price</span>}
                                                    </ControlLabel>
                                                    <InputGroup>
                                                        <InputGroupAddon>
                                                            $
                                                        </InputGroupAddon>
                                                        <FormControl
                                                            name="price"
                                                            type="text"
                                                            onChange={(e) => this.updateResourceAttr(e, this.calculateCost)}
                                                            value={this.isEmptyValue(resource.price, true)}
                                                            onBlur={(e) => this.dollarFormatAttr(e)}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <hr className="grey"/>
                                        </Col>
                                        <Col md={12} className="vcenter mobile-padding">
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.dilution_rate ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.dilution_rate).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Dilution Rate</span>
                                                        </OverlayTrigger> : <span>Dilution Rate</span>}
                                                    </ControlLabel>
                                                    <FormControl
                                                        name="dilution_rate"
                                                        type="number"
                                                        onChange={(e) => this.updateResourceAttr(e, this.calculateCost)}
                                                        value={resource.dilution_rate ? resource.dilution_rate : ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.measurement_dilution_rate ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.measurement_dilution_rate).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Measurement</span>
                                                        </OverlayTrigger> : <span>Measurement</span>}
                                                    </ControlLabel>
                                                    <Select className="Select" classNamePrefix="select"
                                                            value={resource.consumables_types_id === null || resource.consumables_types_id === undefined ? select([], '') : select(unitsTable[resource.consumables_types_id - 1].options, resource.measurement_dilution_rate)}
                                                            options={resource.consumables_types_id === null || resource.consumables_types_id === undefined ? [] : unitsTable[resource.consumables_types_id - 1].options}
                                                            onChange={(e) => this.selectResourceAttr("measurement_dilution_rate", this.calculateCost)(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.per ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.per).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Per</span>
                                                        </OverlayTrigger> : <span>Per</span>}
                                                    </ControlLabel>
                                                    <FormControl
                                                        name="per"
                                                        type="number"
                                                        onChange={(e) => this.updateResourceAttr(e, this.calculateCost)}
                                                        value={resource.per ? resource.per : ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.measurement_per ? <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {moment(JSON.parse(resource?.audit_activity)?.measurement_per).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Measurement</span>
                                                        </OverlayTrigger> : <span>Measurement</span>}
                                                    </ControlLabel>
                                                    <Select className="Select" classNamePrefix="select"
                                                            value={select(unitsTable[resource.measurement_group_per - 1]?.options, resource.measurement_per)}
                                                            options={unitsTable}
                                                            onChange={(e) => this.selectResourceAttr("measurement_per", () => {
                                                                this.calculateCost();
                                                                this.setState({
                                                                    resource: {
                                                                        ...resource,
                                                                        measurement_group_per: e === null ? 1 : e.group
                                                                    }
                                                                })
                                                            })(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <FormGroup>
                                                    <ControlLabel>
                                                        <span>Cost</span>
                                                    </ControlLabel>
                                                    <div>{dollar(this.state.cost)}</div>

                                                </FormGroup>
                                            </Col>
                                        </Col>
                                        <Col md={12} className="vcenter mobile-padding">
                                            <Col md={10} className="mobile-padding">

                                                <FormGroup>
                                                    <ControlLabel>
                                                        <span>Active Ingredients</span>
                                                    </ControlLabel>
                                                    <Select className="Select"
                                                            classNamePrefix="select"
                                                            value={select(this.state.availableActiveIngredients, resource.consumable_active_ingredients)}
                                                            options={this.state.availableActiveIngredients}
                                                            placeholder="Active Ingredients"
                                                            isClearable
                                                            isMulti
                                                            onChange={e => this.selectResourceAttr('consumable_active_ingredients')(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className="mobile-padding">
                                                <Button bsStyle="info"
                                                        type="button"
                                                        className="mt10"
                                                        onClick={() => this.setState({showCreateNewActiveIngredientModal: true})}
                                                >
                                                    Create
                                                </Button>
                                            </Col>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="align-text-end">
                                    <Col md={4}>
                                        {resource.id &&
                                            <div>
                                                <ControlLabel>
                                                    {resource?.audit_activity && JSON.parse(resource?.audit_activity)?.MSDS ? <OverlayTrigger placement="top"
                                                                    overlay={<Tooltip id="tooltip">
                                                                        Last
                                                                        updated: {resource?.modified_at_date && moment(JSON.parse(resource?.audit_activity)?.MSDS).format('llll')}
                                                                    </Tooltip>}>
                                                        <span>MSDS</span>
                                                    </OverlayTrigger> : <span>MSDS</span>}
                                                </ControlLabel>
                                                <div className="flex-wrap">
                                                    {resource.consumable_files?.map(cf => {
                                                        return <div className='service-documents'>
                                                            <a href={cf.original} target='_blank'>
                                                                <Image
                                                                    src={cf.mini || documentIcons[cf.document_type] || documentIcon}/>
                                                                <p className='doc-name'>{cf.name}</p>
                                                            </a>
                                                            <FontAwesomeIcon
                                                                className='remove-icon'
                                                                icon={faTrash}
                                                                onClick={() => this.props.actions.deleteConsumablesAttachment(cf.id, resource.id, () => {
                                                                    this.reload()
                                                                })}/>
                                                        </div>
                                                    })}
                                                </div>
                                                <Dropzone
                                                    onDrop={files => this.props.actions.uploadConsumablesAttachment(resource.id, files, () => {
                                                        this.reload()
                                                    })}
                                                    className="dropzone pointer small mt1">
                                                    <p>Drop a file here or click to select a file to upload.</p>
                                                </Dropzone>
                                            </div>}
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup className="flex-column-center">
                                            <span>PHC/IPM Associations</span>
                                            <ColorCheckbox value={this.state.allowEditingChange}
                                                           label="Allow Editing"
                                                           onChange={() => this.setState({allowEditingChange: !this.state.allowEditingChange})}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr className="grey"/>
                                <Row className="textCenter">
                                    <Col md={4} xs={6}>
                                        <Select className="Select"
                                                classNamePrefix="select"
                                                value={select(this.state.plants, resource.consumable_relevant_plants_ids)}
                                                options={this.state.plants}
                                                placeholder="Relevant Plants"
                                                isClearable
                                                isMulti
                                                isDisabled={!this.state.allowEditingChange}
                                                onChange={e => this.selectResourceAttr('consumable_relevant_plants_ids')(e)}
                                        />
                                    </Col>
                                    <Col md={4} xs={6}>
                                        <Select className="Select"
                                                classNamePrefix="select"
                                                value={select(this.state.factors, resource.consumable_factors_ids)}
                                                options={this.state.factors}
                                                placeholder="Factors"
                                                isClearable
                                                isMulti
                                                isDisabled={!this.state.allowEditingChange}
                                                onChange={e => this.selectResourceAttr('consumable_factors_ids')(e)}
                                        />
                                    </Col>
                                    <Col md={4} xs={6}>
                                        <Select className="Select"
                                                classNamePrefix="select"
                                                value={select(this.state.services, resource.consumable_services_ids)}
                                                options={this.state.services}
                                                placeholder="Services"
                                                isClearable
                                                isMulti
                                                isDisabled={!this.state.allowEditingChange}
                                                onChange={e => this.selectResourceAttr('consumable_services_ids')(e)}
                                        />
                                    </Col>
                                </Row>
                                <hr className="grey"/>
                            </div>
                            </Tab>
                            {resource.id && <Tab eventKey={1} title="Consumption">
                                {this.state.key === 1 &&
                                <div>
                                    <Row>
                                        <Col md={3} mdOffset={9} className='align-right margin-top-bottom-20'>
                                            <Button
                                                className="pointer no-top-margin"
                                                bsStyle="warning"
                                                onClick={() => this.setState({
                                                    filterModalShow: !this.state.filterModalShow,
                                                    oldResource: {...resource}
                                                })}>
                                                Filter {" "}<FontAwesomeIcon icon="search"
                                                                             className="small-margin"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {resource.proposals &&
                                            <BootstrapTable
                                                data={resource.proposals}
                                                striped={true}
                                                bordered={false}
                                                hover={true}
                                                pagination={true}
                                                search={true}
                                            >
                                                <TableHeaderColumn
                                                    width={'100'}
                                                    isKey={true}
                                                    dataField="service_name"
                                                    tdStyle={{whiteSpace: 'normal'}}
                                                    thStyle={{whiteSpace: 'normal'}}
                                                    dataSort={true}
                                                    columnClassName='menu-show-fixer'
                                                >
                                                    Service Name
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataFormat={this.proposalFormatter}
                                                    width={'100'}
                                                    dataField="proposal_no"
                                                    tdStyle={{whiteSpace: 'normal'}}
                                                    thStyle={{whiteSpace: 'normal'}}
                                                    dataSort={true}
                                                    columnClassName='menu-show-fixer'
                                                >
                                                    Proposal_no
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    width={'100'}
                                                    tdStyle={{whiteSpace: 'normal'}}
                                                    thStyle={{whiteSpace: 'normal'}}
                                                    columnClassName='menu-show-fixer'
                                                    dataField="any"
                                                    dataFormat={this.unitsFormatter}
                                                >
                                                    Usage
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    width={'100'}
                                                    tdStyle={{whiteSpace: 'normal'}}
                                                    thStyle={{whiteSpace: 'normal'}}
                                                    columnClassName='menu-show-fixer'
                                                    dataField="cost"
                                                    dataFormat={this.costFormatter}
                                                >
                                                    Cost
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    width={'100'}
                                                    tdStyle={{whiteSpace: 'normal'}}
                                                    thStyle={{whiteSpace: 'normal'}}
                                                    columnClassName='menu-show-fixer'
                                                    dataField="ps_accepted_date"
                                                    dataFormat={dateFormatter}
                                                >
                                                    Proposal service accepted date
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    width={'100'}
                                                    tdStyle={{whiteSpace: 'normal'}}
                                                    thStyle={{whiteSpace: 'normal'}}
                                                    columnClassName='menu-show-fixer'
                                                    dataField="date"
                                                    dataFormat={dateFormatter}
                                                >
                                                    Date
                                                </TableHeaderColumn>
                                            </BootstrapTable>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                }
                                <LeadsFilterModal
                                    title={'Filter'}
                                    show={this.state.filterModalShow}
                                    onHide={() => {
                                        this.setState({filterModalShow: false})
                                        this.props.actions.getFilteredData(resource.id, (e) => this.setState({
                                            resource: {
                                                ...resource,
                                                proposals: e.proposals
                                            }
                                        }), resource)
                                    }}
                                    closeButton={() => this.setState({filterModalShow: false})}
                                    showAcceptBtn
                                    backdrop='static'
                                >
                                    <ServicePrioritiesFilters fetchFilterData={this.props.actions.fetchFilterData}
                                                              selectAttr={(e) => this.selectResourceAttr(e)}
                                                              selectCheckboxAttr={(e) => this.selectCheckboxAttr(e)}
                                                              resource={resource}
                                                              dateResourceAttr={(e) => this.dateResourceAttr(e)}/>
                                </LeadsFilterModal>
                            </Tab>}
                        </Tabs>}
                        <div className={isMobile ? '' : 'custom-footer'}>
                            <div>
                                <ColorCheckbox value={resource?.disabled}
                                               label="Disabled"
                                               onChange={this.selectCheckboxAttr('disabled')}/>
                            </div>
                            <div>
                                Last
                                updated {resource?.modified_at_date ? moment(resource?.modified_at_date).format('llll') : <i>Unknown</i>} by {resource?.modified_by_name}
                            </div>
                        </div>
                    </MasterDetail>
                    <Modal
                        // animation={false}
                        show={this.state.showCreateNewActiveIngredientModal}
                        className={"heightAuto maxHeight90"}
                    >
                        <Modal.Header>
                            <Modal.Title>New Active Ingredient</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mt10">
                                <FormGroup>
                                    <ControlLabel>
                                        Active Ingredient Name
                                    </ControlLabel>
                                    <FormControl
                                        name="name"
                                        value={this.state.activeIngredientModal.name}
                                        onChange={(e) => this.setState({activeIngredientModal: {...this.state.activeIngredientModal, name: e.target.value}})}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>
                                        Percentage
                                    </ControlLabel>
                                    <FormControl
                                        name="description"
                                        type="number"
                                        step="0.01"
                                        value={parseFloat(this.state.activeIngredientModal.percentage)}
                                        onChange={(e) => this.setState({activeIngredientModal: {...this.state.activeIngredientModal, percentage: e.target.value}})}
                                    />
                                </FormGroup>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={() => this.setState({showCreateNewActiveIngredientModal: false})} type="button" className="btn btn-default"
                                    data-dismiss="modal">Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.handleCreateNewActiveIngredient}
                            >
                                Save
                            </button>

                        </Modal.Footer>
                    </Modal>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Consumables)
