import React, {Component} from "react"
import PropTypes from "prop-types"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {showEmailDialog} from "../../components/email/actions"
import * as MyActions from "./actions"
import {Button, Col, ControlLabel, FormControl, FormGroup, Grid, Row, Well,} from "react-bootstrap"
import StatusProposalServicesList from "./StatusProposalServicesList"
import Dollars from "../../components/Dollars"
import moment from "moment"
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import {browserHistory} from "react-router";
import {defaultDateFormat, formatInlineDateOnly} from "../../common/commonHandlers";
import Datetime from "react-datetime";

const Actions = {...MyActions, showEmailDialog};

class ProposalServiceStatusPage extends Component {
    onSearch = e => {
        e.preventDefault()
        this.props.actions.searchByProposalNo()
    }
    handleAcceptedDateChange = proposalDate => {
        console.log("the new date is", proposalDate)
        this.props.actions.updateProposalAcceptedDate(
            proposalDate ? proposalDate.format() : null
        )
    }
    handleSelectAllChecked = e => {
        this.props.eligibleProposalServices.forEach(eps =>
            this.props.actions.selectPs(e, eps.id)
        )
    }
    renderProposalServicesListHeader = () => (
        <Well bsSize="small">
            <Row>
                <Col md={1}>
                    <label>
                        <input
                            type="checkbox"
                            disabled={this.props.eligibleProposalServices.length === 0}
                            checked={
                                this.props.selectedPs.length &&
                                this.props.eligibleProposalServices.length ===
                                this.props.selectedPs.length
                            }
                            onChange={this.handleSelectAllChecked}
                        />
                        {" "}
                        #
                    </label>
                </Col>
                <Col md={3}>
                    Promised Date
                </Col>
                <Col md={2}>
                    Service
                </Col>
                <Col md={1}>
                    Status
                </Col>
                <Col md={1}>
                    Priority
                </Col>
                <Col md={2}>
                    Timing
                </Col>
                <Col md={1}>
                    Est Hrs
                </Col>
                <Col md={1}>
                    Price
                </Col>

            </Row>
        </Well>
    )

    handleDeclinedDateChange = proposalDate => {
        this.props.actions.updateDeclinedDate(this.props.params.proposalId,
            proposalDate ? proposalDate.format() : null, this.props.selectedPs)
        this.forceUpdate();
    };
    changePsStatus = e => {
        e.preventDefault()
        if (this.props.destinationStatusCode === "IN_WORK_ORDER") {
            this.props.actions.deleteConsumption(this.props.selectedPs)
        }
        this.props.actions.changePsStatus()
    }
    handleActionSelectChange = e => {
        this.props.actions.selectDestinationStatusCode(e.target.value)
    }
    totalAccepted = proposalServicesInfo =>
        proposalServicesInfo.reduce(
            (memo, ps) =>
                memo + (ps.proposalServiceStatus !== "Proposed" && ps.proposalServiceStatus !== "Not Offered" && ps.proposalServiceStatus !== "Declined" ? ps.serviceCost : 0),
            0
        )
    totalProposed = proposalServicesInfo =>
        proposalServicesInfo.reduce(
            (memo, ps) =>
                memo + ps.serviceCost,
            0
        )
    renderAcceptedDateForm = acceptedDate => {
        const acceptedServices = this.props.proposalServicesInfo.filter(ps => ps.accepted_date !== null);
        return <FormGroup bsSize="small">
            <ControlLabel><strong>Accepted Date:</strong></ControlLabel>{" "}
            <Datetime
                dateFormat={defaultDateFormat}
                timeFormat={null}
                value={acceptedDate ? moment(acceptedDate) : null}
                disabled={acceptedServices.length > 0 || this.props.proposalInfo.declinedDate}
                onChange={this.handleAcceptedDateChange}/>
        </FormGroup>
    }
    renderProposalActionInfo = () => (
        <div>
            <Row>
                <Col xs={12} md={4}>
                    <h5><strong>
                        Proposed On:
                        {" "}
                        {this.props && this.props.proposalInfo && formatInlineDateOnly(this.props.proposalInfo.proposalDate)}
                    </strong></h5>
                </Col>
                <Col xs={12} md={4}>
                    <h5><strong>
                        Proposed:
                        {" "}
                        <Dollars
                            amount={this.totalProposed(this.props.proposalServicesInfo)}
                        />
                    </strong></h5>
                </Col>
                <Col xs={12} md={4}>
                    <h5 className="text-success"><strong>
                        Accepted:
                        {" "}
                        <Dollars
                            amount={this.totalAccepted(this.props.proposalServicesInfo)}
                        />
                    </strong></h5>
                </Col>
            </Row>
            <Row className={`top15`}>
                <Col md={4}>
                    <ControlLabel><strong>Update checked PS Status to</strong></ControlLabel>
                    <FormGroup bsSize="small">
                        <FormControl
                            componentClass="select"
                            value={this.props.destinationStatusCode}
                            onChange={this.handleActionSelectChange}
                            name="destinationStatusCode"
                        >
                            <option key={0} value={"-"}>Select Status</option>
                            {this.props.proposalServiceStatuses
                                .slice()
                                .sort((a, b) => a - b)
                                .map(status => (
                                    <option key={status.code} value={status.code}>
                                        {status.label}
                                    </option>
                                ))}
                        </FormControl>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    {this.renderAcceptedDateForm(this.props.proposalInfo.acceptedDate)}

                </Col>
                <Col md={4}>
                    {this.renderDeclinedDateForm(this.props.proposalInfo.declinedDate)}
                </Col>
            </Row>
            <Row>
                <Col md={6} className="top27">
                    <Button
                        disabled={this.props.selectedPs.length < 1}
                        bsSize="small"
                        bsStyle="success"
                        type="submit"
                        onClick={this.changePsStatus}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </div>
    )
    renderDeclinedDateForm = declinedDate => (
        <FormGroup bsSize="small">
            <ControlLabel><strong>Declined Date:</strong></ControlLabel>{" "}
            <Datetime
                dateFormat={defaultDateFormat}
                timeFormat={null}
                value={declinedDate ? moment(declinedDate) : null}
                disabled={this.props.proposalInfo.acceptedDate}
                onChange={this.handleDeclinedDateChange}/>
        </FormGroup>
    )

    componentWillMount() {
        this.state = {};
    }

    showEmailDialog = () => {
        const {email, proposalInfo} = this.props;
        this.props.actions.showEmailDialog(
            {
                emailType: "proposal",
                referenceId: proposalInfo.id,
                recipient: proposalInfo.contactEmail,
                defaultEmail: email
            });
    };

    handleProposalIdChange = (id) => {
        browserHistory.push(`/proposal_service_status/${id}`)
    };

    onEmailSend = (id) => {
        this.props.actions.loadProposalInfo(id)
    }

    componentDidMount() {
        this.props.actions.fetchProposalServiceStatuses()
        if (this.props.params && this.props.params.proposalId) {
            this.props.actions.loadProposalInfo(this.props.params.proposalId)
        }
        this.props.actions.fetchPriorities()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {proposalInfo, proposalServiceStatuses} = this.props
        if (prevProps.proposalInfo?.proposalStatusId !== proposalInfo?.proposalStatusId) {
            this.props.actions.selectDestinationStatusCode(proposalInfo?.proposalStatusId === 2 || proposalInfo?.proposalStatusId === 8 ? proposalServiceStatuses[0]?.code : proposalServiceStatuses[1]?.code)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.params &&
            nextProps.params.proposalId &&
            nextProps.params.proposalId !== this.props.params.proposalId
        ) {
            console.info(
                "nextprops params was different so reloading page this and next",
                this.props.params.proposalId,
                nextProps.params.proposalId
            )
            this.props.actions.loadProposalInfo(nextProps.params.proposalId)
        }
    }

    render() {
        const {
            proposalNoSearchText,
            proposalInfo,
            proposalServicesInfo,
            destinationStatusCode,
            proposalServiceStatuses,
            selectedPs,
            eligibleProposalServices,
            email,
            priorities
        } = this.props
        return (
            <Grid fluid>
                <ProposalMenu
                    id={this.props && this.props.routeParams && this.props.routeParams.proposalId}
                    expandButton={false}
                    onReload={this.handleProposalIdChange}
                    onEmailSend={this.onEmailSend}
                />
                <Row>
                    <Col md={12}>
                        {this.renderProposalActionInfo()}
                        <p>&nbsp;</p>

                        {proposalServicesInfo.length
                            ? this.renderProposalServicesListHeader()
                            : null}

                        <div
                            style={{
                                minHeight: "10vh",
                                overflowY: "inherit",
                            }}
                        >
                            <StatusProposalServicesList
                                eligibleProposalServiceIds={eligibleProposalServices.map(
                                    eps => eps.id
                                )}
                                proposalInfo={proposalInfo}
                                proposalServicesInfo={proposalServicesInfo}
                                selectedPs={selectedPs}
                                selectPs={this.props.actions.selectPs}
                                destinationStatusCode={destinationStatusCode}
                                upsertPromise={this.props.actions.upsertPromise}
                                priorities={priorities}
                            />
                        </div>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const getEligibleProposalServices = (
    destinationStatusCode,
    proposalServices,
    userRoles
) => {
    if (userRoles.includes("owner") && destinationStatusCode !== "-") {
        if (destinationStatusCode === "IN_WORK_ORDER") {
            return proposalServices.filter(ps =>
                ["Completed", "Scheduled", "Invoiced"].includes(ps.proposalServiceStatus)
            )
        } else {
            return proposalServices.filter(ps => !["Paid", "Payment"].includes(ps.proposalServiceStatus))
        }
    }

    switch (destinationStatusCode) {

        case "PROPOSED":
            return proposalServices.filter(ps =>
                ["Not Offered", "Accepted", "Declined", "Draft"].includes(ps.proposalServiceStatus)
            )
        case "DECLINED":
            return proposalServices.filter(ps =>
                ["Proposed", "Accepted"].includes(ps.proposalServiceStatus)
            )
        case "ACCEPTED":
            return proposalServices.filter(ps =>
                ["Proposed", "Declined"].includes(ps.proposalServiceStatus)
            )
        case "NOT_OFFERED":
            return proposalServices.filter(ps =>
                ["Proposed", "Draft"].includes(ps.proposalServiceStatus)
            )
        case "IN_WORK_ORDER":
            return proposalServices.filter(ps =>
                ["Completed"].includes(ps.proposalServiceStatus)
            )
        default:
            return []
    }
}

ProposalServiceStatusPage.propTypes = {
    proposalNoSearchText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    proposalInfo: PropTypes.object.isRequired,
    proposalServicesInfo: PropTypes.array.isRequired,
    proposalServiceStatuses: PropTypes.array.isRequired,
    destinationStatusCode: PropTypes.string,
    selectedPs: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    username: state.auth.email,
    email: state.auth.email,
    proposalNoSearchText: state.proposalServiceStatus.proposalNoSearchText,
    proposalInfo: state.proposalServiceStatus.proposalInfo,
    proposalServicesInfo: state.proposalServiceStatus.proposalServicesInfo,
    proposalServiceStatuses: state.proposalServiceStatus.proposalServiceStatuses,
    destinationStatusCode: state.proposalServiceStatus.destinationStatusCode,
    selectedPs: state.proposalServiceStatus.selectedPs,
    priorities: state.mapView.priorities,
    eligibleProposalServices: getEligibleProposalServices(
        state.proposalServiceStatus.destinationStatusCode,
        state.proposalServiceStatus.proposalServicesInfo,
        state.auth.roles
    ),
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(
    ProposalServiceStatusPage
)
