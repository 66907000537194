import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {
    APP_STATE_LOADING,
    SET_ADMIN_TERMS_REQUIRED,
    SET_ADMIN_TERMS_STATUS,
    SET_OPTIONAL_GEOLOCATION,
    SET_REDIRECT_TO_SUBSCRIPTION_ROUTER,
    SET_SUBSCRIPTION_REMINDER,
    SET_SUBSCRIPTION_STATUS,
    SET_USER_TERMS_STATUS
} from "../App/constants";

export const loadRouterInfo = (user_id, callback, light) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${user_id}/get_router_info?light=${light}`,
        method: 'GET',
    };
    apiCall("Loading router resources", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const setUserTermsStatus = status => (dispatch) => {
    dispatch({type: SET_USER_TERMS_STATUS, status})
}

export const setAdminTermsStatus = status => (dispatch) => {
    dispatch({type: SET_ADMIN_TERMS_STATUS, status})
}

export const setAdminTermsRequired = status => (dispatch) => {
    dispatch({type: SET_ADMIN_TERMS_REQUIRED, status})
}
export const setOptionalGeolocation = status => (dispatch) => {
    dispatch({type: SET_OPTIONAL_GEOLOCATION, status})
}

export const setAppLoading = status => (dispatch) => {
    dispatch({type: APP_STATE_LOADING, status})
}

export const setRedirectRequired = status => (dispatch) => {
    dispatch({type: SET_REDIRECT_TO_SUBSCRIPTION_ROUTER, status})
}

export const setSubscriptionStatus = status => (dispatch) => {
    dispatch({type: SET_SUBSCRIPTION_STATUS, status})
}

export const setSubscriptionReminder = status => (dispatch) => {
    dispatch({type: SET_SUBSCRIPTION_REMINDER, status})
}