import React, {Component} from "react"
import LeadForm from "../../LeadsPage/LeadForm";
import {browserHistory} from "react-router";
import ProposalMenu from "./ProposalMenu";


class ProposalLead extends Component {


    handleProposalIdChange = (id, proposal_id) => {
        if (!proposal_id) {
            browserHistory.push(`/proposal_lead/${id}`)

        } else {
            browserHistory.push(`/mapview/${proposal_id}`)
        }

    };

    render() {
        const id = this.props.params.id;
        return (<div>
            <LeadForm id={id} context='proposal' menuRenderer={(resource, saveLead) =>
                <div>
                    <ProposalMenu
                        id={resource && resource.proposal_id}
                        expandButton={false}
                        onReload={this.handleProposalIdChange}
                    />
                </div>}/>
        </div>);
    }
}

export default ProposalLead
