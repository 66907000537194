import React from 'react'
import {GoogleMap} from "react-google-maps";
import ResourceComponent from "../../components/ResourceComponent";
import {defaultMapParams} from "../../common/commonHandlers";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import SiteMarker from "./SiteMarker";

class SitesMap extends ResourceComponent {

    state = {
        sites: [],
        loaded: false,
        state: true
    }

    componentDidUpdate(prevProps, prevState) {
        const {sites, selectedSiteId} = this.props
        if (sites !== prevProps.sites || selectedSiteId !== prevProps.selectedSiteId) {
            //if we want show only selected
            const updatedSites = selectedSiteId ? sites.filter(u => u.id === selectedSiteId) : sites;
            this.setState({
                sites: sites,
                loaded: false
            })
        }
    }

    render() {
        const {sites, center} = this.state;
        const {selectedSiteId, selectSiteMarker, mapRef, token, setSelectedSite} = this.props;
        if (center) {
            this.setState({center: false});
        }
        let mapParams = []
        sites.forEach(site => {
            let param = {};
            param.longitude = site.longitude;
            param.latitude = site.latitude;
            mapParams.push(param);
        });
        return sites && (<>
            <GoogleMapLoader
                containerElement={
                    <div
                        ref={mapRef}
                        id="sitessMap"
                        className="remaining vertical-responsive-height"
                    />
                }
                googleMapElement={
                    <GoogleMap
                        ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude))}
                        {...defaultMapParams(mapParams.filter(param => param.latitude), center)}
                    >
                        {sites && sites.map((site, i) => {
                            return site.latitude && (
                                <SiteMarker
                                    idx={i}
                                    site={site}
                                    selectedSiteId={selectedSiteId}
                                    selectSiteMarker={selectSiteMarker}
                                    setSelectedSite={setSelectedSite}
                                    token={token}/>
                            )
                        })
                        }
                    </GoogleMap>
                }
            />
        </>)

    }

}

export default SitesMap;
