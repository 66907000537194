import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../common/commonHandlers";

export const loadReport = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/lead_appointments`,
        params: filter
    };

    apiCall("Getting lead appointments report", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees?disabled=true`},
        {url: `/api/users/get_reports_settings`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            reports_settings: all[1].data
        });
    }, dispatch, getState, false);
};

export const saveFilter = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_reports_settings`,
        method: 'PUT',
        data: {resource}
    };
    apiCall("Saving reports settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};
