import axios from "axios/index";
import {mapForSelect} from "../../common/commonHandlers";
import {apiCall} from "../../common/apiActionHandler";

export const loadPlantSelects = callback => (dispatch, getState) => {
    const config = [
        {url: '/api/factors'},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            factors: mapForSelect(all[0].data),

        });
    }, dispatch, getState, false);
};
