import React, {useEffect, useState} from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InlineEditable from "../../../../Scheduler/InlineEditable";
import {CrewMessageEditor} from "../../../../Scheduler/CrewMessages/CrewMessagesEditor";
import TimelineElementWrapper from "./TimelineElementWrapper"
import {hexToRGB, themeBodyColor} from "../../../../../common/commonHandlers";

const TimelineNote = ({note, color, employeeName, currentUserPersonId, employeeDisabled, onSave, onDelete, employeePrimaryPhoto, customerId}) => {
    const [showNote, setShowNote] = useState(!note?.person_id || note?.is_quickbooks_note)
    const [editNote, setEditNote] = useState(false)
    const [textNote, setTextNote] = useState(null)

    let themeColor;

    useEffect(() => {
        if (note && textNote && note.notes !== textNote) {
            note.notes = textNote
            onSave(note)
        }
        if (!note?.id && textNote && textNote !== " " && textNote !== '<p></p>\n') {
            onSave({notes: textNote})
        }

    }, [editNote])

    const editArea = (note) => {
        return (
            <InlineEditable
                editMessage={editNote}
                editable={editNote}
                closeEditable={() => setEditNote(false)}
                form={() =>
                    <CrewMessageEditor
                        autoFocus={true}
                        customClass="editor"
                        setValue={value => setTextNote(value)}
                        messageId={note && note.id}
                        reply={note && note.notes && note.notes}
                    />
                }
            />
        )
    }

    const topIcons = () => {
        return (
            <>
                <FontAwesomeIcon icon={faEdit} onClick={() => {
                    if (!document.getElementById("editor")) {
                        setEditNote(true)
                        setTextNote(note.notes ? note.notes : " ");
                    }
                }} className="mr-3 pointer"/>
                <FontAwesomeIcon icon={faTrash} onClick={() => {
                    onDelete(note.id)
                }} className="pointer"/>
            </>
        )
    }

    const getTextWidth = (text, font) => {
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    const textWidth = (note) => getTextWidth(note, "15px Open Sans")
    const containerWidth = document.getElementById('note')?.clientWidth

    const isMultipleParagraph = () => {return note?.notes?.split('<p>').length > 2}

    const isShortNote = (note) => {return containerWidth > textWidth(note)}

    const RGBColor = color && color.includes('#') ? hexToRGB(color) : hexToRGB('#3A87AD')
    const initials = employeeName && employeeName.match(/\b(\w)/g).join('')

    const oneParagraphNote = note?.notes?.replace(/(<[^>]*>|&nbsp;)/g, '')

    const pageTheme = localStorage.getItem('reduxPersist:theme')
    if (pageTheme) {
        themeColor = themeBodyColor.filter(x => x.theme === JSON.parse(pageTheme).theme)[0]
    }

    return (
        note ?
            <TimelineElementWrapper
                color={color}
                employeeName={employeeName}
                employeeDisabled={employeeDisabled && employeeDisabled}
                createdAt={note && note.created_at}
                topElement={note && currentUserPersonId === note?.person_id && !note?.is_quickbooks_note && topIcons()}
                bottomElement={note && note.created_at !== note.updated_at && !note?.is_quickbooks_note && <i>(edited)</i>}
                isShowDetail={showNote}
                hideEvent={note?.person_id ? () => setShowNote(false) : null}
                employeePrimaryPhoto={employeePrimaryPhoto}
                qbNote={note?.is_quickbooks_note}
            >
                <Row>
                    <Col md={12} xs={12}>
                        <>
                            {editArea(note)}
                            <OverlayTrigger
                                placement="top"
                                overlay={showNote || (!isMultipleParagraph() && isShortNote(oneParagraphNote)) ? <></> : <Tooltip id="tooltip">Click to show more!</Tooltip>}
                            >
                                <p id="note" onClick={() => !isMultipleParagraph() && isShortNote(oneParagraphNote) ? {} : setShowNote(true)}
                                   className={`${showNote || (!isMultipleParagraph() && isShortNote(oneParagraphNote)) ? 'message-show' : 'message-hidden'} fontSize15 no-margin-bottom`}>
                                    {note?.is_quickbooks_note && <b className="font11">Last synchronized note from QuickBooks:</b>}
                                    {!editNote && <div dangerouslySetInnerHTML={{__html: note.notes}}/>}
                                </p>
                            </OverlayTrigger>
                        </>
                    </Col>
                </Row>
            </TimelineElementWrapper>
            :
            <Col md={12} xs={12} className={`add-new-note-wrapper ${editNote ? "pad5-0" : ""}`} style={themeColor && {background: `${themeColor.color}`}}>
                <div className={!editNote ? "add-new-note" : ""} onClick={() => {
                    if (!document.getElementById("editor") && customerId) {
                        setEditNote(true)
                        setTextNote(" ")
                    }
                }}>
                    <div className={`${editNote ? "container" : ""}`}>
                        {editNote && RGBColor && <div className='circle vhCenter' style={{borderColor: `${color}`, background: `rgba(${RGBColor.r}, ${RGBColor.g}, ${RGBColor.b}, 0.2)`}}>
                            <OverlayTrigger placement="right"
                                            overlay={<Tooltip
                                                id="tooltip">{employeeName && employeeName}</Tooltip>}>
                                <span>{initials}</span>
                            </OverlayTrigger>
                        </div>}
                        {editArea()}
                    </div>
                    {!editNote &&
                        <div className="container-wrapper-footer">
                            <span>+ Add New Note</span>
                        </div>
                    }
                </div>
            </Col>
    )
}

export default TimelineNote;