import axios from "axios"

import { addAlert } from "../App/actions"
import {
  EDITING_CONTACT_ID,
  FETCH_CONTACT,
  CREATE_NEW_CONTACT,
  SET_CUSTOMER_ID,
  UPDATE_CONTACT_ATTR,
  DELETE_CONTACT,
} from "./constants"
import { fetchContactTypesList } from "../../actions/contactTypes"
import { fetchBillingContact } from "../CustomerPage/CustomerInfo/actions"

const _fetchContact = () => async (dispatch, getState) => {
  const store = getState()
  const contactId = store.contactEditor.editingContactId
  if (contactId === 0) {
    console.info(
      "Contact Id was for new contact so not fetching contact from API"
    )
    return false
  }

  const config = {
    url: `/api/customer_contacts/${contactId}`,
    headers: { Authorization: store.auth.token },
  }

  try {
    const response = await axios.request(config)
    dispatch({ type: FETCH_CONTACT, contact: response.data })
  } catch (error) {
    dispatch(
      addAlert({
        message: "Could not fetch the contact " + error.toString(),
        mood: "danger",
        dismissAfter: 1500,
      })
    )
    throw error
  }
}

export const initializeEditor = customerId => (dispatch, getState) => {
  console.info("Initializing editor data w customerId", customerId)
  dispatch({ type: SET_CUSTOMER_ID, customerId })
  dispatch(_fetchContact()) // maybe check the id > 0 in case its a new one?
  dispatch(fetchContactTypesList())
  //assume the billing contact is already loaded in state
  //dispatch(fetchBillingContact(customerId)) //so we can tell the user who the billing contact is
}

export const editingContactId = editingContactId => ({
  type: EDITING_CONTACT_ID,
  editingContactId,
})

export const setCustomerId = customerId => ({
  type: SET_CUSTOMER_ID,
  customerId,
})
//not sure if i like this. perhaps we should pass in a prop instead of param to a 'internal' method?
export const createNewContact = customerId => ({
  type: CREATE_NEW_CONTACT,
  customerId,
})

export const updateContactAttr = (attr, newVal) => (dispatch, getState) => {
  const store = getState()
  const contact = { ...store.contactEditor.contact }
  contact[attr] = newVal
  contact.__dirty = true
  dispatch({ type: UPDATE_CONTACT_ATTR, contact })
}

const validateForm = contact => {
  const requiredFields = [
    "id" /*some of these are not always required by rails but always should be there for. logic purposes at least*/,
    "customer_id",
    "first_name",
    // "last_name",
    "contact_type_id",
  ]

  const invalidFields = requiredFields.some(prop => {
    if (
      contact[prop] === null ||
      contact[prop] === undefined ||
      contact[prop].toString().trim().length < 1
    ) {
      return true
    } else {
      return false
    }
  })

  if (invalidFields) {
    return false
  }

  if (contact.contact_type_id.toString() === "0") {
    return false
  }

  return true
}

export const saveContact = () => async (dispatch, getState) => {
  const store = getState()
  const contact = store.contactEditor.contact

  const isValid = validateForm(contact)
  console.log(isValid)

  if (!isValid) {
    dispatch(
      addAlert({
        message: "Some required fields are missing. Please check the form and try again.",
        mood: "danger",
        dismissAfter: 3500,
      })
    )
    return false
  }

  const isNew = contact.id === 0 ? true : false

  const config = {
    method: isNew ? "POST" : "PUT",
    url: isNew
      ? "/api/customer_contacts"
      : `/api/customer_contacts/${contact.id}`,
    headers: { Authorization: store.auth.token },
    data: { contact: { ...contact, __dirty: undefined } },
  }

  try {
    const _ = await axios.request(config)
    dispatch(
      addAlert({
        message: `${isNew ? "Created" : "Edited"} contact!`,
        mood: "success",
        dismissAfter: 1500,
      })
    )
    return true
  } catch (error) {
    dispatch(
      addAlert({
        message: `Could not ${isNew ? "CREATE" : "EDIT"} the contact ${error.toString()}`,
        mood: "danger",
        dismissAfter: 1500,
      })
    )
    throw error
  }
}

//DELETE Customer Contact
export const deleteContact = (contactId) => (dispatch, getState) => {

  if (contactId === 0){
    //delete it locally assuming it will be deleted on the server
    dispatch({type: DELETE_CONTACT, contactId})
    return true
  }

  const state = getState()
  const config={
    method: 'delete',
    url:  `/api/customer_contacts/${contactId}`,
    headers: {'Authorization': state.auth.token},
  }

  axios.request(config)
  .then(response => {
    dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
  })
  .catch((error, response) => {
    dispatch(addAlert({message: 'Did not delete Contact! It likely has sites or is the billing contact...', mood: 'danger', dismissAfter: 3500}))
  })
}
