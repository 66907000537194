export const getHazard = (hazard) => async dispatch => {
    dispatch({type: 'GET_HAZARD_INFO', hazard});
};

export const deleteHazard = (hazard) => async dispatch => {
    dispatch({type: 'DELETE_HAZARD', hazard});
}

export const clearDeletedHazard = () => async dispatch => {
    dispatch({type: 'DELETE_HAZARD', hazard: null});
}

export const clearGetHazard = () => async dispatch => {
    dispatch({type: 'GET_HAZARD_INFO', hazard: null});
}
