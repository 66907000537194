import React from "react";
import "../CustomerTimeline.scss"
import moment from "moment-timezone";
import {defaultDateTimeFormat} from "../../../../common/commonHandlers";

const TimelineAxisDate = ({date}) => {
    return <div className="timeline-axis-date">
        {moment(date).format(defaultDateTimeFormat)}
    </div>
}

export default TimelineAxisDate;