import React, {Component} from 'react';
import {FormControl, FormGroup, Modal} from "react-bootstrap";
import Select from "react-select";
import * as actions from './actions'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class NewRequestModal extends Component {

    state = {
        service: '',
        sites: [],
        reasons: [],
        contacts: [],
        resource: {
            site_id: this.props.request && this.props.request.site_id,
            reason_id: this.props.request && this.props.request.reason_id,
            notes: this.props.request && this.props.request.notes,
            contact_id: '',
            sitename: '',
            address: '',
            city: '',
            state: '',
            zip: ''
        },
        addNewSite: false
    }


    getSites = () => {
        this.props.actions.loadSites(this.props.token, res => {
            this.setState({sites: res.customer_sites, loading: false})
        })
    }


    getReasons = () => {
        this.props.actions.loadCustomerReasons(this.props.token, res => {
            this.setState({reasons: res.customer_reasons, loading: false})
        })
    }

    getContacts = () => {
        this.props.actions.loadContacts(this.props.token, res => {
            this.setState({contacts: res.customer_contacts, loading: false})
        })
    }

    componentWillMount = () => {
        if (this.props.token && this.props.showModal) {
            this.getSites()
            this.getReasons()
            this.getContacts()
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }

    mapForSelect(data, propName) {
        return data.map(x => {
            return {value: x.id, label: x[`${propName}`]}
        });
    }

    selectResourceAttr = (name) => e => {
        const {resource} = this.state
        if (!e) {
            this.setState({resource: {...resource, [name]: null}})
            return
        }
        const {value} = e
        this.setState({resource: {...resource, [name]: value}})
    };

    handleInput = (e) => {
        const {resource} = this.state
        const {value, name} = e.target

        if (name === 'zip' && value.length === 5) {
            this.props.actions.fetchStateAndCityForCustomer(value, (response) => {
                let {resource} = this.state;
                resource.zip = response.zipcode;
                resource.state = response.state ? response.state : resource.state;
                resource.city = response.city ? response.city : resource.city;
                this.setState(resource);
            })
        } else {
            this.setState({resource: {...this.state.resource, [name]: value}})
        }


    }

    addRequest = () => {
        this.props.actions.saveRequest(this.props.token, this.state.resource, this.state.addNewSite, () => {
            this.props.closeModal()
            this.props.reload()
        })
    }

    updateRequest = () => {
        this.props.actions.updateRequest(this.props.token, this.state.resource, this.props.request.id, () => {
            this.props.closeModal()
            this.props.reload()
        })
    }

    render() {
        const {showModal, closeModal, request} = this.props
        const {sites,  reasons, addNewSite, contacts} = this.state
        const {notes, site_id, sitename, address, city, zip, state} = this.state.resource
        const sitesOptions = this.mapForSelect(sites.filter(s => !s.site_moved), 'full_address');
        const contactOptions = this.mapForSelect(contacts, 'person');
        const reasonsOptions = this.mapForSelect(reasons, 'name');

        return (
            <Modal
                id='request-modal'
                animation={true}
                show={showModal}
                className={"heightAuto"}
                bsSize="normal"
            >
                <Modal.Header>
                    <Modal.Title>New Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <b>Site address*</b>
                        {!addNewSite ?
                            <>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        options={sitesOptions}
                                        onChange={this.selectResourceAttr('site_id')}
                                        placeholder={request && request.site ? request.site : "Site address"}
                                        isClearable
                                />
                            </> :
                            <>
                                <div className='new-address'>
                                    <div className='contact'>
                                        <b className='font12'>Contact*</b>
                                        <Select className="Select"
                                                classNamePrefix="select"
                                                options={contactOptions}
                                                onChange={this.selectResourceAttr('contact_id')}
                                                placeholder="Contact"
                                                isClearable
                                        />
                                    </div>
                                    <div className='sitename'>
                                        <b className='font12'>Sitename*</b>
                                        <FormControl
                                            placeholder="Sitename"
                                            value={sitename}
                                            name='sitename'
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div className='address'>
                                        <b className='font12'>Address*</b>
                                        <FormControl
                                            placeholder="Address"
                                            value={address}
                                            name='address'
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div>
                                        <b className='font12'>City*</b>
                                        <FormControl
                                            placeholder="City"
                                            value={city}
                                            name='city'
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div>
                                        <b className='font12'>State*</b>
                                        <FormControl
                                            placeholder="State"
                                            value={state}
                                            name='state'
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div>
                                        <b className='font12'>Zip*</b>
                                        <FormControl
                                            placeholder="Zip"
                                            value={zip}
                                            name='zip'
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        <p className='new-adr-btn mt-5'
                           onClick={() => this.setState({addNewSite: !addNewSite})}>
                            {!addNewSite ? 'Use a new address' : 'Use existing address'}
                        </p>
                    </FormGroup>
                    <FormGroup>
                        <b>Reason <span className='ml-8 text-gray'>(optional)</span></b>
                        <Select className="Select"
                                classNamePrefix="select"
                                options={reasonsOptions}
                                onChange={this.selectResourceAttr('reason_id')}
                                placeholder={request && request.reason ? request.reason : "Reason"}
                                isClearable
                        />
                    </FormGroup>
                    <FormGroup>
                        <b>Notes <span className='ml-8 text-gray'>(optional)</span></b>
                        <FormControl
                            rows={5}
                            componentClass="textarea"
                            placeholder="Service details"
                            value={notes}
                            name="notes"
                            onChange={this.handleInput}
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} type="button" className="btn btn-default"
                            data-dismiss="modal">Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                            if (request?.id) {
                                this.updateRequest()
                            } else {
                                this.addRequest()
                            }
                        }}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        )
            ;
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        proposal: state.customerView.proposal
    }
}


const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRequestModal)
