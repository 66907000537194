import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {FETCH_CHECKLIST_MODAL} from "../../constants";

export const checkEquipmentsChecklist = (reminder, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/equipment_answers/check_equipments_checklist`,
        params: {reminder}
    };

    apiCall("Checking Equipments Checklist", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getEquipmentsChecklist = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/equipment_answers/get_equipments_checklist`
    };

    apiCall("Getting Equipments Checklist", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const saveEquipmentsQuestions = (questions, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/equipment_answers/update_answers`,
        method: 'POST',
        params: {questions: JSON.stringify(questions)}
    };

    apiCall("Save Equipments Questions", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const fetchChecklistModal = (status) => (dispatch, getState) => {
    const store = getState()
    const config = {
        url: '/api/equipments',
        headers: {'Authorization': store.auth.token},
    };

    return axios.request(config)
        .then(response => {
            dispatch({type: FETCH_CHECKLIST_MODAL, status: status});
        });
};