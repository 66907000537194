import {
    FETCH_CUSTOMER_INFO,
    FETCH_SITES,
    FETCH_CONTACTS,
    UPDATE_SITE_FILTER_TEXT,
    UPDATE_CONTACT_FILTER_TEXT,
    FETCH_BILLING_CONTACT,
    UPDATE_CUSTOMER_ATTRIBUTE,
    UPDATE_BILLING_CONTACT_ATTR,
    TTC_BILLING_CONTACT_TYPE_ID,
    UPDATE_CUSTOMER_SEARCH,
    SELECT_CUSTOMER,
    FETCH_SEARCHED_CUSTOMERS,
    FETCH_STATE_AND_CITY,
    DELETE_CUSTOMER,
} from "./constants"

import axios from "axios"
import {addAlert} from "../../App/actions"

import {fetchCustomerTypesList} from "../../../actions/customerTypes"
import {fetchReferallsList} from "../../../actions/referalls"
import {fetchSalesArborists} from "../../../actions/salesArborists"
import {
    editingContactId,
    createNewContact,
} from "../../ContactEditorModal/actions"

import {
    editingSiteId,
    createNewSite,
    geocodeAddress,
} from "../../SiteEditorModal/actions"

import {browserHistory} from "react-router"
import {apiCall} from "../../../common/apiActionHandler";
import {mapForSelect} from "../../../common/commonHandlers";
import {dataUpload} from "../../../common/upload";

export {addAlert}
export {editingContactId, createNewContact}
export {editingSiteId, createNewSite}

export const fetchCustomerSalesData = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/customer_sales_data`,
        params: {customer_id: customerId}
    };

    apiCall("Getting customer sales data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const updateSiteFilterText = siteFilterText => ({
    type: UPDATE_SITE_FILTER_TEXT,
    siteFilterText,
})

export const updateContactFilterText = contactFilterText => ({
    type: UPDATE_CONTACT_FILTER_TEXT,
    contactFilterText,
})

export const updateSelectedCustomer = selectedCustomer => dispatch => {
    dispatch({type: SELECT_CUSTOMER, selectedCustomer})
    browserHistory.push(`/customer/info/${selectedCustomer.value}`) //will cause page to kick off api calls
}

export const fetchSites = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/sites/customer_list`,
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SITES, sites: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your sites because " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const fetchContacts = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/customer_contacts/rolodex",
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CONTACTS, contacts: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your contacts because " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const fetchStateAndCity = zipcode => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/customers/zipcode_search",
        headers: {Authorization: store.auth.token},
        params: {
            zipcode: zipcode,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_STATE_AND_CITY, location: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your state and city from zipcode because " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const fetchStateAndCityForCustomer = (zipcode, callback) => async (dispatch, getState) => {
    const config = {
        url: "/api/customers/zipcode_search",
        params: {
            zipcode: zipcode
        }
    };
    apiCall("Fetching state and city", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const getValidationErrors = () => (dispatch, getState) => {
    let validationErrors = []
    const store = getState()
    const customerToUpdate = store.customerInfo.customerInfo
    const isCustomerValid = validateCustomerForm(customerToUpdate)

    const billingContact = store.customerInfo.billingContact

    if (!isCustomerValid) {
        validationErrors.push(
            "Some required fields in the Billing Address area are missing."
        )
    }

    const isBillingValid = validateBillingForm(billingContact)

    if (!isBillingValid) {
        validationErrors.push("Some required Billing Contact fields are missing.")
    }

    dispatch({type: "BOGUS_VALIDATION_DISPATCH_BECAUSE_REDUX"})
    return validationErrors
}

export const fetchBillingContact = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/customer_contacts/billing_contact",
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_BILLING_CONTACT, billingContact: response.data})
        return response.data
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your billing contact because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

const _injectBlankBillingContact = customer_id => dispatch => {
    console.log("injecting billing contact")
    const billingContact = {
        id: 0,
        // contact_type_id: "",
        customer_id,
        first_name: "",
        last_name: "",
        person_phone: "",
        person_phone2: "",
        person_email: "",
        person_email2: "",
    }

    //we'll purposely leave out __dirty because we're goin to use the presence to detedct if an attempt was made to create one
    dispatch({type: FETCH_BILLING_CONTACT, billingContact})
}

export const fetchCustomerInfo = customerId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/customers/${customerId}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: response.data})
        dispatch(fetchCustomerTypesList())
        dispatch(fetchReferallsList())
        dispatch(fetchSalesArborists())
        dispatch(fetchSites(customerId))
        dispatch(fetchContacts(customerId))
        const billingContact = await dispatch(fetchBillingContact(customerId))
        // console.log("Billing contact is", billingContact)
        if (billingContact === null) {
            dispatch(_injectBlankBillingContact(customerId))
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Customer not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const createSiteFromCustomer = customerContactId => async (
    dispatch,
    getState
) => {
    const store = getState()
    const customerAddress = store.customerInfo.customerInfo

    let site = {
        customer_id: customerAddress.id,
        customer_contact_id: customerContactId,
        name: "Primary Address",
        full_address: "geocode me",
        latitude: "",
        longitude: "",
        address: customerAddress.address_1,
        city: customerAddress.city,
        state: customerAddress.state,
        zip: customerAddress.zip,
        country: customerAddress.country,
    }

    site.full_address = `${site.address}, ${site.city}, ${site.state}, ${site.zip}`

    try {
        const coords = await geocodeAddress(site.full_address, getState().auth.token)
        site.latitude = coords[0].geometry.location.lat
        site.longitude = coords[0].geometry.location.lng
    } catch (error) {
        dispatch(
            addAlert({
                message: "Unable to geocode the address. Please modify it and try again! " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 3500,
            })
        )
        throw error
        return false
    }

    const config = {
        method: "POST",
        url: "/api/sites",
        headers: {Authorization: store.auth.token},
        data: {site: site},
    }

    try {
        const _ = await axios.request(config)
        dispatch(
            addAlert({
                message: "Site Created",
                mood: "success",
                dismissAfter: 1500,
            })
        )
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not create a site ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const blankCustomerView = () => dispatch => {
    console.info("Creating new customer")
    const blankCustomer = {
        id: 0,
        show_company_name: false,
        name: "New Customer",
        create_site_from_customer: true,
        country: "United States",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        county: "",
        email_address: "",
        phone_number: "",
        web_url: "",
        fax_number: "",
        company_name: "",
        notes: "",
        customer_type_id: null,
        referral: null,
        employee_id: null,
    }
    dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: blankCustomer})
    dispatch(fetchReferallsList())
    dispatch(fetchSalesArborists())
    dispatch(_injectBlankBillingContact(0))
}

export const updateCustomerAttribute = (attribute, value) => (
    dispatch,
    getState
) => {
    const store = getState()
    let modifiedCustomer = Object.assign({}, store.customerInfo.customerInfo)
    modifiedCustomer[attribute] = value
    modifiedCustomer.__dirty = true

    dispatch({type: UPDATE_CUSTOMER_ATTRIBUTE, customerInfo: modifiedCustomer})
}

export const updateBillingContactAttr = (attr, newVal) => (
    dispatch,
    getState
) => {
    const store = getState()
    const billingContact = {...store.customerInfo.billingContact}
    billingContact[attr] = newVal
    billingContact.__dirty = true //this will signify later that we wanted to edit.
    dispatch({type: UPDATE_BILLING_CONTACT_ATTR, billingContact})
}

const validateCustomerForm = customer => {
    const requiredFields = [
        "id" /*some of these are not always required by rails but always should be there for. logic purposes at least*/,
        "show_company_name",
        // "name",
        "address_1",
        "city",
        "state",
        "zip",
        "country",
    ]

    const invalidFields = requiredFields.some(prop => {
        if (
            customer[prop] === null ||
            customer[prop] === undefined ||
            customer[prop].toString().trim().length < 1
        ) {
            return true
        } else {
            return false
        }
    })

    if (invalidFields) {
        return false
    }

    return true
}

const validateBillingForm = billingContact => {
    const requiredFields = [
        "id" /*some of these are not always required by rails but always should be there for. logic purposes at least*/,
        "customer_id",
        "first_name",
        "last_name",
        "person_phone",
        // "contact_type_id",
    ]

    const invalidFields = requiredFields.some(prop => {
        if (
            billingContact[prop] === null ||
            billingContact[prop] === undefined ||
            billingContact[prop].toString().trim().length < 1
        ) {
            return true
        } else {
            return false
        }
    })

    if (invalidFields) {
        return false
    }

    //hardcode the id that's type billing
    // if (
    //   billingContact.contact_type_id.toString() !==
    //   TTC_BILLING_CONTACT_TYPE_ID.toString()
    // ) {
    //   console.log("Billing contact type id didn't match. weird")
    //   return false
    // }

    return true
}

export const saveBillingContact = () => async (dispatch, getState) => {
    const store = getState()
    const billingContact = store.customerInfo.billingContact

    const isNew = billingContact.id === 0 ? true : false

    const config = {
        method: isNew ? "POST" : "PUT",
        url: isNew
            ? "/api/customer_contacts"
            : `/api/customer_contacts/${billingContact.id}`,
        headers: {Authorization: store.auth.token},
        data: {contact: {...billingContact, id: undefined, __dirty: undefined}},
    }

    try {
        const response = await axios.request(config)
        dispatch(
            addAlert({
                message: `${isNew ? "Created" : "Edited"} billing contact!`,
                mood: "success",
                dismissAfter: 1500,
            })
        )
        return response.data //for a post this is the customer contact id {id: }
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not ${isNew ? "CREATE" : "EDIT"} the billing contact ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

//DELETE Customer
export const deleteCustomer = (customerId) => (dispatch, getState) => {

    if (customerId === 0) {
        //delete it locally assuming it will be deleted on the server
        dispatch({type: DELETE_CUSTOMER, customerId})
        return true
    }

    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/customers/${customerId}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            browserHistory.push("/customer/info")
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Customer! It has Assets!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}

export const loadCustomerInfo = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api//customers/${customerId}`,
        method: 'GET'
    };

    apiCall("Loading customer", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveCustomer = (customer, callback) => async (dispatch, getState) => {
    customer.full_address = `${customer.address_1}, ${customer.city}, ${customer.state}, ${customer.zip}`
    try {
        const coords = await geocodeAddress(customer.full_address, getState().auth.token)
        customer.latitude = coords[0].geometry.location.lat
        customer.longitude = coords[0].geometry.location.lng
    } catch (error) {
        dispatch(
            addAlert({
                message: "Unable to geocode the address. Please modify it and try again! " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 3500,
            })
        )
        throw error
        return false
    }

    const config = {
        url: `/api/customers/${customer.id || ''}`,
        data: {customer},
        method: customer.id ? 'PUT' : 'POST'
    };

    apiCall("Saving customer", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/customer_types`},
        {url: `/api/referrals`, params: {disabled: false}},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            customerTypes: mapForSelect(all[1].data),
            referrals: mapForSelect(all[2].data),
        });
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const updateCustomer = (customer, direction, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_customer`,
        method: 'PUT',
        data: {customer: {...customer}, direction: direction}
    };

    apiCall("Updating customer", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const loadCustomerEvents = (customer_id, callback, filter) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/customer_events?customer_id=${customer_id}${filter ? "&&from=" + filter.from + "&&to=" + filter.to : ''}`,
    };

    apiCall("Loading customer events", config, async config => {
        axios.request(config).then(response => callback(response.data))
    }, dispatch, getState, false);
};

export const loadWorkOrdersInfo = (wo_ids, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/work_orders_info/`,
        params: {wo_ids}
    };

    apiCall("Loading work order", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const uploadAttachment = (acceptedFiles, id, callback) => (dispatch, getState) => {
    dataUpload(`/api/customer_files?customer_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteAttachment = (id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/customer_files/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}

export const reorderAttachment = (newImageOrder, customer_id, callback) => async (dispatch, getState) => {
        const config = {
            url: `/api/customers/${customer_id}/update_doc_order`,
            method: 'PUT',
            data: {new_doc_order: newImageOrder}
        };

        apiCall("Reordering attachments", config, async config => {
            const response = await axios.request(config);
            callback && callback(response.data);
        }, dispatch, getState);
    };

export const selectAttachmentForPrint = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customer_files/${id}/select_for_proposal`,
        method: 'PUT'
    };

    apiCall("Updating file", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const customerTimelineData = (customer_id, filters, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/customer_timeline_data`,
        params: {customer_id, filters}
    };

    apiCall("Loading Data", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const showDuplicates = (value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/search_duplicates`,
        params: {...value}
    };

    apiCall("Show Duplicates", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const sendCredentials = (ids, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/send_credentials`,
        method: 'POST',
        data: {ids: ids}
    };

    apiCall("Send Credentials", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};
export const mergeCustomer = (customer_id, merging_customer_id, callback) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/customers/merge_customer`,
        data: {customer_id, merging_customer_id}
    };

    apiCall("Show Duplicates", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};
export const getProposalsForInactive = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/get_proposals_for_inactive`,
        params: id
    };

    apiCall("Getting Proposals For Inactive", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};