export const [
  FETCH_CUSTOMER_INFO,
  FETCH_SITES,
  FETCH_CONTACTS,
  UPDATE_SITE_FILTER_TEXT,
  UPDATE_CONTACT_FILTER_TEXT,
  FETCH_BILLING_CONTACT,
  UPDATE_CUSTOMER_ATTRIBUTE,
  UPDATE_BILLING_CONTACT_ATTR,
  TTC_BILLING_CONTACT_TYPE_ID,
  UPDATE_CUSTOMER_SEARCH,
  SELECT_CUSTOMER,
  FETCH_SEARCHED_CUSTOMERS,
  FETCH_STATE_AND_CITY,
  DELETE_CUSTOMER,
] = [
  "FETCH_CUSTOMER_INFO",
  "FETCH_SITES",
  "FETCH_CONTACTS",
  "UPDATE_SITE_FILTER_TEXT",
  "UPDATE_CONTACT_FILTER_TEXT",
  "FETCH_BILLING_CONTACT",
  "UPDATE_CUSTOMER_ATTRIBUTE",
  "UPDATE_BILLING_CONTACT_ATTR",
  "TTC_BILLING_CONTACT_TYPE_ID",
  "UPDATE_CUSTOMER_SEARCH",
  "SELECT_CUSTOMER",
  "FETCH_SEARCHED_CUSTOMERS",
  "FETCH_STATE_AND_CITY",
  "DELETE_CUSTOMER",
]

export const boxesPerRowReorderingDND = {
  desktop: {
    columns: 4,
    minWidth: 1850
  },
  laptop: {
    columns: 3,
    minWidth: 1440
  },
  tablet: {
    columns: 2,
    minWidth: 1065
  },
    bigMobile: {
    columns: 1,
    minWidth: 768
  },
  mobile: {
    columns: 4,
    minWidth: 450
  },
  smallMobile: {
    columns: 3,
  }
}
export const boxesPerRowReorderingDNDTrucks = {
  desktop: {
    columns: 7,
    minWidth: 1850
  },
  laptop: {
    columns: 5,
    minWidth: 1440
  },
  tablet: {
    columns: 5,
    minWidth: 1065
  },
  bigMobile: {
    columns: 3,
    minWidth: 768
  },
  mobile: {
    columns: 3,
    minWidth: 450
  },
  smallMobile: {
    columns: 3,
  }
}
export const boxesPerRowReorderingDNDDocuments = {
  desktop: {
    columns: 4,
    minWidth: 1850
  },
  laptop: {
    columns: 4,
    minWidth: 1440
  },
  tablet: {
    columns: 4,
    minWidth: 1065
  },
  bigMobile: {
    columns: 5,
    minWidth: 768
  },
  mobile: {
    columns: 4,
    minWidth: 450
  },
  smallMobile: {
    columns: 3,
  }
}

export const boxesPerRowReorderingDNDCustomerInfo = {
  desktop: {
    columns: 6,
    minWidth: 1850
  },
  laptop: {
    columns: 5,
    minWidth: 1440
  },
  tablet: {
    columns: 3,
    minWidth: 1065
  },
  bigMobile: {
    columns: 3,
    minWidth: 991
  },
  mobile: {
    columns: 6,
    minWidth: 450
  },
  smallMobile: {
    columns: 4,
  }
}

//note customer search actions are duplicated in the app so other reducers will fire
