import {Col, Row} from "react-bootstrap";
import React from "react";

const isMobile = window.innerWidth < 768

const DefaultAnswerFormatter = ({options, preview}) => {
    return <Row className={`bottom15 ${isMobile ? '' : 'd-flex'}`}>
        <Col sm={6} xs={12}>
            <Row>
                <Col xs={12} className="text-center">Options</Col>
                <Col xs={12}>{options}</Col>
            </Row>
        </Col>
        {isMobile ?
            <Col xs={12}><hr/></Col>
            :
            <div style={{borderRight: '1px solid gray'}}/>
        }
        <Col sm={6} xs={12}>
            <Row>
                <Col xs={12} className="text-center">Preview</Col>
                <Col xs={12}>{preview}</Col>
            </Row>
        </Col>
    </Row>
}

export default DefaultAnswerFormatter;