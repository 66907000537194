import React from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import * as MyActions from "./ProposedServicesApi"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import Select from 'react-select'

import {
    Button,
    Col,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    OverlayTrigger,
    Popover,
    Row,
    Well,
} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"

import moment from "moment/moment"
import {defaultDateFormat, defaultDateFormatShort, select, saveNewCsvDownload} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";
import DateRangeFilter from "../../components/DateRangeFilter";

const Actions = {...MyActions, saveNewCsvDownload}

const statuses = [
    {value: 'proposed_now', label: 'Proposed Now'},
    {value: 'proposed_then', label: 'Proposed Then'},
    {value: 'accepted_now', label: 'Accepted Now'},
    {value: 'accepted_then', label: 'Accepted Then'},
    {value: 'in_work_order', label: 'In Work Order Now'},
    {value: 'scheduled', label: 'Scheduled Now'},
    {value: 'completed', label: 'Completed'},
    {value: 'declined', label: 'Declined'},
]

class ProposedServices extends ResourceComponent {
    state = {
        selectedService: [],
        selectedServiceType: [],
        resource: {
            status: "proposed_now"
        },
        myProposed: [],
        serviceTypes: []
    }

    tableRef = React.createRef()

    componentDidMount() {
        this.props.actions.load(response => this.setState(response))
    }

    handleRangeDateChange = (start, end, dateStart, dateEnd) => {
        let {resource} = this.state;
        resource[dateStart] = start;
        resource[dateEnd] = end;
        this.setState(resource);
    }

    render() {
        const {
            myProposed,
            resource,
            salesArborists,
            services,
            serviceTypes
        } = this.state

        const {
            salesArboristId,
            selectedService,
            proposalProposedStart,
            proposalProposedEnd,
            status,
            selectedServiceType
        } = this.state.resource

        return (
            <Grid fluid>
                <Helmet title="Proposed Services"/>
                <form onSubmit={this.fetchMyProposed}>
                    <Row>
                        <Col md={12}>
                            <h3 className="text-primary" style={{marginBottom: "0px", marginTop: "0px"}}>
                                {`Proposed Services ${myProposed ? `${myProposed.length} found.` : "Pick some dates!"} `}
                            </h3>
                            <FormGroup bsSize="sm">
                                <Row>
                                    <Col xs={8} md={8} className='mt-15'>
                                        <DateRangeFilter
                                            promisedStart={proposalProposedStart ? moment(proposalProposedStart) : ""}
                                            promisedEnd={proposalProposedEnd ? moment(proposalProposedEnd) : ""}
                                            upsertPromise={(id, start, end) => this.handleRangeDateChange(start, end, 'proposalProposedStart', 'proposalProposedEnd')}
                                            proposalServiceId={null}
                                            serviceNo={null}
                                            index={1}
                                            timeDelay={1}
                                        />
                                    </Col>
                                    <Col xs={4} className='mt-15'>
                                        <Button
                                            type="submit"
                                            bsStyle="success"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                width: '100%',
                                                maxWidth: '150px'
                                            }}
                                        >
                                            Find Services
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} md={2} className='mt-15'>
                                        <Select className="Select" classNamePrefix="select"
                                                name="sales-arborist-select"
                                                value={select(salesArborists, salesArboristId)}
                                                options={salesArborists}
                                                onChange={this.selectResourceAttr('salesArboristId')}
                                                placeholder="Sales Arborist"
                                                isClearable
                                        />
                                    </Col>
                                    <Col xs={6} md={2} className='mt-15'>
                                        <Select className="Select" classNamePrefix="select"
                                                placeholder="Service"
                                                isMulti
                                                simpleValue={true}
                                                value={select(services, selectedService)}
                                                onChange={this.selectResourceAttr('selectedService')}
                                                options={services}
                                        />
                                    </Col>
                                    <Col xs={6} md={2} className='mt-15'>
                                        <Select className="Select" classNamePrefix="select"
                                                placeholder="Service type"
                                                isMulti
                                                simpleValue={true}
                                                value={select(serviceTypes, selectedServiceType)}
                                                onChange={this.selectResourceAttr('selectedServiceType')}
                                                options={serviceTypes}
                                        />
                                    </Col>
                                    <Col xs={6} md={2} className='mt-15'>
                                        <Select className="Select" classNamePrefix="select"
                                                name="sales-arborist-select"
                                                value={select(statuses, status)}
                                                options={statuses}
                                                onChange={this.selectResourceAttr('status')}
                                                placeholder="Sales Arborist"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            {/*jank way of valigning button to bottom*/}
                            <FormGroup bsSize="sm">
                            </FormGroup>
                            {myProposed ?
                                <Well>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={12} className="text-info">
                                                    <Row>
                                                        {myProposed ? `Total Services: ${myProposed.length}` : null}
                                                        <br/>
                                                        {myProposed
                                                            ? <Dollars
                                                                amount={parseFloat(
                                                                    myProposed.reduce((sum, p) => sum + p.serviceTotal, 0).toFixed(2),
                                                                    10
                                                                )}
                                                            />
                                                            : null}
                                                        <br/>
                                                        {myProposed ? `Total Hours: ${myProposed.reduce((sum, p) => sum + p.serviceHours, 0).toFixed(2)}` : null}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Well>
                                :
                                null
                            }
                        </Col>

                    </Row>
                </form>
                <p>&nbsp;</p>

                <Row>
                    <Col md={12}>
                        {myProposed ? this._renderTable(myProposed) : null}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}/>
                </Row>

            </Grid>
        )
    }

    fetchMyProposed = e => {
        const {resource} = this.state;
        e.preventDefault()
        this.props.actions.fetchMyProposed(resource, response => this.setState({myProposed: response}))
    }

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _dollarFormatter = cell => <Dollars amount={cell}/>

    _csvDollarFormatter = cell => {
        return parseFloat(cell ? cell : 0).toFixed(2)
    }

    _renderTable = data => {
        const options = {
            page: 1,  //which page you want to show as default
            sizePerPageList: [5, 25, 50, 75, 100], //you can change the dropdown list for size per page
            sizePerPage: 50,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }
        return (
            <BootstrapTable
                ref={this.tableRef}
                data={data}
                striped={true}
                bordered={false}
                hover={true}
                exportCSV={true}
                pagination={true}
                search={true}
                csvFileName={() => {
                    const fileName = `proposals-${moment().format()}.csv`
                    if (this.tableRef?.current) {
                        this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "proposed services")
                    }
                    return fileName
                }}
            >
                <TableHeaderColumn
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataField="serviceName"
                    dataSort={true}
                >
                    Service
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataField="serviceType"
                    dataSort={true}
                >
                    Service Type
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="name"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataFormat={this._linkFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer Name
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="status"
                    dataSort={true}
                >
                    Status
                </TableHeaderColumn>

                <TableHeaderColumn
                    hidden
                    export
                    dataField="siteAddress"
                >
                </TableHeaderColumn>

                <TableHeaderColumn
                    hidden
                    export
                    dataField="siteCity"
                >
                </TableHeaderColumn>

                <TableHeaderColumn
                    hidden
                    export
                    dataField="siteZip"
                >
                </TableHeaderColumn>

                <TableHeaderColumn
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataField="any"
                    dataFormat={this._addressFormatter}
                    dataSort={true}
                    export={false}
                >
                    Site Address
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="contact"
                    dataFormat={this._contactFormatter}
                    csvFormat={this._csvContactFormatter}>
                    Contact
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="proposedDate"
                    dataFormat={this._stackDateFormater}
                    csvFormat={this._csvDateFormatter}
                    dataSort={true}>
                    Proposal Date
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="proposalNo"
                    dataFormat={this._proposalLinkFormatter}
                    isKey={true}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Proposal No
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="serviceTotal"
                    dataFormat={this._dollarFormatter}
                    csvFormat={this._csvDollarFormatter}
                    dataSort={true}>
                    Service Total
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="serviceHours"
                    dataSort={true}>
                    Hours
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="arborist"
                    dataSort={true}>
                    Arborist
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.name}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.id}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.id}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.id}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.id}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.id}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.id}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _addressFormatter(cell, row) {
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <strong>
                        {row.siteAddress}
                    </strong>
                </div>
                <div>
                    <small>
                        {row.siteCity}
                    </small>
                </div>
                <div>
                    <small>
                        {row.siteZip}
                    </small>
                </div>
            </div>
        );
    }

    _contactFormatter(cell, row) {
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <strong>
                        {row.phone}
                    </strong>
                </div>
                <div>
                    <small>
                        {row.email}
                    </small>
                </div>
            </div>
        );
    }

    _csvContactFormatter(cell, row) {
        return row.phone + ', '+row.email
    }

    _stackDateFormater(cell, row) {
        const popoverRight = (
            <Popover id="popover-positioned-top" title="Status Dates">
                <div style={{display: 'grid'}}>
                    <div>
                        <strong>
                            <small>
                                {row.proposedDate ?
                                    `Proposed: ${row && moment(row.proposedDate).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                    <div>
                        <strong>
                            <small>
                                {row.acceptedDate ?
                                    `Accepted: ${row && moment(row.acceptedDate).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                    <div>
                        <strong>
                            <small>
                                {row.events ?
                                    `Scheduled: ${row && row.events.map(e => moment(e).format(defaultDateFormatShort))}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                    <div>
                        <strong>
                            <small>
                                {row.declinedDate ?
                                    `Declined: ${row && moment(row.declinedDate).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                    <div>
                        <strong>
                            <small>
                                {row.completedDate ?
                                    `Completed: ${row && moment(row.completedDate).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                </div>
            </Popover>
        );
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverRight}>
                <h6
                    className="text-small text-info text-center"
                >
                    {`${row && moment(row.proposedDate).format(defaultDateFormatShort)}`}
                </h6>
            </OverlayTrigger>
        );
    }

    _csvDateFormatter = (cell) => {
        return moment(cell).format(defaultDateFormatShort)
    }

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposalId}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposalId}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _acceptFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'><Glyphicon glyph='ok'/></span>
        </Link>
    )

    _blankFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'></span>
        </Link>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposedServices)
