import React from "react"

import {bindActionCreators} from 'redux'

import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import * as actions from "./TrucksApi";
import './Truck.scss'
import {components} from "react-select";
import {reverseColor} from "../../common/commonHandlers";

const api = {
    ...actions,
};


class Option extends ResourceComponent {


    render() {
        return (
            <div>
                <components.Option {...this.props}>
                    <div className='option-select-list-item'>
                        <div className='option-name' style={{
                            backgroundColor: `${this.props.options.find(o => o.label === this.props.label).color}`,
                            color: `${reverseColor(this.props.options.find(o => o.label === this.props.label).color)}`
                        }}>
                            {this.props.label}
                        </div>
                        <div className='option-checkbox'>
                            <input
                                type="checkbox"
                                checked={this.props.options.find(o => o.label === this.props.label).show_on_calendar}
                                onChange={(e) => {
                                    let item = this.props.options.find(o => o.label === this.props.label)
                                    item.show_on_calendar = e.target.checked

                                    this.props.actions.updateOption('truck_service_status', item.id, item)
                                }}
                            />{" "}<span>Show on calendar</span></div>
                    </div>
                </components.Option>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    roles: state.auth.roles
});
const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(api, dispatch),
    }

);

export default connect(mapStateToProps, mapDispatchToProps)(Option)
