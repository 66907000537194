import axios from 'axios'
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../../common/upload";

export const load = (callback) => (dispatch, getState) => {
  const config = [
    {url: `/api/sales_cross_view/service_types`}
  ];

  apiCall("Loading data", config, async config => {
    let actions = config.map(c => axios.request(c));
    const all = await axios.all(actions);

    const data = {
      service_types: mapForSelect(all[0].data)
    };
    callback(data);
  }, dispatch, getState, false);
};

export const fetchOpenProposals = (filter, callback) => async (dispatch, getState) => {
  const config = {
    url: `/api/approvals/approved_and_open`,
    params: filter
  };

  apiCall("Getting open proposals", config, async config => {
    const response = await axios.request(config);
    callback(response.data);
  }, dispatch, getState);
};

export const downloadCsv = (params) => async (dispatch, getState) => {
  const config = {
    url: `/api/approvals/csv`,
    params
  };

  apiCall("Downloading csv", config, async config => {
    const response = await axios.request(config);
    const blob = new Blob([response.data], {
      type: 'text/csv',
    });
    FileSaver.saveAs(blob, `approvals-${moment().format()}.csv`);

    blob.name = `approvals-${moment().format()}.csv`
    dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(params ? params : {}), source: "open_proposals"}, false)(dispatch, getState)
  }, dispatch, getState);
};
