import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import EmailEditorPreview from "../../containers/ClientsPage/tabs/EmailEditorPreview";
import {MaterialSwitch} from "../MaterialSwitch/MaterialSwitch";
import JoditEditor from "jodit-react";
import {JODIT_BUTTONS} from "../../constants";
import {Jodit} from "jodit";
import ResourceComponent from "../ResourceComponent";

export default class EmailDraftModal extends ResourceComponent {
    state = {
        resource: {},
    }

    constructor(props) {
        super(props);
        this.state = {
            resource: {
                name: '',
                subject: '',
                message: ''
            },
            isPreviewModalOpened: false,
            advancedView: false,
            config: this.basicConfig,
            showHtmlModal: false
        }
    }

    basicConfig = {
        readonly: false,
        toolbarInline: true,
        popup: {
            cells: [...JODIT_BUTTONS.basic.popupButtons],
        },
        height: 500,
        enter: 'div',
        extraButtons: [{
            name: 'preview',
            exec: () => this.setState({isPreviewModalOpened: true})
        }],
        colorPickerDefaultTab: "color",
        extraPlugins: ['tooltip'],
        showTooltipDelay: 0,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_only_text",
        ...JODIT_BUTTONS.basic
    }

    updateJoditConfig = () => {
        const {advancedView} = this.state;
        const advancedConfig = {
            readonly: false,
            enter: 'div',
            extraButtons: [{
                name: 'preview',
                exec: () => this.setState({isPreviewModalOpened: true})
            }],
            popup: {
                cells: [
                    ...JODIT_BUTTONS.advanced.popupButtons,
                    Jodit.defaultOptions.popup.cells[6],
                    Jodit.defaultOptions.popup.cells[7],
                    Jodit.defaultOptions.popup.cells[8]
                ]
            },
            height: 500,
            colorPickerDefaultTab: "color",
            extraPlugins: ['tooltip'],
            showTooltipDelay: 0,
            askBeforePasteHTML: this.state.showHtmlModal,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_only_text",
        };

        this.setState({
            config: advancedView ? advancedConfig : this.basicConfig,
        });
    }

    componentDidMount() {
        this.setState({
            resource: {
                ...this.state.resource,
                name: this.props.emailTemplate.name,
                subject: this.props.emailTemplate.subject,
                message: this.props.emailTemplate.message
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.advancedView !== this.state.advancedView || prevState.showHtmlModal !== this.state.showHtmlModal) {
            this.updateJoditConfig();
        }
    }

    onEditorStateChange = (editorState) => {
        let {resource} = this.state;
        resource.message = editorState;
        this.setState(resource);
    }

    setInnerHTML = (text) => {
        return <div dangerouslySetInnerHTML={{__html: text}}/>
    }

    render() {

        const {advancedView, resource, isPreviewModalOpened, showHtmlModal, config} = this.state;
        return (
            <Modal
                id='consumption-modal'
                animation={true}
                show={this.props.showModal}
                className={"heightAuto"}
                bsSize="normal"
            >
                <Modal.Header>
                    <Modal.Title>Duplicate email template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} className="marginBottom8">
                            <ControlLabel>
                                Name
                            </ControlLabel>
                            <FormControl
                                placeholder="Name"
                                value={resource.name}
                                name="name"
                                onChange={this.updateResourceAttr}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="marginBottom8">
                            <ControlLabel>
                                Subject
                            </ControlLabel>
                            <FormControl
                                placeholder="Subject"
                                name="subject"
                                onChange={this.updateResourceAttr}
                                value={resource.subject}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <EmailEditorPreview
                            title="Preview mode"
                            show={isPreviewModalOpened}
                            onHide={() => this.setState({isPreviewModalOpened: !isPreviewModalOpened})}
                            closeButton={() => this.setState({isPreviewModalOpened: !isPreviewModalOpened})}
                        >
                            <p className="line_height15">{this.setInnerHTML(resource.message)}</p>
                        </EmailEditorPreview>
                        <Col md={12}>
                            <FormGroup>
                                <div className="space-between">
                                    <ControlLabel>
                                        Message
                                    </ControlLabel>
                                    <div className="d-flex">
                                        {advancedView && <MaterialSwitch
                                            style={{marginRight: 20}}
                                            value={showHtmlModal}
                                            onChange={() => this.setState({showHtmlModal: !showHtmlModal})}
                                            label="Show HTML Modal"
                                        />}
                                        <MaterialSwitch
                                            value={advancedView}
                                            onChange={() => this.setState({advancedView: !advancedView})}
                                            label="Advanced view "
                                        />
                                    </div>
                                </div>
                                <JoditEditor
                                    editorRef={this.setRef}
                                    value={resource.message}
                                    config={config}
                                    onChange={(newValue) => this.onEditorStateChange(newValue)}
                                    onBlur={(newValue) => this.onEditorStateChange(newValue)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.closeModal} type="button" className="btn btn-default"
                            data-dismiss="modal">Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                            this.props.duplicateTemplate(this.state.resource)
                        }}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        )
            ;
    }
}