import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";

export const searchForProposal = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/search`,
        params: {q: searchText}
    };

    apiCall("Searching for proposals", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};


export const removeProposal = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/remove_proposal`,
        method: 'DELETE',
        data: resource
    };

    apiCall("Proposal has been deleted", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};