import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import * as Actions from "./actions"
import {Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Modal, Row,} from "react-bootstrap"
import Select from "react-select"
import PropTypes from "prop-types"
import {select} from "../../common/commonHandlers";
import './SiteEditorModal.css'
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDND} from './constants'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InfoModal from "../../common/InfoModal";
import MovedSiteModal from "./MovedSiteModal";


class SiteEditorModal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
    }

    state = {
        movedSiteContacts: [],
        movedSiteCustomer: null,
        ordinances: [],
        addMoreSiteContacts: false,
        infoModalOpen: false,
        showMovedSiteModal: false,
        movedSiteModalData: []
    }

    componentDidMount() {
        console.info("Site Editor mounted, initializing")
        if (this.props.editingSiteId !== null) {
            this.props.actions.initializeEditor()
        }
    }

    componentWillReceiveProps(nextProps) {
        console.info("Site Editor Receiving Props but not sure if reinitializing")
        if (this.props.editingSiteId !== nextProps.editingSiteId) {
            console.info("Site Editor Props changed, reinitializing")
            this.props.actions.initializeEditor()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.site && prevProps.site !== this.props.site) {
            this.props.actions.fetchOrdinances(this.props.site.zip, (res) => {
                this.setState({ordinances: res})
            })
        }
    }

    deleteSite = (site_id) => {
        const action_confirmed = window.confirm('Do you REALLY want to delete this site???')

        if (action_confirmed) {
            this.props.actions.deleteSite(site_id)
            // this.props.actions.editingSiteId(site_id)
            this._closeModal();
        }
    }

    uploadAttachment = (attachment) => {
        const {site} = this.props;
        this.props.actions.uploadAttachment(attachment, site.id, newFile => {
            this.props.actions.initializeEditor()
        })
    };

    deleteAttachment = (id) => {
        this.props.actions.deleteAttachment(id, () => {
            this.props.actions.initializeEditor()
        })
    }

    reorderAttachments = (orderedFiles) => {
        const order = JSON.stringify(orderedFiles.map((image) => image.id))
        this.props.actions.reorderAttachment(order, this.props.site.id, () => {
            this.props.actions.initializeEditor()
        })
    };

    selectAttachment = (id) => {
        this.props.actions.selectAttachmentForPrint(id, () => {
            this.props.actions.initializeEditor()
        })
    }
    isValidData = () => {
        const {site} = this.props
        if (site?.customer_contact_id && site?.name && site?.address && site?.city && site?.state && site?.zip) {
            return true
        } else {
            return false
        }
    }

    render() {
        const {editingSiteId, site, customerContactsList, foundCustomersModal, roles} = this.props
        const {movedSiteContacts, movedSiteCustomer, addMoreSiteContacts} = this.state;
        const options = customerContactsList?.map(c => ({
            value: c.id,
            label: c.person_name,
        })) || [];
        const customerList = foundCustomersModal ? foundCustomersModal.map(customer => ({
            value: customer.id,
            label: customer.name,
        })) : [];

        const movedSiteContactOptions = movedSiteContacts?.map(c => ({
            value: c.id,
            label: c.person_name,
        })) || [];


        let secondaryContactOptions = []
        if (site?.customer_contact_id) {
            secondaryContactOptions = options.filter(x => (x.value !== site?.customer_contact_id && !site?.additional_customer_contact_ids?.includes(x.value)))
        }

        let additionalContactOptions = []
        if (site?.customer_contact_id) {
            additionalContactOptions = options.filter(x => x.value !== site?.customer_contact_id && x.value !== site?.secondary_customer_contact_id)
        }

        const isAdditionalContacts = site?.additional_customer_contact_ids?.length > 0 || addMoreSiteContacts

        return (
            <>
            <Modal
                className='site_editor_modal'
                bsSize="large"
                animation={false}
                show={true}
                onHide={this._closeModal}
            >
                <form onSubmit={this._saveSite}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {editingSiteId > 0 ? "Editing" : "New"} Site
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt10 align-text-end'>
                            <Col xs={isAdditionalContacts ? 6 : 5} sm={6}>
                                <ControlLabel>
                                    Primary Contact*
                                </ControlLabel>
                                <Select className="Select" classNamePrefix="select"
                                        name="customer_contact_id"
                                        onChange={e => this._updateCustomerContact(e, 'primary')}
                                        placeholder="Contact"
                                        clearable={false}
                                        value={
                                            site && select(options, site.customer_contact_id)
                                        }
                                        options={options}
                                />
                            </Col>
                            <Col xs={isAdditionalContacts ? 6 : 5}>
                                <ControlLabel>
                                    Secondary Contact
                                </ControlLabel>
                                <Select className="Select" classNamePrefix="select"
                                        name="secondary_customer_contact_id"
                                        onChange={e => this._updateCustomerContact(e, 'secondary')}
                                        placeholder="Second Contact"
                                        isClearable
                                        value={
                                            site && select(options, site.secondary_customer_contact_id)
                                        }
                                        options={secondaryContactOptions}
                                />
                            </Col>
                            {!isAdditionalContacts && <Col xs={1} className='no-padding'>
                                <Button
                                    bsStyle="success"
                                    onClick={() => this.setState({addMoreSiteContacts: true})}
                                >
                                    <FontAwesomeIcon icon="plus"/>
                                </Button>
                            </Col>}
                        </Row>
                        {isAdditionalContacts &&
                            <Row>
                                <Col xs={12}>
                                    <ControlLabel>
                                        Additional Contacts
                                    </ControlLabel>
                                    <Select className="Select" classNamePrefix="select"
                                            name="additional_customer_contact_ids"
                                            onChange={e => this._updateCustomerContact(e, 'additional')}
                                            placeholder="Additional Contacts"
                                            isMulti
                                            isClearable
                                            value={
                                                site && select(additionalContactOptions, site.additional_customer_contact_ids)
                                            }
                                            options={additionalContactOptions}
                                    />
                                </Col>
                            </Row>}
                        <Row className='no-margin'>
                            <Col className='mt-15'>
                                <FormGroup bsSize="small">
                                    <ControlLabel>
                                        Site Name*
                                    </ControlLabel>
                                    <FormControl
                                        placeholder="Site Name"
                                        name="name"
                                        onChange={this._updateSiteAttr}
                                        value={site ? site.name : ""}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs={8}>
                                <FormGroup>
                                    <ControlLabel>
                                        Full Address
                                    </ControlLabel>
                                    <FormControl
                                        disabled
                                        placeholder="Full Address"
                                        name="full_address"
                                        onChange={this._updateSiteAttr}
                                        value={site ? site.full_address : ""}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <ControlLabel>
                                        Ordinance
                                    </ControlLabel>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(this.state.ordinances, site?.ordinance_id)}
                                            options={this.state.ordinances}
                                            onChange={this._updateSiteOrdinance}
                                            placeholder={this.state.ordinances.length ? "Select ordinance" : "No matching ordinances"}
                                            isClearable
                                            isDisabled={!this.state.ordinances.length}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6}>

                                <FormGroup bsSize="small">
                                    <ControlLabel>
                                        Address*
                                    </ControlLabel>
                                    <FormControl
                                        placeholder="Address"
                                        name="address"
                                        onChange={this._updateSiteAttr}
                                        value={site ? site.address : ""}
                                    />
                                </FormGroup>

                            </Col>

                            <Col xs={2}>

                                <FormGroup bsSize="small">
                                    <ControlLabel>
                                        City*
                                    </ControlLabel>
                                    <FormControl
                                        placeholder="city"
                                        name="city"
                                        onChange={this._updateSiteAttr}
                                        value={site ? site.city : ""}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={2}>

                                <FormGroup bsSize="small">
                                    <ControlLabel>
                                        State*
                                    </ControlLabel>
                                    <FormControl
                                        placeholder="State"
                                        name="state"
                                        onChange={this._updateSiteAttr}
                                        value={site ? site.state : ""}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={2}>

                                <FormGroup bsSize="small">
                                    <ControlLabel>
                                        Zip*
                                    </ControlLabel>
                                    <FormControl
                                        placeholder="Zip"
                                        name="zip"
                                        onChange={this._updateSiteAttr}
                                        value={site ? site.zip : ""}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {editingSiteId > 0 && (roles.includes("admin") || roles.includes("scheduler")) &&
                            <div className='site-moving-section'>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <p>Move site to another customer:</p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <Select
                                            options={customerList}
                                            isClearable
                                            name='customer_id'
                                            onInputChange={this.props.actions.updateCustomerSearch}
                                            onChange={e => this.updateMovingSite(e, 'customer_id')}
                                            placeholder="Customer Name"
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt10'>
                                    <Col xs={12} md={4}>
                                        <p>Select site contact:</p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <Select className="Select" classNamePrefix="select"
                                                name="moved_site_contact_id"
                                                isDisabled={!movedSiteCustomer}
                                                onChange={e => this.updateMovingSite(e, 'moved_site_contact_id')}
                                                placeholder="Moved Site Contact"
                                                value={site && select(movedSiteContactOptions, site.moved_site_contact_id)}
                                                options={movedSiteContactOptions}
                                        />
                                    </Col>
                                </Row>
                            </div>}
                        <Row>
                            <Col md={12}>
                                <FormGroup bsSize="small">
                                    <ControlLabel>
                                        Site Note
                                    </ControlLabel>
                                    <FormControl
                                        componentClass="textarea"
                                        className='site_note_textarea'
                                        rows={5}
                                        type="text"
                                        name="notes"
                                        placeholder="Site Note"
                                        onChange={this._updateSiteAttr}
                                        value={site ? site.notes : ""}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="site-moving-section">
                            <Row>
                                <Col xs={12} className="text-left">Attachments</Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {site && site.id ?
                                        <div className="dropzone_with_thumbnails">
                                            <Dropzone onDrop={this.uploadAttachment}
                                                      className="dropzone pointer border_none">
                                                <p>Drop a file here or click to select a file to upload.</p>
                                            </Dropzone>
                                            <ReorderingDragAndDrop files={site.files.sort((a, b) => a.order - b.order)}
                                                                   deleteAttachment={this.deleteAttachment}
                                                                   reorderAttachments={this.reorderAttachments}
                                                                   numberOfColumns={boxesPerRowReorderingDND}
                                                                   selectAttachment={this.selectAttachment}/>
                                        </div>
                                        :
                                        <p className='text-left'>Create site first!</p>
                                    }
                                </Col>
                            </Row>
                        </div>
                        {this.state.showMovedSiteModal &&
                            <MovedSiteModal
                                onSave={async () => {
                                    const success = await this.props.actions.saveSite()
                                    if (success) {
                                        this.props.onSuccess()
                                        this.props.actions.editingSiteId(null)
                                    }
                                }}
                                onClose={() => this.setState({showMovedSiteModal: false})}
                                data={this.state.movedSiteModalData}
                                separateProposalServices={this.props.site?.separateProposalServices}
                                onChangeSeparateProposalServices={() => this.props.actions.updateSiteAttr('separateProposalServices', !this.props.site?.separateProposalServices)}
                            />
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Row>
                            <Col className="text-left" xs={3}>
                                <Button
                                    bsStyle="danger"
                                    bsSize="xs"
                                    onClick={() => this.deleteSite(editingSiteId)}
                                    disabled={!(site && site.asset_id)}
                                >
                                    Delete
                                </Button>

                                <Button
                                    bsSize="xs"
                                    bsStyle="warning"
                                    onClick={this._closeModal}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className="space-between" xs={6}>
                                <Checkbox
                                    name="inactive"
                                    checked={site?.inactive}
                                    onChange={this._updateSiteAttr}
                                    inline
                                >
                                    {" "} Inactive
                                </Checkbox>
                                <p>
                                    {" "} Recent
                                    Arborist: {site && site.last_site_arborist !== null ? site.last_site_arborist : "Sorry - not, sure"}
                                </p>
                            </Col>
                            <Col className="text-right" xs={3}>
                                <Button
                                    bsSize="xs"
                                    bsStyle="success"
                                    disabled={(site && !site.__dirty) || (movedSiteCustomer && !site.moved_site_contact_id)}
                                    type="submit"
                                >
                                    Save Site
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </form>
            </Modal>
                {this.state.infoModalOpen && site?.inactive &&
                    <InfoModal
                        isOpen={true}
                        closeModal={() => this.setState({infoModalOpen: false})}
                        title={'Warning'}
                        getProposals={this.props.getProposalsForInactive}
                        searchId={{site_id: this.props.site?.id}}
                        text={<p>If you save a Site with the Inactive option checked, all Open Proposals assigned to that site will be marked as Declined.</p>}
                    />}
            </>
        )
    }

    _updateCustomerContact = (selected, type) => {
        let contact = {}

        if (type === 'primary') {
            contact = {
                target: {
                    name: "customer_contact_id",
                    value: selected?.value,
                },
            }
        } else if (type === 'secondary') {
            contact = {
                target: {
                    name: "secondary_customer_contact_id",
                    value: selected?.value,
                },
            }
        } else if (type === 'additional') {
            contact = {
                target: {
                    name: "additional_customer_contact_ids",
                    value: selected.map(x => x.value),
                },
            }
        }

        this._updateSiteAttr(contact)
    }
    _updateSiteOrdinance = (selected) => {
        let ordinance = {
            target: {
                name: "ordinance_id",
                value: selected?.value,
            },
        }
        this._updateSiteAttr(ordinance)
    }

    updateMovingSite = (selected, key) => {
        const {site, current_customer_id} = this.props

        if (selected) {
            const e = {
                target: {
                    name: key,
                    value: selected.value,
                },
            }

            if (key === 'customer_id') {
                this.setState({movedSiteCustomer: selected.value}, () => {
                    this.props.actions.fetchContacts(selected.value, contacts => {
                        this.setState({movedSiteContacts: contacts})
                    })
                })
            }

            this._updateSiteAttr(e)
        } else {
            if (key === 'customer_id') {
                this.setState({movedSiteContacts: [], movedSiteCustomer: null}, () => {
                    site.customer_id = current_customer_id
                    this.props.actions.updateSiteAttr('moved_site_contact_id', null)
                })
            }
        }
    }

    _updateSiteAttr = (e) => {
        const attr = e.target.name
        let val = e.target.value
        if (e.target.type === 'checkbox'){
            val = e.target.checked
        }
        if (attr === 'inactive' && e.target.checked) {
            this.setState({infoModalOpen: true})
        }
        if (attr === 'zip') {
            val = val.trim()
        }
        this.props.actions.updateSiteAttr(attr, val)

        if (attr === 'zip' && val.length === 5) {
            this.props.actions.fetchStateAndCity(val)
            this.props.actions.fetchOrdinances(val, (res) => {
                if(res.length < 1){
                    this._updateSiteOrdinance({value: null})
                }
                this.setState({ordinances: res})
            })
        }
    }

    _saveSite = async e => {
        e.preventDefault()
        //the fields are updated so just send the post/put to rails
        if(this.isValidData()){
            if (this.props.site?.moved_site_contact_id) {
                this.props.actions.getMovedProposals(this.props.site?.id, this.props.site?.customer_id, res => {
                    this.setState({showMovedSiteModal: true, movedSiteModalData: res})
                })
            } else {
                const success = await this.props.actions.saveSite()
                if (success) {
                    this.props.onSuccess()
                    this.props.actions.editingSiteId(null)
                }
            }
        }
    }

    _closeModal = async e => {
        this.props.actions.editingSiteId(null)
        this.props.onSuccess()
    }
}

//   _closeModal = () => {
//     this.props.actions.editingSiteId(null)
//     this.props.actions._saveSite(null)
//   }
// }

const mapStateToProps = state => {
    return {
        editingSiteId: state.siteEditor.editingSiteId,
        site: state.siteEditor.site
            ? state.siteEditor.site[`site_${state.siteEditor.editingSiteId}`]
            : null,
        customerContactsList: state.customerInfo.contacts,
        foundCustomersModal: state.siteEditor.foundCustomersModal,
        roles: state.auth.roles,
        current_customer_id: state.customerInfo.customerInfo.id
    }
}

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteEditorModal)
