import React from "react";
import PropTypes from "prop-types";
import Attachment from "../../image/Attachment";
import PdfAttachment from "../../pdf/PdfAttachment";
import {DocAttachment} from "../../docs/DocAttachment";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actions from "./AttachmentThumbnailApi";
import {getClient, getRecipients, getTemplate, renderEmail} from "../../email/EmailEditorApi";

const DocAttachmentName = (file, downloadButton) => {
    return <a href='#' title="Open in a new window">
        <span className="attachment_name" onClick={() => downloadButton && downloadResource(file.original, file.name)}>
            {file?.created_at}
            {file?.name?.substring(0, 20)}
            {file?.name?.length > 20 && '...'}
        </span>
    </a>
}

function forceDownload(blob, filename) {
    let a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}

const AttachmentThumbnail = ({actions, file, deleteAttachment, selectAttachment, allowRemove = true, downloadButton = false}) => {

    const AttachmentName = (file) => {
        return <a href={file.original} target="_blank" title="Open in a new window">
        <span
            className="attachment_name"
            target="_blank"
            onClick={() => {
                const fileUrl = file.original;

                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        let filename = fileUrl.split('\\').pop().split('/').pop();
                        filename = filename.replace(/\.heic/ig, '.jpeg')
                        actions.saveDownloadedFile(blob, filename, {name: file?.name})
                    })
                    .catch(error => {
                        console.error('Error fetching the blob:', error);
                    });
            }}
        >
            {file?.created_at}
            {file?.name?.substring(0, 20)}
            {file?.name?.length > 20 && '...'}
        </span>
        </a>
    }

    // Current blob size limit is around 500MB for browsers
    function downloadResource(url, filename) {
        if (!filename) filename = url.split('\\').pop().split('/').pop();
        filename = filename.replace(/\.heic/ig, '.jpeg')
        fetch(url, {
            headers: new Headers({
                'Origin': window.location.origin
            }),
            mode: 'cors'
        })
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                forceDownload(blobUrl, filename);
                actions.saveDownloadedFile(blob, filename, {name: file?.name})
            })
            .catch(e => console.error(e));
    }

    // jpg, jpeg, png, gif
    if (/.jpg|.jpeg|.png|.heic|.gif/.test(file.name.toLowerCase())) {
        return (<div className='attachment'>
            {selectAttachment && <input type='checkbox' className='attachment_checkbox' checked={file.selected_for_proposal}
                    onChange={() => selectAttachment(file.id)}/>}
            <Attachment key={file.id} image={file} disableNBSP={true} disableRedirect={true}
                        deleteImage={() => deleteAttachment(file.id)}
                        downloadResource={downloadResource}
                        downloadButton={downloadButton}
                        allowRemove={allowRemove}
            />
            {AttachmentName(file)}
        </div>)
        //pdf
    } else if (file.name.toLowerCase().includes(".pdf")) {
        return (<div className='attachment'>
            <PdfAttachment key={file.id} pdf={file}
                           disableRedirect={true}
                           deletePdf={() => deleteAttachment(file.id)}
                           downloadResource={downloadResource}
                           downloadButton={downloadButton}
                           allowRemove={allowRemove}
            />
            {AttachmentName(file)}
        </div>)
        //others
    } else {
        return (<div className='attachment'>
            <DocAttachment key={file.id}
                           file={file}
                           disableRedirect={true}
                           deleteDoc={() => deleteAttachment(file.id)}
                           allowRemove={allowRemove}
                           downloadResource={downloadResource}
                           downloadButton={downloadButton}
            />
            {DocAttachmentName(file, downloadButton)}
        </div>)
    }
}

AttachmentThumbnail.propTypes = {
    file: PropTypes.object.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
    selectAttachment: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(AttachmentThumbnail);