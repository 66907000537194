import {
  FETCH_CUSTOMER_SITE_MAPS,
  CREATE_CUSTOMER_SITE_MAP,
  UPDATE_CUSTOMER_SITE_MAP,
  DELETE_CUSTOMER_SITE_MAP,
} from "../constants"

const initialState = {
  // customer site maps is hashkey (siteId) based hashmap
  siteIds: [],
  siteMaps: {},
}

export default function reducer(state = initialState, action) {
  // action
  // type: action Type
  // siteId: associated site ID
  // siteMap: associated siteMap(s)
  switch (action.type) {
    case FETCH_CUSTOMER_SITE_MAPS:
      return { 
        ...state, 
        siteIds: [...new Set([...state.siteIds, action.siteId])],
        siteMaps: {
          ...state.siteMaps,
          [action.siteId]: action.siteMaps,
        }
      }
    case CREATE_CUSTOMER_SITE_MAP:
      // return { ...state, customerSiteMaps: [...state.customerSiteMaps, action.siteMap]}
      // we asssume we already have siteId in state
      const index = state.siteIds.indexOf(action.siteId)
      if (index > - 1) {
        return { 
          ...state, 
          siteIds: [...new Set([...state.siteIds, action.siteId])],
          siteMaps: {
            ...state.siteMaps,
            [action.siteId]: [...state.siteMaps[action.siteId], action.siteMap],
          }
        }
      } else {
        return { 
          ...state, 
          siteIds: [...new Set([...state.siteIds, action.siteId])],
          siteMaps: {
            ...state.siteMaps,
            [action.siteId]: [action.siteMap],
          }
        }
      }
    case UPDATE_CUSTOMER_SITE_MAP:
      return {
        ...state,
        siteMaps: {
          ...state.siteMaps,
          [action.siteId]: state.siteMaps[action.siteId].map(siteMap => siteMap.id !== action.siteMap.id ? siteMap : action.siteMap),
        }
      }
    case DELETE_CUSTOMER_SITE_MAP:
      return {
        ...state,
        siteMaps: {
          ...state.siteMaps,
          [action.siteId]: state.siteMaps[action.siteId].filter(siteMap => siteMap.id !== action.siteMap.id),
        }
      }
    default:
      return state
  }
}
