import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from './PermissionControllerApi'
import {setRedirectRequired} from "./PermissionControllerApi";
import {ROLES} from "../../constants";

const PermissionController = ({children, auth, actions}) => {
    useEffect(() => {
        if (auth.user_id) {
            actions.loadRouterInfo(auth.user_id, res => {
                actions.setUserTermsStatus(res.user_term.term_accepted)
                actions.setAdminTermsStatus(res.admin_term.term_accepted)
                actions.setAdminTermsRequired(res.admin_term.admin_terms_required)
                actions.setOptionalGeolocation(res.optional_geolocation)
                actions.setSubscriptionStatus(res.subscription_active)
                actions.setSubscriptionReminder(res.subscription_reminder)
                actions.setAppLoading(false)
                actions.setRedirectRequired(false)

                // if (this.props.isAuthenticated && !this.props.auth.roles.includes(ROLES.superAdmin)) {
                //     if (!(res.userTerms && (res.adminTerms || !res.adminTermsRequired))) {
            }, true)
        }
    }, [])

    return <>{children}</>
}


const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionController)
