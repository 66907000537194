import React, {useRef, useState} from 'react';
import {CardElement, Elements, injectStripe, StripeProvider} from 'react-stripe-elements';
import {
    Row,
    ControlLabel,
    FormControl,
    FormGroup
} from "react-bootstrap";
import './StripeCardRegister.css'
import StripePowered from '../../images/Powered by Stripe - black.svg'

const createOptions = () => {
    return {
        style: {
            base: {
                width: '100%',
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Open Sans, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            }
        }
    }
};

const _CardForm = ({stripe,license_count, setIsValidCard, setStripePaymentMethod, loading}) => {
    const [name, setName] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isCompleted, setIsCompleted] = useState(false)
    const cardElement = useRef()
    const handleNameChange = (e) => {
        setName(e.target.value)
        if (isCompleted && e.target.value.length > 0) {
            setIsValidCard(true)
        } else {
            setIsValidCard(false)
        }
    }
    const handleCardChange = async (e) => {
        if (e.complete && name.length < 1) {
            const {error, paymentMethod} = await stripe.createPaymentMethod('card', cardElement.current._element);
            if(error){
                setErrorMessage(error.message)
            }else {
                setStripePaymentMethod(paymentMethod)
                setIsCompleted(true)
                setIsValidCard(false)
            }
        } else if (e.complete && name.length > 0) {
            const {error, paymentMethod} = await stripe.createPaymentMethod('card', cardElement.current._element);
            if(error){
                setErrorMessage(error.message)
            }else {
                setStripePaymentMethod(paymentMethod)
                setIsCompleted(true)
                setIsValidCard(true)
            }
        } else {
            setIsCompleted(false)
            setIsValidCard(false)
        }
        e.error ? setErrorMessage(e.error.message) : setErrorMessage('')
    }
    const generateNumberOfUsersInfo = () =>
        <FormGroup bsSize="medium" className='credential_input'>
            <ControlLabel className='d-flex'>
                <p className='bold bottom0'><span className='colorRed mr-3'>*</span>SELECTED NUMBER OF USERS</p>
            </ControlLabel>
            <FormControl
                type='text'
                placeholder='Number of users'
                value={license_count}
                disabled={true}
            />
        </FormGroup>

    const generateSubscriptionNameField = () =>
        <FormGroup bsSize="medium" className='credential_input'>
            <ControlLabel className='d-flex'>
                <p className='bold bottom0'><span className='colorRed mr-3'>*</span>FULL NAME</p>
            </ControlLabel>
            <FormControl
                type='text'
                placeholder='First and last name'
                value={name}
                onChange={e => handleNameChange(e)}
                disabled={loading}
            />
        </FormGroup>

    const generateCardNumberField = () =>
        <FormGroup bsSize="small" className='credential_input'>
            <ControlLabel className='d-flex'>
                <p className='bold bottom0'><span className='colorRed mr-3'>*</span>CARD</p>
            </ControlLabel>
            <CardElement
                ref={cardElement}
                onChange={(e) => handleCardChange(e)}
                {...createOptions()}
                disabled={loading}
            />
        </FormGroup>

    return (
        <div className="CardSubscritionDemo">
            <form>
                <Row className='textCenter'>
                    {generateNumberOfUsersInfo()}
                </Row>
                <Row className='textCenter mt-40'>
                    {generateSubscriptionNameField()}
                </Row>
                <Row className='textCenter mt-40'>
                    {generateCardNumberField()}
                </Row>
                <Row className='textCenter' >
                    <FormGroup bsSize="small" className='credential_input stripe-powered'>
                        <img src={StripePowered} alt='Stripe' style={{width: 150}}/>
                    </FormGroup>
                </Row>
                <Row className='textCenter'>
                    <div className="error text-danger" role="alert">
                        <strong>{errorMessage}</strong>
                    </div>
                </Row>
            </form>
        </div>
    );
}

const CardForm = injectStripe(_CardForm);

export const PaymentElement = ({license_count, setIsValidCard, setStripePaymentMethod, loading, stripePublishableKey}) => (
    <StripeProvider apiKey={stripePublishableKey}>
        <Elements>
            <CardForm
                license_count={license_count}
                setIsValidCard={setIsValidCard}
                setStripePaymentMethod={setStripePaymentMethod}
                loading={loading}
            />
        </Elements>
    </StripeProvider>
);
