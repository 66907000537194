import {Button, Col, Modal, Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import React from "react";
import FollowUps from "../Notifications/FollowUps";


class AddFollowUpModal extends ResourceComponent {

    render() {
        const {proposalId, proposal} = this.props;

        return (
            <Modal
                {...this.props}
                bsSize="big"
                animation={true}
                onHide={() => this.props.onHide()}
                dialogClassName='modal-smaller'

            >
                <Modal.Header >
                    <Modal.Title>Add Follow Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FollowUps count={25} type="follow ups" proposalId={proposalId} proposal={proposal} isProposalView={true} withFollowUp={true}
                    />

                </Modal.Body>
                <Modal.Footer>
                        <Button
                            bsStyle="danger"
                            bsSize="small text-left"
                            onClick={() => this.props.onHide()}
                        >
                            close
                        </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddFollowUpModal;