import React, { Component } from 'react'

//eslint complains on components we import but never render. since this is a highly experimental UI, lets tell eslint to not balk on this
/* eslint-disable no-unused-vars */
import Alert from '../../components/Alert'
import PageTitle from '../../components/PageTitle'


export default class ComponentBrowser extends Component{

  render(){
    return(
      <div>
        <div>Elemental UI Component Browser/Experimenter</div>


          <Alert key={1} message="This is the alert message with a custom mood" mood="success" />
        <PageTitle/>


      </div>



    )
  }

}
