export const [
  GET_CUSTOMER_BY_ID,
  FETCH_PROPOSALS,
  UPDATE_SERVICE_STATUS,
  FETCH_CUSTOMER_SITES,
  UPDATE_SELECTED_SITE,
  UPDATE_CUSTOMER_SEARCH,
  SELECT_CUSTOMER,
  FETCH_SEARCHED_CUSTOMERS,
  FETCH_SITES_ASSETS,
  FETCH_SELECTED_SITEINFO,
] = [
  "GET_CUSTOMER_BY_ID",
  "FETCH_PROPOSALS",
  "UPDATE_SERVICE_STATUS",
  "FETCH_CUSTOMER_SITES",
  "UPDATE_SELECTED_SITE",
  "UPDATE_CUSTOMER_SEARCH",
  "SELECT_CUSTOMER",
  "FETCH_SEARCHED_CUSTOMERS",
  "FETCH_SITES_ASSETS",
  "FETCH_SELECTED_SITEINFO",
]
