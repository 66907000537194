import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";
import MDSpinner from "react-md-spinner";

const ServicesSaveModal = ({onHide, semiRemovalChange, removalChange, onSave}) => {
    const [loading, setLoading] = useState(false)

    return <Modal
        backdrop="static"
        show={true}
        onHide={() => onHide()}
        className={"heightAuto fontIOS"}
    >
        <Modal.Header closeButton>
            <Modal.Title>Confirm save</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                {semiRemovalChange && <span>Semi-Removal Service</span>}
                {semiRemovalChange && removalChange && <span> and </span>}
                {removalChange && <span>Removal Service</span>}
                {(semiRemovalChange || removalChange) && <span> checkbox has been change.</span>}
            </p>
            <p>All Assets associated with this service must be updated, this may take a while. Are you sure you want to continue?</p>
        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={onHide}
                bsStyle="warning"
                data-dismiss="modal"
            >
                Cancel
            </Button>
            <Button
                bsStyle="success"
                disabled={loading}
                onClick={() => {
                    setLoading(true)
                    onSave(() => {
                        setLoading(false)
                        onHide()
                    })
                }}
            >
                {loading ? <div className="vertical-align"><MDSpinner size={20} className="mr-5"/> Processing...</div> : `Save`}
            </Button>
        </Modal.Footer>
    </Modal>
}

export default ServicesSaveModal;