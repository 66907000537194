import React, {useState, useEffect, useRef} from 'react'
import {
    convertToRaw,
    EditorState,
    ContentState,
} from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import 'draft-js/dist/Draft.css'
import 'draft-js-mention-plugin/lib/plugin.css'
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import createImagePlugin from '@draft-js-plugins/image';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import './CrewMessagesEditor.css'
import {locales} from "../../AppWrapper/contents/constants";

const imagePlugin = createImagePlugin();

const linkifyPlugin = createLinkifyPlugin({
    target: "_blank",
    component: props => {
        const {contentState, href, ...rest} = props;
        return <a {...rest} onClick={() => window.open(href, '_blank')} className='link-styles'/>;
    }
});

const plugins = [imagePlugin, linkifyPlugin]

export const CrewMessageEditor = ({setValue, reply, messageId, customClass, autoFocus = false, locale = locales.EN}) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const editorRef = useRef(null)
    const firstRenderRef = useRef(true)


    const focusEditor = () => editorRef.current?.focus()

    const styles = {
        border: "1px solid #777777",
        padding: '4px',
        minHeight: '60px',
        borderRadius: messageId ? '5px' : '5px 5px 0 0',
        borderBottom: messageId ? "1px solid #777777" : 'none',
        background: "#FFFFFF",
    }

    useEffect(() => {
        if (!reply && !firstRenderRef.current) {
            convertEditorState('')
        }
        firstRenderRef.current = false

    }, [reply])

    useEffect(() => {
        if (messageId) {
            convertEditorState(reply)
        }
    }, [messageId, locale])

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                focusEditor()
            }, 50)
        }
    }, [editorState])

    const convertEditorState = (value) => {
        const contentBlock = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        let editorState = EditorState.createWithContent(contentState);
        editorState = EditorState.moveSelectionToEnd(editorState);
        setEditorState(editorState);
    }

    return (
        <div id="editor" onClick={() => focusEditor()}>
            <div style={styles} className={customClass && customClass}>
                <Editor
                    ref={(editor) => (editorRef.current = editor)}
                    editorState={editorState}
                    plugins={plugins}
                    onChange={(event) => {
                        setEditorState(event)
                        let html = draftToHtml(convertToRaw(event.getCurrentContent()));
                        const isContent = convertToRaw(event.getCurrentContent()).blocks.some(x => x.text.length > 0)

                        if (messageId) {
                            if (isContent) {
                                setValue(html, messageId, isContent);
                            }
                        } else {
                            setValue(html, messageId, isContent);
                        }
                    }}
                    placeholder={'Type here...'}
                    spellCheck="true"
                />
            </div>
        </div>
    )
}

