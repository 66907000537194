import React from 'react';
import './SubscriptionRenewWidget.scss'
import Subscription from "../ClientsPage/tabs/Subscription/Subscription";
import {bindActionCreators} from "redux";
import * as actions from "./SubscriptionRenewWidgetAPI";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";

const SubscriptionRenewWidget = ({actions}) => {
    return (
        <>
            <div className='subscription-renew-widget'>
                <div className="mt33">
                    {window.location.href.includes('subscription_renew') ?
                        <h1>Subscription expired</h1> :
                        <h1>Subscription reminder</h1>}
                    <Subscription/>
                </div>
                <div className="vhCenter mt33">
                    <Button onClick={actions.logout}>Logout</Button>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(SubscriptionRenewWidget);
