import {
  FETCH_SERVICE_LIST,
  FETCH_SERVICE,
  UPDATE_SERVICE_ATTR,
  ADD_NEW_SERVICE,
  DELETE_SERVICE,
} from "../constants"

import axios from "axios"
import { addAlert } from "../containers/App/actions"

//READ (abriged index)
export const fetchServiceList = include_service_type => (
  //include_service_type causes the api to also return that type_id for filtering clientside
  dispatch,
  getState
) => {
  const store = getState()
  const config = {
    url: "/api/services/info",
    headers: { Authorization: store.auth.token },
    params: { include_service_type },
  }

  return axios.request(config).then(response => {
    dispatch({
      type: FETCH_SERVICE_LIST,
      serviceList: response.data,
      serviceListLastUpdated: Date.now(),
    })
  })
}

//READ (show)
export const fetchService = id => (dispatch, getState) => {
  const store = getState()
  const config = {
    url: `/api/services/${id}`,
    headers: { Authorization: store.auth.token },
  }

  return axios.request(config).then(response => {
    console.log("---->", response)
    dispatch({ type: FETCH_SERVICE, service: response.data })
  })
}

export const updateServiceAttribute = (id, attr, val) => {
  return { type: UPDATE_SERVICE_ATTR, id, attr, val }
}

//DELETE
export const deleteService = id => (dispatch, getState) => {
  if (id === 0) {
    //delete it locally assuming it will be deleted on the server
    dispatch({ type: DELETE_SERVICE, id })
    return true
  }

  const state = getState()
  const config = {
    method: "delete",
    url: `/api/services/${id}`,
    headers: { Authorization: state.auth.token },
  }

  axios.request(config).then(response => {
    dispatch(
      addAlert({ message: "Deleted", mood: "warning", dismissAfter: 1500 })
    )
  })
}

//CREATE/UPDATE
export const saveService = id => (dispatch, getState) => {
  const state = getState()

  const config = {
    method: id === 0 ? "post" : "put",
    url: id === 0 ? "/api/services" : `/api/services/${id}`,
    headers: { Authorization: state.auth.token },
    data: {
      service: state.services.services[`service_${id}`],
    },
  }

  return axios.request(config).then(response => {
    //returns the saved plant back so we can re-shove in whats in the db for paranoia
    //we dont depend on this
    dispatch({ type: FETCH_SERVICE, service: response.data })

    if (id === 0) {
      dispatch(deleteService(0))
    }

    dispatch(
      addAlert({
        message: id === 0 ? "Added" : "Saved",
        mood: "success",
        dismissAfter: 1500,
      })
    )
    return response // we're returning the promise and response so the callee can do something with the data
  })
}

//CREATE (add blank model)
export const createNewService = () => {
  return { type: ADD_NEW_SERVICE }
}
