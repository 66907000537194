import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actions from "./QuestionnairePageApi"
import './QuestionnairePage.scss'
import {Button, Col, FormControl, Glyphicon, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import MDSpinner from "react-md-spinner";
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const QuestionnairePage = ({actions, preview = false, currentClient=null, onSaveQuestionnaire = null, givenRating = null}) => {
    const [loading, setLoading] = useState(true)
    const [invoice, setInvoice] = useState(undefined)
    const [clientLogo, setClientLogo] = useState('')
    const [rating, setRating] = useState(givenRating)
    const [questionnaire, setQuestionnaire] = useState({})
    const [answers, setAnswers] = useState([])
    const [error, setError] = useState('')
    const [isAnswered, setIsAnswered] = useState(false)
    const [edited, setEdited] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState({show: false, deleteFunction: null})
    const [client, setClient] = useState(currentClient)

    const getMaxValue = (options) => {
        const orderArray = options.map(o => o.order)
        if (orderArray?.length > 0) {
            return Math.max(...options.map(o => o.order)) + 1
        } else {
            return 1
        }
    }

    const buttonsEditComponent = (deleteButton = false, deleteFunction, question, index = 0) => {

        const saveButton = () => {
            return <Button
                className='full-width rolled-button-text'
                bsStyle='success'
                disabled={question?.question?.length === 0}
                onClick={() => {
                    if (questionnaire.questions) {
                        questionnaire.questions = questionnaire.questions.map(q => {
                            return {...q, checkboxOptions: q.checkboxOptions.filter(ch => ch.description.length > 0)}
                        })
                        questionnaire.questions = questionnaire.questions.map(q => {
                            return {...q, checkboxOptions: q.checkboxOptions.map((ch, i) => {return {...ch, order: i + 1}})}
                        })
                    }


                    console.log('aaaaaa', questionnaire)
                    if (question && !question.id) {
                        question = {...question, id: index + 1}
                        questionnaire.questions[index] = question
                        setQuestionnaire({...questionnaire})
                    }
                    client.customer_questionnaire = JSON.stringify(questionnaire)
                    if (onSaveQuestionnaire) {
                        onSaveQuestionnaire(client)
                    }
                    setEdited({})
                }}
            >
                Save
            </Button>
        }

        return <Row className='mt10'>
            {deleteButton && <Col sm={4}>
                <Button
                    className='full-width rolled-button-text'
                    bsStyle='info'
                    disabled={question && !question.id}
                    onClick={() => {
                        setShowDeleteModal({
                            show: true,
                            deleteFunction: () => {
                                deleteFunction()
                                setEdited({})
                            }
                        })
                    }}
                >
                    Delete question
                </Button>
            </Col>}
            <Col sm={deleteButton ? 4 : 6}>
                <Button
                    className='full-width rolled-button-text'
                    bsStyle='warning'
                    onClick={() => {
                        if (client?.customer_questionnaire && client?.customer_questionnaire?.length > 0) {
                            setQuestionnaire(JSON.parse(client?.customer_questionnaire))
                        } else {
                            setQuestionnaire({})
                        }
                        setEdited({})
                    }}
                >
                    Cancel
                </Button>
            </Col>
            <Col sm={deleteButton ? 4 : 6}>
                {question?.question?.length === 0 ?
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Add question before save</Tooltip>}>
                        <div>
                            {saveButton()}
                        </div>
                    </OverlayTrigger>
                    :
                    saveButton()
                }
            </Col>
        </Row>
    }

    const headerEditComponent = () => {
        return <div className='header-edit-component-wrapper'>
            <h4 className="bold">Main Section</h4>
            <Row className='vertical-align marginBottom8'>
                <Col xs={3} className='rolled-text'>Title</Col>
                <Col xs={9}>
                    <FormControl
                        name="Title"
                        placeholder="Title"
                        onChange={e => {
                            questionnaire.header = e.target.value
                            setQuestionnaire({...questionnaire})
                        }}
                        value={questionnaire.header}
                    />
                </Col>
            </Row>
            <Row className='vertical-align'>
                <Col xs={3} className='rolled-text'><span>1 <Glyphicon glyph='star'/></span></Col>
                <Col xs={9}>
                    <FormControl
                        name="oneStar"
                        placeholder="Description when user selects 1 star"
                        onChange={e => {
                            questionnaire.oneStar = e.target.value
                            setQuestionnaire({...questionnaire})
                        }}
                        value={questionnaire.oneStar}
                    />
                </Col>
            </Row>
            <Row className='vertical-align'>
                <Col xs={3} className='rolled-text'><span>2 <Glyphicon glyph='star'/></span></Col>
                <Col xs={9}>
                    <FormControl
                        name="twoStar"
                        placeholder="Description when user selects 2 star"
                        onChange={e => {
                            questionnaire.twoStar = e.target.value
                            setQuestionnaire({...questionnaire})
                        }}
                        value={questionnaire.twoStar}
                    />
                </Col>
            </Row>
            <Row className='vertical-align'>
                <Col xs={3} className='rolled-text'><span>3 <Glyphicon glyph='star'/></span></Col>
                <Col xs={9}>
                    <FormControl
                        name="threeStar"
                        placeholder="Description when user selects 3 star"
                        onChange={e => {
                            questionnaire.threeStar = e.target.value
                            setQuestionnaire({...questionnaire})
                        }}
                        value={questionnaire.threeStar}
                    />
                </Col>
            </Row>
            <Row className='vertical-align'>
                <Col xs={3} className='rolled-text'><span>4 <Glyphicon glyph='star'/></span></Col>
                <Col xs={9}>
                    <FormControl
                        name="fourStar"
                        placeholder="Description when user selects 4 star"
                        onChange={e => {
                            questionnaire.fourStar = e.target.value
                            setQuestionnaire({...questionnaire})
                        }}
                        value={questionnaire.fourStar}
                    />
                </Col>
            </Row>
            <Row className='vertical-align marginBottom8'>
                <Col xs={3} className='rolled-text'><span>5 <Glyphicon glyph='star'/></span></Col>
                <Col xs={9}>
                    <FormControl
                        name="fiveStar"
                        placeholder="Description when user selects 5 star"
                        onChange={e => {
                            questionnaire.fiveStar = e.target.value
                            setQuestionnaire({...questionnaire})
                        }}
                        value={questionnaire.fiveStar}
                    />
                </Col>
            </Row>
            <Row className='vertical-align'>
                <Col xs={3} className='rolled-text'>Description</Col>
                <Col xs={9}>
                    <FormControl
                        componentClass="textarea"
                        rows={3}
                        name="description"
                        placeholder="Description"
                        onChange={e => {
                            questionnaire.description = e.target.value
                            setQuestionnaire({...questionnaire})
                        }}
                        value={questionnaire.description}
                    />
                </Col>
            </Row>
            {buttonsEditComponent()}
        </div>
    }

    const headerComponent = () => {
        const ratingDescription = () => {
            switch (rating) {
                case 1:
                    return questionnaire.oneStar;
                case 2:
                    return questionnaire.twoStar;
                case 3:
                    return questionnaire.threeStar;
                case 4:
                    return questionnaire.fourStar;
                case 5:
                    return questionnaire.fiveStar;
            }
        }

        const isHeader = () => {
            return (questionnaire?.header && questionnaire?.header?.length > 0) ||
                (ratingDescription() && ratingDescription().length > 0) ||
                (questionnaire?.description && questionnaire?.description?.length > 0)
        }

        return <div className={`welcome-text ${preview ? Object.keys(edited).length === 0 ? 'welcome-text-preview' : '' : ''}`} onClick={() => preview && Object.keys(edited).length === 0 && setEdited({header: true})}>
            {isHeader() ?
                <>
                    <h3><b>{questionnaire?.header}</b></h3>
                    <h4>{ratingDescription()}</h4>
                    <h4>{questionnaire?.description}</h4>
                </>
                :
                <h3>Header is empty</h3>
            }
        </div>
    }

    const questionEditComponent = (q, i) => {
        const deleteQuestionFunction = () => {
            questionnaire.questions.splice(i, 1)
            const newQuestions = questionnaire.questions.map((o, i) => {
                return {...o, order: i + 1, id: i + 1}
            })
            setQuestionnaire({...questionnaire, questions: newQuestions})
            client.customer_questionnaire = JSON.stringify({...questionnaire, questions: newQuestions})
            if (onSaveQuestionnaire) {
                onSaveQuestionnaire(client)
            }
        }

        return <Row className='question-edit-component-wrapper'>
            <h4 className="bold">Question {i + 1}</h4>
            <Col xs={12} className="top5 bottom5 question">
                <Row>
                    <Col sm={3} xs={12} className="rolled-text">Question:</Col>
                    <Col sm={9} xs={12} className="vertical-align">
                        <FormControl
                            name="question"
                            placeholder="Question"
                            onChange={e => {
                                q.question = e.target.value
                                questionnaire.questions[i] = q
                                setQuestionnaire({...questionnaire})
                            }}
                            value={q.question}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={9} smOffset={3} className='top5 bottom5'>
                        <ColorCheckbox
                            value={q.addDescription}
                            label="Add a textbox below the question"
                            onChange={() => {
                                q.addDescription = !q.addDescription
                                questionnaire.questions[i] = q
                                setQuestionnaire({...questionnaire})
                            }}/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={9} smOffset={3} className='top5 bottom5'>
                        <ColorCheckbox
                            value={q.addOptions}
                            label="Add selectable options below the question"
                            onChange={() => {
                                q.addOptions = !q.addOptions
                                questionnaire.questions[i] = q
                                setQuestionnaire({...questionnaire})
                            }}/>
                    </Col>
                </Row>
                {q.addOptions && <Row>
                    <Col sm={8} smOffset={4} xs={11} xsOffset={1} className='top5 bottom5'>
                        <ColorCheckbox
                            value={q.enableMulti}
                            label="Enable select multiple options"
                            onChange={() => {
                                q.enableMulti = !q.enableMulti
                                questionnaire.questions[i] = q
                                setQuestionnaire({...questionnaire})
                            }}/>
                    </Col>
                </Row>}
                {q.addOptions && <Row>
                    <Col sm={8} smOffset={4} xs={11} xsOffset={1} className='top5 bottom5'>
                        <ColorCheckbox
                            value={q.optionsHorizontally}
                            label="Set options horizontally"
                            onChange={() => {
                                q.optionsHorizontally = !q.optionsHorizontally
                                q.optionsVertically = !q.optionsVertically
                                questionnaire.questions[i] = q
                                setQuestionnaire({...questionnaire})
                            }}/>
                    </Col>
                </Row>}
                {q.addOptions && <Row>
                    <Col sm={8} smOffset={4} xs={11} xsOffset={1} className='top5 bottom5'>
                        <ColorCheckbox
                            value={q.optionsVertically}
                            label="Set options vertically"
                            onChange={() => {
                                q.optionsVertically = !q.optionsVertically
                                q.optionsHorizontally = !q.optionsHorizontally
                                questionnaire.questions[i] = q
                                setQuestionnaire({...questionnaire})
                            }}/>
                    </Col>
                </Row>}
                {q.addOptions && <Row>
                    <Col sm={9} smOffset={3}>
                        {q.checkboxOptions && q.checkboxOptions.length > 0 && q.checkboxOptions?.map((ch, index) => {
                            return <div className='vertical-align bottom5'>
                                {ch.order}.
                                <FormControl
                                    name="description"
                                    placeholder={`Option ${index + 1} text`}
                                    className="ml-6"
                                    onChange={e => {
                                        q.checkboxOptions[index].description = e.target.value
                                        questionnaire.questions[i] = q
                                        setQuestionnaire({...questionnaire})
                                    }}
                                    value={ch.description}
                                />
                                {q.checkboxOptions.length > 1 &&
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Remove option</Tooltip>}>
                                        <Glyphicon
                                            glyph='remove-sign'
                                            className="pointer font20 marginLeft10"
                                            style={{color: 'red'}}
                                            onClick={() => {
                                                setShowDeleteModal({
                                                    show: true,
                                                    deleteFunction: () => {
                                                        q.checkboxOptions.splice(index, 1)
                                                        q.checkboxOptions = q.checkboxOptions.map((o, i) => {
                                                            return {...o, order: i + 1}
                                                        })
                                                        questionnaire.questions[i] = q
                                                        setQuestionnaire({...questionnaire})
                                                    }
                                                })
                                            }}
                                        />
                                    </OverlayTrigger>}
                            </div>
                        })}
                    </Col>
                    <Col sm={9} smOffset={3}>
                        <Button
                            bsSize="small"
                            bsStyle="success"
                            className="full-width bottom5"
                            onClick={() => {
                                if (!q?.checkboxOptions?.some(o => o.description?.length === 0)) {
                                    q.checkboxOptions.push({order: getMaxValue(q.checkboxOptions), description: ''})
                                    questionnaire.questions[i] = q
                                    setQuestionnaire({...questionnaire})
                                }
                            }}
                        >
                            Add new option
                        </Button>
                    </Col>
                </Row>}
            </Col>
            {buttonsEditComponent(true, () => deleteQuestionFunction(), q, i)}
        </Row>
    }

    const questionComponent = (q, i) => {
        return <div className={`question-wrapper ${preview ? Object.keys(edited).length === 0 ? 'question-preview-wrapper' : '' : ''}`} onClick={() => preview && Object.keys(edited).length === 0 && setEdited({question: i + 1})}>
            <div className='d-flex'>
                <div className='order'>
                    {q.order}.
                </div>
                <div className='full-width'>
                    <div>{q.question}</div>
                    <div>
                        {q.addOptions && <div
                            className={`checkbox-options-wrapper ${q.optionsHorizontally && 'horizontally-wrapper'} ${q.optionsVertically && 'vertically-wrapper'}`}>
                            {q.checkboxOptions?.map((o, index) => {
                                return <div
                                    className={`${q.optionsHorizontally && 'horizontally'} ${q.optionsVertically && 'vertically'}`}
                                    style={{width: q.optionsVertically ? `${(1 / q.checkboxOptions.length) * 100}%` : 'auto'}}
                                >
                                    <input
                                        id={`${i}-${index}`}
                                        type="checkbox"
                                        checked={q.enableMulti ? answers[i]?.checkboxAnswer?.includes(o.description) : answers[i]?.checkboxAnswer === o.description}
                                        disabled={preview}
                                        onClick={(e) => !preview && onChangeCheckbox(e, o.description, i, q.enableMulti)}
                                    />
                                    <label htmlFor={`${i}-${index}`}>
                                        {o.description}
                                    </label>
                                </div>
                            })}
                        </div>}
                    </div>
                    {q.addDescription && <div className='description'>
                        <FormControl
                            name="comment"
                            componentClass="textarea"
                            rows={3}
                            disabled={preview}
                            placeholder=""
                            onChange={e => {
                                answers[i].descriptionAnswer = e.target.value
                                setAnswers([...answers])
                            }}
                            value={answers[i]?.descriptionAnswer}
                        />
                    </div>}
                </div>
            </div>
        </div>
    }

    const onChangeCheckbox = (e, description, index, isMulti) => {
        if (isMulti) {
            if (e.target.checked) {
                answers[index].checkboxAnswer.push(description)
            } else {
                answers[index].checkboxAnswer = answers[index].checkboxAnswer.filter(a => a !== description)
            }
        } else {
            answers[index].checkboxAnswer = description
        }
        setAnswers([...answers])
    }

    const onSave = () => {
        actions.saveQuestionnaire(invoice.id, JSON.stringify(answers), rating, res => {
            if (res.error) {
                setError(res.error)
            } else {
                setIsAnswered(true)
            }
        })
    }

    useEffect(() => {
        if (preview) {
            if (client && client?.customer_questionnaire && client?.customer_questionnaire?.length > 0) {
                setQuestionnaire(JSON.parse(client?.customer_questionnaire))
            }
            setClientLogo(client.client_logo_selected)
            setLoading(false)
        } else {
            const query = new URLSearchParams(window.location.search)
            const urlRating = query.get('rating')
            const token = window.location?.pathname?.split('/')?.pop()
            let link = null

            if (urlRating) {
                setRating(parseInt(urlRating))

                if (parseInt(urlRating) === 5 && token){
                    actions.sendGoogleEmail(token, res => {
                        link = res.link
                        if (res.link) {
                            window.location.replace(res.link)
                        }
                    })
                }
            }

            if (token && (parseInt(urlRating) !== 5 || !link)) {
                actions.getQuestionnaire(token, res => {
                    const clientQuestionnaire = JSON.parse(res.questionnaire)
                    setQuestionnaire(clientQuestionnaire)
                    setInvoice(res.invoice)
                    setClientLogo(res.client_header)
                    setLoading(false)
                    if (parseInt(urlRating) === 5) {
                        setIsAnswered(true)
                    } else {
                        setIsAnswered(res.is_answered)
                    }

                    if (clientQuestionnaire) {
                        const newAnswer = clientQuestionnaire.questions?.map(q => ({
                            question: q.question,
                            checkboxAnswer: q.enableMulti ? [] : '',
                            descriptionAnswer: q.addDescription ? '' : null
                        }))
                        setAnswers(newAnswer)
                        setRating(parseInt(urlRating))
                    }
                })
            }
        }
    }, [])

    useEffect(() => {
        if (givenRating) {
            setRating(givenRating)
        }
    }, [givenRating])

    return <div id='questionnaire'>
        {loading ?
            <div className='loader'>
                <MDSpinner size={70}/>
            </div>
            :
            <div className='questionnaire-wrapper'>
                <div className='logo'>
                    <img src={clientLogo} alt='Client Logo'/>
                </div>
                {error && error.length > 0 && <h3 className='text-danger text-center'>{error}</h3>}
                {isAnswered ?
                    <h3 className='text-center'><b>Thank you for your answers!</b></h3>
                    :
                    edited?.header ?
                        headerEditComponent()
                        :
                        questionnaire && Object.keys(questionnaire)?.length > 0 ?
                            headerComponent()
                            :
                            <h3 className={`text-center ${preview ? 'empty-questionnaire-preview' : ''}`} onClick={() => preview && Object.keys(edited).length === 0 && setEdited({header: true})}>Sorry, the questionnaire is not ready yet.</h3>
                }
                <div>
                    {!isAnswered && questionnaire && questionnaire?.questions?.map((q, i) => {
                        return edited?.question && edited?.question === i + 1 ?
                            questionEditComponent(q,i)
                            :
                            questionComponent(q, i)
                    })}
                </div>
                {preview && <div>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        className="full-width bottom5 mt-20"
                        onClick={() => {
                            const newQuestion = {
                                question: "",
                                addOptions: false,
                                enableMulti: false,
                                optionsHorizontally: false,
                                optionsVertically: true,
                                addDescription: false,
                                checkboxOptions: [{order: 1, description: ''}],
                                order: getMaxValue(questionnaire?.questions ? questionnaire?.questions : []),
                            }

                            if (questionnaire?.questions) {
                                questionnaire.questions.push(newQuestion);
                            } else {
                                questionnaire.questions = [newQuestion]
                            }

                            setQuestionnaire({...questionnaire})
                            setEdited({question: questionnaire?.questions?.length})
                        }}
                    >
                        Add new question
                    </Button>
                </div>}
                {!preview && !isAnswered && questionnaire && Object.keys(questionnaire).length > 0 && <div className='buttons'>
                    <Button
                        bsStyle='success'
                        onClick={() => onSave()}
                    >
                        Submit
                    </Button>
                </div>}
            </div>}
        {showDeleteModal?.show &&
            <Modal
                show={true}
                onHide={() => setShowDeleteModal({show: false, deleteFunction: null})}
                className="heightAuto fontIOS"
                bsSize="small"
                dialogClassName="min-fit-content"
            >
                <Modal.Header>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you sure?</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        onClick={() => setShowDeleteModal({show: false, deleteFunction: null})}
                    >
                        Close
                    </Button>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        onClick={() => {
                            showDeleteModal.deleteFunction()
                            setShowDeleteModal({show: false, deleteFunction: null})
                        }}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>}
    </div>
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(undefined, mapDispatchToProps)(QuestionnairePage)