import {Col, Modal, Row} from "react-bootstrap";
import React, {Component} from 'react';
import MDSpinner from "react-md-spinner";

class RateModal extends Component {
    state = {
        loading: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show) {
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                style={this.props.overflow}
                backdrop={this.props.backdrop}
            >
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            {this.props.infoContent}
                        </Col>
                        {!this.props.acceptBtn ?
                            <Col md={6}>
                                <button disabled={this.state.loading} type="button" onClick={this.props.closeButton} className="btn btn-default"
                                        data-dismiss="modal">No
                                </button>
                                <button disabled={this.state.loading} type="button" onClick={() => this.setState({loading: true}, () => this.props.onHide())} className="btn btn-success">
                                    {this.state.loading ? <MDSpinner size={15}/> : "Yes"}
                                </button>
                            </Col> :
                            <Col md={6}>
                                <button type="button" onClick={this.props.onHide} className="btn btn-success">Ok
                                </button>
                            </Col>}
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default RateModal;
