import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../common/commonHandlers";
import moment from "moment";
import _ from "lodash";

export const load = (workOrder, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/chemical_compliances/consumables`},
        {url: `/api/chemical_compliances/employees`},
        {url: `/api/chemical_compliances/pests`},
        {url: `/api/chemical_compliances/application_sites`},
        {url: `/api/chemical_compliances/application_types`},
        {url: `/api/chemical_compliances/${workOrder.id}`},
        {url: `/api/chemical_compliances/service_associations`,
            params: {service_ids: workOrder.work_order_proposal_services.filter(s => s.isPHC).map(s => s.proposal_service_id)}
        },
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const data = {
            brandNames: all[0].data.map(x => ({
                value: x.id,
                label: `${x.name}${x.epa ? ` [#${x.epa}]` : ''}`,
                relevantPlants: x.relevant_plants,
                mixingRatio: x.dilution_rate && x.per ? `${x.dilution_rate}/${x.per}` : '',
                measurementPer: x.measurement_per,
                targetedPestIds: x.factors,
            })),
            applicators: mapForSelect(all[1].data),
            pests: mapForSelect(_.sortBy(all[2].data, "name")),
            applicationSites: mapForSelect(all[3].data),
            applicationTypes: mapForSelect(all[4].data),
            chemicalCompliance: all[5].data,
            serviceAssociations: all[6].data,
        };
        callback(data);
    }, dispatch, getState, false);
}

export const save = (data, workOrderId, callback) => (dispatch, getState) => {
    const chemical_compliance_entries_attributes = [];

    data.services.forEach(s => {
        s.assets.forEach(a => {
            a.chemical_compliances.forEach(cc => {
                chemical_compliance_entries_attributes.push({
                    proposal_service_id: s.proposal_service_id,
                    asset_object_id: a.id,
                    brand_name_id: cc.brandName?.value,
                    mixing_ratio: cc.mixingRatio,
                    amount_applied: cc.amountApplied,
                    measurement_id: cc.measurement?.value,
                    plants_count: cc.plantsCount,
                    area_sqft: cc.areaSqft,
                    plant_material_ids: cc.materialType?.map(p => p.value) || [],
                    targeted_pest_ids: cc.targetedPest?.map(p => p.value) || [],
                    application_type_ids: cc.appType?.map(p => p.value) || [],
                    notes: cc.notes,
                    position: cc.position,
                    stay_away: a.stay_away_until_dry || false,
                })
            })
        })
    });

    const config = {
        url: data.id ? `/api/chemical_compliances/${data.id}` : `/api/chemical_compliances`,
        method: data.id ? "PUT" : "POST",
        data: {
            chemical_compliance: {
                work_order_id: workOrderId,
                application_date: moment(data.applicationDate).unix(),
                application_time_start: moment(data.applicationTimeFrom).unix(),
                application_time_stop: moment(data.applicationTimeTo).unix(),
                applicator_id: data.applicator?.value,
                applicator_responsible_id: data.applicatorResponsible?.value,
                temperature: data.temperature,
                wind: data.wind?.value,
                chemical_compliance_entries_attributes: chemical_compliance_entries_attributes
            }
        }
    };

    apiCall(`${data.id ? "Updating" : "Creating"} chemical compliance`, config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true);
}