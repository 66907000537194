import {usePlaidLink} from "react-plaid-link";
import React from "react";

export const PlaidLink = ({token, onSuccess}) => {
    const config = {token, onSuccess};
    const {open, ready} = usePlaidLink(config);

    return (
        <button onClick={() => open()} disabled={!ready}>
            Connect a bank account
        </button>
    );
};
