export const [
  FETCH_PLANTS_FOR_CRUD,
  EDITING_SERVICE_ID,
  LOAD_PLANT_FOR_EDITING,
  EDIT_FILTER_TEXT,
] = [
  'FETCH_PLANTS_FOR_CRUD',
  'EDITING_SERVICE_ID',
  'LOAD_PLANT_FOR_EDITING',
  'EDIT_FILTER_TEXT',
]
