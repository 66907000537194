import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const getEmails = (resource, page, per_page, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/emails/get_emails`,
        params: {
            ...resource,
            page: page,
            per_page: per_page
        }
    }

    apiCall("Emails load successfully", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};
