import React from 'react';
import PropTypes from "prop-types";
import headerData from './bundleServicesHeaderData.json'
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {Button, Glyphicon} from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import {defaultDateFormat} from "../../common/commonHandlers";

const BundleServicesHeader = (bundleService, view, onDelete, isDeletable, markedCheckboxes, proposalServiceId, promisedDates, dateTimeCompletedAt, getAllAssets) => {


    const checkPromisedDate = (bundleServiceDate, proposalServiceDate) => {
        if (proposalServiceDate) {
            return moment.utc(proposalServiceDate)
        } else if (bundleServiceDate) {
            return moment.utc(bundleServiceDate)
        } else {
            return ""
        }
    }

    const manipulateParagraph = (object) => {
        let content = bundleService[object.name]
        switch (object.name) {
            case "man_hours":
                return `${content}${content > 1 ? "hrs" : "hr"}`;
            case "service_total":
                return `$${parseFloat(content).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`
            case "priorities":
                return content.map(c => c.name).join(" ")
            case "asset_numbers":
                return (
                    content.length < 5 ? content?.map(number => getAllAssets().find(a => a.asset_number === number)?.label).sort((a, b) => a - b).join(", ") : `<b>${content.length}</b> assets`
                )
            default:
                return content
        }
    }

    const buildHeaderColumn = (object) => {
        switch (object.type) {
            case "date":
                return (
                    <DateRangePicker
                        selected={false}
                        startDate={checkPromisedDate(bundleService.promised_start_at, promisedDates.promisedStart)}
                        startDatePlaceholderText={'From'}
                        endDate={checkPromisedDate(bundleService.promised_end_at, promisedDates.promisedEnd)}
                        endDatePlaceholderText={'To'}
                    />
                )
                break;
            case "checkbox":
                return <ColorCheckbox label={""} value={markedCheckboxes.includes(proposalServiceId)} disabled={true}/>
                break;
            case "paragraph":
                return <p className="bundle-service-header-paragraph" dangerouslySetInnerHTML={{__html: manipulateParagraph(object)}}></p>
                break;
            case "button":
                return (
                    <>
                        {isDeletable &&
                            <Button onClick={() => onDelete(bundleService.id)}>
                                <Glyphicon glyph='remove' className="pointer"/>
                            </Button>
                        }
                    </>
                )
                break;
            case "date-time":
                return (
                    <Datetime
                        dateFormat={defaultDateFormat}
                        timeFormat={false}
                        value={moment(dateTimeCompletedAt)}
                        className="full-width table-auto"
                        inputProps={{placeholder: 'Date'}}
                    />
                )
                break;
            default:
                return ""
        }
    }

    return (
        <>
            <div className={`bundle-service-header bundle-service-header--${view}`} key={bundleService.id}>
                {headerData.filter(hd => hd.view === view).map(hd => {
                    return (
                        <>
                            {hd.body.map(b => {
                                return (
                                    <div className={b.class}>
                                        {buildHeaderColumn(b)}
                                    </div>
                                )
                            })}
                        </>
                    )
                })}
            </div>
        </>
    );
};

BundleServicesHeader.propTypes = {
    bundleService: PropTypes.object.isRequired
}

export default BundleServicesHeader;