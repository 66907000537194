import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {merge, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";

let api = createApiService('conditions', 'condition', 'Condition');

class Conditions extends ResourceComponent {

    defaultValues = {
        name: '',
        description: '',
        resource: null
    };

    state = {}

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    updateResources = resources => this.setState({resources});

    handleSelect = e => this.setState({resource: merge(e.resource, this.defaultValues)});

    render() {
        const {resources, resource} = this.state;
        const {save, remove, load} = this.props.actions;

        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Condition"
                        resourceNamePlural="Conditions"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                save(resource, this.reloadResources)
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => remove(resource, () => {
                            this.setState({resource: null}, () => load({}, this.updateResources));
                        })}>
                        {resource && <div>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Name
                                        </ControlLabel>
                                        <FormControl
                                            name="name"
                                            placeholder="Name"
                                            onChange={this.updateResourceAttr}
                                            value={resource.name}
                                        />
                                        {this.state.errorMessage && <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup bsSize="sm">
                                        <ControlLabel>Description</ControlLabel>
                                        <FormControl
                                            componentClass="textarea"
                                            placeholder="Description"
                                            value={resource.description}
                                            name="description"
                                            rows={5}
                                            onChange={this.updateResourceAttr}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Conditions)
