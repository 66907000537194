import React from 'react';
import './SubscriptionRenewWidget.scss'
import {bindActionCreators} from "redux";
import * as actions from "./SubscriptionExpiredAPI";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";

const SubscriptionExpired = ({actions}) => {
    return (
        <div className='subscription-renew-widget'>
            <div className="mt33">
                <h1>Subscription expired</h1>
                <h2>Contact with your administrator</h2>
            </div>
            <div className="vhCenter mt33">
                <Button onClick={actions.logout}>Logout</Button>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(SubscriptionExpired);
