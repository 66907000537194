import React from "react";
import {connect} from "react-redux";
import {fetchMap} from "./actions";
import ResourceComponent from "../../components/ResourceComponent";
import config from "../../config/environment"

// Don't remove - it's for wkhtmltopdf
window.requestAnimationFrame = window.requestAnimationFrame || function () {
    return true;
};

class StaticMap extends ResourceComponent {

    state = {
        loaded: false,
        image: null
    };

    constructor(props) {
        super(props);
    }

    createUrl = () => {
        let resourceUrl;
        const {size, maptype, mainMap, fitBounds, zoom, center, isServiceOffered, showDraft} = this.props;

        if (this.props.proposalToken) {
            resourceUrl = `${this.props.baseUrl}/api/restricted/${this.props.proposalToken}/proposal`;
        } else if (this.props.invoiceToken) {
            resourceUrl = `${this.props.baseUrl}/api/restricted/${this.props.invoiceToken}/invoice`;
        } else if (this.props.workOrderToken){
            resourceUrl = `${this.props.baseUrl}/api/restricted/${this.props.workOrderToken}/work_order`;
        }
        let url = `${resourceUrl}/map?size=${size}&zoom=${zoom}&maptype=${maptype}&mainMap=${mainMap}&center=${center}&fit_bounds=${fitBounds}&is_service_offered=${isServiceOffered}&show_draft=${showDraft}`;
        return url;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isServiceOffered} = this.props;

        if (prevProps.isServiceOffered !== isServiceOffered) {
            this.loadMap()
        }
    }

    loadMap = () => {
        fetchMap(this.createUrl(), this.props.token, image => {
            this.setState({image: image});
        })
    };

    componentWillMount() {
        this.loadMap()
    }

    render() {
        const isMobile = window.screen.width <= 425;
        const {image} = this.state;
        return (<img width={isMobile && window.screen.width - 60} src={image}/>)
    }
}

const mapStateToProps = state => ({
    baseUrl: state.auth.baseUrl,
    token: state.auth.token
});

export default connect(mapStateToProps)(StaticMap)