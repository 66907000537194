import React from "react";
import DefaultReportView from "./DefaultReportView";

const InvoicedReportTab = ({getRevenueReport}) => {
    const reportOptions = [
        {value: 'invoiced_report', label: 'Invoiced Report'},
    ]

    return <DefaultReportView
        title="Invoiced Reports"
        options={reportOptions}
        getRevenueReport={getRevenueReport}
    />
}

export default InvoicedReportTab;