import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from "./FindTimeApi";
import SchedulerEventDialog from "../Scheduler/SchedulerEventDialog";
import LeadMenu from "./LeadMenu";
import {browserHistory} from "react-router";
import {Grid} from "react-bootstrap";

class FindTime extends Component {

    state = {lead: null, addressesWithCoordination: [], hideCompleted: true};

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.props.actions.getLead(this.props.params.id, lead => this.setState({lead}))
    }

    setHideCompleted = () => {
        const {hideCompleted} = this.state;
        this.setState({hideCompleted: !hideCompleted})
    }

    render() {
        const id = this.props.params.id;
        const eventId = this.props.params.eventId;
        const {lead, hideCompleted} = this.state;
        return (lead && <Grid fluid>
                <LeadMenu lead={lead} id={id} save={this.save}/>
                <SchedulerEventDialog
                    setHideCompleted={this.setHideCompleted}
                    hideCompleted={hideCompleted}
                    inModal={false}
                    event={{
                        id: eventId,
                        person_id: lead.assigned_arborist ? lead.assigned_arborist : lead.person_id,
                        scheduler_event_type_resource: 'Lead',
                        scheduler_event_resource_id: id
                    }}
                    save={save => {
                        this.save = save
                    }}
                    handleClose={() => {
                        browserHistory.push(`/lead/${id}`);
                    }}
                />
            </Grid>
        );
    }
}

FindTime.propTypes = {};

FindTime.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FindTime);
