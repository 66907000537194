import axios from "axios/index";
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import {handleUpload} from "../../common/upload";
import printJS from "print-js";
import {addAlert} from "../../containers/App/actions";
import * as FileSaver from "file-saver";

export const getTemplate = (emailTemplateId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/email_templates/${emailTemplateId}`
    };

    apiCall("Getting email template", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}
export const getClient = (clientId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/clients/${clientId}`
    };

    apiCall("Getting client", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const renderEmail = (emailTemplateId, emailType, referenceId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/email_templates/${emailTemplateId}/render_email?email_type=${emailType}&reference_id=${referenceId}`
    };

    apiCall("Rendering proposal email", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const uploadAttachment = (acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload('/api/emails/upload_attachment', acceptedFiles, callback)(dispatch, getState);
};

export const sendEmail = (email, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/emails/send_email`,
        method: 'POST',
        data: {email}
    };

    apiCall("Email sending", config, async config => {
        const response = await axios.request(config);
        callback(response);
    }, dispatch, getState, true, errorCallback);
};

export const attachPdf = (invoice, callback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${invoice.token}/invoice_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        handleUpload('/api/emails/upload_attachment', [response.data], callback, null, `${invoice.client_name}-Invoice #${invoice.number}.pdf`)(dispatch, getState);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        throw exception
    }
};

export const sendNotification = (emailType, referenceId, emailTemplateId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/twilio/send_notification`,
        method: 'POST',
        data: {email_type: emailType, reference_id: referenceId, email_template_id: emailTemplateId}
    };

    apiCall("Text sent", config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState, true, callback);
};

export const getRecipients = (ids, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/emails/get_recipients`,
        params: {ids: ids}
    };

    apiCall("Get recipients", config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState, false, callback);
};
export const verifySender = (data,callback) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/sendgrid/verify_sender`,
        data: data
    };

    apiCall("Verify Sender", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
export const resendVerifyEmail = (callback) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/sendgrid/resend_verify_email`,
    };

    apiCall("Resend Verification Email", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
export const checkIfSenderVerified = (callback) => (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/sendgrid/check_if_verified`,
    };

    apiCall("Verify Sender", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const duplicateTemplate = (data, callback) => async (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/email_templates`,
        data: {...data}
    };

    apiCall("Duplicate email template", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false, callback);
};

export const saveEditorSettings = (settings, clientId, callback) => async (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/clients/${clientId}/change_editor_settings`,
        data: {editor_settings: settings}
    };

    apiCall("Saved selected editor", config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState, false, callback);
};