import {Button, Col, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import React, {Component} from 'react';
import './modal.css';

class InvoiceFilterModal extends Component {

    render() {
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                id="invoice-filter-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invoices Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" onClick={this.props.closeButton} className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" onClick={this.props.onHide} className="btn btn-success">Save</button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default InvoiceFilterModal;