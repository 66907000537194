import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {Link} from "react-router"
import * as actions from "./actions"
import AssetHistoryForm from "./AssetHistoryForm"
import moment from "moment"
import {uploadAttachment} from '../JobSiteAnalysisPage/JobSiteAnalysisApi'
import Dropzone from "react-dropzone"
import Dollars from "../../components/Dollars"
import PropTypes from "prop-types"
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Label,
    Modal,
    Panel,
    Row,
    Table,
} from "react-bootstrap"

import Select from "react-select"
import "./styles.css"
import SiteHazardTable from "../JobSiteAnalysisPage/SiteHazardTable";
import createApiService from "../../common/clientActionsBuilder";
import {checkRectangleCoordinates, select} from "../../common/commonHandlers";

const assetHazardApi = createApiService('asset_hazards', 'asset_hazard', 'Asset Hazard');
const Actions = {
    ...actions,
    uploadAttachment,
    saveAssetHazard: assetHazardApi.save,
    deleteAssetHazard: assetHazardApi.remove
};


class AssetEditorModal extends Component {
    //really editingAssetId should be a number, or null if the modal isn't open but .isRequired means ! null :/
    //so weird because we can import updateAssetAttribute from MapView or let it pass it in as a ownprop. not sure which is best
    state = {};
    static propTypes = {
        editingAssetId: PropTypes.number,
        assetHistory: PropTypes.object.isRequired,
        doneEditingAsset: PropTypes.func.isRequired,
        updateAssetAttribute: PropTypes.func.isRequired,
    }

    componentWillReceiveProps(nextProps) {
        //not sure we need to hook into this
        console.log(
            "receiving props with editingasset id this and next",
            this.props.editingAssetId,
            nextProps.editingAssetId
        )
        //  console.log("next asset id is " + nextProps.editingAssetId)
        if (
            nextProps.editingAssetId !== null &&
            nextProps.editingAssetId !== this.props.editingAssetId
        ) {
            //load data for forms
            this.fetchPlantData(nextProps.editingAssetId)
        }
    }


    componentDidMount() {
        //todo we might want this to happen more than once/more often
        this.props.actions.getPlants(plants => this.setState({plants}));
        this.fetchPlantData(this.props.editingAssetId)
    }

    fetchPlantData(editingAssetId) {
        console.log("fetch all data for this plant for the asset modal")
        if (editingAssetId > 0) {
            this.props.actions.clearPlantData()
            this.props.actions.fetchPlantHistory()
            this.props.actions.fetchServiceHistoryForPlant()
            this.props.actions.fetchPlantImageInfo()
            this.reload();
        }
    }

    reload = (callback, response) => {
        this.props.actions.loadHazards(this.props.editingAssetId, data => {
            const {asset} = this.props
            let assetType = !asset?.polygons || asset.polygons?.length <= 0 ? 'marker' :
                Array.isArray(asset.polygons) ? asset.polygons.find(p => p.chosen).type_polygon : asset.polygons.type_polygon
            let invalidRectangleCoordinates = false
            if(assetType === "rectangle"){
                const polygon = asset.polygons.find(p => p.chosen)
                if(!checkRectangleCoordinates(polygon)){
                    invalidRectangleCoordinates = true
                }
            }
            this.setState({...data, invalidRectangleCoordinates}, ()=>{
                callback && callback(response);
            });

        });
    };

    saveAssetHazard = (resource, callback) => {
        this.props.actions.saveAssetHazard(resource, response => this.reload(callback, response));
    };

    renderPlantMeasurementHistory(assetHistory, editingAssetHistory) {
        return (
            <Panel bsStyle="info" header="Plant History">
                <Table striped bordered condensed hover>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>DBH (in)</th>
                        <th>Height (ft)</th>
                        <th>Spread (ft)</th>
                        <th># Stems</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(assetHistory).map(k => {
                        const h = assetHistory[k]
                        return (
                            <tr key={h.id}>
                                <td>{moment(h.review_date).format("L")}</td>
                                <td>{h.dbh}</td>
                                <td>{h.height}</td>
                                <td>{h.width}</td>
                                <td>{h.stem_count}</td>
                                <td>
                                    <Button
                                        disabled={h.id === this.props.editingAssetHistoryId}
                                        bsStyle="warning"
                                        bsSize="xsmall"
                                        onClick={() => this.props.actions.editAssetHistory(h.id)}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <Button
                    bsStyle="success"
                    onClick={this.props.actions.addBlankAssetHistory}
                    bsSize="xsmall"
                >
                    Add New History
                </Button>
                <br/>
                {editingAssetHistory
                    ? <AssetHistoryForm
                        submitPlantStatsHistory={
                            this.props.actions.submitPlantStatsHistory
                        }
                        updateHistoryAttribute={this.props.actions.updateHistoryAttribute}
                        assetHistory={editingAssetHistory}
                        cancelHistoryEdit={this.props.actions.cancelHistoryEdit}
                    />
                    : null}
            </Panel>
        )
    }

    renderImageList(imageList) {
        return (
            <Panel bsStyle="default" header="Photos">
                {imageList.length === 0 ? "No photos found. Please upload some." : ""}

                {imageList.map(i => {
                    return (
                        <div key={i.id} className="asset_photo_widget">
                            <a
                                href={i.original}
                                title="Open in a new window"
                                target="_blank"
                            >
                                <img
                                    role="presentation"
                                    src={i.mini}
                                    className="img-thumbnail"
                                />
                            </a>
                            <a
                                className="remove_asset_icon"
                                title="Delete this image"
                                onClick={() => this.props.actions.deleteAssetImage(i.id)}
                            >
                <span className="text-danger">
                  <Glyphicon glyph="remove-sign"/>
                </span>
                            </a>
                            &nbsp;
                        </div>
                    )
                })}

                <Dropzone onDrop={this.props.actions.uploadAssetImage}>
                    <div style={{padding: "20px"}}>
                        <p>Drop a photo here or click to select a file to upload.</p>
                    </div>
                </Dropzone>
            </Panel>
        )
    }

    renderServiceHistory(serviceHistory) {
        return (
            <Panel bsStyle="success" header="Services">
                <Table striped bordered condensed hover>
                    <thead>
                    <tr>
                        <th>Service</th>
                        <th>Proposal #</th>
                        <th>Status</th>
                        <th>Proposed</th>
                        <th>Completed</th>
                        <th>Sales Arborist</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {serviceHistory
                        .slice()
                        .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
                        .map(s => {
                            return (
                                <tr key={s.id}>
                                    <td>{s.serviceName}</td>
                                    <td>
                                        <Link to={`/mapview/${s.proposalId}`}>
                                            {s.proposalNo}
                                        </Link>
                                    </td>
                                    <td>{s.serviceStatusName}</td>
                                    <td>{moment(s.serviceProposed).format("L")}</td>
                                    <td>
                                        {s.serviceCompleted
                                            ? moment(s.serviceCompleted).format("L")
                                            : "Not Completed"}
                                    </td>
                                    <td>{s.salesArboristName}</td>
                                    <td><Dollars amount={s.serviceCost}/></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Panel>
        )
    }

    updateAsset = e => {
        e.preventDefault()
        this.props.actions.submitAsset()
        if (this.props.onSuccess) {
            this.props.onSuccess()
        }
    }

    updateAssetAttribute(attr, newVal) {
        const dirty = true //optional param to tell the user to push the button
        this.props.updateAssetAttribute(this.props.asset.id, attr, newVal, dirty) //or this.props.editingAssetId
        //this is a little convoluted because we get updateassetattribute from mapview/actions so there's a high coupling. not sure why we did it that way
    }

    maybeAutoSave = asset => {
        if (
            asset.id === 0 &&
            asset.site_id > 0 &&
            asset.asset_number > 0 &&
            asset.label !== "" &&
            asset.plant_count > 0 &&
            asset.plant_id > 0
        ) {
            console.info("Autosaving new asset", asset)
            this.props.actions.submitAsset()
        }
    }

    selectPlant(plant) {
        const plantId = parseInt(plant.value, 10);
        const plantLabel = plant.label;
        this.updateAssetAttribute("plant_id", plantId);
        this.updateAssetAttribute("plant_name", plantLabel);
        this.maybeAutoSave({...this.props.asset, plant_id: plantId, plant_label: plantLabel});
        //this.props.actions.selectPlant

    }

    closeModal = () => {
        //when the modal is closed/cancelled/dismissed in any way

        //call the requested callback along with the assets site id)
        const {asset} = this.props
        this.props.doneEditingAsset(asset.site_id > 0 ? asset.site_id : undefined) //supply it with the site id passed in so it can refresh them conditinoaly

        this.props.actions.cancelHistoryEdit() //revert any in-progress history edits
    }

    deleteAsset = (asset_id) => {
        const action_confirmed = window.confirm('Do you REALLY want to delete this asset???')

        if (action_confirmed) {
            this.props.actions.deleteAsset(asset_id)
            this.closeModal();
        }
    }

    renderAssetForm(asset) {
        const {invalidRectangleCoordinates} = this.state
        return (
            <form onSubmit={e => this.updateAsset(e)}>
                <Row>
                    <Col xs={1}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>Asset #</ControlLabel>
                            {" "}
                            <Label bsStyle="info">{asset.asset_number}</Label>
                        </FormGroup>
                    </Col>

                    <Col xs={2}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>Label</ControlLabel><br/>
                            <FormControl
                                type="text"
                                placeholder="Label"
                                value={asset.label}
                                onChange={e =>
                                    this.updateAssetAttribute("label", e.target.value)}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={2}>

                        <FormGroup bsSize="sm">
                            <ControlLabel># Plants</ControlLabel><br/>
                            <FormControl
                                type="text"
                                placeholder="Label"
                                value={asset.plant_count}
                                onChange={e =>
                                    this.updateAssetAttribute("plant_count", e.target.value)}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={5}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>Plant</ControlLabel><br/>
                            <Select className="Select" classNamePrefix="select"
                                    name="plant-select"
                                    value={this.state.plants
                                    && (select(this.state.plants[0].options || [], asset.plant_id)
                                        || select(this.state.plants[1].options || [], asset.plant_id))
                                    }
                                    options={this.state.plants}
                                    onChange={this.selectPlant.bind(this)}
                                    clearable={false}
                                    placeholder="Select Plant"
                            />

                        </FormGroup>
                    </Col>

                    <Col xs={2}>
                        <FormGroup bsSize="sm">
                            <Checkbox
                                checked={asset.disabled}
                                onChange={e =>
                                    this.updateAssetAttribute("disabled", e.target.checked)}
                            >
                                Disabled?
                            </Checkbox>
                        </FormGroup>
                    </Col>

                </Row>

                <Row>
                    <Col xs={6}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>Note</ControlLabel><br/>
                            <FormControl
                                componentClass="textarea"
                                placeholder="Note"
                                value={asset.note || ""}
                                onChange={e =>
                                    this.updateAssetAttribute("note", e.target.value)}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={6}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>Location</ControlLabel><br/>
                            <FormControl
                                componentClass="textarea"
                                placeholder="Description"
                                value={asset.description || ""}
                                onChange={e =>
                                    this.updateAssetAttribute("description", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Button
                            bsSize="small"
                            bsStyle={asset.dirty ? "warning" : "success"}
                            disabled={!asset.dirty}
                            type="submit"
                        >
                            {this.props.editingAssetId > 0 ? "Update" : "Add New"} {""} Plant
                        </Button>
                    </Col>
                    {invalidRectangleCoordinates && <Col md={6}>
                        <span className="errorText">Incorrect coordinates for rectangle polygon!</span>
                    </Col>}
                </Row>
            </form>
        )
    }

    renderEditSections(asset) {
        const {hazards, assetHazards} = this.state;
        return (
            <span>
        <hr/>
        <Row>
          <Col md={6}>
        {this.renderPlantMeasurementHistory(
            this.props.assetHistory,
            this.props.editingAssetHistory
        )}
          </Col>
          <Col md={6}>
            <Panel bsStyle="info" header="Hazards">
                                {hazards && <SiteHazardTable saveSiteHazard={this.saveAssetHazard}
                                                             deleteSiteHazard={(resource, callback) => {
                                                                 this.props.actions.deleteAssetHazard(resource, () => {
                                                                     this.reload(callback);
                                                                 });
                                                             }}
                                                             uploadAttachment={this.props.actions.uploadAttachment}
                                                             siteHazards={assetHazards}
                                                             hazards={hazards}
                                                             assetObjectId={asset.id}
                                />}
            </Panel>
          </Col>
        </Row>
        <hr/>
                {this.renderImageList(this.props.imagesList)}
                <hr/>
                {this.renderServiceHistory(this.props.serviceHistory)}
      </span>
        )
    }

    render() {
        const {editingAssetId, asset} = this.props

        if (editingAssetId === null) {
            return <span>No asset is in edit mode...</span>
        }

        if (asset === null || asset === undefined) {
            console.log("Asked to render the asset but its still blank...")
            return <span>Asset is blank still...</span>
        }

        return (
            <Modal
                bsSize="large"
                animation={false}
                show={editingAssetId !== null}
                onHide={this.closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Editing {asset.label} - {asset.plant_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderAssetForm(asset)}
                    {editingAssetId > 0 && this.renderEditSections(asset)}
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            <Button
                                bsStyle="danger"
                                bsSize="small"
                                onClick={() => this.deleteAsset(editingAssetId)}
                                disabled={this.props.serviceHistory.length > 0 ? true : false}
                            >
                                Delete
                            </Button>
                        </Col>
                        <Col md={6} className="text-right">
                            <Button bsSize="small" onClick={this.closeModal}>Close</Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
        )
    }
}

const selectAssetById = (assets, id) => assets.find(a => a.id === id)

//this component is passed a prop that tells us which asset id the thing that opened this modal thinks it wants to edit
//but since we're connected() we can get this as ownprops, as well as look in the store directly. so 3 ways to get teh same data.
//By overwriting it here with the same var name, we're being more declarative of what we're able to use? dunno.
const mapStateToProps = (state, ownProps) => {
    //Like mapview setting editinAssetId, but unlike Proposals, since we can only load up 1 assethistory to edit,
    //just keep track of which one. differerent but similar paradigms.
    return {
        editingAssetId: ownProps.editingAssetId,
        asset: selectAssetById(state.mapView.siteAssets, ownProps.editingAssetId),
        assetHistory: state.assetModal.assetHistory,
        editingAssetHistoryId: state.assetModal.editingAssetHistoryId,
        editingAssetHistory: state.assetModal.assetHistory[
            `plant_stat_history_${state.assetModal.editingAssetHistoryId}`
            ],
        serviceHistory: state.assetModal.serviceHistory,
        imagesList: state.assetModal.imagesInfo,
        updateAssetAttribute: ownProps.updateAssetAttribute,
    }
}

//^^ we could have imported updateAssetAttribute from mapview/actions but we'll let the caller just pass in the function instead

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetEditorModal)
