import React, {useState} from "react";
import {Col, ControlLabel, Glyphicon, Label, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Select from "react-select";
import {defaultDateFormat, select} from "../../../../common/commonHandlers";
import DefaultAnswerFormatter from "../DefaultAnswerFormatter";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import './DateAnswer.scss'
import ColorCheckbox from "../../../Scheduler/ColorCheckbox";

const DateAnswer = ({number, preview, options, question, value, onChange, setAnswerOptions, disabled}) => {
    const [onFocusChange, setOnFocusChange] = useState('')

    const dateOptions = () => {
        const orientationOptions = [
            {value: 'vertical', label: 'Vertical'},
            {value: 'horizontal', label: 'Horizontal'},
        ]

        const anchorDirectionOptions = [
            {value: 'left', label: 'Left'},
            {value: 'right', label: 'Right'},
        ]

        const openDirectionOptions = [
            {value: 'up', label: 'Up'},
            {value: 'down', label: 'Down'},
        ]

        return <Row>
            <Col xs={12}>
                <Label bsStyle="info">Date Picker Orientation</Label>
                <Select className="Select mb-15" classNamePrefix="select"
                        value={select(orientationOptions, options.orientation)}
                        options={orientationOptions}
                        onChange={e => setAnswerOptions({
                            ...options,
                            orientation: e ? e.value : null,
                        })}
                        placeholder="Date Picker Orientation"
                />
            </Col>
            <Col xs={12}>
                <Label bsStyle="info">Anchor Direction</Label>
                <Select className="Select mb-15" classNamePrefix="select"
                        value={select(anchorDirectionOptions, options.anchorDirection)}
                        options={anchorDirectionOptions}
                        onChange={e => setAnswerOptions({
                            ...options,
                            anchorDirection: e ? e.value : null,
                        })}
                        placeholder="Anchor Direction"
                />
            </Col>
            <Col xs={12}>
                <Label bsStyle="info">Open Picker Direction</Label>
                <Select className="Select mb-15" classNamePrefix="select"
                        value={select(openDirectionOptions, options.openDirection)}
                        options={openDirectionOptions}
                        onChange={e => setAnswerOptions({
                            ...options,
                            openDirection: e ? e.value : null,
                        })}
                        placeholder="Open Picker Direction"
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.showPicker}
                               label="Show Picker"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   showPicker: !options.showPicker,
                               })}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.required}
                               className="mb-35"
                               label="Answer will be required"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   required: !options.required
                               })}
                />
            </Col>
        </Row>
    }

    const dateAnswers = () => {
        return <div id="date-answer-type">
            <ControlLabel className="bold">
                <span>{number}. {question}</span>
                {options.required && <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip id={`tooltip-refresh`}>Answer is required</Tooltip>}
                >
                    <Glyphicon glyph='exclamation-sign' className="ml-6 font14"/>
                </OverlayTrigger>}
            </ControlLabel>
            <div>
                <DateRangePicker
                    startDate={value?.startDate && moment(value?.startDate) || ""}
                    startDateId={'P From'}
                    endDate={value?.endDate && moment(value?.endDate) || ""}
                    endDateId={'P To'}
                    keepOpenOnDateSelect={true}
                    reopenPickerOnClearDate={true}
                    onDatesChange={({startDate, endDate}) => {
                        onChange({
                            startDate: moment(startDate).format(defaultDateFormat),
                            endDate: moment(endDate).format(defaultDateFormat)
                        })
                        if (startDate && endDate) {
                            setOnFocusChange(null)
                        }
                    }}
                    focusedInput={onFocusChange}
                    onFocusChange={(focusedInput) => setOnFocusChange(focusedInput)}
                    isOutsideRange={() => { return false}}
                    displayFormat={defaultDateFormat}
                    orientation={options.orientation}
                    anchorDirection={options.anchorDirection}
                    openDirection={options.openDirection}
                    withPortal={!options.showPicker}
                    appendToBody={true}
                    disabled={disabled}
                />
            </div>
        </div>
    }
    return preview ?
        <DefaultAnswerFormatter options={dateOptions()} preview={dateAnswers()}/>
        :
        dateAnswers()
}

export default DateAnswer;