import React from 'react';
import PropTypes from "prop-types"
import "./SchedulerEventDialog.css";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, FormControl, FormGroup, Glyphicon, MenuItem, Modal, Nav, NavDropdown, Row} from "react-bootstrap";
import * as actions from "./SchedulerEventDialogApi"
import * as findTimeApi from "../LeadsPage/FindTimeApi";
import {addressesFormater} from "../LeadsPage/FindTimeApi";
import {isAssignedArborist} from "../AssignedArboristModal/AssignedArboristModalApi"
import './Pane.css'
import Dollar from "../../components/Dollars";
import Select from "react-select"
import InlineEditable from "./InlineEditable";
import Datetime from "react-datetime";
import SplitPane from 'react-split-pane';
import {
    defaultDateFormat,
    defaultMapParams,
    mapEvents,
    mapForSelect,
    mapPersonSearch,
    reverseColor,
    round,
    select,
    updateExternalLinks
} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import createApiService from "../../common/clientActionsBuilder";
import {Link} from "react-router";
import {LinkContainer} from "react-router-bootstrap"
import ColorCheckbox from "./ColorCheckbox";
import {showEmailDialog} from "../../components/email/actions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {debounce} from "throttle-debounce";
import moment from "moment";
import {getBigIcon, getColorIcon} from "../MapViewPage/IconProvider";
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import '@fullcalendar/bootstrap/main.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import AddFollowUpModal from './AddFollowUpModal';
import {downloadPdf, downloadWOPdf} from "../CustomerViewPage/actions";
import AsyncSelect from "react-select/lib/Async";
import EventContent from "./Components/EventContent";
import CrewMessages from "./CrewMessages/CrewMessages";
import RateModal from '../MapViewPage/new/RateModal'
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import AssignedArboristModal from "../AssignedArboristModal/AssignedArboristModal";
import ConsumablesConsumptionModal from "../../common/ConsumablesConsumptionModal";
import {updateEmployeeColor} from "./SchedulerApi";

let schedulerEventsActions = createApiService('scheduler_events', 'scheduler_event', 'Scheduler events');

const api = {
    ...schedulerEventsActions,
    ...actions,
    isAssignedArborist,
    showEmailDialog,
    downloadPdf,
    downloadWOPdf,
    updateEmployeeColor
};

const iPad = window.innerWidth >= 768 && window.innerHeight === 1024;
const isMobile = window.innerWidth <= 768;
const screen30 = window.innerWidth >= 1024 && window.innerHeight <= 700;
const screen32 = window.innerWidth >= 1024 && window.innerHeight <= 600;
const height1500 = window.innerWidth >= 1024 && window.innerHeight > 1500;
const smMobile = window.screen.width <= 450;
const xsMobile = window.screen.width <= 350;
let isLoading = false;

class SchedulerEventDialog extends ResourceComponent {
    calendarDialogComponentRef = React.createRef();

    period = {dayGridMonth: 'months', timeGridWeek: 'weeks', timeGridDay: 'days'};
    state = {
        resource: {loaded: false},
        loaded: true,
        schedulerEventTypes: [],
        employees: [],
        events: [],
        defaultView: 'timeGridWeek',
        start: moment(),
        end: moment(),
        scrollTime: '08:00:00',
        modalEvent: null,
        customerSearchResults: [],
        emailTemplateShow: false,
        AddFollowUpModalShow: false,
        useCustomLocation: false,
        midDate: moment(),
        isTitleFieldActive: false,
        selectedEmployees: [],
        guestsColors: [],
        prevUrl: '',
        showDisabledUsers: false,
        isCompletedModalOpened: false,
        completedModalType: '',
        showAssignedArboristModal: false,
        isSaveInProgress: false,
        consumableConsumptionModal: {
            show: false,
            psIds: [],
            proposalServices: {}
        },
        downloadInProgress: false,
    };

    mapResourceToEvent = (resource) => {
        const {contextResource, customLocation} = this.state;
        const date_from = moment(resource.date_from).format();
        const date_to = moment(resource.date_to).format();
        return {
            start: moment(date_from).format(),
            end: moment(date_to).format(),
            allDay: false,
            date_from, date_to,
            person_id: resource.person_id,
            site_latitude: resource.location_latitude ? resource.location_latitude : contextResource && contextResource.site_latitude,
            site_longitude: resource.location_longitude ? resource.location_longitude : contextResource && contextResource.site_longitude,
            title: '<b class="new-event">Current Event</b>',
            highlighted: true,
            target: true,
            site_zip: resource.site_zip
        };
    };


    componentWillMount() {
        this.setState({prevUrl: window.location.href})
    }

    componentDidMount() {
        const {showDisabledUsers} = this.state;
        this.props.save && this.props.save(this.save);
        this.delayedViewRender = debounce(500, this.viewRender);
        this.props.actions.getUserDisabledStatus(({show_disabled_users}) => this.setState({showDisabledUsers: show_disabled_users}))
        const {clientId} = this.props;
        this.props.actions.load(clientId, result => {
            const customerSearchResults = result.customerSearchResults.map(customer => {
                const mappedCustomer = {...customer}
                if (customer.sites.length === 0) {
                    mappedCustomer.addresses = addressesFormater(mappedCustomer)()
                }
                return mappedCustomer
            })
            this.setState({...result, customerSearchResults}, () => {
                this.loadEvent()
            });
        });
        this.guestSearch = this.buildSearchComponent(
            'scheduler_event_guests',
            this.props.actions.search,
            mapPersonSearch
        );
        this.selectEvent = this.selectMarker('events', (e, x) => (e.id === x.id && e.person_id === x.person_id), null, true, true);
    }

    loadEvent() {
        const {client, event} = this.props;
        const primaryUrl = new URL(window.location.href).origin
        let descriptionAddProposal = ""

        if (event.id) {
            event.id = parseInt(event.id);
            this.props.actions.loadEvent(event.id, event => {
                const betaEvent = event.description && event.description.includes("https://beta.treehub.me")
                const prodEvent = event.description && event.description.includes("https://app.treehub.me")

                if (primaryUrl === "https://app.treehub.me" && betaEvent) {
                    event.description = event.description.replace("https://beta.treehub.me", primaryUrl)
                } else if (primaryUrl === "https://beta.treehub.me" && prodEvent) {
                    event.description = event.description.replace("https://app.treehub.me", primaryUrl)
                }

                const descriptionProposalNo = `<br/>Proposal: <a href=\"${primaryUrl + `/#/mapview/${event.proposal_id}`}\" target=\"_blank\">${event.proposal_no}</a><br/>`

                if (event.customer_id && event.lead_id) {
                    descriptionAddProposal = `<br>Proposal: <a href=\"${primaryUrl + `/mapview?customer_id=${event.customer_id}&lead_id=${event.lead_id}`}\" target="_blank">Add</a>`;
                }
                if (event.proposal_id && event.description.indexOf(descriptionAddProposal) > -1) {
                    event.description = event.description.replace(descriptionAddProposal, descriptionProposalNo)
                }
                if (!event.site_id && event.description) {
                    event.description = event.description.replace(descriptionAddProposal, " ")
                }

                if (event.location_address) {
                    let customLocation = {};
                    customLocation.longitude = event.location_longitude;
                    customLocation.latitude = event.location_latitude;
                    customLocation.value = event.location_address;
                    customLocation.label = event.location_address;
                    this.setState({customLocation: customLocation, useCustomLocation: true}, () => {
                        this.reload(event, client);
                    })
                } else {
                    this.selectSite(event.site_id);
                    this.reload(event, client);
                }
            });
        } else {
            this.reload(event, client);
        }
    }

    loadEvents = (callback) => {
        let {scheduler_event_guests} = this.state.resource;
        const {start, end, resource, defaultView, contextResource, view} = this.state;
        const {inModal} = this.props;

        if (!inModal) {
            if (resource.person_id) {
                scheduler_event_guests.push(resource.person_id)
                scheduler_event_guests = scheduler_event_guests && scheduler_event_guests.length > 0 && scheduler_event_guests.filter((g, i, arr) => arr.indexOf(g) === i)
            }

            if (scheduler_event_guests.length > 0) {
                let from;
                let to;

                if (defaultView === 'timeGridDay') {
                    from = moment(start).startOf('day').format();
                    to = moment(start).add(1, 'day').startOf('day').format()
                } else {
                    from = moment(start).startOf('day').format();
                    to = moment(end).subtract(1, "days").format();
                }

                this.props.actions.loadEvents(
                    {
                        calendar_view_type: view?.type,
                        scheduler_event_guests,
                        from,
                        to,
                    }, result => {
                        let events = result.map(mapEvents);
                        const newEventDate = this.changeEventDateTime(events)

                        if (resource.id) {
                            events = events.filter(x => x.id !== resource.id);
                        }
                        const selectedCustomer = this.state.customerSearchResults.find(x => x.id === resource.customer_id);
                        const new_event_site_zip = selectedCustomer.sites.length > 0 ? selectedCustomer.sites.find(s => s.id == contextResource.site_id)?.zip : selectedCustomer.addresses.find(a => a.name == resource.full_address)?.zip;
                        const updatedResource = {
                            ...resource,
                            site_zip: new_event_site_zip,
                            date_from: moment(newEventDate.date_from),
                            date_to: moment(newEventDate.date_to)
                        }
                        events.push(this.mapResourceToEvent(updatedResource));
                        events = events.map(e => {
                            if (e.site_zip === new_event_site_zip) {
                                e.highlighted = true
                                return e
                            } else {
                                e.highlighted = false
                                return e
                            }
                        })
                        this.setState({events: events, loaded: false, resource: updatedResource}, callback);
                    });
            }
        } else {
            let events = [];
            events.push(this.mapResourceToEvent(resource));
            this.setState({events}, callback);
        }
    };


    changeEventDateTime = (events) => {
        let {date_from, date_to} = this.state.resource;
        const {resource} = this.state;

        const todaysEvent = events.filter(x => moment(x.start).format(defaultDateFormat) === moment(date_from).format(defaultDateFormat)).sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())

        const initialTimeDiff = moment.duration(moment(date_to).diff(moment(date_from))).asMinutes()
        let timeDuration = resource.timeDiff ? resource.timeDiff : initialTimeDiff

        let current_date_from = moment(date_from).format();
        let current_date_to = moment(date_to).format();

        if (todaysEvent && todaysEvent.length > 0) {
            current_date_from = round(moment(resource.user_selected_date_from)).format();
            current_date_to = moment(current_date_from).add(timeDuration ? timeDuration : 60, "minutes").format();

            todaysEvent.map(e => {
                if ((current_date_from >= e.start && current_date_from < e.end) ||
                    (current_date_to > e.start && current_date_to <= e.end) ||
                    (current_date_from <= e.start && current_date_to >= e.end)) {
                    current_date_from = moment(e.end).format()
                    current_date_to = moment(e.end).add(timeDuration ? timeDuration : 60, "minutes").format()
                }
            })

        } else {
            current_date_from = round(moment(resource.user_selected_date_from)).format();
            current_date_to = moment(current_date_from).add(timeDuration ? timeDuration : 60, "minutes").format();
        }

        return {date_from: current_date_from, date_to: current_date_to}
    }

    onSave = () => {
        if (this.state.emailTemplateShow) {
            this.props.emailDialogShow && this.props.emailDialogShow()
        }
        let {
            id,
            date_from,
            date_to,
            person_id,
            scheduler_event_resource_id,
            scheduler_event_type_id,
            all_day,
            description,
            scheduler_event_guests,
            name,
            created_by_person_id,
            customer_id,
            site_id,
            proposal_title,
            send_confirmation,
            url,
            address_id
        } = this.state.resource;
        if (all_day) {
            let UTCFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]'; //ignore timezone
            date_from = moment(date_from).format(UTCFormat)
            date_to = moment(date_from).add(1, 'day').format(UTCFormat)
        }
        date_from = date_from && moment(date_from).utc().format();
        date_to = date_to && moment(date_to).utc().format();
        const customLocation = this.state.customLocation;
        const useCustomLocation = this.state.useCustomLocation;
        const primaryUrl = new URL(window.location.href).origin
        const work_order_no = this.state.contextResource?.work_order_no
        const full_address = this.state.customerSearchResults.find(c => c.id === customer_id)?.addresses?.find(a => a.id === address_id)?.name || null

        url = primaryUrl

        scheduler_event_guests = [...new Set(scheduler_event_guests)]


        this.props.actions.save({
            id,
            date_from,
            date_to,
            person_id,
            scheduler_event_resource_id,
            scheduler_event_type_id,
            all_day,
            description: updateExternalLinks("prod", description),
            scheduler_event_guests,
            name,
            created_by_person_id,
            customer_id,
            site_id,
            customLocation,
            useCustomLocation,
            proposal_title,
            send_confirmation,
            url,
            work_order_no,
            full_address
        }, this.props.handleClose);
    };

    save = () => {
        const {customer_id, person_id, scheduler_event_type_resource, id, scheduler_event_guests} = this.state.resource
        const {isSaveInProgress, initialPersonId} = this.state

        if (!isSaveInProgress) {
            this.setState({isSaveInProgress: true}, () => {
                if (!customer_id || (id && person_id === initialPersonId)) {
                    this.onSave()
                } else {
                    this.props.actions.isAssignedArborist({
                        id: customer_id,
                        arboristId: person_id,
                        eventType: scheduler_event_type_resource,
                        scheduler_event_guests
                    }, (result) => {
                        if (result) {
                            this.onSave()
                        } else {
                            this.setState({showAssignedArboristModal: true})
                        }
                    })
                }
            })
        }
    };

    search = e => {
        e !== '' && this.props.actions.searchForCustomer(e, customerSearchResults => {
            this.props.findTimeApi.getAddressCoordination({customerSearchResults: customerSearchResults}, () =>
                this.setState({customerSearchResults: customerSearchResults}))
        })
    };

    generateName = () => {
        const {resource, contextResource, schedulerEventTypes} = this.state;
        const type = select(mapForSelect(schedulerEventTypes), resource.scheduler_event_type_id);
        let result = type.label;
        if (contextResource) {
            result += ': ' + contextResource.customer_contact;
            if (contextResource.proposal_title) {
                result = contextResource.proposal_title;
            }
        }
        return result;
    };

    generateDescription = () => {
        const {resource, employees, contextResource} = this.state;
        const employeeOptions = employees.filter(x => x.label !== 'Not assigned');
        const primaryUrl = new URL(window.location.href).origin
        let result = '';
        let url;

        if (primaryUrl === "https://app.treehub.me" || primaryUrl === "https://beta.treehub.me") {
            url = "https://app.treehub.me"
        } else {
            url = primaryUrl
        }

        if (contextResource) {
            result = `Email: <a href=\"mailto:${contextResource.email_address}\">${contextResource.email_address}</a><br/>`;
            result += contextResource.phone_number && `Phone: <a href=\"tel:${contextResource.phone_number}\">${contextResource.phone_number}</a><br/>`;
            if (contextResource.customer_created_at) {
                result += `Customer created: ${moment(contextResource.customer_created_at).format(defaultDateFormat)}<br/>`
            }
            result += `<a href=\"${url + `/customer/info/${contextResource.customer_id}`}\">${contextResource.customer_name}</a><br/>`;
            if (contextResource.communication_preference && contextResource.communication_preference !== null) {
                result += `Communication preference: ` + contextResource.communication_preference + `<br/>`
            }
        }
        const person = select(employeeOptions, resource.created_by_person_id);
        result += `Created by: ${person && person.label}`;
        return result;
    };

    updateNameAndDescription = () => {
        const {resource} = this.state;
        if (!resource.id) {
            resource.name = this.generateName();
            resource.description = this.generateDescription();
            this.setState({resource});
        }
        this.updateEditorState(resource.description);
    };

    formatPeriod = (event, color) => {
        return <span>
            <b><h4 style={{color: color}} className={'no-margin inline-block'}>
                {moment(event.date_from).format('dddd, MMMM DD')}
            </h4></b>

            <h4 style={{color: color}}
                className={'inline-block timeScheduler ml-12'}><span>{moment(event.date_from).format('LT')} {event.date_to && `- ${moment(event.date_to).format('LT')}`}</span></h4>
        </span>
    };

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(500, this.search);
    }

    delete = () => {
        this.props.actions.remove({id: this.state.resource.id}, this.props.handleClose);
    };

    updateStatus = () => {
        this.props.actions.get(this.state.resource.id, result => this.setState({
                resource: {
                    ...this.state.resource,
                    status: result.status,
                    confirmed: result.confirmed,
                    completed: result.completed
                },
                isCompletedModalOpened: false,
                completedModalType: ''
            })
        );
    };

    completeAllServices = () => {
        let {work_order_proposal_services} = this.state.contextResource
        if (work_order_proposal_services && work_order_proposal_services.length > 0) {
            this.props.actions.loadManyConsumables(work_order_proposal_services.map(ps => ps.proposal_service_id), res => {
                if (res.consumables && res.consumables.length > 0) {
                    this.setState({
                        consumableConsumptionModal: {
                            ...this.state.consumableConsumptionModal,
                            psIds: res.consumables.map(e => +e.proposal_service_id).filter((value, index, array) => {
                                return array.indexOf(value) === index
                            }),
                            show: true,
                            proposalServices: work_order_proposal_services
                        },
                        isCompletedModalOpened: false,
                    })
                } else {
                    this.props.actions.completeAllServices({id: this.state.resource.id}, this.updateStatus);
                }
            })
        } else {
            this.props.actions.completeAllServices({id: this.state.resource.id}, this.updateStatus);
        }
    };

    toggleComplete = () => {
        this.props.actions.markAsCompleted({id: this.state.resource.id}, this.updateStatus);
    };

    toggleConfirmed = () => {
        this.props.actions.markAsConfirmed({id: this.state.resource.id}, this.updateStatus);
    };

    toggleOnSite = (e) => {
        this.setState({resource: {...this.state.resource, on_site: e}}, () => {
            this.props.actions.markAsOnSite({id: this.state.resource.id}, this.updateStatus)
        })
    };

    handleDateChange = name => e => {
        const {resource, start, end} = this.state;
        this.dateResourceAttr(name)(e);
        let timeDuration;

        const timeDiff = moment.duration(moment(resource.date_to).diff(moment(resource.date_from))).asMinutes()
        const initialTimeDiff = moment.duration(moment(end).diff(moment(start))).asMinutes()

        timeDuration = resource.timeDiff ? resource.timeDiff : initialTimeDiff

        if (name === 'date_from') {
            resource.date_to = moment(resource.date_from).add(timeDuration ? timeDuration : 60, "minutes")
        } else if (name === 'date_to') {
            resource.timeDiff = timeDiff
        }
        if (timeDuration === 1440 && resource.all_day) {
            this.setState({resource: {...this.state.resource, all_day: true}});
        } else {
            this.setState({resource: {...this.state.resource, all_day: false}});
        }
    };

    setEmailRecipients = (value) => {
        let baseValue = null
        const {
            contactEmail,
            contactEmail2,
            secondContactEmail,
            secondContactEmail2,
            additionalContactEmails,
            additionalContactEmails2
        } = value
        const primarySiteContactEmails = (contactEmail && contactEmail2) ? `${contactEmail}, ${contactEmail2}` : contactEmail
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

        baseValue = `${primarySiteContactEmails || ""}`

        if (secondarySiteContactEmails) baseValue += `, ${secondarySiteContactEmails}`
        if (additionalContactEmails?.length > 0) baseValue += `, ${additionalContactEmails.toString()}`
        if (additionalContactEmails2?.length > 0) baseValue += `, ${additionalContactEmails2.toString()}`

        return baseValue
    }

    emailCustomer = (e) => {
        e.stopPropagation();
        const {resource, contextResource} = this.state;
        const {email} = this.props;

        let recipientEmail;
        recipientEmail = this.setEmailRecipients(contextResource)

        this.props.actions.showEmailDialog(
            {
                emailType: "scheduler_event",
                referenceId: resource.id,
                recipient: recipientEmail,
                defaultEmail: email,
                withFollowUpDate: false,
                cc_recipients: [{email_address: contextResource?.sales_arborist_email ? contextResource.sales_arborist_email : ''}]
            });
    };

    emailEmployee = employee => {
        const {resource, contextResource} = this.state;
        const {email} = this.props;
        this.props.actions.showEmailDialog(
            {
                emailType: "scheduler_event",
                referenceId: resource.id,
                recipient: employee?.email,
                defaultEmail: email,
                withFollowUpDate: false,
                cc_recipients: [{email_address: contextResource?.sales_arborist_email ? contextResource.sales_arborist_email : ''}]
            });
    };

    viewRender = () => {
        const {view} = this.state;
        const {start, end} = this.state;
        const differentStart = !moment(view.activeStart).isSame(start);
        const differentEnd = !moment(view.activeEnd).isSame(end);
        if ((differentStart || differentEnd)) {
            this.setState({
                start: moment(view.activeStart),
                end: moment(view.activeEnd),
                defaultView: view.type
            }, () => this.loadEvents());
        }
    };
    getColor = e => {
        const guestOptions = this.guestSearch ? this.guestSearch.searchOptions() : [];
        const guest = select(guestOptions, e.person_id);
        let employee = select(this.state.employees, e.person_id);
        let result = '#3a87ad';
        if (guest && guest.color) {
            result = guest.color;
        } else if (employee) {
            result = employee.color
        }
        return result;
    };

    selectSite = (e) => {
        const {customerSearchResults, resource} = this.state
        const customer = customerSearchResults.find(c => c.id === resource.customer_id)
        this.selectResourceAttr(customer?.sites?.length > 0 ? 'site_id' : 'address_id', () => {
            if (e) {
                if (customer?.sites?.length > 0) {
                    this.props.actions.loadSite(e.value || e, site => {
                        if (site?.message !== "Site not found") {
                            this.setState({
                                site,
                                assets: site?.assets,
                                resource: {
                                    ...this.state.resource,
                                    site_id: site.id,
                                    site_latitude: site.latitude,
                                    site_longitude: site.longitude
                                }
                            });
                        }
                    })
                } else {
                    const selectedAddress = customer?.addresses.find(c => c.id === (e.value ? e.value : resource.address_id))
                    if (selectedAddress) {
                        this.setState({
                            selectedAddress,
                            resource: {
                                ...this.state.resource,
                                address_id: selectedAddress.id,
                                site_latitude: selectedAddress.latitude,
                                site_longitude: selectedAddress.longitude

                            }
                        })
                    }
                }
            } else {
                this.setState({
                    site: null,
                    assets: [],
                    resource: {...resource, full_address: null, site_latitude: null, site_longitude: null}
                });
            }
        })(e);
    };

    reload(event, client) {
        const {schedulerEventTypes, resource, person, employees} = this.state;
        const eventTypeResource = event.scheduler_event_type_resource || null;
        const eventType = schedulerEventTypes.find(s => s.resource === eventTypeResource);
        this.setState({schedulerEventTypes: schedulerEventTypes.filter(s => s.resource === eventTypeResource)});
        const guests = event.scheduler_event_guests || [];
        const guestOptions = mapPersonSearch(guests);
        event.scheduler_event_guests = guests.map(x => x.id);
        if (guestOptions && guestOptions.length === 0) {
            const creator = employees.find(e => e.value === event.person_id);
            if (creator) {
                guestOptions.push(creator);
                event.scheduler_event_guests.push(creator.value);
            }
        }
        this.guestSearch.initTempOptions(guestOptions);
        const mergedResource = {
            ...resource,
            ...event,
            send_confirmation: !event.id && client.scheduler_email_on_save
        };

        if (!event.id) {
            mergedResource.created_by_person_id = person.id;
            mergedResource.scheduler_event_type_id = eventType.id;
        }
        if (!mergedResource.date_from) {
            mergedResource.date_from = round(moment());
            mergedResource.date_to = moment(mergedResource.date_from).add(1, 'hour');
        }
        mergedResource.date_from = mergedResource.date_from && moment(mergedResource.date_from);
        mergedResource.date_to = mergedResource.date_to && moment(mergedResource.date_to);

        if (event.scheduler_event_resource_id && eventType.resource === 'WorkOrder') {
            this.props.actions.loadWorkOrder(event.scheduler_event_resource_id, result => {
                this.setState({
                    ...result,
                    resource: {
                        ...mergedResource,
                        loaded: true,
                        customer_id: result.contextResource.customer_id,
                        site_id: result.contextResource.site_id
                    }
                }, this.updateNameAndDescription);
            });
        } else if (event.scheduler_event_resource_id && eventType.resource === 'Lead') {
            this.props.actions.loadLead(event.scheduler_event_resource_id, result => {
                this.setState({
                    ...result,
                    resource: {
                        ...mergedResource,
                        loaded: true,
                        customer_id: result.contextResource.customer_id,
                        site_id: result.contextResource.site_id,
                        full_address: result.contextResource.full_address,
                        site_latitude: result.contextResource.site_latitude,
                        site_longitude: result.contextResource.site_longitude,
                    }
                }, this.updateNameAndDescription);
            });
        } else if (mergedResource.customer_id) {
            this.props.actions.getCustomer(mergedResource.customer_id, customer => {
                if (customer && customer.customerSearchResults && customer.customerSearchResults[0].sites.length === 0) {
                    customer.customerSearchResults[0].addresses = this.props.findTimeApi.addressesFormater(customer.customerSearchResults[0])
                }
                const cs = customer.customerSearchResults[0]
                let customerAddress = {}
                let customerSite = {}
                if (cs.sites.length === 0) {
                    customerAddress = cs.addresses.find(a => a.name === mergedResource.full_address)
                } else {
                    customerSite = cs.sites.find(s => s.id === mergedResource.site_id)
                }
                this.setState({
                    customerSearchResults: customer.customerSearchResults,
                    resource: {
                        ...mergedResource,
                        address_id: customerAddress?.id,
                        site_latitude: customerSite?.latitude ? customerSite.latitude : customerAddress?.latitude,
                        site_longitude: customerSite?.longitude ? customerSite.longitude : customerAddress?.longitude,

                    }
                })
            });
            mergedResource.loaded = true;
        } else {
            mergedResource.loaded = true;
        }
        this.setState({
            initialPersonId: event.person_id,
            resource: mergedResource,
            customLocation: this.state.useCustomLocation ? this.state.customLocation : null,
            eventType,
            start: mergedResource.date_from,
            end: mergedResource.date_to || mergedResource.date_from
        }, this.updateNameAndDescription);
    }

    emailTemplate = () => {
        this.setState({emailTemplateShow: !this.state.emailTemplateShow})
    }

    loadOptions = (e, callback) => {
        this.props.actions.geocodeAddress(e, result => {
            let addresses = [];
            result && result.length > 0 && result.forEach(result => {
                let address = {};
                address.longitude = result.lng;
                address.latitude = result.lat;
                address.value = result.formatted_address;
                address.label = result.formatted_address;
                addresses.push(address);
            })
            callback(addresses);
            this.setState({addresses: addresses})
        })
    }

    toggleTitleInput = (value) => {
        this.setState({isTitleFieldActive: value})
    }

    timeConverter = (seconds) => {
        let duration = seconds;
        let hours = duration / 3600;
        duration = duration % (3600);

        let min = parseInt(duration / 60);
        duration = duration % (60);

        let sec = parseInt(duration);

        if (sec < 10) {
            sec = `0${sec}`;
        }
        if (min < 10) {
            min = `0${min}`;
        }

        if (parseInt(hours, 10) > 0) {
            if (min !== '00') {
                return `${parseInt(hours, 10)}hr ${parseFloat(min)}min`
            } else {
                return (`${parseInt(hours, 10)}hr`)
            }
        }
        return (`${min} : ${sec}`)
    }

    renderHeader() {
        const {
            resource,
            contextResource,
            employees,
            customers,
            center,
            customLocation,
            schedulerEventTypes,
            customerSearchResults,
            useCustomLocation,
            isTitleFieldActive
        } = this.state;
        const {inModal} = this.props;
        const schedulerEventTypesOptions = mapForSelect(schedulerEventTypes);
        const customerOptions = mapForSelect(customerSearchResults);
        let customer = null;
        if (resource.customer_id) {
            customer = select(customerOptions, resource.customer_id);
        }

        const color = reverseColor(this.getColor(resource))

        let {events} = this.state;
        events = events.map(e => {
            const color = this.getColor(e);
            return {
                ...e,
                backgroundColor: e.highlighted ? '#f8e500' : color,
                borderColor: e.highlighted ? '#f8e500' : color,
                textColor: e.selected ? 'black' : 'white',
            };
        });
        const eventsOnMap = [{
            latitude: this.state.useCustomLocation && customLocation ? customLocation.latitude : (resource.location_latitude ? resource.location_latitude : contextResource ? contextResource.site_latitude : (resource.site_latitude ? resource.site_latitude : 40)),
            longitude: this.state.useCustomLocation && customLocation ? customLocation.longitude : (resource.location_longitude ? resource.location_longitude : contextResource ? contextResource.site_longitude : (resource.site_longitude ? resource.site_longitude : -100)),
        }];
        const mailTo = contextResource && resource.id && `mailto:${contextResource.email_address}`;
        const phoneTo = contextResource && resource.id && `tel:${contextResource.phone_number}`;
        const employee = select(employees, this.state.person.id);
        const employeeColor = (employee ? employee.color : '#3a87ad');
        const selectedCustomer = customerSearchResults.find(x => x.id === resource.customer_id);
        const sites = (resource.customer_id && selectedCustomer) ? selectedCustomer.sites : [];
        const addresses = (resource.customer_id && selectedCustomer) ? selectedCustomer.addresses : [];
        const sitesOptions = mapForSelect(sites.length > 0 ? sites : addresses);
        const addressId = resource.address_id && addresses.find(a => a.id === resource.address_id)?.id
        const site = sites.length > 0 ? select(sitesOptions, resource.site_id) : select(sitesOptions, addressId);

        let duration;

        if (resource.date_to && resource.date_from) {
            const timeDiff = moment.duration(moment(resource.date_to).diff(moment(resource.date_from)), 'milliseconds')
            const hr = Math.floor(timeDiff.asHours());
            const min = Math.floor(timeDiff.asMinutes()) - hr * 60;
            if (isNaN(hr)) {
                duration = 'Invalid date'
            } else {
                duration = ` ${hr} hr ${min} min`.replace(' 0 hr', '').replace(' 0 min', '')
            }
        }

        if (resource?.full_address !== null && contextResource?.site_latitude === null) {
            const {address_coordination} = this.props.event
            this.setState({
                contextResource: {
                    ...contextResource,
                    site_latitude: address_coordination && address_coordination[0]?.latitude,
                    site_longitude: address_coordination && address_coordination[0]?.longitude
                }
            })
        }

        return <Row className={`no-padding`} style={{color: `${color}!important`}}>
            <Col
                md={(window.location.href.indexOf(`lead/`) > -1 && window.location.href.indexOf(`/time`) > -1) ? 12 : 6}
                style={{marginBottom: isMobile && 20}}>
                <Row style={{color: `${color}!important`}} className={isMobile && `no-padding`}>
                    <Col className="text-left" xs={12}>
                        <Row className={`vcenter`}>
                            <Col style={{color}} xs={2} className="text-center">
                                <Glyphicon glyph="time" className="big"/>
                            </Col>
                            <Col xs={10} className={isMobile && "padding10LR"}>
                                {isMobile ?
                                    <Row className="vcenter no-margin" style={{flexDirection: smMobile && 'column'}}>
                                        <Col xs={smMobile ? 12 : 6}

                                             className={`no-padding ${isMobile && 'small-right-padding'}`}
                                             style={{marginBottom: smMobile && 8}}>
                                            <Datetime
                                                value={resource.date_from}
                                                onChange={this.handleDateChange('date_from')}/>
                                        </Col>
                                        <Col xs={smMobile ? 12 : 6}
                                             className={`no-padding ${isMobile && 'small-left-padding'}`}>
                                            <Datetime
                                                value={resource.date_to}
                                                onChange={this.handleDateChange('date_to')}/>
                                        </Col>
                                    </Row> : <InlineEditable
                                        color={color}
                                        value={resource.date_from && this.formatPeriod(resource, color)}
                                        form={() =>
                                            <Row className="vcenter">
                                                <Col xs={6}
                                                     className={`no-padding ${isMobile && 'small-right-padding'}`}
                                                     style={{marginBottom: smMobile && 8}}>
                                                    <Datetime
                                                        value={resource.date_from}
                                                        onChange={this.handleDateChange('date_from')}/>
                                                </Col>
                                                <Col xs={6}
                                                     className={`no-padding ${isMobile && 'small-left-padding'}`}>
                                                    <Datetime
                                                        value={resource.date_to}
                                                        onChange={this.handleDateChange('date_to')}/>
                                                </Col>
                                            </Row>
                                        }
                                    />}
                            </Col>
                        </Row>
                        <Row className={`vcenter hcenter`}>
                            <Col xs={2} style={{color}} className="text-center">
                                {customer ? <Link to={`/customer/info/${customer.value}`} target='_blank'
                                                  style={{color}}><Glyphicon glyph="user" className="big"/></Link> :
                                    <Glyphicon glyph="user" className="big"/>}
                            </Col>
                            <Col xs={10} className={isMobile ? "padding10LR" : !isMobile ? "mt5" : null}>
                                {isMobile ? <div className={'top10 bottom10'}><Select className="Select"
                                                                                      st classNamePrefix="select"
                                                                                      isClearable
                                                                                      value={customer}
                                                                                      options={customerOptions}
                                                                                      placeholder="Customer"
                                                                                      onInputChange={this.delayedSearch}
                                                                                      onChange={
                                                                                          this.selectResourceAttr('customer_id', () => {
                                                                                                  const {customer_id} = this.state.resource;
                                                                                                  const selectedCustomer = customerSearchResults.find(x => x.id === customer_id);
                                                                                                  const eventType = schedulerEventTypes.find(x => x.id === resource.scheduler_event_type_id)
                                                                                                  if (selectedCustomer != null) {
                                                                                                      if (resource && eventType) {
                                                                                                          resource.name = `${eventType.name}: ${selectedCustomer.name}`
                                                                                                      }
                                                                                                      const selectSite = selectedCustomer.sites.length === 1;
                                                                                                      if (selectSite) {
                                                                                                          this.selectSite((mapForSelect(selectedCustomer.sites)[0]));
                                                                                                      } else {
                                                                                                          this.selectSite(null);
                                                                                                      }
                                                                                                  }
                                                                                              }
                                                                                          )
                                                                                      }
                                />
                                    <div>
                                            <span>
                                                <small><a
                                                    style={{color}}
                                                    href={phoneTo}>{contextResource && contextResource.phone_number && contextResource.phone_number}</a></small>
                                                {' '}
                                                <small><a
                                                    style={{color}}
                                                    href={mailTo}>{contextResource && contextResource.email_address && contextResource.email_address}</a></small>
                                            </span>
                                        <Link onClick={this.emailCustomer}>
                                            <Glyphicon
                                                style={{color}}
                                                glyph="envelope"
                                                className={isMobile ? "fontSize15 pointer mt5" : "bigger pointer small-margin mt5"}/>
                                        </Link>
                                    </div>
                                </div> : <InlineEditable
                                    editable={contextResource === undefined}
                                    value={
                                        <div
                                            className={screen32 && contextResource && window.location.href.indexOf(`lead/${contextResource.id}/time`) > -1 && 'flex-column-start'}>
                                            <h4 style={{color}}
                                                className={'no-margin'}><b
                                                className={screen32 && 'mr-5'}>{customer ? customer.label : 'Customer'}</b>
                                            </h4>
                                            {contextResource && resource.id && (
                                                <div>
                                            <span>
                                                <small><a
                                                    style={{color}}
                                                    href={phoneTo}>{contextResource && contextResource.phone_number && contextResource.phone_number}</a></small>
                                                {' '}
                                                <small><a
                                                    style={{color}}
                                                    href={mailTo}>{contextResource && contextResource.email_address && contextResource.email_address}</a></small>
                                            </span>
                                                    <Link onClick={this.emailCustomer}>
                                                        <Glyphicon
                                                            style={{color}}
                                                            glyph="envelope"
                                                            className="bigger pointer small-margin mt5"/>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    }
                                    form={(done) =>
                                        <Select autoFocus menuIsOpen className="Select"
                                                st classNamePrefix="select"
                                                isClearable
                                                value={customer}
                                                options={customerOptions}
                                                placeholder="Customer"
                                                onInputChange={this.delayedSearch}
                                                onChange={
                                                    this.selectResourceAttr('customer_id', () => {
                                                            done();
                                                            const {customer_id} = this.state.resource;
                                                            const selectedCustomer = customerSearchResults.find(x => x.id === customer_id);
                                                            const eventType = schedulerEventTypes.find(x => x.id === resource.scheduler_event_type_id)
                                                            if (selectedCustomer != null) {
                                                                if (resource && eventType) {
                                                                    resource.name = `${eventType.name}: ${selectedCustomer.name}`
                                                                }
                                                                const selectSite = selectedCustomer.sites.length === 1;
                                                                if (selectSite) {
                                                                    this.selectSite((mapForSelect(selectedCustomer.sites)[0]));
                                                                } else {
                                                                    this.selectSite(null);
                                                                }
                                                            } else {
                                                                this.selectSite(null);
                                                            }
                                                        }
                                                    )
                                                }
                                        />
                                    }
                                />}
                            </Col>
                        </Row>

                        <Row className="vcenter">
                            <Col xs={2}
                                 style={{color}}
                                 className="text-center">
                                <Glyphicon glyph="map-marker" className="big"/>
                            </Col>
                            {!this.state.useCustomLocation ?
                                <Col xs={10} className={isMobile ? 'padding10LR vcenter no-margin' : 'vcenter'}
                                     style={{height: window.innerHeight <= 720 ? (window.location.href.indexOf("time") > -1 ? "unset" : isMobile ? null : "60px") : "80px"}}>
                                    {isMobile ? <div className={'full-width'}>
                                        <Select className="Select" classNamePrefix="select"
                                                value={site}
                                                options={sitesOptions}
                                                isClearable
                                                onChange={e => {
                                                    this.selectSite(e);
                                                }}
                                        />
                                        <div className={isMobile ? 'mt-5 justify-flex-start' : 'top10 bottom10'}>
                                            <a
                                                style={{color}}
                                                href={`https://www.google.com/maps/search/?api=1&query=${contextResource && contextResource.site_latitude}, ${contextResource && contextResource.site_longitude}&zoom=21&basemap=satellite`}
                                                target="_blank"><FontAwesomeIcon icon="map-marked-alt"
                                                                                 className={isMobile ? "fontSize15" : "bigger small-margin"}/></a>
                                            <Link
                                                to={`customer/sites/${contextResource && contextResource.customer_id}`}
                                                target="_blank">
                                                <FontAwesomeIcon
                                                    style={{color}}
                                                    icon="map-signs"
                                                    className="bigger small-margin"/>
                                            </Link>
                                        </div>
                                    </div> : <InlineEditable
                                        className="full-width"
                                        icons={
                                            contextResource && (
                                                <span>
                                    <a style={{color}}
                                       href={`https://www.google.com/maps/search/?api=1&query=${contextResource.site_latitude}, ${contextResource.site_longitude}&zoom=21&basemap=satellite`}
                                       target="_blank"><FontAwesomeIcon icon="map-marked-alt"
                                                                        className="bigger small-margin"/></a>
                                                    {resource.has_sites && <Link
                                                        style={{color}}
                                                        to={`customer/sites/${contextResource.customer_id}`}
                                                        target="_blank">
                                                        <FontAwesomeIcon icon="map-signs"
                                                                         className="bigger small-margin"/>
                                                    </Link>}
                                </span>
                                            )}
                                        editable={contextResource === undefined}
                                        value={<h4
                                            style={{color}}
                                            className={(window.location.href.indexOf(`lead/`) > -1 && window.location.href.indexOf(`/time`) > -1) > -1 ? "font14" : null}>{site ? <>
                                            <b>{site.label?.split(':')[0]}</b>
                                            <p>{site.label?.split(':')[1]}</p></> : resource && resource.full_address ? resource.full_address : 'Site'}</h4>}
                                        form={(done) => <Select autoFocus menuIsOpen className="Select"
                                                                classNamePrefix="select"
                                                                value={site}
                                                                options={sitesOptions}
                                                                isClearable
                                                                onChange={e => {
                                                                    this.selectSite(e);
                                                                    done();
                                                                }}
                                        />
                                        }
                                    />}
                                </Col>
                                :
                                <Col xs={10} style={{height: window.innerHeight <= 600 ? "60px" : "80px"}}
                                     className={'vcenter'}>
                                    <FormGroup className={'full-width bottom0'}>
                                        <InlineEditable
                                            icons={
                                                customLocation && (
                                                    <span style={{color}}>
                                                        <a href={`https://www.google.com/maps/search/?api=1&query=${customLocation.latitude}, ${customLocation.longitude}&zoom=21&basemap=satellite`}
                                                           target="_blank"><FontAwesomeIcon icon="map-marked-alt"
                                                                                            className="bigger small-margin"/></a>
                                                    </span>
                                                )}
                                            editable={contextResource === undefined}
                                            value={
                                                <h4 style={{color}}>{this.state.customLocation ? this.state.customLocation.label : 'Custom location'}</h4>}
                                            form={(done) => <AsyncSelect className="Select full-width"
                                                                         classNamePrefix="select"
                                                                         loadOptions={this.loadOptions}
                                                                         defaultOptions={false}
                                                                         value={this.state.customLocation ? this.state.customLocation : 'Custom location'}
                                                                         onChange={e => {
                                                                             this.setState({customLocation: e}, () => {
                                                                                 done();
                                                                             })
                                                                         }}
                                            />
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        <Row className={isMobile ? "bottom10 vcenter" : "vcenter"}>
                            <Col xs={2}></Col>
                            <Col xs={10} className={isMobile && 'bottom5 padding10LR'}>
                                <ColorCheckbox
                                    textColor={color}
                                    value={this.state.useCustomLocation}
                                    className="color-white font12 no-margin no-padding"
                                    label='Use Custom Location'
                                    onChange={e => {
                                        if (!e && resource.site_id) {
                                            this.selectSite(resource.site_id)
                                        }
                                        this.setState({useCustomLocation: !this.state.useCustomLocation}, () => this.loadEvents())

                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="vcenter">
                            <Col xs={2} className="text-center">
                                <FontAwesomeIcon
                                    style={{color}}
                                    icon="font"
                                    className="big"/>
                            </Col>
                            <Col xs={10} className={isMobile && 'padding10LR'}>
                                {isMobile ? <FormControl
                                    name={resource.work_order ? "proposal_title" : "name"}
                                    spellCheck="true"
                                    className="inputTextColor"
                                    value={resource.work_order && resource.proposal_title ? resource.proposal_title : (!resource.work_order && resource.name && !resource.name.includes("Work Order:")) ? resource.name : ""}
                                    onChange={this.updateResourceAttr}
                                    placeholder="Event name"
                                /> : <InlineEditable
                                    toggleTitleInput={this.toggleTitleInput}
                                    form={() =>
                                        <FormControl
                                            style={isTitleFieldActive ? null : {color}}
                                            name={resource.work_order ? "proposal_title" : "name"}
                                            spellCheck="true"
                                            value={resource.work_order && resource.proposal_title ? resource.proposal_title : !resource.work_order && resource.name && !resource.name.includes("Work Order:") ? resource.name : ""}
                                            onChange={this.updateResourceAttr}
                                            placeholder="Event name"
                                        />
                                    }
                                    value={
                                        <div
                                            style={{color}}>{resource.work_order && resource.proposal_title ? resource.proposal_title : !resource.work_order && resource.name && !resource.name.includes("Work Order:") ? resource.name : "No name"}</div>
                                    }
                                />}
                            </Col>
                        </Row>
                    </Col>

                    <Col className="text-left" xs={12}>
                        <Row className={`vcenter`}>
                            <Col xs={12} className={isMobile && 'mt10'}>
                                <Row className={!isMobile && 'min-height-40 vertical-align'}
                                     style={{marginLeft: iPad && 10}}>
                                    {isMobile ?
                                        <><Col md={2} xs={2} className='mt10 padding-left-20'>
                                            <small
                                                style={{color}}
                                                className={isMobile ? 'add-margin-right-scheduler' : 'add-margin-right'}>Type:</small></Col>
                                            <Col md={10} xs={10} className="padding10LR"><Select
                                                className={`Select full-width add-margin-right ${smMobile && 'mr-40'}`}
                                                classNamePrefix="select"
                                                options={schedulerEventTypesOptions}
                                                value={select(schedulerEventTypesOptions, resource.scheduler_event_type_id)}
                                                onChange={(e) => this.handleTypeChange(e, customer)}

                                            /> </Col></>
                                        : <Col md={10} mdOffset={2} xs={12}
                                               className={isMobile ? 'space-between no-padding mt-20' : 'vertical-align'}>
                                            <small
                                                style={{color}}
                                                className={isMobile ? 'add-margin-right-scheduler' : 'add-margin-right'}>Type:</small>
                                            <InlineEditable
                                                className={'inline-block full-width add-margin-right'}
                                                value={
                                                    <h4 style={{color}}>
                                                        <b>{resource.scheduler_event_type_id && select(schedulerEventTypesOptions, resource.scheduler_event_type_id) && resource.scheduler_event_type_id && select(schedulerEventTypesOptions, resource.scheduler_event_type_id).label}</b>
                                                    </h4>
                                                }
                                                form={(done) =>
                                                    <Select className={`Select full-width`}
                                                            classNamePrefix="select"
                                                            options={schedulerEventTypesOptions}
                                                            value={select(schedulerEventTypesOptions, resource.scheduler_event_type_id)}
                                                            autoFocus
                                                            menuIsOpen
                                                            onChange={(e) => this.handleTypeChange(e, done, customer)}

                                                    />
                                                }
                                            /></Col>}
                                </Row>
                                <Row className={!isMobile && 'min-height-40 vertical-align'}
                                     style={{marginLeft: iPad && 10}}>
                                    {isMobile ? <>
                                            <Col md={2} xs={2} className='mt10 padding-left-20'>
                                                <small style={{color}}
                                                       className={isMobile && 'add-margin-right'}>Status:</small>
                                            </Col>
                                            <Col md={10} xs={10} className='mt10 vertical-align padding10LR'>
                                            <span
                                                style={{color}}
                                                className={'add-margin-right'}><b>{resource.id ? resource.status : 'New'}</b></span>
                                                {resource && resource.date_from &&
                                                    <h5 style={{color}} className={'color-white inline-block no-margin'}>
                                                        <b>{duration}</b>
                                                    </h5>}
                                            </Col></>
                                        :
                                        <Col md={10} mdOffset={2} xs={12}
                                             className={isMobile ? 'mt10 no-padding vertical-align' : 'vertical-align'}>
                                            <small style={{color}}
                                                   className={isMobile && 'add-margin-right'}>Status:</small>
                                            <h4 style={{color}} className={'inline-block add-margin-right marginLeft5'}>
                                                <b>{resource.id ? resource.status : 'New'}</b></h4>
                                            {resource && resource.date_from &&
                                                <h5 style={{color}} className={'color-white inline-block no-margin'}>
                                                    <b>{duration}</b>
                                                </h5>}
                                        </Col>}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            {!(window.location.href.indexOf(`lead/`) > -1 && window.location.href.indexOf(`/time`) > -1) &&
                <Col md={6} className='no-padding'>
                    <GoogleMapLoader
                        containerElement={
                            <div
                                ref={el => {
                                    if (el && !isMobile) {
                                        const headerHeight = document.getElementsByClassName('event-modal-header')[0] && +document.getElementsByClassName('event-modal-header')[0].clientHeight;
                                        const map = document.getElementById('schedulerMap')
                                        if (map) map.style.height = `${headerHeight}px`
                                    }
                                }
                                }
                                id="schedulerMap"
                                style={{
                                    height: isMobile ? screen30 ? "233px" : "264px" : "unset",
                                    width: isMobile ? "calc(100% + 10px)" : "100%",
                                    marginTop: "-10px",
                                    marginLeft: "-5px"
                                }}
                            />
                        }
                        googleMapElement={
                            <GoogleMap
                                ref={it => this.map = it}
                                zoom={12}
                                center={(() => {
                                    return {
                                        lat: this.state.useCustomLocation && customLocation ? customLocation.latitude : (resource.site_latitude ? resource.site_latitude : contextResource ? contextResource.site_latitude : this.props.client.organization_latitude || 40),
                                        lng: this.state.useCustomLocation && customLocation ? customLocation.longitude : (resource.site_longitude ? resource.site_longitude : contextResource ? contextResource.site_longitude : this.props.client.organization_longitude || -100)
                                    }
                                })()}
                            >
                                {((customLocation && customLocation.latitude) || (resource && resource.site_latitude) || (resource && resource.location_latitude) || (contextResource && contextResource.site_latitude)) &&
                                    <Marker
                                        key={1}
                                        icon={getColorIcon(employeeColor)}
                                        position={{
                                            lat: this.state.useCustomLocation && customLocation ? customLocation.latitude : (resource.site_latitude ? resource.site_latitude : contextResource ? contextResource.site_latitude : 40),
                                            lng: this.state.useCustomLocation && customLocation ? customLocation.longitude : (resource.site_longitude ? resource.site_longitude : contextResource ? contextResource.site_longitude : -100)
                                        }}
                                    />
                                }
                            </GoogleMap>
                        }
                    />
                </Col>}
        </Row>
    }

    handleTypeChange = (e, done, customer) => {
        let {resource} = this.state;

        resource.scheduler_event_type_id = e.value;
        if (resource.name !== e.label) {
            resource.name = e.label;
        }

        if (customer && customer.label) {
            resource.name = `${e.label}: ${customer.label}`
        }

        this.setState(resource);

        if (!isMobile) {
            done && done();
        }
    };

    onToggleCompleteCheckbox = () => {
        const {resource, isCompletedModalOpened} = this.state
        const isWorkOrder = resource.scheduler_event_type_resource === 'WorkOrder'

        if (isWorkOrder && this.props.client?.service_completed_notification) {
            this.setState({isCompletedModalOpened: !isCompletedModalOpened, completedModalType: 'event'})
        } else {
            this.toggleComplete()
        }
    }

    handleCompleteAllServices = () => {
        if (this.props.client.work_order_completed_notification) {
            this.setState({
                isCompletedModalOpened: true,
                completedModalType: 'services'
            })
        } else {
            this.completeAllServices()
        }
    }

    renderFooter(shouldEventSave) {
        const {resource, eventType, emailTemplateShow, contextResource, isSaveInProgress} = this.state;
        if (isMobile) {
            return (<Row
                className={`flex-column-end direction-row no-margin ${isMobile && 'width-bigger pb10 minus-bottom10'}`}
                style={{maxHeight: screen30 && 50, paddingTop: screen30 && 3}}
            >
                <Col xs={12}
                     style={{paddingLeft: isMobile && 0, marginTop: isMobile && 10, marginLeft: isMobile && 15}}>
                    <Row className="justify-content-around"
                         style={{marginBottom: isMobile && 5, flexDirection: 'column'}}>
                        <Col md={3} lgOffset={1} className={"no-padding text-left"} style={{fontSize: isMobile && 12}}>
                            <ColorCheckbox value={resource.confirmed || false} label="Confirm Event"
                                           onChange={this.toggleConfirmed}/>
                        </Col>
                        <Col md={3} className={"no-padding text-left"} style={{fontSize: isMobile && 12}}>
                            <ColorCheckbox value={resource.completed || false} label="Complete Event"
                                           onChange={this.onToggleCompleteCheckbox}/>
                        </Col>
                        <Col md={3} lg={5} className={"no-padding text-left"} style={{fontSize: isMobile && 12}}>
                            <ColorCheckbox value={resource.send_confirmation}
                                           label="Send Customer Confirmation Email"
                                           onChange={this.selectCheckboxAttr('send_confirmation')}/>
                        </Col>
                        <Col md={3} lgOffset={1} className={"no-padding text-left bottom5"}>
                            <ColorCheckbox value={resource.on_site}
                                           label="On Site"
                                           onChange={this.toggleOnSite}
                            />
                        </Col>
                        <Col className={`bottom15 mt10 no-padding`}>
                            {resource.id &&
                                <Glyphicon glyph="trash" className={`big pull-left pointer ${!isMobile && 'plb-10'}`}
                                           onClick={this.delete}/>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className={isMobile && 'reset-left-padding mt10'}>
                            <Button
                                bsSize="xs"
                                bsStyle
                                onClick={this.AddFollowUpModalShow}
                                className={"colorRed"}>
                                Add follow up
                            </Button>
                        </Col>
                    </Row>
                    <Row className="vcenter" style={{fontSize: isMobile && 12, marginTop: isMobile && 5}}>
                        <Col md={3} xs={34} className={"no-padding text-left"}>
                            {contextResource && contextResource.proposal_id &&
                                <Button
                                    bsSize={isMobile ? 's' : 'xs'}
                                    bsStyle="warning"
                                    className={`pull-left ${isMobile && 'font10 padding4'} ${iPad && 'mr-5'}`}
                                    onClick={() => this.handleCompleteAllServices()}
                                >
                                    Complete All Services
                                </Button>}
                        </Col>
                        <Col md={3} xs={4} className={"no-padding text-left"}>
                            <Button
                                bsSize={isMobile ? 's' : 'xs'}
                                bsStyle={emailTemplateShow && "success"}
                                className={`pull-left ${isMobile && 'font10 padding4'}`}
                                onClick={this.emailTemplate}
                            >
                                Email Template
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col className={isMobile ? `d-flex right-5 text-right no-padding` : `text-right no-padding`} xs={4}>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        onClick={shouldEventSave ? this.save : this.props.handleClose}
                        className={!isMobile ? `mrb-10` : `padding4 font10`}
                        style={{maxHeight: isMobile && 25, marginRight: iPad && 5}}
                    >
                        Cancel
                    </Button>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        disabled={this.state.isSaveInProgress}
                        onClick={this.save}
                        className={!isMobile ? `mrb-10` : `padding4 font10`}
                        style={{maxHeight: isMobile && 25}}
                    >
                        Save
                    </Button>
                </Col>
            </Row>)
        }
        if (!isMobile) {
            return (
                <Row
                    className={`flex-column-end direction-row no-margin`}
                    style={{maxHeight: screen30 && 50, paddingTop: screen30 && 3}}
                >
                    <Col className={`mb2 no-padding`}>
                        {resource.id &&
                            <Glyphicon glyph="trash" className={`big pull-left pointer ${!isMobile && 'plb-10'}`}
                                       onClick={this.delete}/>}
                    </Col>
                    <Col xs={8} className={!isMobile && 'ml-30'}>
                        <Row className="vcenter justify-content-around">
                            <Col md={3} lgOffset={1} className={"no-padding text-left"}>
                                <ColorCheckbox value={resource.confirmed || false} label="Confirm Event"
                                               onChange={this.toggleConfirmed}/>
                            </Col>
                            <Col md={3} className={"no-padding text-left"}>
                                <ColorCheckbox value={resource.completed || false} label="Complete Event"
                                               onChange={this.onToggleCompleteCheckbox}/>
                            </Col>
                            <Col md={6} lg={5} className={"no-padding text-left"}>
                                <ColorCheckbox value={resource.send_confirmation}
                                               label="Send Customer Confirmation Email"
                                               onChange={this.selectCheckboxAttr('send_confirmation')}/>
                            </Col>
                        </Row>
                        <Row className="vcenter">
                            <Col md={3} lgOffset={1} className={"no-padding text-left bottom5"}>
                                <ColorCheckbox value={resource.on_site}
                                               label="On Site"
                                               onChange={this.toggleOnSite}
                                />
                            </Col>
                            <Col md={3} className={"no-padding text-left"}>
                                {contextResource && contextResource.proposal_id &&
                                    <Button
                                        bsSize={isMobile ? 's' : 'xs'}
                                        bsStyle="warning"
                                        className={`pull-left ${isMobile && 'font10'}`}
                                        onClick={() => this.handleCompleteAllServices()}
                                    >
                                        Complete All Services
                                    </Button>}
                            </Col>
                            <Col md={2} className={"no-padding text-left"}>
                                <Button
                                    bsSize={isMobile ? 's' : 'xs'}
                                    bsStyle={emailTemplateShow && "success"}
                                    className={`pull-left ${isMobile && 'font10'}`}
                                    onClick={this.emailTemplate}
                                >
                                    Email Template
                                </Button>
                            </Col>
                            <Col sm={3} style={{paddingLeft: 0}}>
                                <Button
                                    bsSize="xs"
                                    bsStyle
                                    onClick={this.AddFollowUpModalShow}
                                    className={"pull-right colorRed text-right mr--40"}>
                                    Add follow up
                                </Button>
                            </Col>
                            {isMobile && <Col sm={3} className={'hright'} style={{paddingLeft: 0}}>
                                <Button
                                    bsSize="xs"
                                    bsStyle
                                    onClick={this.AddFollowUpModalShow}
                                    className={"colorRed"}>
                                    Add follow up
                                </Button>
                            </Col>}
                        </Row>
                    </Col>
                    <Col className={isMobile ? `right-20 text-right no-padding` : `text-right no-padding`} xs={3}>
                        <Button
                            bsSize="small"
                            bsStyle="warning"
                            onClick={shouldEventSave ? this.save : this.props.handleClose}
                            className={!isMobile ? `mrb-10` : `cancel-button-mobile`}
                        >
                            Cancel
                        </Button>
                        <Button
                            bsSize="small"
                            bsStyle="success"
                            disabled={this.state.isSaveInProgress}
                            onClick={this.save}
                            className={!isMobile ? `mrb-10` : `ml-20`}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            )
        }
    }

    updateEvent = e => {
        e = e.event
        const {resource, events} = this.state;
        const event = {};
        const end = e.end || moment(e.start).add(1, 'hour').format();
        event.date_from = e.start;
        event.date_to = end;
        event.user_selected_date_from = e.start;
        event.user_selected_date_to = end;
        event.all_day = e.allDay;
        if (e.id) {
            event.id = e.id;
            isLoading = true
            this.props.actions.updateEvent(event, () => {
                setTimeout(() => {
                    isLoading = false
                }, 100)
            });
        } else {
            this.setState({resource: {...resource, ...event}});
        }
        let toUpdate = events.find(x => +x.id === +e.id);
        toUpdate = toUpdate ? toUpdate : events.find(x => !x.id);
        toUpdate.date_from = e.start;
        toUpdate.start = e.start;
        toUpdate.date_to = end;
        toUpdate.end = end;
        toUpdate.allDay = e.allDay;
        this.setState({events, scrollTime: this.getScrollTime(toUpdate)});
    };

    onEditorStateChange = (editorState) => {
        this.state.resource.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({editorState});
    };

    updateEditorState(message) {
        if (message) {
            const contentBlock = htmlToDraft(updateExternalLinks(null, message));
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({editorState});
        } else {
            this.setState({editorState: null});
        }
    }

    AddFollowUpModalShow = () => {
        this.setState({AddFollowUpModalShow: !this.state.AddFollowUpModalShow})
    };

    removeDuplicates = (values) => {
        return values.reduce((unique, o) => {
            if (!unique.some(obj => obj.value === o.value)) {
                unique.push(o);
            }
            return unique;
        }, []);
    }

    renderBody() {
        const {inModal} = this.props;
        const {
            employees,
            resource,
            contextResource,
            eventType,
            modalEvent,
            customerSearchResults,
            selectedGuests,
            guestsColors,
            prevUrl,
            showDisabledUsers,
            consumableConsumptionModal,
            downloadInProgress
        } = this.state;
        let guestOptions = this.guestSearch ? this.guestSearch.searchOptions() : [];
        const employee = employees.find(e => e.value === resource.person_id);

        let employeeOptions;

        if (!showDisabledUsers) {
            employeeOptions = employees.filter(x => x.label !== 'Not assigned' && (this.state.scheduler_event_guestsTemp.map(x => x.value).includes(x.value) || !x.disabled))
        } else {
            employeeOptions = employees.filter(x => x.label !== 'Not assigned')
        }

        const person = select(employeeOptions, resource.created_by_person_id);

        const selectedCustomer = customerSearchResults.find(x => x.id === resource.customer_id);
        const sites = (resource.customer_id && selectedCustomer) ? selectedCustomer.sites : [];
        const sitesOptions = mapForSelect(sites);
        const site = select(sitesOptions, resource.site_id);

        if (!inModal) {
            if (guestOptions && guestOptions.length > 0) {
                const index = guestOptions.findIndex(x => x === this.state.person_id)

                if (index !== -1) {
                    guestOptions[index] = employee
                }

                guestOptions = guestOptions.filter(s => s && s.value !== resource.person_id)

                if (selectedGuests && selectedGuests.length > 0) {
                    selectedGuests.map(g => {
                        const employee = employeeOptions?.find(guest => +g.id === +guest.value)

                        guestOptions.push({
                            value: g.id,
                            label: `${g.first_name} ${g.last_name}`,
                            employee_id: employee?.employee_id,
                            start_date: null,
                            termination_date: null,
                            color: g.color ? g.color : employee?.color,
                            email: g.email ? g.email : employee?.color
                        })
                    })
                }
                guestOptions = this.removeDuplicates(guestOptions).filter(x => x.value)

                if (employees?.length > 0 && guestOptions?.length > 0) {
                    employees.map(e => {
                        guestOptions.map(g => {
                            if (e.value === g.value) {
                                g.label = e.label
                            }
                        })
                    })
                }
            }

            if (guestsColors && guestsColors.length > 0) {
                guestsColors.map(c => {
                    const guest = guestOptions.find(x => x.value === c.value);
                    guest.color = c.color;
                })
            }
        }

        guestOptions = guestOptions.filter(s => s.value !== resource.person_id)

        if (employees?.length > 0 && guestOptions?.length > 0) {
            employees.map(e => {
                guestOptions.map(g => {
                    if (e.value === g.value) {
                        g.label = e.label
                    }
                })
            })
        }

        const isWorkOrderType = eventType && eventType.resource === 'WorkOrder'

        let eventPersonIds = []
        let guestsPersonIds = []
        let personIds = []

        if (resource) {
            const {arborist_person_id, created_by_person_id, person_id, scheduler_event_guests} = resource
            eventPersonIds = [arborist_person_id, created_by_person_id, person_id]
            personIds = eventPersonIds.concat(scheduler_event_guests).filter((p, i, arr) => arr.indexOf(p) === i)
        }

        return <>
            {consumableConsumptionModal.show && <ConsumablesConsumptionModal
                completeServices={this.props.actions.completeServices}
                loadConsumables={this.props.actions.loadConsumables}
                sendConsumablesConsumption={this.props.actions.sendConsumablesConsumption}
                psIds={consumableConsumptionModal.psIds}
                proposalServices={consumableConsumptionModal.proposalServices}
                showModal={consumableConsumptionModal.show}
                closeModal={() => {
                    this.setState({
                        consumableConsumptionModal: {
                            show: false,
                            psIds: [],
                            proposalServices: {}
                        }
                    })
                    this.props.actions.completeAllServices({id: this.state.resource.id}, this.updateStatus);
                }}
            />}
            <Row
                className={isMobile ? "padding-top-10 no-margin width-bigger" : "no-margin minus10margin"}>
                <Row className='no-margin' id={'schedulerEventInfo'}>
                    <Col className="text-left body" xs={inModal ? (isMobile ? 12 : 6) : 12}
                         style={{display: screen30 && 'flex', justifyContent: screen30 && 'space-between'}}>
                        <Row className={isMobile ? "mt10 vcenter bottom5" : "vcenter bottom5"}>
                            <Col xs={2} className="text-center">
                                <Glyphicon glyph="calendar" className={isMobile ? "big small-margin" : "big"}/>
                            </Col>
                            <Col xs={10} style={{paddingLeft: screen30 && 6, fontSize: screen30 && 12}}>
                                <div>
                                    {isMobile ? <div>
                                        <Select className="Select" classNamePrefix="select"
                                                options={employeeOptions}
                                                isClearable
                                                value={select(employeeOptions, resource.person_id)}
                                                onChange={e => {
                                                    const previous_person_id = resource.person_id;
                                                    this.selectResourceAttr('person_id', () => {
                                                        const person = employees.find(e => e.value === resource.person_id);
                                                        if (resource.person_id && !resource.scheduler_event_guests.some(e => e === resource.person_id)) {
                                                            this.guestSearch.initTempOptions([person]);
                                                        }
                                                        if (previous_person_id !== resource.person_id) {
                                                            resource.scheduler_event_guests = resource.scheduler_event_guests
                                                                .filter(e => e !== previous_person_id);
                                                        }
                                                        this.setState({resource, selectedGuests}, () => {
                                                            this.loadEvents();
                                                        });
                                                    })(e);
                                                }
                                                }/>
                                        <div className={isMobile ? 'vertical-align bottom5' : 'top10 bottom10'}>
                                            {resource.id &&
                                                <Link onClick={e => {
                                                    this.emailEmployee(employee);
                                                }}>
                                                    <Glyphicon glyph="envelope"
                                                               className="bigger pointer small-margin mt5"/>
                                                </Link>}
                                            <div>Created by: {person && person.label}</div>
                                        </div>
                                    </div> : <InlineEditable
                                        icons={
                                            <span>
                                                    {resource.id &&
                                                        <Link onClick={e => {
                                                            this.emailEmployee(employee);
                                                        }}>
                                                            <Glyphicon glyph="envelope"
                                                                       className="bigger pointer small-margin mt5"/>
                                                        </Link>}
                                                {isMobile && <div>Created by: {person && person.label}</div>}
                                            </span>
                                    }
                                    value={
                                        <h4>{employee ?
                                            <div>{employee.label}
                                            </div>
                                            : 'Not Assigned'}
                                        </h4>
                                    }
                                    form={(done) =>
                                        <Select className="Select" classNamePrefix="select"
                                                options={employeeOptions}
                                                isClearable
                                                autoFocus menuIsOpen
                                                value={select(employeeOptions, resource.person_id)}
                                                onChange={e => {
                                                    const previous_person_id = resource.person_id;
                                                    this.selectResourceAttr('person_id', () => {
                                                        const person = employees.find(e => e.value === resource.person_id);
                                                        if (resource.person_id && !resource.scheduler_event_guests.some(e => e === resource.person_id)) {
                                                            this.guestSearch.initTempOptions([person]);
                                                        }
                                                        if (previous_person_id !== resource.person_id) {
                                                            resource.scheduler_event_guests = resource.scheduler_event_guests
                                                                .filter(e => e !== previous_person_id);
                                                        }
                                                        this.setState({resource, selectedGuests}, () => {
                                                            this.loadEvents();
                                                            done();
                                                        });
                                                    })(e);
                                                }
                                                }/>
                                    }
                                />}
                            </div>
                            {!isMobile && <div>Created by: {person && person.label}</div>}
                        </Col>
                    </Row>
                    <Row className="vcenter">
                        <Col xs={2} className="text-center" style={{marginRight: screen32 ? 6 : screen30 ? 10 : null}}>
                            <FontAwesomeIcon icon="users"
                                             className="big small-margin"/>
                        </Col>
                        <Col xs={10} style={{fontSize: screen30 && 12}}>
                            {isMobile ? <Select className="Select bottom10"
                                                classNamePrefix="select"
                                                value={select(guestOptions, resource.scheduler_event_guests)}
                                                options={guestOptions}
                                                placeholder="Guests"
                                                onInputChange={this.guestSearch.searchGuess}
                                                isMulti
                                                onChange={e => {
                                                    if (!inModal) {
                                                        this.selectResourceAttr('scheduler_event_guests', () => {
                                                            this.props.actions.getPerson(resource.scheduler_event_guests, guests => {
                                                                this.setState({selectedGuests: guests.guests})
                                                            })
                                                            this.setState({resource}, () => {
                                                                this.loadEvents();
                                                            });
                                                        })(e)
                                                    } else {
                                                        this.selectResourceAttr('scheduler_event_guests', this.loadEvents)(e)
                                                    }
                                                }}
                                                isClearable
                            /> : <InlineEditable
                                value={<div><h4>Guests: </h4>
                                    {select(guestOptions, resource.scheduler_event_guests).map(x => x.label).join(', ')}
                                </div>}
                                form={(done) =>
                                    <Select className="Select bottom10"
                                            classNamePrefix="select"
                                            value={select(guestOptions, resource.scheduler_event_guests)}
                                            options={guestOptions}
                                            placeholder="Guests"
                                            onInputChange={this.guestSearch.searchGuess}
                                            isMulti
                                            isClearable
                                            onChange={e => {
                                                if (!inModal) {
                                                    this.selectResourceAttr('scheduler_event_guests', () => {
                                                        this.props.actions.getPerson(resource.scheduler_event_guests, guests => {
                                                            this.setState({selectedGuests: guests.guests})
                                                        })
                                                        this.setState({resource}, () => {
                                                            this.loadEvents();
                                                            done();
                                                        });
                                                    })(e)
                                                } else {
                                                    this.selectResourceAttr('scheduler_event_guests', this.loadEvents)(e)
                                                }
                                            }}
                                    />
                                }
                            />}
                        </Col>
                    </Row>
                    {!inModal && <Row>
                        <Col xsOffset={2} xs={10}>
                            {select(guestOptions, resource.scheduler_event_guests).map((x, i) => {
                                return <ColorCheckbox key={i} value={x.selected !== undefined ? x.selected : true}
                                                      label={x.label}
                                                      color={x.color && x.color}
                                                      onChange={e => {
                                                          x.selected = e;
                                                          this.setState({resource});
                                                      }}
                                                      selectColor={e => {
                                                          x.color = e;
                                                          const selectedGuest = guestOptions.find(g => g.value === x.value)
                                                          selectedGuest.color = e

                                                          const employee = {
                                                              id: selectedGuest.employee_id,
                                                              color: e
                                                          }

                                                          this.setState({
                                                              resource: resource,
                                                              selectedGuests: selectedGuests,
                                                              guestsColors: [...this.state.guestsColors, {
                                                                  value: x.value,
                                                                  color: x.color
                                                              }]
                                                          }, () => this.props.actions.updateEmployeeColor(employee));
                                                      }}
                                />
                            })}
                        </Col>
                    </Row>}

                    </Col>
                    {contextResource && contextResource.proposal_id &&
                        <AddFollowUpModal
                            proposalId={contextResource.proposal_no}
                            proposal={contextResource.proposal_id}
                            onHide={this.AddFollowUpModalShow}
                            show={this.state.AddFollowUpModalShow}>
                        </AddFollowUpModal>}
                    {(inModal && contextResource && contextResource.proposal_id) &&
                        <Col className="text-left prices" xs={isMobile ? 12 : 6}>
                            {!isMobile && <Row className="vcenter">

                                <Col sm={5}>
                                    <Row className="vcenter">
                                        <Col xs={3} className="text-right no-padding"
                                             style={{fontSize: screen30 && 12}}>
                                            Arborist:
                                        </Col>
                                        <Col xs={9} style={{paddingLeft: 5}}>
                                            <h4>{contextResource.sales_arborist_name}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={4}>
                                    <Row className={!resource.work_order ? 'vcenter line-height-35' : "vcenter"}>
                                        <Col className="text-right no-padding" xs={6}
                                             style={{fontSize: screen30 && 12}}>
                                            Invoice:
                                        </Col>
                                        <Col xs={6} className="hstart" style={{paddingLeft: 5}}>
                                            {resource.work_order && resource.work_order.invoice_id ?
                                                <div>
                                                    <h4 className='text-right'>
                                                        <LinkContainer target="_blank"
                                                                       to={`/proposals/${contextResource.proposal_id}/invoices/`}>
                                                            <MenuItem className='dropdown'
                                                                      bsSize="small">#{resource.work_order.invoice_no}</MenuItem>
                                                        </LinkContainer>
                                                    </h4>
                                                </div>
                                                :
                                                resource.work_order && resource.work_order.id ?
                                                    <div>
                                                        <h4 className='hstart'>
                                                            <LinkContainer target="_blank"
                                                                           to={`/proposals/${contextResource.proposal_id}/invoices/`}>
                                                                <MenuItem className='dropdown'
                                                                          bsSize="small">Add</MenuItem>
                                                            </LinkContainer>
                                                        </h4>
                                                    </div>
                                                    :
                                                    <hr className={'border-top-color-red width-30 no-margin'}></hr>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={3}>
                                    <Row
                                        className={!contextResource.service_price ? 'vcenter line-height-35' : "vcenter"}>
                                        <Col className="no-padding" xs={3} style={{fontSize: screen30 && 12}}>
                                            Price:
                                        </Col>
                                        <Col xs={9} className="hstart" style={{paddingLeft: 0}}>
                                            {contextResource.service_price ?
                                                <h4><Dollar amount={contextResource.service_price}/>
                                                </h4> :
                                                <hr className={'border-top-color-red width-30 no-margin'}></hr>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}
                            <Row style={{
                                marginTop: 5,
                                display: isMobile && 'flex',
                                flexDirection: isMobile && 'column',
                                marginBottom: isMobile && 10
                            }}>
                                {isMobile && <Col sm={4}>
                                    <Row className="vcenter"
                                         style={{display: isMobile && 'flex', alignItems: isMobile && 'center'}}>
                                        <Col sm={6} xs={6} className="text-right no-padding"
                                             style={{fontSize: screen30 && 12}}>
                                            Arborist:
                                        </Col>
                                        <Col sm={6} xs={6} style={{paddingLeft: 5}}>
                                            <h4 className={isMobile && 'fontSize12'}>{contextResource.sales_arborist_name}</h4>
                                        </Col>
                                    </Row>
                                </Col>}
                                <Col sm={3}>
                                    <Row className={"vcenter"}
                                         style={{display: isMobile && 'flex', alignItems: isMobile && 'center'}}>
                                        <Col sm={6} className="no-padding" xs={6}
                                             style={{fontSize: screen30 && 12, textAlign: isMobile && 'right'}}>
                                            Lead:
                                        </Col>
                                        <Col sm={6} xs={6} className="hstart" style={{paddingLeft: 8}}>
                                            {resource.lead_id ?
                                                <h4>{resource.lead_id}</h4> :
                                                <hr className={'border-top-color-red width-30 no-margin'}></hr>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={3}>
                                    <Row className={!contextResource.proposal_id ? 'vcenter line-height-35' : "vcenter"}
                                         style={{display: isMobile && 'flex'}}>
                                        <Col className="text-right no-padding" xs={6}
                                             style={{fontSize: screen30 && 12}}>
                                            WO:
                                        </Col>
                                        <Col xs={6} className="hstart" style={{paddingLeft: 5}}>
                                            {contextResource.work_order_no ?
                                                <div>
                                                    <h4 className={isMobile ? 'fontSize12 text-right' : 'text-right'}>
                                                        <Nav>
                                                            <NavDropdown
                                                                pullRight
                                                                eventKey={2}
                                                                title={`#${contextResource.work_order_no}`}
                                                                id={`proposal-menu`}
                                                                className="menu-show-fixer proposal-menu-padding-reset">
                                                                <LinkContainer target="_blank"
                                                                               to={`/work_orders/${contextResource.proposal_id}`}>
                                                                    <MenuItem bsSize="small"><h5
                                                                        className="text-success">Manage WO</h5>
                                                                    </MenuItem>
                                                                </LinkContainer>
                                                                <LinkContainer target="_blank"
                                                                               to={`/print_work_orders/${contextResource.work_order_no}`}>
                                                                    <MenuItem bsSize="small"><h5
                                                                        className="text-success">Print WO</h5>
                                                                    </MenuItem>
                                                                </LinkContainer>
                                                                <LinkContainer target="_blank"
                                                                               to={`/complete_work_orders/${contextResource.work_order_no}`}>
                                                                    <MenuItem bsSize="small"><h5
                                                                        className="text-success">Complete WO</h5>
                                                                    </MenuItem>
                                                                </LinkContainer>
                                                                <LinkContainer target="_blank"
                                                                               to={`/proposal_cost/${contextResource.proposal_id}`}>
                                                                    <MenuItem bsSize="small"><h5
                                                                        className="text-success">Job Costs</h5>
                                                                    </MenuItem>
                                                                </LinkContainer>
                                                                <Link
                                                                    onClick={() => !downloadInProgress && this.setState({downloadInProgress: true}, () => this.props.actions.downloadWOPdf(contextResource.wo_token, contextResource.work_order_no,
                                                                        () => this.setState({downloadInProgress: false}),
                                                                        () => this.setState({downloadInProgress: false})
                                                                    ))}
                                                                >
                                                                    <MenuItem bsSize="small"><h5
                                                                        className="text-success menu-item-padding">PDF</h5>
                                                                    </MenuItem>
                                                                </Link>
                                                            </NavDropdown>
                                                        </Nav>
                                                    </h4>
                                                </div> :
                                                contextResource.proposal_id ?
                                                    <div>
                                                        <h4 className='text-right'>
                                                            <LinkContainer
                                                                to={`/work_orders/${contextResource.proposal_id}`}>
                                                                <MenuItem className='dropdown'
                                                                          bsSize="small">Add</MenuItem>
                                                            </LinkContainer>
                                                        </h4>
                                                    </div> :
                                                    <hr className={'border-top-color-red width-30 no-margin'}></hr>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                {(eventType && eventType.resource === 'WorkOrder' || resource.lead_id) && contextResource &&
                                    <Col sm={3}>
                                        <Row className="vcenter" style={{display: isMobile && 'flex'}}>
                                            <Col className="text-right no-padding" xs={6}
                                                 style={{fontSize: screen30 && 12}}>
                                                Proposal:
                                            </Col>
                                            {contextResource.proposal_id ?
                                                <Col md={6} className='hstart' style={{paddingLeft: 5}}>
                                                    <div>
                                                        <h4 className={isMobile && 'fontSize12'}>
                                                            <Nav>
                                                                <NavDropdown
                                                                    eventKey={2}
                                                                    title={`#${contextResource.proposal_no}`}
                                                                    id={`proposal-menu`}
                                                                    className="menu-show-fixer proposal-menu-padding-reset">
                                                                    <LinkContainer target="_blank"
                                                                                   to={`/proposal_service_status/${contextResource.proposal_id}`}>
                                                                        <MenuItem bsSize="small"><h5
                                                                            className="text-success">Statuses</h5>
                                                                        </MenuItem>
                                                                    </LinkContainer>
                                                                    <LinkContainer target="_blank"
                                                                                   to={`/mapview/${contextResource.proposal_id}`}>
                                                                        <MenuItem bsSize="small"><h5
                                                                            className="text-success">Edit</h5>
                                                                        </MenuItem>
                                                                    </LinkContainer>
                                                                    <LinkContainer target="_blank"
                                                                                   to={`/proposal_cost/${contextResource.proposal_id}`}>
                                                                        <MenuItem bsSize="small"><h5
                                                                            className="text-success">Job Costs</h5>
                                                                        </MenuItem>
                                                                    </LinkContainer>
                                                                    <Link
                                                                        onClick={() => !downloadInProgress && this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(contextResource,
                                                                            () => this.setState({downloadInProgress: false}),
                                                                            () => this.setState({downloadInProgress: false})
                                                                        ))}
                                                                    >
                                                                        <MenuItem bsSize="small"><h5
                                                                            className="text-success menu-item-padding">PDF</h5>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </NavDropdown>
                                                            </Nav>
                                                        </h4>
                                                    </div>
                                                </Col> :
                                                <Col xs={3} className='hstart'>
                                                    <div>
                                                        <h4>
                                                            <LinkContainer
                                                                to={`/mapview/${resource.proposal_id || ''}?lead_id=${resource.lead_id}&customer_id=${resource.customer_id}&site_id=${resource.site_id}`}>
                                                                <MenuItem className='dropdown'
                                                                          bsSize="small">Add</MenuItem>
                                                            </LinkContainer>
                                                        </h4>
                                                    </div>
                                                </Col>}
                                        </Row>
                                    </Col>
                                }
                                <Col sm={3}>
                                    <Row
                                        className={!contextResource.service_hours ? 'vcenter line-height-35' : "vcenter"}
                                        style={{display: isMobile && 'flex'}}>
                                        <Col className="text-right no-padding" xs={6}
                                             style={{fontSize: screen30 && 12}}>
                                            Hours:
                                        </Col>
                                        <Col xs={6} className={isMobile ? "fontSize12 hstart" : "hstart"}
                                             style={{paddingLeft: 5}}>
                                            {contextResource.work_order_no ?
                                                <h4 style={{
                                                    margin: isMobile && 0,
                                                    fontSize: isMobile && 12
                                                }}>{contextResource.service_hours}</h4> :
                                                <hr className={'border-top-color-red width-30 no-margin'}></hr>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                {isMobile &&
                                    <Col sm={3}>
                                        <Row className={!resource.work_order ? 'vcenter line-height-35' : "vcenter"}>
                                            <Col className="text-right no-padding" xs={6}
                                                 style={{fontSize: screen30 && 12}}>
                                                Invoice:
                                            </Col>
                                            <Col xs={6} style={{paddingLeft: 5}}>
                                                {resource.work_order && resource.work_order.invoice_id ?
                                                    <div>
                                                        <h4 className={isMobile ? 'text-right fontSize12 mt-3' : 'text-right'}>
                                                            <LinkContainer target="_blank"
                                                                           to={`/proposals/${contextResource.proposal_id}/invoices/`}>
                                                                <MenuItem className='dropdown'
                                                                          bsSize="small">#{resource.work_order.invoice_no}</MenuItem>
                                                            </LinkContainer>
                                                        </h4>
                                                    </div>
                                                    :
                                                    resource.work_order && resource.work_order.id ?
                                                        <div>
                                                            <h4 className={isMobile && 'fontSize12 mt-3'}
                                                                style={{marginBottom: isMobile && window.innerHeight > 900 && '0'}}>
                                                                <LinkContainer target="_blank"
                                                                               to={`/proposals/${contextResource.proposal_id}/invoices/`}>
                                                                    <MenuItem className='dropdown'
                                                                              bsSize="small">Add</MenuItem>
                                                                </LinkContainer>
                                                            </h4>
                                                        </div>
                                                        :
                                                        <hr className={'border-top-color-red width-30 no-margin'}></hr>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>}
                            </Row>
                        </Col>
                    }
                </Row>
                <hr className="mt0 bottom3"/>
                <Row className={`no-margin mt-15 ${!isMobile && 'minus-right10'}`}>
                    <Col md={12}>
                        <Row className={isMobile ? 'vcenter columnDirection' : "vcenter d-flex"}>
                            {!isMobile && <Col xs={isWorkOrderType ? 12 : 6} md={6} className='padding-right-8'
                                               style={{lineHeight: screen30 && '16px'}}>
                                <span>
                                Description:
                                </span>
                                <div
                                    className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                    style={{height: "unset", lineHeight: screen30 && '16px'}}
                                >
                                    <Editor
                                        editorState={this.state.editorState}
                                        onEditorStateChange={this.onEditorStateChange}
                                        toolbarHidden
                                        showOpenOptionOnHover={false}
                                        toolbar={{
                                            link: {
                                                showOpenOptionOnHover: true,
                                            }
                                        }}
                                        spellCheck="true"
                                    />
                                    {contextResource && contextResource.notes &&
                                        <small><b>Notes: </b>{contextResource.notes}</small>}
                                </div>
                            </Col>}
                            {isWorkOrderType &&
                                <Col md={6} xs={12} className='padding-left-8'>
                                    <div className={isMobile && 'mt10 bottom10'}
                                         style={{lineHeight: screen30 && '16px'}}>
                                        <span>Messages</span>
                                        <div className={`modalBox crewMessages borderDiv ${!isMobile && 'scrollDiv'}`}
                                             style={{
                                                 height: isMobile ? '40vh' : "unset",
                                                 lineHeight: screen30 && '16px',
                                                 overflow: 'auto'
                                             }}
                                        >
                                            <CrewMessages workOrderId={resource.work_order && resource.work_order.id}
                                                          isCalendarView={prevUrl.includes("/calendar")}
                                                          eventPersonIds={personIds}
                                                          renderSendArea={false} employees={employees}
                                                          siteId={resource.site_id}/>
                                        </div>

                                    </div>
                                </Col>}
                        </Row>
                        {isMobile &&
                            <Col xs={12} className='no-padding bottom10' style={{lineHeight: screen30 && '16px'}}>
                                <span>
                                Description:
                                </span>
                                <div
                                    className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                    style={{height: "unset", lineHeight: screen30 && '16px'}}
                                >
                                    <Editor
                                        editorState={this.state.editorState}
                                        onEditorStateChange={this.onEditorStateChange}
                                        toolbarHidden
                                        showOpenOptionOnHover={false}
                                        toolbar={{
                                            link: {
                                                showOpenOptionOnHover: true,
                                            }
                                        }}
                                        spellCheck="true"
                                    />
                                    {contextResource && contextResource.notes &&
                                        <small><b>Notes: </b>{contextResource.notes}</small>}
                                </div>
                            </Col>}
                        <Col md={12}>
                            {eventType && eventType.resource === 'WorkOrder' && <Row className='top5'>
                                <Col xs={isMobile ? 12 : 4} className={iPad ? 'bottom10' : 'no-padding'}>
                                    <div style={{lineHeight: screen30 && '16px'}}>
                                <span>
                                Services:
                                </span>
                                        <div className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                             style={{
                                                 height: "unset",
                                                 lineHeight: screen30 && '16px'
                                             }}
                                        >
                                            <ul>
                                                {contextResource && contextResource.work_order_proposal_services.map((wops, i) =>
                                                    <li key={i}>#{wops.service_no} {wops.service_name} {wops.est_hours}hr <Dollar
                                                        amount={wops.price}/>
                                                        {wops.bs_services && <ul>
                                                            {wops.bs_services.map((bs_s, i) => <li key={i}>{bs_s}</li>)}
                                                        </ul>}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={isMobile ? 12 : 4} style={{marginTop: isMobile && 10, padding: isMobile && 0}}>
                                    <div style={{lineHeight: screen30 && '16px'}}>
                                <span style={{lineHeight: screen30 && '16px'}}>
                                Equipment:
                                </span>
                                        <div className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                             style={{
                                                 height: "unset",
                                                 lineHeight: screen30 && '16px'
                                             }}
                                        >
                                            <div>
                                                <ul>
                                                    {contextResource && contextResource.equipment && contextResource.equipment.length > 0 && contextResource.equipment.map((e, i) =>
                                                        <li key={i}>{e}</li>)}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={isMobile ? 12 : 4} className='no-padding'>
                                    <div className={isMobile && 'mt10 bottom10'}
                                         style={{lineHeight: screen30 && '16px'}}>
                                <span>
                                JSA:
                                </span>
                                        <div className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                             style={{
                                                 height: "unset",
                                                 lineHeight: screen30 && '16px'
                                             }}
                                        >
                                            <ul>
                                                {contextResource && contextResource.site_hazards.map((e, i) => {
                                                        return <li key={i}>{e.site_hazard}</li>
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Col>
                </Row>
                {modalEvent && <SchedulerEventDialog
                    handleClose={() => {
                        this.setState({modalEvent: null});
                        this.loadEvents();
                    }}
                    clientId={this.props.clientId}
                    actions={this.props.actions}
                    email={this.props.email}
                    event={modalEvent}
                />}
            </Row>
        </>
    }

    updateDate(date) {
        let calendarApi = this.calendarDialogComponentRef.current.getApi();
        calendarApi.gotoDate(date.format());
        this.setState({
            midDate: date,
        }, this.delayedViewRender);
    }

    calculateSingleBoxHeight = () => {
        const {eventType} = this.state
        const bodyHeight = document.getElementById('modal-body') && +document.getElementById('modal-body').clientHeight;
        const infoContainerHeight = document.getElementById('schedulerEventInfo') && document.getElementById('schedulerEventInfo').clientHeight;
        const boxesContainerHeight = bodyHeight - infoContainerHeight;
        const singleBoxHeight = eventType && eventType.resource !== 'WorkOrder' ? boxesContainerHeight - 40 : window.innerHeight < 720 ? (boxesContainerHeight - 44) / 2 : (boxesContainerHeight - 66) / 2;
        if (!isMobile) {
            const boxes = document.getElementsByClassName('modalBox')
            for (let box of boxes) {
                box.style.height = `${singleBoxHeight}px`
            }
        }
    }

    setLinkUrl = (key, url, value) => {
        key.addEventListener("click", e => {
            e.preventDefault()
            window.open(url, value)
        })
    }

    detectURLs = (description) => {
        const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return description.match(urlRegex)
    }

    renderHyperlinks = (editorLinks, primaryUrl) => {
        const {resource, contextResource} = this.state;
        const {customer_id, lead_id, proposal_id} = resource;
        let urls = []

        const customerLink = [...editorLinks].find(x => x.outerHTML.includes(`${"/#/customer/info/" + customer_id}`) || x.outerHTML.includes(`/#/lead/${resource.lead_id}`))
        const proposalAddLink = [...editorLinks].find(x => x.outerHTML.includes(`${"/#/mapview?customer_id=" + customer_id}`))
        const proposalNoLink = [...editorLinks].find(x => x.outerHTML.includes(`${"/#/mapview/" + proposal_id}`))

        const customerUrl = resource.lead_id ? `${primaryUrl}/lead/${resource.lead_id}` : `${primaryUrl}/customer/info/${customer_id}`
        const proposalUrl = proposalAddLink ? `${primaryUrl}/mapview?customer_id=${customer_id}&lead_id=${lead_id}` : `${primaryUrl}/mapview/${proposal_id}`
        const proposalUrlValue = proposalAddLink ? `customer_id=${customer_id}&lead_id=${lead_id}` : contextResource && contextResource.proposal_id ? `${contextResource.proposal_id}` : null

        if (isMobile) {
            urls = this.detectURLs(resource.description)
            if ([...editorLinks] && [...editorLinks].length > 0) {
                let key;
                urls && urls.length > 0 && urls.map(u => {
                    key = [...editorLinks].find(x => x.href === u.replace(/['"]+/g, ''))
                    if (key) {
                        this.setLinkUrl(key, u.replace(/['"]+/g, ''))
                    }
                })
            }
        }
        if (customerLink) {
            this.setLinkUrl(customerLink, customerUrl)
        }
        if (proposalAddLink || proposalNoLink) {
            if (proposalAddLink) {
                this.setLinkUrl(proposalAddLink, proposalUrl, proposalUrlValue)
            } else if (proposalNoLink) {
                this.setLinkUrl(proposalNoLink, proposalUrl, proposalUrlValue)
            }
        }

    }

    renderPopUp = () => {
        const {isCompletedModalOpened, completedModalType, resource, employees, client} = this.state
        let recipients = []
        let arborist = null
        const isCompleted = resource.status === 'Completed'
        const title = completedModalType === 'event' ? "Complete event" : "Complete services"

        if (client.is_arborist_recipient_wo) arborist = employees.find(e => e.value === resource.arborist_person_id)?.label
        if (client.recipients_wo && client.recipients_wo.length > 0) {
            client.recipients_wo.map(recipient => {
                if (!recipients.includes(recipient)) {
                    recipients.push(employees.find(e => e.value === recipient)?.label)
                }
            })
        }

        if (arborist && !recipients.includes(arborist)) recipients.push(`${arborist} (arborist)`)

        const [completed, opened] = ['This event has been already assigned as completed', `This operation will send an email notification about complete ${completedModalType === 'event' ? 'event' : 'services'} to below recipients`]

        return (
            <RateModal
                title={title}
                show={isCompletedModalOpened}
                onHide={() => completedModalType === 'event' ? this.toggleComplete() : this.completeAllServices()}
                closeButton={() => this.setState({isCompletedModalOpened: false})}
                backdrop='static'
            >
                <div className='columnDirection rate-modal-body-padding'>
                    <div className="bottom10 d-flex">
                        <Row className='d-flex'>
                            <Col xs={2} md={1}><FontAwesomeIcon icon={faExclamationTriangle}
                                                                className={isMobile ? "font20 alert-font-color mt-3" : "small-margin font20 alert-font-color mt-3"}/></Col>
                            <Col xs={10} md={11} className='no-left-padding'>
                                <p className={isMobile ? "ml-10 font12 line_height17_5" : "ml-10 line_height17_5"}>
                                    {`${(isCompleted && !(completedModalType === 'services')) ? completed : opened}. Are you sure?`}
                                </p>
                            </Col>
                        </Row>
                    </div>
                    {!(isCompleted && !(completedModalType === 'services')) &&
                        <Row>
                            <Col xs={2} md={1}/>
                            <Col xs={10} md={11} className="no-padding">
                                <p className={isMobile ? "ml-10 font12" : "ml-6"}>
                                    <ul className='no-margin no-padding'>
                            <span
                                className={`bottom10 ${recipients?.length === 0 && 'wo-link'}`}>{recipients?.length > 0 ? 'Recipients:' : 'Please select any recipients in client settings to send email notification'}</span>
                                        {recipients.map(recipient => (
                                            <li className='list_style_none recipient-text'>{recipient}</li>
                                        ))}
                                    </ul>
                                </p>
                            </Col>
                        </Row>
                    }
                </div>
            </RateModal>
        )
    }

    render() {
        const {
            employees,
            resource,
            defaultView,
            start,
            end,
            contextResource,
            isTitleFieldActive,
            prevUrl,
            isCompletedModalOpened,
            showAssignedArboristModal
        } = this.state;
        let {events, scrollTime, client} = this.state;
        const employee = select(employees.filter(x => x.label !== 'Not assigned'), resource.person_id);
        const editorLinks = document.querySelectorAll(`.rdw-link-decorator-wrapper > a`)
        const {inModal, hideCompleted, setHideCompleted} = this.props;
        events = events.map(e => {
            const color = this.getColor(e);
            return {
                ...e,
                backgroundColor: e.selected ? '#f8e500' : e.highlighted ? '#fff500' : color,
                borderColor: e.selected ? '#f8e500' : e.highlighted ? '#fff500' : color,
                textColor: e.selected ? 'black' : 'white',
            };
        });
        const eventsOnMap = events.filter(e => e.site_latitude).map(x => ({
            latitude: x.site_latitude,
            longitude: x.site_longitude,
            selected: x.selected
        }));
        if (this.state.center) {
            this.setState({center: false});
        }
        // full calendar work around
        const syncCalendar = this.props.actions.syncCalendar;
        const reload = this.loadEvents;
        const background = this.getColor(resource)
        const color = reverseColor(background)
        const primaryUrl = new URL(window.location.href).origin
        let descriptionLine = ""
        let shouldEventSave = false;

        if (resource.lead_id && resource.description) {
            resource.description = resource.description.replace(`/customer/info/${resource.customer_id}`, `/lead/${resource.lead_id}`)
        }
        if (resource.customer_id && resource.lead_id) {
            descriptionLine = `<br>Proposal: <a href=\"${primaryUrl + `/mapview?customer_id=${resource.customer_id}&lead_id=${resource.lead_id}`}\" target="_blank">Add</a>`;
        }
        if (resource.description && resource.description.indexOf(descriptionLine) > -1 && resource.scheduler_event_type_resource === "Lead" && resource.proposal_id) {
            shouldEventSave = true;
        }

        if (editorLinks && editorLinks.length > 0) {
            this.renderHyperlinks(editorLinks, primaryUrl)
        }

        const clientStartTime = this.state && client && client.start_date;

        let start_time
        if (clientStartTime) {
            start_time = moment.utc((clientStartTime) * 1000).format('HH:mm:ss')
        } else {
            start_time = '08:00:00'
        }
        if (hideCompleted) {
            events = events.filter((e) => (e.status !== "Completed"))
        }
        const assignedArboristModal = () => {
            return (
                <AssignedArboristModal
                    showModal={showAssignedArboristModal}
                    onCancel={() => this.setState({isSaveInProgress: false})}
                    hideModal={() => this.setState({showAssignedArboristModal: false})}
                    selectedCustomer={resource.customer_id}
                    onSave={() => this.onSave()}
                />
            )
        }

        return (
            resource.loaded ?
                inModal ?
                    !isMobile ? <><Modal
                        bsSize="large"
                        animation={false}
                        show={true}
                        onHide={shouldEventSave ? this.save : this.props.handleClose}
                        id='scheduler-modal'
                        className="master-form"
                    >
                        <Modal.Header
                            className="event-modal-header"
                            style={{background, color: `${color}!important`}}>
                            {this.renderHeader()}
                        </Modal.Header>
                        <Modal.Body
                            ref={el => {
                                if (el) {
                                    this.calculateSingleBoxHeight()
                                }
                            }}
                            id="modal-body"
                            className={isMobile && "padding-top-10"}>
                            {this.renderBody()}
                        </Modal.Body>
                        <Modal.Footer>
                            {this.renderFooter(shouldEventSave)}
                        </Modal.Footer>
                    </Modal>
                        {this.renderPopUp()}
                        {showAssignedArboristModal && assignedArboristModal()}
                    </> : <Modal
                        bsSize="large"
                        animation={false}
                        show={true}
                        onHide={shouldEventSave ? this.save : this.props.handleClose}
                        className="master-form"
                    >
                        <Modal.Body style={{background}} id="modal-body">
                            <Row
                                className="event-modal-header"
                            >
                                {this.renderHeader()}
                            </Row>
                            {this.renderBody()}
                            {this.renderFooter(shouldEventSave)}
                            {this.renderPopUp()}
                            {showAssignedArboristModal && assignedArboristModal()}
                        </Modal.Body>
                    </Modal>
                    : <div className="inline" id='scheduler-no-modal'>
                        <Row>
                            <Col md={3} className="master-form">
                                <div className="event-modal-header"
                                     style={{background}}>
                                    {this.renderHeader()}
                                </div>
                                <div className="master-form-body">
                                    {this.renderBody()}
                                </div>
                            </Col>
                            <Col md={9} className="pane-wrapper">
                                <SplitPane split="vertical" minSize={200} defaultSize={500}>
                                    <div><GoogleMapLoader
                                        containerElement={
                                            <div
                                                id="leadMap"
                                                style={{
                                                    height: screen32 ? window.innerHeight - 130 : window.innerHeight - 124,
                                                    width: "100%",
                                                }}
                                            />
                                        }
                                        googleMapElement={
                                            <GoogleMap
                                                {...defaultMapParams(eventsOnMap, this.state.center, null, this.props.client)}
                                                ref={it => this.refGoogleMap(it, eventsOnMap)}
                                            >
                                                {events.filter(e => e.site_latitude).sort((a, b) => moment(a.start).diff(moment(b.start))).map((e, i) => (
                                                    <Marker
                                                        key={i}
                                                        position={{lat: e.site_latitude, lng: e.site_longitude}}
                                                        title={e.name}
                                                        icon={e.target ? getBigIcon(e.backgroundColor, e.selected ? false : e.highlighted, (i + 1).toString()) :
                                                            getColorIcon(e.selected ? '#f8e500' : e.backgroundColor, false, (i + 1).toString(), false, false, e.selected ? false : e.highlighted)}
                                                        onClick={() => {
                                                            this.setState({scrollTime: this.getScrollTime(e)});
                                                            this.selectEvent(e);
                                                        }}
                                                        zIndex={e.selected ? 1000 : (e.id ? null : 1000)}
                                                    />
                                                ))}
                                            </GoogleMap>
                                        }
                                    />
                                    </div>
                                    <div>
                                        {(this.state && client && client.start_date || start_time) && <FullCalendar
                                            titleFormat= {{
                                                day: "2-digit",
                                                month: 'long',
                                                year: 'numeric'
                                            }}
                                            dayHeaderFormat={{
                                                weekday: 'short',
                                                month: '2-digit',
                                                day: '2-digit',
                                                omitCommas: true
                                            }}
                                            ref={this.calendarDialogComponentRef}
                                            fixedWeekCount={false}
                                            timezone="local"
                                            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin]}
                                            slotDuration={screen32 ? "00:30:00" : height1500 ? "00:15:00" : "00:30:00"}
                                            snapDuration="00:15:00"
                                            scrollTime={start_time}
                                            slotLabelInterval="01:00"
                                            selectable={true}
                                            editable={!isLoading}
                                            height={screen32 ? window.innerHeight - 125 : window.innerHeight - 124}
                                            eventDrop={this.updateEvent}
                                            eventResize={this.updateEvent}
                                            initialDate={this.state.midDate.format()}
                                            viewDidMount={(arg) => {
                                                if (!this.state.view) {
                                                    this.setState({view: arg.view}, this.delayedViewRender);
                                                }
                                            }}
                                            viewClassNames={(arg) => {
                                                if (this.state.view && this.state.view.type !== arg.view.type) {
                                                    this.setState({view: arg.view}, this.delayedViewRender);
                                                }
                                            }}
                                            initialView={defaultView}
                                            customButtons={{
                                                toggle: {
                                                    text: <div className="material-switch">
                                                        <input id="showMapToggle" name="showMapToggle" type="checkbox"
                                                               checked={!hideCompleted}
                                                               onChange={setHideCompleted}/>
                                                        <label htmlFor="showMapToggle"
                                                               className="label-default"></label>
                                                    </div>,
                                                    hint: hideCompleted ? "Show Completed" : "Hide Completed",
                                                },
                                                sync: {
                                                    text: 'sync',
                                                    click: function () {
                                                        if (client.gcal_authorized) {
                                                            syncCalendar(client.id, reload);
                                                        } else {
                                                            reload();
                                                        }
                                                    }
                                                },
                                                prev: {
                                                    click: () => {
                                                        this.updateDate(moment(this.state.midDate).subtract(1, this.period[this.state.view.type]));
                                                    }
                                                },
                                                next: {
                                                    click: () => {
                                                        this.updateDate(moment(this.state.midDate).add(1, this.period[this.state.view.type]));
                                                    }
                                                },
                                            }}
                                            id="scheduler"
                                            dateClick={(arg) => {
                                                const date = moment(arg.date);
                                                let {events} = this.state;
                                                resource.date_from = date;
                                                resource.start = date;
                                                if (arg.view.type === 'dayGridMonth') {
                                                    resource.date_from = moment(date.format()).add(8, 'hours');
                                                }
                                                resource.date_to = moment(resource.date_from).add(1, 'hour');
                                                if (events && events.length > 0) {
                                                    events[events.length - 1] = this.mapResourceToEvent(resource);
                                                } else {
                                                    events = [];
                                                    events.push(this.mapResourceToEvent(resource))
                                                }
                                                this.setState({
                                                    events,
                                                    scrollTime: this.getScrollTime(resource)
                                                });
                                            }}
                                            headerToolbar={{
                                                left: 'prev,next today toggle',
                                                center: 'title',
                                                right: 'sync,dayGridMonth,timeGridWeek,timeGridDay,list'
                                            }}
                                            dayCellDidMount={(arg) => {
                                                if (arg.view.type === 'timeGridWeek' || arg.view.type === 'timeGridDay') {
                                                    this.setState({view: arg.view}, () => this.delayedViewRender)
                                                }
                                            }}
                                            events={events}
                                            eventSources={events}
                                            eventContent={(e) =>
                                                <EventContent event={e} employees={employees}/>
                                            }
                                        />}
                                    </div>
                                </SplitPane>
                            </Col>
                        </Row>
                        {showAssignedArboristModal && assignedArboristModal()}
                    </div>
                : null
        );
    }

    getScrollTime(event) {
        return moment(event.start).add(-1, 'hour').format('HH:mm:ss');
    }
}

SchedulerEventDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
    resourceType: PropTypes.string,
    inModal: PropTypes.bool.isRequired,
    save: PropTypes.func
};

SchedulerEventDialog.defaultProps = {
    inModal: true,
    handleClose: () => {
    }
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
        client: state.client.customerInfo,
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch),
        findTimeApi: bindActionCreators(findTimeApi, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerEventDialog);
