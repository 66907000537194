import {defaultDateFormat, defaultTimeFormat} from "../../common/commonHandlers";
import Datetime from "react-datetime";
import React, {memo} from "react";

function areEquals(prev, next) {
    return prev?.completedDate?.toISOString() === next?.completedDate?.toISOString() &&
        prev?.updateInProgress === next?.updateInProgress
}

const MemoizedCompletedDate = ({completedDate, updateInProgress, onChange, className}) => {
    return <Datetime
        dateFormat={defaultDateFormat}
        timeFormat={defaultTimeFormat}
        defaultValue={completedDate}
        value={completedDate}
        className={className || "table-auto"}
        isClearable
        inputProps={{
            disabled: updateInProgress,
            placeholder: 'Date',
            className: "global-date-time-picker-input form-control"
        }}
        closeOnSelect={true}
        onChange={onChange}
    />
}

export default memo(MemoizedCompletedDate, areEquals);


