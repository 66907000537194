export const [
  EDITING_CONTACT_ID,
  FETCH_CONTACT,
  CREATE_NEW_CONTACT,
  SET_CUSTOMER_ID,
  UPDATE_CONTACT_ATTR,
  DELETE_CONTACT,
] = [
  "EDITING_CONTACT_ID",
  "FETCH_CONTACT",
  "CREATE_NEW_CONTACT",
  "SET_CUSTOMER_ID",
  "UPDATE_CONTACT_ATTR",
  "DELETE_CONTACT",
]
