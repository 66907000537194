import { EDITING_SERVICE_ID, EDIT_FILTER_TEXT } from './constants'

import { fetchService, saveService } from '../../actions/services'

export const loadServiceForEditing = editingServiceId => dispatch => {
  dispatch({ type: EDITING_SERVICE_ID, editingServiceId })

  switch (editingServiceId) {
    case null:
      console.log('Not editing any service')
      break
    case 0:
      console.log('This service id is 0, not going to fetch it!')
      break
    default:
      dispatch(fetchService(editingServiceId))
  }
}

export const saveOrCreateService = id => (dispatch, getState) => {
  console.log('in save or create')
  //when we create the service, we need to know its id so we can mark it for editing
  //so it reloads properly in teh UI
  dispatch(saveService(id)).then(response => {
    dispatch(loadServiceForEditing(response.data.id)) //for paranoia
  })
}

export const editFilterText = filterText => {
  return { type: EDIT_FILTER_TEXT, filterText }
}
