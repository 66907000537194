import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";

export const searchForPlant = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/plants/plant_search`,
        params: {q: searchText}
    };

    apiCall("Searching for plant", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};


export const transferPlant = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/plants/plant_transfer`,
        method: 'PUT',
        data: resource
    };

    apiCall("Assets has been transferred", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};