import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect, mapPersonSearch, optionsFor} from "../../common/commonHandlers";

export const load = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/map_scheduler`,
        params: filter
    };

    apiCall("Getting proposal services", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadContextData = (callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/sales_cross_view/service_types`},
        {url: `/api/new_services`},
        {url: `/api/priorities/search`},
        {url: `/api/equipments/search`},
        {url: `/api/reasons`},
        {url: `/api/customer_types`},
        {url: `/api/plants`},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const data = {
            employees: all[0].data,
            service_types: mapForSelect(all[1].data),
            services: mapForSelect(all[2].data),
            priorities: all[3].data,
            equipments: all[4].data,
            reasons: all[5].data,
            customerTypes: mapForSelect(all[6].data),
            plants: all[7].data,
        };
        callback(data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForWorkOrder = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/new_search`,
        params: {q: searchText}
    };

    apiCall("Searching for work orders", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};


export const searchForProposal = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/search`,
        params: {q: searchText}
    };

    apiCall("Searching for proposals", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForCity = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for city", config, async config => {
        const response = await axios.request(config);
        callback(optionsFor(...response.data.map(x => x.city)));
    }, dispatch, getState, false);
};

export const searchForZip = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/zip_search`,
        params: {q: searchText}
    };

    apiCall("Searching for zip", config, async config => {
        const response = await axios.request(config);
        callback(optionsFor(...response.data.map(x => x.zip)));
    }, dispatch, getState, false);
};

export const updateEvent = (scheduler_event, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${scheduler_event.id}`,
        method: 'PUT',
        data: {scheduler_event}
    };

    apiCall("Updating scheduler event", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const loadEvents = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events`,
        params: {...params, map_scheduler_view: true}
    };
    apiCall("Loading events", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadProposalServices = (id, filter, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/map_scheduler/${id}`,
        params: filter
    };
    apiCall("Loading proposal services for proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const syncCalendar = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/sync_calendar`,
        method: 'POST'
    };
    apiCall("Scheduler refresh", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState);
};

export const addWo = (wo, selectedPs, callback) => (dispatch, getState) => {
    const config = {
        method: wo ? 'put' : 'post',
        url: wo ? `/api/work_orders/${wo}/add_ps_to_work_order` : '/api/work_orders/',
        data: {
            psid: selectedPs.join(',')
        }
    };

    apiCall("Saving work order", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const search = (q, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/search/search_for_guest`,
        params: {q}
    };
    apiCall("Searching for guests", config, async config => {
        const response = await axios.request(config);
        callback(mapPersonSearch(response.data));
    }, dispatch, getState, false);
};

export const removeProposalServicesFromWo = (ids, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: '/api/map_scheduler/remove_proposal_services_from_wo',
        data: {
            ids: ids.join(',')
        }
    };

    apiCall("Saving work order", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const removePsFromWo = (psid, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: '/api/work_orders/remove_ps_from_work_order',
        params:{psid}
    };

    apiCall("Deleting work order", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};