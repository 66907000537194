import axios from "axios"
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";

export const load = (callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees?disabled=true`},
        {url: `/api/new_services?bundle_service=false`},
        {url: `/api/service_types?disabled=false`},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const data = {
            salesArborists: all[0].data,
            services: mapForSelect(all[1].data),
            serviceTypes: mapForSelect(all[2].data)
        };
        callback(data);
    }, dispatch, getState, false);
};

export const fetchMyProposed = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/proposed`,
        params: filter
    };

    apiCall("Getting proposed services", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
