import React from "react";
import DefaultReportView from "./DefaultReportView";

const PaidReportTab = ({getRevenueReport}) => {
    const reportOptions = [
        {value: 'paid_report', label: 'Paid Report'},
    ]

    return <DefaultReportView
        title="Paid Reports"
        options={reportOptions}
        getRevenueReport={getRevenueReport}
    />
}

export default PaidReportTab;