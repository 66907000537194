import {Button, Col, Modal, Nav, NavItem, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import MDSpinner from "react-md-spinner";
import {isEmailValid} from "../../../../common/commonHandlers";

const isMobile = window.screen.width <= 768;

const SendCredentialsModal = ({
                                  showCredentialsModal,
                                  hideCredentialsModal,
                                  customerContacts,
                                  editContact,
                                  editingContactId,
                                  sendCredentials
                              }) => {
    const [loading, setLoading] = useState(true)
    const [selectedContacts, setSelectedContacts] = useState([])

    const validContactsIds = () => {
        const validContacts = []
        customerContacts.map(cc => {
            if (isEmailValid(cc.person_email)) {
                validContacts.push(cc.id)
            }
        })
        setSelectedContacts(validContacts)
    }

    useEffect(() => {
        if (customerContacts?.length > 0) {
            validContactsIds()
            setLoading(false)
        }
    }, [])

    return (
        <Modal show={showCredentialsModal}
               onHide={hideCredentialsModal}
               className="heightAuto fontIOS send-credentials-modal">
            <Modal.Header closeButton><Modal.Title>Send credential to:</Modal.Title></Modal.Header>
            <Modal.Body>
                {loading ?
                    <div className="vhCenter" style={{minHeight: "200px"}}>
                        <MDSpinner size={50}/>
                    </div>
                    :
                    <div className="max-height-600 boxContainer">
                        {customerContacts?.length > 0 && customerContacts.map(c => {
                            const isEmail = isEmailValid(c.person_email)
                            return (
                                <Row className="margin-0 wrapper">
                                    {isEmail ?
                                        <Col xs={1} className="textCenter">
                                            <input
                                                type="checkbox"
                                                checked={selectedContacts.includes(c.id)}
                                                onChange={() => {
                                                    if (selectedContacts.includes(c.id)) {
                                                        const filteredContacts = selectedContacts.filter(sc => sc !== c.id)
                                                        setSelectedContacts(filteredContacts)
                                                    } else {
                                                        setSelectedContacts(selectedContacts.concat(c.id))
                                                    }
                                                }}
                                            />
                                        </Col>
                                        :
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="tooltip">
                                                <strong>Add email address to send credentials!</strong>
                                            </Tooltip>}
                                        >
                                            <Col xs={1} className="textCenter">
                                                <input
                                                    type="checkbox"
                                                    disabled={true}/>
                                            </Col>
                                        </OverlayTrigger>
                                    }
                                    <Col xs={11} className="no-left-padding">
                                        <Nav
                                            key={c.id}
                                            bsStyle="pills"
                                            stacked
                                            activeKey={editingContactId}
                                            onSelect={editContact}
                                        >
                                            <NavItem
                                                key={c.id}
                                                eventKey={c.id}
                                            >
                                                <Row
                                                    style={c.contact_type === "Billing" ? {color: "#28a745"} : !isEmail ? {color: "#7c7c7c"} : null}
                                                    className="wrapper">
                                                    <Col xs={isMobile ? 7 : 6}>
                                                        {c.person_name}<br/>
                                                        {c.person_email}
                                                    </Col>
                                                    {isMobile ?
                                                        <Col xs={5}>
                                                            {c.person_phone}<br/>
                                                            {c.contact_type}
                                                        </Col>
                                                        :
                                                        <>
                                                            <Col xs={3}>
                                                                {c.person_phone}
                                                            </Col>
                                                            <Col xs={3}>
                                                                {c.contact_type}
                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col xs={6} className="text-left button-left-wrapper">
                        <Button
                            bsStyle="info"
                            className="button-left"
                            onClick={() => setSelectedContacts([])}
                        >
                            Uncheck All
                        </Button>
                        <Button
                            bsStyle="info"
                            className="button-left"
                            onClick={() => validContactsIds()}
                        >
                            Check All
                        </Button>
                    </Col>
                    <Col xs={6} className="button-right-wrapper">
                        <Button
                            bsStyle="info"
                            className="button-right"
                            onClick={hideCredentialsModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            bsStyle="success"
                            className="button-right"
                            onClick={() => sendCredentials(selectedContacts, hideCredentialsModal)}
                            disabled={selectedContacts.length === 0}
                        >
                            Send Credential
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default SendCredentialsModal;