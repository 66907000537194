import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {Link} from "react-router";
import MDSpinner from "react-md-spinner";


const InfoModal = ({isOpen, closeModal, title, text, getProposals, searchId}) => {
    const [readyOpenProposals, setReadyOpenProposals] = useState(true)
    const [openProposals, setOpenProposals] = useState([])

    const tableRef = useRef()

    useEffect(() => {
        setReadyOpenProposals(false)
        getProposals(searchId, res => {
            setOpenProposals(res)
            setReadyOpenProposals(true)
        })
    }, [])

    return (
        <Modal
            bsSize="large"
            animation={false}
            centered={true}
            show={isOpen}
            onHide={closeModal}
            backdrop="static"
            dialogClassName="info-modal"
            className={"heightAuto maxHeight90 fontIOS"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: '400px'}}>
                <Row>
                    <Col md={12}>
                        {text}
                        {readyOpenProposals ?
                            openProposals?.length > 0 ?
                                <BootstrapTable
                                    ref={tableRef}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    remote
                                    className="wrapped"
                                    data={openProposals}
                                >
                                    <TableHeaderColumn
                                        isKey
                                        dataField="id"
                                        width={"10%"}
                                        hidden
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="proposal_no"
                                        dataFormat={(cell) => <p className='text-center no-margin'>{cell}</p>}
                                        width={"10%"}
                                    >
                                        #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="proposal_title"
                                        width={"25%"}
                                    >
                                        Proposal Title
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="site_name"
                                        width={"25%"}
                                    >
                                        Site name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="proposal_status"
                                        width={"10%"}
                                    >
                                        Status
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="number_of_proposal_services"
                                        dataFormat={(cell) => <p className='text-center no-margin'>{cell}</p>}
                                        width={"20%"}
                                    >
                                        Proposal services
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataFormat={(cell) => <Link target="_blank" to={`/mapview/${cell}`}>Go to proposal</Link>}
                                        width={"20%"}
                                    >
                                        Link
                                    </TableHeaderColumn>
                                </BootstrapTable>
                                :
                                <div className='vhCenter'>
                                    <b><i>There is no open proposals</i></b>
                                </div>
                            :
                            <div className='vhCenter'>
                                <MDSpinner size={70} borderSize={2}/>
                            </div>
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col className="text-right">
                        <Button
                            bsSize="small"
                            bsStyle="success"
                            onClick={() => closeModal()}
                        >
                            Ok
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
};

export default InfoModal;