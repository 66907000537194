import React, {useState, useEffect, useCallback} from "react";
import {PlaidLink} from './PlaidLink'
import MDSpinner from "react-md-spinner";
import {Button, Col, FormControl, FormGroup, InputGroup, Row} from "react-bootstrap";
import axios from "axios";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from './PlaidApi'

const isMobile = window.screen.width < 1024;

const Plaid = ({total, setACHCharge, reloadInvoice, invoiceToken, invoiceNo, actions}) => {
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [isAuthorized, setIsAuthorized] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [error, setError] = useState(false)
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)
    const [payPartial, setPayPartial] = useState(false)
    const [partialAmount, setPartialAmount] = useState('')
    const [acceptPartialValue, setAcceptPartialValue] = useState(false)


    useEffect(() => {
        const existingToken = localStorage.getItem('link_token')
        const plaidCustomerId = localStorage.getItem('plaid_customer')

        if (!existingToken) {
            actions.createLinkToken(invoiceNo, token => {
                localStorage.setItem('link_token', token)
                localStorage.setItem('invoice_token', invoiceToken)
                setToken(token)
            })
        } else if (existingToken && plaidCustomerId) {
            setToken(existingToken)
            setIsAuthorized(true)
            setCustomer(plaidCustomerId)
        }
    }, []);

    useEffect(() => {
        setACHCharge(acceptPartialValue, partialAmount)
    }, [partialAmount, acceptPartialValue]);


    const payWithPlaid = (e, amount, stripeFee) => {
        setIsPaymentLoading(true)
        e.preventDefault();
        actions.payWithPlaid(amount,
            customer,
            invoiceToken,
            acceptPartialValue ? partialAmount : 0,
            stripeFee,
            response => {
                if (response.status === 200) {
                    const localStorageData = ["invoice_token", "link_token", "plaid_customer"]
                    localStorageData.map(data => localStorage.removeItem(data))
                    reloadInvoice();
                }
            }
        )
    };

    const onSuccess = useCallback(
        (public_token, metadata) => {
            setIsLoading(true)
            const url = '/api/stripe/plaid_create';
            axios.post(url, {
                public_token: public_token,
                account_id: metadata.account_id
            }).then(response => {
                if (response.status === 200) {
                    setIsAuthorized(true)
                    setCustomer(response.data.customer)
                    setIsLoading(false)
                }
            }).catch(error => {
                if (error) {
                    setIsAuthorized(false)
                    setIsLoading(false)
                    setError(true)
                }
            })
        },
        []
    );

    const calculateACHValue = (amount) => {
        if (amount) {
            let basedAmount = parseFloat(amount)
            let totalACHAmount = basedAmount / (1 - 0.008)

            if (basedAmount > 625) {
                return (basedAmount + 5).toFixed(2)
            } else {
                return (basedAmount + (totalACHAmount - basedAmount)).toFixed(2)
            }
        }
    }

    const calculateStripeProcessingFee = (amount) => {
        if (amount) {
            let basedAmount = parseFloat(amount)
            let totalACHAmount = basedAmount / (1 - 0.008)

            if (basedAmount > 625) {
                return 5
            } else {
                return (totalACHAmount - basedAmount).toFixed(2)
            }
        }
    }


    const validPartialAmount = partialAmount.length === 0 || partialAmount < 0 || partialAmount > total ? total : partialAmount;
    const transactionValue = acceptPartialValue && partialAmount > 0 ? partialAmount : total

    if (!token) {
        return <MDSpinner/>
    } else {
        return (<div>
            <PlaidLink token={token} onSuccess={onSuccess}/>
            {isLoading ? <div className="vertical-align ml-20 mt10"><MDSpinner/></div> : (
                isAuthorized ? (
                    <Row className={`d-flex no-left-margin mt-15 ${isMobile && 'columnDirection'}`}>
                        <Col xs={isMobile ? 7 : 4}>
                            <Row>
                                <Button disabled={isPaymentLoading} className="no-border-radius"
                                        onClick={e => payWithPlaid(e, total, calculateStripeProcessingFee(transactionValue))}>{isPaymentLoading ? "...Loading" : acceptPartialValue ? `Pay $${calculateACHValue(partialAmount)}` : `Pay $${calculateACHValue(total)}`}</Button>
                                <Button onClick={() => setPayPartial(!payPartial)}
                                        className="no-border-radius">Pay
                                    partial</Button>
                            </Row>
                        </Col>
                        {payPartial ?
                            <Col xs={5} className={isMobile && 'mt-15 width-80 no-left-padding'}>
                                <FormGroup className='d-flex bottom0'>
                                    <InputGroup>
                                        <InputGroup.Addon>$</InputGroup.Addon>
                                        <FormControl type="number" value={partialAmount}
                                                     onChange={e => {
                                                         const value = e.target.value
                                                         const match_value = value ? value.toString().match(/^(\d+\.\d{0,2})/) : value

                                                         if(value > total){
                                                             setPartialAmount(total)
                                                         }else{
                                                             setPartialAmount(match_value ? match_value[1] : value)
                                                         }
                                                         setAcceptPartialValue(false)
                                                     }}
                                                     className="border_none"
                                        />
                                    </InputGroup>
                                    <button className="border_none" onClick={() => {
                                        setPartialAmount(validPartialAmount)
                                        setAcceptPartialValue(true)
                                    }}>Accept
                                        Amount
                                    </button>
                                </FormGroup>
                            </Col>
                            : null}
                    </Row>) : error ?
                    <span className="vertical-align ml-20"><i
                        className="glyphicon glyphicon-warning-sign mr-9 font16 colorRed"/>Your bank account can't be verified</span> : null)}
        </div>)
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(null, mapDispatchToProps)(Plaid)
