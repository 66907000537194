import React, {useEffect, useRef, useState} from "react";
import {hexToRGB, mapForSelect, reverseColor, RGBToHex, select} from "../../common/commonHandlers";
import Select from "react-select";
import {Button, Glyphicon, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import DutyModal from "./DutyModal";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DutyCard from "./DutyCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const EmployeeCard = ({employeeCard, employee, updateEmployee, editMode, removeEmployee, haveSubordinate, employeesOptions, moveEmployee, employeesInChart, employeesInChartForDemote, handleExpandTree, handleShowEmployee, editedEmployee, setEditedEmployee, typeCardColor, typeBorderColor, backgroundColor, borderColor}) => {
    const [selectedEmployee, setSelectedEmployee] = useState(employeeCard)
    const [dutyModal, setDutyModal] = useState(false)
    const [editedDuty, setEditedDuty] = useState(undefined)
    const [moveEmployeeSelects, setMoveEmployeeSelects] = useState({})
    const [deleteType, setDeleteType] = useState(null)

    let ref = useRef(null)
    let refMove = useRef(null)
    let refPromote = useRef(null)
    let refDegrade = useRef(null)

    useEffect(() => {
        setEditedEmployee(null)
    }, [editMode])

    const employeesInChartOptions = mapForSelect(employeesInChart)
    const employeesInChartForDemoteOptions = mapForSelect(employeesInChartForDemote)

    const isLeader = selectedEmployee?.supervisor === "leader"
    const isSupervisorLeader = selectedEmployee?.supervisor === 1

    const deleteOptions = [
        {value: "hard_delete", label: "Delete employee with subordinates"},
        {value: "soft_delete", label: "Delete only employee"}
    ]

    const updateDuty = (duty) => {
        if (duty.id) {
            const index = selectedEmployee?.duties.findIndex(d => d.id === duty.id)
            selectedEmployee.duties.splice(index, 1, duty)
        } else {
            if (selectedEmployee.duties && selectedEmployee.duties.length > 0) {
                const newId = Math.max(...selectedEmployee?.duties?.map(o => o.id)) + 1
                if (newId) {
                    selectedEmployee.duties.push({...duty, id: newId})
                }
            } else if (!selectedEmployee?.duties?.some(d => d.id === 1)) {
                selectedEmployee.duties = [{...duty, id: 1}]
            }
        }
        setSelectedEmployee({...selectedEmployee})
    }
    const color = employee?.color && hexToRGB(employee?.color)

    const colorBackgroundText = (text, className) => {
        return <span className={`${className} color_background_text`} style={{
            background: `rgb(${color?.r},${color?.g},${color?.b})`,
            color: `${reverseColor(employee?.color)}`
        }}>{text}</span>
    }

    const selectedCardBackground = () => {
        if (typeCardColor === 'none') {
            return {r: 255, g: 255, b: 255, a: 1}
        } else if (typeCardColor === 'color') {
            return backgroundColor
        } else if (typeCardColor === 'user_color') {
            return color
        }
    }

    const cardBackground = () => {
        if (typeCardColor === 'none') {
            return 'rgba(255,255,255,1)'
        } else if (typeCardColor === 'color') {
            return `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`
        } else if (typeCardColor === 'user_color') {
            return `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${0.6})`
        }
    }

    const cardBorderColor = () => {
        if (typeBorderColor === 'color') {
            return `1px solid rgba(${borderColor.r}, ${borderColor.g}, ${borderColor.b}, ${borderColor.a})`
        } else if (typeBorderColor === 'user_color') {
            return `2px solid rgb(${color?.r}, ${color?.g}, ${color?.b})`
        }
    }

    const editDuty = duty => {
        setEditedDuty(duty);
        setDutyModal(true)
    }

    const deleteDuty = duty => {
        const index = selectedEmployee?.duties.findIndex(d => d.id === duty.id)
        selectedEmployee.duties.splice(index, 1)
        setSelectedEmployee({...selectedEmployee})
    }

    const renderDuty = () => {
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        const onDragEnd = (result) => {
            if (!result.destination) {
                return;
            }
            const newDuties = reorder(
                selectedEmployee?.duties,
                result.source.index,
                result.destination.index
            )
            setSelectedEmployee({...selectedEmployee, duties: newDuties})
        }

        const getItemStyle = (isDragging, draggableStyle) => ({
            ...draggableStyle,
            position: isDragging && 'absolute !important',
            background: isDragging && "#9f9f9f",
            height: isDragging && 'fit-content',
        });

        return (editedEmployee ?
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                className="duty"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{color: selectedCardBackground() ? reverseColor(RGBToHex(selectedCardBackground())) : "#444444"}}
                            >
                                {selectedEmployee?.duties?.map((d, i) =>
                                    <Draggable
                                        key={d.id}
                                        draggableId={d.id?.toString()}
                                        index={i}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className="draggable-duty"
                                                key={i}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <DutyCard
                                                    duty={d}
                                                    i={i}
                                                    allowEdit={editedEmployee}
                                                    editDuty={() => editDuty(d)}
                                                    deleteDuty={() => deleteDuty(d)}
                                                    colorBackgroundText={colorBackgroundText}
                                                    backgroundColor={cardBackground()}
                                                    cardBackground={selectedCardBackground}
                                                />
                                            </div>)}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>)}
                    </Droppable>
                </DragDropContext>
                :
                <div className="duty">
                    {selectedEmployee?.duties?.map((d, i) => {
                        return <div className="draggable-duty" style={{color: selectedCardBackground() ? reverseColor(RGBToHex(selectedCardBackground())) : "#444444"}}>
                            <DutyCard
                                duty={d}
                                i={i}
                                allowEdit={editedEmployee}
                                editDuty={() => editDuty(d)}
                                deleteDuty={() => deleteDuty(d)}
                                colorBackgroundText={colorBackgroundText}
                                cardBackground={selectedCardBackground}
                            />
                        </div>
                    })}
                </div>
        )
    }

    const handleEditEmployee = () => {
        if (editedEmployee) {
            setEditedEmployee(null)
        } else {
            setEditedEmployee(selectedEmployee.id)
        }
    }

    return (
        <div id="employee-card" style={{color: selectedCardBackground() ? reverseColor(RGBToHex(selectedCardBackground())) : "#444444"}}>
            {employee ?
                <div>
                    <div className={`full-employee-card`} style={{backgroundColor: cardBackground(), border: cardBorderColor()}}>
                        {editMode && selectedEmployee?.supervisor !== "leader" && <OverlayTrigger placement="left" overlay={<Tooltip>Show employee</Tooltip>}>
                            <div className="show-employee-card">
                                <ColorCheckbox value={selectedEmployee.show}
                                               onChange={() => handleShowEmployee(selectedEmployee, !selectedEmployee?.show)}/>
                            </div>
                        </OverlayTrigger>}
                        <div className={editMode ? "admin-header-on" : "admin-header-off"}>
                            {editMode && <span onClick={() => handleEditEmployee()}>{editedEmployee ? "Click to lock edit" : "Click to unlock edit"}</span>}
                            {editedEmployee && <div className="icons-employee-card"  style={{color: selectedCardBackground() ? reverseColor(RGBToHex(selectedCardBackground())) : "#444444"}}>
                                {!isLeader && <OverlayTrigger ref={r => (refMove = r)} trigger="click" placement="top" overlay={
                                    <Popover id="popover-positioned-top" title="Move Employee">
                                        <span>Who will be new supervisor?</span>
                                        <Select
                                            className="select"
                                            classNamePrefix="select"
                                            value={select(employeesInChartOptions, moveEmployeeSelects?.newSupervisor)}
                                            options={employeesInChartOptions}
                                            isClearable
                                            onChange={e => setMoveEmployeeSelects({...moveEmployeeSelects, newSupervisor: e?.value})}
                                        />
                                        <div className="text-right mt10">
                                            <Button bsStyle="warning" bsSize="xsmall" className="mr-10" onClick={() => refMove.handleHide()}>
                                                Cancel
                                            </Button>
                                            <Button
                                                bsStyle="success"
                                                bsSize="xsmall"
                                                onClick={() => {moveEmployee(selectedEmployee, moveEmployeeSelects); refMove.handleHide()}}
                                                disabled={!moveEmployeeSelects?.newSupervisor}
                                            >
                                                Move
                                            </Button>
                                        </div>
                                    </Popover>
                                }>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Move to</Tooltip>}>
                                        <Glyphicon glyph='share-alt' className="pointer mr-5" onClick={() => {setMoveEmployeeSelects({type: "move"}); employeesInChartForDemote.length > 0 && refDegrade.handleHide(); ref.handleHide(); !isSupervisorLeader && refPromote.handleHide()}}/>
                                    </OverlayTrigger>
                                </OverlayTrigger>}
                                {!isLeader && !isSupervisorLeader && <OverlayTrigger ref={r => (refPromote = r)} trigger="click" placement="top" overlay={
                                    <Popover id="popover-positioned-top" title="Promote Employee">
                                        <div className="text-right mt10">
                                            <Button bsStyle="warning" bsSize="xsmall" className="mr-10" onClick={() => refPromote.handleHide()}>
                                                Cancel
                                            </Button>
                                            <Button
                                                bsStyle="success"
                                                bsSize="xsmall"
                                                onClick={() => {moveEmployee(selectedEmployee, moveEmployeeSelects); refPromote.handleHide()}}
                                            >
                                                Promote
                                            </Button>
                                        </div>
                                    </Popover>
                                }>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Promote Employee</Tooltip>}>
                                        <Glyphicon glyph='arrow-up' className="pointer mr-5" onClick={() => {setMoveEmployeeSelects({type: "promote"}); employeesInChartForDemote.length > 0 && refDegrade.handleHide(); ref.handleHide(); refMove.handleHide()}}/>
                                    </OverlayTrigger>
                                </OverlayTrigger>}
                                {employeesInChartForDemote.length > 0 ?
                                    !isLeader && <OverlayTrigger ref={r => (refDegrade = r)} trigger="click" placement="top" overlay={
                                        <Popover id="popover-positioned-top" title="Demote Employee">
                                            <span>Who will be new supervisor?</span>
                                            <Select
                                                className="select"
                                                classNamePrefix="select"
                                                value={select(employeesInChartForDemoteOptions, moveEmployeeSelects?.newSupervisor)}
                                                options={employeesInChartForDemoteOptions}
                                                isClearable
                                                onChange={e => setMoveEmployeeSelects({...moveEmployeeSelects, newSupervisor: e?.value})}
                                            />
                                            <div className="text-right mt10">
                                                <Button bsStyle="warning" bsSize="xsmall" className="mr-10" onClick={() => refDegrade.handleHide()}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    bsStyle="success"
                                                    bsSize="xsmall"
                                                    onClick={() => {moveEmployee(selectedEmployee, moveEmployeeSelects); refDegrade.handleHide()}}
                                                    disabled={!moveEmployeeSelects?.newSupervisor}
                                                >
                                                    Demote
                                                </Button>
                                            </div>
                                        </Popover>
                                    }>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Demote Employee</Tooltip>}>
                                            <Glyphicon glyph='arrow-down' className="pointer mr-5" onClick={() => {setMoveEmployeeSelects({type: "demote"}); ref.handleHide(); refMove.handleHide(); !isSupervisorLeader && refPromote.handleHide()}}/>
                                        </OverlayTrigger>
                                    </OverlayTrigger>
                                    :
                                    !isLeader && <OverlayTrigger placement="top" overlay={<Tooltip>Demote Employee</Tooltip>}>
                                        <Glyphicon glyph='arrow-down' className="pointer mr-5 disable-icon"/>
                                    </OverlayTrigger>
                                }
                                <OverlayTrigger ref={r => (ref = r)} trigger="click" placement="right" overlay={
                                    <Popover id="popover-positioned-right" title={haveSubordinate ? "Are you sure you want to delete this employee with all subordinates?" : "Are you sure you want to delete this employee?"}>
                                        <Select
                                            className="select"
                                            classNamePrefix="select"
                                            value={select(deleteOptions, deleteType)}
                                            options={deleteOptions}
                                            isClearable
                                            onChange={e => setDeleteType(e?.value)}
                                        />
                                        <div className="text-right mt10">
                                            <Button bsStyle="warning" bsSize="xsmall" className="mr-10" onClick={() => ref.handleHide()}>
                                                Cancel
                                            </Button>
                                            <Button bsStyle="danger" bsSize="xsmall" onClick={() => {removeEmployee(selectedEmployee, deleteType); ref.handleHide()}}>
                                                Delete
                                            </Button>
                                        </div>
                                    </Popover>
                                }>
                                    <FontAwesomeIcon icon={faTrash} className="pointer" onClick={() => {!isLeader && refMove.handleHide(); !isLeader && employeesInChartForDemote.length > 0 && refDegrade.handleHide(); !isLeader && !isSupervisorLeader && refPromote.handleHide()}}/>
                                </OverlayTrigger>
                            </div>}
                        </div>
                        <div className={`${selectedEmployee.show ? "shown_employee-card" : "hidden_employee-card"}`}>
                            {(employee.images && employee.images.length > 0) ?
                                <img src={employee.images.find(i => i.primary)?.original}/>
                                :
                                <i>Employee dont have photos!</i>
                            }
                            <div className="employee_name">
                                {editedEmployee ?
                                    <div style={{color: "#000"}}>
                                        <Select
                                            className="select"
                                            classNamePrefix="select"
                                            value={select(employeesOptions, selectedEmployee?.person_id)}
                                            options={employeesOptions}
                                            isClearable
                                            onChange={e => setSelectedEmployee({...selectedEmployee, person_id: e?.value})}
                                            placeholder="Select Employee"
                                        />
                                    </div>
                                    :
                                    <p className='font14'>{employee.name}</p>
                                }
                            </div>
                            <div className="role">
                                <p>ROLE</p>
                                {colorBackgroundText(employee.job_title)}
                            </div>
                            {renderDuty()}
                            {editedEmployee &&
                                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Add new duty</Tooltip>}>
                                    <Glyphicon glyph='plus' className="pointer" onClick={() => {
                                        setDutyModal(true);
                                        setEditedDuty(undefined)
                                    }}/>
                                </OverlayTrigger>}
                        </div>
                        {editedEmployee ?
                            <div className="admin-footer">
                                <p onClick={() => {updateEmployee(selectedEmployee); setEditedEmployee(null)}}>Save</p>
                                {haveSubordinate && <>
                                    <div className="trapeze"/>
                                    <Glyphicon
                                        glyph='menu-down'
                                        className={`triangle ${selectedEmployee.showSubordinates ? "show_subordinates" : "hide_subordinates"}`}
                                        onClick={() => {handleExpandTree(selectedEmployee); setSelectedEmployee({...selectedEmployee, showSubordinates: !selectedEmployee?.showSubordinates})}}/>
                                </>}
                            </div>
                            :
                            editMode && <div className="footer">
                                {haveSubordinate && <>
                                    <div className="trapeze"/>
                                    <Glyphicon
                                        glyph='menu-down'
                                        className={`triangle ${selectedEmployee.showSubordinates ? "show_subordinates" : "hide_subordinates"}`}
                                        onClick={() => {handleExpandTree(selectedEmployee); setSelectedEmployee({...selectedEmployee, showSubordinates: !selectedEmployee?.showSubordinates})}}/>
                                </>}
                            </div>
                        }
                    </div>
                </div>
                :
                <div className="empty-employee-card" style={{backgroundColor: typeCardColor === 'user_color' ? '#fff' : cardBackground(), border: cardBorderColor()}}>
                    <Select
                        className="select"
                        classNamePrefix="select"
                        value={select(employeesOptions, selectedEmployee?.person_id)}
                        options={employeesOptions}
                        isClearable
                        onChange={e => setSelectedEmployee({...selectedEmployee, person_id: e?.value})}
                        placeholder="Select Employee"
                    />
                    <Glyphicon
                        glyph='ok'
                        className="pointer mr-10"
                        onClick={() => updateEmployee(selectedEmployee)}
                    />
                    <FontAwesomeIcon icon={faTrash} className="pointer" onClick={() => removeEmployee(selectedEmployee, "hard_delete")}/>
                </div>
            }
            {dutyModal && <DutyModal
                duty={editedDuty}
                isOpen={dutyModal}
                onHide={() => setDutyModal(false)}
                updateDuty={updateDuty}
            />}
        </div>
    )
}

export default EmployeeCard;