import React from "react"
import MasterDetail from "../../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Row, Button, Panel} from "react-bootstrap";
import {merge} from "../../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../../common/clientActionsBuilder";
import {connect} from "react-redux";
import ResourceComponent from "../../../components/ResourceComponent";

const api = createApiService('text_message_templates', 'text_message_template', 'Text message template');

class TextMessageTemplates extends ResourceComponent {
    defaultValues = {name: '', message: ''};
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            resource: {
                message: '',
                name: ''
            }
        }
    }


    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };


    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };


    render() {
        const {resource, resources} = this.state;

        return (
            resources ? <MasterDetail
                resourceName="Text message template"
                resourceNamePlural="Text message templates"
                resources={resources}
                resource={resource}
                select={this.handleSelect}
                save={() => this.props.actions.save(resource, this.reloadResources)}
                delete={() => this.props.actions.remove(resource, x => {
                    this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                })}>
                {resource && <div>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>
                                    Name
                                </ControlLabel>
                                <FormControl
                                    name="name"
                                    onChange={this.updateResourceAttr}
                                    value={resource.name}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <ControlLabel>
                                    Message
                                </ControlLabel>
                                <FormControl
                                    style={{"min-width": '100%'}}
                                    name="message"
                                    componentClass='textarea'
                                    rows={3}
                                    maxLength={1600}
                                    onChange={this.updateResourceAttr}
                                    value={resource.message}
                                />
                                {resource.message?.length || 0}/160 = {Math.ceil(resource.message?.length/160) > 1 ? `${Math.ceil(resource.message?.length/160)} messages` : `${Math.ceil(resource.message?.length/160)} message`}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='no-margin'>
                        <Panel>
                            <Col md={12}>
                                <Col md={3} className='d-flex columnDirection'>
                                    <h4>Proposal variables:</h4>
                                    <p><b>{"{{arborist_name}}"}</b> - Arborist name</p>
                                    <p><b>{"{{recipient_name}}"}</b> - Customer name</p>
                                    <p><b>{"{{message_type}}"}</b> - Message type eg. proposal, invoice, work order, message </p>
                                    <p><b>{"{{proposal_no}}"}</b> - Proposal number</p>
                                    <p><b>{"{{link}}"}</b> - Link to proposal customer view</p>
                                </Col>
                                <Col md={3} className='d-flex columnDirection'>
                                    <h4>Invoice variables:</h4>
                                    <p><b>{"{{arborist_name}}"}</b> - Arborist name</p>
                                    <p><b>{"{{recipient_name}}"}</b> - Customer name</p>
                                    <p><b>{"{{message_type}}"}</b> - Message type eg. proposal, invoice, work order, message </p>
                                    <p><b>{"{{invoice_no}}"}</b> - Invoice number</p>
                                    <p><b>{"{{link}}"}</b> - Link to invoice customer view</p>
                                </Col>
                                <Col md={3} className='d-flex columnDirection'>
                                    <h4>Work order:</h4>
                                    <p><b>{"{{arborist_name}}"}</b> - Arborist name</p>
                                    <p><b>{"{{recipient_name}}"}</b> - Customer name</p>
                                    <p><b>{"{{message_type}}"}</b> - Message type eg. proposal, invoice, work order, message </p>
                                    <p><b>{"{{work_order_no}}"}</b> - Work order number</p>
                                </Col>
                                <Col md={3} className='d-flex columnDirection'>
                                    <h4>Message variables:</h4>
                                    <p><b>{"{{arborist_name}}"}</b> - Arborist name</p>
                                    <p><b>{"{{recipient_name}}"}</b> - Customer name</p>
                                    <p><b>{"{{message_type}}"}</b> - Message type eg. proposal, invoice, work order, message </p>
                                </Col>
                            </Col>
                        </Panel>
                    </Row>
                </div>
                }
            </MasterDetail> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(TextMessageTemplates)
