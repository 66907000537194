import React from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {hexToRGB} from "../../../../../common/commonHandlers";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import quickbooks from "../../../CustomerInfo/quickbooks.png";

const TimelineElementWrapper = ({children, employeeName, employeeDisabled, bottomElement, color, createdAt, topElement, customClassName, isShowDetail, hideEvent, employeePrimaryPhoto, qbNote}) => {
    const RGBColor = color && color.includes('#') && hexToRGB(color)
    const initials = employeeName && employeeName.match(/\b(\w)/g).join('')
    return (
        <div className={`container ${customClassName ? customClassName : ""}`}>
            <div className='circle well'/>
            <div className='circle vhCenter' style={{borderColor: `${color}`, background: `rgba(${RGBColor.r}, ${RGBColor.g}, ${RGBColor.b}, 0.2)`}}>
                <OverlayTrigger placement="right"
                                overlay={<Tooltip id="tooltip">{employeeName}</Tooltip>}>
                    {qbNote ? <img src={quickbooks} alt=" "/> :  employeePrimaryPhoto?.mini ? <img src={employeePrimaryPhoto?.mini} alt=" "/> : <span>{initials}</span>}
                </OverlayTrigger>
            </div>
            <div className='triangle' style={{borderColor: `transparent ${color} transparent transparent`}}/>
            <div className="content" style={{borderColor: `${color}`, background: `${!qbNote ? `rgba(${RGBColor.r}, ${RGBColor.g}, ${RGBColor.b}, 0.2)` : `rgba(44, 160, 28, 0.2)`}`}}>
                <Row className="space-between-end font10">
                    <Col className="date" md={topElement ? 8 : 12} xs={topElement ? 8 : 12}>{createdAt && moment(createdAt).format('LLLL')}</Col>
                    <Col md={4} xs={4} className="text-right-top">
                        {topElement && topElement}
                        {isShowDetail && hideEvent && !qbNote && <OverlayTrigger placement="top" overlay={<Tooltip id="user_tooltip">Hide</Tooltip>}>
                            <FontAwesomeIcon icon={faArrowUp} className="ml-3 pointer" onClick={hideEvent} style={{color: "#ff0000"}}/>
                        </OverlayTrigger>}
                    </Col>
                </Row>
                {children}
                <Row className="space-between-end font10">
                    <Col md={6} xs={6}>{bottomElement && bottomElement}</Col>
                    <Col md={6} xs={6}  className="text-right font10">{qbNote && "Synced by: "}{employeeName && employeeName}{employeeDisabled && " (disabled)"}</Col>
                </Row>
            </div>
        </div>
    )
}

export default TimelineElementWrapper;