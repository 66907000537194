import React from 'react';
import PropTypes from "prop-types"
import {Col, ControlLabel, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import {mapForSelect, select} from "../../../common/commonHandlers";
import * as actions from "./AssetEditorModalApi";
import ResourceComponent from "../../../components/ResourceComponent";
import Select from "react-select";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class PlantStatsForm extends ResourceComponent {
    state = {
        resource: {
            height: null,
            width: null,
            dbh: null,
            stem_count: null,
            condition_type: null,
            conditions: [],
        },
        factors_options: [],
    };

    componentDidMount() {
        const {plantStats, plant_id} = this.props;
        const {resource} = this.state;
        this.props.actions.fetchConditions(conditions => this.setState({
            resource: {...resource, conditions, ...plantStats}
        }))
        plant_id && this.props.actions.fetchFactors(plant_id, plant => this.setState({
            factors_options: plant.plant_factors
        }))
    }

    componentDidUpdate(prevProps, prevState) {
        const {asset_id, plant_id, plantStats} = this.props
        const {resource} = this.state;
        if (prevProps.asset_id && asset_id !== prevProps.asset_id) {
            this.setState({resource: {...plantStats, conditions: resource.conditions}})
        }
        if (plant_id !== prevProps.plant_id) {
            this.props.actions.fetchFactors(plant_id, plant => this.setState({
                factors_options: plant.plant_factors
            }))
        }
        if (plantStats !== prevProps.plantStats) {
            this.setState({
                resource: {
                    ...plantStats,
                    conditions: resource.conditions
                }
            })
        }
    }

    onChange = () => {
        const {resource} = this.state;
        this.props.onChange(resource)
    };

    render() {
        const {resource, factors_options} = this.state;
        const {lockedCrewEditAccess} = this.props;

        let conditions_arr = []
        if (resource.conditions && resource.conditions.length > 0) {
            const new_arr = resource.conditions.map(({id, name}) => ({
                value: id,
                label: name
            }))
            conditions_arr.push(new_arr)
            conditions_arr = conditions_arr && conditions_arr[0].sort((a, b) => a.value - b.value)
        }
        return (
            <div>
                <Form>
                    <Row>
                        <Col md={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>DBH</ControlLabel>
                                <FormControl
                                    name="dbh"
                                    pattern="\d*"
                                    inputmode='decimal'
                                    type="number"
                                    placeholder="dbh"
                                    disabled={lockedCrewEditAccess}
                                    value={resource.dbh || ''}
                                    onChange={(e) => this.updateResourceAttr(e, this.onChange)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Condition</ControlLabel>
                                <Select className="Select"
                                        classNamePrefix="small-select"
                                        isDisabled={lockedCrewEditAccess}
                                        isSearchable={false}
                                        inputProps={{readOnly: true}}
                                        value={select(conditions_arr, resource.condition_type)}
                                        options={conditions_arr}
                                        onChange={this.selectResourceAttr('condition_type', this.onChange)}
                                        clearable={false}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Height</ControlLabel>
                                <FormControl
                                    name="height"
                                    pattern="\d*"
                                    inputmode='decimal'
                                    type="number"
                                    placeholder="Height"
                                    disabled={lockedCrewEditAccess}
                                    value={resource.height || ''}
                                    onChange={(e) => this.updateResourceAttr(e, this.onChange)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Width</ControlLabel>
                                <FormControl
                                    name="width"
                                    pattern="\d*"
                                    inputmode='decimal'
                                    type="number"
                                    placeholder="Width"
                                    disabled={lockedCrewEditAccess}
                                    value={resource.width || ''}
                                    onChange={(e) => this.updateResourceAttr(e, this.onChange)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Stems</ControlLabel>
                                <FormControl
                                    name="stem_count"
                                    pattern="\d*"
                                    inputmode='decimal'
                                    type="number"
                                    placeholder="stem count"
                                    disabled={lockedCrewEditAccess}
                                    value={resource.stem_count || ''}
                                    onChange={(e) => this.updateResourceAttr(e, this.onChange)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Factors</ControlLabel>
                                <Select className="Select"
                                        classNamePrefix="small-select"
                                        isDisabled={lockedCrewEditAccess}
                                        isMulti
                                        inputProps={{readOnly: true}}
                                        value={resource.factors && mapForSelect(resource.factors)}
                                        options={factors_options}
                                        onChange={this.selectResourceAttrWithName('factors', this.onChange)}

                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

PlantStatsForm.propTypes = {
    plantStats: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

PlantStatsForm.defaultProps = {};

function mapStateToProps(state) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlantStatsForm);
