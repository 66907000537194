import React, {Component} from 'react';
import * as actions from "./TrucksApi";
import createApiService from "../../common/clientActionsBuilder";
import {addAlert} from "../App/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    InputGroup, Nav, NavItem,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import moment from "moment-timezone";
import Select from "react-select";
import {defaultDateFormat, select} from "../../common/commonHandlers";
import Datetime from "react-datetime";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import ResourceComponent from "../../components/ResourceComponent";
import {browserHistory} from "react-router";
import './TruckNewServicePage.scss'


const trucks = createApiService('trucks', 'truck', 'Truck');
const api = {
    ...trucks,
    ...actions,
    addAlert
};
const isMobile = window.screen.width <= 1024;
class TruckNewServicePage extends ResourceComponent {
    state = {
        trucks: [],
        truck: {},
        truck_service_statuses: [],
        showMessage: false,
        messageText: 'Thank you for reporting service',
        confirmedSelect: false
    };

    componentDidMount() {
        let stuff = window.location.href.split('/')
        let truck_id = +stuff[stuff.length - 2]
        this.props.actions.getTrucksData(res => {
            if (res.status === 200) {
                if (truck_id) {
                    this.setState({
                        trucks: res.trucks,
                        truck_service_statuses: res.truck_service_statuses,
                        truck: res.trucks.find(t => t.id === truck_id),
                        confirmedSelect: res.trucks.find(t => t.id === truck_id)
                    })
                } else {
                    this.setState({trucks: res.trucks, truck_service_statuses: res.truck_service_statuses})
                }

            } else if (res.status === 404) {
                this.setState({showMessage: true, messageText: 'Sorry no trucks was found'})
            }
        });


    }

    isEmptyValue = (value, forceTwoDigit = false) => {
        if (value === undefined || value === null) {
            return ""
        }
        if (forceTwoDigit && value.split('.')[1]?.length > 2) {
            return parseFloat(value).toFixed(2)
        } else {
            return value
        }
    }
    handleSelect = (selected) => {
        const {trucks} = this.state
        this.setState({truck: trucks.find(t => t.id === selected.id)})
    }

    render() {
        const {trucks, truck, truck_service_statuses, showMessage, messageText, confirmedSelect} = this.state;
        return (

            truck && <Col md={12}>
                <Row>
                    {showMessage ?
                        <Col mdOffset={3} md={6} className='margin-top-20 text-center'><h3>{messageText}</h3>
                            <Button
                                bsStyle="primary"
                                className={"mt-20"}
                                onClick={() => browserHistory.push(`/`)}
                            >Go to Home Page</Button></Col> :
                        <Col>
                            {(!isMobile || (isMobile && !confirmedSelect) )&& <Col md={3}>
                                <h3 className="no-top-margin pointer">Trucks</h3>
                                <div className='truck_select'>
                                    <Nav
                                        bsStyle="pills"
                                        stacked
                                        onSelect={this.handleSelect}
                                    >
                                        {trucks && trucks.sort((a, b) => a.name.localeCompare(b.name)).map(t => (
                                            <NavItem key={t.id} eventKey={t}
                                                     active={truck && truck.id === t.id}>{t.name}</NavItem>
                                        ))}

                                    </Nav>
                                    {isMobile && <Button disabled={!truck.id}
                                                         onClick={() => this.setState({confirmedSelect: true})}>Select</Button>}
                                </div>

                            </Col>}
                            {(!isMobile || (isMobile && confirmedSelect) )&& <Col md={6} className='margin-top-20'>
                                <Row><h3 className='ml-10'>Service report: {truck.name}</h3></Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Status
                                            </ControlLabel>
                                            <Select className="Select" classNamePrefix="select"
                                                    value={select(truck_service_statuses, truck.truck_service_status_id)}
                                                    options={truck_service_statuses}
                                                    isClearable
                                                    onChange={(e) => this.setState({
                                                        truck: {
                                                            ...truck,
                                                            truck_service_status_id: e.value
                                                        }
                                                    })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Scheduled date
                                            </ControlLabel>
                                            <Datetime
                                                dateFormat={defaultDateFormat}
                                                isClearable
                                                timeFormat={null}
                                                value={truck?.scheduled_date && moment(truck?.scheduled_date).format(defaultDateFormat)}
                                                className="text-center"
                                                inputProps={{placeholder: 'Scheduled date'}}
                                                onChange={(e) => this.setState({
                                                    truck: {
                                                        ...truck,
                                                        scheduled_date: e
                                                    }
                                                })}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Completed date
                                            </ControlLabel>
                                            <Datetime
                                                dateFormat={defaultDateFormat}
                                                isClearable
                                                timeFormat={null}
                                                value={truck?.completed_date && moment(truck?.completed_date).format(defaultDateFormat)}
                                                className="text-center"
                                                inputProps={{placeholder: 'Completed date'}}
                                                onChange={(e) => this.setState({
                                                    truck: {
                                                        ...truck,
                                                        completed_date: e
                                                    }
                                                })}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={9}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Details
                                            </ControlLabel>
                                            <FormControl
                                                name="truck_service_attributes"
                                                type="text"
                                                onChange={(e) => this.setState({
                                                    truck: {
                                                        ...truck,
                                                        details: e.target.value
                                                    }
                                                })}
                                                value={this.isEmptyValue(truck?.details)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Cost
                                            </ControlLabel>
                                            <InputGroup>
                                                <InputGroupAddon>
                                                    $
                                                </InputGroupAddon>
                                                <FormControl
                                                    name="truck_service_attributes"
                                                    type="number"
                                                    step={0.01}
                                                    onChange={(e) => this.setState({
                                                        truck: {
                                                            ...truck,
                                                            cost: e.target.value
                                                        }
                                                    })}
                                                    onBlur={(e) => this.setState({
                                                        truck: {
                                                            ...truck,
                                                            cost: e.target.value ? parseFloat(e.target.value).toFixed(2) : e.target.value
                                                        }
                                                    })}
                                                    value={this.isEmptyValue(truck?.cost, true)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    {isMobile && <Button
                                        className={"no-top-margin small-margin"}
                                        onClick={() => this.setState({confirmedSelect: false})}
                                    >Back</Button>}
                                    <Button
                                        bsStyle="primary"
                                        className={"no-top-margin small-margin"}
                                        onClick={() => this.props.actions.createNewTruckService({
                                            ...truck,
                                            id: null,
                                            truck_id: truck.id
                                        }, this.setState({showMessage: true}))}
                                    >Create</Button>
                                </Row>
                            </Col>}
                        </Col>

                    }
                </Row>
            </Col>
        );
    }
}

const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(api, dispatch),
    }

);

export default connect(undefined, mapDispatchToProps)(TruckNewServicePage)
