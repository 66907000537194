import React, {Component} from 'react';
import {Row} from "react-bootstrap";
import stopTriggeringClickOnDoubleClick from "../../common/stopTriggeringClickOnDoubleClick";
import {browserHistory} from "react-router";

class SiteRow extends Component {
    render() {
        const {
            site: s,
            selectedSiteId,
            siteRowRef,
            handleSiteRowClick,
            onClick,
            onDoubleClick,
            token,
            setSelectedSite
        } = this.props
        return (
            <div ref={selectedSiteId === s.id && siteRowRef} key={s.id}>
                <Row
                    className={`item-link ${selectedSiteId === s.id ? 'text-danger background-yellow' : ''}`}
                    onClick={() => onClick(() => handleSiteRowClick(s.id, s.name))}
                    onDoubleClick={() => onDoubleClick(() => {
                        setSelectedSite(s.id, token, () => {
                            browserHistory.push(`/customer_view/${token}/proposals`)
                        })
                    })}
                >
                    <div className='item-header'>
                        <div className='item-title item-title__sites'>
                            <p className='item-title__sites--name'>{s.name}</p>
                            <p className='item-title__sites--address'>{s.full_address}</p>
                            {s.site_moved && <p className='item-title__sites--address text-right'><i>Site has been moved to another customer</i></p>}
                        </div>
                    </div>
                </Row>

            </div>
        );
    }
}

export default stopTriggeringClickOnDoubleClick(SiteRow);