import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as MyActions from "./actions"
import {addAlert} from "../App/actions";
import {showDock} from "../../components/dock/actions";
import {Button, Col, Row} from "react-bootstrap";
import CustomerViewMenu from "./CustomerViewMenu";
import {LinkContainer} from "react-router-bootstrap";
import moment from "moment";

import CustomerViewTabs from "./CustomerViewTabs";
import AssetsMap from "./AssetsMap";

const Actions = {...MyActions, addAlert, showDock}
const isMobile = window.screen.width < 1024

class CustomerViewProposals extends Component {
    state = {
        proposals: [],
        loading: true,
        loadAllRecords: false,
        siteAssets: []
    }

    getProposals = (loadAll) => {
        this.props.actions.loadProposals(this.props.params.token, loadAll, this.props.site?.selected?.id, res => {
            this.setState({
                proposals: res.customer_proposals,
                loading: false,
                loadAllRecords: loadAll && true,
                siteAssets: res.site_assets
            })
        })
    }
    componentWillMount = () => {
        if (this.props.params.token) {
            this.getProposals()
            setTimeout(() => {
                this.setState({show: true})
            }, 500)
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }

    render() {
        const {proposals, loading, siteAssets} = this.state
        const {client, params} = this.props
        const clientLogo = client ? client.client_logo_selected : ''
        return (<>

                <div id="customerView" className={`${isMobile ? "customerView--Mob" : "customerView"}`}>
                    <Row className='customerView__header customerView__header--disable-grid'>
                        <Col md={2} className='company-logo'><img src={clientLogo}/></Col>
                        <Col md={8} className='align-center white-stripe'>
                            <p className='title'>{this.props.site?.selected?.name}:  Proposals</p>
                            { !loading &&
                            <p
                                className='subtitle'>{proposals.length === 0  ? `It looks like you don't have any proposals yet` : "Past"}
                            </p>
                            }
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    {isMobile
                        ? <Col md={12}>
                            <CustomerViewMenu token={this.props.params.token}/>
                        </Col>
                        : <Row className="customerView__tabs">
                            <Col md={12}>
                                <CustomerViewTabs token={params.token}/>
                            </Col>
                        </Row>
                    }
                    <Row className='customerView__content align-center'>
                        {!loading &&
                            <>
                                <Col md={isMobile ? 12 : 5} className={`customerView__map ${this.state.show ? 'showMap' : ''}`}>
                                    <AssetsMap assets={siteAssets}/>
                                </Col>
                                <Col md={isMobile ? 12 : 7} xs={12} className='customerView__items items-container'>
                                    {proposals.map(p => {
                                        return <LinkContainer to={`/customer_view/${p.token}`}>
                                            <Row className='item-link'>
                                                <div className='item-header'>
                                                    <div
                                                        className='item-title'>#{p.proposal_no} {p.proposal_title}</div>
                                                    {p.accepted_date || p.declined_date ?
                                                        <div className='item-status' style={{
                                                            color: p.accepted_date && '#517209',
                                                            backgroundColor: p.accepted_date && '#ecf3db'
                                                        }}>{p.accepted_date ? 'Accepted' : 'Declined'}</div> :
                                                        <div className='item-status'
                                                             style={{
                                                                 color: '#414141',
                                                                 backgroundColor: '#f3f3f3'
                                                             }}>Proposed </div>}
                                                </div>
                                                <div className='item-data'>
                                                    {p.proposal_date && <p><span
                                                        className='bold'>Proposed date:</span> {moment(p.proposal_date).format('MMMM Do YYYY')}
                                                    </p>}
                                                    {p.accepted_date && <p><span
                                                        className='bold'>Accepted date:</span> {moment(p.accepted_date).format('MMMM Do YYYY')}
                                                    </p>}
                                                    {p.declined_date && <p><span
                                                        className='bold'>Declined date:</span> {moment(p.declined_date).format('MMMM Do YYYY')}
                                                    </p>}
                                                </div>
                                            </Row>
                                        </LinkContainer>
                                    })}
                                    {proposals.length === 10 && !this.state.loadAllRecords &&
                                        <Row className='mt-20'>
                                            <Button onClick={() => {
                                                this.getProposals(true)
                                            }}>
                                                <span>Load More...</span>
                                            </Button>
                                        </Row>}
                                </Col>
                            </>
                        }
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        site: state.customerView.site
    }
}


const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewProposals)