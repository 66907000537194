import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {dataUpload, handleUpload} from "../../../common/upload";
import {addAlert} from "../../App/actions";

export const loadCrewMessages = (work_order_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages`,
        method: 'GET',
        params: {
            work_order_id: work_order_id
        }
    };
    apiCall("Getting crew messages", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveCrewMessage = (message, callback) => (dispatch, getState) => {
    const config = {
        url:  message.id ? `/api/crew_messages/${message.id}` : `/api/crew_messages`,
        method: message.id ? "PUT" : "POST",
        data: {crew_message: message}
    };
    apiCall("Saving crew message", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, message.id && false);
};

export const deleteCrewMessage = (message_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages/${message_id}`,
        method: "DELETE"
    };

    apiCall("Deleting crew message", config, async config => {
        try {
            await axios.request(config);
            callback && callback(true);
        } catch (e) {
            callback && callback(false);
        }
    }, dispatch, getState);
};

export const markAsRead = (work_order_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages/mark_as_read`,
        method: "PUT",
        data: {
            work_order_id: work_order_id
        }
    };

    apiCall("Reading crew messages", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const uploadImages = (acceptedFiles, id, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload?crew_message_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const uploadFiles = (acceptedFiles, id, callback) => (dispatch, getState) => {
    dataUpload(`/api/crew_message_files/upload?crew_message_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const fetchCrewMessageFiles = (crew_message_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_message_files`,
        params: {crew_message_id}
    };
    apiCall("Getting crew message files", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const removeFile = (id, type, callback) => (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: type === 'images' ? `/api/images/${id}` : `/api/crew_message_files/${id}`
    };
    apiCall("File has been deleted", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const getSiteHazards = (site_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/site_hazards?site_id=${site_id}`,
    };

    apiCall("Getting site hazard", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getUserStatus = (userId, clientId, callback) => async (dispatch, getState) => {
    const store = getState();
    const config = {
        method: "get",
        url: `/api/users/${userId}/status`,
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        dispatch({type: 'GOT_USER_STATUS', userStatus: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Internal error, please contact the administrator",
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }

    callback && callback()
};

