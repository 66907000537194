import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const loadReport = (filters, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/follow_ups/${filters.person_id}`,
        params: filters
    };

    apiCall("Getting follow ups report", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};