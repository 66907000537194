import React, {useState} from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Link} from "react-router";
import moment from "moment-timezone";
import {faCalendarTimes, faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SchedulerEventDialog from "../../../../Scheduler/SchedulerEventDialog";
import TimelineElementWrapper from "./TimelineElementWrapper"
import {defaultDateFormat, defaultTimeFormat} from "../../../../../common/commonHandlers";

const TimelineEvent = ({event, site, wo, proposal, leadId, color, employeeName, employeeDisabled, eventType, employeePrimaryPhoto, onSuccess, createdBy}) => {
    const [showEvent, setShowEvent] = useState(false)
    const [showEventModal, setShowEventModal] = useState(null)

    const bottomLinks = () => {
        return (
            !event.deleted ? <div className="d-flex">
                    {event.id &&
                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-1">Scheduler Event</Tooltip>}>
                                <span onClick={() => setShowEventModal(true)} className='pointer'>
                                    <FontAwesomeIcon icon={faClock}/>
                                </span>
                        </OverlayTrigger>}
                    {event?.scheduler_event_resource_id && leadId &&
                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-2">Find Time</Tooltip>}>
                            <Link to={`/lead/${event.scheduler_event_resource_id}/time`}>
                                <FontAwesomeIcon icon={faCalendarTimes} className="ml-4"/>
                            </Link>
                        </OverlayTrigger>}
                </div> :
                <div>
                    <i>(Event deleted)</i>
                </div>
        )
    }

   const dateFromFormatter = () => {
        if (event?.all_day) {
            return `All Day (${moment(event.date_from).utc().format(defaultDateFormat)})`
        } else {
            return `${event.date_from ? moment(event.date_from).format(defaultTimeFormat) : 'N/A'}-${event.date_to ? moment(event.date_to).format("h:mma ddd MM/DD/YY") : 'N/A'}`
        }
    }

    return (
        <TimelineElementWrapper
            color={color}
            employeeName={employeeName}
            employeeDisabled={employeeDisabled && employeeDisabled}
            createdAt={event.created_at}
            topElement={wo.work_order && <span>WO <strong>#{wo.work_order.work_order_no}</strong></span>}
            bottomElement={bottomLinks()}
            isShowDetail={showEvent}
            hideEvent={() => setShowEvent(false)}
            employeePrimaryPhoto={employeePrimaryPhoto}
        >
            {showEvent ?
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={6} style={{color: "#337ab7"}}>
                                {employeeName || wo?.work_order?.work_order_no && `WO: #${wo.work_order.work_order_no}` || event.name}
                            </Col>
                            <Col xs={6} className="no-left-padding">
                                <span className="pointer">
                                    {dateFromFormatter()}
                                </span>
                            </Col>
                        </Row>
                        <Row className="green-text-color">
                            <Col xs={6}>
                                {site && site.site_contact &&
                                    <span>{`${site?.site_contact?.first_name} ${site?.site_contact?.last_name}`}</span>}
                            </Col>
                            <Col xs={6} className="no-left-padding">
                                {site?.site?.full_address}
                            </Col>
                        </Row>
                        {eventType && <Row>
                            <Col xs={6}>
                                Event type:
                            </Col>
                            <Col xs={6} className="no-left-padding">
                                {eventType}
                            </Col>
                        </Row>}
                        {createdBy && <Row>
                            <Col xs={6}>
                                Created by:
                            </Col>
                            <Col xs={6} className="no-left-padding">
                                {createdBy}
                            </Col>
                        </Row>}
                        {event.scheduler_event_type_id === 1 && wo.work_order && <hr/>}
                        {event.scheduler_event_type_id === 1 && wo.work_order &&
                            <Row>
                                <Col xs={5} className="flex-column-center">
                                    Work Order:
                                    <br/>
                                    <Link to={`/work_orders/${wo.work_order?.proposal_id}`}>
                                        {`#${wo.work_order?.work_order_no}`}
                                    </Link>
                                </Col>
                                    <Col xs={4} className="flex-column-center">
                                        Proposal:
                                        <br/>
                                        <Link to={`/mapview/${wo.work_order?.proposal_id}`}>
                                            {`#${proposal?.proposal_no}`}
                                        </Link>
                                    </Col>
                                    <Col xs={3} className="flex-column-center">
                                        Hours:
                                        <br/>
                                        <span>{wo.service_hours}</span>
                                    </Col>
                                </Row>}
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col xs={12} className={`${showEvent ? 'message-show' : 'message-hidden'} pointer`}>
                            <span onClick={() => setShowEvent(true)}>
                                {dateFromFormatter()}
                            </span>
                        </Col>
                    </Row>}
            {showEventModal &&
                <SchedulerEventDialog
                    handleClose={() => {
                        setShowEventModal(false)
                        onSuccess()
                    }}
                    event={{id: event.id, scheduler_event_resource_id: event.scheduler_event_resource_id}}
                />}
        </TimelineElementWrapper>
    )
}

export default TimelineEvent;