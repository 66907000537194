import React from 'react';
import moment from "moment";

const CustomerViewProposalHeader = ({proposal, clientLogo, newMessages, stickiesIds, isMobile, renderPageHeader}) => {

    const logo = () => {
        return <div className="cVh__logo">
            <img src={clientLogo && clientLogo} alt='Client Logo'/>
        </div>
    }

    const companyDetails = () => {
        return <div className="cVh__company-details text-success">
            <strong><p>{proposal.client_name}</p></strong>
            <strong><p>{proposal.client_address}</p></strong>
            <strong><p>{proposal.client_city}, {proposal.client_state} {proposal.client_zip}</p></strong>
            <strong><p>{proposal.client_phone}</p></strong>
            <strong><p>{proposal.client_email}</p></strong>
        </div>
    }

    const arborist = () => {
        return <div className="cVh__arborist text-success">
            <strong><p>Arborist:</p></strong>
            <p>{proposal.sales_arborist_name}</p>
            <p>{proposal.arborist_phone}</p>
            <p>{proposal.arborist_email}</p>
        </div>
    }

    const proposalDetails = () => {
        return <div className="cVh__proposal-details cVh__divide text-success">
            <div className="cVh__divide--align-right">
                <strong><p>Proposal No.</p></strong>
                <strong><p>Date:</p></strong>
                <strong><p>Title:</p></strong>
            </div>
            <div className="cVh__divide--align-left ">
                <p>{proposal.proposal_no}</p>
                <p>{moment(proposal.proposal_date).format('YYYY/MM/DD')}</p>
                <p>{proposal.proposal_title}</p>
            </div>
        </div>
    }

    const customerInfo = () => {
        return <div className="cVh__customer-info cVh__divide text-danger">
            <div className="cVh__divide--align-right">
                <strong><p>Customer:</p></strong>
                <strong><p>Site:</p></strong>
            </div>
            <div className="cVh__divide--align-left">
                <p>{proposal.customer_name}</p>
                <p>{proposal.site_name}</p>
            </div>
        </div>
    }

    const customerSite = () => {
        return <div className="cVh__customer-site cVh__divide-gap-column text-danger">
            <div className="cVh__divide--align-right">
                <strong><p>Site Address</p></strong>
            </div>
            <div className="cVh__divide--align-left">
                <p>{proposal.site_full_address}</p>
            </div>
            <div className="cVh__divide--align-right">
                <strong><p>Purchase Order</p></strong>
            </div>
            <div className="cVh__divide--align-left">
                <p>{proposal.purchase_order}</p>
            </div>
        </div>
    }

    const actions = () => {
        return <div className="cVh__actions">
            {renderPageHeader(proposal, newMessages, stickiesIds)}
        </div>
    }

    return (
        !isMobile ? <div className="customerView__header-wrapper">
                <div className={`customerView__header proposal-details fit-content-height`}>
                    {logo()}
                    {companyDetails()}
                    {arborist()}
                    {proposalDetails()}
                    {customerInfo()}
                    {customerSite()}
                </div>
                <div className="customerView__header proposal-actions justify-flex-end">
                    {actions()}
                </div>
            </div>
            :
            <div className={`customerView__header__Mob fit-content-height`}>
                <div className="customerView__header proposal-details">
                    {logo()}
                    {companyDetails()}
                    {arborist()}
                    {proposalDetails()}
                    {customerInfo()}
                    {customerSite()}
                </div>
                <div className="proposal-actions pr-15">
                    <hr/>
                    {actions()}
                </div>
            </div>
    );
};

export default CustomerViewProposalHeader;