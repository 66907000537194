import React from "react";
import {OverlayTrigger, Row, Tooltip} from "react-bootstrap";

const Container = ({title, style, titleFunction, subtitleFunction, children, addNewFunctions, containerType, contentStyle, disabled}) => {
    return <div className="customer-info-container-wrapper well" style={style}>
        <div className="container-wrapper-title well">
            <b>{title}</b>
            {titleFunction && titleFunction()}
        </div>
        {subtitleFunction && <div className="container-wrapper-subtitle">
            {subtitleFunction()}
        </div>}
        <div className="container-wrapper-contents" style={contentStyle}>
            {children}
        </div>
        {containerType && disabled && <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip"><strong>Save page before adding new {containerType}</strong></Tooltip>}
        >
            <div className={`container-wrapper-footer disabled`}>
                <span>+ Add New {containerType}</span>
            </div>
        </OverlayTrigger>}
        {containerType && !disabled && <div className="container-wrapper-footer" onClick={() => addNewFunctions()}>
            <span>+ Add New {containerType}</span>
        </div>}
    </div>
}

export default Container;