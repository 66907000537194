import React from 'react';
import PropTypes from "prop-types"
import {Col, FormControl, FormGroup, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Select from "react-select"
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/draggable';
import '../../common/touchPunch';
import ResourceComponent from "../../components/ResourceComponent";
import {optionsFor, select} from "../../common/commonHandlers";
import * as actions from "./SchedulerApi";
import Datetime from "react-datetime";


const isMobile = window.screen.width <= 1024;

class WorkOrdersFilter extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: this.props.filter,
            serviceTypes: [],
            services: [],
            priorities: [],
            equipment: [],
            proposalServiceStatuses: [],
            reasons: []
        };
    }

    componentDidMount = () => {
        this.props.actions.loadFilterData(data => {
            this.setState(data);
        });
    };

    onClickOutside = (e) => {
        console.log(e)
    }


    render() {


        const {
            resource, serviceTypes, services, priorities, proposalServiceStatuses,
            equipment, reasons
        } = this.state;
        const {workOrderSearchResults, customerSearchResults, citySearchResults, zipSearchResults} = this.props;
        let signs = optionsFor(">", "<", "=");
        return (
            <div className="work-orders-filter top15">
                <Row>
                    <Col md={6} xs={12} className="bottom5">
                        <FormGroup>
                            <Datetime
                                dateFormat="MMMM Do"
                                value={resource.from}
                                locale="en-us"
                                inputProps={{
                                    placeholder: 'Promised from',
                                    className: "form-control input-sm",
                                    disabled: this.props.disabled
                                }}
                                onChange={e => {
                                    this.dateResourceAttr('from')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} xs={12} className="bottom5">
                        <FormGroup>
                            <Datetime
                                dateFormat="MMMM Do"
                                value={resource.to}
                                locale="en-us"
                                inputProps={{
                                    placeholder: 'Promised to',
                                    className: "form-control input-sm",
                                    disabled: this.props.disabled
                                }}
                                onChange={e => {
                                    this.dateResourceAttr('to')(e);
                                    this.props.onChange(resource);
                                }}
                            />

                        </FormGroup>
                    </Col>
                </Row>
                <Row className="bottom10">
                    <Col xs={12} className="bottom5">
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                options={priorities}
                                value={select(priorities, resource.priority)}
                                placeholder="Priority"
                                isMulti
                                onChange={e => {
                                    this.selectResourceAttr('priority')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr className={'marginBottom10'}/>
                <Row>
                    <Col md={6} xs={12} className="bottom5">
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                options={proposalServiceStatuses}
                                value={select(proposalServiceStatuses, resource.status)}
                                placeholder="Status"
                                isMulti
                                onChange={e => {
                                    this.selectResourceAttr('status')(e);
                                    this.props.onChange(resource);
                                }}
                                selected={() => this.setState({
                                    resource: {
                                        ...resource,
                                        status: proposalServiceStatuses[0]
                                    }
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} xs={12} className="bottom5">
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                options={serviceTypes}
                                value={select(serviceTypes, resource.serviceType)}
                                placeholder="Service Type"
                                isMulti
                                onChange={e => {
                                    this.selectResourceAttr('serviceType')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={isMobile ? null : 'bottom10'}>
                    <Col md={6} xs={12} className={isMobile ? 'bottom5' : null}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                options={services}
                                value={select(services, resource.service)}
                                placeholder="Service"
                                isMulti
                                onChange={e => {
                                    this.selectResourceAttr('service')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} xs={12} className={isMobile ? 'bottom5' : null}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                options={reasons}
                                value={select(reasons, resource.reason)}
                                placeholder="Reason"
                                isMulti
                                onChange={e => {
                                    this.selectResourceAttr('reason')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr className={'marginBottom10'}/>
                <Row className={isMobile ? null : 'bottom5'}>
                    <Col xs={12} className={isMobile ? 'bottom5' : null}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                options={equipment}
                                value={select(equipment, resource.equipment)}
                                placeholder="Equipment"
                                isMulti
                                onChange={e => {
                                    this.selectResourceAttr('equipment')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="bottom15">
                    <Col md={6} xs={12} className={isMobile ? 'bottom5' : null}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                value={select(customerSearchResults, resource.customer)}
                                options={customerSearchResults}
                                placeholder="Customer"
                                isMulti
                                onInputChange={e =>
                                    this.props.actions.searchForCustomer(e, this.props.onCustomerSearch())
                                }
                                onChange={e => {
                                    this.selectResourceAttr('customer')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                value={select(workOrderSearchResults, resource.workOrder)}
                                options={workOrderSearchResults}
                                placeholder="#WO"
                                isMulti
                                onInputChange={e =>
                                    this.props.actions.searchForWorkOrder(e, this.props.onWoSearch())
                                }
                                onChange={e => {
                                    this.selectResourceAttr('workOrder')(e);
                                    this.props.onChange(resource);
                                }}
                            />

                        </FormGroup>
                    </Col>
                </Row>
                <hr className={'marginBottom10'}/>
                <Row className="bottom15">
                    <Col md={6} xs={12} className={isMobile ? 'bottom5' : null}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                value={select(citySearchResults, resource.city)}
                                options={citySearchResults}
                                placeholder="City"
                                isMulti
                                onInputChange={e =>
                                    this.props.actions.searchForCity(e, this.props.onCitySearch())
                                }
                                onChange={e => {
                                    this.selectResourceAttr('city')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                value={select(zipSearchResults, resource.zip)}
                                options={zipSearchResults}
                                placeholder="Zip"
                                isMulti
                                onInputChange={e =>
                                    this.props.actions.searchForZip(e, this.props.onZipSearch())
                                }
                                onChange={e => {
                                    this.selectResourceAttr('zip')(e);
                                    this.props.onChange(resource);
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr className={'marginBottom10'}/>
                <Row className="bottom15">
                    <Col md={4} xs={12} className={isMobile ? 'bottom5' : null}>
                        <FormGroup>
                            <Select
                                isDisabled={this.props.disabled}
                                className="Select"
                                classNamePrefix="small-select"
                                value={select(signs, resource.eq)}
                                placeholder=""
                                options={signs}
                                onChange={e => {
                                    this.selectResourceAttr('eq')(e);
                                    this.props.onChange(resource);
                                }}
                                clearable={false}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4} xs={12} className={isMobile ? 'bottom5' : null}>
                        <FormGroup>
                            <FormControl
                                disabled={this.props.disabled}
                                type="number"
                                placeholder="Price"
                                name="price"
                                className="input-sm"
                                onChange={e => {
                                    this.updateResourceAttr(e);
                                    this.props.onChange(resource);
                                }}
                                value={resource.price}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                        <FormGroup>
                            <FormControl
                                disabled={this.props.disabled}
                                type="number"
                                placeholder="Hours"
                                name="hours"
                                className="input-sm"
                                onChange={e => {
                                    this.updateResourceAttr(e);
                                    this.props.onChange(resource);
                                }}
                                value={resource.hours}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

WorkOrdersFilter.propTypes = {
    onChange: PropTypes.func,
    filter: PropTypes.object.isRequired
};


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(undefined, mapDispatchToProps)(WorkOrdersFilter);