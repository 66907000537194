import {Modal} from "react-bootstrap";
import React, {Component} from 'react';
import Filter from "./Filter";
import DraggableModalDialog from "./DraggableModalDialog";

class FilterModal extends Component {

    render() {
        return (

            <Modal
                bsSize="big"
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                className={"heightAuto maxHeight90"}
                backdrop={false}
                dialogComponentClass={DraggableModalDialog}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Calendar Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"padding25LR top10"}>
                        <Filter
                            value={this.props.value}
                            users={this.props.users}
                            employees={this.props.employees}
                            newSchedulerView={this.props.newSchedulerView}
                            schedulerEventTypes={this.props.schedulerEventTypes}
                            setDisabledState={this.props.setDisabledState}
                            onChange={this.props.onChange}
                            updateEmployeeColor={this.props.updateEmployeeColor}
                            loadEvents={this.props.loadEvents}
                            showAllHolidays={this.props.showAllHolidays}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" onClick={this.props.onHide} className="btn btn-success">Ok</button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default FilterModal;
