import React, {useEffect, useState} from "react";
import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import {equipmentAnswerTypes, equipmentDefaultAnswer, select} from "../../../common/commonHandlers";
import './NewQuestionModal.scss'

const NewQuestionModal = ({editedQuestion, onHide, onSave}) => {
    const [answerType, setAnswerType] = useState(null)
    const [question, setQuestion] = useState('')
    const [answerOptions, setAnswerOptions] = useState({})
    const [testAnswer, setTestAnswer] = useState(null)

    const questionTypeOptions = [
        {value: 'text', label: 'Text Answers'},
        {value: 'checkbox', label: 'Checkbox Answers'},
        {value: 'switch', label: 'Switch Answers'},
        {value: 'dates', label: 'Dates Answers'},
        {value: 'files', label: 'Upload Files Answers'},
        {value: 'select', label: 'Select Answers'}
    ]

    const selectAnswerTypeOptions = (type) => {
        setTestAnswer(equipmentDefaultAnswer(type))
        switch (type) {
            case 'text':
                setAnswerOptions({
                    singleRow: true,
                    textBox: false,
                    placeholder: 'Your Answer',
                    minRowNumber: 3,
                    maxRowNumber: 5,
                    required: true
                })
                break;
            case 'checkbox':
                setAnswerOptions({
                    multiSelect: false,
                    answersHorizontally: false,
                    required: true,
                    checkboxOptions: [{id: 1, label: ''}]
                })
                break;
            case 'switch':
                setAnswerOptions({
                    labelPosition: 'top',
                    answersHorizontally: false,
                    required: true,
                    switchOptions: [{id: 1, label: ''}]
                })
                break;
            case 'dates':
                setAnswerOptions({
                    orientation: 'horizontal',
                    anchorDirection: 'left',
                    openDirection: 'down',
                    required: true,
                    showPicker: true,
                })
                break;
            case 'files':
                setAnswerOptions({
                    dropzonePlaceholder: 'Drop a file here or click to select a file to upload.',
                    attachmentPlaceholder: 'No attachments found!',
                    required: false,
                })
                break;
            case 'select':
                setAnswerOptions({
                    isMulti: true,
                    placeholder: 'Your Answer',
                    required: true,
                    selectOptions: [{id: 1, value: '', label: ''}]
                })
                break;
            default:
                setAnswerOptions({})
                break;
        }
        setAnswerType(type)
    }

    useEffect(() => {
        if (editedQuestion) {
            const content = JSON.parse(editedQuestion.content)
            setAnswerType(content.answer_type)
            setQuestion(content.question)
            setAnswerOptions(content.options)
            setTestAnswer(equipmentDefaultAnswer(content.answer_type))
        }
    }, [])

    return <Modal
        id="new-question-modal"
        bsSize="large"
        centered={true}
        show={true}
        onHide={onHide}
        backdrop="static"
        className={"heightAuto maxHeight90 fontIOS"}
    >
        <Modal.Header closeButton>
            <Row className="flex-center">
                <Col xs={4}>
                    <Modal.Title>
                        New Question
                    </Modal.Title>
                </Col>
                <Col xs={8}>
                    <Select className="Select" classNamePrefix="select"
                            value={select(questionTypeOptions, answerType)}
                            options={questionTypeOptions}
                            onChange={e => selectAnswerTypeOptions(e ? e.value : null)}
                            placeholder="Answer Type"
                            isClearable
                    />
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body style={{maxHeight: '500px'}}>
            <FormGroup>
                <ControlLabel>Question</ControlLabel>
                <FormControl
                    type="text"
                    placeholder="Question"
                    onChange={e => setQuestion(e.target.value)}
                    value={question}
                />
            </FormGroup>
            {answerType && equipmentAnswerTypes(1, answerType, question, answerOptions, testAnswer, setTestAnswer, setAnswerOptions, true, false)}
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsSize="small"
                bsStyle="warning"
                onClick={() => onHide()}
            >
                Close
            </Button>
            <Button
                bsSize="small"
                bsStyle="success"
                onClick={() => answerType && onSave(editedQuestion?.id, {answer_type: answerType, options: answerOptions, question: question}, () => onHide())}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

export default NewQuestionModal;