import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import {defaultDateFormat, defaultTimeFormat} from "../../common/commonHandlers";
import Select, {Creatable} from "react-select";
import {bindActionCreators} from "redux";
import * as actions from "./ChemicalComplianceModalApi";
import {connect} from "react-redux";
import moment from "moment";
import Datetime from "react-datetime";
import _ from "lodash";
import "./ChemicalComplianceModal.scss"

const unitsTable = [
    {id: 1, value: 1, label: "Gallon"},
    {id: 2, value: 2, label: "Liters"},
    {id: 3, value: 3, label: "Quarts"},
    {id: 4, value: 4, label: "Pints"},
    {id: 5, value: 5, label: "Cups"},
    {id: 6, value: 6, label: "Pound"},
    {id: 7, value: 7, label: "Ounce"},
    {id: 8, value: 8, label: "Gram"},
]

const directionsArray = [
    {value: 'None', label: 'None'},
    {value: 'N', label: 'N'},
    {value: 'NE', label: 'NE'},
    {value: 'E', label: 'E'},
    {value: 'SE', label: 'SE'},
    {value: 'S', label: 'S'},
    {value: 'SW', label: 'SW'},
    {value: 'W', label: 'W'},
    {value: 'NW', label: 'NW'},
];

const initialCC = {
    position: 0,
    brandName: null,
    mixingRatio: "",
    amountApplied: 0,
    measurement: null,

    plantsCount: 0,
    areaSqft: 0,
    materialType: [],
    targetedPest: [],
    appType: [],
    notes: ""
}

const ChemicalComplianceModal = ({workOrder, onHide, isOpen, actions}) => {
    const [brandNameOptions, setBrandNameOptions] = useState([]);
    const [applicatorOptions, setApplicatorOptions] = useState([]);
    const [complianceId, setComplianceId] = useState(null);
    const [plantMaterialOptions, setPlantMaterialOptions] = useState([]);
    const [applicationTypeOptions, setApplicationTypeOptions] = useState([]);
    const [pestsOptions, setPestsOptions] = useState([]);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        //Header data
        applicator: null,
        applicatorResponsible: null,
        applicationDate: workOrder.scheduler_event_date_from ? moment(workOrder.scheduler_event_date_from) : null,
        applicationTimeFrom: workOrder.scheduler_event_date_from ? moment(workOrder.scheduler_event_date_from) : null,
        applicationTimeTo: workOrder.scheduler_event_date_to ? moment(workOrder.scheduler_event_date_to) : null,
        wind: null,
        temperature: 0,
        //Body data
        services: []
    })

    useEffect(() => {
        actions.load(workOrder, (data => {
            setBrandNameOptions(data.brandNames);
            setPestsOptions(data.pests);
            setApplicatorOptions(data.applicators);
            setPlantMaterialOptions(data.applicationSites);
            setApplicationTypeOptions(data.applicationTypes);
            const existingCC = data.chemicalCompliance;

            const mappedServices = workOrder.work_order_proposal_services?.filter(ps => ps.isPHC).map(ps => {
                const thisServiceConsumables = data?.serviceAssociations
                    ?.find(sat => sat.ps_id === ps.proposal_service_id)?.service_consumables;

                const thisAssetsConsumables = data?.serviceAssociations
                    ?.find(sat => sat.ps_id === ps.proposal_service_id)?.assets_consumables;

                const thisServiceApplicationTypes = data?.serviceAssociations
                    ?.find(sat => sat.ps_id === ps.proposal_service_id)?.service_application_types;

                const thisServiceApplicationSites = data?.serviceAssociations
                    ?.find(sat => sat.ps_id === ps.proposal_service_id)?.service_application_sites;

                let brandName = null;

                const availableConsumableIds = [...thisServiceConsumables, ...thisAssetsConsumables]

                if (availableConsumableIds?.length === 1) {
                    brandName = data.brandNames.find(bn => bn.value === availableConsumableIds[0]);
                }

                const assets = ps.assets.map(a => {

                    const thisAssetExistingCC = existingCC?.chemical_compliance_entries
                        ?.filter(cc => cc.proposal_service_id === ps.id && cc.asset_object_id === a.id);

                    return {
                        id: a.id,
                        name: a.name,
                        label: a.label,
                        stay_away_until_dry: thisAssetExistingCC?.length > 0 ? thisAssetExistingCC[0].stay_away : false,
                        available_consumable_ids: availableConsumableIds,
                        chemical_compliances: thisAssetExistingCC?.length > 0 ? thisAssetExistingCC.map(cc => ({
                            ...initialCC,
                            brandName: data.brandNames.find(bn => bn.value === cc.brand_name_id),
                            mixingRatio: cc.mixing_ratio,
                            amountApplied: cc.amount_applied,
                            measurement: unitsTable.find(u => u.value === cc.measurement_id),
                            plantsCount: cc.plants_count,
                            areaSqft: cc.area_sqft,
                            materialType: data.applicationSites.filter(as => cc.plant_material_ids.includes(as.value)),
                            targetedPest: data.pests.filter(p => cc.targeted_pest_ids.includes(p.value)),
                            appType: data.applicationTypes.filter(at => cc.application_type_ids.includes(at.value)),
                            notes: cc.notes,
                            position: cc.position,
                        })) : [{
                            ...initialCC,
                            plantsCount: a.plant_count,
                            areaSqft: a.polygons[0]?.field || 0,
                            materialType: data.applicationSites.filter(as => thisServiceApplicationSites?.includes(as.value)),
                            targetedPest: brandName ? data.pests.filter(p => brandName.targetedPestIds.includes(p.value)) : null,
                            appType: data.applicationTypes.filter(at => thisServiceApplicationTypes?.includes(at.value)),
                            brandName: brandName,
                            mixingRatio: brandName?.mixingRatio || initialCC.mixingRatio,
                            measurement: brandName?.measurementPer ? unitsTable.find(u => u.value === brandName?.measurementPer) : initialCC.measurement,
                        }]
                    }
                });

                return {
                    name: ps.name,
                    proposal_service_id: ps.id,
                    assets: assets
                }
            })

            if(existingCC?.id) {
                setComplianceId(existingCC.id);
                setFormData({
                    applicator: data.applicators.find(a => a.value === existingCC.applicator_id) || null,
                    applicatorResponsible: data.applicators.find(a => a.value === existingCC.applicator_responsible_id) || null,
                    applicationDate: existingCC.application_date ? moment(existingCC.application_date) : null,
                    applicationTimeFrom: existingCC.application_time_start ? moment(existingCC.application_time_start) : null,
                    applicationTimeTo: existingCC.application_time_stop ? moment(existingCC.application_time_stop) : null,
                    wind: directionsArray.find(d => d.value === existingCC.wind) || null,
                    temperature: existingCC.temperature,
                    services: mappedServices
                })
            } else {
                setFormData(prev => ({
                    ...prev,
                    applicator: data.applicators.find(a => a.value === workOrder.person_id) || null,
                    applicatorResponsible: data.applicators.find(a => a.value === workOrder.person_id) || null,
                    services: mappedServices,
                }))
            }
        }))
    }, []);

    const handleChange = (e, type, serviceId, assetId, index) => {
        switch (type) {
            case "brand": {
                setFormData(prevState => {
                    const copy = _.cloneDeep(prevState);
                    const compliance = copy.services
                        .find(s => s.proposal_service_id === serviceId).assets
                        .find(a => a.id === assetId).chemical_compliances[index];

                    compliance.brandName = {value: e.value, label: e.label};
                    compliance.mixingRatio = e.mixingRatio;
                    compliance.measurement = unitsTable.find(u => u.value === e.measurementPer);
                    compliance.targetedPest = pestsOptions.filter(p => e.targetedPestIds.includes(p.value))

                    setFieldError(assetId, serviceId, index, 'mixingRatio', false);
                    setFieldError(assetId, serviceId, index, 'measurement', false);
                    setFieldError(assetId, serviceId, index, 'targetedPest', false);

                    return {
                        ...copy,
                    }
                });
                break;
            }

            case "dry": {
                const checked = e.target.checked;
                setFormData(prevState => {
                    const copy = _.cloneDeep(prevState);
                    copy.services
                        .find(s => s.proposal_service_id === serviceId).assets
                        .find(a => a.id === assetId).stay_away_until_dry = checked;
                    return {
                        ...copy,
                    }
                });
                break;
            }

            case "applicator": {
                setFormData(prev => ({
                    ...prev,
                    applicator: e
                }));
                break;
            }
            case "applicatorResponsible": {
                setFormData(prev => ({
                    ...prev,
                    applicatorResponsible: e
                }));
                break;
            }
            case "wind": {
                setFormData(prev => ({
                    ...prev,
                    wind: e
                }));
                break;
            }
            case "temperature": {
                const {value} = e.target;
                setFormData(prev => ({
                    ...prev,
                    temperature: value
                }));
                break;
            }
            case "measurement": {
                setFormData(prevState => {
                    const copy = _.cloneDeep(prevState);
                    const compliance = copy.services
                        .find(s => s.proposal_service_id === serviceId).assets
                        .find(a => a.id === assetId).chemical_compliances[index];

                    compliance.measurement = e;

                    return {
                        ...copy,
                    }
                });
                break;
            }
            case "date": {
                setFormData(prev => ({
                    ...prev,
                    applicationDate: moment(e).format(defaultDateFormat)
                }));
                break;
            }
            case "timeFrom": {
                setFormData(prev => ({
                    ...prev,
                    applicationTimeFrom: e
                }));
                break;
            }
            case "timeTo": {
                setFormData(prev => ({
                    ...prev,
                    applicationTimeTo: e
                }));
                break;
            }
            case "material": {
                setFormData(prevState => {
                    const copy = _.cloneDeep(prevState);
                    const compliance = copy.services
                        .find(s => s.proposal_service_id === serviceId).assets
                        .find(a => a.id === assetId).chemical_compliances[index];

                    compliance.materialType = e;

                    return {
                        ...copy,
                    }
                });
                break;
            }

            case "targetedPest": {
                setFormData(prevState => {
                    const copy = _.cloneDeep(prevState);
                    const compliance = copy.services
                        .find(s => s.proposal_service_id === serviceId).assets
                        .find(a => a.id === assetId).chemical_compliances[index];

                    compliance.targetedPest = e;

                    return {
                        ...copy,
                    }
                });
                break;
            }

            case "appType": {
                setFormData(prevState => {
                    const copy = _.cloneDeep(prevState);
                    const compliance = copy.services
                        .find(s => s.proposal_service_id === serviceId).assets
                        .find(a => a.id === assetId).chemical_compliances[index];

                    compliance.appType = e;

                    return {
                        ...copy,
                    }
                });
                break;
            }

            case "other": {
                const {name, value} = e.target;
                setFormData(prevState => {
                    const copy = _.cloneDeep(prevState);
                    const compliance = copy.services
                        .find(s => s.proposal_service_id === serviceId).assets
                        .find(a => a.id === assetId).chemical_compliances[index];

                    compliance[name] = value;

                    return {
                        ...copy,
                    }
                });
                break;
            }
        }
    }

    const onSubmit = () => {
        if(!validCCFields()) {
            return;
        }
        actions.save({...formData, id: complianceId}, workOrder.id, () => {
            onHide();
        })
    }

    const getFieldError = (assetId, serviceId, index, name) => {
        return errors?.[`${serviceId}-${assetId}-${index}-${name}`] || false;
    }

    const setFieldError = (assetId, serviceId, index, name, value) => {
        setErrors(prev => ({
            ...prev,
            [`${serviceId}-${assetId}-${index}-${name}`]: value
        }))
    }

    const validCCFields = () => {
        let isValid = true;
        // formData.services.forEach(service => {
        //     service.assets.forEach(asset => {
        //         asset.chemical_compliances.forEach((cc, index) => {
        //             const nullishFields = Object.keys(cc).filter(key => {
        //                 const value = cc[key];
        //                 return (value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0)) && key !== "notes";
        //             });
        //
        //             if(nullishFields.length > 0) {
        //                 nullishFields.forEach(field => {
        //                     setFieldError(asset.id, service.proposal_service_id, index, field, true);
        //                 });
        //                 isValid = false;
        //             } else {
        //                 Object.keys(cc).forEach(key => {
        //                     setFieldError(asset.id, service.proposal_service_id, index, key, false);
        //                 });
        //             }
        //         })
        //     })
        // })

        if(!formData.applicator) {
            isValid = false;
            setFieldError(null, null, null, 'applicator', true);
        }
        if (!formData.applicatorResponsible) {
            isValid = false;
            setFieldError(null, null, null, 'applicatorResponsible', true);
        }
        if (!formData.applicationDate) {
            isValid = false;
            setFieldError(null, null, null, 'applicationDate', true);
        }
        if (!formData.applicationTimeFrom) {
            isValid = false;
            setFieldError(null, null, null, 'applicationTimeFrom', true);
        }
        if (!formData.applicationTimeTo) {
            isValid = false;
            setFieldError(null, null, null, 'applicationTimeTo', true);
        }
        if (!formData.wind) {
            isValid = false;
            setFieldError(null, null, null, 'wind', true);
        }
        if (!formData.temperature) {
            isValid = false;
            setFieldError(null, null, null, 'temperature', true);
        }

        return isValid;
    }

    const removeChemicalComplianceFromAsset = (serviceId, assetId, ccIndex) => {
        setFormData(prevState => {
            const copy = _.cloneDeep(prevState);
            copy.services
                .find(s => s.proposal_service_id === serviceId).assets
                .find(a => a.id === assetId).chemical_compliances
                .splice(ccIndex, 1);
            return {
                ...copy,
            }
        })
    }

    const addChemicalComplianceToAsset = (serviceId, assetId) => {
        setFormData(prevState => {
            const copy = _.cloneDeep(prevState);
            const chemicalCompliances = copy.services.find(s => s.proposal_service_id === serviceId).assets
                .find(a => a.id === assetId).chemical_compliances;

            const lastCC = chemicalCompliances[chemicalCompliances.length - 1];

            const nullishFields = Object.keys(lastCC).filter(key => {
                const value = lastCC[key];
                return (value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0)) && key !== "notes";
            });

            if(nullishFields.length > 0) {
                nullishFields.forEach(field => {
                    setFieldError(assetId, serviceId, chemicalCompliances.length - 1, field, true);
                });

                return {
                    ...copy,
                }
            }
            Object.keys(lastCC).forEach(key => {
                setFieldError(assetId, serviceId, chemicalCompliances.length - 1, key, false);
            });

            chemicalCompliances.push({...initialCC, position: chemicalCompliances.length});
            return {
                ...copy,
            }
        })
    }

    const renderProposalServiceCC = (proposalService) => {
        return <div className="proposal-service-cc">
            <div className="service-name">
                {proposalService.name}
            </div>
            <div className="service-assets">
                {proposalService.assets.map(asset =>
                    <div className='service-asset'>
                        {asset.chemical_compliances.map((cc, index) => <div className="asset-row">
                            {index === 0 ? <div className="cc-input border-right asset-name" style={{width: 150}}>
                                <span>#{asset.label} {asset.name}</span>
                                <div className="dry-checkbox">
                                    <Checkbox
                                        name="stay_away_until_dry"
                                        value={asset.stay_away_until_dry}
                                        onChange={(e) => handleChange(e, 'dry', proposalService.proposal_service_id, asset.id, null)}
                                        className="vertical-align"
                                        style={{paddingTop: 1, marginRight: 5}}
                                        checked={asset.stay_away_until_dry}
                                    />
                                    <span>Stay away until dry</span>
                                </div>

                            </div> : <div className="cc-input border-right" style={{width: 150}}>
                                <button
                                    className="asset-new-cc"
                                    onClick={() => removeChemicalComplianceFromAsset(proposalService.proposal_service_id, asset.id, index)}
                                >
                                    Remove
                                </button>
                            </div>}
                            <div className="cc-input border-right" style={{width: 140}}>
                                <FormGroup>
                                    <ControlLabel>
                                        Brand name
                                    </ControlLabel>
                                    <Select
                                        className="Select" classNamePrefix="select"
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    getFieldError(asset.id, proposalService.proposal_service_id, index, 'brandName') ? 'red' : baseStyles.borderColor,
                                            }),
                                        }}
                                        options={brandNameOptions.filter(bn => asset.available_consumable_ids.includes(bn.value))}
                                        value={cc.brandName}
                                        onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'brandName', false)}
                                        onChange={e => handleChange(e, 'brand', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input" style={{width: 100}}>
                                <FormGroup>
                                    <ControlLabel>Mixing ratio</ControlLabel>
                                    <FormControl type='text'
                                                 name="mixingRatio"
                                                 placeholder='Mixing ratio'
                                                 value={cc.mixingRatio}
                                                 style={{
                                                        borderColor: getFieldError(asset.id, proposalService.proposal_service_id, index, 'mixingRatio') ? 'red' : ''
                                                 }}
                                                 onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'mixingRatio', false)}
                                                 onChange={e => handleChange(e, 'other', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input" style={{width: 120}}>
                                <FormGroup>
                                    <ControlLabel>Amount applied</ControlLabel>
                                    <FormControl type='number'
                                                 name="amountApplied"
                                                 value={cc.amountApplied}
                                                 style={{
                                                        borderColor: getFieldError(asset.id, proposalService.proposal_service_id, index, 'amountApplied') ? 'red' : ''
                                                 }}
                                                 onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'amountApplied', false)}
                                                 onChange={e => handleChange(e, 'other', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input border-right" style={{width: 120}}>
                                <FormGroup>
                                    <ControlLabel>
                                        Measurement
                                    </ControlLabel>
                                    <Select
                                        className="Select" classNamePrefix="select"
                                        options={unitsTable}
                                        value={cc.measurement}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    getFieldError(asset.id, proposalService.proposal_service_id, index, 'measurement') ? 'red' : baseStyles.borderColor,
                                            }),
                                        }}
                                        onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'measurement', false)}
                                        onChange={e => handleChange(e, 'measurement', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input" style={{width: 100}}>
                                <FormGroup>
                                    <ControlLabel>Plants count</ControlLabel>
                                    <FormControl type='number'
                                                 step={1}
                                                 name="plantsCount"
                                                 value={cc.plantsCount}
                                                 style={{
                                                        borderColor: getFieldError(asset.id, proposalService.proposal_service_id, index, 'plantsCount') ? 'red' : ''
                                                 }}
                                                 onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'plantsCount', false)}
                                                 onChange={e => handleChange(e, 'other', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input border-right" style={{width: 100}}>
                                <FormGroup>
                                    <ControlLabel>Area (sqft.)</ControlLabel>
                                    <FormControl type='number'
                                                 step={0.01}
                                                 name="areaSqft"
                                                 value={cc.areaSqft}
                                                 style={{
                                                        borderColor: getFieldError(asset.id, proposalService.proposal_service_id, index, 'areaSqft') ? 'red' : ''
                                                 }}
                                                 onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'areaSqft', false)}
                                                 onChange={e => handleChange(e, 'other', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input" style={{width: 200}}>
                                <FormGroup>
                                    <ControlLabel>
                                        Plant material
                                    </ControlLabel>
                                    <Select
                                        isMulti
                                        className="Select" classNamePrefix="select"
                                        options={plantMaterialOptions}
                                        value={cc.materialType}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    getFieldError(asset.id, proposalService.proposal_service_id, index, 'materialType') ? 'red' : baseStyles.borderColor,
                                            }),
                                        }}
                                        onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'materialType', false)}
                                        onChange={e => handleChange(e, 'material', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input border-right" style={{width: 200}}>
                                <FormGroup>
                                    <ControlLabel>
                                        Targeted pest
                                    </ControlLabel>
                                    <Select
                                        isMulti
                                        className="Select" classNamePrefix="select"
                                        options={pestsOptions}
                                        value={cc.targetedPest}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    getFieldError(asset.id, proposalService.proposal_service_id, index, 'targetedPest') ? 'red' : baseStyles.borderColor,
                                            }),
                                        }}
                                        onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'targetedPest', false)}
                                        onChange={e => handleChange(e, 'targetedPest', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input border-right" style={{width: 200}}>
                                <FormGroup>
                                    <ControlLabel>
                                        App type
                                    </ControlLabel>
                                    <Select
                                        isMulti
                                        className="Select" classNamePrefix="select"
                                        options={applicationTypeOptions}
                                        value={cc.appType}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    getFieldError(asset.id, proposalService.proposal_service_id, index, 'appType') ? 'red' : baseStyles.borderColor,
                                            }),
                                        }}
                                        onFocus={() => setFieldError(asset.id, proposalService.proposal_service_id, index, 'appType', false)}
                                        onChange={e => handleChange(e, 'appType', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="cc-input" style={{width: 200}}>
                                <FormGroup>
                                    <ControlLabel>
                                        Notes
                                    </ControlLabel>
                                    <FormControl
                                        rows={3}
                                        componentClass="textarea"
                                        placeholder="Chemical compliance notes"
                                        value={cc.notes}
                                        name="notes"
                                        onChange={e => handleChange(e, 'other', proposalService.proposal_service_id, asset.id, index)}
                                    />
                                </FormGroup>
                            </div>
                        </div>)}
                        <div>
                            <button
                                className="asset-new-cc"
                                onClick={() => addChemicalComplianceToAsset(proposalService.proposal_service_id, asset.id)}
                            >
                                Add new chemical compliance for this asset
                            </button>
                        </div>
                    </div>)}
            </div>
        </div>
    }

    return (
        <Modal
            id="chemical-compliance-modal"
            show={isOpen}
            onHide={onHide}
            backdrop="static"
            className={"heightAuto fontIOS"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {complianceId ? "Edit Chemical Compliance" : "Add Chemical Compliance"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid style={{paddingTop: 20}}>
                    <Row>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>
                                    Applicator
                                </ControlLabel>
                                <Select
                                    className="Select" classNamePrefix="select"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor:
                                                getFieldError(null, null, null, 'applicator') ? 'red' : baseStyles.borderColor,
                                        }),
                                    }}
                                    options={applicatorOptions}
                                    value={formData.applicator}
                                    onChange={e => handleChange(e, 'applicator')}
                                    onFocus={() => setFieldError(null, null, null, 'applicator', false)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>
                                    Applicator responsible
                                </ControlLabel>
                                <Select
                                    className="Select" classNamePrefix="select"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor:
                                                getFieldError(null, null, null, 'applicatorResponsible') ? 'red' : baseStyles.borderColor,
                                        }),
                                    }}
                                    options={applicatorOptions}
                                    value={formData.applicatorResponsible}
                                    onChange={e => handleChange(e, 'applicatorResponsible')}
                                    onFocus={() => setFieldError(null, null, null, 'applicatorResponsible', false)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>
                                    Application date
                                </ControlLabel>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    timeFormat={null}
                                    style={{
                                        borderColor: getFieldError(null, null, null, 'applicationDate') ? 'red' : ''
                                    }}
                                    value={formData.applicationDate ? moment(formData.applicationDate) : null}
                                    onChange={e => handleChange(e, 'date')}
                                    onFocus={() => setFieldError(null, null, null, 'applicationDate', false)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Application time [From -> To]
                                </ControlLabel>
                                <Row>
                                    <Col xs={6}>
                                        <Datetime
                                            dateFormat={null}
                                            timeFormat={true}
                                            style={{
                                                borderColor: getFieldError(null, null, null, 'applicationTimeFrom') ? 'red' : ''
                                            }}
                                            value={formData.applicationTimeFrom}
                                            onChange={e => handleChange(e, 'timeFrom')}
                                            onFocus={() => setFieldError(null, null, null, 'applicationTimeFrom', false)}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Datetime
                                            dateFormat={null}
                                            timeFormat={true}
                                            style={{
                                                borderColor: getFieldError(null, null, null, 'applicationTimeTo') ? 'red' : ''
                                            }}
                                            value={formData.applicationTimeTo}
                                            onChange={e => handleChange(e, 'timeTo')}
                                            onFocus={() => setFieldError(null, null, null, 'applicationTimeTo', false)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col xs={1}>
                            <FormGroup>
                                <ControlLabel>
                                    Wind
                                </ControlLabel>
                                <Select
                                    className="Select" classNamePrefix="select"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor:
                                                getFieldError(null, null, null, 'wind') ? 'red' : baseStyles.borderColor,
                                        }),
                                    }}
                                    options={directionsArray}
                                    value={formData.wind}
                                    onChange={e => handleChange(e, 'wind')}
                                    onFocus={() => setFieldError(null, null, null, 'wind', false)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>Temperature</ControlLabel>
                                <FormControl type='number'
                                             name="temperature"
                                             placeholder='Temperature'
                                             style={{
                                                 borderColor: getFieldError(null, null, null, 'temperature') ? 'red' : ''
                                             }}
                                             step={0.1}
                                             value={formData.temperature}
                                             onFocus={() => setFieldError(null, null, null, 'temperature', false)}
                                             onChange={e => handleChange(e, 'temperature')}
                                             onBlur={e => {
                                                 const value = e.target.value ? parseFloat(e.target.value) : "";
                                                 setFormData(prev => ({
                                                        ...prev,
                                                        temperature: value
                                                 }))
                                             }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {formData.services.map(proposalService => renderProposalServiceCC(proposalService))}
                        </Col>
                    </Row>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    bsSize="small"
                    bsStyle="warning"
                    onClick={() => onHide()}
                >
                    Cancel
                </Button>
                <Button
                    bsSize="small"
                    bsStyle="success"
                    onClick={() => {
                        onSubmit();
                    }}
                    type="button"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => ({
    roles: state.auth.roles
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChemicalComplianceModal)