import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";
import _ from "lodash";

export const getTextMessageTemplates = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/text_message_templates`,
    };

    apiCall("Getting text message templates", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const getShortCodes = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/email_templates/shortcodes`,
    };

    apiCall("Getting shortcodes", config, async config => {
        const response = await axios.request(config);
        const result = response.data.map(shortcode => {
            const entries = shortcode[shortcode.accessor] ? shortcode[shortcode.accessor] : _.omit(shortcode, "accessor", "template_name")
            return {
                template_name: shortcode.template_name,
                accessor: shortcode.accessor,
                entries: entries
            }
        })
        callback(result);
    }, dispatch, getState, false);
};