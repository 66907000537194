import {
  EDITING_CONTACT_ID,
  FETCH_CONTACT,
  CREATE_NEW_CONTACT,
  SET_CUSTOMER_ID,
  UPDATE_CONTACT_ATTR,
  DELETE_CONTACT,
} from "./constants"
import {
  deleteKeyFromObject,
} from '../../utilities'
const initialState = {
  customerId: null,
  editingContactId: null,
  contact: null,
}
const blankContact = customer_id => ({
  id: 0,
  contact_type_id: 0,
  customer_id,
  first_name: "",
  last_name: "",
  person_phone: "",
  person_phone2: "",
  person_email: "",
  person_email2: "",
  __dirty: false,
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_CONTACT:
      console.info(
        "Modal got create new contact with customerid",
        action.customerId
      )
      return {
        ...state,
        editingContactId: 0,
        contact: blankContact(action.customerId),
      }
    case SET_CUSTOMER_ID:
      return { ...state, customerId: action.customerId }
    case EDITING_CONTACT_ID:
      return { ...state, editingContactId: action.editingContactId }
    case UPDATE_CONTACT_ATTR:
    case FETCH_CONTACT:
      return { ...state, contact: action.contact }
    case DELETE_CONTACT:
      return {
        ...state,
        sites: deleteKeyFromObject(
          state.contacts,
          'contacts',
          action.id
        ),
      }
    default:
      return state
  }
}
