import React, {Component} from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from './actions'

import ReactDock from "react-dock"
import {Glyphicon, OverlayTrigger, Tooltip} from "react-bootstrap";

class Dock extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const {docks, type, position, reload, custom_title = false} = this.props;
        const title = custom_title || type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();

        return (
            <div className="dock">
                <ReactDock position={position}
                           isVisible={docks.active === type}
                           dimMode="none"
                           defaultSize={window.innerWidth > 990 ? 0.3 : 1}
                           dockStyle={{overflow: 'hidden'}}
                           zIndex={1031}
                >
                    <div className="close-dock">

                        {position === 'left' && reload && <div className="pull-left">
                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-refresh`}>
                                        Refresh
                                    </Tooltip>
                                }
                            >
                            <Glyphicon glyph='refresh' className="pointer margin10" onClick={() => this.props.actions.reloadDock(type)}/>
                            </OverlayTrigger>
                        </div>}
                        {position === 'right' && <div className="pull-left">
                            <Glyphicon glyph='remove' className="pointer margin10" onClick={() => this.props.actions.showDock(null)}/>
                        </div>}
                        <strong className="margin10 pull-left">{title}</strong>
                        {position === 'left' && <div className="pull-right">
                            <Glyphicon glyph='remove' className="pointer margin10" onClick={() => this.props.actions.showDock(null)}/>
                        </div>}
                        {position === 'right' && reload && <div className="pull-right">
                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-refresh`}>
                                        Refresh
                                    </Tooltip>
                                }
                            >
                            <Glyphicon glyph='refresh' className="pointer margin10" onClick={() => this.props.actions.reloadDock(type)}/>
                            </OverlayTrigger>
                        </div>}

                    </div>
                    {docks.active === type && <div className="dock-content">
                        {this.props.children}
                    </div>}
                </ReactDock>
            </div>
        );
    }
}

Dock.propTypes = {
    position: PropTypes.string,
    type: PropTypes.string.isRequired,
    children: PropTypes.object,
    reload: PropTypes.bool
};

Dock.defaultProps = {
    position: 'left',
    reload: true
};

function mapStateToProps(state) {
    return {
        docks: state.docks
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dock);