import {withGoogleMap, withScriptjs} from "react-google-maps"
import React from 'react';
import config from "../config/environment";

export const GoogleMapLoader = withScriptjs(withGoogleMap((props) =>
    <div>
        {props.googleMapElement}
    </div>
))

GoogleMapLoader.defaultProps = {
    googleMapURL: `https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${config.googleMapApiKey}`,
    loadingElement: <div style={{height: `100%`}}/>,
    mapElement: <div style={{height: `100%`}}/>
};