import React from 'react';
import './ClientTransactions.css';
import * as actions from '../TransactionsAPI'
import ClientTransactionsTable from './ClientTransactionsTable';
import {Button, Col, FormControl, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import LeadsFilterModal from "../../../LeadsPage/LeadsFilterModal";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons'
import ClientTransactionsFilters from './ClientTransactionsFilters';
import {debounce} from "throttle-debounce";
import ResourceComponent from "../../../../components/ResourceComponent";
import {SizePerPageDropDown} from "react-bootstrap-table";
import moment from "moment-timezone";

class ClientTransactions extends ResourceComponent {
    constructor(props) {
        super(props);

        this.delayedSearch = debounce(500, this.search);
        this.delayedCustomerSearch = debounce(1250, this.search);

        this.state = {
            isModalOpen: false,
            transactions: [],
            areTransactionsFetched: false,
            resource: {
                sort: 'desc',
                sort_by: 'created_at',
                page: 1,
                per_page: 25,
                client_id: null,
                customer_id: null,
                invoice_number: null,
                amount_from: null,
                amount_to: null,
                created_from: null,
                created_to: null,
                q: null
            },
            total_payments: 0,
            statuses: [
                {value: "paid", label: "Paid"},
                {value: "refunded", label: "Refunded"},
                {value: "refund_requested", label: "Refund requested"}
            ],
        };
    }

    componentDidMount() {
        const {resource} = this.state;
        resource.created_from = moment().subtract(30, "days").startOf('day');
        resource.created_to = moment().endOf('day');
    }

    search() {
        let {resource} = this.state;
        const {client} = this.props;
        const clientId =  resource.client_id ? resource.client_id : client?.id
        this.props.actions.fetchClientPayments({...resource}, clientId, transactions => this.setState({
            transactions: transactions.transactions,
            total_payments: transactions.total_payments,
        }));
    }

    onChangeFilters = (key, value) => {
        const {resource} = this.state;
        this.setState(() => ({resource: {...resource, [key]: value}}))
    };

    onChangeResource = (value) => {
        this.setState(() => ({resource: value}), this.delayedSearch)
    };

    toggleModal = (e) => {
        e.preventDefault();
        const {isModalOpen} = this.state;
        this.setState({isModalOpen: !isModalOpen});
    };

    getClientTransactions = () => {
        const {resource} = this.state;
        const {client} = this.props;
        const clientId =  resource.client_id ? resource.client_id : client?.id
        this.props.actions.fetchClientPayments(resource, clientId, transactions => this.setState({
            transactions: transactions.transactions,
            total_payments: transactions.total_payments
        }))
        ;
    };

    onToggleDropDown = (toggleDropDown) => toggleDropDown();

    renderSizePerPageDropDown = (props) => {
        const {per_page} = this.state.resource;
        const {toggleDropDown} = props;

        return (
            <SizePerPageDropDown
                className='my-size-per-page'
                variation='dropup'
                onClick={() => this.onToggleDropDown(toggleDropDown)}
                currSizePerPage={per_page}
            />
        );
    };

    render() {
        const {isModalOpen, transactions, resource, statuses, total_payments, areTransactionsFetched} = this.state;
        const {client} = this.props;
        const client_name = client && client.name;
        const client_id = client && client.id;

        return (
            <div id="client_transactions">
                <div>
                    {!areTransactionsFetched ?
                        <Button className="get_transactions_btn"
                                onClick={e => this.setState({areTransactionsFetched: true}, () => this.getClientTransactions())}>
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="mr-9 font16"/>
                            Get {client_name} Transactions
                        </Button>
                        :
                        <div>
                            <div>
                                <Row>
                                    <Col md={6} className="transactions_header_wrapper">
                                        <h4 className="transactions_title">Transactions</h4>
                                        <h5 className="transactions_count">{total_payments} returned</h5>
                                    </Col>
                                    <Col md={6} className="justify-flex-end">
                                        <FormControl
                                            name="q"
                                            placeholder="Search..."
                                            value={resource.q}
                                            className="mr-15"
                                            onChange={e => this.setState(({
                                                resource: {
                                                    ...resource,
                                                    q: e.target.value
                                                }
                                            }), this.delayedCustomerSearch)}
                                        />
                                        <Button
                                            bsStyle="success"
                                            className="transactions_btn mr-7"
                                            onClick={() => this.props.actions.downloadCsv(resource, client_name)}>
                                            Export to CSV
                                        </Button>
                                        <Button
                                            className="transactions_btn"
                                            onClick={e => this.toggleModal(e)}> Filter {" "}
                                            <FontAwesomeIcon icon="search" className="small-margin"/>
                                        </Button>
                                    </Col>
                                </Row>
                                <ClientTransactionsTable
                                    transactions={transactions}
                                    resource={resource}
                                    total_payments={total_payments}
                                    client_id={client_id}
                                    onChangeResource={this.onChangeResource}
                                    renderSizePerPageDropDown={this.renderSizePerPageDropDown}
                                />
                                {isModalOpen &&
                                <LeadsFilterModal
                                    title="Transactions Filter"
                                    show={isModalOpen}
                                    onHide={(e) => this.setState({isModalOpen: !isModalOpen}, () => this.getClientTransactions())}
                                    closeButton={() => this.setState({isModalOpen: !isModalOpen})}
                                >
                                    <ClientTransactionsFilters
                                        onChangeFilters={this.onChangeFilters}
                                        resource={resource}
                                        selectAttr={this.selectResourceAttr('status')}
                                        statuses={statuses}
                                    />
                                </LeadsFilterModal>
                                }
                            </div>
                        </div>

                    }

                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(null, mapDispatchToProps)(ClientTransactions);
