import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const getSites = (params, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_sites`,
        params
    };

    apiCall("Getting customers' sites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};