export const [
  CLIENT_LIST_LOADED,
  FETCH_CLIENT,
  EDITING_CLIENT_ID,
  EDIT_CLIENT_FILTER_TEXT,
  EDIT_USER_FILTER_TEXT,
  IMPERSONATE_SUCCESS,
  ADD_NEW_CLIENT,
  DELETE_CLIENT,
  FETCH_USERS,
  FETCH_EMPLOYEES,
  FETCH_ROLES,
  SAVED_USER_INFO,
  FETCH_CUSTOMER_INFO,
  SAVED_CLIENT_INFO,
  DELETED_USER,
  USER_ACTIVITIES_LOADED,
  USER_ISSUES_LOADED,
  DELETE_ISSUE,
  EDIT_USER_LIST_FILTER_TEXT
] = [
  'CLIENT_LIST_LOADED',
  'FETCH_CLIENT',
  'EDITING_CLIENT_ID',
  'EDIT_CLIENT_FILTER_TEXT',
  'EDIT_USER_FILTER_TEXT',
  'IMPERSONATE_SUCCESS',
  'ADD_NEW_CLIENT',
  'DELETE_CLIENT',
  'FETCH_USERS',
  'FETCH_EMPLOYEES',
  'FETCH_ROLES',
  'SAVED_USER_INFO',
  'FETCH_CUSTOMER_INFO',
  'SAVED_CLIENT_INFO',
  'DELETED_USER',
  'USER_ACTIVITIES_LOADED',
  'USER_ISSUES_LOADED',
  'DELETE_ISSUE',
    'EDIT_USER_LIST_FILTER_TEXT'
];
