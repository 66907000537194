import {
  GET_CUSTOMER_BY_ID,
  FETCH_RECENT_SERVICES,
  UPDATE_SERVICE_STATUS,
  FETCH_CUSTOMER_SITES,
  UPDATE_SELECTED_SITE,
  UPDATE_CUSTOMER_SEARCH,
  SELECT_CUSTOMER,
  FETCH_SEARCHED_CUSTOMERS,
  FETCH_SITES_ASSETS,
  FETCH_SELECTED_SITEINFO,
  UPDATE_WORKHISTORY_ASSET,
} from "./constants"

import {
  FETCHED_SITE_ASSETS,
  EDITING_ASSET_ID,
} from "../../MapViewPage/constants"

import axios from "axios"
import { addAlert } from "../../App/actions"
import {
  fetchProposalServiceStatuses,
} from "../../ProposalServiceStatusPage/actions"
import { browserHistory } from "react-router"
import {
  onMarkerClick,
  doneEditingAsset,
  fetchDbhs,
} from "../../MapViewPage/actions"

export const updateServiceStatus = serviceStatus => {
  return { type: UPDATE_SERVICE_STATUS, serviceStatus }
}

export { onMarkerClick }
export { doneEditingAsset }

export const updateSelectedSite = selectedSite => dispatch => {
  dispatch({ type: UPDATE_SELECTED_SITE, selectedSite })
  dispatch(fetchSelectedSiteInfo())
  dispatch(fetchCustomerAssets())
}

export const onAssetMouseOver = assetId => dispatch => {
  //set the .__highlighted = true on this marker
  dispatch(updateAssetAttribute(assetId, "__highlighted", true, false))
}

export const onAssetMouseOut = assetId => dispatch => {
  dispatch(updateAssetAttribute(assetId, "__highlighted", undefined, false))
}

export const onAssetLocked = assetId => dispatch => {
  //set the .__highlighted = true on this marker
  dispatch(updateAssetAttribute(assetId, "__locked", true, false))
}

export const onAssetUnlocked = assetId => dispatch => {
  dispatch(updateAssetAttribute(assetId, "__locked", undefined, false))
}

//this is the same process as editing any objet in a list. if this breaks then other stuff will break
export const updateAssetAttribute = (assetId, attr, newVal, dirty) => (
  dispatch,
  getState
) => {
  //  console.log("updating asset id attr to x", assetId, attr, newVal)
  const store = getState()

  //1. copy the one we want to edit

  // here state name is site(s)Assets LOL
  const siteAssets = store.workHistory.sitesAssets // yes this is a reference!

  //deepFreeze(siteAssets);// just to ensure we're not really modifying it

  const editAssetIdx = siteAssets.findIndex(a => a.id === assetId) // a refernce. dont edit this!
  // console.log("found asset requested index by id", assetId, editAssetIdx)


  // if you want to lock asset, need to unlock others
  if(attr === '__locked') {
    siteAssets.forEach(siteAsset => {
      if (siteAsset['__locked']) {
        siteAsset['__locked'] = false
      }
    })
  }

  let editedAsset = Object.assign({}, siteAssets[editAssetIdx]) // need to create a new object!

  // console.log("found asset  object", editedAsset)
  // console.log(`setting ${attr} to ${newVal}`)
  //2. edit it
  editedAsset[attr] = newVal

  //set the model as dirty whih we use to tell the user to push the button
  if (dirty !== undefined) {
    editedAsset["dirty"] = dirty
  }

  //3. use slice/concat to substitute it in. note that we can do this different ways but because of the UI
  //and sorting, we dont want it to rerender in a different sot order. we want the proposal service to stay put
  //so also you can spread over instead of using concat() keyword

  //TODO: redux really likes the nosql approach of having objects with keys as identifiers rather than having arrays of objects that you search thru
  //http://stackoverflow.com/questions/38445006/redux-state-as-array-of-objects-vs-object-keyed-by-id

  const updatedSiteAssets = [
    ...siteAssets.slice(0, editAssetIdx),
    editedAsset,
    ...siteAssets.slice(editAssetIdx + 1),
  ] // spreading is creating a new array. deepFreeze ensures that

  dispatch({ type: UPDATE_WORKHISTORY_ASSET, sitesAssets: updatedSiteAssets })
}

export const updateCustomerSearch = customerSearch => dispatch => {
  dispatch({ type: UPDATE_CUSTOMER_SEARCH, customerSearch })
  dispatch(searchForCustomer())
}

export const updateSelectedCustomer = selectedCustomer => dispatch => {
  dispatch({ type: SELECT_CUSTOMER, selectedCustomer })
  browserHistory.push(`/customer/work_history/${selectedCustomer.value}`) //will cause page to kick off api calls
}

export const dismissAssetEditorModal = () => ({
  type: EDITING_ASSET_ID,
  editingAssetHistoryId: null,
})

export const applyHistoryFilter = () => (dispatch, getState) => {
  const store = getState()
  dispatch(fetchCustomerById(store.workHistory.customer.id))
}

export const fetchCustomerAssets = () => async (dispatch, getState) => {
  const store = getState()
  const selectedSite = store.workHistory.selectedSite

  let siteIds = []
  if (selectedSite === null) {
    siteIds = store.workHistory.customerSites.map(site => site.id)
  } else {
    siteIds = [selectedSite]
  }

  if (siteIds.length === 0) {
    console.info(
      "There are no Site Ids to fetch Assets for. This customer probably doesn't have a Site. Sending API request anyway."
    )
  }

  const config = {
    method: "get",
    url: `/api/assets`,
    headers: { Authorization: store.auth.token },
    params: {
      site_id: siteIds,
    },
  }

  try {
    const response = await axios.request(config)
    dispatch({ type: FETCH_SITES_ASSETS, sitesAssets: response.data })
    dispatch({ type: FETCHED_SITE_ASSETS, siteAssets: response.data }) //mapview which is what asset editor depends on :(
    dispatch(fetchDbhs()) //asset editor depends on this too
  } catch (exception) {
    dispatch(
      addAlert({
        message: "Unable to fetch the customer because " + exception.response?.data?.error,
      })
    )
    throw exception
  }
}

export const fetchCustomerById = customerId => async (dispatch, getState) => {
  const store = getState()

  const config = {
    url: `/api/customers/${customerId}/info`,
    headers: { Authorization: store.auth.token },
  }

  try {
    const response = await axios.request(config)

    dispatch({ type: GET_CUSTOMER_BY_ID, customer: response.data })
    dispatch(fetchRecentServices())
    dispatch(fetchProposalServiceStatuses("all"))
    const _ = await dispatch(fetchCustomerSites())
    dispatch(fetchCustomerAssets())
  } catch (exception) {
    dispatch(
      addAlert({
        message: "Unable to fetch the customer because " + exception.toString(),
      })
    )
    throw exception
  }
}

const fetchRecentServices = () => async (dispatch, getState) => {
  const store = getState()
  const customerId = store.workHistory.customer.id
  const siteId = store.workHistory.selectedSite
  const serviceStatus = store.workHistory.serviceStatus

  const config = {
    url: "/api/proposal_services/recent",
    headers: { Authorization: store.auth.token },
    params: {
      customer_id: customerId,
      site_id: siteId,
      service_status: serviceStatus,
    },
  }

  try {
    const response = await axios.request(config)

    dispatch({ type: FETCH_RECENT_SERVICES, recentServices: response.data })
    dispatch(
      addAlert({
        message: response.data.length.toString() + " Services returned",
        mood: "info",
        dismissAfter: 1000,
      })
    )
  } catch (exception) {
    dispatch(
      addAlert({
        message: "Unable to fetch recent services because " +
          exception.toString(),
      })
    )
    throw exception
  }
}

const fetchCustomerSites = () => async (dispatch, getState) => {
  const store = getState()
  const customerId = store.workHistory.customer.id
  const config = {
    url: "/api/sites",
    headers: { Authorization: store.auth.token },
    params: { customer_id: customerId },
  }

  try {
    const response = await axios.request(config)

    dispatch({ type: FETCH_CUSTOMER_SITES, customerSites: response.data })
  } catch (exception) {
    dispatch(
      addAlert({
        message: "Unable to fetch customer sites because " +
          exception.toString(),
      })
    )
    throw exception
  }
}

const fetchSelectedSiteInfo = () => async (dispatch, getState) => {
  const store = getState()
  const selectedSite = store.workHistory.selectedSite
  if (isNaN(parseInt(selectedSite, 10))) {
    return false
  }

  const config = {
    url: `/api/sites/${selectedSite}`,
    headers: { Authorization: store.auth.token },
  }

  try {
    const response = await axios.request(config)
    dispatch({ type: FETCH_SELECTED_SITEINFO, selectedSiteInfo: response.data })
  } catch (exception) {
    dispatch(
      addAlert({
        message: "Unable to fetch customer site because " +
          exception.toString(),
      })
    )
    throw exception
  }
}

export const searchForCustomer = () => async (dispatch, getState) => {
  const store = getState()
  const searchQuery = store.workHistory.customerSearch

  if (searchQuery.length < 2) {
    return false
  }

  const config = {
    method: "get",
    url: `/api/customers`,
    headers: { Authorization: store.auth.token },
    params: {
      q: searchQuery,
    },
  }
  try {
    const response = await axios.request(config)
    dispatch({
      type: FETCH_SEARCHED_CUSTOMERS,
      foundCustomers: response.data,
    })
  } catch (exception) {
    dispatch(
      addAlert({
        message: "Unable to search for customers because " +
          exception.toString(),
      })
    )
    throw exception
  }
}
