export const trucksDataFilterModal = {
    trucks: [
        {
            field_type: 'multiselect',
            field_name: 'truck_year',
        },
        {
            field_type: 'multiselect',
            field_name: 'truck_make',
        },
        {
            field_type: 'multiselect',
            field_name: 'truck_model',
        },
        {
            field_type: 'multiselect',
            field_name: 'truck_type',
        },
        {
            field_type: 'multiselect',
            field_name: 'truck_class',
        },
        {
            field_type: 'multiselect',
            field_name: 'truck_unit',
        },
        {
            field_type: 'date',
            field_name: 'expiration'
        }
    ], services: [
        {
            field_type: 'date',
            field_name: 'scheduled_date'
        },
        {
            field_type: 'date',
            field_name: 'completed_date'
        },
        {
            field_type: 'number',
            field_name: 'cost'
        },
        {
            field_type: 'multiselect',
            field_name: 'truck_service_status'
        }
    ], financials: [
        {
            field_type: 'multiselect',
            field_name: 'financial_lender',
        },
        {
            field_type: 'date',
            field_name: 'origin_date'
        },
        {
            field_type: 'number',
            field_name: 'original_amount'
        },
        {
            field_type: 'number',
            field_name: 'term_amount',
        },
        {
            field_type: 'multiselect',
            field_name: 'financial_interest_rate',
        },
        {
            field_type: 'number',
            field_name: 'payment_amount'
        },
        {
            field_type: 'date',
            field_name: 'payment_date'
        },
        {
            field_type: 'number',
            field_name: 'balance'
        },
        {
            field_type: 'number',
            field_name: 'value',
        }
    ]
}
