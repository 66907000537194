import {
  GET_CUSTOMER_BY_ID,
  FETCH_RECENT_SERVICES,
  UPDATE_SERVICE_STATUS,
  FETCH_CUSTOMER_SITES,
  UPDATE_SELECTED_SITE,
  UPDATE_CUSTOMER_SEARCH,
  SELECT_CUSTOMER,
  FETCH_SEARCHED_CUSTOMERS,
  FETCH_SITES_ASSETS,
  FETCH_SELECTED_SITEINFO,
  UPDATE_WORKHISTORY_ASSET,
} from "./constants"

const initialState = {
  customer: null,
  recentServices: null,
  serviceStatus: null,
  customerSites: null,
  selectedSite: null,
  selectedSiteInfo: null,
  customerSearch: null,
  foundCustomers: null,
  selectedCustomer: null,
  sitesAssets: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SELECTED_SITEINFO:
      return { ...state, selectedSiteInfo: action.selectedSiteInfo }
    case FETCH_SITES_ASSETS:
    case UPDATE_WORKHISTORY_ASSET:
      return { ...state, sitesAssets: action.sitesAssets }
    case SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.selectedCustomer,
        customerSites: null,
        selectedSite: null,
        selectedSiteInfo: null,
        sitesAssets: null,
        recentServices: null,
      }
    case UPDATE_CUSTOMER_SEARCH:
      return {
        ...state,
        customerSearch: action.customerSearch,
      }
    case FETCH_SEARCHED_CUSTOMERS:
      return { ...state, foundCustomers: action.foundCustomers }
    case UPDATE_SELECTED_SITE:
      return { ...state, selectedSite: action.selectedSite }
    case FETCH_CUSTOMER_SITES:
      return { ...state, customerSites: action.customerSites }
    case UPDATE_SERVICE_STATUS:
      return { ...state, serviceStatus: action.serviceStatus }
    case FETCH_RECENT_SERVICES:
      return { ...state, recentServices: action.recentServices }
    case GET_CUSTOMER_BY_ID:
      /*we can load the page via customer id or search for customer in the page. simulate a search so the ui is happy*/
      return {
        ...state,
        customer: action.customer,
        foundCustomers: [
          { id: action.customer.id, name: action.customer.name },
        ],
        sitesAssets: null,
      }
    default:
      return state
  }
}
