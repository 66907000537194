import React from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import moment from "moment";
import {defaultDateTimeFormat} from "../../common/commonHandlers";

const BlockedRecipientsModal = ({onHide, blockedRecipients = []}) => {
    return <Modal
        show={true}
        onHide={onHide}
        backdrop="static"
        className={"heightAuto maxHeight90 fontIOS"}
    >
        <Modal.Header closeButton>
            <Modal.Title>
                Recipients on bounces list
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h5>These recipients are on the bounces list. Remove them from it to send them an email</h5>
            <Row>
                <Col xs={3} className="text-center">
                    Date
                </Col>
                <Col xs={3} className="text-center">
                    Email
                </Col>
                <Col xs={6} className="text-center">
                    Reason
                </Col>
            </Row>
            {blockedRecipients && blockedRecipients.length > 0 && blockedRecipients[0].map(r => {
                return <Row className="bottom10">
                    <Col xs={3} className="word-wrap">
                        {moment(parseInt(r.created) * 1000).format(defaultDateTimeFormat)}
                    </Col>
                    <Col xs={3} className="word-wrap">
                        {r.email}
                    </Col>
                    <Col xs={6} className="word-wrap">
                        {r.reason}
                    </Col>
                </Row>
            })}
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsSize="small"
                bsStyle="success"
                onClick={() => onHide()}
            >
                OK
            </Button>
        </Modal.Footer>
    </Modal>
}

export default BlockedRecipientsModal;