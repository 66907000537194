import React, {Component} from 'react';
import PropTypes from "prop-types"
import {browserHistory, Link} from "react-router";
import {Col, Panel, Row} from "react-bootstrap";

class LeadMenu extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const {lead, id} = this.props;
        return (
            <div>
                <Row className="top-menu">
                    <Col md={6}>
                        <h2 className="inline no-top-margin">{id ? 'Lead' : 'Leads'}</h2>
                        <Link onClick={() => (id && browserHistory.push(`/lead/${id}`))}
                              className={`${window.location.href.includes('/time') ? '' : 'active'} small-margin`}>{id ? `Lead #${id}` : 'New Lead'}</Link>
                        <Link
                            className={`${window.location.href.includes('/time') ? 'active' : ''} ${id ? '' : 'disabled'} small-margin`}
                            onClick={() => (id && browserHistory.push(`/lead/${id}/time`))}>Find a time</Link>
                        <Link className={id && lead.site_id ? '' : 'disabled'} onClick={() => {
                            lead && lead.site_id && browserHistory.push(`/mapview/${lead.proposal_id || ''}?lead_id=${id}&customer_id=${lead.customer_id}&site_id=${lead.site_id}`)
                        }}>{lead && lead.proposal_id ? `Proposal #${lead.proposal_no}` : 'Create a proposal'}</Link>
                        <Link
                            className={`${lead.customer_id ? '' : 'disabled'} small-margin`}
                            onClick={() => (id && browserHistory.push(`/customer/info/${lead.customer_id}`))}>Customer info</Link>
                    </Col>
                    <Col md={2} className="text-right">
                        <Link className="text-danger small-margin" onClick={() => {
                            !window.location.href.includes('/time') ? browserHistory.push('/leads') : browserHistory.push(`/lead/${id}`);
                        }}>
                            Cancel
                        </Link>
                        <Link className="text-primary small-margin" onClick={this.props.save}>
                            Save
                        </Link>
                    </Col>
                    <Col md={6} className="text-left">
                    </Col>
                </Row>
                <Panel
                    collapsible
                    expanded={false}
                    className="bottom0"
                />
            </div>
        );
    }
}

LeadMenu.propTypes = {
    save: PropTypes.func.isRequired,
    id: PropTypes.object,
    lead: PropTypes.object
};

LeadMenu.defaultProps = {
    //myProp: <defaultValue>
};

export default LeadMenu;
