import React, {Component} from 'react';
import {debounce} from "throttle-debounce";
import _ from 'lodash'

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.handleSearchInputChange = debounce(1000, this.handleSearchInputChange);
    }

    state = {
        stickies: [], searchResult: null, searchResults: [], employees: [], showDisabledUsers: false
    };

    componentDidMount = () => {
        this.setState({
            employee: {value: this.props.auth.employee_id, label: 'ME'}
        }, () => this.loadStickies());
    };

    loadStickies = () => {
        const {type, count, isProposalView, auth} = this.props;
        const {user, searchResult, unreadOnly, arborist, employee, employee_arborist} = this.state;
        const options = {
            type,
            count
        };
        options.unreadOnly = isProposalView ? false : unreadOnly;
        options.userId = isProposalView ? null : (user ? user.value : null);
        options.proposalId = this.props.searchResult ? this.props.searchResult.value : (searchResult ? searchResult.value : null);
        options.arboristId = isProposalView ? null : (arborist ? arborist.value : null);
        options.employee_arborist = isProposalView ? null : (employee_arborist ? employee_arborist.value : null);
        options.employeeId = isProposalView ? null : (employee ? employee.value : null);

        if(isProposalView){
            this.props.searchResult && this.props.actions.load(options, this.updateStickies);
        } else{
            this.props.actions.load(options, this.updateStickies);
        }

        this.props.actions.getShowDisabledUsers(({show_disabled_users_for_notes}) => {
            this.setState({showDisabledUsers: show_disabled_users_for_notes}, () => {
                this.props.actions.getActiveEmployees(show_disabled_users_for_notes, employees => {
                    const unknown = {id: "Unknown", label: "Unknown"}
                    const clientEmployees = [...employees, unknown]
                    this.setState({employees: clientEmployees})
                })
            })
        })
        this.props.actions.getUserStatus(auth.user_id);
    };

    componentWillReceiveProps(nextProps) {
        const {type} = this.props;
        const {searchResult} = this.state
        const reload = nextProps.docks.active === type && this.props.docks.reloadDate !== nextProps.docks.reloadDate;
        if (reload ) {
            this.loadStickies();
        }
        if (!_.isEqual(this.props.searchResult, nextProps.searchResult)) {
            this.setState({searchResult: nextProps.searchResult}, this.loadStickies)
        }
    }

    updateStickies = stickies => {
        this.setState({stickies});
    };

    handleRemove = (sticky, user_id) => {
        const choice = window.confirm('Are you sure?');
        if (choice) {
            this.props.actions.removeNote(sticky, () => {
                this.loadStickies();
                this.props.actions.getUserStatus(user_id);
            })
        }
    };

    showEmailDialog = () => {
        const {email} = this.props.auth;
        const {sticky} = this.state;
        this.props.actions.showEmailDialog(
            {
                emailType: "sticky",
                referenceId: sticky.id,
                recipient: sticky.target_email,
                defaultEmail: email,
                handleAction: this.loadStickies
            });
    };

    handleUserSelect = e => {
        this.setState({user: e}, this.loadStickies);
    };

    handleEmployeeSelect = e => {
        this.setState({employee: e}, this.loadStickies);
    };

    handleArboristSelect = e => {
        this.setState({arborist: e}, this.loadStickies);
    };

    handleEmployeeArboristSelect = e => {
        this.setState({employee_arborist: e}, this.loadStickies);
    };

    handleSearchResultSelect = e => {
        this.setState({searchResult: e}, this.loadStickies);
    };

    handleUnreadChange = e => {
        this.setState({unreadOnly: e.target.checked}, this.loadStickies);
    };

    updateResourceAttr = (e, callback) => {
        this.state.resource[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({resource: this.state.resource}, callback);
    };

    handleSearchInputChange = (e, params) => {
        if (e && e.length > 2) {
            this.setState({searchResults: [{value: 1, label: ''}], isLoading: true}, () => {
                this.props.actions.search(e, params, results => {
                    const searchResults = results.map(x => ({value: x.id, label: x.proposal_no, type: 'Proposal'}));
                    this.setState({searchResults: searchResults, isLoading: false});
                    if (this.searchRef) {
                        this.searchRef.current.focus()
                    }
                })
            })
        }
    };

    setActiveEmployees = (employee_id) => {
        const {employees} = this.state
        let activeEmployees = []

        if (employees?.length > 0) {
            activeEmployees = employees.map(e => {
                if (e.employee_id === employee_id) {
                    return ({value: e.employee_id, label: 'ME'})
                } else if (e.id === "Unknown") {
                    return ({value: "Unknown", label: "Unknown"})
                }
                return ({value: e.employee_id, label: `${e.first_name} ${e.last_name}`})
            })
            return activeEmployees
        }
    }
}
