import React, {Component} from "react"

import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Panel,
    PanelFooter,
    Row,
    Table,
    MenuItem,
} from "react-bootstrap"

import {LinkContainer} from "react-router-bootstrap"

import Dollars from "../../components/Dollars"

import Select from "react-select"
import moment from "moment-timezone"
import {mapForSelect, select} from "../../common/commonHandlers";

export default class ProposalServiceForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      closePanel: false,
    }
  }

  updateAttribute(attr, newVal) {
    this.props.updatePsAttribute(this.props.proposalService.id, attr, newVal)
  }

  openClosePanels = () => {
    this.setState({
      closePanel: !this.state.closePanel,
    })
    this.props.updateHaveOpenService(this.props.proposalService.id, !this.state.closePanel)
  }

  saveChanges(e) {
    e.preventDefault(e) // prevent page refresh if the user submits teh form by hitting the enter key
    this.props.savePsChanges(this.props.proposalService.id)
    this.openClosePanels()
  }

  saveAndAddNewService = () => {
    this.props.savePsChanges(this.props.proposalService.id)
    this.openClosePanels()

    setTimeout(function () {
      this.props.onAddNewPs()
    }.bind(this), 2000);
  }


  recalculating(field, newVal) {
    const val = parseFloat(newVal)

    //first we can always update the desired attribute to the new value
    this.updateAttribute(field, val)

    //now decide the 2nd thing we have to modify for teh calculation to stay correct
    //time is a constant (unless its what was changed)
    switch (field) {
      case "serviceCost":
        //modify rate
        this.updateAttribute(
          "aveSalaryRate",
          val / this.props.proposalService.manHours
        )
        return true
      case "aveSalaryRate":
        //modify total
        this.updateAttribute(
          "serviceCost",
          val * this.props.proposalService.manHours
        )
        return true
      case "manHours":
        //update total
        this.updateAttribute(
          "serviceCost",
          val * this.props.proposalService.aveSalaryRate
        )
        return true
      default:
        alert(
          `Unable to change service costs for field ${field}. Please contact IT!`
        )
        return false
    }
  }

  deleteProposalService = () => {
    const areYouSure = window.confirm(
      "Are you sure you want to delete this Proposal Service? This cannot be undone!"
    )
    if (areYouSure && this.props.proposalService.deleteable) {
      console.info("About to delete this ps!")
      this.props.deleteProposalService(this.props.proposalService.id)
    } else {
      console.info("User aborted deleting PS")
    }
  }

  selectService(service) {
    this.updateAttribute("serviceId", service.value)
    this.updateAttribute("serviceName", service.label)
    this.changeServiceDetails(service.value)
  }

  selectPriority(priority) {
    this.updateAttribute("priorityId", priority ? priority.value : null)
    this.updateAttribute("priority", priority ? priority.label : null)
  }

  changeServiceDetails(serviceId) {
    //when the user selects a new servive, we have to show the correct prices/notes for it
    //this is action craeater stuff. pull it out

    const newService = this.props.serviceList.find(s => s.id === serviceId)
    this.updateAttribute(
      "manHours",
      parseFloat(newService.estimated_total_time)
    )
    this.updateAttribute("aveSalaryRate", parseFloat(newService.rate))
    this.updateAttribute(
      "serviceCost",
      parseFloat(newService.estimated_total_time) * parseFloat(newService.rate)
    )
    this.updateAttribute("notes", newService.skills)
  }

  panTo = (lat, lng, assId) => {
    //window._googleMapComponent.props.map.setCenter({lat: lat, lng: lng})
    let currentAssetId = localStorage.getItem("currentAssetId")
    // if (currentAssetId === null) {
    //   localStorage.setItem("currentAssetId", assId)
    // }

    if (assId && currentAssetId === assId) {
      this.props.onAssetMouseOver(assId)
    } else {
      this.props.onAssetMouseOut(assId)
    }
    localStorage.setItem("currentAssetId", assId)
    window._googleMapComponent.panTo &&
    window._googleMapComponent.panTo({ lat: lat, lng: lng })
    // console.log("panTo--->", this.props.onAssetMouseOut(assId))
  }

  renderSelectedTrees(siteAssetIds, siteAssets) {
    const selectedSiteAssets = siteAssets.filter(a =>
      siteAssetIds.includes(a.id)
    )

    const rows = selectedSiteAssets.map(ass => {
      const dbh = this.props.siteAssetsDbhs[`latest_dbh_assetId_${ass.id}`]
      return (
        <tr key={ass.id}>
          <td
            onClick={() => this.props.onAssetClick(ass)}
          // onMouseOut={() => this.props.onAssetMouseOut(ass.id)}
          >
            {ass.label} {ass.plant_name}{" "}
            {ass.plant_count > 1 ? `[${ass.plant_count}]` : null}{" "}
          </td>
          <td>
            DBH: {(dbh && dbh.latestDbh) || null} "
          </td>
          <td>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.removeAssetFromPs(
                  this.props.proposalService.id,
                  ass.id
                )
              }}
            >
              {" "}<span className="text-danger">
                <Glyphicon glyph="remove-sign" />
              </span>{" "}
            </a>
          </td>
        </tr>
      )
    })

    return (
      <Table striped condensed hover>
        <tbody>
          {rows}
        </tbody>
      </Table>
    )
  }

  renderSelectedEquipment(equipmentIds, equipments) {
    const selectedEquipments = equipments.filter(a =>
      equipmentIds.includes(a.id)
    )

    const rows = selectedEquipments.map(ass => (
      <p key={ass.id}>
        {ass.name}
        <a
          style={{ cursor: "pointer" }}
          onClick={() =>
            this.props.removeEquipment(this.props.proposalService.id, ass.id)}
        >
          {" "}<span className="text-danger">
            <Glyphicon glyph="remove-sign" />
          </span>{" "}
        </a>
      </p>
    ))

    return (
      <span>
        {rows}
      </span>
    )
  }

  getPsAssetLabels(psAssetIds, siteAssets) {
    //console.log("inputs are", psAssetIds, siteAssets)

    if (psAssetIds.length === 0 || siteAssets.length === 0) {
      return "no assets"
    }

    const labels = psAssetIds.map(id => {
      const foundAsset = siteAssets.find(asset => id === asset.id)
      return foundAsset.label
    })

    return labels.join(", ")
  }

  render() {
    const {
      proposalService,
      serviceList,

      equipmentList,

      priorities,

      siteAssets,
      addAssetsToPs,
      onAssetMouseOver,
      onAssetMouseOut,
      onAddNewPs,
      haveOpenService,
      movePs,
    } = this.props

    const titleStyle = {
      fontSize: 12,
      cursor: "pointer",
    }

    const inlineStyle = {
      display: "inline-block",

      width: "70px",
      marginRight: "10px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }
    const serviceListOptions = mapForSelect(serviceList);
    const prioritiesOptions = mapForSelect(priorities);

    if (proposalService === undefined) {
      return (
        <Panel
          collapsible
          defaultExpanded={haveOpenService}
        />
      )
    }
    const title = (
      <div style={titleStyle} onClick={this.openClosePanels}>
        <div style={{ ...inlineStyle, width: "25px" }}>
          # {proposalService.serviceNo}
        </div>

        <div style={{ ...inlineStyle, width: "150px" }}>
          {proposalService.serviceName}
        </div>

        <div style={inlineStyle}>
          {this.getPsAssetLabels(proposalService.assetIds, siteAssets)}
        </div>

        <div style={inlineStyle}>
          {proposalService.manHours} {proposalService.manHours > 1 ? 'hrs' : 'hr'}
        </div>

        <div style={inlineStyle}>
          {proposalService.priority || ''}
        </div>

        <div style={inlineStyle}>
          {proposalService.serviceStatusName}
        </div>

        <div
          className="text-right"
          style={{ ...inlineStyle, marginRight: "0px" }}
        >
          <Dollars amount={proposalService.serviceCost} />

        </div>
      </div>
    )

    const renderSaveAndAddNewPSButton = (
      <Button
        type="button"
        bsStyle="success"
        bsSize="xsmall"
        style={{ 'marginLeft': '1rem' }}
        onClick={()=> this.saveAndAddNewService()}
      >
        {`Save & Add New Service`}
      </Button>
    )

    return (
      <div style={{ position: "relative" }}>
        {/* movePs &&
          <div>
            <div
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 5,
                right: 5,
              }}
            >
              <span onClick={() => movePs(proposalService.id, "up")}>
                <Glyphicon glyph="chevron-up" />
              </span>
            </div>
            <div
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 27,
                right: 5,
              }}
            >
              <span onClick={() => movePs(proposalService.id, "down")}>
                <Glyphicon glyph="chevron-down" />
              </span>
            </div>
          </div> */}

        <Panel
            className="proposal-form-panel"
          collapsible
          header={title}
          expanded={(proposalService && proposalService.id === 0) ? null : haveOpenService }
          defaultExpanded={proposalService.id === 0 ? true :  haveOpenService }
        >
          <form onSubmit={e => this.saveChanges(e)}>
            <Row>
              <Col xs={8}>
                <FormGroup bsSize="small">
                  <ControlLabel>Service</ControlLabel>
                  <br />

                  <Select className="Select" classNamePrefix="select"
                    value={select(serviceListOptions, proposalService.serviceId)}
                    options={serviceListOptions.sort((a, b) => a.label < b.label)}
                    onChange={s => this.selectService(s)}
                  />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup bsSize="small">
                  <ControlLabel>Priority</ControlLabel>
                  <br />
                  <Select className="Select" classNamePrefix="select"
                    name="select_priority"
                    value={select(prioritiesOptions, proposalService.priorityId)}
                    default={''}
                    options={prioritiesOptions
                      .sort((a, b) => a.label < b.label)}
                    onChange={p => this.selectPriority(p)}
                    placeholder="Select Priority"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={4} className="text-left">
                {
                  proposalService.invoiceId !== null
                  ?
                  <LinkContainer className="wo-link" to={`/customer/manage_invoices/${proposalService.customerId}?invoice=${proposalService.invoiceId}`}>
                    <MenuItem>
                      Invoice No: {proposalService.invoiceNo}
                    </MenuItem>
                  </LinkContainer>
                  :
                    null
              }
              </Col>
              <Col xs={4} className="text-left">
                {proposalService.workOrderId !== null
                  ?
                  <LinkContainer className="wo-link" to={`/work_orders/${proposalService.proposalId}`}>
                    <MenuItem>
                      {`WO #${proposalService.workOrderNo}`}
                    </MenuItem>
                  </LinkContainer>
                  :
                  null}
              </Col>
              <Col xs={4} className="text-right">
                {proposalService.scheduledEvents ? proposalService.scheduledEvents.map((event, i) => (
                    <div key={i} className="text-danger">
                      <strong>Scheduled: {moment(event.event_start).format("MMM D, YYYY")}</strong>
                    </div>
                  ))
                  :
                  null}
              </Col>
            </Row>
            <Row>
            <Col xs={12} className="text-right">
                <Button
                  onClick={() => addAssetsToPs(proposalService.id)}
                  bsSize="xsmall"
                >
                  Add Selected Assets
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <br />
                {this.renderSelectedTrees(proposalService.assetIds, siteAssets)}
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <FormGroup bsSize="small">
                  <ControlLabel>Proposal Service Notes</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    rows={3}
                    placeholder="Proposal Service Notes"
                    value={proposalService.notes}
                    onChange={e =>
                      this.updateAttribute("notes", e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <FormGroup bsSize="small">
                  <ControlLabel>Work Order Notes</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    rows={3}
                    placeholder="Work Order Notes"
                    value={proposalService.workOrderNotes}
                    onChange={e =>
                      this.updateAttribute("workOrderNotes", e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4}>
                <FormGroup bsSize="small">
                  <ControlLabel>Man Hours</ControlLabel>
                  <FormControl
                    placeholder="Man Hours"
                    value={proposalService.manHours}
                    onChange={e =>
                      this.updateAttribute("manHours", e.target.value)}
                    onBlur={e => this.recalculating("manHours", e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col xs={4}>
                <FormGroup bsSize="small">
                  <ControlLabel>Rate</ControlLabel>
                  <FormControl
                    placeholder="Rate"
                    value={proposalService.aveSalaryRate}
                    onChange={e =>
                      this.updateAttribute("aveSalaryRate", e.target.value)}
                    onBlur={e =>
                      this.recalculating("aveSalaryRate", e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col xs={4}>
                <FormGroup bsSize="small">
                  <ControlLabel>Service Total</ControlLabel>
                  <FormControl
                    placeholder="Service Total"
                    // value={Math.round(proposalService.serviceCost * 100) / 100}
                    value={
                      Math.round(proposalService.serviceCost * 1000) / 1000
                    }
                    onChange={e =>
                      this.updateAttribute("serviceCost", e.target.value)}
                    onBlur={e =>
                      this.recalculating("serviceCost", e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />

            <Row>
              <Col xs={12}>
                <FormGroup bsSize="small">
                  <ControlLabel>Optional Equipment</ControlLabel>
                  <FormControl
                    componentClass="select"
                    onChange={e =>
                      this.props.addEquipment(
                        proposalService.id,
                        e.target.value
                      )}
                    placeholder="Select Optional Equipment"
                  >
                    <option key={0} value={0}>
                      Select Optional Equipment
                    </option>
                    {equipmentList.map(e => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      )
                    })}
                  </FormControl>
                </FormGroup>
                {this.renderSelectedEquipment(
                  proposalService.equipmentIds,
                  equipmentList
                )}
              </Col>
            </Row>

            <hr />

            <Row>
              <Col xs={6} className="text-left">
                <Button
                  bsStyle="danger"
                  disabled={!proposalService.deleteable}
                  onClick={this.deleteProposalService}
                  bsSize="xsmall"
                >
                  {proposalService.deleteable ? "Delete" : "Not Deleteable"}
                </Button>
              </Col>

              <Col xs={6} className="text-right">
                <Button
                  type="submit"
                  bsStyle={proposalService.id === 0 ? "success" : "success"}
                  bsSize="xsmall"
                >
                  {proposalService.id === 0
                    ? "Save New Service"
                    : "Save Changes"}
                </Button>
                {renderSaveAndAddNewPSButton}
              </Col>
            </Row>
          </form>
        </Panel>
      </div>
    )
  }
}
