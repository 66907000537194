import React, { Component } from "react"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { LinkContainer } from "react-router-bootstrap"

import Helmet from "react-helmet"
import PageHeader from "../../components/PageTitle"
import { Grid, Row, Col, Well, Button } from "react-bootstrap"

import SearchWidget from "./SearchWidget"

import * as Actions from "./actions"

import SearchResults from "./SearchResults"
import {saveNewCsvDownload} from "../../common/commonHandlers";

class CustomerSearch extends Component {
  render() {
    const { searchResults } = this.props

    return (
      <Grid fluid>
        {/*<Helmet title="Customers" />*/}
        {/*<PageHeader pageName="Customers" pageDetail={`- find a customer`} />*/}

        {/*<p>&nbsp;</p>*/}
        <Well>
          <Row>
            <Col md={8}>
              <SearchWidget doSearch={this.props.actions.doSearch} />
            </Col>
            <Col md={4}>
              <LinkContainer to="/customer/info">
                <Button bsStyle="primary">New Customer</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Well>

        <p>&nbsp;</p>

        <Row>
          <Col md={12}>
            <h5 className="text-primary text-center">{searchResults.length > 0 ? `${searchResults.length} Customers Returned` : null}</h5>

            <SearchResults rows={searchResults} saveNewCsvDownload={this.props.actions.saveNewCsvDownload}/>
          </Col>
        </Row>

      </Grid>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchResults: state.customerSearch.searchResults,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({...Actions, saveNewCsvDownload}, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch)
