import axios from "axios/index";
import {mapForSelect} from "../../common/commonHandlers";
import {apiCall} from "../../common/apiActionHandler";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../../common/upload";

export const loadService = callback => (dispatch, getState) => {
    const config = [
        {url: '/api/service_types'},
        {url: '/api/consumables'},
        {url: '/api/equipments/search'},
        {url: '/api/trucks'},
        {url: '/api/application_sites'},
        {url: '/api/application_types'},
        {url: '/api/plants'},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            serviceTypesList: mapForSelect(all[0].data),
            consumables: mapForSelect(all[1].data),
            equipments: mapForSelect(all[2].data),
            trucks: mapForSelect(all[3].data.data),
            applicationSites: mapForSelect(all[4].data),
            applicationTypes: mapForSelect(all[5].data),
            plants: mapForSelect(all[6].data),
        });
    }, dispatch, getState, false);
};
export const loadResource = (id,callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/services/${id}`},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            resource: all[0].data,
        });
    }, dispatch, getState, false);
};

export const updateService = (services, direction, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_service`,
        method: 'PUT',
        data: {services: {...services}, direction: direction}
    };

    apiCall("Updating service", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const searchForQbAccount = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/search_for_account`,
        params: {search_text: searchText}
    };

    apiCall("Searching for quickbooks accounts", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const downloadCsv = (params) => async (dispatch, getState) => {
    const config = {
        url: `/api/services/csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `services-${moment().format()}.csv`);

        blob.name = `services-${moment().format()}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(params ? params : {}), source: "services"}, false)(dispatch, getState)
    }, dispatch, getState);
};

