import axios from 'axios'
import {apiCall} from "../../../common/apiActionHandler";

export const getTerms = callback => async (dispatch, getState) => {
    const config = {
        method: 'get',
        url: `/api/terms`
    }

    apiCall("Getting terms list", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};

export const getTerm = (id, callback) => async (dispatch, getState) => {
    const config = {
        method: 'get',
        url: `/api/terms/${id}`
    }

    apiCall("Getting selected term", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};

export const saveTerm = (term, callback) => async (dispatch, getState) => {
    const config = {
        method: term.id ? 'PUT' : 'POST',
        url: term.id ? `/api/terms/${term.id}` : `/api/terms`,
        data: {term}
    }

    apiCall("Terms saved successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data)
    }, dispatch, getState, true);
};

export const publishTerm = (term_id, callback) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/terms/publish_term/`,
        data: {id: term_id}
    }

    apiCall("Terms published successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data)
    }, dispatch, getState, true);
};

export const deleteTerm = (id, callback) => async (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/terms/${id}`
    }

    apiCall("Terms removed successfully", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, true);
};
