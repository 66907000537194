import {FETCH_SALES_ARBORISTS} from '../constants'


const initialState = {
  salesArboristsList: []
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_SALES_ARBORISTS:
      return {...state, salesArboristsList: action.salesArboristsList}
    default:
      return state
  }
}
