import {FETCH_TRUCKS_LIST} from '../constants'

const initialState = {
    trucksList: [],
    trucks: {},
    trucksListLastUpdated: 0,
    editingTruckId: null,
    filterText: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TRUCKS_LIST:
            return {
                ...state,
                trucksList: action.trucksList,
                trucksListLastUpdated: action.trucksListLastUpdated,
            };
        default:
            return state
    }
}
