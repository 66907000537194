import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const getProgress = (callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/general_progress`,
    }

    apiCall("General progress load successfully", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};
