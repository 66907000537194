import axios from "axios";
import {addAlert} from "../App/actions";
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import * as FileSaver from "file-saver";
import printJS from "print-js";
import {dataUpload} from "../../common/upload";

export const updateInvoicePrintOptions = (invoiceId, value, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'PUT',
        url: `/api/invoice_print/${invoiceId}/print_options`,
        headers: {Authorization: token},
        data: {
            value
        },
    };

    try {
        const response = await axios.request(config);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update invoice print option" + exception.toString(),
            })
        );
        throw exception
    }
};

export const getInvoice = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/invoice_print/${id}`
    };

    apiCall("Getting invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const printPdf = (invoice, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${invoice.token}/invoice_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${invoice?.client_name}-Invoice #${invoice?.number}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(invoice ? invoice : {}), source: "invoice pdf"}, false)(dispatch, getState)
        const url = URL.createObjectURL(blob);
        printJS(url);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};
export const downloadPdf = (invoice, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${invoice.token}/invoice_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${invoice.client_name}-Invoice #${invoice.number}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(invoice ? invoice : {}), source: "invoice pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const fetchInvoice = (invoiceToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${invoiceToken}/load_invoice`,
    };

    apiCall("Fetching invoice", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
export const updateUserInvoicePrintOptions = (userId, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${userId}/invoice_print_options`,
        data: {
            invoice_print_options: value
        },
        method: 'PUT'
    };

    apiCall("Make print options default", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};
export const resetInvoicePrintOptions = (invoiceId, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/invoice_print/${invoiceId}/reset_print_options`,
        data: {
            reset_print_options: value
        },
        method: 'PUT'
    };

    apiCall("Reset print options to default", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};
export const fetchUser = (userId, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'GET',
        url: `/api/users/${userId}`,
        headers: {Authorization: token},
    };

    try {
        const response = await axios.request(config);
        callback(response.data);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the user " + exception.toString(),
            })
        );
        throw exception
    }
};