import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";

export const getCustomersSearch = (searchText, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_customers/search_customers/`,
        params: {q: searchText}
    };

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const removeCustomer = (resource, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_customers/remove_customer`,
        method: 'PUT',
        data: resource
    };

    apiCall("Customer has been removed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback);
};