import {
  FETCH_EMPLOYEE_LIST,
  FETCH_EMPLOYEE,
  UPDATE_EMPLOYEE_ATTR,
  ADD_NEW_EMPLOYEE,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE,
} from "../constants"

import axios from "axios"
import { addAlert } from "../containers/App/actions"
import {apiCall} from "../common/apiActionHandler";

//READ (abriged index)
export const fetchEmployeeList = () => (dispatch, getState) => {
  const store = getState()
  const config = {
    url: "/api/employees/info",
    headers: { Authorization: store.auth.token },
  }

  return axios.request(config).then(response => {
    dispatch({
      type: FETCH_EMPLOYEE_LIST,
      employeeList: response.data,
      employeeListLastUpdated: Date.now(),
    })
  })
}

//READ (show)
export const fetchEmployee = (id, callback) => (dispatch, getState) => {
  const store = getState()
  const config = {
    url: `/api/employees/${id}`,
    headers: { Authorization: store.auth.token },
  }

  return axios.request(config).then(async response => {
    await callback && callback(response.data)
    dispatch({ type: FETCH_EMPLOYEE, employee: response.data })
  })
}

export const updateEmployeeAttribute = (id, attr, val) => {
  return { type: UPDATE_EMPLOYEE_ATTR, id, attr, val }
}

export const updateEmployee = (id, val) => {
  return { type: UPDATE_EMPLOYEE, id, val }
}

//DELETE
export const deleteEmployee = (id, callback) => (dispatch, getState) => {
  if (id === 0) {
    //delete it locally assuming it will be deleted on the server
    dispatch({ type: DELETE_EMPLOYEE, id })
    return true
  }

  const state = getState()
  const config = {
    method: "delete",
    url: `/api/employees/${id}`,
    headers: { Authorization: state.auth.token },
  }

  axios.request(config).then(response => {
    callback && callback();
    dispatch(
      addAlert({ message: "Deleted", mood: "warning", dismissAfter: 1500 })
    )
  })
}

//CREATE/UPDATE
export const saveEmployee = id => (dispatch, getState) => {
  const state = getState()
  console.log('employee params', state.employees.employees)
  const employee = state.employees.employees[`employee_${id}`];
  const config = {
    method: id === 0 ? "post" : "put",
    url: id === 0 ? "/api/employees" : `/api/employees/${id}`,
    headers: { Authorization: state.auth.token },
    data: {
      employee: employee,
      user: state.employees.employees[`employee_${id}`].user,
    },
  }

  return axios.request(config).then(response => {
    //returns the saved plant back so we can re-shove in whats in the db for paranoia
    //we dont depend on this
    dispatch({ type: FETCH_EMPLOYEE, employee: response.data })

    if (id === 0) {
      dispatch(deleteEmployee(0))
    }

    dispatch(
      addAlert({
        message: id === 0 ? "Added" : "Saved",
        mood: "success",
        dismissAfter: 1500,
      })
    )
    return response // we're returning the promise and response so the callee can do something with the data
  })
}

//CREATE (add blank model)
export const createNewEmployee = () => {
  return { type: ADD_NEW_EMPLOYEE }
}

export const saveUserInfo = (user, callback, errorCallback) => async (dispatch, getState) => {
  const userId = user.id;
  const config = {
    method: userId ? "PUT" : "POST",
    url: userId ? `/api/users/${userId}` : `/api/users/`,
    data: {user}
  };

  apiCall(userId ? "User updated" : "User saved", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data);
  }, dispatch, getState, true, errorCallback);
};

export const generatePassword = (callback) => async (dispatch, getState) => {
  const config = {
    url: '/api/employees/generate_password',
  };

  apiCall("Generate Password", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data);
  }, dispatch, getState, false);
};