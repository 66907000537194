import {FETCH_CUSTOMER_TYPES_LIST} from '../constants'

const initialState = {
    customerTypesList: [],
    customerTypes: {},
    customerTypesListLastUpdated: 0,
    editingCustomerTypeId: null,
    filterText: '',
};
// DEPRECATED
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CUSTOMER_TYPES_LIST:
            return {
                ...state,
                customerTypesList: action.customerTypesList,
                customerTypesListLastUpdated: action.customerTypesListLastUpdated,
            };
        default:
            return state
    }
}
