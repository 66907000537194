import {FETCH_SERVICE_TYPES_LIST} from '../constants'

import axios from 'axios'

// TODO: DEPRECATED
export const fetchServiceTypesList = () => (dispatch, getState) => {
    const store = getState()
    const config = {
        url: '/api/service_types',
        headers: {Authorization: store.auth.token},
    };

    return axios.request(config).then(response => {
        dispatch({
            type: FETCH_SERVICE_TYPES_LIST,
            serviceTypesList: response.data,
            serviceTypesListLastUpdated: Date.now(),
        })
    });
};
