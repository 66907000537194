import React, {Component} from "react"
import * as MyActions from "./NewWorkOrderPrintApi"
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {NewWorkOrderPrintOutput} from "./NewWorkOrderPrintOutput";

class PdfPrintWorkOrderPage extends Component {

    state = {};

    componentWillMount = () => {
        this.props.actions.fetchWorkOrder(this.props.params.token, (work_order) => {
            work_order.site_maps.forEach(sitemap => {
                const property = {};
                property[`maps_${sitemap.id}`] = sitemap.include_when_print;
                this.setState(property);
            });
            this.setState({work_order})
        }, true);
    };

    render() {
        const {work_order} = this.state;
        if (!work_order) {
            return <div>Loading...</div>
        }
        return <NewWorkOrderPrintOutput work_order={work_order && work_order} options={work_order && JSON.parse(work_order.print_options)}/>
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(MyActions, dispatch),
});


export default connect(undefined, mapDispatchToProps)(PdfPrintWorkOrderPage)
