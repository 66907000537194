import React from 'react';
import {Col, FormGroup, Glyphicon, Row} from "react-bootstrap";
import Select from "react-select";
import ResourceComponent from "../../components/ResourceComponent";
import {defaultDateFormat, defaultTimeFormat, mapForSelect, select} from "../../common/commonHandlers";
import Datetime from "react-datetime";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import MDSpinner from "react-md-spinner";

class ServicePrioritiesFilter extends ResourceComponent {
    state = {loading: true}

    componentDidMount() {
        this.props.fetchFilterData(this.props.resource.id, (data) => this.setState({data: data, loading: false}))
    }

    nameMapForSelect = (data) => {
        return data.map(x => ({value: x.id, label: x.name.replace(/[()]/g, '').replace(',', ' ')}));
    }

    render() {
        const {resource, selectAttr, dateResourceAttr, selectCheckboxAttr} = this.props;
        const {data, loading} = this.state
        return (
            <>
                {loading && <div className="overlay"><MDSpinner/></div>}
                <Row className="mt-15">
                    <Col md={6}>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    placeholder="Service Types"
                                    value={data && select(mapForSelect(data.service_types), resource.service_type)}
                                    isClearable
                                    isMulti
                                    onChange={selectAttr('service_type')}
                                    options={data && mapForSelect(data.service_types)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    placeholder="Services"
                                    value={data && select(mapForSelect(data.services), resource.service)}
                                    isClearable
                                    isMulti
                                    onChange={selectAttr('service')}
                                    options={data && mapForSelect(data.services)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    placeholder="Customer types"
                                    value={data && select(mapForSelect(data.customer_types), resource.customer_type)}
                                    isClearable
                                    isMulti
                                    onChange={selectAttr('customer_type')}
                                    options={data && mapForSelect(data.customer_types)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    placeholder="Customers"
                                    value={data && select(mapForSelect(data.customers), resource.customer)}
                                    isClearable
                                    isMulti
                                    onChange={selectAttr('customer')}
                                    options={data && mapForSelect(data.customers)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-15">
                    <Col md={6}>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    placeholder="Arborists"
                                    value={data && select(this.nameMapForSelect(data.arborists), resource.arborist)}
                                    isClearable
                                    isMulti
                                    onChange={selectAttr('arborist')}
                                    options={data && this.nameMapForSelect(data.arborists)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    placeholder="Proposals #"
                                    value={data && select(mapForSelect(data.proposals), resource.proposal)}
                                    isClearable
                                    isMulti
                                    onChange={selectAttr('proposal')}
                                    options={data && mapForSelect(data.proposals)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup bsSize="sm">
                            <Datetime
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={defaultTimeFormat}
                                value={resource.accepted_from}
                                className="text-center"
                                inputProps={{placeholder: 'Accepted From'}}
                                onChange={dateResourceAttr('accepted_from')}
                                closeOnSelect
                            />
                            {" "}
                            <span className='text-default'><Glyphicon glyph='minus'/></span>
                            {" "}
                            <Datetime
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={defaultTimeFormat}
                                value={resource.accepted_to}
                                className="text-center"
                                inputProps={{placeholder: 'Accepted To'}}
                                onChange={dateResourceAttr('accepted_to')}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <ColorCheckbox
                            value={resource?.completedProposal}
                            label="Completed"
                            onChange={selectCheckboxAttr('completedProposal')}
                        />
                        <ColorCheckbox
                            value={resource?.invoicedProposal}
                            label="Invoiced"
                            onChange={selectCheckboxAttr('invoicedProposal')}
                        />
                        <ColorCheckbox
                            value={resource?.paidProposal}
                            label="Paid"
                            onChange={selectCheckboxAttr('paidProposal')}
                        />
                    </Col>
                </Row>
            </>
        )
    };
}

export default ServicePrioritiesFilter;