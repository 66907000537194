import React from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import moment from "moment";
import './TruckHistoryModal.scss'
import {defaultDateTimeFormatShort} from "../../common/commonHandlers";

const TruckHistoryModal = ({onHide, history}) => {
    return <Modal
        show={true}
        animation={true}
        className={"heightAuto maxHeight90 fontIOS"}
        id='truck-history-modal'
        onHide={() => onHide()}
    >
        <Modal.Header>
            <Modal.Title>History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {history ?
                history.map((h, i) => {
                    return <Row>
                        <Col xs={4} className='text-right'>
                            <b>{h.user_name}</b>
                            <p>{moment(h.time).format(defaultDateTimeFormatShort)}</p>
                        </Col>
                        <Col xs={8}>
                            {h.initialize_truck &&
                                <div>
                                    <p><b>The Truck has been created with data:</b></p>
                                    <p>Title: <b>{h.initialize_truck?.name}</b></p>
                                    <p>Year: <b>{h.initialize_truck?.year}</b></p>
                                    <p>Make: <b>{h.initialize_truck?.make}</b></p>
                                    <p>Model: <b>{h.initialize_truck?.model}</b></p>
                                    <p>Type: <b>{h.initialize_truck?.type}</b></p>
                                    <p>Class: <b>{h.initialize_truck?.class}</b></p>
                                    <p>Unit: <b>{h.initialize_truck?.unit}</b></p>
                                </div>
                            }
                            {h.changes && h.changes.map(change => {
                                return <div>
                                    <p className='no-margin'><b>{change.description}</b></p>
                                    {change.deleted && <p className='no-margin'>Deleted: <b dangerouslySetInnerHTML={{__html: change.deleted}}/></p>}
                                    {change.added && <p className='no-margin'>Added: <b dangerouslySetInnerHTML={{__html: change.added}}/></p>}
                                    {change.changed && <p className='no-margin'>Changed: <b dangerouslySetInnerHTML={{__html: change.changed}}/></p>}
                                    {change.old && <p className='no-margin'>Old value: <b>{change.old}</b></p>}
                                    {change.new && <p>New value: <b>{change.new}</b></p>}
                                </div>
                            })
                            }
                        </Col>
                        <Col xs={12}>{i + 1 !== history.length && <hr/>}</Col>
                    </Row>
                })
                :
                <h4 className='text-center'>The Truck has no history yet!</h4>
            }
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col mdOffset={6} md={6} className="text-right">
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default TruckHistoryModal;