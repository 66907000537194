export const [
  FETCH_CUSTOMER_INFO,
  FETCH_STATE_AND_CITY,
  SAVED_CLIENT_INFO,
  FETCH_USERS,
  FETCH_ROLES,
  FETCH_EMPLOYEES,
  SAVED_USER_INFO,
  DELETED_USER,
  FETCH_IMAGES_INFO
] = [
  "FETCH_CUSTOMER_INFO",
  "FETCH_STATE_AND_CITY",
  "SAVED_CLIENT_INFO",
  "FETCH_USERS",
  "FETCH_ROLES",
  "FETCH_EMPLOYEES",
  "SAVED_USER_INFO",
  "DELETED_USER",
  "FETCH_IMAGES_INFO",
]
