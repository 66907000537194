export const [
  UPDATE_SALES_ARBORIST_SEARCH,
  FETCHED_SALES_ARBORISTS,
  UPDATE_CUSTOMER_SEARCH,
  FETCHED_CUSTOMER_SEARCH,
  SELECT_CUSTOMER,
  FETCHED_CUSTOMER_SITES,
  SELECTED_SALES_ARBORIST,
  UPDATE_SITE_SEARCH,
  SELECT_SITE,
  FETCHED_SITE_ASSETS,
  UPDATE_PROPOSAL,
  RESET_MAP_VIEW,
  FETCH_PROPOSAL_SERVICES,
  FETCH_PS_EQUIPMENT,
  FETCH_SERVICES,
  FETCH_PRIORITIES,
  EDITING_ASSET_ID,
  UPDATE_PROPOSAL_SERVICE,
  DELETE_PROPOSAL_SERVICE,
  UPDATE_ASSET,
  FETCH_DBHS,
  ADD_NEW_PS,
  FETCH_SITE_INFO,
  ADD_NEW_ASSET,
  CLEAR_BLANK_ASSET,
  UPDATE_HAVE_OPEN_SERVICE,
  TOGGLE_FULL_SCREEN,
] = [
  "UPDATE_SALES_ARBORIST_SEARCH",
  "FETCHED_SALES_ARBORISTS",
  "UPDATE_CUSTOMER_SEARCH",
  "FETCHED_CUSTOMER_SEARCH",
  "SELECT_CUSTOMER",
  "FETCHED_CUSTOMER_SITES",
  "SELECTED_SALES_ARBORIST",
  "UPDATE_SITE_SEARCH",
  "SELECT_SITE",
  "FETCHED_SITE_ASSETS",
  "UPDATE_PROPOSAL",
  "RESET_MAP_VIEW",
  "FETCH_PROPOSAL_SERVICES",
  "FETCH_PS_EQUIPMENT",
  "FETCH_SERVICES",
  "FETCH_PRIORITIES",
  "EDITING_ASSET_ID",
  "UPDATE_PROPOSAL_SERVICE",
  "DELETE_PROPOSAL_SERVICE",
  "UPDATE_ASSET",
  "FETCH_DBHS",
  "ADD_NEW_PS",
  "FETCH_SITE_INFO",
  "ADD_NEW_ASSET",
  "CLEAR_BLANK_ASSET",
  "UPDATE_HAVE_OPEN_SERVICE",
  "TOGGLE_FULL_SCREEN",
]

export const autocompleteStyles = {
  item: {
    padding: "5px 10px",
    cursor: "default",
    color: "black",
    zIndex: "7337",
  },

  highlightedItem: {
    color: "white",
    background: "hsl(200, 50%, 50%)",
    padding: "5px 10px",
    cursor: "default",
    zIndex: "7337",
  },

  menu: {
    border: "solid 1px #ccc",
    zIndex: "7337",
  },
}
