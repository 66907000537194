import React from "react";
import "./MaterialSwitch.scss"

export const MaterialSwitch = ({value, onChange, label, label2, style}) => {
    return (
        <div className="material-switch-component pb-10" style={style}>
            <span className="mr-10">{label}</span>
            <input id={`material-switch-${label}`} type="checkbox"
                   checked={value}
                   onChange={onChange}
            />
            <label htmlFor={`material-switch-${label}`} className="label-default"></label>
            {label2 && <span className="ml-10">{label2}</span>}
        </div>
    )
}