import axios from "axios"
import {mapForSelect} from "../../common/commonHandlers";
import {apiCall} from "../../common/apiActionHandler";

export const load = (callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees?disabled=true`},
        {url: `/api/new_services?bundle_service=false`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const data = {
            salesArborists: all[0].data,
            services: mapForSelect(all[1].data),
        };
        callback(data);
    }, dispatch, getState, false);
};

export const fetchCompletedProposals = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/completed_proposals`,
        params: filter
    };

    apiCall("Getting completed proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
