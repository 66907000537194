import React, {Component} from 'react';
import PropTypes from "prop-types"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from './actions'

import PageHeader from '../../components/PageTitle'
import SearchByProposalNo from './SearchByProposalNo'
import {
  Grid,
  Row,
  Col,
  Panel,
  Table,
  FormGroup,
  Checkbox,
  Button,
} from 'react-bootstrap'
import { Link } from 'react-router'

import Helmet from 'react-helmet'
import Stickies from '../Stickies'

import ThDate from '../../components/ThDate'
import Dollars from '../../components/Dollars'
import moment from 'moment'
import Datetime from "react-datetime";
import {defaultDateFormat} from "../../common/commonHandlers";

class AcceptProposals extends Component {
  constructor(props) {
    super(props)
    this.state = { allChecked: false }
  }

  componentDidMount() {
    if (this.props.requestedProposalNo !== undefined) {
      console.log(
        'requested proposal no is not nil. its ' +
          this.props.requestedProposalNo
      )
      this.props.actions.updateProposalNo(this.props.requestedProposalNo)
      this.props.actions.findProposal()
      //   this.props.actions.fetchServiceHistoryForPlant()
    }
  }

  renderFoundProposalStatus(proposal) {
    if (proposal === null || proposal === undefined) {
      return null
    }

    //note the api has the logic to tell us if its acceptable. the ui might want to distinguish between teh sub unacceptable statuses
    //i.e. we  might want to tell the user why its unacceptable (everythings accpeted or therees 0 ps in teh proposal).
    if (proposal.isAcceptable) {
      return (
        <h4 className="text-success">
          Found proposal #
          {' '}
          {proposal.proposalNo}
          . Please select the Proposal Service(s) the
          Customer has accepted and click "Accept".
        </h4>
      )
    } else {
      return (
        <h4>
          Proposal
          {' '}
          {proposal.proposalNo}
          {' '}
          has already been Accepted (or has 0 Proposal Services in it).
          {' '}
          {' '}
          <Link to={`/proposals/${proposal.id}`}>View Proposal</Link>
        </h4>
      )
    }
  }

  handleSubmitAcceptButton(e) {
    e.preventDefault() //in case they press enter to accept ones they checked
    //call an action to send the proposal id, and psto accept array over to the api todo the write.
    this.props.actions.acceptProposalServices()
  }

  handlePsAcceptCheckbox(e) {
    //if the checkbox is checked then call the action to add it
    //if unchecked then call the action to remove it
    console.log(
      'checkbox ' + e.target.value + ' was checked ' + e.target.checked
    )
    //TODO: make sure this ps is in a status that can be accepted
    if (e.target.checked) {
      this.props.actions.addPsToAccept(e.target.value) //will cause rerender
    } else {
      this.props.actions.removePsToAccept(e.target.value) //will cause rerender
    }
  }

  willIBeAccepted(psId) {
    console.info("checking if i'll be accepted ", psId)
    return this.props.psToAccept.includes(psId)
  }

  renderProposalServices(proposalServices) {
    if (proposalServices.length < 1) {
      return null
    }
    return proposalServices.map(ps => {
      return (
        <tr key={ps.id}>
          <td>

            <Checkbox
              inline
              value={ps.id}
              checked={this.willIBeAccepted(ps.id.toString())}
              onChange={e => this.handlePsAcceptCheckbox(e)}
            />

          </td>
          <td>#{ps.serviceNo}</td>
          <td>
            <u>{ps.serviceName}</u><br />
            {ps.notes}
          </td>
          <td>
            {ps.plants.map(plant => {
              return (
                <span key={plant.assetNumber}>
                  #{plant.assetNumber}
                  {plant.plantCount > 1
                    ? ` (${plant.plantCount} plants)`
                    : null}
                  {' '}
                </span>
              )
            })}
          </td>
          <td>{ps.priority}</td>
          <td>
            {ps.dates.map(
              dateRange =>
                `${moment(dateRange.from).format('MMM D')} - ${moment(dateRange.to).format('MMM D')}`
            )}
          </td>
          <td className="text-right">
            <Dollars amount={ps.serviceCost} />
          </td>
        </tr>
      )
    })
  }

  handleAllCheckbox(e) {
    this.setState({ allChecked: e.target.checked })
    if (e.target.checked) {
      this.props.actions.selectAllPsToAccept() //will cause rerender
    } else {
      this.props.actions.selectNonePsToAccept() //will cause rerender
    }
  }

  renderProposalServiceTable(proposalServices) {
    if (proposalServices.length < 1) {
      return null
    }
    return (
      <form onSubmit={e => this.handleSubmitAcceptButton(e)}>
        <Table striped hover>
          <thead>
            <tr>
              <th>
                <FormGroup>
                  <Checkbox
                    inline
                    checked={this.state.allChecked}
                    onChange={e => this.handleAllCheckbox(e)}
                  >
                    All{' '}
                  </Checkbox>
                </FormGroup>
              </th>
              <th>#</th>
              <th>Service</th>
              <th>Trees</th>
              <th>Priority</th>
              <th>Timing</th>
              <th className="text-right">Price</th>
            </tr>
          </thead>
          <tbody>
            {this.renderProposalServices(proposalServices)}
          </tbody>
        </Table>
      </form>
    )
  }

  renderAcceptanceUi(proposal) {
    if (proposal === null || proposal === undefined) {
      return null
    }

    if (proposal.isAcceptable === false) {
      console.log(
        'the proposal is not in acceptable status so not showing the ui'
      )
      //another ui element has told the user that theres no need for the acceptance ui
      return ''
    }

    return (
      <Panel bsStyle="default">
        <Row>
          <Col xs={6}>
            <h4 className="text-success">Proposal No: {proposal.proposalNo}</h4>
            <h5 className="text-info">{proposal.customerName}</h5>
            <h6 className="text-info">
              Proposed: <ThDate dateString={proposal.createdAt} />
            </h6>
            <p className="text-info">
              <small>Accepted Date:</small>
              <Datetime
                  dateFormat={defaultDateFormat}
                  isClearable
                  timeFormat={null}
                  value={moment(this.props.proposalAcceptedDate)}
                  className="text-center"
                  inputProps={{placeholder: 'Accepted Date'}}
                  onChange={m =>
                      this.props.actions.updateProposalAcceptedDate(
                          moment(m).format('L')
                      )}/>
            </p>
          </Col>

          <Col xs={6}>
            <h5 className="text-warning">
              Arborist: {proposal.salesArboristName}
            </h5>
            <h5 className="text-info">Site: {proposal.siteName}</h5>
            <h5 className="text-info">
              Site Address: {proposal.siteFullAddress}
            </h5>
            <h5 className="text-success">
              Total Proposed: <Dollars amount={proposal.proposalTotal} />
            </h5>
          </Col>

        </Row>
        {proposal.proposalServices
          ? this.renderProposalServiceTable(proposal.proposalServices)
          : null}
        <Row>
          <Col lg={12} className="clearfix">
            <div className="pull-left text-warning">
              <h5>
                Total Accepted: <Dollars amount={this.props.totalAccepted} />
              </h5>
            </div>
            <div className="pull-right text-success">
              <h5>
                Total Proposed: <Dollars amount={proposal.proposalTotal} />
              </h5>
            </div>
          </Col>
        </Row>
        <p>&nbsp;</p>
        <Row>
          <Col lg={12} className="text-right">
            <Button
              type="submit"
              onClick={e => this.handleSubmitAcceptButton(e)}
              bsStyle="success"
            >
              Accept
            </Button>
          </Col>
        </Row>
      </Panel>
    )
  }

  render() {
    const { proposalNo, foundProposal, actions } = this.props
    return (
      <Grid fluid>
        <Helmet title="Accept Proposal" />
        <Row>
          <Col xs={9}>
            <Row>
              <Col xs={12}>
                <PageHeader
                  pageName="Accept Proposals"
                  pageDetail="Customer wants work performed"
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <SearchByProposalNo
                  proposalNo={proposalNo}
                  updateProposalNo={actions.updateProposalNo}
                  onSearch={actions.findProposal}
                />
              </Col>
            </Row>

            <p>&nbsp;</p>

            <Row>
              <Col xs={12}>
                {this.renderFoundProposalStatus(foundProposal)}
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                {this.renderAcceptanceUi(foundProposal)}
              </Col>
            </Row>
          </Col>

          <Col xs={3}>
            {foundProposal && foundProposal.isAcceptable
              ? <Stickies proposalId={foundProposal.id} />
              : ''}
          </Col>
        </Row>
      </Grid>
    )
  }
}

function reselectTotalValue(proposal, psIdToAccept) {
  try {
    const theTotal = psIdToAccept.reduce((total, psId) => {
      const ps = proposal.proposalServices.find(
        ps => ps.id === parseInt(psId, 10)
      ) //this should always return a ps because we only  add psids that exist
      return ps ? total + ps.serviceCost : total
    }, 0)

    return theTotal
  } catch (e) {
    console.log(
      "Couldn't calculate total value of checked services because:",
      e
    )
    return 0
  }
}

//url parameters come via ownprops.
const mapStateToProps = (state, ownProps) => {
  return {
    proposalNo: state.acceptProposals.proposalNo,
    foundProposal: state.acceptProposals.foundProposal,
    requestedProposalNo: ownProps.params.proposalNo,
    psToAccept: state.acceptProposals.psToAccept,
    totalAccepted: reselectTotalValue(
      state.acceptProposals.foundProposal,
      state.acceptProposals.psToAccept
    ),
    proposalAcceptedDate: state.acceptProposals.proposalAcceptedDate,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AcceptProposals)
