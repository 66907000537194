import {
  UPDATE_PROPOSAL_NO_SEARCH_TEXT,
  UPDATE_PROPOSAL_DATE,
  STATUS_FETCH_PROPOSAL_INFO,
  STATUS_FETCH_PS_INFO,
  FETCH_PROPOSAL_SERVICE_STATUSES,
  SELECT_DESTINATION_STATUS_CODE,
  STATUS_SELECT_PROPOSAL_SERVICE,
  STATUS_UN_SELECT_PROPOSAL_SERVICE,
} from "./constants"

const initialState = {
  proposalNoSearchText: "",
  proposalInfo: {},
  proposalServicesInfo: [],
  proposalServiceStatuses: [],
  destinationStatusCode: "ACCEPTED",
  selectedPs: [],
  newProposalAcceptedDate: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROPOSAL_DATE:
      return {
        ...state,
        newProposalAcceptedDate: action.newProposalAcceptedDate,
      }
    case UPDATE_PROPOSAL_NO_SEARCH_TEXT:
      return { ...state, proposalNoSearchText: action.proposalNoSearchText }
    case STATUS_FETCH_PROPOSAL_INFO:
      return { ...state, proposalInfo: action.proposalInfo }
    case STATUS_FETCH_PS_INFO:
      return {
        ...state,
        proposalServicesInfo: action.proposalServicesInfo,
        selectedPs: action.selectedPs,
      }
    case FETCH_PROPOSAL_SERVICE_STATUSES:
      return {
        ...state,
        proposalServiceStatuses: action.proposalServiceStatuses,
      }
    case SELECT_DESTINATION_STATUS_CODE:
      return {
        ...state,
        destinationStatusCode: action.destinationStatusCode,
        selectedPs: [],
      }
    case STATUS_SELECT_PROPOSAL_SERVICE:
      return {
        ...state,
        selectedPs: [...new Set(state.selectedPs.concat(action.psId))],
      }
    case STATUS_UN_SELECT_PROPOSAL_SERVICE:
      return {
        ...state,
        selectedPs: state.selectedPs.filter(ps => ps !== action.psId),
      }
    default:
      return state
  }
}

export default reducer
