import React, {Profiler} from "react";
import {GoogleMap} from "react-google-maps";

const GoogleMapElement = React.forwardRef(({children, ...props}, ref) => {

    return <Profiler onRender={() => console.log("render GM")} id='google-map'><GoogleMap
        ref={ref}
        {...props}
    >
        {children}
    </GoogleMap>
    </Profiler>
})


const arePropsEqual = (prevProps, nextProps) => {
    if(prevProps.children !== nextProps.children){
        return false
    }
    return true
}
export default React.memo(GoogleMapElement, arePropsEqual);