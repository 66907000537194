import React from "react";

const EventWindowContent = ({site}) => {
    const types = [];
    site.map(site => {
        if (site.service_type && !types.includes(site.service_type)) {
            types.push(site.service_type);
        }
    });
    const formattedTypes = types.map(type => {
        let typeObject = {};
        typeObject.fullName = type;
        if (type !== 'PHC') {
            typeObject.shortName = type.split(' ').map(s => s.charAt(0)).join('');
        } else {
            typeObject.shortName = type;
        }
        return typeObject;
    });
    formattedTypes.map(type => {
        let hours = 0;
        let total = 0;
        site.filter((thing, index, self) => self.findIndex(t => t.service_no === thing.service_no) === index).map(item => {
            hours += item.service_type === type.fullName ? +item.man_hours : 0;
            total += item.service_type === type.fullName ? +item.service_total : 0;
            type.hours = hours;
            type.total = total;
        })
    });
    const eventData = site[0] && site[0].event_data && JSON.parse(site[0].event_data)
    return <div className="info-window">
        <h6 className='text-blue no-margin'>{site[0].customer_full_name}
            <span className={`text-green`}>
                                                                                            {
                                                                                                formattedTypes.map(type => {
                                                                                                    return `  ${type.shortName}: ${(type.hours).toFixed(2)} hr  $${type.total.toFixed(2)}`
                                                                                                })
                                                                                            }
                                                                                        </span>
        </h6>
        <span><strong>{site[0].full_address}</strong></span>
        {eventData &&
            <p>{eventData?.name}</p>}
    </div>
}

export default EventWindowContent;