import React from "react";
import {Button, Col, ControlLabel, FormControl, Glyphicon, Label, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ColorCheckbox from "../../../Scheduler/ColorCheckbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DefaultAnswerFormatter from "../DefaultAnswerFormatter";
import {select} from "../../../../common/commonHandlers";
import Select from "react-select";

const SelectAnswer = ({number, question, value, onChange, options, setAnswerOptions, preview, disabled}) => {
    const selectOptions = () => {
        return <Row>
            <Col xs={12}>
                <Label bsStyle="info">Placeholder</Label>
                <FormControl
                    type="text"
                    placeholder="Placeholder"
                    onChange={e => setAnswerOptions({
                        ...options,
                        placeholder: e.target.value
                    })}
                    value={options.placeholder}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.isMulti}
                               label="Enable Multiple Selection"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   isMulti: !options.isMulti,
                               })}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.required}
                               label="Answer will be required"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   required: !options.required
                               })}
                />
            </Col>
            {options.selectOptions.map(option => {
                const label = `Option ${option.id}`
                return <Col xs={12}>
                    <Row className="mt10">
                        <Col xs={12}><Label bsStyle="info">{label}</Label></Col>
                        <Col xs={12} className="flex-center">
                            <FormControl
                                type="text"
                                placeholder={label}
                                onChange={(e) => {
                                    const newOptions = options.selectOptions.map(o => {
                                        if (o.id === option.id) {
                                            o.value = e.target?.value?.toLowerCase()
                                            o.label = e.target.value
                                            return o
                                        } else {
                                            return o
                                        }
                                    })
                                    setAnswerOptions({
                                        ...options,
                                        selectOptions: newOptions,
                                    })
                                }}
                                value={option.label}
                            />
                            <FontAwesomeIcon
                                icon="trash-alt"
                                className="trash-icon"
                                onClick={() => {
                                    let newOptions = options.selectOptions.filter(o => o.id !== option.id)
                                    newOptions = newOptions.map((o, i) => {
                                        o.id = i + 1
                                        return o
                                    })
                                    setAnswerOptions({
                                        ...options,
                                        selectOptions: newOptions,
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            })}
            <Col xs={12}>
                <Button
                    className="pointer mt-15 mb-15 full-width"
                    bsSize="small"
                    bsStyle="success"
                    onClick={() => {
                        if (options.selectOptions?.every(q => q.label && q.label.length > 0)) {
                            setAnswerOptions({
                                ...options,
                                selectOptions: options.selectOptions.concat({
                                    id: options.selectOptions.length + 1,
                                    value: '',
                                    label: ''
                                }),
                            })
                        }
                    }}
                >
                    Add next option {" "}<FontAwesomeIcon icon="plus" className="small-margin"/>
                </Button>
            </Col>
        </Row>
    }

    const selectAnswers = () => {
        return <div id="select-answer-type">
            <ControlLabel className="bold">
                <span>{number}. {question}</span>
                {options.required && <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip id={`tooltip-refresh`}>Answer is required</Tooltip>}
                >
                    <Glyphicon glyph='exclamation-sign' className="ml-6 font14"/>
                </OverlayTrigger>}
            </ControlLabel>
            <Select className="Select mb-15" classNamePrefix="select"
                    value={select(options.selectOptions, value)}
                    isDisabled={disabled}
                    options={options.selectOptions}
                    isMulti={options.isMulti}
                    isClearable
                    onChange={e => onChange(options.isMulti ? e.map(x => x.value) : [e?.value])}
                    placeholder={options.placeholder}
            />
        </div>
    }

    return preview ?
        <DefaultAnswerFormatter options={selectOptions()} preview={selectAnswers()}/>
        :
        selectAnswers()
}

export default SelectAnswer;