import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import {handleUpload} from "../../common/upload";
import {addAlert} from "../App/actions";

export const load = (siteId, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/site_hazards?site_id=${siteId}`},
        {url: `/api/asset_hazards?site_id=${siteId}`},
        {url: `/api/hazards`},
        {url: `/api/site_hazards/get_description?site_id=${siteId}`}
    ];

    apiCall("Loading job site analysis", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            siteHazards: all[0].data,
            assetHazards: all[1].data,
            hazards: mapForSelect(all[2].data),
            description: all[3].data.hazard_description
        });
    }, dispatch, getState, false);
};


export const updateDescription = (siteId, description, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/site_hazards/update_description?site_id=${siteId}`,
        method: 'PUT',
        data: {site: {hazard_description: description}}
    };

    apiCall("Updating job side analysis description", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

//update_description

export const uploadAttachment = (siteId, acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload`, acceptedFiles, callback)(dispatch, getState);
};
export const uploadHazardAttachment = (id, acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload?asset_object_hazard_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const saveAssetHazard = (assetId, assetHazard) => (dispatch, getState) => {
    const config = {
        url: `/api/asset_hazards/${assetId}?id=${assetId}`,
        method: 'PUT',
        data: {asset_hazard: assetHazard}
    };

    apiCall("Saving asset hazard", config, async config => {
        const response = await axios.request(config);
    }, dispatch, getState, false);
};

export const removeImage = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: `/api/images/${id}`
    };
    apiCall("Asset image has been removed", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const rotateImage = (id, direction, callback) => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "POST",
        url: `/api/images/rotate?id=${id}`,
        headers: {Authorization: store.auth.token},
        data: {direction: direction}
    }

    return axios.request(config).then(response => {
        callback(response);
        dispatch(
            addAlert({
                message: response.data.message,
                mood: "success",
                dismissAfter: 1500,
            })
        )
    })
}

export const setCwoAndProposalVisibleHazard = (siteId, assetHazardId, image_id, visible, callback) => (dispatch, getState) => {
    const config = {
        method: "GET",
        url: `/api/new_sites/${siteId}/new_assets/set_cwo_and_proposal_display_hazard?asset_hazard_id=${assetHazardId}&image_id=${image_id}/&visible=${visible}`
    }

    apiCall("Visibility changed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const setCwoAndProposalVisibleSiteHazard = (siteId, siteHazardId, image_id, visible, callback) => (dispatch, getState) => {
    const config = {
        method: "GET",
        url: `/api/new_sites/${siteId}/new_assets/set_cwo_and_proposal_display_site_hazard?site_hazard_id=${siteHazardId}&image_id=${image_id}&visible=${visible}`
    }

    apiCall("Visibility changed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}