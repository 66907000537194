export const [

FETCH_ASSET_HISTORY,
FETCH_SERVICE_HISTORY,
FETCH_IMAGES_INFO,
  ADD_BLANK_HISTORY,
  EDIT_ASSET_HISTORY,
  UPDATE_HISTORY_ATTRIBUTE,
  REMOVE_BLANK_HISTORY,
  CLEAR_PLANT_DATA,
DELETE_ASSET,
] = [
'FETCH_ASSET_HISTORY',
'FETCH_SERVICE_HISTORY',
'FETCH_IMAGES_INFO',
'ADD_BLANK_HISTORY',
'EDIT_ASSET_HISTORY',
'UPDATE_HISTORY_ATTRIBUTE',
'REMOVE_BLANK_HISTORY',
'CLEAR_PLANT_DATA',
'DELETE_ASSET',
]
