import React, {Component} from "react"
import LeadForm from "./LeadForm";
import {browserHistory} from "react-router";
import LeadMenu from "./LeadMenu";

class Lead extends Component {

    render() {
        const id = this.props.params.id;
        return <LeadForm
            id={id}
            menuRenderer={(resource, saveLead) => {
                return <LeadMenu id={id} lead={resource} save={() => {
                    saveLead((result) => {
                        browserHistory.push(`/lead/${result.id}`);
                    })
                }}
                />
            }
            }
        />
    }
}

export default Lead
