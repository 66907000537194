import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {Glyphicon} from "react-bootstrap";
import './SiteNotes.scss'
import {defineMessages, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {locales} from "../AppWrapper/contents/constants";
import {bindActionCreators} from "redux";
import {updateSiteNotes} from "../CrewWorkOrdersPage/CrewWorkOrdersDetailsApi";

const messages = defineMessages({
    siteNotes: {
        id: 'SiteNotes.SiteNotes.Label',
        defaultMessage: 'Site notes'
    },
});

const actions = {
    updateSiteNotes,
}

const SiteNotes = ({resource, locale, intl, actions}) => {
    const [edition, setEdition] = useState(false)
    const [translatedNote, setTranslatedNote] = useState('')
    const [note, setNote] = useState('')
    const [noteValue, setNoteValue] = useState('');

    const showTranslation = locale === locales.ES
    const {formatMessage} = intl;
    useEffect(() => {
        if(!!resource){
            setNote(resource.site_notes);
            setTranslatedNote(resource.site_notes_translation);
            setNoteValue(showTranslation ? resource.site_notes_translation : resource.site_notes)
        }
    }, [resource])

    useEffect(() => {
        setNoteValue(locale === locales.ES ? translatedNote : note);
    }, [locale])

    const handleActivateEdition = () => {
        setEdition(true)
    }

    const handleSaveNote= () => {
        actions.updateSiteNotes(resource.site_id, noteValue, (data) => {
            setEdition(false)
            setNote(data.note)
            setTranslatedNote(data.translated_note)
        })
    }

    const handleCancelEdition = () => {
        setNoteValue(showTranslation ? translatedNote : note)
        setEdition(false)
    }

    return (
        <div className='site_notes_wrapper'>
            <div>{formatMessage(messages.siteNotes)}:
                {!edition && <FontAwesomeIcon className='ml-6 pointer' icon={faEdit} onClick={handleActivateEdition}/>}
            </div>
            <div className={`${edition && 'site_notes-edition'}`}>
                {edition ?
                    <textarea className='site_notes' value={noteValue}
                              onChange={(e) =>
                                  setNoteValue(e.target.value)}/>
                    :
                    <span>{noteValue}</span>}
                {edition &&
                    <div className='site_notes_buttons'>
                        <Glyphicon glyph="ok" className="pointer" onClick={handleSaveNote}/>
                        <Glyphicon glyph="remove" className="pointer" onClick={handleCancelEdition}/>
                    </div>}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    locale: state.userStatus.userStatus.locale
})

SiteNotes.propTypes = {
    note: PropTypes.string.isRequired,
    translatedNote: PropTypes.string,
    saveNote: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SiteNotes));