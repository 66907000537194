//import deepFreeze  from 'deep-freeze'

import {FETCH_OPEN_PROPOSALS,UPDATE_SERVICE_TYPE
} from './constants'

const initialState = {
    openProposals: [],
    serviceType: 'all'
}

export function arboristApprovals(state = initialState, action){
    switch(action.type){
        case FETCH_OPEN_PROPOSALS:
            return {...state, openProposals: action.openProposals}
        case UPDATE_SERVICE_TYPE:
            return {...state, serviceType: action.serviceType}
        default:
            return state
    }
}


