const initialState = {
    users: [], employees: [], client: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'users loaded':
            let users = action.data.map(u => ({
                value: u.id,
                label: u.current_user ? 'ME' : `${u.first_name} ${u.last_name}`,
            }));
            if (!users.find(u => u.value === 'Unknown')) {
                users.push({
                    value: 'Unknown',
                    label: 'Unknown'
                })
            }
            return {
                ...state, users: users.sort((a, b) => {
                    let result = a.label < b.label ? -1 : 1;
                    result = a.label === 'ME' ? -1 : b.label === 'ME' ? 1 : result;
                    return result;
                })
            };
        case 'employees loaded':
            return {
                ...state, employees: action.data.map(e => ({
                    value: e.id,
                    label: e.name,
                    email: e.email_address
                })).sort((a, b) => {
                    return a.label < b.label ? -1 : 1;
                })
            };
        case 'clients loaded by id':
            return {
                ...state, client: action.data
            };
        default:
            return state
    }
}