import React from "react";
import {Button, Modal} from "react-bootstrap";

const ConfirmDisassociateModal = ({onHide, onSave, type}) => {
    return <Modal show={true}
                  onHide={onHide}
                  className={"heightAuto fontIOS"}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Disassociate {type.charAt(0).toUpperCase() + type.slice(1)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Are you sure you want to disassociate {type}?</h4>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle='success'
                bsSize="small"
                onClick={onHide}
            >
                Cancel
            </Button>
            <Button
                bsStyle='warning'
                bsSize="small"
                onClick={() => {
                    onSave()
                    onHide()
                }}
            >
                Continue
            </Button>
        </Modal.Footer>
    </Modal>
}

export default ConfirmDisassociateModal;