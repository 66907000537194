import {START_RECORDING, START_RECORDING_CURRENT_TAB, STOP_RECORDING, SET_RECORDING_VIDEO, REMOVE_VIDEO, CLEAR_VIDEOS} from './constants'
import {dataUpload} from "../../../common/upload";
import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const startRecording = () => {
    return {
        type: START_RECORDING,
        current_tab: false
    }
}

export const startRecordingCurrentTab = () => {
    return {
        type: START_RECORDING_CURRENT_TAB,
        current_tab: true
    }
}

export const stopRecording = () => {
    return {
        type: STOP_RECORDING
    }
}

export const setRecordingVideo = video => {
    return {
        type: SET_RECORDING_VIDEO,
        video
    }
}

export const removeVideo = id => {
    return {
        type: REMOVE_VIDEO,
        id
    }
}

export const clearVideos = () => {
    return {
        type: CLEAR_VIDEOS
    }
}

export const uploadFiles = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/issue_files/upload`, acceptedFiles, callback)(dispatch, getState);
};

export const getIssueMedia = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/issue_files`,
        params: {id}
    };
    apiCall("Getting issue files", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const removeIssueMedia = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: `/api/issue_files/${id}`
    };
    apiCall("File has been removed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const downloadFile = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "GET",
        url: `/api/issue_files`,
        params: {id}
    };

    apiCall("Download file", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
