import React from "react";
import {Col, Glyphicon, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {equipmentAnswerTypes, equipmentDefaultAnswer} from "../../../common/commonHandlers";

const EquipmentQuestionsPreview = React.memo(({equipmentQuestions, onEdit, onDelete}) => {
    return equipmentQuestions?.map((q, i) => {
        const content = JSON.parse(q.content)
        return <Row className="d-flex vcenter bottom5">
            <Col xs={10}>
                <Row className="vertical-align">
                    <Col xs={12}>
                        {equipmentAnswerTypes(i + 1, content.answer_type, content.question, content.options, equipmentDefaultAnswer(content.answer_type))}
                    </Col>
                </Row>
            </Col>
            <Col xs={2} className="hcenter vcenter no-padding">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="equipment_plus_tooltip">Edit Question</Tooltip>}
                >
                    <Glyphicon
                        glyph='edit'
                        className="bigger pointer"
                        style={{color: 'green'}}
                        onClick={() => onEdit(q)}
                    />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="equipment_plus_tooltip">Delete Question</Tooltip>}
                >
                    <Glyphicon
                        glyph='remove-sign'
                        className="bigger pointer ml-6"
                        style={{color: 'red'}}
                        onClick={() => onDelete(q)}
                    />
                </OverlayTrigger>
            </Col>
        </Row>
    })
}, (prevProps, nextProps) => { return prevProps.equipmentQuestions === nextProps.equipmentQuestions})

export default EquipmentQuestionsPreview;