import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, FormControl, Grid, MenuItem, NavDropdown, Row} from "react-bootstrap";
import Select from "react-select";
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {LinkContainer} from 'react-router-bootstrap'
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import * as api from "./CustomerSearchApi";
import {defaultMapParams, select} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showEmailDialog} from "../../components/email/actions"
import Dollars from "../../components/Dollars";
import {getColorIcon} from "../MapViewPage/IconProvider";
import {debounce} from "throttle-debounce";
import {Link} from "react-router";
import SplitPane from "react-split-pane";
import './customerSearch.css';
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";

const actions = {...api, showEmailDialog};
const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

class CustomerSearch extends ResourceComponent {
    state = {
        filterVisible: true,
        customers: [],
        filtered_sites: [],
        resource: {
            customer_id: [],
            person_id: [],
            plant_id: [],
            customer_type_id: [],
            sort: 'DESC',
            sort_by: 'new_customer',
            page: 1,
            per_page: 25,
            inBounds: false,
            inactive: false
        },
        employees: [],
        customerTypes: [],
        plants: [],
        total: 0,
        center: true,
        CustomersFilterModalShow: false,
    };

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(1000, this.search);
        this.delayedInBounds = debounce(2000, this.inBounds)
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
    }

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.getCustomersSearch);
        this.props.actions.load(result => this.setState(result, this.delayedSearch));
    }

    search() {
        let {resource} = this.state;
        this.props.actions.getCustomers({...resource}, results => {
            this.setState({
                customers: results.content,
                filtered_sites: results.filtered_sites,
                total: results.total,
                loaded: resource.inBounds === true
            });
        });
    }

    customerFormatter(cell, row) {
        return <NavDropdown
            title={<div>
                <div>{row.name}</div>
                <div>{row.address_1}</div>
                <span><strong>{row.sites.length > 0 && `Sites: ${row.sites.length}`}</strong></span></div>}
            noCaret
        >
            <LinkContainer to={`/customer/info/${row.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Info</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/proposals/${row.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/sites/${row.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Sites</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_orders/${row.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/invoices/${row.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_history/${row.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    }

    formatValue = (title, value, style, percentage) => {
        return (value > 0 ?
                <div className={`text-${style}`}>{title}: {percentage ? `${value}%` : <Dollars amount={value}/>}</div> :
                <div>{title}: ---</div>
        );
    };

    dueFormatter = (cell, row) => {
        return <div>
            {this.formatValue('Close rate', row.close_rate, 'primary', true)}
            {this.formatValue('In progress', row.active_jobs, 'success')}
            {this.formatValue('Balance due', row.balance_due, 'danger')}
            {this.formatValue('Lifetime spend', row.lifetime_spend, 'primary')}
        </div>;
    };

    optionsFormatter = (cell) => {
        return <Link to={`/lead?customer_id=${cell}`}>Find a time</Link>;
    };

    statusFormatter = (cell, row) => {
        if (row.status === "existing") {
            return <span style={{color: 'green'}}>Existing</span>
        } else {
            return <span style={{color: 'red'}}>New</span>
        }
    };

    rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{this.customerFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Active proposals</Col>
                <Col xs={6}>{row.active_proposals_count}</Col>
            </Row>
            <Row>
                <Col xs={6}>Appointments</Col>
                <Col xs={6}>{row.scheduler_events}</Col>
            </Row>
            <Row>
                <Col xs={6}>Due</Col>
                <Col xs={6}>{this.dueFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Assigned arborist</Col>
                <Col xs={6}>{row.assigned_arborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Status</Col>
                <Col xs={6}>{this.statusFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Options</Col>
                <Col xs={6}>{this.optionsFormatter(cell, row)}</Col>
            </Row>
        </div>
    };

    searchFilter = (e, callback) => {
        this.state.resource[e.target.name] = e.target.value;
        this.state.resource.page = 1

        this.setState({resource: this.state.resource}, callback);
    }

    selectFilter = (name, callback) => e => {

        if (e && e.constructor.name === 'Array') {
            this.state.resource[name] = e.map(x => x.value);
        } else {
            this.state.resource[name] = e
        }
        this.state.resource.page = 1
        this.setState({resource: this.state.resource}, callback && callback);
    };

    inBounds = () => {
        let {resource} = this.state;
        if (resource.inBounds && this.map) {
            resource.min_lng = this.map.getBounds().getNorthEast().lng();
            resource.max_lng = this.map.getBounds().getSouthWest().lng();
            resource.min_lat = this.map.getBounds().getNorthEast().lat();
            resource.max_lat = this.map.getBounds().getSouthWest().lat();
            this.setState({resource}, this.delayedSearch)
        }
    };


    rowStyleFormat = (cell, row, trHeight) => {
        const {customers} = this.state;
        return {
            height: customers && customers.length === 1 ? `${trHeight}` : null,
            background: customers && customers.map((customer, i) => customer.inactive === true ? i : -1).filter(index => index !== -1).includes(row) ? '#ffebee' : null
        }
    };

    render() {
        const {
            filterVisible, customers, employees, resource,
            total, customerTypes, plants, center, CustomersFilterModalShow, filtered_sites
        } = this.state;
        const {client} = this.props;
        const {page, per_page, sort, sort_by} = resource;
        const customerOptions = this.customerSearch.searchOptions();
        const mapParams = [];
        customers.map(customer => {
            customer.sites.map(site => {
                let param = {};
                param.longitude = site.longitude;
                param.latitude = site.latitude;
                mapParams.push(param);
            });
        });
        //DEPRECATED
        if (center) {
            this.setState({center: false});
        }

        let trHeight;
        if (window.screen.width >= 1250) {
            trHeight = '390px';
        }
        if (window.screen.width < 1250) {
            trHeight = '410px';
        }

        const siteParams = [];
        customers.map(customer => {
            customer.sites.map(site => {
                let site_param = {};
                site_param.id = site.id;
                site_param.name = site.name;
                site_param.address = site.address;
                site_param.longitude = site.longitude;
                site_param.latitude = site.latitude;
                site_param.person_id = customer.person_id;
                site_param.customer = customer;
                siteParams.push(site_param);
            });
        });

        const sortedSites = siteParams && siteParams.sort((a, b) => b.latitude - a.latitude);

        return (
            <Grid fluid id='customerSearch'>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter full-width">
                        <h2 className="no-top-margin mr-9">Customers</h2>
                        <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{total} returned</h5>
                    </Col>

                    <Col md={8} className={smMobile ? "mt-20 full-width" : "text-right full-width"}>
                        <Link to="/customer/info">
                            <Button bsStyle="primary"
                                    className={smMobile ? "no-top-margin small-margin ml0" : "no-top-margin small-margin"}>New
                                Customer</Button>
                        </Link>
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => this.setState({
                                CustomersFilterModalShow: !this.state.CustomersFilterModalShow,
                                oldResource: {...resource}
                            })}>
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <hr className={'mb-10-mt-0'}/>
                <LeadsFilterModal
                    title="Customers Filter"
                    show={CustomersFilterModalShow}
                    onHide={() => {
                        this.setState({
                            CustomersFilterModalShow: !this.state.CustomersFilterModalShow,
                        }, this.search)
                    }}
                    closeButton={() => this.setState({
                        CustomersFilterModalShow: !this.state.CustomersFilterModalShow,
                        resource: this.state.oldResource
                    })}
                >
                    <Row className={'modalLeadsFilter'}>
                        <Col md={12}>
                            <Select className="Select bottom15 top15"
                                    classNamePrefix="select"
                                    value={select(customerOptions, resource.customer_id)}
                                    options={customerOptions}
                                    placeholder="Customer"
                                    isMulti
                                    isClearable
                                    onInputChange={this.customerSearch?.search}
                                    onChange={this.selectFilter('customer_id')}
                            />
                        </Col>
                        <Col md={12}>
                            <Select className="Select bottom15" classNamePrefix="select"
                                    value={select(employees, resource.person_id)}
                                    options={employees}
                                    isClearable
                                    onChange={this.selectFilter('person_id')}
                                    isMulti
                                    placeholder="Arborist"
                            />
                        </Col>
                        <Col md={12}>
                            <Select className="Select bottom15"
                                    classNamePrefix="select"
                                    value={select(customerTypes, resource.customer_type_id)}
                                    options={customerTypes}
                                    placeholder="Customer Type"
                                    isClearable
                                    isMulti
                                    onChange={this.selectFilter('customer_type_id')}
                            />
                        </Col>
                        <Col md={12}>
                            <Select className="Select bottom15"
                                    classNamePrefix="select"
                                    value={select(plants, resource.plant_id)}
                                    options={plants}
                                    placeholder="Plants"
                                    isClearable
                                    isMulti
                                    onChange={this.selectFilter('plant_id')}
                            />
                        </Col>
                        <Col md={6}>
                            <ColorCheckbox value={resource.inBounds}
                                           className="marginBottom8"
                                           label='Show customers in bounds'
                                           onChange={this.selectFilter('inBounds', () => {
                                               if (resource.inBounds === false) {
                                                   let {resource} = this.state;
                                                   this.setState({
                                                       resource: {
                                                           ...resource,
                                                           min_lng: null,
                                                           max_lng: null,
                                                           min_lat: null,
                                                           max_lat: null
                                                       }
                                                   })
                                               }
                                           })}
                            />
                        </Col>
                        <Col md={6}>
                            <ColorCheckbox value={resource.inactive}
                                           className="marginBottom8"
                                           label='Show only inactive customers'
                                           onChange={this.selectFilter('inactive')}
                            />
                        </Col>
                    </Row>
                </LeadsFilterModal>
                {isMobile ?
                    <div>
                        <Row className="nomarginLR justify-flex-start pb-10">
                            <Col xs={4} md={6} className="no-left-padding">
                                <Button bsStyle="success"
                                        onClick={() => this.props.actions.downloadCsv(resource)}>Export to CSV</Button>
                            </Col>
                            <Col xs={8} md={6} className="no-right-padding pl-20">
                                <FormControl
                                    name="q"
                                    placeholder="Search..."
                                    value={resource.q}
                                    onChange={e => this.searchFilter(e, this.delayedSearch)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="vertical-align ml-15 bottom10 mt10">
                                <div className="inactive_customers_legend"></div>
                                <span className="marginLeft5">- Inactive Leads</span>
                            </Col>
                        </Row>
                        <Col className="remaining vertical-responsive-height">
                            <BootstrapTable
                                ref="table"
                                data={customers}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={true}
                                remote
                                fetchInfo={{dataTotalSize: total}}
                                trStyle={(cell, row) => this.rowStyleFormat(cell, row, trHeight)}
                                className="wrapped"
                                trClassName={this.selectedMarkerClass}
                                options={
                                    {
                                        onPageChange: (page, per_page) => {
                                            let {resource} = this.state;
                                            resource = {...resource, page, per_page};
                                            this.setState({resource}, this.delayedSearch);
                                        },
                                        page,
                                        sizePerPageList: [{
                                            text: '10', value: 10
                                        }, {
                                            text: '25', value: 25
                                        },
                                            {
                                                text: '30', value: 30
                                            },
                                            {
                                                text: '50', value: 50
                                            },
                                            {
                                                text: '100', value: 100
                                            },
                                            {
                                                text: '200', value: 200
                                            }
                                            ],
                                        sizePerPage: per_page,
                                        onRowClick: row => {
                                            this.selectMarker('customers')(row);
                                        },
                                        sortName: sort_by,
                                        sortOrder: sort,
                                        onSortChange: (sort_by, sort) => {
                                            let {resource} = this.state;
                                            resource = {...resource, sort_by, sort};
                                            this.setState({resource}, this.delayedSearch);
                                        }
                                    }
                                }
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataFormat={this.rowFormatter}
                                >
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        <Row className="no-margin">
                            <GoogleMapLoader
                                containerElement={
                                    <div
                                        id="customersMap"
                                        className="remaining vertical-responsive-height bottom15"
                                    />
                                }
                                googleMapElement={
                                    <GoogleMap
                                        ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude), () => this.map = it)}
                                        onIdle={() => this.delayedInBounds()}
                                        {...defaultMapParams(mapParams, center, null, client)}
                                    >
                                        {sortedSites && sortedSites.map((x, index) => {
                                            const employee = select(employees, x.customer.person_id);
                                            const employeeColor = (employee ? employee.color : '#3a87ad');
                                            return x.latitude && (
                                                <Marker
                                                    key={x.id}
                                                    position={{lat: x.latitude, lng: x.longitude}}
                                                    icon={getColorIcon((x.customer.selected ? '#f8e500' : employeeColor), !filtered_sites.includes(x.id))}
                                                    title={x.name}
                                                    onClick={() => {
                                                        const page = this.refs.table.getPageByRowKey(x.customer.id);
                                                        this.setState({page});
                                                        this.selectMarker('customers')(x.customer);
                                                    }}
                                                    zIndex={x.customer.selected ? 1000 : index + 1}
                                                />
                                            )
                                        })}
                                    </GoogleMap>
                                }
                            />
                        </Row>
                    </div>
                    :
                    <div>
                        <SplitPane split="vertical" defaultSize={"50%"} minSize={500}>
                            <div>
                                <Row className="tableHeader">
                                    <Col md={2} className="pl-20">
                                        <Button bsStyle="success"
                                                onClick={() => this.props.actions.downloadCsv({
                                                    ...resource,
                                                    selected: this.state.allSelected ? 'all' : this.state.selectedLeads
                                                })}>Export to CSV</Button>
                                    </Col>
                                    <Col md={5} className="vertical-align marginLeft10">
                                        <div className="inactive_customers_legend"></div>
                                        <span className="marginLeft5">- Inactive customers </span>
                                    </Col>
                                    <Col md={5}>
                                        <FormControl
                                            name="q"
                                            placeholder="Search..."
                                            value={resource.q}
                                            onChange={e => this.searchFilter(e, this.delayedSearch)}
                                        />
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    ref="table"
                                    data={customers}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    pagination={true}
                                    remote
                                    fetchInfo={{dataTotalSize: total}}
                                    className="wrapped"
                                    trStyle={(cell, row) => this.rowStyleFormat(cell, row, trHeight)}
                                    trClassName={this.selectedMarkerClass}
                                    options={
                                        {
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({resource}, this.delayedSearch);
                                            },
                                            page,
                                            sizePerPageList: [{
                                                text: '10', value: 10
                                            }, {
                                                text: '25', value: 25
                                            },
                                                {
                                                    text: '30', value: 30
                                                },
                                                {
                                                    text: '50', value: 50
                                                },
                                                {
                                                    text: '100', value: 100
                                                },
                                                {
                                                    text: '200', value: 200
                                                }],
                                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                                            sizePerPage: per_page,
                                            onRowClick: row => {
                                                this.selectMarker('customers')(row);
                                            },
                                            sortName: sort_by,
                                            sortOrder: sort,
                                            onSortChange: (sort_by, sort) => {
                                                let {resource} = this.state;
                                                resource = {...resource, sort_by, sort};
                                                this.setState({resource}, this.delayedSearch);
                                            }
                                        }
                                    }
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden
                                        width={"5%"}
                                        isKey
                                    />
                                    <TableHeaderColumn
                                        dataField="name"
                                        dataFormat={this.customerFormatter}
                                        dataSort
                                        width={"10%"}
                                    >
                                        Customer
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="active_proposals_count"
                                        width={"10%"}
                                    >
                                        Active proposals count
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="scheduler_events"
                                        width={"10%"}
                                    >
                                        Appointments
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="balance_due"
                                        dataFormat={this.dueFormatter}
                                        width={"10%"}
                                    >
                                        Due
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="assigned_arborist"
                                        dataSort
                                        width={"12%"}
                                    >
                                        Assigned arborist
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="status"
                                        dataFormat={this.statusFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        Status
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataFormat={this.optionsFormatter}
                                        width={"8%"}
                                    >
                                        Options
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                            <div>
                                <GoogleMapLoader
                                    containerElement={
                                        <div
                                            id="customersMap"
                                            className="remaining vertical-responsive-height"
                                        />
                                    }
                                    googleMapElement={
                                        <GoogleMap
                                            ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude), (res) => {
                                                this.map = it;
                                            })}
                                            onIdle={() => {
                                                this.delayedInBounds()
                                            }}
                                            {...defaultMapParams(mapParams, center, null, client)}
                                        >
                                            {sortedSites && sortedSites.map((x, index) => {
                                                const employee = select(employees, x.customer.person_id);
                                                const employeeColor = (employee ? employee.color : '#3a87ad');
                                                return x.latitude && (
                                                    <Marker
                                                        key={x.id}
                                                        position={{lat: x.latitude, lng: x.longitude}}
                                                        icon={getColorIcon((x.customer.selected ? '#f8e500' : employeeColor), !filtered_sites.includes(x.id))}
                                                        title={x.name}
                                                        onClick={() => {
                                                            const page = this.refs.table.getPageByRowKey(x.customer.id);
                                                            this.setState({page});
                                                            this.selectMarker('customers')(x.customer);
                                                        }}
                                                        zIndex={x.customer.selected ? 1000 : index + 1}
                                                    />
                                                )
                                            })}
                                        </GoogleMap>
                                    }
                                />
                            </div>
                        </SplitPane>
                    </div>
                }
            </Grid>
        );
    }
}

CustomerSearch.propTypes = {
    //myProp: PropTypes.object.isRequired
};

CustomerSearch.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        client: state.client.customerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch);
