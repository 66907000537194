import {
  EDITING_EMPLOYEE_ID,
  EDIT_EMPLOYEE_FILTER_TEXT,
} from "./constants";

const initialState = {
  editingEmployeeId: null,
  filterText: ""
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EDITING_EMPLOYEE_ID:
      return { ...state, editingEmployeeId: action.editingEmployeeId };
    case EDIT_EMPLOYEE_FILTER_TEXT:
      return { ...state, filterText: action.filterText };
    default:
      return state;
  }
}
