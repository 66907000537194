import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {colors, merge, select, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {CirclePicker} from "react-color";
import './Plants.scss'
import Select from "react-select";
import * as actions from "./PlantsApi";

const plants = createApiService('plants', 'plant', 'Plant');
const isMobile = window.screen.width <= 1024;
const api = {
    ...plants,
    ...actions
};

class Plants extends ResourceComponent {
    defaultValues = {
        name: '',
        family: '',
        species: '',
        genus: '',
        variety: '',
        ave_height: '',
        ave_width: '',
        ave_dbh: '',
        order_name: '',
        description: '',
        color: '',
        allow_marker: true,
        allow_circle: false,
        allow_polygon: false,
        allow_polyline: false,
        allow_rectangle: false,
        disabled: false,
    };
    state = {
        allowEditing: false
    };

    updateResources = resources => {
        this.setState({resources: resources.map(x => (this.mapResource(x)))})
    };

    mapResource(x) {
        return {...x, label: x.name};
    }

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
        this.props.actions.loadPlantSelects(res => {
            this.setState({factors: res.factors})
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;
        if ((prevState.resources !== resources) && resource) {
            const selected = resources.find(r => r.id === resource.id)
            this.setState(() => ({resource: {...resource, modified_at_date: selected.modified_at_date}}))
        }
    }

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource: this.mapResource(resource)});
        this.componentDidMount();
    };
    allowEditingChange = () => {
        this.setState({allowEditing: !this.state.allowEditing})
    }


    render() {
        const {roles} = this.props;
        const {resource, resources, showPicker, allowEditing} = this.state;

        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Plant"
                        resourceNamePlural="Plants"
                        resources={resources}
                        resource={resource}
                        buttonsBottom={true}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                this.props.actions.save(resource, this.reloadResources)
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource && <div>
                            <Row>
                                <Col md={11}>
                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Name
                                                </ControlLabel>
                                                <FormControl
                                                    name="name"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.name}
                                                />
                                                {this.state.errorMessage &&
                                                    <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Family
                                                </ControlLabel>
                                                <FormControl
                                                    name="family"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.family}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Species
                                                </ControlLabel>
                                                <FormControl
                                                    name="species"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.species}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Genus
                                                </ControlLabel>
                                                <FormControl
                                                    name="genus"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.genus}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Variety
                                                </ControlLabel>
                                                <FormControl
                                                    name="variety"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.variety}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>

                                            <FormGroup>
                                                <ControlLabel>
                                                    Order Name
                                                </ControlLabel>
                                                <FormControl
                                                    name="order_name"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.order_name}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Ave Height
                                                </ControlLabel>
                                                <FormControl
                                                    name="ave_height"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.ave_height}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Ave Width
                                                </ControlLabel>
                                                <FormControl
                                                    name="ave_width"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.ave_width}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Ave Dbh
                                                </ControlLabel>
                                                <FormControl
                                                    name="ave_dbh"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.ave_dbh}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Description
                                                </ControlLabel>
                                                <FormControl
                                                    name="description"
                                                    componentClass="textarea"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource.description}
                                                />
                                            </FormGroup>
                                            <ColorCheckbox value={resource.invasive_species}
                                                           label="Invasive species"
                                                           onChange={this.selectCheckboxAttr('invasive_species')}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}>
                                    <ControlLabel>
                                        Asset types
                                    </ControlLabel>
                                    <FormGroup>

                                        <ColorCheckbox value={resource.allow_marker}
                                                       label="Marker"
                                                       onChange={this.selectCheckboxAttr('allow_marker')}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.allow_polyline}
                                                       label="Polyline"
                                                       onChange={this.selectCheckboxAttr('allow_polyline')}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.allow_polygon}
                                                       label="Polygon"
                                                       onChange={this.selectCheckboxAttr('allow_polygon')}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.allow_rectangle}
                                                       label="Rectangle"
                                                       onChange={this.selectCheckboxAttr('allow_rectangle')}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.allow_circle}
                                                       label="Circle"
                                                       onChange={this.selectCheckboxAttr('allow_circle')}/>
                                    </FormGroup>
                                </Col>
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <h5>Plant Associations</h5>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={allowEditing}
                                                onChange={this.allowEditingChange}
                                            />
                                            Allow Editing
                                        </label>
                                        <hr className="grey"/>
                                        <Row className={'bottom15'}>
                                            <Col md={4} mdOffset={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.factors, resource.plant_factors)}
                                                        options={this.state.factors}
                                                        placeholder="Factors"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttrWithName('plant_factors')(e)}
                                                />
                                            </Col>

                                        </Row>
                                        <hr className="grey"/>
                                    </Col>
                                </Row>
                                <Col md={12} className={isMobile ? '' : 'custom-footer'}>
                                    {(roles && roles.includes('admin')) && <div>
                                        <ControlLabel>
                                            Color
                                        </ControlLabel>
                                        <div className={`c${resource.color} color-picker pointer visible bigger`}
                                             onClick={() => this.setState({showPicker: !showPicker})}/>
                                        {showPicker &&
                                            <CirclePicker className='expand-top' width={400} colors={colors}
                                                          onChange={e => {
                                                              this.setState({showPicker: false}, () => {
                                                                  this.selectCheckboxAttr('color')(colors.findIndex(c => c === e.hex));
                                                              });
                                                          }}/>}
                                    </div>}
                                    <div>
                                        <ColorCheckbox value={resource.disabled}
                                                       label="Disabled"
                                                       onChange={this.selectCheckboxAttr('disabled')}/>
                                    </div>
                                    <div>
                                        {`Last updated ${resource.modified_at_date ? moment(resource.modified_at_date).format("llll") :
                                            <i>Unknown</i>} ${resource.modified_by_name ? `by ${resource.modified_by_name}` : ''}`}

                                    </div>
                                    <div>
                                        {resource.id && `Plant ID: ${resource.id}`}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapStateToProps = (state) => ({
    roles: state.auth.roles,
    state
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plants)
