import React from 'react';
import {Col} from "react-bootstrap";
import {select} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import Select from "react-select";
import {debounce} from "throttle-debounce";

class UsersMapFilters extends ResourceComponent {
    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(1000, this.updateFilters);
        this.clientSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
    }

    updateFilters = () => {
        this.props.onChangeFilters(this.state.resource)
    }

    componentDidMount() {
        this.setState({resource: this.props.resource}, () => {
            this.clientSearch = this.buildSearchComponent('client_ids', this.props.onSearch);
            this.clientSearch.search()
        })
    }

    selectClientFilter = (e) => {
        let {resource} = this.props
        resource['client_ids'] = e.map(x => x.value);
        this.props.onChangeSelectedClients(e)
    };

    render() {
        const {resource} = this.props
        const clientOptions = this.clientSearch.searchOptions();

        return (<Col md={12} className="mt-15">
                <Select className="Select bottom15 top15"
                        classNamePrefix="select"
                        value={select([...this.props.selectedClients, ...clientOptions], resource.client_ids)}
                        options={clientOptions}
                        placeholder="Client"
                        isMulti
                        isClearable
                        onInputChange={this.clientSearch.search}
                        onChange={this.selectClientFilter}
                />
            </Col>
        );
    };
}

export default UsersMapFilters;