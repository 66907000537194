import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    Alert,
    Col,
    Grid,
    OverlayTrigger,
    Panel, Popover,
    Row,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import moment from "moment";
import * as api from "./QuickbooksApi";
import * as servicesActions from "./QuickbooksServices/QuickbooksServicesApi";
import * as customersActions from "./QuickbooksCustomers/QuickbooksCustomersApi";
import * as invoicesActions from "./QuickbooksInvoices/QuickbooksInvoicesApi";
import {defaultDateFormat, defaultTimeFormat, select} from "../../common/commonHandlers";
import Datetime from "react-datetime";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import QuickbooksCustomers from "./QuickbooksCustomers/QuickbooksCustomers";
import QuickbooksInvoices from "./QuickbooksInvoices/QuickbooksInvoices";
import QuickbooksServices from "./QuickbooksServices/QuickbooksServices";
import Select from "react-select";
import ResourceComponent from "../../components/ResourceComponent";
import './Quickbooks.css';
import {debounce} from "throttle-debounce";
import Button from "react-bootstrap/lib/Button";
import PromiseUi from "../../components/PromiseUi";
import {DateRangePicker} from "react-dates";
import LogsModal from "./LogsModal";

const actions = {...api, ...servicesActions, ...customersActions, ...invoicesActions}
const queryString = require('query-string');

class Quickbooks extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            filterVisible: true,
            resource: {
                from: moment().utc().subtract(7, "days"),
                to: moment(),
                customer_id: null,
                service_id: null,
                invoice_id: null,
                showSynchronized: false,
                qb_customer_id: null,
                customerFilterType: 'th',
                customerSearchFilterType: 'th',
                invoiceSearchFilterType: 'th',
                serviceFilterType: 'th',
                serviceSearchFilterType: 'th',
                byInvoice: true,
                showNotSyncCustomer: false
            },
            companyInfo: null,
            content: [],
            key: null,
            isLoading: true,
            isSearchLoading: false,
            showLogsModal: false
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.invoiceSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.qbInvoiceSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.serviceSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.qbServiceSearch = {
            searchOptions: () => [],
            search: () => {

            }
        };
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.qbCustomerSearch = {
            searchOptions: () => [],
            search: () => {

            }
        };
    }

    componentWillMount() {
        let {key} = this.state;
        if (window.location.href.indexOf("service_id") > -1) {
            key = 2
        } else if (window.location.href.indexOf("invoice_id") > -1) {
            key = 3
        } else {
            key = 1
        }
        this.setState({key: key})
    }

    setIsNotLoading = () => {
        this.setState({isLoading: false})
    }

    componentDidMount() {
        let {resource} = this.state;
        let param = window.location.href.split('=').pop()
        if (window.location.href.indexOf("customer_id") > -1 && param) {
            this.props.actions.getCustomer(param, (customer) => {
                resource.customer_id = param
                resource.customer_name = customer.name
                this.setState({
                    resource: resource
                }, () => this.customers.sync());
            })
        } else if (window.location.href.indexOf("service_id") > -1 && param) {
            this.setState({resource: {...resource, key: 2}}, () => {
                this.props.actions.getService(param, (service) => {
                    resource.service_id = param
                    resource.service_name = service.name
                    this.setState({
                        resource: resource
                    }, () => this.services.sync())
                });
            })

        } else if (window.location.href.indexOf("invoice_id") > -1 && param) {
            this.props.actions.getInvoice(param, (invoice) => {
                resource.invoice_number = invoice.number
                resource.invoice_id = param
                this.setState({
                    resource: resource
                }, () => this.invoices.sync())
            });
        }

        this.props.actions.getCompanyInfo(result => {
            this.setState({
                companyInfo: {
                    legal_name: result.legal_name,
                    quickbooks_realm_id: result.quickbooks_realm_id
                }
            })
        })
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.qbCustomerSearch = this.buildSearchComponent('qb_customer_id', this.props.actions.searchForQbCustomer);
        this.invoiceSearch = this.buildSearchComponent('invoice_number', this.props.actions.searchForInvoice);
        this.qbInvoiceSearch = this.buildSearchComponent('qb_invoice', this.props.actions.searchForQbInvoice);
        this.serviceSearch = this.buildSearchComponent('service_id', this.props.actions.searchForService);
        this.qbServiceSearch = this.buildSearchComponent('qb_service', this.props.actions.searchForQbService);
    }

    handleSelect(key) {
        const {resource} = this.state
        resource.showSynchronized = false

        this.setState({key, resource, isSearchLoading: false});
    }

    dateResourceAttr = (name) => e => {
        this.state.resource[name] = (e && e !== '') ? e : null;
        this.setState({resource: this.state.resource});
    };

    renderHelperPopover = () => {
        return (
        <Popover id={"qb-class-helper"}>
            <ul>
                <li>Open Quickbooks</li>
                <li>Go to <strong>Account and Settings/Advanced</strong></li>
                <li>switch <strong>Track classes</strong> on</li>
                <li>Click <strong>Save</strong></li>
            </ul>
        </Popover>)
    }

    refresh = () => {
        const {key} = this.state;
        this.setState({isLoading: true}, () => {
            if (key === 1) {
                this.customers.sync();
            } else if (key === 2) {
                this.services.sync();
            } else if (key === 3) {
                this.invoices.sync();
            }
        });
    }

    getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return parsed;
        } else {
            return null;
        }
    }

    updateDateData = async (startDate, endDate) => {
        let {resource, focusedInput} = this.state;

        if (focusedInput === 'startDate') {
            this.refreshDates()
            resource.from = moment(startDate).utc().startOf('day');
            this.setState(resource)
            return
        }
        if (focusedInput === 'endDate') {
            resource.to = moment(endDate).utc().endOf('day');
            this.setState(resource)
        }

        if (resource.from && resource.to) {
            this.setState({focusedInput: null}, () => {

            })

        }
    }


    refreshDates = () => {
        let {resource} = this.state;
        resource.from = null;
        resource.to = null;
        this.setState(resource)
    }

    renderSelectButtons = (filterType) => {
        const {resource, key} = this.state;
        const statuses = [
            {value: 'th', label: 'Treehub'},
            {value: 'qb', label: 'Quickbooks'}
        ];
        return <ToggleButtonGroup type="radio"
                                  name="options"
                                  value={resource[filterType]}
                                  onChange={(e) => {
                                      let {resource} = this.state;
                                      resource[filterType] = e;
                                      this.setState({
                                          resource: resource
                                      },() => {
                                          if (key === 1) {
                                              this.customers.sync();
                                          } else if (key === 2) {
                                              this.services.sync();
                                          }
                                      })

                                  }}
        >
            {statuses.map((s, i) =>
                <ToggleButton
                    key={i}
                    value={s.value}
                >
                    {s.label}
                </ToggleButton>)
            }
        </ToggleButtonGroup>
    }

    renderSearchButtons = (filterType) => {
        const {resource} = this.state;
        const statuses = [
            {value: 'th', label: 'Treehub'},
            {value: 'qb', label: 'Quickbooks'}
        ];
        return <ToggleButtonGroup type="radio"
                                  name="options"
                                  value={resource[filterType]}
                                  onChange={(e) => {
                                      let {resource} = this.state;
                                      resource[filterType] = e;
                                      this.setState({
                                          resource: resource
                                      })
                                  }}
        >
            {statuses.map((s, i) =>
                <ToggleButton
                    key={i}
                    value={s.value}
                >
                    {s.label}
                </ToggleButton>)
            }
        </ToggleButtonGroup>
    }

    render() {
        const {
            filterVisible, resource, content, key, companyInfo, isLoading, showLogsModal, isSearchLoading
        } = this.state;
        const customerOptions = this.customerSearch.searchOptions();
        const customerQbOptions = this.qbCustomerSearch.searchOptions();
        const invoiceOptions = this.invoiceSearch.searchOptions();
        const invoiceQbOptions = this.qbInvoiceSearch.searchOptions();
        const serviceOptions = this.serviceSearch.searchOptions();
        const serviceQbOptions = this.qbServiceSearch.searchOptions();

        const parsed = this.props.location && queryString.parse(this.props.location.search);
        if (window.location.href.indexOf("customer_id") > -1) {
            customerOptions.push({value: parsed['customer_id'], label: resource.customer_name})
        }
        if (window.location.href.indexOf("service_id") > -1) {
            serviceOptions.push({value: parsed['service_id'], label: resource.service_name})
        }
        if (window.location.href.indexOf("invoice_id") > -1) {
            invoiceOptions.push({value: resource.invoice_id, label: resource.invoice_number})
        }
        return (
            <Grid fluid id={'quickbooks'}>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter headerBorder">
                    <Col xs={6} className="vcenter">
                        <h2 className="no-top-margin small-margin">Synchronize with quickbooks</h2>
                    </Col>
                    {companyInfo && <Col xs={6} className='reset-right-padding'>
                        <Row>
                            <Col xs={5}></Col>
                            <Col xs={7}><Row className='text-small full-width reset-right-padding'>
                                <Col xs={6} className='hright'>
                                    <span>You are connected to:</span>
                                </Col>
                                <Col xs={6} className='hstart reset-right-padding'>
                                    <span className='text-orange'><strong>{companyInfo.legal_name}</strong></span>
                                </Col>
                            </Row>
                                <Row className='text-small full-width'>
                                    <Col xs={6} className='hright'>
                                        <span>Company Id:</span>
                                    </Col>
                                    <Col xs={6} className='hstart reset-right-padding'>
                                        <span><strong>{companyInfo.quickbooks_realm_id}</strong></span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>}
                </Row>
                <Row className="quickbooks-sticky-panel">
                    <Panel
                        collapsible
                        expanded={filterVisible}
                        id='quickbooks-panel'
                    >
                        <Row>
                            {this.state.key !== 2 &&
                            <div>
                                <Col md={2}>
                                    <DateRangePicker
                                        startDate={resource.from ? moment.utc(resource.from) : ""}
                                        startDateId={'P From'}
                                        endDate={resource.to ? moment.utc(resource.to) : ""}
                                        endDateId={'P To'}
                                        keepOpenOnDateSelect={true}
                                        disabled={isLoading}
                                        showClearDates={true}
                                        reopenPickerOnClearDate={true}
                                        onDatesChange={({startDate, endDate}) => this.updateDateData(startDate, endDate)}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => {
                                            this.setState({focusedInput})
                                        }}
                                        startDatePlaceholderText={'From'}
                                        endDatePlaceholderText={'To'}
                                        isOutsideRange={() => {
                                            return false
                                        }}
                                        displayFormat={defaultDateFormat}
                                    />
                                </Col>
                            </div>
                            }
                            {this.state.key === 1 &&
                            <Col style={{display: "flex", flexDirection: "column", justifyContent: "center"}} md={2}>
                                <ColorCheckbox value={resource.byInvoice}
                                               disabled={(this.state.key === 1 && ((resource.customer_id && resource.customer_id.length > 0) || (resource.qb_customer_id && resource.qb_customer_id.length > 0))) || isLoading}
                                               onChange={(e) => this.setState({isLoading: true}, () => {
                                                   this.selectCheckboxAttr('byInvoice')(e)
                                               })}
                                               checked={resource.byInvoice === true}
                                               label="Filter by invoice date"
                                />
                                <ColorCheckbox value={resource.showNotSyncCustomer}
                                               disabled={(this.state.key === 1 && ((resource.customer_id && resource.customer_id.length > 0) || (resource.qb_customer_id && resource.qb_customer_id.length > 0))) || isLoading}
                                               onChange={(e) => this.setState({isLoading: true}, () => {
                                                   this.selectCheckboxAttr('showNotSyncCustomer')(e)
                                               })}
                                               checked={resource.showNotSyncCustomer === true}
                                               label="Show not fully synced customers"
                                />
                            </Col>}
                            <Col style={{display: "flex", flexDirection: "column", justifyContent: "center"}} md={2}>
                                <ColorCheckbox value={resource.showSynchronized}
                                               disabled={((this.state.key === 1 && ((resource.customer_id && resource.customer_id.length > 0) || (resource.qb_customer_id && resource.qb_customer_id.length > 0))) ||
                                                   (this.state.key === 2 && ((resource.service_id && resource.service_id.length > 0) || (resource.qb_service && resource.qb_service.length > 0))) ||
                                                   (this.state.key === 3 && ((resource.invoice_id && resource.invoice_id.length > 0) || (resource.qb_invoice && resource.qb_invoice.length > 0)))) || isLoading}
                                               onChange={(e) => this.setState({isLoading: true}, () => {
                                                   let {resource} = this.state;
                                                   resource.showSynchronized = !this.state.resource.showSynchronized
                                                   this.setState(resource)
                                               })}
                                               label="Show Synchronized"
                                />
                                <div className="text-center mt-5">
                                    <Button
                                        bsSize="small"
                                        bsStyle="success"
                                        className="width-auto"
                                        onClick={() => this.setState({showLogsModal: true})}
                                    >
                                        Show sync logs
                                    </Button>
                                </div>
                            </Col>
                            <Col style={{display: "flex", flexDirection: "column", justifyContent: "center"}} md={3}>
                                <Row>
                                    <Col xs={12}>
                                        <p className="text-center">Search {this.state.key === 1 ? 'customer' : this.state.key === 2 ? 'service' : 'invoice'} in:</p>
                                        {this.state.key === 1 && this.renderSearchButtons('customerSearchFilterType')}
                                        {this.state.key === 2 && this.renderSearchButtons('serviceSearchFilterType')}
                                        {this.state.key === 3 && this.renderSearchButtons('invoiceSearchFilterType')}
                                    </Col>
                                    <Col xs={12}>
                                        {this.state.key === 1 && (this.state.resource.customerSearchFilterType === 'th' ?
                                            <Select className="Select"
                                                    classNamePrefix="select"
                                                    value={select(customerOptions, resource.customer_id)}
                                                    options={customerOptions}
                                                    placeholder="Customer"
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isMulti
                                                    isLoading={isSearchLoading}
                                                    onInputChange={e => {
                                                        if (e && e.length > 0) {
                                                            this.setState({isSearchLoading: true}, () => this.customerSearch.searchWithCallback(e, () => this.setState({isSearchLoading: false})))
                                                        }
                                                    }}
                                                    onChange={(e) => this.setState({isLoading: true}, () => {
                                                        this.selectResourceAttr('customer_id',
                                                            () => {
                                                                this.setState({
                                                                    resource: {
                                                                        ...this.state.resource,
                                                                        qb_customer_id: null,
                                                                    }
                                                                }, this.customers.sync)
                                                            })(e)
                                                    })}

                                            /> : this.state.resource.customerSearchFilterType === 'qb' &&
                                            <Select className="Select"
                                                    classNamePrefix="select"
                                                    value={select(customerQbOptions, resource.qb_customer_id)}
                                                    options={customerQbOptions}
                                                    placeholder="Customer"
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isMulti
                                                    isLoading={isSearchLoading}
                                                    onInputChange={e => {
                                                        if (e && e.length > 0) {
                                                            this.setState({isSearchLoading: true}, () => this.qbCustomerSearch.searchWithCallback(e, () => this.setState({isSearchLoading: false})))
                                                        }
                                                    }}
                                                    onChange={(e) => this.setState({isLoading: true}, () => {
                                                        this.selectResourceAttr('qb_customer_id', () => {
                                                            this.setState({
                                                                resource: {
                                                                    ...this.state.resource,
                                                                    customer_id: null,
                                                                }
                                                            }, this.customers.sync)
                                                        })(e)
                                                    })}
                                            />)}
                                        {this.state.key === 2 && (this.state.resource.serviceSearchFilterType === 'th' ?
                                            <Select className="Select"
                                                    classNamePrefix="select"
                                                    value={select(serviceOptions, resource.service_id)}
                                                    options={serviceOptions}
                                                    placeholder="Service"
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isLoading={isSearchLoading}
                                                    onInputChange={e => {
                                                        if (e && e.length > 0) {
                                                            this.setState({isSearchLoading: true}, () => this.serviceSearch.searchWithCallback(e, () => this.setState({isSearchLoading: false})))
                                                        }
                                                    }}
                                                    onChange={(e) => this.setState({isLoading: true}, () => {
                                                        this.selectResourceAttr('service_id', () => {
                                                                this.setState({
                                                                    resource: {
                                                                        ...this.state.resource,
                                                                        qb_service: null,
                                                                    }
                                                                }, this.services.sync)
                                                            }
                                                        )(e)
                                                    })}
                                            /> : this.state.resource.serviceSearchFilterType === 'qb' &&
                                            <Select className="Select"
                                                    classNamePrefix="select"
                                                    value={select(serviceQbOptions, resource.qb_service)}
                                                    options={serviceQbOptions}
                                                    placeholder="Service"
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isLoading={isSearchLoading}
                                                    onInputChange={e => {
                                                        if (e && e.length > 0) {
                                                            this.setState({isSearchLoading: true}, () => this.qbServiceSearch.searchWithCallback(e, () => this.setState({isSearchLoading: false})))
                                                        }
                                                    }}
                                                    onChange={(e) => this.setState({isLoading: true}, () => {
                                                        this.selectResourceAttr('qb_service', () => {
                                                                this.setState({
                                                                    resource: {
                                                                        ...this.state.resource,
                                                                        service_id: null,
                                                                    }
                                                                }, this.services.sync)
                                                            }
                                                        )(e)
                                                    })}
                                            />)}
                                        {this.state.key === 3 && (this.state.resource.invoiceSearchFilterType === 'th' ?
                                            <Select className="Select"
                                                    classNamePrefix="select"
                                                    value={select(invoiceOptions, resource.invoice_id)}
                                                    options={invoiceOptions}
                                                    placeholder="Invoice"
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isLoading={isSearchLoading}
                                                    onInputChange={e => {
                                                        if (e && e.length > 0) {
                                                            this.setState({isSearchLoading: true}, () => this.invoiceSearch.searchWithCallback(e, () => this.setState({isSearchLoading: false})))
                                                        }
                                                    }}
                                                    onChange={(e) => this.setState({isLoading: true}, () => {
                                                        this.selectResourceAttr('invoice_id',
                                                            () => {
                                                                this.setState({
                                                                    resource: {
                                                                        ...this.state.resource,
                                                                        qb_invoice: null,
                                                                    }
                                                                }, this.invoices.sync)
                                                            }
                                                        )(e)
                                                    })}
                                            /> : this.state.resource.invoiceSearchFilterType === 'qb' &&
                                            <Select className="Select"
                                                    classNamePrefix="select"
                                                    value={select(invoiceQbOptions, resource.qb_invoice)}
                                                    options={invoiceQbOptions}
                                                    placeholder="Invoice"
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isLoading={isSearchLoading}
                                                    onInputChange={e => {
                                                        if (e && e.length > 0) {
                                                            this.setState({isSearchLoading: true}, () => this.qbInvoiceSearch.searchWithCallback(e, () => this.setState({isSearchLoading: false})))
                                                        }
                                                    }}
                                                    onChange={(e) => this.setState({isLoading: true}, () => {
                                                        this.selectResourceAttr('qb_invoice',
                                                            () => {
                                                                this.setState({
                                                                    resource: {
                                                                        ...this.state.resource,
                                                                        invoice_id: null,
                                                                    }
                                                                }, this.invoices.sync)
                                                            }
                                                        )(e)
                                                    })}
                                            />)}
                                    </Col>
                                </Row>
                            </Col>
                            {this.state.key === 2 &&
                            <Col md={4}>
                            <Alert bsStyle="warning">
                                Before synchronizing services, make sure you have
                                <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="left"
                                overlay={this.renderHelperPopover()}
                            >
                                <span className="text-blue">
                                    <strong> Track classes </strong>
                                </span>
                            </OverlayTrigger>
                                switched on.<br/>
                                To synchronize services from Quickbooks, you also need to select a class for each service
                            </Alert>
                            </Col>
                            }
                            <Col md={this.state.key === 1 ? 3 : 3} className='justify-flex-end columnDirection'>
                                <Row className="marginBottom8">
                                    {this.state.key !== 3 && <Col xs={8}>
                                        <span className="text-center">Load {this.state.key === 1 ? 'customer' : 'service'} from:</span>
                                    </Col>}
                                    <Col xs={4} className="text-right">
                                        <span className="glyphicon glyphicon-refresh pointer" onClick={() => this.refresh()}/>
                                    </Col>
                                </Row>
                                {this.state.key === 1 && this.renderSelectButtons('customerFilterType')}
                                {this.state.key === 2 && this.renderSelectButtons('serviceFilterType')}

                            </Col>
                        </Row>
                    </Panel>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Tabs
                            activeKey={this.state.key}
                            onSelect={this.handleSelect}
                            id="controlled-tab-example"
                            mountOnEnter={true}
                        >
                            <Tab eventKey={1} title="Customers">
                                <QuickbooksCustomers
                                    onRef={c => {
                                        this.customers = c;
                                    }}
                                    eventKey={1}
                                    selectedKey={key}
                                    dateFrom={resource.from}
                                    dateTo={resource.to}
                                    showSynchronized={resource.showSynchronized}
                                    byInvoice={resource.byInvoice}
                                    showNotSyncCustomer={resource.showNotSyncCustomer}
                                    customerFilterType={resource.customerFilterType}
                                    customerQbFilter={resource.qb_customer_id}
                                    customerThFilter={resource.customer_id}
                                    page={(resource.qb_customer_id || resource.customer_id) ? 1 : null}
                                    setIsNotLoading={this.setIsNotLoading}
                                />
                            </Tab>
                            <Tab eventKey={2} title="Services">
                                <QuickbooksServices
                                    onRef={s => {
                                        this.services = s;
                                    }}
                                    alert={this.props.alert}
                                    eventKey={2}
                                    selectedKey={key}
                                    dateFrom={resource.from}
                                    dateTo={resource.to}
                                    showSynchronized={resource.showSynchronized}
                                    serviceFilterType={resource.serviceFilterType}
                                    serviceQbFilter={resource.qb_service}
                                    serviceThFilter={resource.service_id}
                                    page={(resource.qb_service || resource.service_id) ? 1 : null}
                                    setIsNotLoading={this.setIsNotLoading}
                                />
                            </Tab>
                            <Tab eventKey={3} title="Invoices">
                                <QuickbooksInvoices
                                    onRef={i => {
                                        this.invoices = i;
                                    }}
                                    eventKey={3}
                                    selectedKey={key}
                                    dateFrom={resource.from}
                                    dateTo={resource.to}
                                    showSynchronized={resource.showSynchronized}
                                    invoiceFilterType={resource.invoiceFilterType}
                                    invoiceQbFilter={resource.qb_invoice}
                                    invoiceThFilter={resource.invoice_id}
                                    page={(resource.qb_invoice || resource.invoice_id) ? 1 : null}
                                    setIsNotLoading={this.setIsNotLoading}
                                />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                {showLogsModal && <LogsModal
                    onHide={() => this.setState({showLogsModal: false})}
                    selectedTab={this.state.key === 1 ? 'Quickbook Customer:' : this.state.key === 2 ? 'Quickbook Service:' : this.state.key === 3 && 'Quickbook Invoice:'}
                    getUsers={this.props.actions.getUsers}
                    getQuickbooksLogs={this.props.actions.getQuickbooksLogs}
                />}
            </Grid>
        );
    }
}

Quickbooks.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Quickbooks.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        clientId: state.auth.client_id,
        alert: state.app.alertMessages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Quickbooks);
