import {defineMessages} from "react-intl";

export const commonMessages = defineMessages({
    myLocation: {
        id: 'Common.MyLocation.Checkbox.Label',
        defaultMessage: 'My Location'
    },
    employee: {
        id: 'Common.Employee.Label',
        defaultMessage: 'Employee'
    },
    jobCompleted: {
        id: 'Common.JobCompleted.Button.Label',
        defaultMessage: 'Job Completed'
    },
    updateJobStatus: {
        id: 'Common.UpdateJobStatus.Button.Label',
        defaultMessage: 'Update Job Status'
    },
})

export const buildMessages = (messages = {}) => {
    return {...commonMessages, ...messages};
};