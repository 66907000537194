import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as MyActions from "./actions"
import {addAlert} from "../App/actions";
import {showDock} from "../../components/dock/actions";
import {Col, Row} from "react-bootstrap";
import CustomerViewMenu from "./CustomerViewMenu";
import {debounce} from "throttle-debounce";
import CustomerViewTabs from "./CustomerViewTabs";
import SiteRow from "./SiteRow";

import SitesMap from "./SitesMap";
import MDSpinner from "react-md-spinner";
import {browserHistory} from "react-router";

const Actions = {...MyActions, addAlert, showDock}

const isMobile = window.screen.width < 1024

class CustomerViewSites extends Component {
    constructor(props, context) {
        super(props, context);
        this.delayedInBounds = debounce(2000, this.inBounds)
        this.state = {
            sites: [],
            selectedSiteId: null,
            loading: true,
            selectedSiteName: "",
            pendingPromises: []
        }
    }

    mapRef = React.createRef()
    siteRowRef = React.createRef();

    getSites = () => {
        const {params} = this.props
        this.props.actions.loadSites(params.token, res => {
            this.setState({
                sites: res.customer_sites,
                loading: false,
                selectedSiteId: this.props.site && this.props.site.selected.id
            })
        })
    }

    componentWillMount = () => {
        const {params} = this.props
        if (params.token) {
            this.getSites()
            setTimeout(() => {
                this.setState({show: true})
            }, 500)
            if (this.props.site.loaded) return
            this.props.actions.setSelectedSite(null, params.token, (data) => {
                this.setState({selectedSiteId: data.selected.id})
            })
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }

    selectSiteMarker = (siteId) => {
        const {token} = this.props.params

        this.setState({
            selectedSiteId: siteId
        })

        this.props.actions.setSelectedSite(siteId, token, () => {
            if (isMobile) {
                browserHistory.push(`/customer_view/${token}/proposals`)
            }
        })

        if (isMobile) return
        this.executeScroll()

    }

    handleSiteRowClick = (siteId, siteName) => {
        const {token} = this.props.params

        this.setState({
            selectedSiteId: siteId,
            selectedSiteName: siteName
        })

        this.props.actions.setSelectedSite(siteId, token, () => {
            if (isMobile) {
                browserHistory.push(`/customer_view/${token}/proposals`)
            }
        })

    }

    executeScroll = () => {
        this.siteRowRef && this.siteRowRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    render() {
        const {selectedSiteId, sites, loading, selectedSiteName} = this.state
        const {client, site, params} = this.props
        const {setSelectedSite} = this.props.actions
        const clientLogo = client ? client.client_logo_selected : ''
        return (<>
                <div id="customerView" className={`${isMobile ? "customerView--Mob" : "customerView"}`}>
                    <Row className='customerView__header customerView__header--disable-grid'>
                        <Col md={2} xs={12} className='company-logo'><img src={clientLogo}/></Col>
                        <Col md={10} xs={12} className="white-stripe">
                            <Col md={4} xs={12} style={{height: '100%'}} className='site-arborist-header'>
                                {site.loaded &&
                                    <Row>
                                        <p className={isMobile && "font12"}>
                                            <strong>Arborist:</strong>
                                        </p>
                                        {!site?.arborist
                                            ? <p>No arborist assigned to this site</p>
                                            : <>
                                                <p className={isMobile && "font12"}>{site?.arborist.full_name}</p>
                                                <p className={isMobile && "font12"}>{site?.arborist.number}</p>
                                                <p className={isMobile && "font12"}>{site?.arborist.email}</p>
                                            </>
                                        }
                                    </Row>}
                            </Col>
                            <Col md={8} xs={12}>
                                <Row>
                                    <span
                                        className='title'>{!selectedSiteName ? site?.selected.name : selectedSiteName}</span>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                    {isMobile
                        ? <Col md={12}>
                            <CustomerViewMenu token={params.token}/>
                        </Col>
                        : <Row className="customerView__tabs">
                            <Col md={12}>
                                <CustomerViewTabs token={params.token}/>
                            </Col>
                        </Row>
                    }
                    <Row className='customerView__content align-center'>
                        <Col md={isMobile ? 12 : 5} className={`customerView__map ${this.state.show ? 'showMap' : ''}`}>
                            <SitesMap selectedSiteId={selectedSiteId}
                                      sites={sites}
                                      selectSiteMarker={this.selectSiteMarker}
                                      mapRef={this.mapRef}
                                      token={params.token}
                                      setSelectedSite={setSelectedSite}
                            />
                        </Col>
                        <Col md={isMobile ? 12 : 7} xs={12} className='customerView__items items-container'>
                            {loading ? <MDSpinner className="mt-20"/> : sites.map(s => {
                                return (
                                    <SiteRow selectedSiteId={selectedSiteId}
                                             site={s}
                                             siteRowRef={this.siteRowRef}
                                             handleSiteRowClick={this.handleSiteRowClick}
                                             token={params.token}
                                             setSelectedSite={setSelectedSite}
                                    />
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        customerSiteMaps: state.siteMaps.siteMaps,
        client: state.client.customerInfo,
        site: state.customerView.site
    }
}

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewSites)