import React from 'react';
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Modal,
    Row,
} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import {colors} from "../../../common/commonHandlers";
import {CirclePicker} from "react-color";

class PolygonModal extends ResourceComponent {
    state = {
        showPicker: false,
        resource: {}
    }

    componentWillReceiveProps(nextProps) {
        this.setState({resource: nextProps.resource})
    }

    render() {
        const {showPicker, resource} = this.state
        return (
            <Modal
                className={"heightAuto maxHeight90"}
                animation={false}
                onHide={this.props.onHide}
                show={this.props.show}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                {resource && <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Name</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="label"
                                    placeholder="label"
                                    value={resource.label}
                                    onChange={this.updateResourceAttr}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <ControlLabel>
                                    Color
                                </ControlLabel>

                                <div className={`c${resource.color} color-picker pointer visible bigger`}
                                     onClick={() => this.setState({showPicker: !showPicker})}/>
                                {showPicker &&
                                <CirclePicker width={400} colors={colors} onChange={e => {
                                    this.setState({showPicker: false}, () => {
                                        this.selectCheckboxAttr('color')(colors.findIndex(c => c === e.hex));
                                    });
                                }}/>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>}

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            <Button
                                bsStyle="danger"
                                bsSize="small"
                                onClick={() => {
                                    this.props.onHide()
                                    this.props.destoryPolygon(resource)
                                }}
                            >
                                Delete
                            </Button>
                        </Col>
                        <Col md={6} className="text-right">
                            <Button bsSize="small" bsStyle="success"
                                    onClick={() => {
                                        this.props.onHide()
                                        this.props.savePolygon(resource)
                                    }}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}


export default PolygonModal;