import React from "react"
import PropTypes from "prop-types"
import {Button, Col, DropdownButton, MenuItem, Row} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import {select, sortAssetsLabels} from "../../../common/commonHandlers";
import Select from "react-select";
import {Link} from "react-router";
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";


const isMobile = window.screen.width < 1024;

export const CustomerSiteHeader = ({
                                       customerId,
                                       customerList,
                                       onUpdateCustomerSearch,
                                       onSelectCustomer,
                                       selectedSite,
                                       selectedSiteInfo,
                                       sites,
                                       customer,
                                       assets,
                                       editingSiteId,
                                       saveAllMapSettings,
                                       createNewSite,
                                       roles,
                                       newSiteMap,
                                       applyHistoryFilter,
                                       onChangeSite,
                                       customerSitesOptions,
                                       setShowReportModal,
                                       editSite,
                                       actions,
                                   }) => {

    const onExportSiteAssets = async () => {
        const assets_data = assets.map(a => {
            return {...a, images: a.images.map(i => i.url).join(',')}
        }).sort((a, b) => sortAssetsLabels(a, b, 'label'))

        //SITE ASSETS
        const site_assets_data = _.cloneDeep(assets_data)
        const workbook = new ExcelJS.Workbook()
        const site_assets_worksheet = workbook.addWorksheet("Site Assets")

        site_assets_worksheet.addRow([
            "Id",
            "Parent Asset Id",
            "Site Id",
            "Latitude",
            "Longitude",
            "Description",
            "Disabled",
            "Asset Number",
            "Asset Kind",
            "Plant Id",
            "Label",
            "Note",
            "Plant Count",
            "Genus",
            "Species",
            "Plant Name",
            "Plant Color",
            "Images",
            "Client Id",
            "Last DBH",
            "Polygon",
            "Recommended Services"
        ])
        site_assets_worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "bfbfbf"},
            };
        })
        site_assets_data.forEach((asset) => {
            site_assets_worksheet.addRow([
                asset.id,
                asset.parent_asset_id,
                asset.site_id,
                asset.latitude.toFixed(5),
                asset.longitude.toFixed(5),
                asset.description,
                !!asset.disabled,
                asset.asset_number,
                asset.asset_kind,
                asset.plant_id,
                asset.label,
                asset.note,
                asset.plant_count,
                asset.genus,
                asset.species,
                asset.plant_name,
                asset.plant_color,
                asset.images,
                asset.client_id,
                asset.last_dbh,
                asset.polygons?.find(p => p.chosen)?.type_polygon,
                asset.recommended_services.join(', ')
            ])
        })
        site_assets_worksheet.columns.forEach(column => {
            const lengths = column.values.map(v => v.toString().length);
            const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
            if (column._number === 12 || column._number === 18) {
                column.width = 30;
            } else {
                column.width = maxLength + 2;
            }
        });
        site_assets_worksheet.getColumn(7).numFmt = 'BOOLEAN'

        //TREE INVENTORY TABLE
        const tree_inv_data = _.cloneDeep(assets_data)
        const tree_inv_worksheet = workbook.addWorksheet("Tree Inventory")

        tree_inv_worksheet.addRow(["TREE INVENTORY TABLE"])
        tree_inv_worksheet.mergeCells('A1:K1');
        tree_inv_worksheet.getCell('A1').style = {font: {name: 'Calibri', size: 12}}
        tree_inv_worksheet.getCell('A1').alignment = {horizontal: 'center'};
        tree_inv_worksheet.getCell('A1').fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {argb: "bfbfbf"},
        };
        tree_inv_worksheet.getCell('A1').border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        };
        tree_inv_worksheet.addRow([
            "TREE #",
            "DBH",
            "SPECIES",
            "STEMS",
            "FACTORS",
            "GRADE",
            "CONDITION",
            "RISK RATING",
            "OWNERSHIP",
            "CLASS",
            "INVASIVE SPECIES"
        ])
        tree_inv_worksheet.getRow(2).eachCell((cell) => {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "bfbfbf"},
            }
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            }
        })
        tree_inv_data.forEach((asset) => {
            tree_inv_worksheet.addRow([
                asset.label,
                (asset.last_dbh ? asset.last_dbh + "\"" : "")?.toString().toUpperCase(),
                asset.plant_name?.toString().toUpperCase(),
                (asset.stems ? asset.stems : '-'),
                asset.factors ? (JSON.parse(asset.factors)?.map((f) => f.name).join(', '))?.toString().toUpperCase() : "",
                asset.grade?.toString().toUpperCase(),
                asset.condition?.toString().toUpperCase(),
                asset.risk_rating?.toString().toUpperCase(),
                asset.ownership?.toUpperCase(),
                asset.class?.toString().toUpperCase(),
                asset.invasive_species ? "YES" : "NO"
            ])
        })
        tree_inv_worksheet.columns.forEach(column => {
            const lengths = column.values.slice(2).map(v => v.toString().length); //SKIP TITLE ROW
            const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) * 1.1; // font size is 12
            column.width = maxLength + 4;
        });
        tree_inv_worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell, colNumber) => {
                    if ([1, 2, 3, 4, 6, 7, 8, 9, 10, 11].includes(colNumber)) {
                        cell.alignment = {horizontal: 'center'};
                    }
                    cell.font = {
                        name: 'Calibri',
                        size: 12
                    }
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    }
                })
            }
        })

        const excelBuffer = await workbook.xlsx.writeBuffer();
        const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(dataBlob, `${customer.name}-Site Assets For-${selectedSiteInfo.name}.xlsx`);
    }

    const primaryUrl = window.location.href.split("/#/")[0]

    return (<Row className={isMobile ? "columnDirection" : "vertical-align"}>
            <Col md={2} xs={12} className={isMobile && 'bottom15'}>
                <Select
                    value={select(customerList, customerId)}
                    isClearable={false}
                    options={customerList}
                    onInputChange={onUpdateCustomerSearch}
                    onChange={onSelectCustomer}
                    placeholder="Customer Name"
                />
            </Col>
            <Col md={2} xs={12} className={isMobile && 'bottom15'}>
                {customerSitesOptions && customerSitesOptions.length > 0 &&
                    <Select className="Select" classNamePrefix="select"
                            name="select_site"
                            value={select(customerSitesOptions, selectedSite)}
                            options={customerSitesOptions}
                            onChange={onChangeSite}
                            placeholder="All Sites"
                    />}
            </Col>
            <Col md={8} className={isMobile ? 'flex-column-center mt10' : 'hright'}>
                <div className={isMobile && 'bottom10'}>
                    <Button onClick={() => window.open(`${primaryUrl}/#/lead?customer_id=${customer.id}`, '_blank')}
                            className="customer-sites-btn">
                        Find a time
                    </Button>
                    <DropdownButton
                        bsStyle="default"
                        className="customer-sites-btn"
                        title={"More"}
                    >
                        <MenuItem>
                            <Link onClick={() => createNewSite(customer.id)}>New Site</Link>
                        </MenuItem>
                        <MenuItem>
                            {selectedSite &&
                                <Link onClick={() => editSite(editingSiteId)}>Edit Site</Link>
                            }
                        </MenuItem>
                        <MenuItem>
                            <LinkContainer disabled={!(customer && selectedSite)}
                                           to={(customer && selectedSite) ? `/mapview?customer_id=${customer.id}&site_id=${editingSiteId}` : ''}>
                                <MenuItem>New Proposal</MenuItem>
                            </LinkContainer>
                        </MenuItem>
                    </DropdownButton>
                    {customer.id !== 0
                        ? <DropdownButton
                            bsStyle="default"
                            className="customer-sites-btn"
                            style={{color: '#d9230f'}}
                            title={"Customer sites"}
                        >
                            <LinkContainer to={`/customer/info/${customer.id}`} target='_blank'>
                                <MenuItem>Customer Info</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/proposals/${customer.id}`} target='_blank'>
                                <MenuItem>Customer Proposals</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/sites/${customer.id}`} target='_blank'>
                                <MenuItem>Customer Sites</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/work_orders/${customer.id}`} target='_blank'>
                                <MenuItem>Customer Work Orders</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/invoices/${customer.id}`} target='_blank'>
                                <MenuItem>Customer Invoices</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/work_history/${customer.id}`} target='_blank'>
                                <MenuItem>Work History</MenuItem>
                            </LinkContainer>
                            <MenuItem divider/>
                            <LinkContainer to={`/customer/info`} target='_blank'>
                                <MenuItem>Create New Customer</MenuItem>
                            </LinkContainer>
                        </DropdownButton>
                        : null}
                    <Button bsStyle="success"
                            disabled={sites?.length === 0}
                            className="customer-sites-btn"
                            onClick={applyHistoryFilter}
                    >
                        Filter
                    </Button>
                </div>
                <div className='d-flex'>
                    <Col className="d-flex">
                        {selectedSite &&
                            <Button
                                onClick={newSiteMap}
                                className="customer-sites-btn"
                                bsStyle="info">
                                New Map
                            </Button>
                        }
                        {" "}
                        {selectedSite &&
                            <Button
                                onClick={saveAllMapSettings}
                                className="customer-sites-btn"
                                bsStyle="success">
                                Save All Map Settings
                            </Button>
                        }
                    </Col>
                    {selectedSite && assets && assets.length > 0 && customer && selectedSite && roles.find(role => role === "admin") ?
                        <Button
                            className="customer-sites-btn"
                            onClick={onExportSiteAssets}>
                            Site assets
                        </Button>
                        : null
                    }
                    {selectedSite && assets && assets.length > 0 && customer && selectedSite && roles.find(role => role === "admin") ?
                        <Button
                            onClick={() => setShowReportModal(true)}
                            className="customer-sites-btn"
                            bsStyle="info">
                            Generate Report
                        </Button>
                        : null
                    }
                </div>
            </Col>
        </Row>
    )
}

CustomerSiteHeader.propTypes = {
    customerId: PropTypes.number.isRequired,
    customerList: PropTypes.array,
    onUpdateCustomerSearch: PropTypes.func.isRequired,
    onSelectCustomer: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    salesArborist: PropTypes.string,
}
