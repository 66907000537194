import {addAlert} from "../../containers/App/actions";

export const showEmailDialog = (options) => async dispatch => {
    dispatch({type: 'SHOW_EMAIL_DIALOG', options});
};

export const hideEmailDialog = () => async dispatch => {
    dispatch({type: 'HIDE_EMAIL_DIALOG'});
};

export const setEmailErrors = (errors, showAlert = false) => async dispatch => {
    dispatch({type: 'SET_EMAIL_ERRORS', errors});

    showAlert && dispatch(addAlert({
        message: `Problem occurred when sending email`,
        mood: "warning",
        dismissAfter: 5000,
    }));
};

export const appendEmailError = (error, showAlert = false) => async dispatch => {
    dispatch({type: 'APPEND_EMAIL_ERROR', error});

    showAlert && dispatch(addAlert({
        message: `Problem occurred when sending email`,
        mood: "warning",
        dismissAfter: 5000,
    }));
};