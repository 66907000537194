import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import {defaultDateFormat, defaultTimeFormat, mapForSelect, optionsFor, select} from "../../common/commonHandlers";
import moment from "moment";
import Datetime from "react-datetime";
import React from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Select from "react-select";
import * as api from "./MapSchedulerPageApi";
import './MapScheduler.scss'
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import DateRangeFilter from "../../components/DateRangeFilter";

const isMobile = window.screen.width <= 1024;

const actions = {...api};

class FilterModal extends ResourceComponent {
    constructor(props) {
        super(props);
        this.state = {
            resource: {
                scheduled_to: null,
                hide_completed: true,
                hide_invoiced: true
            },
            employees: [],
            service_types: [],
            services: [],
            reasons: [],
            priorities: [],
            equipments: [],
            citySearchResults: [],
            zipSearchResults: [],
            customerTypes: [],
            plants: []
        };
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.workOrderSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.proposalSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    };

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.workOrderSearch = this.buildSearchComponent('work_order_id', this.props.actions.searchForWorkOrder);
        this.proposalSearch = this.buildSearchComponent('proposal_id', this.props.actions.searchForProposal);
        this.props.actions.loadContextData(data => {
            data.reasons = data.reasons.map(reason => ({label: reason.name, value: reason.id}))
            this.setState(data)
        });
    };

    getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return parsed;
        } else {
            return null;
        }
    }

    handleRangeDateChange = (start, end, dateStart, dateEnd) => {
        let {resource} = this.state;
        resource[dateStart] = this.getDate(start);
        resource[dateEnd] = this.getDate(end);
        this.setState(resource, this.search);
    }

    render() {
        const {
            resource,
            employees,
            service_types,
            services,
            priorities,
            equipments,
            citySearchResults,
            zipSearchResults,
            reasons,
            customerTypes,
            plants
        } = this.state;
        const customerOptions = this.customerSearch.searchOptions();
        const workOrdersOptions = this.workOrderSearch.searchOptions();
        const proposalsOptions = this.proposalSearch.searchOptions();
        const prioritiesOptions = mapForSelect(priorities);
        const allEquipment = mapForSelect(equipments);
        const plantsOptions = mapForSelect(plants);
        let signs = optionsFor(">", "<", "=");

        return (
            <Modal
                {...this.props}
                bsSize="big"
                animation={true}
                id='filter-modal'
                onHide={() => this.props.onHide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Find Work To Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12} className={'bottom10 top15'}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(service_types, resource.service_type)}
                                    options={service_types}
                                    isClearable
                                    onChange={this.selectResourceAttr('service_type')}
                                    placeholder="Service Type"
                            />
                        </Col>
                        <Col md={6} xs={12} className={isMobile ? 'bottom10' : 'bottom10 top15'}>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(services, resource.service_id)}
                                    isClearable
                                    isMulti
                                    options={services}
                                    onChange={this.selectResourceAttr('service_id')}
                                    placeholder="Service"
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(allEquipment, resource.equipment_ids)}
                                    options={allEquipment}
                                    isMulti
                                    isClearable
                                    onChange={this.selectResourceAttr('equipment_ids')}
                                    placeholder="Select Equipment"
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    options={reasons}
                                    value={select(reasons, resource.reason_id)}
                                    placeholder="Reason"
                                    isClearable
                                    onChange={this.selectResourceAttr('reason_id', this.search)}
                            />
                        </Col>
                    </Row>
                    <hr className={'marginBottom10 top10'}/>
                    <Row>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(prioritiesOptions, resource.priority_id)}
                                options={prioritiesOptions}
                                isClearable
                                isMulti
                                onChange={this.selectResourceAttr('priority_id')}
                                placeholder="Select Priority"
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(plantsOptions, resource.plant_id)}
                                options={plantsOptions}
                                isClearable
                                isMulti
                                onChange={this.selectResourceAttr('plant_id')}
                                placeholder="Select Plant"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={'bottom10'}>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(customerOptions, resource.customer_id)}
                                    options={customerOptions}
                                    placeholder="Customer"
                                    isClearable
                                    onInputChange={this.customerSearch.search}
                                    onChange={this.selectResourceAttr('customer_id')}
                            />
                        </Col>
                    </Row>
                    <hr className={'marginBottom10 top10'}/>
                    <Row>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(services, resource.ex_service_id)}
                                options={services}
                                isClearable
                                isMulti
                                onChange={this.selectResourceAttr('ex_service_id')}
                                placeholder="Select Excluded Services"
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(prioritiesOptions, resource.ex_priority_id)}
                                options={prioritiesOptions}
                                isClearable
                                isMulti
                                onChange={this.selectResourceAttr('ex_priority_id')}
                                placeholder="Select Excluded Priority"
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(allEquipment, resource.ex_equipment_ids)}
                                    options={allEquipment}
                                    isClearable
                                    isMulti
                                    onChange={this.selectResourceAttr('ex_equipment_ids')}
                                    placeholder="Select Excluded Equipment"
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(customerTypes, resource.customer_type)}
                                    options={customerTypes}
                                    isClearable
                                    isMulti
                                    onChange={this.selectResourceAttr('customer_type')}
                                    placeholder="Select Customer Type"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(plantsOptions, resource.ex_plant_id)}
                                    options={plantsOptions}
                                    isClearable
                                    isMulti
                                    onChange={this.selectResourceAttr('ex_plant_id')}
                                    placeholder="Select Excluded Plant"
                            />
                        </Col>
                    </Row>
                    <hr className={'bottom10 top10'}/>
                    <Row>
                        <Col xs={12}>
                            <ColorCheckbox value={resource.include_scheduled}
                                           label="Include scheduled"
                                           onChange={this.selectCheckboxAttr('include_scheduled')}
                            />
                        </Col>
                        {resource.include_scheduled && <div>
                            <Col md={6} xs={12} className={'bottom10 top15'}>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={defaultTimeFormat}
                                    value={resource.scheduled_from ? moment(resource.scheduled_from) : null}
                                    inputProps={{placeholder: 'Scheduled From'}}
                                    onChange={this.dateResourceAttr('scheduled_from')}
                                />
                            </Col>
                            <Col md={6} xs={12} className={isMobile ? 'bottom10' : 'bottom10 top15'}>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    onFocus={() => {
                                        if (resource.scheduled_to === null){
                                            resource.scheduled_to = moment().endOf('day')
                                            this.setState(resource)
                                        }
                                    }}
                                    timeFormat={defaultTimeFormat}
                                    value={resource.scheduled_to ? moment(resource.scheduled_to) : null}
                                    inputProps={{placeholder: 'Scheduled To'}}
                                    onChange={this.dateResourceAttr('scheduled_to')}
                                />
                            </Col>
                        </div>}

                        <Col xs={12}>
                            <ColorCheckbox value={resource.hide_completed}
                                           label="Hide Completed"
                                           onChange={this.selectCheckboxAttr('hide_completed')}
                            />
                        </Col>
                        <Col xs={12}>
                            <ColorCheckbox value={resource.hide_invoiced}
                                           label="Hide Invoiced"
                                           onChange={this.selectCheckboxAttr('hide_invoiced')}
                            />
                        </Col>
                    </Row>
                    <hr className={'marginBottom10 top10'}/>
                    <Row>
                        <Col xs={12}>
                            <ControlLabel>Promised</ControlLabel>
                            <DateRangeFilter
                                promisedStart={resource.promised_from}
                                promisedEnd={resource.promised_to}
                                upsertPromise={(id, start, end) => this.handleRangeDateChange(start, end, 'promised_from', 'promised_to')}
                                proposalServiceId={null}
                                serviceNo={null}
                                index={resource.include_scheduled ? 2 : 1}
                                timeDelay={1}
                            />
                        </Col>
                    </Row>
                    <hr className={'marginBottom10 top10'}/>
                    <Row>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(employees, resource.arborist_id)}
                                    options={employees}
                                    isClearable
                                    onChange={this.selectResourceAttr('arborist_id')}
                                    placeholder="Arborist"
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(zipSearchResults, resource.zip)}
                                    options={zipSearchResults}
                                    placeholder="Zip"
                                    isClearable
                                    isMulti
                                    onInputChange={e =>
                                        this.props.actions.searchForZip(e, this.handleSearchResults('zip', 'zipSearchResults'))
                                    }
                                    onChange={e => {
                                        this.selectResourceAttr('zip')(e);
                                    }}
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(citySearchResults, resource.city)}
                                    options={citySearchResults}
                                    placeholder="City"
                                    isClearable
                                    isMulti
                                    onInputChange={e =>
                                        this.props.actions.searchForCity(e, this.handleSearchResults('city', 'citySearchResults'))
                                    }
                                    onChange={e => {
                                        this.selectResourceAttr('city')(e);
                                    }}
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(proposalsOptions, resource.proposal_id)}
                                    options={proposalsOptions}
                                    placeholder="Proposal #"
                                    isClearable
                                    isMulti
                                    onInputChange={this.proposalSearch.search}
                                    onChange={this.selectResourceAttr('proposal_id')}
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={select(workOrdersOptions, resource.work_order_id)}
                                    onInputChange={this.workOrderSearch.search}
                                    options={workOrdersOptions}
                                    placeholder="Work Order #"
                                    isClearable
                                    isMulti
                                    onChange={this.selectResourceAttr('work_order_id')}
                            />
                        </Col>
                        <Col md={6} xs={12} className={'bottom10'}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(signs, resource.eq)}
                                        placeholder="Eq"
                                        isClearable
                                        options={signs}
                                        onChange={this.selectResourceAttr('eq')}
                                        clearable={false}
                                />
                            </FormGroup>
                        </Col>
                        {resource.service_type && <Col md={6} xs={12}>
                            <FormGroup>
                                <FormControl
                                    type="number"
                                    placeholder="Hours"
                                    name="hours"
                                    onChange={e => {
                                        resource.hours = e.target.value;
                                        this.setState(resource);
                                    }}
                                    value={resource.hours}
                                />
                            </FormGroup>
                        </Col>}
                        <Col xs={12}>
                            <ColorCheckbox value={resource.show_exclusive}
                                           label="Show exclusive"
                                           onChange={this.selectCheckboxAttr('show_exclusive')}
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsSize="small" onClick={() => this.props.onHide()}>Close</Button>
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => this.props.onSave(resource)}
                    >Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
