import React from 'react';
import PropTypes from "prop-types"
import {FormattedNumber} from "react-intl";
import currencyFormatter from 'currency-formatter';

/* eslint-disable react/style-prop-object */
//TODO: supress the warning of the style prop since its making eslint sad
const Dollar = ({amount = 0}) => (
    <FormattedNumber value={amount ? amount : 0} style="currency" currency="USD"/>
)

Dollar.propTypes = {
    amount: PropTypes.number.isRequired,
}

export default Dollar


export function dollar(number) {
    return currencyFormatter.format(number, {locale: 'en-US'});
}