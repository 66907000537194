import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {addAlert} from "../App/actions";

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/users/get_reports_favourites`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            reports_favourites: all[0].data
        });
    }, dispatch, getState, false);
};

export const saveFilter = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_reports_favourites`,
        method: 'PUT',
        data: {resource}
    };
    apiCall("Saving reports favourites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const downloadChemicalComplianceCSV = (from, to, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/restricted/chemical_compliance`,
        method: 'GET',
        params: {from, to}
    };
    apiCall("Download chemical compliance report", config, async config => {
        const response = await axios.request(config);
        if(response.data && response.data.chemical_compliance_entries?.length === 0) {
            dispatch(
                addAlert({
                    message: `No data found for the selected date range`,
                    mood: "warning",
                    dismissAfter: 1500,
                })
            );
        } else {
            callback(response.data);
        }
    }, dispatch, getState, false);
}