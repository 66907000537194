import React, {memo} from 'react';
import {colors, reverseColor} from "../../../common/commonHandlers";
import {Marker} from "react-google-maps";
import * as moment from "moment";
import * as _ from "lodash";

function areEquals(prev, next) {
    return prev?.isSelected === next?.isSelected &&
        prev?.lockedMode === next?.lockedMode &&
        prev?.showNewAssets === next?.showNewAssets &&
        _.isEqual(prev?.ass.proposal_services, next?.ass.proposal_services) &&
        prev?.duplicating === next?.duplicating &&
        prev?.ass.latitude === next?.ass.latitude &&
        prev?.ass.longitude === next?.ass.longitude
}

const theSameYear = (asset) => {
    let now = moment()
    if (asset.completed_at) {
        return moment(now).isSame(asset.completed_at, 'year')
    } else {
        return false
    }
}

const markerDims = {w: 30, h: 45}

const markers = {
    baseMarker: (ass) => {
        let label = ass.label
        let baseFontSize = 15
        let y = 8;
        const fillColor = ass.selected ? '#f8e500' : colors[ass.plant_color];
        const labelColor = reverseColor(fillColor);

        if(label !== "") {
            if(label.length > 2) {
                baseFontSize = 12
                y = 6
            } else if(label.length > 3) {
                baseFontSize = 10
                y = 6
            }
            label = label.replace("&", "&amp;")
        }
        return `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                  <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" 
                    fill="${fillColor}" stroke="black"
                    transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"/>
                  <text xml:space="preserve" style="font-style:normal;font-weight:400;font-size:8px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill:${labelColor};fill-opacity:1;stroke:none" x="10.055" y="5.334">
                    <tspan x="31" y="${y}" style="font-size:${baseFontSize}px;text-align:center;text-anchor:middle;">${label}</tspan>
                  </text>
                </svg>`
    },
    circleMarker: (ass) => {
        let label = ass.label
        if(label !== "") {
            label = label.replace("&", "&amp;")
        }
        const fillColor = ass.selected ? '#f8e500' : colors[ass.plant_color];
        const labelColor = "#444444";

        return `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                    <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" style="display:inline;opacity:1;fill:${fillColor};fill-opacity:1;stroke:#000;stroke-width:1.30877256;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"/>
                    <ellipse cx="10.045" cy="2.219" rx="10.038" ry="9.827" style="fill:#fff;stroke-width:1.11443377"/>
                    <text xml:space="preserve" style="font-style:normal;font-weight:400;font-size:8px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill:${labelColor};fill-opacity:1;stroke:none" x="10.055" y="5.334">
                        <tspan x="33" y="5.334" style="font-size:10px;text-align:center;text-anchor:middle">${label}</tspan>
                    </text>
                </svg>`;
    },
    circleGradientMarker: (ass) => {
        let label = ass.label
        if(label !== "") {
            label = label.replace("&", "&amp;")
        }
        const fillColor = ass.selected ? '#f8e500' : colors[ass.plant_color];
        const labelColor = "#444444";

        return `<svg xmlns="http://www.w3.org/2000/svg" width="${markerDims.w}" height="${markerDims.h}" viewBox="-1 -12 22 47">
                    <path d="M815.23 274.257c-1.61 1.796-2.62 4.412-2.58 6.873.082 5.143 2.3 7.09 5.85 14.167 1.278 3.115 2.613 6.41 3.882 11.868.176.806.348 1.553.428 1.618.08.065.251-.685.428-1.49 1.269-5.459 2.603-8.752 3.882-11.866 3.549-7.078 5.768-9.025 5.85-14.167.04-2.461-.973-5.08-2.584-6.876-1.84-2.052-4.616-3.57-7.576-3.633-2.96-.064-5.738 1.454-7.58 3.506z" style="display:inline;opacity:1;fill:${fillColor};fill-opacity:1;stroke:#000;stroke-width:1.30877256;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33631 0 0 1.18559 -1089.487 -331.784)"/>
                    <ellipse cx="10.045" cy="2.219" rx="8.749" ry="8.679" style="opacity:1;fill:#fff;stroke:#000;stroke-width:2.63411473;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
                    <text xml:space="preserve" style="font-style:normal;font-weight:400;font-size:8px;line-height:1.25;font-family:sans-serif;letter-spacing:0;word-spacing:0;fill:${labelColor};fill-opacity:1;stroke:none" x="10.055" y="5.334">
                        <tspan x="33" y="5.334" style="font-size:10px;text-align:center;text-anchor:middle">${label}</tspan>
                    </text>
                    <path style="fill:green;stroke-width:1.11443377" d="M20.082 2.22a10.038 9.827 0 0 1-.021.642"/>
                </svg>`;
    }
}

const MemoizedMarker = ({index, editable, lockedMode, ass, refs, setRefs, showNewAssets, infoWindow, onDragend, onMarkerClick}) => {
    const withCircle = showNewAssets ? ass.proposal_services.some(ps => !!ps.completed_at) : false;
    const gradient = showNewAssets ? !ass.proposal_services.some(theSameYear) : false

    const getIcon = () => {
        if(withCircle && gradient) {
            return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markers.circleGradientMarker(ass))}
        }
        if(withCircle) {
            return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markers.circleMarker(ass))}
        }
        return {url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markers.baseMarker(ass))}
    }

    return <Marker
        ref={(ref) => {
            refs[index] = ref
            setRefs(refs)
        }}
        key={ass.id}
        position={{lat: ass.latitude, lng: ass.longitude}}
        label={null}
        icon={getIcon()}
        zIndex={ass.selected ? 10000 : null}
        title={
            ass.plant_name +
            (ass.plant_count > 1 ? ' [' + ass.plant_count + ']' : '')
        }
        defaultOpacity={ass.opacity ? 0.5 : 1}
        draggable={editable && !lockedMode}
        onDragEnd={onDragend(ass)}
        onClick={() => {
            onMarkerClick(ass);
        }}
    >
        {infoWindow(ass)}
    </Marker>
}

export default memo(MemoizedMarker, areEquals);