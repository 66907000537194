import {Col, ControlLabel, FormControl, FormGroup, Modal, Row, Radio} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import Datetime from "react-datetime";
import {defaultDateFormat} from "../../common/commonHandlers";
import moment from "moment/moment";
import Dropzone from "react-dropzone";
import HolidayRemovalModal from "./HolidayRemovalModal";
import './HolidayModal.scss'
import AttachmentThumbnail from "../../components/ReorderingDragAndDrop/AttachmentThumbnail/AttachmentThumbnail";
import Attachment from "../../components/image/Attachment";

const isMobile = window.screen.width <= 1024;

const HolidayModal = ({onDelete, onHide, editedHoliday, fetchHolidayImages, fetchHolidayFiles, removeHolidayImage, removeHolidayFile, uploadImages, uploadFiles, rotateImage, onSave, addAlert}) => {
    const [holiday, setHoliday] = useState({recurring: null, name: '', date: null})
    const [showRemovalModal, setShowRemovalModal] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [images, setImages] = useState([])
    const [docs, setDocs] = useState([])

    const valid = (current) => {
        const yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    };

    const validateForm = () => {
        let requiredFields = ["name", "date"]

        if (!holiday) return false

        const invalidFields = requiredFields.some(prop => {
            return !holiday[prop] ||
                holiday[prop].toString().trim().length < 1;
        })
        return !invalidFields;
    }

    const updateEventType = (e) => setHoliday({...holiday, recurring: e.target.name === 'recurring', date: !holiday?.date ? moment() : holiday?.date})
    const updateName = (data) => setHoliday({...holiday, name: data.target.value})
    const updateDate = (data) => setHoliday({...holiday, date: moment.utc(data).add(1, 'hour')})
    const removeImage = (id) => removeHolidayImage(id, () => fetchImages())
    const removeFile = (id) => removeHolidayFile(id, () => fetchFiles())
    const rotateHolidayImage = (id, direction) => rotateImage(id, direction, () => fetchImages())
    const fetchImages = () => fetchHolidayImages(holiday.id, images => setImages(images))
    const fetchFiles = () => fetchHolidayFiles(holiday.id, docs => setDocs(docs.content))

    const uploadFile = (files, callback) => {
        const imageAttachment = files && files[0] && files[0].type.indexOf("image") > -1

        if (imageAttachment) {
            uploadImages(files, holiday.id, data => {
                setImages([...images, data])
                fetchImages()
                callback && callback()
            })
        } else {
            uploadFiles(files, holiday.id, data => {
                setDocs([...docs, data])
                fetchFiles()
                callback && callback()
            })
        }
    }

    const forceDownload = (blob, filename) => {
        let a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    const downloadResource = (url, filename) => {
        if (!filename) filename = url.split('\\').pop().split('/').pop();
        filename = filename.replace(/\.heic/ig, '.jpeg')
        fetch(url, {
            headers: new Headers({
                'Origin': window.location.origin
            }),
            mode: 'cors'
        })
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                forceDownload(blobUrl, filename);
            })
            .catch(e => console.error(e));
    }

    useEffect(() => {
        if (holiday?.id) {
            fetchImages()
            fetchFiles()
        }
    }, [holiday?.id])

    useEffect(() => {
        if (editedHoliday?.id) {
            setHoliday(editedHoliday)
        } else {
            setHoliday({recurring: null, name: '', date: null})
        }
    }, [])

    return (
        <Modal
            show={true}
            bsSize="large"
            onHide={onHide}
            className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
            id="holiday-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>{holiday && holiday.id ? `Edit mode` : 'New holiday'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <form>
                            <Row>
                                <Col md={6} className="mt10">
                                    <FormGroup>
                                        <ControlLabel>
                                            Name
                                        </ControlLabel>
                                        <FormControl
                                            name="name"
                                            placeholder="Name"
                                            onChange={updateName}
                                            value={holiday?.name}
                                        />
                                    </FormGroup>
                                    <ControlLabel>
                                        {holiday && holiday.id ? 'Edit event type' : 'Date'}
                                    </ControlLabel>
                                    <FormGroup
                                        className='d-flex '
                                        style={{justifyContent: 'space-between', alignItems: 'center', gap: '20px'}}
                                    >
                                        <div>
                                            <Radio name="oneTime"
                                                   id='oneTime'
                                                   checked={holiday && holiday.date && !holiday.recurring}
                                                   onChange={updateEventType}
                                            >
                                                One time
                                            </Radio>
                                            <Radio name="recurring"
                                                   id='recurring'
                                                   checked={holiday && holiday.date && holiday.recurring}
                                                   onChange={updateEventType}
                                            >
                                                Recurring
                                            </Radio>
                                        </div>
                                        <div style={{width: '100%'}}>
                                            <Datetime
                                                timeFormat={false}
                                                value={holiday && holiday.date && moment.utc(holiday.date).format(defaultDateFormat)}
                                                name={'oneTime'}
                                                utc={true}
                                                closeOnSelect
                                                onChange={(e) => {
                                                    updateDate(e)
                                                    valid(moment())
                                                }}
                                                inputProps={{placeholder: 'Date', disabled: !holiday.date}}
                                                isValidDate={!editedHoliday?.recurring && valid}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                {holiday && holiday.id &&
                                    <Col md={6} className="mt10">
                                        <FormGroup>
                                            <ControlLabel>
                                                Upload Image/Docs
                                            </ControlLabel>
                                            <Dropzone
                                                disabled={!(holiday && holiday.id)}
                                                onDrop={files => {
                                                    setDisabledButton(true)
                                                    uploadFile(files, () => setDisabledButton(false))
                                                }}
                                                style={{height: !isMobile && 109}}
                                                className="dropzone pointer small">
                                                <div className="flex-column-center">
                                                    <p className="no-margin">Drop a file here or click to select a file to upload.</p>
                                                    <span className="font10">(Maximum file size: 10mb)</span>
                                                </div>
                                            </Dropzone>
                                        </FormGroup>
                                    </Col>}
                            </Row>
                            {(images && images.length > 0 || docs && docs.length > 0) &&
                                <div className="bottom15">
                                    <hr className={'marginBottom10 top10'}/>
                                    {images && images.length > 0 &&
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    <p className="bottom15 bold">Images:</p>
                                                    <div className="images-container">
                                                        {images && images.map(i => <Attachment key={i.id}
                                                                                               image={i}
                                                                                               rotateImage={rotateHolidayImage}
                                                                                               allowRemove={true}
                                                                                               downloadButton={true}
                                                                                               downloadResource={downloadResource}
                                                                                               deleteImage={imageId => removeImage(imageId)}
                                                                                               showName={true}/>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {(images && images.length > 0 && docs && docs.length > 0) &&
                                        <hr className={'marginBottom10 top15'}/>}
                                    {docs && docs.length > 0 &&
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    <p className="bottom15 bold">Documents:</p>
                                                    <div className='d-flex'>
                                                        {docs && docs.map(doc => <AttachmentThumbnail
                                                            key={doc.id}
                                                            file={doc}
                                                            allowRemove={true}
                                                            deleteAttachment={docId => removeFile(docId)}
                                                            downloadButton={true}
                                                        />)}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                        </form>
                    </Col>
                </Row>
            </Modal.Body>
            {showRemovalModal &&
                <HolidayRemovalModal
                    onDelete={() => {
                        setShowRemovalModal(false)
                        onDelete(holiday)
                    }}
                    onHide={() => setShowRemovalModal(false)}
                />
            }
            <Modal.Footer>
                <Row className="d-flex">
                    <Col md={3} className="text-left">
                        <button
                            type="button"
                            onClick={() => {
                                if (images && images.length > 0 || docs && docs.length > 0) {
                                    setShowRemovalModal(true)
                                } else {
                                    onDelete(holiday)
                                }
                            }}
                            className="btn btn-warning"
                            disabled={!holiday || (holiday && !holiday.id) || disabledButton}
                        >
                            Delete
                        </button>
                    </Col>
                    <Col md={9} className="full-width">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-default"
                            data-dismiss="modal"
                            disabled={disabledButton}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                if (!validateForm()) {
                                    addAlert({
                                        message: "Fill all the fields",
                                        mood: "danger",
                                        dismissAfter: 2500,
                                    })
                                    return false
                                }
                                onSave(holiday)
                            }}
                            className="btn btn-success"
                            disabled={disabledButton}
                        >
                            Save
                        </button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default HolidayModal;
