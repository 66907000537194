import React, {useEffect} from "react"
import {Col, Grid} from "react-bootstrap";
import Helmet from "react-helmet";
import PageTitle from "../../components/PageTitle";
import RegisterWidget from "./RegisterWidget";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import * as myActions from "./RegisterPageApi";
import {addAlert} from "../App/actions";
import Logo from "../HomePage/HomePageLogo.png";
const actions = {...myActions, addAlert};

const Register = ({...props}) => {
    useEffect(()=>{
        if (props.isAuthenticated) {
            console.log("Login component says You're already authenticated. Deciding where to send you")
            if(props.roles.includes('crew')) {
                browserHistory.push('/crew_work_orders')
                return
            }
            if (props.roles.includes('it_engineering')){
                browserHistory.push('/admin')
                return
            }else if(props.roles.includes('scheduler')) {
                browserHistory.push('/client_dashboard')
                return
            }else if(props.roles.includes('sales_arborist')) {
                browserHistory.push('/sales_dashboard')
                return
            }else{
                props.actions.addAlert({message: "Your user type doesnt have any pages. Goto Root.", dismissAfter: 10000, mood: 'info'})
                console.log("If we got this far then i dont know where to send you. or i didin't program it yet. going to root.")
                browserHistory.push('/?dunno')
            }
        }
    },[])

    return <Grid fluid>
        <Helmet title="Register"/>
        <PageTitle pageLogo={Logo} pageName="TreeHub" pageDetail="Sign Up"/>
        <Col xs={6} xsOffset={3}>
            <RegisterWidget/>
        </Col>
    </Grid>
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        client: state.auth.client,
        email: state.email,
        roles: state.auth.roles,
        password: state.password,
        auth: state.auth,
        state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
