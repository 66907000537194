import {addAlert} from "../App/actions";
import {SIGN_OUT_USER} from "../LoginPage/constants";
import {browserHistory} from "react-router";

export const logout = () => (dispatch, getState) => {
    dispatch(
        addAlert({message: 'Signed out!', mood: 'info', dismissAfter: 1000})
    )
    const store = getState()
    dispatch({
        type: SIGN_OUT_USER,
        isAuthenticated: false,
        client: store.auth.client,
        email: store.auth.email,
        name: '',
        token: '',
        password: '',
    })

    browserHistory.push('/')
}
