import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const getPricesList = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_prices_list`,
        method: 'GET'
    };
    apiCall("Get prices list", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const getStripeSettings = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_stripe_settings`,
        method: 'GET'
    };
    apiCall("Get stripe settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const updateStripeSettings = (applicationPrice, maxUserCount, firstUserPrice, userPrice, isNewPrice, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/update_stripe_settings`,
        method: 'PUT',
        params: {
            application_price: applicationPrice,
            max_user_count: maxUserCount,
            first_user_price: firstUserPrice,
            user_price: userPrice,
            is_new_price: isNewPrice
        }
    };
    apiCall("Application price updated", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const updateSubscriptionPrice = (applicationPrice, newSubscriptionsOnly, newPrice, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/update_subscription_price`,
        method: 'PUT',
        params: {
            application_price: applicationPrice,
            new_subscription_only: newSubscriptionsOnly,
            new_price: newPrice,
        }
    };
    apiCall("Application price updated", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};