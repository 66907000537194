import React from 'react';
import Helmet from "react-helmet";
import PageTitle from "../../components/PageTitle";
import Logo from "../HomePage/HomePageLogo.png";

function TermsAndConditions() {
    return (
        <div>
            <Helmet title="Terms & Conditions"/>
            <PageTitle pageLogo={Logo} pageName="TreeHub" pageDetail="Terms & Conditions"/>
            <div className='padding10'>
                <p className="c40"><span className="c52">TREEHUB SOFTWARE AS A &nbsp;SERVICE AGREEMENT </span>
                </p><p className="c62"><span
                className="c7">IMPORTANT. READ THIS TreeHub SOFTWARE AS A SERVICE &nbsp;AGREEMENT (THIS &ldquo;AGREEMENT&rdquo;) CAREFULLY BEFORE CONTINUING &nbsp;REGISTRATION. </span>
            </p><p className="c33"><span className="c0">By checking the &ldquo;I Accept&rdquo; option to subscribe to TreeHub, LLC&rsquo;s &nbsp;software-as-a-service, you (the &ldquo;Client&rdquo;) are acknowledging that you &nbsp;have read and agree to the terms of service. You are consenting to be &nbsp;bound by and become a party to this Agreement. If you are entering into &nbsp;this Agreement on behalf of a company or other legal entity, you &nbsp;represent and warrant that you have authority to bind such entity to this &nbsp;Agreement. If you do not have such authority, or if you do not agree to &nbsp;all the terms and conditions in this Agreement, you must select the &ldquo;I &nbsp;Decline&rdquo; button and may not use this service. </span>
            </p><p className="c54"><span className="c0">1.</span><span className="c4">Definitions.</span><span
                className="c0">&nbsp;</span></p><p className="c22"><span className="c0">&nbsp;</span><span
                className="c7">1.1 Application means the software and other material, used by TreeHub to &nbsp;access, configure, and provide Services. </span>
            </p><p className="c42"><span className="c7">&nbsp;1.2 Client Data means any data that Client sends to the Service and any &nbsp;data that Client receives from the Service in fulfillment of a request. &nbsp;1.3 Intellectual Property Rights means all Intellectual Property Rights, &nbsp;including patents, trademarks, trade name, service mark, copyright, trade &nbsp;secret, know-how, process, technology, development tool, ideas, concepts, &nbsp;design right, domain names, moral right, database, methodology, algorithm &nbsp;and invention, and any other proprietary information (whether registered, &nbsp;unregistered, pending, or applied for). </span>
            </p><p className="c1"><span className="c7">&nbsp;1.4 Service(s) means software-as-a-service provided by TreeHub, including &nbsp;but not limited to software for tree service companies to MANAGE THEIR &nbsp;BUSINESS. </span>
            </p><p className="c8"><span className="c7">&nbsp;1.5 Users means those individuals authorized byyou or on your behalf to &nbsp;use the services, as defined herein. </span>
            </p><p className="c19"><span className="c7">&nbsp;1.6 You, your, or Client refers to the individual or entity that has ordered &nbsp;software-as-a-service from Tree Data Sytsems, LLC. </span>
            </p><p className="c16"><span className="c0">2.</span><span className="c4">Service.</span><span
                className="c0">&nbsp;</span></p><p className="c59"><span className="c0">&nbsp;</span><span
                className="c7">2.1 </span><span className="c3">TreeHub Obligations. </span><span className="c7">TreeHub agrees, subject to and during the terms &nbsp;of this Agreement: (a) to provide the Service to Client in accordance with this &nbsp;Agreement; (b) to grant or procure a right for Client to access and use the &nbsp;Application as a part of the Service only; (c) to provide ongoing maintenance, &nbsp;upgrades, and enhancements to the Service;(d) to use all commercially </span>
            </p><p className="c17"><span className="c7">reasonable efforts to prevent unauthorized access to, or use of, the Service; &nbsp;and (e) to notify Client promptly of any such unauthorized use which may &nbsp;affect Client. </span>
            </p><p className="c32"><span className="c7">&nbsp;2.2 </span><span
                className="c3">Client Obligations.</span><span className="c7">Client agrees, subject to and during the terms of this &nbsp;Agreement: (a) not to reverse engineer the Application; (b) not to modify, &nbsp;copy, duplicate, reproduce the Software, or allow copies of the Software to be &nbsp;made by others. Client may make copies of the Software for backup purposes &nbsp;only; (c) to use an appropriate integration method for the volume and/or &nbsp;nature of queries to the Service; (d) that it is solely responsible for all of its &nbsp;activities and for the accuracy, integrity, legality, reliability, and &nbsp;appropriateness of all Client Data; (e) to use all commercially reasonable &nbsp;efforts to prevent unauthorized access to, or use of, the Service, and to notify &nbsp;TreeHub of any such unauthorized use; (f) to comply with all applicable laws &nbsp;in using the Service, wherever such use occurs, and not use, or require &nbsp;TreeHub to use, any Client Data obtained via the Service for any unlawful &nbsp;purpose; and (g) to accurately represent Client&rsquo;s use of the Serve and data &nbsp;obtained from the Service. </span>
            </p><p className="c34"><span className="c0">3.</span><span className="c4">Access to the Service, Fees, Term, and Payment.</span><span
                className="c0">&nbsp;</span></p><p className="c15"><span className="c0">&nbsp;</span><span
                className="c7">3.1 </span><span className="c3">Client Accounts.</span><span className="c7">Client must create an account and provide TreeHub &nbsp;with valid contact information prior to receiving access to the Service. Client &nbsp;will be able to create up to three (3) separate logins for one account. If Client &nbsp;desires additional logins, they will be charged an extra fee. </span>
            </p><p className="c9"><span className="c7">&nbsp;3.2 </span><span className="c3">Term. </span><span
                className="c7">The Service is offered on a month-to-month basis. &nbsp;3.3 </span><span
                className="c3">Fees. </span><span className="c7">The monthly fee for the Service is $75.00.A Client will have a &nbsp;&ldquo;Current Account&rdquo; if they pay the monthly fee for that month. This account &nbsp;includes access for one Administrator. Additional access are charged &nbsp;according to the rates on the TreeHub sign up page. </span>
            </p><p className="c36"><span className="c7">&nbsp;3.4 </span><span className="c3">Payment. </span><span
                className="c7">The Service must be paid for via credit card. Client must &nbsp;maintain valid credit card information in the Client file.Client&rsquo;s credit card will &nbsp;be automatically charged monthly on the anniversary of their sign up date for &nbsp;the following month. Client shall be responsible for keeping a current credit &nbsp;card on file. If the Client&rsquo;s card is declined for any reason, TreeHub will &nbsp;contact the Client. The Client will have ten (10) days from the time it is notified &nbsp;of the declined payment to bring its monthly payment current. If the Client &nbsp;does not bring its monthly payment current, the Client Account shall be &nbsp;cancelled. A separate set-up fee of fifty dollars ($50.00) is required to &nbsp;reestablish a cancelled account. </span>
            </p><p className="c58"><span className="c7">&nbsp;3.5 </span><span
                className="c3">Client Information. </span><span className="c7">Any Client with a Current Account can access and &nbsp;download their own Customer proprietary information in a comma separated &nbsp;values (&ldquo;csv&rdquo;) format. This information includes but is not limited to &nbsp;Customers, contacts, work orders, and proposals. Client may download any </span>
            </p><p className="c26"><span className="c7">images or documents that they own; such downloads will be in their original &nbsp;format. </span>
            </p><p className="c31"><span className="c0">4.</span><span className="c4">End of Agreement.</span><span
                className="c0">&nbsp;</span></p><p className="c61"><span className="c0">&nbsp;</span><span
                className="c7">4.1 </span><span className="c3">Cancellation of Service. </span><span className="c7">If you wish to cancel your Client Account and &nbsp;terminate this Agreement, you must do so prior to the first day of your monthly &nbsp;anniversary. TreeHub will not pay any refunds for partial months. &nbsp;4.2 </span><span
                className="c3">Breach. </span><span className="c7">TreeHub reserves the right to terminate this cancel the Client &nbsp;Account and terminate this Agreement for any breaches of this Agreement, &nbsp;within ten (10) days of notice to Client. </span>
            </p><p className="c57"><span className="c7">&nbsp;4.3 </span><span
                className="c3">Termination of Account. </span><span
                className="c7">TreeHub may suspend your password, &nbsp;account, and access to or use of the services (a) if you fail to pay as provided &nbsp;for in this Agreement and do not cure as provided in this Agreement; (b) if you &nbsp;violate any provisions of this Agreement. </span>
            </p><p className="c18"><span className="c7">&nbsp;4.4 A</span><span className="c3">ccount Access Post Termination. </span><span
                className="c7">At your request and for a period of &nbsp;up to sixty (60) days after the termination of the Service, TreeHub may permit &nbsp;you to access the services solely to the extent necessary for you to retrieve a &nbsp;file of your data then in the services environment. You agree and &nbsp;acknowledge that TreeHub has no obligation to retain your data and that your &nbsp;data may be irretrievably deleted after sixty (60) days following the termination &nbsp;of this Agreement. </span>
            </p><p className="c30"><span className="c0">5.</span><span className="c4">Availability, Maintenance, and Technical Support</span><span
                className="c0">&nbsp;</span></p><p className="c43"><span className="c0">&nbsp;</span><span
                className="c7">5.1 A</span><span className="c3">vailability and Maintenance. </span><span
                className="c7">Disruptions in service can happen. &nbsp;TreeHub will use commercially reasonable efforts to make sure the service is &nbsp;back online as soon as possible; however, disruptions in Service can occur. &nbsp;TreeHub is not liable for any damage caused by an interruption in the Service. &nbsp;Downtime for maintenance, upgrades, enhancements, or any other reason, &nbsp;maybe scheduled at any time. </span>
            </p><p className="c41"><span className="c7">&nbsp;5.2 </span><span
                className="c3">Hosting of Service. </span><span className="c7">TreeHub software is hosted in third-party facilities. &nbsp;Currently, TreeHub is using Amazon Cloud Services but this may change &nbsp;without notice from time to time. </span>
            </p><p className="c29"><span className="c7">&nbsp;5.3 </span><span
                className="c3">Technical Support.</span><span className="c7">TreeHub will offer technical and Client support on a &nbsp;first-come, first-served basis during regular business hours, Mountain &nbsp;Standard Time. </span>
            </p><p className="c46"><span className="c0">6.</span><span className="c4">Confidential and Proprietary Information.</span><span
                className="c0">&nbsp;</span></p><p className="c12"><span className="c7">For purposes of this Section, a Party receiving Confidential and Proprietary &nbsp;Information (as defined in this Section) shall be the &ldquo;Recipient&rdquo; and the Party &nbsp;disclosing such information shall be the &ldquo;Discloser.&rdquo; </span>
            </p><p className="c39"><span className="c7">&nbsp;6.1 A</span><span
                className="c3">cknowledgements.</span></p><p className="c2"><span
                className="c7">&nbsp;6.1(a) </span><span className="c24">By Client.</span><span className="c7">Client acknowledges that the Service (including any &nbsp;documentation, source code, translations, compilations, partial copies, &nbsp;derivative works used in connection with the Services) is provided using &nbsp;confidential and proprietary information belonging exclusively to TreeHub. </span>
            </p><p className="c6"><span className="c7">6.1(b) </span><span className="c24">By TreeHub. </span><span
                className="c7">TreeHub acknowledges that Client Data contains &nbsp;confidential and proprietary information that belongs exclusively to Client or &nbsp;relating to Client&rsquo;s affairs (in each case &ldquo;Confidential &amp; Proprietary &nbsp;Information&rdquo;). </span>
            </p><p className="c11"><span className="c7">&nbsp;6.2 </span><span
                className="c3">Not Confidential &amp; Proprietary Information. </span><span className="c7">Confidential and proprietary &nbsp;information does not include (a) information already known or independently &nbsp;developed by Recipient outside the scope of this relationship by personnel not &nbsp;having access to any Confidential &amp; Proprietary Information; (b) information in &nbsp;the public domain through no wrongful act of Recipient; (c) information &nbsp;received by Recipient from a third party who was free to disclose it. &nbsp;6.3 </span><span
                className="c3">Covenant. </span><span className="c7">Recipient agrees that during the Term of this Agreement and &nbsp;at all times after its termination, the Recipient shall not use, commercialize, or &nbsp;disclose such Confidential &amp; Proprietary Information of the Discloser to any &nbsp;person or entity, except as provided in Section 6.4. </span>
            </p><p className="c27"><span className="c7">&nbsp;6.4 </span><span
                className="c3">TreeHub Right to Disclose. </span><span
                className="c7">TreeHub agrees to maintain all of Client&rdquo;s &nbsp;information private and not release any Client information to third parties, &nbsp;except that TreeHub may provide some Client data in an anonymous form to &nbsp;certain parties (for example&cedil; TreeHub may notify a county extension agent or &nbsp;university research laboratory if there is an outbreak of a certain pest or &nbsp;disease in a geographic area); and shall not to sell or distribute Client names, &nbsp;email addresses, or other information to third parties without Client&rdquo;s &nbsp;permission. </span>
            </p><p className="c30"><span className="c0">7.</span><span className="c4">Location Data.</span><span
                className="c0">&nbsp;</span></p><p className="c48"><span className="c7">Once you download the App or log in to the web platform, the App will request &nbsp;access to your device location. If you grant the App access to your device &nbsp;location, we will be able to track the physical location of your device using &nbsp;GPS and WiFi signals. We use this information to provide the TreeHub mobile &nbsp;application and the Service. You can remove the App&rsquo;s access to your device &nbsp;location at any time by changing your privacy settings on your device, and you &nbsp;may also permit the App to only track your location at specific times by &nbsp;changing your privacy settings within the App. Not enabling access to your &nbsp;device location may limit the features and functionality of the Service that you &nbsp;can use. Location data will be available to the Account Owner, as applicable. </span><span
                className="c0">8.</span><span className="c4">Warranty.</span><span className="c0">&nbsp;</span></p>
                <p className="c53"><span className="c7">This Service is subject to a limited warranty. Except as provided in Section4. If &nbsp;the services provided to you for any given month during the Term were not &nbsp;performed as warranted, you must provide written notice to TreeHub </span>
                </p><p className="c60"><span className="c7">addressed to Tree Data Systems, LLC, 591 Two Mile Run, Johns Island, SC &nbsp;29455. Such notice must be postmarked no later than five (5) business days &nbsp;after the last day of that particular month. </span>
            </p><p className="c64"><span className="c0">TreeHub does not guarantee that the service will be performed error free or uninterrupted, or that TreeHub will correct all services errors. You acknowledge that TreeHub does not control the transfer of data over communications facilities&cedil; including the internet, and that the service may be subject to limitations, delays, or other problems inherent in the use of such communications facilities. TreeHub is not responsible for any delays, delivery failures, or other damages resulting from such problems. </span>
            </p><p className="c49"><span className="c0">For any breach of the above warranties, TreeHub will remit a services fee credit to you calculated at ten percent (10%) of the monthly fees for your Service for the month in which the breach occurred. The credit will be provided only towards any outstanding balances for services owed to TreeHub, and the remittance of such credit will represent your exclusive remedy, and TreeHub&rdquo;s sole liability, for all such breaches of any warranty specified in this Agreement. </span>
            </p><p className="c38"><span className="c0">To the extent not prohibited by law, these warranties are exclusive and there are no other express or implied warranties or conditions including for hardware, systems, networks, or environments, or for merchantability, satisfactory quality, and fitness for a particular purpose. </span>
            </p><p className="c23"><span className="c0">9.</span><span className="c4">Mutual Indemnity.</span><span
                className="c0">&nbsp;</span></p><p className="c47"><span className="c7">9.1 </span><span
                className="c3">By TreeHub. </span><span className="c7">TreeHub shall defend, indemnify, and hold Client harmless &nbsp;from any claim including attorney fees) that TreeHub violated Section 6 &nbsp;(&ldquo;Confidential and Proprietary Information&rdquo;) or that the Service in the form &nbsp;provided violates or infringes any Intellectual Property Rights, provided that &nbsp;TreeHub is given prompt notice of the claim and sole control over the defense &nbsp;any settlement thereof (except that TreeHub shall not enter into a settlement &nbsp;prejudicial to Client without Client&rdquo;s consent). </span>
            </p><p className="c28"><span className="c7">9.2 </span><span className="c3">By Client. </span><span
                className="c7">Client shall defend, indemnify, and hold TreeHub harmless &nbsp;from any claim (including attorney fees) that Client violated Section 6 or that &nbsp;Client Data or Client&rdquo;s use of the Service harms any person, volatiles any law, &nbsp;or infringes any Intellectual Property Rights; provided that Client is given &nbsp;prompt notice of the claim and sole control over the defense any settlement &nbsp;thereof (except that Client shall not enter into a settlement prejudicial to &nbsp;TreeHub without TreeHub&rdquo;s consent). </span>
            </p><p className="c5"><span className="c0">10.</span><span
                className="c4">Limitation on Liability.</span><span className="c0">&nbsp;</span></p><p
                className="c55"><span className="c0">Except for indemnities express provided in this Agreement, neither Party is liable for direct damages exceeding one-half (1/2) of the fees</span>
            </p><p className="c21"><span className="c0">paid by Client to TreeHub relating to the twelve (12) month period immediately preceding the last event giving rise to the claim, nor for any indirect, incidental, or consequential damage (including lost profit or business interruptions even if notified in advance of such possibility), arising out of or pertaining to this Agreement. This limitation is independent of remedy limits. </span>
            </p><p className="c25"><span className="c0">11.</span><span className="c4">Notices.</span><span
                className="c0">&nbsp;</span></p><p className="c13"><span className="c7">Notices sent to either Party shall be effective immediately when delivered in &nbsp;person or transmitted by fax machine, one (1) day after being sent by &nbsp;overnight courier, two (2) days after being sent by first-class mail postage &nbsp;prepaid to the physical address provided by the Client. TreeHub&rdquo;s address for &nbsp;notice purposes if 591 Two Mile Run, Johns Island, SC 29455. </span>
            </p><p className="c10"><span className="c0">12.</span><span
                className="c4">Modifications to Agreement.</span><span className="c0">&nbsp;</span></p><p
                className="c37"><span className="c0">&nbsp;</span><span className="c7">12.1 </span><span
                className="c3">Posting of Revised Agreement. </span><span
                className="c7">TreeHub may modify this Agreement &nbsp;by posting a revised version on its website. The revised agreement is effective &nbsp;on the first day of the following month (unless it is posted on the first day of a &nbsp;given calendar month and then it shall be effective that day) unless TreeHub &nbsp;express states otherwise. Continued use of the Service by the Client beyond &nbsp;the Grace Period defined below constitutes acceptance by the Client of the &nbsp;revised agreement. It is the responsibility of the Client to regularly check the &nbsp;posted agreement for changes. </span>
            </p><p className="c35"><span className="c7">&nbsp;12.2 </span><span className="c3">Grace Period. </span><span
                className="c7">Clients that do not accept the revised agreement may &nbsp;notify TreeHub to terminate this Agreement pursuant to Section 3.6. </span><span
                className="c0">13.</span><span className="c4">Entire Agreement.</span><span
                className="c0">&nbsp;</span></p><p className="c51"><span className="c7">This Agreement and the Exhibits hereto constitute the entire agreement &nbsp;between the parties and supersedes all prior agreements, representations, &nbsp;warranties, and understandings of the parties. No supplement, modification, or &nbsp;amendment of this Agreement shall be binding unless executed in writing by &nbsp;both parties.&rdquo; </span>
            </p><p className="c25"><span className="c0">14.</span><span className="c4">Severability.</span><span
                className="c0">&nbsp;</span></p><p className="c20"><span className="c7">If any provision of this Agreement will be found to be illegal or unenforceable, &nbsp;the remaining provisions will continue to be valid and enforceable, and such &nbsp;provision will be deemed severed. </span>
            </p><p className="c5"><span className="c0">15.</span><span
                className="c4">Waiver of Contractual Right.</span><span className="c0">&nbsp;</span></p><p
                className="c45"><span className="c7">The failure of either party to enforce any provision of this Agreement will not &nbsp;be construed as a waiver or limitation of that party&rsquo;s right to subsequently &nbsp;enforce and compel strict compliance with every provision of this Agreement. </span><span
                className="c0">16.A</span><span className="c4">ssignment.</span><span className="c0">&nbsp;</span>
            </p><p className="c56"><span className="c7">Client shall not be permitted to assign or transfer this Agreement or any of the &nbsp;obligations to be performed hereunder without prior consent of TreeHub. </span>
            </p><p className="c44"><span className="c7">TreeHub shall not assign or transfer this Agreement without giving prior &nbsp;written notice to Client. </span>
            </p><p className="c5"><span className="c0">17.A</span><span
                className="c4">pplicable Law and Forum Selection.</span><span
                className="c0">&nbsp;</span></p><p className="c63"><span className="c7">This Agreement will be governed by the laws of the State of South Carolina &nbsp;without regard to its conflict of laws principles. The parties select Charleston, &nbsp;South Carolina as the proper forum under which any dispute shall be &nbsp;submitted. </span>
            </p><p className="c50"><span className="c7">&nbsp;17.1 </span><span
                className="c3">Dispute Resolution</span><span className="c7">. The parties agree that any dispute arising out of or &nbsp;related to this agreement may first be submitted to mediation. If the parties &nbsp;agree to mediation, the dispute shall be submitted to mediation through a &nbsp;mediation provider mutually agreed upon by the parties. Each party agrees to &nbsp;bear its own costs of mediation. If mediation fails or is undesirable, the parties &nbsp;shall submit contested matters to the Legal Resolution Center in Charleston, &nbsp;South Carolina for selection and resolution by a neutral arbitrator pursuant to &nbsp;AAA Rules. Each party will bear its own costs during the arbitration process &nbsp;and the prevailing party shall be entitled to reimbursement of reasonable &nbsp;attorney fees and costs. Nothing in this section shall prohibit either party from &nbsp;seeking emergency equitable relief pending mediation and arbitration.</span>
            </p>
            </div>
        </div>

    );
}

export default TermsAndConditions;