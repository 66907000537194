import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"
import {
  Modal,
  Button,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap"
import Select from "react-select"
import PropTypes from "prop-types"
import {mapForSelect, select} from "../../common/commonHandlers";

class ContactEditorModal extends Component {
  static propTypes = {
    customerId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.editingContactId !== null) {
      this.props.actions.initializeEditor(this.props.customerId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.editingContactId !== nextProps.editingContactId) {
      console.info("Contact Editor Props changed, reinitializing")
      this.props.actions.initializeEditor(nextProps.customerId)
    }
  }

  deleteContact = (contactId) => {
    const action_confirmed = window.confirm('Do you REALLY want to delete this contact???')

    if (action_confirmed) {
      this.props.actions.deleteContact(contactId)
      // this.props.actions.editingSiteId(site_id)
      this._closeModal();
    }
  }


  render() {
    const {
      editingContactId,
      contact,
      contactTypesList,
      billingContact,
      lockBilling,
    } = this.props

    const blank = [null, undefined]
    if (blank.includes(contactTypesList) || blank.includes(contact)) {
      console.log("Not rendering modal yet...")
      return <span>please wait...</span>
    }
    console.log("Rendering Modal")
    const contactTypesListOptions = mapForSelect(contactTypesList);
    return (
      <Modal
        bsSize="large"
        animation={false}
        show={true}
        onHide={this._cancelModal}
        backdrop="static"
      >
        <form onSubmit={this._saveContact}>
          <Modal.Header closeButton>
            <Modal.Title>
              {editingContactId > 0 ? "Editing" : "New"} Contact
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row>
              <Col xs={6}>
                <ControlLabel>
                  Contact Type*
                </ControlLabel>
                {lockBilling
                  ? <p>
                      The contact type cannot be changed for the one and only Billing contact.
                    </p>
                  : <Select className="Select" classNamePrefix="select"
                      name="contact_type_id"
                      onChange={this._updateContactType}
                      placeholder="Contact Type"
                      clearable={false}
                      value={
                        contact && select(contactTypesListOptions, contact.contact_type_id)
                      }
                      options={contactTypesListOptions}
                    />}
              </Col>
              <Col xs={6}>
                {billingContact
                  ? billingContact.id === 0
                      ? "There is no current billing contact."
                      : billingContact.person_id === contact.person_id
                          ? "This is the current billing contact."
                          : `Current Billing Contact: ${billingContact.first_name} ${billingContact.last_name}`
                  : "There is no current billing contact."}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>
                <FormGroup bsSize="small">
                  <ControlLabel>
                    First Name*
                  </ControlLabel>
                  <FormControl
                    placeholder="First Name"
                    name="first_name"
                    onChange={this._updateContactAttr}
                    value={contact ? contact.first_name : ""}
                  />
                </FormGroup>
              </Col>

              <Col xs={6}>
                <FormGroup bsSize="small">
                  <ControlLabel>
                    Last Name
                  </ControlLabel>
                  <FormControl
                    placeholder="Last Name"
                    name="last_name"
                    onChange={this._updateContactAttr}
                    value={contact ? contact.last_name : ""}
                  />
                </FormGroup>

              </Col>
            </Row>

            <Row>
              <Col xs={6}>

                <FormGroup bsSize="small">
                  <ControlLabel>
                    Phone 1
                  </ControlLabel>
                  {contact.person_phone && <a className="ml-4" href={"tel:"+contact.person_phone.replace(/\D/g,'')}
                                              style={{color: "#209f1e"}}><i className="fa fa-phone" aria-hidden="true" style={{color: "#209f1e;"}}/></a>}
                  <FormControl
                    placeholder="Phone"
                    name="person_phone"
                    onChange={this._updateContactAttr}
                    value={
                      contact && contact.person_phone
                        ? contact.person_phone
                        : ""
                    }
                  />
                </FormGroup>

              </Col>

              <Col xs={6}>

                <FormGroup bsSize="small">
                  <ControlLabel>
                    Phone 2
                  </ControlLabel>
                  {contact.person_phone2 && <a className="ml-4" href={"tel:"+contact.person_phone2.replace(/\D/g,'')}
                                               style={{color: "#209f1e"}}><i className="fa fa-phone" aria-hidden="true"/></a>}
                  <FormControl
                    placeholder="Alternate Phone"
                    name="person_phone2"
                    onChange={this._updateContactAttr}
                    value={
                      contact && contact.person_phone2
                        ? contact.person_phone2
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <FormGroup bsSize="small">
                  <ControlLabel>
                    Email 1
                  </ControlLabel>
                  <FormControl
                    placeholder="Email"
                    name="person_email"
                    onChange={this._updateContactAttr}
                    value={
                      contact && contact.person_email
                        ? contact.person_email
                        : ""
                    }
                  />
                </FormGroup>
              </Col>

              <Col xs={6}>
                <FormGroup bsSize="small">
                  <ControlLabel>
                    Email 2
                  </ControlLabel>
                  <FormControl
                    placeholder="Alternate Email"
                    name="person_email2"
                    onChange={this._updateContactAttr}
                    value={
                      contact && contact.person_email2
                        ? contact.person_email2
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

          </Modal.Body>

          <Modal.Footer>
            <Row>
              <Col className="text-left" xs={6}>
                <Button
                  bsSize="xs"
                  bsStyle="danger"
                  onClick={() => this.deleteContact(editingContactId)}
                  disabled={billingContact
                    ? billingContact.person_id === contact.person_id
                        ? true
                        : contact && contact.has_sites
                            ? true
                            : false
                    : false}
                >
                  Delete
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="warning"
                  onClick={this._cancelModal}
                >
                  Cancel
                </Button>
              </Col>

              <Col className="text-right" xs={6}>
                <Button
                  bsSize="small"
                  bsStyle="success"
                  disabled={contact && !contact.__dirty}
                  type="submit"
                >
                  Save Contact
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }

  _updateContactType = contactType => {
    //we get name and value obj. make it look like an event and send.
    const e = {
      target: {
        name: "contact_type_id",
        value: contactType.value,
      },
    }

    this._updateContactAttr(e)
  }

  _updateContactAttr = e => {
    const attr = e.target.name
    const val = e.target.value
    this.props.actions.updateContactAttr(attr, val)
  }

  _saveContact = async e => {
    e.preventDefault()
    // alert("maybe saving contact and calling dismiss callback.")
    //the fields are updated so just send the post/put to rails
    const success = await this.props.actions.saveContact()
    if (success) {
      this.props.onSuccess()
      this.props.actions.editingContactId(null)
    }
  }

  _closeModal = async e => {
      this.props.onSuccess()
      this.props.actions.editingContactId(null)
    }

  _cancelModal = () => {
    console.log("Setting editing to null which should close modal")
    this.props.actions.editingContactId(null)
  }
}

const mapStateToProps = state => {
  return {
    editingContactId: state.contactEditor.editingContactId,
    contactTypesList: state.contactTypes.contactTypesList,
    contact: state.contactEditor.contact,
    billingContact: state.customerInfo.billingContact,
  }
}

const mapDispatchToProps = dispatch => {
  return { actions: bindActionCreators(Actions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditorModal)
