import React, {useEffect, useState} from 'react'
import {Button, Col, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageHeader from "../../../components/PageTitle";
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import {dateFormatter} from "../../../components/BootStrapTableCellFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadsFilterModal from "../../LeadsPage/LeadsFilterModal";
import DeletedProposalsFilters from "./DeletedProposalsFilters";
import * as actions from "./DeletedProposalsApi"
import "./DeletedProposals.css"

const primaryUrl = new URL(window.location.href).origin
const isMobile = window.screen.width <= 1024;

const DeletedProposals = ({actions, name}) => {
        const [deletedProposals, setDeletedProposals] = useState([])
        const [page, setPage] = useState(1)
        const [per_page, setPer_page] = useState(25)
        const [total, setTotal] = useState(0)
        const [isModalOpen, setIsModalOpen] = useState(false)
        const [resource, setResource] = useState({})

        const loadDeletedProposals = () => actions.loadDeletedProposals(resource, page, per_page, res => {
            setDeletedProposals(res.proposals)
            setTotal(res.total)
        })

        const restoreProposal = (proposal_id) => actions.restoreProposal(proposal_id, res => {
            loadDeletedProposals()
        })

        useEffect(() => {
            loadDeletedProposals()
        }, [page, per_page])

        const renderSizePerPageDropDown = (props) => {
            const {toggleDropDown} = props;

            return (
                <SizePerPageDropDown
                    className='my-size-per-page'
                    variation='dropup'
                    onClick={() => this.onToggleDropDown(toggleDropDown)}
                    currSizePerPage={per_page}
                />
            );
        };

        const onChangeFilters = (key, value) => {
            setResource({...resource, [key]: value})
        };

        const toggleModal = (e) => {
            e.preventDefault();
            setIsModalOpen(!isModalOpen);
        };

        const actionsFormatter = (cell, row) => {
            return (
                <div>
                    <button onClick={()=>restoreProposal(row?.id)}>Restore</button>
                </div>

            )
        }

        const proposal_no_formatter = (cell, row) => {
            return (
                <div>
                    <a target="_blank" href={primaryUrl + `/#/proposals/${row?.id}?isDeleted=true`}>#{row.proposalNo}</a>
                </div>
            )
        }

        return (
            <div id='deletedProposalsList'>
                <Row>
                    <Col md={6} className="deleted-proposals-header-wrapper">
                        <PageHeader pageName="Deleted proposals" pageDetail={`${total} records`}/>
                    </Col>
                    <Col md={6} className="justify-flex-end mt10">
                        <Button className="emails_btn"
                                onClick={e => toggleModal(e)}> Filter {" "}
                            <FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <Col md={12} className="remaining vertical-responsive-height no-padding" style={{width: isMobile ? "90vw" : ""}}>
                    <BootstrapTable
                        data={deletedProposals}
                        striped={true}
                        bordered={false}
                        hover={true}
                        pagination={true}
                        remote
                        fetchInfo={{dataTotalSize: total}}
                        className="wrapped word-wrap"
                        options={{
                            onPageChange: (page, per_page) => {
                                setPage(page)
                                setPer_page(per_page)
                            },
                            page,
                            sizePerPageDropDown: renderSizePerPageDropDown,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                }],
                            sizePerPage: per_page,
                        }
                        }
                    >
                        <TableHeaderColumn hidden isKey dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="proposalNo" width={isMobile ? '100px' : '7%'} dataFormat={proposal_no_formatter}>
                            Proposal No.
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="customerName" width={isMobile ? '200px' : '25%'}>
                            Customer Name
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="siteAddress" width={isMobile ? '200px' : '25%'}>
                            Site Address
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="arborist" width={isMobile ? '100px' : '20%'}>
                            Arborist
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="createdDate" width={isMobile ? '100px' : '10%'} dataFormat={dateFormatter}>
                            Created Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="any" width={isMobile ? '100px' : '7%'} dataFormat={actionsFormatter}>
                            Actions
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Col>
                {isModalOpen &&
                <LeadsFilterModal
                    title="Deleted Proposals Filter"
                    show={isModalOpen}
                    onHide={() => {
                        setIsModalOpen(false)
                        page === 1 ? loadDeletedProposals(): setPage(1)
                    }}
                    closeButton={() => setIsModalOpen(false)}
                >
                    <DeletedProposalsFilters
                        resource={resource}
                        onChangeFilters={onChangeFilters}
                    />
                </LeadsFilterModal>
                }
            </div>
        );
    }
;

const mapStateToProps = state => (
    {
        name: state.auth.name,
        client: state.client.customerInfo,
    }
)

const mapDispatchToProps = dispatch => (
    {
        actions: bindActionCreators(actions, dispatch),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(DeletedProposals)