import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Editor as TinyEditor} from "@tinymce/tinymce-react";
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    Panel,
    Row,
    Tab,
    Tabs,
    Well,
} from "react-bootstrap"

import Helmet from "react-helmet"
import * as actions from "./actions"
import Dropzone from "react-dropzone"
import EmailTemplates from "./tabs/EmailTemplates";
import Imports from "./tabs/Imports/Imports";
import Embedded from "./tabs/Embedded";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Client from "./tabs/Client";
import ServiceConfiguration from "./tabs/ServiceConfiguration";
import {getStripeCredentials} from "./tabs/ServiceConfigurationApi";
import UsersList from "./tabs/Users/UsersList";
import UserProfile from "./tabs/UserProfile";
import ManageData from "./tabs/ManageData";
import {debounce} from "throttle-debounce";
import Attachment from "../../components/image/Attachment";
import QuickbooksTab from "./tabs/QuickbooksTab";
import Subscription from "./tabs/Subscription/Subscription";
import GeneralProgress from "../AdminPage/GeneralProgress/GeneralProgress";
import ReportDocuments from "./tabs/ReportDocuments";
import TextMessageTemplates from "./tabs/TextMessageTemplates";
import Documents from "./tabs/Documents/Documents";
import CustomerFeedback from "./tabs/CustomerFeedback";
import {browserHistory} from "react-router";
import config from "../../config/environment";
import {MaterialSwitch} from "../../components/MaterialSwitch/MaterialSwitch";

const Actions = {
    getStripeCredentials,
    ...actions,
};

class Clients extends Component {
    static propTypes = {
        onChange: PropTypes.func,
    };

    tinyMceRef = React.createRef();

    constructor(props, context) {
        super(props, context);
        this.delayedValidateProposalNumber = debounce(500, this.validateProposalNumber);
        this.delayedValidateWoNumber = debounce(500, this.validateWoNumber);
        this.delayedValidateInvoiceNumber = debounce(500, this.validateInvoiceNumber);
    }

    state = {
        activeTab: 0,
        hover: false,
        isUsersTabActive: false,
        q: "",
        key: 0,
        isTiny: false,
        tinyInitialValue: ""
    };

    componentDidMount = () => {
        const clientId = this.props.routeParams.clientId;
        let activeTab = 1
        if (this.props.location.query.tab) {
            activeTab = parseInt(this.props.location.query.tab)
        }
        this.setState({clientId, activeTab});
        this.props.actions.fetchCustomerInfo(clientId)
        this.props.actions.fetchUsers(clientId)
        this.props.actions.fetchEmployees(clientId)
        this.props.actions.fetchRoles()
        this.props.actions.fetchClientImageInfo(clientId)

        if (window.location.href.indexOf("?scope=read_write&code=") > -1) {
            this.props.actions.getStripeCredentials(window.location.href.split('=').pop());
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isTiny !== this.state.isTiny) {
            this.setState({tinyInitialValue: this.state.client?.terms_and_conditions || ""})
        }
    }

    handleSelect = (key) => {
        const clientId = this.props.routeParams.clientId;
        this.setState({key});
        browserHistory.push(`/clients/${clientId}?tab=${key}`)
        if (key === 2) {
            this.setState({isUsersTabActive: true})
        } else
            this.setState({isUsersTabActive: false})
    }

    componentWillReceiveProps = nextProps => {
        if (this.props.client !== nextProps.client) {
            if (nextProps.client.terms_and_conditions) {
                this.updateEditorState(nextProps.client.terms_and_conditions)
            }
            this.setState({client: nextProps.client, isTiny: true});
        }
        if (nextProps.fetchDate !== this.props.fetchDate && this.state.user) {
            const user = nextProps.users.find(u => u.id === this.state.user.id);
            user && this.handleUserSelect(user);
        }
        if (nextProps.deleteDate !== this.props.deleteDate) {
            this.setState({user: null});
        }
    };

    _updateSelectedEmployee = selected => {
        this.state.user.person_id = selected.value
        this.setState({user: this.state.user})
    }

    _updateClientAttr = e => {
        let value = {};
        let name = e.target.name;
        this.state.client[e.target.name] = e.target.value
        this.setState(value, () => {
            if (name === 'proposal_number') {
                this.delayedValidateProposalNumber()
            }
            if (name === 'invoice_number') {
                this.delayedValidateInvoiceNumber()
            }
            if (name === 'work_order_number') {
                this.delayedValidateWoNumber()
            }
        });
    }

    validateProposalNumber = () => {
        this.props.actions.validateProposalNumber(this.state.client.proposal_number, (response) => {
            this.setState({client: {...this.state.client, proposal_number: response.number}})
        })
    }

    validateWoNumber = () => {
        this.props.actions.validateWoNumber(this.state.client.work_order_number, (response) => {
            this.setState({client: {...this.state.client, work_order_number: response.number}})
        })
    }

    validateInvoiceNumber = () => {
        this.props.actions.validateInvoiceNumber(this.state.client.invoice_number, (response) => {
            this.setState({client: {...this.state.client, invoice_number: response.number}})
        })
    }

    toggleHover = () => {
        this.setState({hover: !this.state.hover})
    }

    onEditorStateChange = (editorState) => {
        this.state.client.terms_and_conditions = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({editorState});
        this.setState({client: this.state.client});
    };

    onTinyEditorStateChange = (editorState) => {
        const {client} = this.state
        client.terms_and_conditions = editorState
        this.updateEditorState(editorState)
        this.setState(client);
    };

    updateEditorState(message) {
        const contentBlock = htmlToDraft(message);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({editorState});
    }

    render() {
        const isCrewRole = this.props.roles && !(this.props.roles.length === 1 && this.props.roles[0] === 'crew');

        const {
            client,
            user,
            filterText,
            isTiny
        } = this.state

        let {
            users,
            imageList
        } = this.props

        if (filterText && filterText.length > 0) {
            users = users.filter(u => u.email.includes(filterText))
        }
        if ([null, undefined].includes(client)) {
            return <span>Please wait for client to load...</span>
        }

        const isMobile = window.screen.width < 768;

        return (
            <Grid fluid={!isMobile} style={isMobile ? {paddingLeft: 0, paddingRight: 0} : {}}>
                <Helmet title="Company Info"/>
                <Tabs activeKey={parseInt(this.props.location?.query?.tab) || this.state.key}
                      id="controlled-tab-example"
                      mountOnEnter={true}
                      onSelect={this.handleSelect}
                      animation={false} id="noanim-tab-example">
                    <Tab eventKey={0} title="General Progress">
                        {(this.state.key === 0 || parseInt(this.props.location?.query?.tab) === 0) && <GeneralProgress setActiveTab={this.handleSelect}/>}
                    </Tab>
                    <Tab eventKey={1} title="Organization Info">
                        {(this.state.key === 1 || parseInt(this.props.location?.query?.tab) === 1)  && <Client id={this.props.routeParams.clientId}/>}
                    </Tab>
                    <Tab eventKey={2} title="Users">
                        {(this.state.key === 2 || parseInt(this.props.location?.query?.tab) === 2) && <UsersList isUsersTabActive={this.state.isUsersTabActive} auth={this.props.auth}/>}
                    </Tab>
                    <Tab eventKey={3} title="Preferences">
                        {(this.state.key === 3 || parseInt(this.props.location?.query?.tab) === 3) && <form onSubmit={this._saveAll}>
                            <Grid fluid>
                                <Row>
                                    <Col md={9}>
                                        <Row>
                                            <Well>
                                                <Row>
                                                    <Col md={3}>
                                                        Others
                                                        <Dropzone onDrop={this.props.actions.uploadClientImage}>
                                                            <div>
                                                                <p>Drop a photo here or click to select a file to
                                                                    upload.</p>
                                                            </div>
                                                        </Dropzone>
                                                    </Col>
                                                    <Col md={9}>
                                                        {!imageList || imageList.length === 0 ? "No photos found. Please upload some." : ""}
                                                        {imageList && imageList.map(i => {
                                                            if(i.client_logo === false){
                                                               return <Attachment key={i.id}
                                                                            image={i}
                                                                            setAsMain={this.props.actions.changeClientHeader}
                                                                            allowRemove={true}
                                                                            deleteImage={imageId => {
                                                                                this.props.actions.deleteClientImage(imageId)
                                                                            }}/>
                                                            }

                                                        })}
                                                    </Col>
                                                </Row>
                                            </Well>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Well>
                                                    <h5>
                                                        Content of the proposal will be here. Could be many pages or a
                                                        single page.
                                                    </h5>
                                                </Well>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Panel>
                                                    <FormGroup>
                                                        <ControlLabel>
                                                            Document Footer
                                                        </ControlLabel>
                                                        <FormControl
                                                            name="doc_footer"
                                                            placeholder="Document Footer"
                                                            onChange={this._updateClientAttr}
                                                            value={client.doc_footer}
                                                            componentClass="textarea"
                                                        />
                                                    </FormGroup>
                                                </Panel>
                                            </Col>
                                            <Col md={12}>
                                                <Row className='no-margin'>
                                                    <Panel>
                                                        <p>Company Logo (Customer View)</p>
                                                        <Col md={3}>
                                                            <Dropzone onDrop={this.props.actions.uploadClientImageLogo}>
                                                                <div>
                                                                    <p>Drop a photo here or click to select a file to
                                                                        upload.</p>
                                                                </div>
                                                            </Dropzone>
                                                        </Col>
                                                        <Col md={9}>
                                                            {!imageList || imageList.length === 0 ? "No photos found. Please upload some." : ""}
                                                            {imageList && imageList.map(i => {
                                                                if(i.client_logo === true){
                                                                  return <Attachment key={i.id}
                                                                                image={i}
                                                                                setAsLogo={this.props.actions.changeClientLogo}
                                                                                allowRemove={true}
                                                                                deleteImage={imageId => {
                                                                                    this.props.actions.deleteClientImage(imageId)
                                                                                }}/>
                                                                }
                                                            })}
                                                        </Col>
                                                    </Panel>
                                                </Row>
                                            </Col>

                                            <Col md={12} className="text-right">
                                                <FormGroup bsSize="small">
                                                    {this._renderActionButton()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={3}>
                                        <Row>
                                            <Col md={12}>
                                                <Well>
                                                    <FormGroup bsSize="small">
                                                        <ControlLabel>
                                                            Next Proposal Number
                                                        </ControlLabel>
                                                        <FormControl
                                                            name="proposal_number"
                                                            placeholder="1001"
                                                            onChange={this._updateClientAttr}
                                                            value={client.proposal_number}
                                                        />
                                                    </FormGroup>
                                                </Well>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Well>
                                                    <FormGroup bsSize="small">
                                                        <ControlLabel>
                                                            Next Work Order Number
                                                        </ControlLabel>
                                                        <FormControl
                                                            name="work_order_number"
                                                            placeholder="1001"
                                                            onChange={this._updateClientAttr}
                                                            value={client.work_order_number}
                                                        />
                                                    </FormGroup>
                                                </Well>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Well>
                                                    <FormGroup bsSize="small">
                                                        <ControlLabel>
                                                            Next Invoice Number
                                                        </ControlLabel>
                                                        <FormControl
                                                            name="invoice_number"
                                                            placeholder="1001"
                                                            onChange={this._updateClientAttr}
                                                            value={client.invoice_number}
                                                        />
                                                    </FormGroup>
                                                </Well>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <h5>Scheduler Options</h5>
                                                <Well>
                                                    <FormGroup bsSize="small">
                                                        <ColorCheckbox value={client.scheduler_email_on_save}
                                                                       label='"Send Customer Confirmation Email" on saving new events'
                                                                       onChange={e => {
                                                                           client.scheduler_email_on_save = e;
                                                                           this.setState({client})
                                                                       }}
                                                        />
                                                    </FormGroup>
                                                </Well>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Grid>
                        </form>}
                    </Tab>
                    <Tab eventKey={4} title="Terms & Conditions">
                        {(this.state.key === 4 || parseInt(this.props.location?.query?.tab) === 4) && <form onSubmit={this._saveTermsAndConditions}>
                            <Grid fluid className="top25">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Terms & Conditions
                                            </ControlLabel>
                                            <Row>
                                                <Col xs={12}>
                                                    <MaterialSwitch
                                                        value={isTiny}
                                                        onChange={() => {this.setState({isTiny: !isTiny})}}
                                                        label={"Wysiwyg"}
                                                        label2={"TinyMCE"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} style={{maxHeight: '620px', overflow: "scroll"}}>
                                                    {isTiny ?
                                                        <TinyEditor
                                                            apiKey={config.tinyMceKey}
                                                            onInit={(evt, editor) => this.tinyMceRef.current = editor}
                                                            initialValue={this.state.tinyInitialValue}
                                                            value={this.state.client.terms_and_conditions}
                                                            onEditorChange={(newValue) => this.onTinyEditorStateChange(newValue)}
                                                            init={{
                                                                convert_urls: false,
                                                                height: 600,
                                                                menubar: true,
                                                                plugins: [
                                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                ],
                                                                toolbar: 'undo redo | blocks | ' +
                                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat | help',
                                                                toolbar_mode: 'wrap',
                                                                mobile: {
                                                                    convert_urls: false,
                                                                    menubar: true,
                                                                    toolbar_mode: 'wrap',
                                                                    plugins: [
                                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                    ],
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <Editor
                                                            editorState={this.state.editorState}
                                                            onEditorStateChange={this.onEditorStateChange}
                                                            spellCheck
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Row className="top25 text-center">
                                            <FormGroup bsSize="small" className="text-center">
                                                {this._renderActionButton()}
                                            </FormGroup>
                                        </Row>
                                        <FormGroup style={{maxHeight: '620px', overflow: "scroll"}}>
                                            {/* this is how to use it in print proposal view*/}
                                            <div dangerouslySetInnerHTML={{__html: client.terms_and_conditions}}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="text-right">
                                        <FormGroup bsSize="small">
                                            {this._renderActionButton()}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Grid>
                        </form>}
                    </Tab>
                    <Tab eventKey={5} title="Email templates">
                        {(this.state.key === 5 || parseInt(this.props.location?.query?.tab) === 5) && <EmailTemplates clientId={this.props.routeParams.clientId}/>}
                    </Tab>
                    <Tab eventKey={6} title="Text templates">
                        {(this.state.key === 6 || parseInt(this.props.location?.query?.tab) === 6) && <TextMessageTemplates/>}
                    </Tab>
                    <Tab eventKey={7} title="Service Configuration">
                        {(this.state.key === 7 || parseInt(this.props.location?.query?.tab) === 7) && <ServiceConfiguration id={this.props.routeParams.clientId}/>}
                    </Tab>
                    <Tab eventKey={8} title="Import data">
                        {(this.state.key === 8 || parseInt(this.props.location?.query?.tab) === 8) && <Imports/>}
                    </Tab>
                    <Tab eventKey={9} title="Embedded">
                        {(this.state.key === 9 || parseInt(this.props.location?.query?.tab) === 9) && <Embedded clientId={this.props.routeParams.clientId}
                                                           client_token={this.props.client.client_token}/>}
                    </Tab>
                    {isCrewRole && <Tab eventKey={10} title="Settings">
                        {(this.state.key === 10 || parseInt(this.props.location?.query?.tab) === 10) && <UserProfile/>}
                    </Tab>}
                    <Tab eventKey={11} title="Manage Data">
                        {(this.state.key === 11 || parseInt(this.props.location?.query?.tab) === 11) && <ManageData clientId={this.props.routeParams.clientId}
                                                              client_token={this.props.client.client_token}/>}
                    </Tab>
                    <Tab eventKey={12} title="Quickbooks">
                        {(this.state.key === 12 || parseInt(this.props.location?.query?.tab) === 12) && <QuickbooksTab id={this.props.routeParams.clientId}/>}
                    </Tab>
                    <Tab eventKey={13} title="Subscription">
                        {(this.state.key === 13 || parseInt(this.props.location?.query?.tab) === 13) && <Subscription/>}
                    </Tab>
                    {this.props.client.id === 97 && <Tab eventKey={14} title="Report Documents">
                        {(this.state.key === 14 || parseInt(this.props.location?.query?.tab) === 14) && <ReportDocuments/>}
                    </Tab>}
                    <Tab eventKey={15} title='Documents'>
                        {(this.state.key === 15 || parseInt(this.props.location?.query?.tab) === 15) &&  <Documents currentClient={this.props.client}/>}
                    </Tab>
                    <Tab eventKey={16} title="Customer Feedback">
                        {(this.state.key === 16 || parseInt(this.props.location?.query?.tab) === 16) && <CustomerFeedback client={this.props.client}/>}
                    </Tab>
                </Tabs>
            </Grid>
        )
    }

    handleUserSelect = (e) => {
        e.password_confirmation = ''
        e.password = ''
        this.setState(
            {
                user: Object.assign({}, e)
            }
        )
    }

    _renderActionButton = () => (
        <Button
            type="submit"
            bsSize="xsmall"
            bsStyle="success"
        >
            Save
        </Button>
    )

    _updateCustomer = e => {
        e.preventDefault()
    }

    _saveTermsAndConditions = e => {
        e.preventDefault();
        this.props.actions.saveClientInfo({
            id: this.state.client.id,
            terms_and_conditions: this.state.client.terms_and_conditions
        });
    };

    _saveAll = async e => {
        e.preventDefault();
        this.props.actions.saveClientInfo(this.state.client)
    };

    _saveUser = async e => {
        e.preventDefault();
        if (this.props.actions.validateUser(this.state.user)) {
            this.props.actions.saveUserInfo(this.state.user)
        }
    };

    _updateCustomerAttr = e => {
        const val = e.target.type === "checkbox" ? e.target.checked : e.target.value
        this.props.actions.updateCustomerAttribute(e.target.name, val)

        // update state and city based on zipcode
        if (e.target.name === 'zip' && val.length === 5) {
            this.props.actions.fetchStateAndCity(val)
        }
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        users: state.client.userInfo,
        roles: state.client.roles,
        employees: state.client.employees,
        fetchDate: state.client.fetchDate,
        newUserId: state.client.newUserId,
        deleteDate: state.client.deleteDate,
        imageList: state.client.imagesInfo,
        auth: state.auth
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
