import React, {Component} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Dollars from "../../components/Dollars";
import moment from "moment";
import {defaultDateTimeFormat} from "../../common/commonHandlers";

const isMobile = window.screen.width <= 1024;

export default class ApprovalList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reviewed: new Set(),
            page: 1,
            per_page: 30
        }
    }

    onReviewProposal = (proposal_id) => {
        this.setState({reviewed: this.state.reviewed.add(proposal_id)})
        this.props.reviewProposal(proposal_id)
    };

    amountFormatter = (cell,row) => {
        return <>${cell}</>
    }

    rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{row.proposal_no}</Col>
            </Row>
            <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{row.customer_name}</Col>
            </Row>
            <Row>
                <Col xs={6}>Accepted On</Col>
                <Col xs={6}>{moment(row.when_accepted).format(defaultDateTimeFormat)}</Col>
            </Row>
            <Row>
                <Col xs={6}> Current Status</Col>
                <Col xs={6}>{row.current_status}</Col>
            </Row>
            <Row>
                <Col xs={6}>Amount Accepted</Col>
                <Col xs={6}>{this.amountFormatter(row.accepted_value)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Actions</Col>
                <Col xs={6}>
                    <span>
                        <Button bsStyle="success"
                                onClick={() => this.onReviewProposal(row.proposal_id)}>Review</Button>
                        {' '}
                        <Button bsStyle="warning" disabled={!this.state.reviewed.has(row.proposal_id)}
                                onClick={e => this.props.onApproveProposal(row.proposal_id)}>Approve</Button>
                    </span>
                </Col>
            </Row>
        </div>
    };

    render() {
        const {arboristApprovals, onApproveProposal, onPaginationChange} = this.props
        const {page, per_page} = this.state
        return (

            <div>
                <Row>
                    <Col xs={12}>
                        <h4>{arboristApprovals?.total || 0} Proposals To Approve</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        {!isMobile ? <BootstrapTable
                                ref="table"
                                data={arboristApprovals.data}
                                striped={true}
                                bordered={false}
                                hover={true}
                                remote
                                className="wrapped"
                                fetchInfo={{dataTotalSize: arboristApprovals?.total}}
                                pagination={arboristApprovals && arboristApprovals.data?.length > 0}
                                options={
                                    {
                                        onPageChange: (page, per_page) => {
                                            this.setState({page: page, per_page: per_page})
                                            onPaginationChange(page, per_page)
                                        },
                                        page,
                                        sizePerPageList: [{
                                            text: '10', value: 10
                                        }, {
                                            text: '25', value: 25
                                        },
                                            {
                                                text: '30', value: 30
                                            },
                                            {
                                                text: '50', value: 50
                                            },
                                            {
                                                text: '100', value: 100
                                            },
                                            {
                                                text: '200', value: 200
                                            },
                                            {
                                                text: 'All', value: arboristApprovals?.total
                                            }],
                                        sizePerPage: per_page,
                                    }
                                }
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    width={"5%"}
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataField="proposal_no"
                                    width={"5%"}
                                >
                                    Proposal #
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="customer_name"
                                    width={"15%"}
                                >
                                    Customer
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="when_accepted"
                                    dataFormat={this.dateFormatter}
                                    width={"25%"}
                                >
                                    Accepted On
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="current_status"
                                    width={"15%"}
                                >
                                    Current Status
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="accepted_value"
                                    width={"15%"}
                                    dataFormat={this.amountFormatter}
                                >
                                    Amount Accepted
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    width={"10%"}
                                    dataFormat={(cell, row) => {
                                        return <span>
                                    <Button bsStyle="success"
                                            onClick={() => this.onReviewProposal(row.proposal_id)}>Review</Button>
                                            {' '}
                                            <Button bsStyle="warning" disabled={!this.state.reviewed.has(row.proposal_id)}
                                                    onClick={e => onApproveProposal(row.proposal_id)}>Approve</Button>
                                </span>
                                    }}
                                >
                                    Actions
                                </TableHeaderColumn>
                            </BootstrapTable>
                            :
                            <BootstrapTable
                                ref="table"
                                data={arboristApprovals.data}
                                striped={true}
                                bordered={false}
                                hover={true}
                                remote
                                className="wrapped"
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataField="id"
                                    dataFormat={this.rowFormatter}
                                >
                                </TableHeaderColumn>
                            </BootstrapTable>}
                    </Col>
                </Row>
            </div>
        )
    }
}
