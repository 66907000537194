import React, {useEffect, useState} from "react";
import {Button, Col, FormControl, InputGroup, Modal, Row} from "react-bootstrap";
import QuestionnairePage from "../../../QuestionnairePage/QuestionnairePage";
import Select from "react-select";
import {select} from "../../../../common/commonHandlers";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";

const QuestionnaireModal = ({onHide, onSave, client}) => {
    const [rating, setRating] = useState(1)
    const [googleLink, setGoogleLink] = useState('')

    const ratingOptions = [
        {label: "★", value: 1},
        {label: "★★", value: 2},
        {label: "★★★", value: 3},
        {label: "★★★★", value: 4},
        {label: "★★★★★", value: 5},
    ]

    useEffect(() => {
        if (client.google_review_link) {
            setGoogleLink(client.google_review_link)
        }
    }, [])

    return <Modal
        show={true}
        onHide={onHide}
        bsSize='lg'
        backdrop="static"
        id="questionnaire-modal"
        className="heightAuto fontIOS"
    >
        <Modal.Header>
            <Row>
                <Col xs={6}><h4 className="bold">Questionnaire Edit</h4></Col>
                <Col xs={6} className="justify-flex-end vertical-align">
                    <span className="mr-5">Preview for: </span>
                    <div style={{width: '100px'}}>
                        <Select
                            className="Select"
                            classNamePrefix="select"
                            name="rating"
                            value={select(ratingOptions, rating)}
                            options={ratingOptions}
                            onChange={e => setRating(e.value)}
                        />
                    </div>
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body className="max-height-600">
            <QuestionnairePage
                preview={true}
                currentClient={client}
                onSaveQuestionnaire={onSave}
                givenRating={rating}
            />
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col xs={8} className="justify-flex-end vertical-align">
                    <h5 className="mr-5">Google review link: </h5>
                    <div style={{flexGrow: 1}}>
                        <InputGroup>
                            <InputGroupAddon id="https">
                                https://
                            </InputGroupAddon>
                            <FormControl
                                aria-describedby="https"
                                name="google_review_link"
                                placeholder="Google review link"
                                onChange={e => {
                                    setGoogleLink(e.target.value)
                                }}
                                onBlur={() => {
                                    client.google_review_link = googleLink
                                    onSave(client)
                                }}
                                value={googleLink}
                            />
                        </InputGroup>
                    </div>
                </Col>
                <Col xs={4}>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default QuestionnaireModal;