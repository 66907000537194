import React, {useRef} from "react";
import {Button, Checkbox, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {Link} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const MovedSiteModal = ({onSave, onClose, data, separateProposalServices, onChangeSeparateProposalServices}) => {
    const tableRef = useRef()

    const isExpandableRow = (row) => {
        return row.proposal_services.length > 0;
    }

    const expandComponent = (row) => {
        return <div>
            <Row>
                <Col md={1}/>
                <Col md={6} className="text-center"><b>Service name</b></Col>
                <Col md={2} className="text-center"><b>Status</b></Col>
                {separateProposalServices && <Col md={3} className="text-center"><b>Customer</b></Col>}
            </Row>
            {row?.proposal_services?.map(ps => {
                return <Row>
                    <Col md={1}/>
                    <Col md={6}>{ps.service_name}</Col>
                    <Col md={2} className="text-center">{ps.ps_status}</Col>
                    {separateProposalServices && <Col md={3} className="text-center">{ps.ps_customer_name}</Col>}
                </Row>
            })}
        </div>
    }

    const expandColumnComponent = ({ isExpandableRow, isExpanded }) =>  {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/> );
        } else {
            content = ' ';
        }
        return (
            <div> { content } </div>
        );
    }

    return (
        <Modal
            className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS pointer"}
            bsSize="large"
            show={true}
            onHide={onClose}
        >
            <form onSubmit={onSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Are you sure you want to move this site to customer - <b>{data?.new_customer?.name}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BootstrapTable
                        ref={tableRef}
                        data={data && data.table}
                        striped={true}
                        bordered={false}
                        hover={true}
                        remote
                        className="wrapped"
                        expandableRow={isExpandableRow}
                        expandComponent={expandComponent}
                        expandColumnOptions={ {
                            expandColumnVisible: true,
                            expandColumnComponent: expandColumnComponent
                        } }
                    >
                        <TableHeaderColumn
                            dataField="proposal_no"
                            width={"10%"}
                            isKey
                        >
                            Proposal No
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="title"
                            width={"40%"}
                        >
                            Proposal title
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_status"
                            dataFormat={(cell) => <b>{cell}</b>}
                            width={"15%"}
                        >
                           Status
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="customer_name"
                            width={"15%"}
                        >
                            Customer
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataFormat={(cell) => <Link target="_blank" to={`/mapview/${cell}`} bla>Show Proposal</Link>}
                            width={"20%"}
                        />
                    </BootstrapTable>
                </Modal.Body>

                <Modal.Footer>
                    <Col md={12} className="space-between-end">
                        <Checkbox
                            checked={separateProposalServices}
                            onChange={onChangeSeparateProposalServices}
                            inline
                        >

                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="separate_proposal_services_tooltip">
                                        Tick this checkbox if you want Proposals to be split. Proposals with status Paid, Payment and Invoiced will remain with the current customer and the rest will be assigned to <b>{data?.new_customer?.name}</b>. as new Proposals
                                    </Tooltip>}
                            >
                                <span>Separate Proposal Services</span>
                            </OverlayTrigger>
                        </Checkbox>
                        <Button
                            bsSize="small"
                            bsStyle="success"
                            type="submit"
                        >
                            Save Site
                        </Button>
                    </Col>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default MovedSiteModal;