import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {browserHistory} from 'react-router'

import * as AlertActions from '../App/actions'
import Secured from "../../common/Secured";

const Actions = Object.assign({}, AlertActions)

export default function (WrappedComponent, roles = null) {

    class Auth extends Component {

        componentWillMount() {
            if (this.props.location.pathname.includes('/customer_view/') || this.props.location.pathname.includes('/cv/') || this.props.location.pathname.includes('/questionnaire/') || this.props.location.pathname.includes('/oauth_page') || this.props.location.pathname.includes('/reset_password')) {
                console.log("user does not need the authentication");
            } else if (!this.props.isAuthenticated) {
                const url = window.location.href
                console.info("User is unauthenticated. RequireAuth will not mount protected component. Redirecting to /login route")
                this.props.actions.addAlert({
                    message: "Login required to view this module.",
                    dismissAfter: 1000,
                    mood: 'warning'
                })
                browserHistory.push(`/login?url=${url.slice(url.indexOf('#') + 1)}`)
            } else {
                console.log("user is authenticated. Will not block render of wrapped component.")
            }
        }

        render() {
            return roles ?
                <Secured roles={roles}>
                    <WrappedComponent {...this.props} />
                </Secured>
                :
                <WrappedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            roles: state.auth.roles
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(Actions, dispatch)
        }
    }


    return connect(mapStateToProps, mapDispatchToProps)(Auth)
}
