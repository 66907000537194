import React, {useEffect, useState} from 'react';
import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import './TrucksFilterModal.scss'
import Select from "react-select";
import {defaultDateFormat, select} from "../../common/commonHandlers";


const TrucksFilterModal = ({isOpen, closeModal, filterData, initialFilter, data, modalBuilder}) => {
    const [filter, setFilter] = useState({})

    useEffect(() => setFilter(initialFilter), [])

    const handleClearFilter = () => {
        setFilter({})
    }

    const handleFilterChange = (filter) => {
        filterData(filter)
        closeModal()
    }

    const handleInputChange = (e, fieldName, edge) => {
        if (filter[fieldName]) {
            setFilter({
                ...filter, [fieldName]: {
                    value: {
                        from: edge === 'from' ? Number(e.target.value) : filter[fieldName].value.from,
                        to: edge === 'to' ? Number(e.target.value) : filter[fieldName].value.to
                    },
                    type: 'number'
                }
            })
        } else {
            setFilter({
                ...filter, [fieldName]: {
                    value: {
                        from: edge === 'from' ? Number(e.target.value) : null,
                        to: edge === 'to' ? Number(e.target.value) : null
                    },
                    type: 'number'
                }
            })
        }

    }

    const handleDateChange = (e, fieldName, edge) => {
        if (filter[fieldName]) {
            setFilter({
                ...filter, [fieldName]: {
                    value: {
                        from: edge === 'from' ? e : filter[fieldName].value.from,
                        to: edge === 'to' ? e :  filter[fieldName].value.to
                    },
                    type: 'date'
                }
            })
        } else {
            setFilter({
                ...filter, [fieldName]: {
                    value: {
                        from: edge === 'from' ? e : '',
                        to: edge === 'to' ? e : ''
                    },
                    type: 'date'
                }
            })
        }

    }

    const handleSelect = (e, fieldName) => {
        if (e && e.constructor.name === 'Array') {
            setFilter({
                ...filter, [fieldName]: {
                    value: (e.map(x => x.value)),
                    id: (e.map(x => x.id)),
                    type: 'multiselect'
                }
            })
        } else {
            setFilter({
                ...filter, [fieldName]: {
                    value: (e ? e.value : null),
                    id: (e ? e.id : null),
                    type: 'multiselect'
                }
            })
        }

    }

    return (
        <Modal
            bsSize="small"
            animation={false}
            centered={true}
            show={isOpen}
            onHide={closeModal}
            backdrop="static"
            dialogClassName="truck-filter-modal"
        >
            <Modal.Header>
                <div className='modal_header'>
                    <p>Truck Filter Modal</p>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        onClick={handleClearFilter}
                    >
                        Clear filters
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {modalBuilder.map((mb) => {
                    if (mb.field_type === "date") {
                        return <FormGroup key={mb.field_name}>
                            <ControlLabel>
                                <div className='filter-header'>{mb.field_name.split('_').map(i => <span>{i} {' '}</span>)}</div>
                            </ControlLabel>
                            <div className='filter-dates'>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={filter[mb.field_name]?.value.from ? moment(filter[mb.field_name]?.value.from) : ""}
                                    className="text-center full-width"
                                    inputProps={{placeholder: `${mb.field_name}_from`}}
                                    onChange={(e) => handleDateChange(e, mb.field_name, 'from')}/>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={filter[mb.field_name]?.value.to ? moment(filter[mb.field_name]?.value.to) : ""}
                                    className="text-center, full-width"
                                    inputProps={{placeholder: `${mb.field_name}_to`}}
                                    onChange={(e) => handleDateChange(e, mb.field_name, 'to')}/>
                            </div>
                        </FormGroup>
                    } else if (mb.field_type === 'multiselect') {
                        return <FormGroup key={mb.field_name}>
                            <ControlLabel>
                                <div className='filter-header'>{mb.field_name.split('_').map(i => <span>{i} {' '}</span>)}</div>
                            </ControlLabel>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(data[`${mb.field_name}${mb.field_name.charAt(mb.field_name.length - 1) === 's' ? 'es' : 's'}`], filter[mb.field_name]?.value)}
                                    options={data[`${mb.field_name}${mb.field_name.charAt(mb.field_name.length - 1) === 's' ? 'es' : 's'}`]}
                                    isMulti
                                    isClearable
                                    onChange={(e) => handleSelect(e, mb.field_name)}
                                    placeholder={`${mb.field_name}`}
                            />
                        </FormGroup>

                    } else {
                        return <FormGroup key={mb.field_name}>
                            <ControlLabel>
                                <div className='filter-header'>{mb.field_name.split('_').map(i => <span>{i} {' '}</span>)}</div>
                            </ControlLabel>
                            <div className='filter-numbers'>
                                <FormControl
                                    type='number'
                                    placeholder={`${mb.field_name}_from`}
                                    name={`${mb.field_name}_from`}
                                    onChange={(e) => handleInputChange(e, mb.field_name, 'from')}
                                    value={filter[mb.field_name]?.value.from || ''}
                                />
                                <FormControl
                                    type='number'
                                    placeholder={`${mb.field_name}_to`}
                                    name={`${mb.field_name}_to`}
                                    onChange={(e) => handleInputChange(e, mb.field_name, 'to')}
                                    value={filter[mb.field_name]?.value.to || ''}
                                />
                            </div>
                        </FormGroup>
                    }

                })}
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col className="text-left" xs={6}>
                        <Button
                            bsSize="small"
                            bsStyle="warning"
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>
                    </Col>

                    <Col className="text-right" xs={6}>
                        <Button
                            bsSize="small"
                            bsStyle="success"
                            onClick={() => handleFilterChange(filter)}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
};

export default TrucksFilterModal;