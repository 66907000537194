import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import * as FileSaver from "file-saver";
import moment from "moment";
import {defaultDateFormat} from "../../common/commonHandlers";

export const getRevenueReport = (params) => (dispatch, getState) => {
    const config = {
        url: `/api/revenues/generate_report`,
        params: params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `${params.report_type}_${params.from ? moment(params.from, 'DD-MM-YYYY').format(defaultDateFormat) : 'no_set'}_${params.to ? moment(params.to, 'DD-MM-YYYY').format(defaultDateFormat) : 'no_set'}.csv`);
    }, dispatch, getState);
};