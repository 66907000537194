import axios from "axios/index";
import {mapForSelect} from "../../common/commonHandlers";
import {apiCall} from "../../common/apiActionHandler";

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getCustomerStats = (customerId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/customer_stats?id=${customerId}`
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/referrals`, params: {disabled: false}},
        {url: `/api/customers/name_zip_city_search`, params: {q: ''}},
        {url: `/api/user_profile`},
        {url: `/api/reasons`, params: {disabled: false}},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            referrals: mapForSelect(all[1].data),
            customerSearchResults: all[2].data,
            user: all[3].data,
            reasons: mapForSelect(all[4].data),
        });
    }, dispatch, getState, false);
};

export const saveLead = (lead, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/leads/${lead.id || ''}`,
        data: {lead},
        method: lead.id ? 'PUT' : 'POST'
    };

    apiCall("Saving lead", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const getCustomer = (id, callback) => (dispatch, getState) => {
    apiCall("Loading customer",
        [
            {url: `/api/customers/name_zip_city_search`, params: {id}},
            {url: `/api/customers/customer_stats`, params: {id}}
        ],
        async config => {
            let actions = config.map(c => axios.request(c));
            const all = await axios.all(actions);
            const customerSearchResults = all[0].data;
            const stats = all[1].data;
            callback({customerSearchResults, stats});
        }, dispatch, getState, false);
};

export const getLead = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/leads/${id}`
    };

    apiCall("Getting lead", config, async config => {
        const response = await axios.request(config);
        const lead = response.data;
        getCustomer(lead.customer_id, result => {
            callback({resource: lead, ...result});
        })(dispatch, getState);
    }, dispatch, getState, false);
};

export const remove = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${id}`,
        method: 'DELETE'
    };

    apiCall("Deleting scheduler event", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};