export const [
  EDITING_SITE_ID,
  FETCH_SITE,
  CREATE_NEW_SITE,
  UPDATE_SITE_ATTR,
  FETCH_SITE_CONTACT,
  FETCH_STATE_AND_CITY,
  DELETE_SITE,
  FETCH_SEARCHED_CUSTOMERS_MODAL,
] = [
  "EDITING_SITE_ID",
  "FETCH_SITE",
  "CREATE_NEW_SITE",
  "UPDATE_SITE_ATTR",
  "FETCH_SITE_CONTACT",
  "FETCH_STATE_AND_CITY",
  "DELETE_SITE",
  "FETCH_SEARCHED_CUSTOMERS_MODAL",
]

export const boxesPerRowReorderingDND = {
  desktop: {
    columns: 8,
    minWidth: 1850
  },
  laptop: {
    columns: 7,
    minWidth: 1440
  },
  tablet: {
    columns: 6,
    minWidth: 1065
  },
  bigMobile: {
    columns: 4,
    minWidth: 768
  },
  mobile: {
    columns: 4,
    minWidth: 450
  },
  smallMobile: {
    columns: 3,
  }
}