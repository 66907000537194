import React, {useEffect} from 'react'
import {DropdownButton, MenuItem} from "react-bootstrap"
import './CustomerViewMenu.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faClipboardList,
    faListAlt,
    faMapMarkedAlt,
    faFileInvoiceDollar, faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {LinkContainer} from "react-router-bootstrap";
import {bindActionCreators} from "redux";
import * as Actions from "./actions";
import {connect} from "react-redux";
import {logout} from "../LoginPage/actions";
const actions = {...Actions, logout};

export const CustomerViewMenu = ({token, site, actions, isCustomerAuthenticated}) => {

    useEffect(() => {
        if (site.loaded) return
        actions.setSelectedSite(null, token)
    }, [])

    const menuItems = [
        {label: 'Sites', icon: faMapMarkedAlt},
        {label: 'Proposals', icon: faListAlt},
        {label: 'Invoices', icon: faFileInvoiceDollar},
        {label: 'Messages', icon: faClipboardList}
    ]
    return (
        <DropdownButton className='vhCenter no-caret-in-dropdown vertical-align font12'
                        title={<FontAwesomeIcon className='font18' icon={faBars}/>}
                        id="bg-justified-dropdown">
            {menuItems.map(({label, icon}, i) => {
                if (label) {
                    return <LinkContainer to={`/customer_view/${token}/${label.replace(/ /g, "_").toLowerCase()}`}>
                        <MenuItem eventKey={i + 1}>
                            <div>
                                <FontAwesomeIcon className='font18 mr-15' icon={icon}/>
                                <span>{label}</span>
                            </div>
                        </MenuItem>
                    </LinkContainer>
                }
            })}
            {isCustomerAuthenticated && <LinkContainer to={''} active={false}>
                <MenuItem eventKey={6} onClick={() => actions.logout('/customer_view/logout')}>
                    <div>
                        <FontAwesomeIcon className='font18 mr-15' icon={faSignOutAlt}/>
                        <span>Logout</span>
                    </div>
                </MenuItem>
            </LinkContainer>}
        </DropdownButton>
    )


}

const mapStateToProps = state => {
    return {
        site: state.customerView.site,
        isCustomerAuthenticated: state.auth.isCustomerAuthenticated
    }
}

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewMenu);