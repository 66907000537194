import React, {useEffect, useRef, useState} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import {Col, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import PageTitle from "../../components/PageTitle"
import moment from "moment"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {dateFormatter, dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import "./WorkOrderListPage.scss"
import * as Actions from '../../actions/workOrders'

const isMobile = window.screen.width <= 1024;

const WorkOrdersListPage = ({actions}) => {
    const [workOrders, setWorkOrders] = useState(null)
    const tableRef = useRef()

    useEffect(() => {
        actions.fetchWorkOrders(res => {
            setWorkOrders(res)
        })
    }, [])

    const customerNameFormatter = (cell, row) => (
        <div>
            <Nav>
                <NavDropdown
                    className="no-caret-in-dropdown"
                    eventKey={2}
                    title={row.name}
                >
                    <LinkContainer to={`/customer/info/${row.customer_id}`}>
                        <MenuItem bsSize="small">Customer Info</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                        <MenuItem bsSize="small">Customer Proposals</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                        <MenuItem bsSize="small">Customer Sites</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                        <MenuItem bsSize="small">Customer Work Orders</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                        <MenuItem bsSize="small">Customer Work History</MenuItem>
                    </LinkContainer>
                </NavDropdown>
            </Nav>

        </div>
    )

    const addressFormatter = (cell, row) => (
        <div>
            {row.site_address}, {" "} {row.site_city} {" "} {row.zip}
        </div>
    )

    const proposalNoFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                bsSize="small"
                bsStyle="default"
                title={row.proposal_no || "..."}
                id={`proposal-menu-${row.id}`}
                className="no-caret-in-dropdown"
            >
                <LinkContainer target="_blank" to={`/mapview/${row.proposal_id}`}>
                    <MenuItem>Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer
                    target="_blank"
                    to={`/proposal_service_status/${row.proposal_id}`}
                >
                    <MenuItem>Edit Service Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer target="_blank" to={`/print_proposal/${row.proposal_id}`}>
                    <MenuItem>Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    const workOrderNoFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                bsSize="small"
                bsStyle="default"
                title={row.work_order_no || "..."}
                id={`wo-menu-${row.id}`}
                className="no-caret-in-dropdown"
            >
                <LinkContainer to={`/work_orders/${row.proposal_id}`}>
                    <MenuItem>Manage WO</MenuItem>
                </LinkContainer>
                <LinkContainer

                    to={`/complete_work_orders/${row.work_order_no}`}
                >
                    <MenuItem>Complete WO</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_work_orders/${row.work_order_no}`}>
                    <MenuItem>Print Work Order</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    const _eventFormatter = (cell, row) => {
        if (row.events && row.events.length > 1) {
            return <div className="columnDirection">{row.events.map(ps => ps !== "" ?
                <span>{moment(ps).format("MM/DD/YY")}</span> : '')}</div>;
        }
        if (row.events && row.events[0]) {
            return <div className="columnDirection"><span>{moment(row.events[0]).format("MM/DD/YY")}</span>
            </div>;
        }
        return null
    };

    const promisedStartDateFormatter = (cell, row) => {
        if (row.promisedStart && row.promisedStart.length > 1) {
            return <div className="columnDirection">{row.promisedStart.map(ps => ps !== "" ?
                <span>{moment(ps).format("MM/DD/YY")}</span> : '')}</div>;
        }
        if (row.promisedStart && row.promisedStart[0].length > 0) {
            return <div className="columnDirection"><span>{moment(row.promisedStart[0]).format("MM/DD/YY")}</span></div>;
        }
        return null
    };

    const promisedEndDateFormatter = (cell, row) => {
        if (row.promisedEnd && row.promisedEnd.length > 1) {
            return <div className="columnDirection">{row.promisedEnd.map(ps => ps !== "" ?
                <span>{moment(ps).format("MM/DD/YY")}</span> : '')}</div>;
        }
        if (row.promisedEnd && row.promisedEnd[0].length > 0) {
            return <div className="columnDirection"><span>{moment(row.promisedEnd[0]).format("MM/DD/YY")}</span></div>;
        }
        return null
    };

    const rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Customer Name</Col>
                <Col xs={6}>{customerNameFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Address</Col>
                <Col xs={6}>{addressFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Zip</Col>
                <Col xs={6}>{row.zip}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{proposalNoFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>WO #</Col>
                <Col xs={6}>{workOrderNoFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Accepted Date</Col>
                <Col xs={6}>{dateFormatter(row.accepted_date)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Promised From</Col>
                <Col xs={6}>{promisedStartDateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Promised To</Col>
                <Col xs={6}>{promisedEndDateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Scheduled on</Col>
                <Col xs={6}>{_eventFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Hours</Col>
                <Col xs={6}>{row.hours}</Col>
            </Row>
            <Row>
                <Col xs={6}>Cost</Col>
                <Col xs={6}>{dollarsFormatter(row.cost)}</Col>
            </Row>
        </div>
    };

    const rowStyleFormat = (cell, row) => {
        return {
            background: workOrders && workOrders.map((work_order, i) => work_order.inactive === true ? i : -1).filter(index => index !== -1).includes(row) ? '#EBEBE4' : null
        }
    };

    const renderWorkOrders = (workOrders) => {
        if (workOrders === null) {
            return null
        }

        return (
            isMobile ? <BootstrapTable
                    ref={tableRef}
                    data={workOrders}
                    striped={true}
                    bordered={false}
                    hover={true}
                    remote
                    className="wrapped"
                >
                    <TableHeaderColumn
                        dataField="id"
                        hidden
                        isKey
                    />
                    <TableHeaderColumn
                        dataField="id"
                        dataFormat={rowFormatter}
                    >
                    </TableHeaderColumn>
                </BootstrapTable>
                :
                <BootstrapTable
                    ref={tableRef}
                    data={workOrders}
                    striped={true}
                    bordered={false}
                    hover={true}
                    trStyle={(cell, row) => rowStyleFormat(cell, row)}
                >
                    <TableHeaderColumn hidden isKey dataField="id">
                        Wo Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="12%"
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        columnClassName="menu-show-fixer"
                        dataFormat={customerNameFormatter}
                        dataField="name"
                    >
                        Customer Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="15%"
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        dataFormat={addressFormatter}
                        dataField="site_address"
                    >
                        Address
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="5%"
                        dataField="zip"
                    >
                        Zip
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="7%"
                        dataField="arborist"
                        tdStyle={{whiteSpace: 'normal'}}
                    >
                        Arborist
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="5%"
                        columnClassName="menu-show-fixer"
                        dataFormat={proposalNoFormatter}
                        dataField="proposal_no"
                    >
                        Proposal #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="5%"
                        columnClassName="menu-show-fixer"
                        dataFormat={workOrderNoFormatter}
                        dataField="work_order_no"
                    >
                        WO #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10%"
                        dataFormat={dateFormatter}
                        dataField="accepted_date"
                        tdStyle={{whiteSpace: 'normal'}}

                    >
                        Accepted Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10%"
                        dataFormat={"promisedStart" ? promisedStartDateFormatter : null}
                        dataField={"promisedStart"}
                    >
                        Promised From
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10%"
                        dataFormat={"promisedEnd" ? promisedEndDateFormatter : null}
                        dataField={"promisedEnd"}
                    >
                        Promised To
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10%"
                        dataFormat={_eventFormatter}
                        dataField={"scheduledLastEvent"}
                    >
                        Scheduled On
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="5%"
                        dataField="hours"
                    >
                        Hours
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="8%"
                        dataFormat={dollarsFormatter}
                        dataField="cost"
                    >
                        Cost
                    </TableHeaderColumn>
                </BootstrapTable>
        )
    }

    return (
        <Grid fluid id="work-order-list-page">
            <Helmet title="Recent Work Orders"/>
            <PageTitle pageName="Recent Work Orders" pageDetail="the last 50..."/>

            <Row>
                <Col xs={12}>
                    {workOrders && workOrders.length > 0 ? renderWorkOrders(workOrders) : null}
                </Col>

            </Row>
        </Grid>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(undefined, mapDispatchToProps)(WorkOrdersListPage)
