import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const loadDeletedProposals = (resource, page, per_page, callback) => (dispatch, getState) => {
    const config = {
        method: "get",
        url: `/api/proposals/get_deleted`,
        params: {...resource, page, per_page}
    };

    apiCall("Getting deleted proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const restoreProposal = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/proposals/restore`,
        params: {
            id: id
        }
    };

    apiCall("Restoring proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true);
};