import {FETCH_STICKIES_SUCCESS, UPDATE_NEW_STICKY_BODY, CREATE_STICKY_SUCCESS, REPLY_STICKY_SUCCESS, UPDATE_REPLY_STICKY_BODY} from './constants'

import {addAlert} from '../App/actions'
import axios from 'axios'

export function fetchStickies(proposalId){
  return function (dispatch, getState) {
    const store = getState()

    const config = {
      method: 'get',
      url: '/api/stickies',
      headers: {'Authorization': store.auth.token},
      params: {
        proposal_id: proposalId,
      }
    }

    axios.request(config)
    .then(function(response){

      dispatch({
        type: FETCH_STICKIES_SUCCESS,
        stickies: response.data,
        proposalId: proposalId,
      })
    })
    .catch(function(error){
      dispatch(addAlert({message: "Error fetching stickies!", mood: "danger", dismissAfter: 3500}))
    })
  }
}


export function updateNewStickyBody(newStickyBody){
  return {type: UPDATE_NEW_STICKY_BODY, newStickyBody}
}

export function updateReplyStickyBody(replyStickyBody, stickyReplyingTo){
  return {type: UPDATE_REPLY_STICKY_BODY, replyStickyBody, stickyReplyingTo } ;//use shortcut array destructuring.
  //also improves clarity of params meaning
}


export function createNewSticky(){
  return function (dispatch, getState) {
    const store = getState()

    const config = {
      method: 'post',
      url: '/api/stickies',
      headers: {'Authorization': store.auth.token},
      data: {
        stickable_id: store.stickies.proposalId,
        body: store.stickies.newStickyBody,
      }
    }

    axios.request(config)
    .then(function(response){
      dispatch({
        type: CREATE_STICKY_SUCCESS,
        newStickyBody: ''
      })
      dispatch(addAlert({message: "Created Sticky!", mood: "success", dismissAfter: 500}))
      dispatch(fetchStickies(store.stickies.proposalId))
    })
    .catch(function(error){
      dispatch(addAlert({message: "Error creating sticky!", mood: "danger", dismissAfter: 3500}))
    })
  }
}

export function replyToSticky(){
  return function (dispatch, getState) {
    const store = getState()

    const config = {
      method: 'post',
      url: '/api/stickies',
      headers: {'Authorization': store.auth.token},
      data: {
        stickable_id: store.stickies.proposalId,
        body: store.stickies.replyStickyBody,
        initial_id: store.stickies.stickyReplyingTo
      }
    }

    axios.request(config)
    .then(function(response){

      dispatch({
        type: REPLY_STICKY_SUCCESS,
        stickyReplyingTo: null,
        replyStickyBody: ''
      })
      dispatch(fetchStickies(store.stickies.proposalId))
      dispatch(addAlert({message: "Replied To Sticky!", mood: "success", dismissAfter: 500}))
    })
    .catch(function(error){
      dispatch(addAlert({message: "Error replying to sticky!", mood: "danger", dismissAfter: 3500}))
    })
  }
}
