import {FETCH_SERVICE_TYPES_LIST} from '../constants'

const initialState = {
    serviceTypesList: [],
    serviceTypes: {},
    serviceTypesListLastUpdated: 0,
    editingServiceTypeId: null,
    filterText: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SERVICE_TYPES_LIST:
            return {
                ...state,
                serviceTypesList: action.serviceTypesList,
                serviceTypesListLastUpdated: action.serviceTypesListLastUpdated,
            };
        default:
            return state
    }
}
