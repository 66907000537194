import React from 'react';
import {Col, FormGroup, Row, FormControl} from "react-bootstrap";
import Select from "react-select";
import moment from 'moment'
import {defaultDateFormat, defaultTimeFormat, select} from "../../../../common/commonHandlers";
import Datetime from "react-datetime";
import ResourceComponent from "../../../../components/ResourceComponent";

class ClientTransactionsFilters extends ResourceComponent {

    handleChangeCreatedFrom = (e, date) => {
        let {resource} = this.props;
        if (resource[date] === null) {
            resource[date] = moment(e).startOf('day');
        } else {
            resource[date] = moment(e).startOf('day');
        }
        this.setState(resource, this.search);
    };

    handleChangeCreatedTo = (e, date) => {
        let {resource} = this.props;
        if (resource[date] === null) {
            resource[date] = moment(e).endOf('day');
        } else {
            resource[date] = moment(e).endOf('day');
        }
        this.setState(resource, this.search);
    };

    render() {
        const {onChangeFilters, statuses, resource, selectAttr} = this.props;
        const {invoice_number, created_from, created_to, amount_from, amount_to} = resource;

        return (
            <div>
                <Row className="mt-15">
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                type="number"
                                name="invoice_number"
                                placeholder="Invoice"
                                value={invoice_number}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    placeholder="Status"
                                    isClearable
                                    value={select(statuses, resource.status)}
                                    options={statuses}
                                    onChange={selectAttr}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Datetime
                            dateFormat={defaultDateFormat}
                            timeFormat={defaultTimeFormat}
                            value={created_from}
                            className="created_from"
                            inputProps={{placeholder: 'Created from'}}
                            closeOnSelect
                            onChange={e => this.handleChangeCreatedFrom(e, 'created_from')}
                        />
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={created_to}
                                name="created_to"
                                className="created_to"
                                inputProps={{placeholder: 'Created to'}}
                                closeOnSelect
                                onChange={e => this.handleChangeCreatedTo(e, 'created_to')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr className={'marginBottom10'}/>
                <Row className="mt-15">
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                type="number"
                                placeholder="Amount from"
                                name="amount_from"
                                value={amount_from}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                type="number"
                                placeholder="Amount to"
                                name="amount_to"
                                value={amount_to}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    };
}

export default ClientTransactionsFilters;