import React, {useState, useEffect} from 'react'
import moment from "moment";
import InlineEditable from "../Scheduler/InlineEditable";
import TextareaAutosize from "react-textarea-autosize";
import {faCheck, faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {MediaComponent} from "../Scheduler/CrewMessages/MediaComponent";
import {ImageLightBox} from "../Scheduler/CrewMessages/ImageLightBox";
import {DocAttachment} from "../../components/docs/DocAttachment";

export const CrewWorkOrderMessage = ({message, employees, markAsRead, markThreadAsRead, getUserStatus, getMessages, showWorkOrderThread, type}) => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [isImageModalOpened, setIsImageModalOpened] = useState(false)

    const renderTooltip = (name) => (
        <Tooltip id="tooltip">
            <strong>{name}</strong>
        </Tooltip>
    );

    const employee = employees?.length > 0 && employees.find(e => e.value === message.from)
    const background = employee ? employee.color : '#3a87ad'

    const onSelectImage = src => {
        setSelectedImage(src)
    }

    const toggleImageModal = (value) => {
        setIsImageModalOpened(value)

        if (value !== true) {
            setSelectedImage(null)
        }
    }

    if (!message) {
        return null
    } else return (
        <div className='message-item' key={message.id}>
            <div className='message-content'>
                <div className='space-between-end'>
                    <OverlayTrigger placement="top" overlay={renderTooltip(employee?.label)}>
                        <div className='avatar' style={{background}}>{employee?.initials}</div>
                    </OverlayTrigger>
                    <div>
                        <div
                            className='message-content-date text-right'>{moment(message.created_at).format('llll')}</div>
                        <div className='vcenter full-width d-flex'>
                            <div md={6} className='text-center colorRed font11' onClick={() => {
                                if (type === 'unread') {
                                    markThreadAsRead(message.work_order_id, () => {
                                        getMessages(type)
                                    })
                                }
                                showWorkOrderThread(message.work_order_id)}
                            }>
                                Go to thread: <span className='message-thread'>{message.work_order_no}</span>
                            </div>
                            <div className='mark_as_read'/>
                            <div md={6} className={`text-center ${message.read ? 'not-allowed' : 'pointer'}`}
                                 onClick={() => {
                                     if (!message.read) {
                                         markAsRead(message.id, false, () => {
                                             getMessages(type)
                                             getUserStatus()
                                         })
                                     }
                                 }}>
                                <span
                                    className={`mr-5 font11 ${message.read ? 'text-green' : 'message-unread'}`}>{message.read ? 'Read' : 'Mark as read'}</span>
                                <FontAwesomeIcon icon={message.read ? faEnvelopeOpenText : faCheck}
                                                 className={`font11 ${message.read ? 'text-green' : 'message-unread'}`}/>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='top10 bottom10'/>
                <InlineEditable
                    editable={false}
                    breakWord={true}
                    form={() =>
                        <TextareaAutosize
                            style={{width: '100%'}}
                            name="wo_notes"
                            value={message.body}
                            minRows={3}
                            maxRows={10}
                        />
                    }
                    value={message.body && <div dangerouslySetInnerHTML={{__html: message.body}}/>}
                />
                <div className='flex-wrap'>
                    {message.images && message.images.length > 0 && message.images.map(({id, mini, original}) => (
                        <MediaComponent messageId={message.message_id} imageSrcMini={mini} imageId={id}
                                        imageSrcOriginal={original} onSelectImage={onSelectImage}
                                        toggleImageModal={toggleImageModal}/>
                    ))}
                    {message.docs && message.docs.length > 0 && message.docs.map(doc => <DocAttachment
                        key={doc.id}
                        file={doc}
                        isCrewMessage={true}
                        allowRemove={false}
                    />)}
                </div>
                <ImageLightBox images={[selectedImage]}
                               isImageModalOpened={isImageModalOpened}
                               toggleImageModal={toggleImageModal}/>
            </div>
        </div>
    )
}
