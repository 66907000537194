import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, ControlLabel, Glyphicon, Modal, Row, Tab, Tabs} from "react-bootstrap";
import "./EquipmentChecklistModal.scss"
import Select from "react-select";
import {select} from "../../common/commonHandlers";
import DateRangeFilter from "../../components/DateRangeFilter";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {debounce} from "throttle-debounce";

const EquipmentFilterModal = ({onHide, onSave, employees, filters, findWorkOrders, findProposals, workOrderSelected, proposalsSelected}) => {
    const [equipmentFilters, setEquipmentFilters] = useState(filters)
    const [workOrderOptions, setWorkOrderOptions] = useState([])
    const [workOrderSelectedOptions, setWorkOrderSelectedOptions] = useState(workOrderSelected)
    const [proposalsOptions, setProposalsOptions] = useState([])
    const [proposalsSelectedOptions, setProposalsSelectedOptions] = useState(proposalsSelected)

    const employeesOptions = employees && employees.length > 0 ? employees.map(x => ({value: x.user_id, label: x.name})) : []

    const onSelectFilter = (e, field) => {
        setEquipmentFilters({
            ...equipmentFilters,
            [field]: e.map(e => e.value)
        })
    }

    const handleRangeDateChange = (start, end, dateStart, dateEnd) => {
        setEquipmentFilters({
            ...equipmentFilters,
            [dateStart]: start,
            [dateEnd]: end
        })
    }
    const onProposalSearch = useCallback(debounce(1000, false, (q) => findProposals(q, res => setProposalsOptions(res))), [])
    const onWorkOrderSearch = useCallback(debounce(1000, false, (q) => findWorkOrders(q, res => setWorkOrderOptions(res))), [])

    return <Modal
        id="equipment-filter"
        show={true}
        className="heightAuto maxHeight90 fontIOS"
    >
        <Modal.Header>
            <Modal.Title>Equipment Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: "400px"}}>
            <Row className="mb-15">
                <Col xs={12}>
                    <ControlLabel>Created At:</ControlLabel>
                    <DateRangeFilter
                        promisedStart={equipmentFilters.dateFrom}
                        promisedEnd={equipmentFilters.dateTo}
                        upsertPromise={(id, start, end) => handleRangeDateChange(start, end, 'dateFrom', 'dateTo')}
                        proposalServiceId={null}
                        serviceNo={null}
                        index={1}
                        timeDelay={1}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ControlLabel>Crew Leader:</ControlLabel>
                    <Select className="Select bottom15"
                            classNamePrefix="select"
                            value={select(employeesOptions, equipmentFilters?.crewLeaders)}
                            options={employeesOptions}
                            placeholder="Crew Leader"
                            isMulti
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={e => onSelectFilter(e, 'crewLeaders')}
                    />
                </Col>
                <Col xs={12}>
                    <ControlLabel>Completed By:</ControlLabel>
                    <Select className="Select bottom15"
                            classNamePrefix="select"
                            value={select(employeesOptions, equipmentFilters?.completedBy)}
                            options={employeesOptions}
                            placeholder="Completed By"
                            isMulti
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={e => onSelectFilter(e, 'completedBy')}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    <ControlLabel>Proposals:</ControlLabel>
                    <Select className="Select bottom15"
                            classNamePrefix="select"
                            value={select([...proposalsOptions, ...proposalsSelectedOptions], equipmentFilters?.proposals)}
                            options={proposalsOptions}
                            placeholder="Proposals"
                            isMulti
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onInputChange={e => {
                                if (e && e.length > 0) {
                                    onProposalSearch(e)
                                }
                            }}
                            onChange={e => {
                                onSelectFilter(e, 'proposals')
                                setProposalsSelectedOptions(e)
                            }}
                    />
                </Col>
                <Col md={6} xs={12}>
                    <ControlLabel>Work Orders:</ControlLabel>
                    <Select className="Select bottom15"
                            classNamePrefix="select"
                            value={select([...workOrderOptions, ...workOrderSelectedOptions], equipmentFilters?.workOrders)}
                            options={workOrderOptions}
                            placeholder="Work Orders"
                            isMulti
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onInputChange={e => {
                                if (e && e.length > 0) {
                                    onWorkOrderSearch(e)
                                }
                            }}
                            onChange={e => {
                                onSelectFilter(e, 'workOrders')
                                setWorkOrderSelectedOptions(e)
                            }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ColorCheckbox value={equipmentFilters.showFullAnswers}
                                   label='Show full answers'
                                   className='vertical-align'
                                   onChange={e => setEquipmentFilters({
                                       ...equipmentFilters,
                                       showFullAnswers: e
                                   })}
                    />
                    <ColorCheckbox value={equipmentFilters.showIncompleteAnswers}
                                   label='Show incomplete answers'
                                   className='vertical-align'
                                   onChange={e => setEquipmentFilters({
                                       ...equipmentFilters,
                                       showIncompleteAnswers: e
                                   })}
                    />
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle="warning"
                onClick={() => onHide()}
            >
                Close
            </Button>
            <Button
                bsStyle="success"
                onClick={() => onSave({
                    filters: equipmentFilters,
                    workOrderSelectedOptions,
                    proposalsSelectedOptions
                })}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

export default EquipmentFilterModal;