import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {merge, validateName, validateDescription} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const api = createApiService('referrals', 'referral', 'Referral');

class Referrals extends ResourceComponent {
    defaultValues = {name: '', description: '', disabled: false};
    state = {errorMessages: {}};

    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;
        if ((prevState.resources !== resources) && resource) {
            const selected = resources.find(r => r.id === resource.id)
            this.setState(() => ({resource: {...resource, modified_at_date: selected.modified_at_date}}))
        }
    }

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    validate = () => {
        let {resource, errorMessages} = this.state
        let validationName = validateName(resource.name)
        let validationDescription = validateDescription(resource.description)
        if (validationName.is_valid && validationDescription.is_valid) {
            this.setState({errorMessages: {"name": null, "description": null}})
            return true
        } else {
            !validationName.is_valid && this.setState({errorMessages: {...errorMessages, "name": validationName.errorMessage}})
            !validationDescription.is_valid && this.setState({errorMessages: {...errorMessages, "description": validationDescription.errorMessage}})
            return false
        }
    }

    render() {
        const {resource, resources, errorMessages} = this.state;
        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Referral"
                        resourceNamePlural="Referrals"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        blockSave={Object.values(this.state.errorMessages).some((e) => e !== null)}
                        save={() => {
                            this.validate() && this.props.actions.save(resource, this.reloadResources)
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource &&
                        <div>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Name
                                        </ControlLabel>
                                        <FormControl
                                            name="name"
                                            onChange={(e) => {
                                                this.updateResourceAttr(e)
                                                let validationName = validateName(resource.name)
                                                !validationName.is_valid ?
                                                    this.setState({errorMessages: {...errorMessages, "name": validationName.errorMessage}})
                                                    : this.setState({errorMessages: {...errorMessages, "name": null}})
                                            }}
                                            value={resource.name}
                                        />
                                        {this.state.errorMessages.name &&
                                        <p className="error-message-master-detail ">{this.state.errorMessages["name"]}</p>}
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>
                                            Description
                                        </ControlLabel>
                                        <FormControl
                                            name="description"
                                            type="text"
                                            onChange={(e) => {
                                                this.updateResourceAttr(e)
                                                let validationDescription = validateDescription(resource.description)
                                                !validationDescription.is_valid ?
                                                    this.setState({errorMessages: {...errorMessages, "description": validationDescription.errorMessage}})
                                                    : this.setState({errorMessages: {...errorMessages, "description": null}})
                                            }}
                                            value={resource.description}
                                        />
                                        {this.state.errorMessages.description &&
                                        <p className="error-message-master-detail ">{this.state.errorMessages["description"]}</p>}
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.disabled}
                                                       label="Disabled"
                                                       onChange={this.selectCheckboxAttr('disabled')}/>
                                    </FormGroup>
                                    <FormGroup>
                                        Last
                                        updated {resource.modified_at_date ? moment(resource.modified_at_date).format('llll') : <i>Unknown</i>} by {resource.modified_by_name}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Referrals)
