import React from "react"
import PropTypes from "prop-types"
import {Col, DropdownButton, MenuItem, Row} from "react-bootstrap"

import {LinkContainer} from "react-router-bootstrap"

import {select} from "../common/commonHandlers";
import Select from "react-select";
import {Link} from "react-router";
import './CustomerHeader.scss'

const isMobile = window.screen.width <= 991;

const CustomerHeader = ({
                            customerId,
                            customerList,
                            onUpdateCustomerSearch,
                            onSelectCustomer,
                            saveButton,
                            deleteButton,
                            haveSites,

                            title,
                            showDuplicates,
                            salesArborist,
                        }) => {

    const renderDropdown = () =>{
        return <>{customerId !== 0
            ? <DropdownButton
                bsStyle="default"
                title={title}
                id="customer-menu"
            >
                <LinkContainer to={`/customer/info/${customerId}`}>
                    <MenuItem>Customer Info</MenuItem>
                </LinkContainer>
                {haveSites ?
                    <>
                        <LinkContainer to={`/customer/proposals/${customerId}`}>
                            <MenuItem>Customer Proposals</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/sites/${customerId}`}>
                            <MenuItem>Customer Sites</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/work_orders/${customerId}`}>
                            <MenuItem>Customer Work Orders</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/invoices/${customerId}`}>
                            <MenuItem>Customer Invoices</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/work_history/${customerId}`}>
                            <MenuItem>Work History</MenuItem>
                        </LinkContainer>
                    </>
                :
                    <>
                        <MenuItem disabled={true}>Customer Proposals</MenuItem>
                        <MenuItem disabled={true}>Customer Sites</MenuItem>
                        <MenuItem disabled={true}>Customer Work Orders</MenuItem>
                        <MenuItem disabled={true}>Customer Invoices</MenuItem>
                        <MenuItem disabled={true}>Work History</MenuItem>
                    </>
                }

                <MenuItem divider/>
                <LinkContainer to={`/customer/info`}>
                    <MenuItem>Create New Customer</MenuItem>
                </LinkContainer>
            </DropdownButton>
            : null}</>
    }


    return (
    <Row id="customer-header">
        <Col md={4} xs={12}>
            <Row className={!isMobile? "vertical-align" : ""}>
                <Col md={8} xs={12}>
                    <Select
                        value={select(customerList, customerId)}
                        isClearable={false}
                        options={customerList}
                        onInputChange={onUpdateCustomerSearch}
                        onChange={onSelectCustomer}
                        placeholder="Customer Name"
                    />
                </Col>

                {!isMobile ?
                    <Col xs={4} className={isMobile? "customer-info-mobile" : ""}>
                        {renderDropdown()}
                    </Col> : <>
                    <Col xs={12}>
                        <div className="buttons-mobile">
                            {renderDropdown()}
                            {saveButton}
                            {deleteButton}
                        </div>
                    </Col>
                    </>
                }

            </Row>
        </Col>
        <Col md={3} xs={12}>
            {isMobile ? null
            : <div className='top-menu'>
                <Link className='mr-20' to={`/lead?customer_id=${customerId}`}>Find a time</Link>
                {saveButton}
                {window.location.href.includes('customer/info') && <Link to="" onClick={showDuplicates}>Show Duplicates</Link>}
            </div>}
        </Col>
        <Col md={5} xs={12} className={!isMobile? "text-right" : "text-left"}>
            {isMobile ?
                <div className='top-menu'>
                    <Col xs={4} className='no-padding'>
                        <Link className='mr-20' to={`/lead?customer_id=${customerId}`}>Find a time</Link>
                    </Col>
                    <Col xs={8} className='no-padding text-right'>
                    <h5 className='customer-arborist'>
                        <span style={{color: "#f89406"}}>Customer Arborist:</span>
                        <span className='mt-2'>{" "}{salesArborist || "None Assigned"}</span>
                    </h5>
                    </Col>
                </div>
                :
                <h5>
                    {deleteButton}
                    <span className='ml-20' style={{color: "#f89406"}}>Customer Arborist:</span>
                    <span className='mt-2'>{" "}{salesArborist || "None Assigned"}</span>
                </h5>
            }

        </Col>

    </Row>
)
}
CustomerHeader.propTypes = {
    customerId: PropTypes.number.isRequired,
    customerList: PropTypes.array,
    onUpdateCustomerSearch: PropTypes.func.isRequired,
    onSelectCustomer: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    salesArborist: PropTypes.string,
}

export default CustomerHeader
