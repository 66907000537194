import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";

export const checkIfAssigned = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/equipments/check_is_assigned`,
        params: {id: id}
    };

    apiCall("Check is assigned", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data.is_assigned);
    }, dispatch, getState, false);
};

export const saveQuestion = (question, callback) => (dispatch, getState) => {
    const config = {
        method: question.id ? "PUT" : "POST",
        url: question.id ? `/api/equipment_questions/${question.id}` : "/api/equipment_questions",
        data: {equipment_question: question}
    };

    apiCall("Saved questions", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const deleteQuestion = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/equipment_questions/${id}`
    }

    apiCall("Deleting question", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState, false);
};

export const getEquipmentAnswers = (id, filters, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/equipment_answers/get_equipment_answers`,
        params: {id, filters}
    };

    apiCall("Get Equipments Answers", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getEmployees = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/employees`
    };

    apiCall("Get Employees", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const findWorkOrders = (work_order_no, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/new_search`,
        params: {q: work_order_no}
    };

    apiCall("Searching for work orders", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const findProposals = (proposal_no, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/search`,
        params: {q: proposal_no}
    };

    apiCall("Searching for proposals", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};