import {FETCH_APPLICATION_SITE_LIST} from '../constants'

const initialState = {
    applicationSitesList: [],
    applicationSites: {},
    applicationSitesListLastUpdated: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_APPLICATION_SITE_LIST:
            return {
                ...state,
                applicationSitesList: action.applicationSitesList,
                applicationSitesListLastUpdated: action.applicationSitesListLastUpdated,
            };
        default:
            return state
    }
}
