import {SA_STATS_SUCCESS, UPDATE_SEARCH_PROPOSAL_NO, PROPOSAL_SEARCH_SUCCESS, PROPOSAL_SEARCH_FAILED
} from './constants'

import {addAlert} from '../App/actions'
import axios from 'axios'
import {browserHistory} from 'react-router'

export function fetchSalesArboristStats() {
  return function (dispatch, getState) {

    const store = getState()
    const config = {
      method: 'get',
      url: `/api/proposals/approvable_stats`,
      headers: {'Authorization': store.auth.token},
    }

    axios.request(config)
    .then(response => {
      dispatch({
         type: SA_STATS_SUCCESS,
         dollars: response.data.dollars,
         numProposals: response.data.num_proposals,
         dollarsProposed: response.data.dollars_proposed,
         numProposed: response.data.num_proposed,
         leads: response.data.leads,
         dollarsAccepted: response.data.dollars_accepted,
         confirmedLeads: response.data.confirmed_leads,
         followUpCount: response.data.follow_up_count,
         initialSendCount: response.data.initial_send_count,
         followUpToDo: response.data.follow_up_todo,
         nextWeeksLeads: response.data.next_weeks_leads,
         confirmedNext: response.data.confirmed_next,
       });
      console.log("SA Approvable------>", response.data)
    })
    .catch(error => {
      //   dispatch({ type: BUMMER })
    })

  }
}

export function onGoToProposal(){
return function (dispatch, getState) {

    const store = getState()
    const config = {
      method: 'get',
      url: `/api/proposals/`,
      headers: {'Authorization': store.auth.token},
      params: {q: store.sales.searchProposalNo }
    }

    axios.request(config)
    .then(response => {
      const proposalId = response.data.id
      //TODO: since this endpoint returns the proposal (and we're goign to load it anyway next)
      //we should stuff the proposal JSON in the appropriate redux store along with a timestamp
      //then on the proposal show page, check the timestamp, if it was really soon ago then load it from
      //the redux store, otherwise, fetch it from teh API. This will remove an unnecessary call and speed it up.
      //we should do this with other things, too.
      //and record a cache hit or miss to tune
      dispatch({ type: PROPOSAL_SEARCH_SUCCESS, searchStatusMessage: "Proposal Found.", foundProposalId: proposalId});
      browserHistory.push(`/mapview/${proposalId}`)
    })
    .catch(error => {
      if (error.response && error.response.status === 404){
        const message = `Proposal ${store.sales.searchProposalNo} was not found.`
          dispatch(addAlert({message }))
          dispatch({ type: PROPOSAL_SEARCH_FAILED, searchStatusMessage: message, foundProposalId: null })
      }
    })

  }
  }

export function updateProposalSearchText(searchProposalNo){
  return {type: UPDATE_SEARCH_PROPOSAL_NO, searchProposalNo}
}

export const updateTheme = theme => dispatch => {
  dispatch({type: 'UPDATE_THEME', theme: theme});
};
