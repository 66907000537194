import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {downloadPdf} from "../CustomerViewPage/actions"
import {addAlert} from "../App/actions"
import * as MyActions from "./actions"
import {Button, Checkbox, Col, FormGroup, Grid, Panel, Row, Well,} from "react-bootstrap"
import Helmet from "react-helmet"
import PageHeader from "../../components/PageTitle"
import {InvoicePrint} from "./InvoicePrint";
import {showEmailDialog} from "../../components/email/actions"

const Actions = {...MyActions, addAlert, downloadPdf, showEmailDialog};

class PrintInvoicePage extends Component {
    state = {};

    componentWillMount = () => {
        if (this.props.params.proposalId) {
            this.props.actions.fetchInvoice(this.props.params.proposalId, (invoice) => {
                this.setState({invoice})
            })
        } else {
            this.props.actions.addAlert({
                message: "Invoice not given. I can't go on!",
            })
        }
    };

    componentWillReceiveProps = nextProps => {
    };

    handleFormChange = e => {
        let checked = e.target.type === "checkbox"
            ? e.target.checked
            : e.target.value === "true";
        let value = {
            [e.target.name]: checked,
        };

        this.setState({invoice: this.state.invoice});
        this.props.actions.updateProposalPrintOptions(this.props.params.proposalId, value);
    };

  renderPrintOptionsHeader = (invoice) => {
    const printOptions = this.state.invoice.print_options;
    const {email} = this.props;
    return (
        <Row>
          <Col xs={12}>
            <div className="hidden-print text-center">
              <Button
                bsSize="small"
                onClick={() => window.print()}
                bsStyle="primary"
              >
                Print
              </Button>
                {/*{" "}*/}
                {/*<Button*/}
                  {/*bsSize="small"*/}
                  {/*onClick={this.showEmailDialog}*/}
                  {/*bsStyle="primary"*/}
                {/*>*/}
                  {/*Email*/}
                {/*</Button>*/}
            </div>
          </Col>
        </Row>
    )
  };


  showEmailDialog = () => {
        const {email} = this.props;
        const {invoice} = this.state;
        this.props.actions.showEmailDialog(
            {
                emailType: "proposal",
                referenceId: invoice.id,
                recipient: invoice.customer_email_address,
                defaultEmail: email
            });
    };

    render() {
        const {invoice} = this.state;
        if (!invoice) {
            return <div>Loading...</div>
        }

        return (
            <div>
                <Grid fluid>
                  <Col md={12}>
                    <Helmet>
                      <title>{`Invoice #${invoice.invoice_no}`}</title>
                      <style type="text/css">
                        {`
                          @page {
                          size: 8.5in 11in;
                          margin: 0.25in 0.25in 0.25in 0.25in;
                          }
                       `}
                      </style>
                    </Helmet>
                  </Col>
                    <div className="hidden-print">
                        <PageHeader
                            pageName="Print Invoice"
                            pageDetail={`Invoice #${invoice.invoice_no}`}
                        />
                    </div>
                  {this.renderPrintOptionsHeader(invoice)}
              </Grid>
              <br/>
              <InvoicePrint invoice={invoice} options={invoice.print_options}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        email: state.auth.email
    }
};

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintInvoicePage)
