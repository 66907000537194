import React from 'react';
import PropTypes from "prop-types"
import ResourceComponent from "../../components/ResourceComponent";
import {Creatable} from 'react-select';
import Dropzone from "react-dropzone";
import {Button} from "react-bootstrap";
import Attachment from "../../components/image/Attachment";
import createApiService from "../../common/clientActionsBuilder";
import {bindActionCreators} from "redux";
import {select, stringifySelect} from "../../common/commonHandlers";
import {connect} from "react-redux";

const api = createApiService('hazards', 'hazard', 'Hazard');

class SiteHazardForm extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {resource: props.resource};
    }


    confirmDelete = resource => {
        const choice = window.confirm('Are you sure?');

        if (choice) {
            this.props.delete(resource);
        }
    };

    render() {
        const {hazards} = this.props;
        const {resource} = this.state;
        const hazardsOptions = stringifySelect(hazards);
        return (
            <tr>
                <td>
                    <Creatable options={hazardsOptions}
                            value={select(hazardsOptions, resource.hazard_id && resource.hazard_id.toString())}
                            onChange={e => {
                                const doSave = () => {
                                    this.selectResourceAttr('hazard_id')(e);
                                    this.props.save(resource);
                                };

                                if (isNaN(e.value)) {
                                    this.props.actions.save({name: e.label}, result => {
                                        e.value = result.id;
                                        doSave();
                                    });
                                } else {
                                    doSave();
                                }
                            }}/>
                </td>
                <td>
                    {resource.images.map(i => <Attachment key={i.id} image={i} deleteImage={imageId => {
                        resource.images = resource.images.filter(i => i.id !== imageId);
                        this.setState({resource});
                        this.props.save(resource, true);
                    }}/>)}
                    {resource.id &&
                    <Dropzone
                        onDrop={files => this.props.uploadAttachment(resource.site_id, files, data => {
                            resource.images.push(data);
                            this.setState({resource});
                            this.props.save(resource, true);
                        })}
                        className="dropzone pointer small">
                        <p>Drop a file here or click to select a file to upload.</p>
                    </Dropzone>
                    }
                </td>
                <td>
                  <Button
                    bsSize="xsmall"
                    bsStyle="warning"
                    className="full-width top5"
                    onClick={this.props.cancel}
                  >
                    Close
                  </Button>
                  <p>&nbsp;</p>
                    {resource.id &&
                    <Button
                        bsSize="xsmall"
                        bsStyle="warning"
                        className="full-width"
                        onClick={() => this.confirmDelete(resource)}
                    >
                        Delete
                    </Button>
                    }
                </td>
            </tr>
        );
    }
}

SiteHazardForm.propTypes = {
    resource: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    uploadAttachment: PropTypes.func.isRequired,
    hazards: PropTypes.array
};

SiteHazardForm.defaultProps = {
    //myProp: <defaultValue>
};


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(undefined, mapDispatchToProps)(SiteHazardForm);