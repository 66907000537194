import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const saveCustomerNote = (note, customer_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customer_notes_histories/${note?.id ? note.id : ''}`,
        data: {...note, customer_id},
        method: note?.id ? 'PUT' : 'POST'
    };

    apiCall("Saving customer note", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};


export const deleteCustomerNote = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/customer_notes_histories/${id}`
    }

    apiCall("Customer note deleted", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};