import {

  FETCH_ASSET_HISTORY,
  FETCH_SERVICE_HISTORY,
  FETCH_IMAGES_INFO,
  ADD_BLANK_HISTORY,
  EDIT_ASSET_HISTORY,
  UPDATE_HISTORY_ATTRIBUTE,
  REMOVE_BLANK_HISTORY,
  CLEAR_PLANT_DATA,
} from './constants'

//we probably dont want to use this reducer but instead emit events that invoke the mapview reducer?

//note assetHistory is an object with keyed objects in it for nosql goodness
const initialState={

  assetHistory: {},
  serviceHistory: [],
  imagesInfo: [],
  editingAssetHistoryId: null,
}

//note we're not selecting or updaing the plant here because that data is in another reducer

 const reducer = (state=initialState, action) => {
  switch(action.type){

    case FETCH_ASSET_HISTORY:
    case ADD_BLANK_HISTORY:
    case REMOVE_BLANK_HISTORY:
    case UPDATE_HISTORY_ATTRIBUTE:
      return {...state, assetHistory: action.assetHistory}
    case FETCH_SERVICE_HISTORY:
    return {...state, serviceHistory: action.serviceHistory}
    case FETCH_IMAGES_INFO:
    return {...state, imagesInfo: action.imagesInfo}
    case EDIT_ASSET_HISTORY:
        return {...state, editingAssetHistoryId: action.editingAssetHistoryId}
    case CLEAR_PLANT_DATA:
      return {...state, assetHistory: {}, serviceHistory: [], imagesInfo: []}
    default:
    return state
  }
}

export default reducer
