import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const getQuestionnaire = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${token}/questionnaire`,
    };

    apiCall("Getting questionnaire", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const saveQuestionnaire = (invoice_id, answers, rating, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/save_questionnaire`,
        method: 'PUT',
        data: {invoice_id, answers, rating}
    };

    apiCall("Save questionnaire", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const sendGoogleEmail = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/send_google_email`,
        method: 'PUT',
        data: {token}
    };

    apiCall("Send Google Email", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};