import React, {useEffect, useState} from "react";
import {Badge, Button, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import * as AAMActions from './AssignedArboristModalApi'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {BootstrapTable, TableHeaderColumn, SizePerPageDropDown} from "react-bootstrap-table";
import {proposalNoFormatterWithEmail} from "../../components/BootStrapTableCellFormatters";
import * as SDActions from "../SalesDashboard/SalesDashboardApi";
import {showEmailDialog} from "../../components/email/actions";
import moment from "moment";
import './AssignedArboristModal.scss'
import {reverseColor} from "../../common/commonHandlers";
import MDSpinner from "react-md-spinner";
import Dollars from "../../components/Dollars";

const actions = {...AAMActions, ...SDActions, showEmailDialog}
const isMobile = window.innerWidth <= 1024;

const AssignedArboristModal = ({actions, showModal, hideModal, onCancel, selectedCustomer, onSave}) => {
    const [customer, setCustomer] = useState({})
    const [summary, setSummary] = useState([])
    const [proposals, setProposals] = useState([])
    const [proposalsTotal, setProposalsTotal] = useState(0)
    const [salesArborist, setSalesArborist] = useState({})
    const [ready, setReady] = useState(false)
    const [readyFilter, setReadyFilter] = useState(true)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [sort, setSort] = useState({sort_by: 'proposal_no', sort_order: 'desc'})
    const [filteredSelectedArborist, setFilteredSelectedArborist] = useState()

    useEffect(() => {
        actions.getCustomer(selectedCustomer, page, perPage, sort, result => {
                const summaryArray = []
                Object.keys(result.summary).forEach(function (key, index) {
                    const cell = {
                        arboristName: key,
                        sitesCount: new Set(result.summary[key].map(l => l.site_id).filter(l => l)).size,
                        proposalsCount: new Set(result.summary[key].map(l => l.proposal_id).filter(l => l)).size,
                        arborist_disabled: result.summary[key][0].arborist_disabled,
                        arboristId: result.summary[key][0].arborist_id,
                        color: result.summary[key][0].color
                    }
                    summaryArray.push(cell)
                });
                setCustomer(result.customer)
                setSummary(summaryArray.sort((a, b) => b.proposalsCount - a.proposalsCount))
                setProposals(result.proposals.proposals)
                setSalesArborist(result.sales_arborist)
                setProposalsTotal(result.proposals.proposals_total)
                setReady(true)
                setReadyFilter(true)
        }, hideModal)
    }, [])

    useEffect(() => {
        setReadyFilter(false)
        actions.filterAssignedArborist(selectedCustomer, page, perPage, sort, filteredSelectedArborist, result => {
            setProposals(result.proposals.proposals)
            setProposalsTotal(result.proposals.proposals_total)
            setReadyFilter(true)
        })
    }, [page, perPage, sort, filteredSelectedArborist]);

    const headerTable = () => {
        const primarySummary = summary.filter(l => salesArborist && l.arboristId === salesArborist?.id)
        const secondarySummary = summary.filter(l => l.arboristId !== salesArborist?.id && l.arborist_disabled === false)
        const disabledSummary = summary.filter(l => l.arboristId !== salesArborist?.id && l.arborist_disabled === true)
        const isSelected = (l) => filteredSelectedArborist === l.arboristId
        const arboristColor = (l) => isSelected(l) ? l.color : undefined

        const cell = summary => {
            return (
                <>
                    {summary.arboristName.length > 1 ? <p className="no-margin">{summary.arboristName}</p> :
                        <p className="no-margin text-gray"><em>Not assigned</em></p>}
                    <p className="no-margin">{summary.sitesCount} Sites</p>
                    <p className="no-margin">{summary.proposalsCount} Proposals</p>
                </>
            )
        }

        return <div className="padding5 header-table-wrapper maxHeight30vh">
            {primarySummary.length > 0 ? primarySummary.map((l, i) => {
                    return (
                        <div
                            className={`header-table-element border-right ${isSelected(l) ? 'selected-arborist' : 'active-button'}`}
                            style={{
                                minWidth: "fit-content",
                                backgroundColor: arboristColor(l),
                                color: reverseColor(arboristColor(l))
                            }}
                            onClick={() => {
                                setFilteredSelectedArborist(l.arboristId);
                                setPage(1)
                            }}>
                            <p className="no-margin">Primary</p>
                            {cell(l)}
                        </div>
                    )
                })
                :
                salesArborist ?
                    <div className="header-table-element border-right inactive-button"
                         style={{maxWidth: "200px"}}>
                        <p className="no-margin">Primary</p>
                        <p className="no-margin">{salesArborist?.first_name}{" "}{salesArborist?.last_name}</p>
                        <p className="no-margin text-gray">Sales Arborist does not have Sites and Proposals</p>
                    </div>
                    :
                    <div className="header-table-element border-right inactive-button"
                         style={{maxWidth: "200px"}}>
                        <p className="no-margin">Primary</p>
                        <p className="no-margin text-gray">Customer does not have assigned Sales Arborist</p>
                    </div>
            }
            {secondarySummary.length > 0 && secondarySummary.map((l, i) => {
                return (
                    <div
                        className={`header-table-element ${secondarySummary.length - 1 === i && disabledSummary.length > 0 ? 'border-right' : ''} ${isSelected(l) ? 'selected-arborist' : 'active-button'}`}
                        style={{
                            minWidth: "fit-content",
                            backgroundColor: arboristColor(l),
                            color: reverseColor(arboristColor(l))
                        }}
                        onClick={() => {
                            setFilteredSelectedArborist(l.arboristId);
                            setPage(1)
                        }}>
                        <div>
                            <p className="no-margin">Secondary</p>
                            {cell(l)}
                        </div>

                    </div>
                )
            })}
            {disabledSummary.length > 0 && disabledSummary.map((l, i) => {
                return (
                    <div
                        className={`header-table-element ${isSelected(l) ? 'selected-arborist' : 'active-button'}`}
                        style={{
                            minWidth: "fit-content",
                            backgroundColor: arboristColor(l),
                            color: reverseColor(arboristColor(l))
                        }}
                        onClick={() => {
                            setFilteredSelectedArborist(l.arboristId);
                            setPage(1)
                        }}>
                        <div>
                            <p className="no-margin">Disabled</p>
                            {cell(l)}
                        </div>
                    </div>
                )
            })}
        </div>
    }

    const email = proposalId => {
        actions.loadProposalData(proposalId, proposal => {
            let recipientEmail
            const {contactEmail, contactEmail2, secondContactEmail, secondContactEmail2} = proposal
            const primarySiteContactEmails = (contactEmail && contactEmail2) ? `${contactEmail}, ${contactEmail2}` : contactEmail
            const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

            recipientEmail = `${primarySiteContactEmails || ""}`
            if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`

            actions.showEmailDialog(
                {
                    emailType: "proposal",
                    referenceId: proposalId,
                    recipient: recipientEmail
                });
        });
    };

    const titleFormatter = (cell, row) => {
        return cell ? <p style={{whiteSpace: "pre-wrap"}}>{cell}</p> : <i className="text-gray">Untitled</i>
    }

    const siteFormatter = (cell, row) => {
        return <div>
            <b>{cell.name}</b>
            <br/>
            <a href={`https://www.google.com/maps/search/${cell.site_latitude},${cell.site_longitude}/`}
               target="_blank"
               style={{whiteSpace: "pre-wrap"}}
            >
                {cell.full_address}
            </a>
        </div>
    };

    const eventsFormatter = (cell, row) => {
        const status = (statuses, statusCodeName, statusName, color) => {
            return (statuses[statusCodeName]?.length > 0 &&
                <div className="space-between"><span style={{color: `${color}`}}>{statusName}:</span><OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            {statusCodeName === 'lead_created' ?
                                <p>{moment(statuses[statusCodeName]).format('L')}</p> :
                                <p>
                                    {statuses[statusCodeName].map((ps, i) => {
                                        return <div className="text-left">
                                            <strong>#{i + 1}{' '}{ps.name}</strong>
                                            <p>
                                                <span className="mr-3">{moment(ps.proposal_service.modified_at).format('L')}</span>
                                                <span>/</span>
                                                <span className="ml-3 mr-3">{ps.proposal_service.man_hours}h</span>
                                                <span>/</span>
                                                <span className="ml-3"><Dollars amount={ps.proposal_service.service_total}/></span>
                                            </p>
                                        </div>
                                    })}
                                </p>}
                        </Tooltip>}><span><Badge
                    className="proposalBadge font10">{statusCodeName === 'lead_created' ? 1 : statuses[statusCodeName].length}</Badge><br/></span></OverlayTrigger>
                </div>)
        }
        return (
            <div>
                {status(cell, 'lead_created', 'Lead created', 'blue')}
                {status(cell, 'proposed', 'Proposed', '#e87e27')}
                {status(cell, 'accepted', 'Accepted', '#1bb307')}
                {status(cell, 'scheduled', 'Scheduled', '#6e0a0f')}
                {status(cell, 'completed', 'Completed', '#4f07b3')}
                {status(cell, 'in_work_order', 'W/O created', '#b80d70')}
                {status(cell, 'invoiced', 'Invoiced', '#3196de')}
                {status(cell, 'paid', 'Paid', '#523600')}
                {status(cell, 'declined', 'Declined', '#c73030')}
            </div>
        )
    }

    const costFormatter = (cell, row, className) => {
        return <p className={className}><Dollars amount={cell}/></p>
    }

    const mobileFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{proposalNoFormatterWithEmail(cell, row, email)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Title</Col>
                <Col xs={6}>{titleFormatter(row.proposal_title)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Site</Col>
                <Col xs={6}>{siteFormatter(row.site)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Events</Col>
                <Col xs={6}>{eventsFormatter(row.events)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Cost</Col>
                <Col xs={6}>{costFormatter(row.cost, row, "text-left")}</Col>
            </Row>
        </div>
    }

    return (ready ?
            <Modal
                bsSize="large"
                show={showModal}
                onHide={hideModal}
                className="assigned-arborist-modal"
            >
                <Modal.Header>
                    <Modal.Title>WARNING: {customer.name} has other assigned Arborist(s)</Modal.Title>
                    {headerTable()}
                </Modal.Header>
                <Modal.Body className="mr-3">
                    {readyFilter ?
                        !isMobile ?
                        <BootstrapTable
                            data={proposals}
                            fetchInfo={{dataTotalSize: proposalsTotal}}
                            striped
                            bordered={false}
                            hover
                            remote
                            pagination
                            options={
                                {
                                    onPageChange: (page, per_page) => {
                                        setPage(page)
                                        setPerPage(per_page)
                                    },
                                    page,
                                    sizePerPageList: [{
                                        text: '10', value: 10
                                    }, {
                                        text: '25', value: 25
                                    }, {
                                        text: '30', value: 30
                                    }, {
                                        text: '50', value: 50
                                    }, {
                                        text: '100', value: 100
                                    }, {
                                        text: '200', value: 200
                                    }, {
                                        text: 'All', value: proposalsTotal
                                    }],
                                    sizePerPage: perPage,
                                    sortName: sort.sort_by,
                                    sortOrder: sort.sort_order,
                                    onSortChange: (sort_by, sort) => {
                                        setSort({sort_by: sort_by, sort_order: sort})
                                    },
                                    sizePerPageDropDown: () => {
                                        return (
                                            <SizePerPageDropDown
                                                variation='dropup'
                                            />
                                        )
                                    }
                                }
                            }
                            className="wrapped"
                        >
                            <TableHeaderColumn
                                isKey={true}
                                width="10%"
                                dataField="proposal_no"
                                dataFormat={(cell, row) => proposalNoFormatterWithEmail(cell, row, email)}
                                dataSort
                            >
                                Proposal #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="proposal_title"
                                dataFormat={(cell, row) => titleFormatter(cell, row)}
                                dataSort
                            >
                                Title
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="site"
                                dataFormat={(cell, row) => siteFormatter(cell, row)}
                            >
                                Site
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="arborist"
                            >
                                Arborist
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="15%"
                                dataField="events"
                                dataFormat={eventsFormatter}
                            >
                                Events
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="15%"
                                dataField="cost"
                                className="text-right"
                                dataFormat={(cell, row) => costFormatter(cell, row, "text-right")}
                            >
                                Cost
                            </TableHeaderColumn>
                        </BootstrapTable>
                            :
                            <BootstrapTable
                                data={proposals}
                                fetchInfo={{dataTotalSize: proposalsTotal}}
                                striped
                                bordered={false}
                                hover
                                remote
                                pagination
                                options={
                                    {
                                        onPageChange: (page, per_page) => {
                                            setPage(page)
                                            setPerPage(per_page)
                                        },
                                        page,
                                        sizePerPageList: [{
                                            text: '10', value: 10
                                        }, {
                                            text: '25', value: 25
                                        }, {
                                            text: '30', value: 30
                                        }, {
                                            text: '50', value: 50
                                        }, {
                                            text: '100', value: 100
                                        }, {
                                            text: '200', value: 200
                                        }, {
                                            text: 'All', value: proposalsTotal
                                        }],
                                        sizePerPage: perPage,
                                        sortName: sort.sort_by,
                                        sortOrder: sort.sort_order,
                                        onSortChange: (sort_by, sort) => {
                                            setSort({sort_by: sort_by, sort_order: sort})
                                        }
                                    }
                                }
                                className="wrapped"
                            >
                                <TableHeaderColumn
                                    isKey={true}
                                    dataField="proposal_no"
                                    dataFormat={mobileFormatter}
                                />
                            </BootstrapTable>
                        :
                        <div className="vhCenter full-height">
                            <MDSpinner size={70} borderSize={2}/>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Row className="space-between-end modal-body">
                        <div>
                            <Button
                                bsStyle="success"
                                bsSize="small"
                                onClick={() => setFilteredSelectedArborist()}
                            >
                                Reset Filter
                            </Button>
                        </div>
                        <div className="full-width">
                            <Button
                                bsStyle="warning"
                                bsSize="small"
                                onClick={()=>{
                                    hideModal()
                                    onCancel && onCancel()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                bsSize="small"
                                onClick={() => {
                                    hideModal();
                                    onSave()
                                }}
                                bsStyle="success"
                            >
                                Continue
                            </Button>
                        </div>

                    </Row>
                </Modal.Footer>
            </Modal>
            :
            <Modal
                bsSize="large"
                show={showModal}
                onHide={hideModal}
            >
                <Modal.Body className="vhCenter">
                    <MDSpinner size={100} borderSize={2}/>
                </Modal.Body>
            </Modal>
    )
}

const mapStateToProps = state => ({
    customer: state.customer
})

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)})

export default connect(mapStateToProps, mapDispatchToProps)(AssignedArboristModal)