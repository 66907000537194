export const getValidFontSize = (size) => {
    if(!size || size < 13){
        return 13;
    }
    else if(size > 28){
        return 28;
    }
    else{
        return size;
    }
}

export const validateFontSize = (sectionName, size, errors) => {
    const updatedErrors = {...errors};
    if(size < 13){
        updatedErrors[sectionName] = "Minimum font size is 13";
    }
    else if(size > 28){
        updatedErrors[sectionName] = "Maximum font size is 28"
    }
    else{
        updatedErrors[sectionName] && delete updatedErrors[sectionName]
    }
    return updatedErrors;
}