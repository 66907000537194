import {apiCall} from "../../../../common/apiActionHandler";
import axios from "axios";
import {addAlert} from "../../../App/actions";

export const createSubscription = (subscription, payment_method, callback) => async (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: '/api/stripe/create_subscription',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {subscription, payment_method}
    }

    apiCall("Creating subscription", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const cancelSubscription = (subscription, callback) => async (dispatch, getState) => {
    const config = {
        method: 'DELETE',
        url: '/api/stripe/cancel_subscription',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {subscription}
    }

    apiCall("Cancel subscription", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const updateSubscription = (subscription, callback) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: '/api/stripe/update_subscription',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {subscription}
    }
    apiCall("Subscription Updated", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}
export const getSubscription = (subscription_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_subscription`,
        method: 'GET',
        params: {subscription_id}
    };
    apiCall("Get subscription", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};
export const getPrices = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_prices`,
        method: 'GET'
    };
    apiCall("Get prices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};
export const getClientPrice = (client_price_id,callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_client_price`,
        method: 'GET',
        params: {
            client_price_id: client_price_id
        }
    };
    apiCall("Get client price", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};
export const getProducts = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_products`,
        method: 'GET'
    };
    apiCall("Get products", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};
export const getProduct = (product_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_product`,
        method: 'GET',
        params: {product_id}
    };
    apiCall("Get product", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};
export const loadStripeCredentials = (token, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/stripe_credentials`,
        method: 'GET',
        params: {
            token: token
        }
    };
    apiCall("Loading stripe credentials", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getEmployees = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/search/all_employees?disabled=true`,
        method: 'GET'
    };
    apiCall("Get employees", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};

export const changeTrialPermission = (client_id, permission, callback) => async (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: '/api/stripe/change_trial_permission',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {client_id, permission}
    }

    apiCall("Creating subscription", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const sendTrialRenewRequest = (client_id, callback) => async (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: '/api/stripe/send_trial_renew_request',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {client_id}
    }

    apiCall("Send trial renew request", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const getActiveUsersCount = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/count_client_active_users`,
        method: 'GET'
    };
    apiCall("Active users count get successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};
export const getInvoices = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_invoices`,
        method: 'GET'
    };
    apiCall("Get invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};

export const getUpcomingInvoice = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_upcoming_invoice`,
        method: 'GET'
    };
    apiCall("Get upcoming invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState,false);
};