import {
  UPDATE_SERVICE_STATUS,
  UPDATE_SELECTED_SITE,
  SELECT_CUSTOMER,
  FETCH_SELECTED_SITEINFO,
} from "./constants"

const initialState = {
  customer: null,
  serviceStatus: null,
  customerSites: null,
  selectedSite: null,
  selectedSiteInfo: null,
  selectedCustomer: null,
  invoiceTypesList: [
    {name: 'Unpaid', id: 1},
    {name: 'Paid', id: 2},
  ]
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SELECTED_SITEINFO:
      return { ...state, selectedSiteInfo: action.selectedSiteInfo }
    case SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.selectedCustomer,
        customerSites: null,
        selectedSite: null,
        selectedSiteInfo: null,
        customerInvoices: null,
      }
    case UPDATE_SELECTED_SITE:
      return { ...state, selectedSite: action.selectedSite }
    case UPDATE_SERVICE_STATUS:
      return { ...state, serviceStatus: action.serviceStatus }
    default:
      return state
  }
}
