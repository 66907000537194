import React from 'react';
import PropTypes from "prop-types"
import ResourceComponent from "../../../components/ResourceComponent";
import {Creatable} from 'react-select';
import Dropzone from "react-dropzone";
import {Button} from "react-bootstrap";
import Attachment from "../../../components/image/Attachment";
import createApiService from "../../../common/clientActionsBuilder";
import {bindActionCreators} from "redux";
import {select, stringifySelect} from "../../../common/commonHandlers";
import {connect} from "react-redux";

const api = createApiService('hazards', 'hazard', 'Hazard');

class SiteHazardForm extends ResourceComponent {

    state = {
        resource: {images: []},
        hazards: []
    };

    componentDidMount() {
        const {resource, hazards} = this.props;
        this.setState({
            resource: {...this.state.resource, ...resource},
            hazards: stringifySelect(hazards)
        });
    }

    selectHazard = (e) => {
        const {resource} = this.state;
        this.selectResourceAttr('hazard_id', () => {
            this.props.save({
                ...resource, hazard_id: parseInt(resource.hazard_id)
            });
        })(e);
    };

    render() {
        const {resource, hazards} = this.state;
        const id = this.props.resource.id;
        return (
            <tr>
                <td>
                    <Creatable options={hazards}
                               value={select(hazards, resource.hazard_id && resource.hazard_id.toString())}
                               onChange={e => {
                                   if (isNaN(e.value)) {
                                       this.props.actions.save({name: e.label}, result => {
                                           const hazard = {value: result.id.toString(), label: result.name};
                                           hazards.push(hazard);
                                           this.setState({hazards}, () => {
                                               this.props.saveHazard(hazard);
                                               e.value = result.id;
                                               this.selectHazard(e);
                                           });
                                       });
                                   } else {
                                       this.selectHazard(e);
                                   }
                               }}/>
                </td>
                <td>
                    {resource.images.map(i => <Attachment key={i.id} image={i} deleteImage={imageId => {
                        resource.images = resource.images.filter(i => i.id !== imageId);
                        this.setState({resource}, () => this.props.save(resource));
                    }} allowRemove={true}/>)}
                    <Dropzone
                        disabled={!id}
                        onDrop={files => this.props.uploadAttachment(id, files, data => {
                            data = {...data, order: resource.images.length, cwo_and_proposal_display: true}
                            resource.images.push(data);
                            this.setState({resource});
                            this.props.save({
                                ...resource, hazard_id: parseInt(resource.hazard_id)
                            });
                        })}
                        className="dropzone pointer small">
                        <p>Drop a file here or click to select a file to upload.</p>
                    </Dropzone>
                </td>
                <td>
                    <Button
                        bsSize="xsmall"
                        bsStyle="warning"
                        className="full-width top5"
                        onClick={this.props.close}
                    >
                        Close
                    </Button>
                    <p>&nbsp;</p>
                    <Button
                        bsSize="xsmall"
                        bsStyle="warning"
                        className="full-width"
                        onClick={() => this.props.delete(resource)}
                    >
                        Delete
                    </Button>
                </td>
            </tr>
        );
    }
}

SiteHazardForm.propTypes = {
    resource: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    saveHazard: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    uploadAttachment: PropTypes.func.isRequired,
    hazards: PropTypes.array
};

SiteHazardForm.defaultProps = {};


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(undefined, mapDispatchToProps)(SiteHazardForm);