//We are using plants in > 1 place/page/feature so we'll put them as a top level reducer like rails-like redux folder layouts

import {
  FETCH_EMPLOYEE_LIST,
  FETCH_EMPLOYEE,
  UPDATE_EMPLOYEE_ATTR,
  ADD_NEW_EMPLOYEE,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE,
} from '../constants'

const initialState = {
  employeeList: [],
  employees: {},
  employeeListLastUpdated: 0,
}

//add/overwrite object to object i.e. {} => { 'something_3': {boots: 'cat'} }
const insertKeyIntoObject = (obj, kind, key) => {
  return { ...obj, [`${kind}_${key['id']}`]: key }
}

//edit key of object
const updateObjectAttribute = (obj, kind, id, attr, val) => {
  return {
    ...obj,
    [`${kind}_${id}`]: { ...obj[`${kind}_${id}`], [attr]: val },
  }
}

const newEmployee = () => {
  return {
    id: 0,
  }
}

const deleteKeyFromObject = (obj, kind, id) => {
  //destructure like let { x,y, ...restOfThem} = this.props and do nothing with the throwaway
  let { [`${kind}_${id}`]: unwanted, ...restOfThem } = obj
  console.log('removed ', unwanted)
  console.log('returning', restOfThem)
  return restOfThem
}

export function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.employeeList,
        employeeListLastUpdated: action.employeeListLastUpdated,
      }
    case FETCH_EMPLOYEE:
      return {
        ...state,
        employees: insertKeyIntoObject(
          state.employees,
          'employee',
          action.employee
        ),
      }
    case UPDATE_EMPLOYEE_ATTR:
      return {
        ...state,
        employees: updateObjectAttribute(
          state.employees,
          'employee',
          action.id,
          action.attr,
          action.val
        ),
      }
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        employees: insertKeyIntoObject(state.employees, 'employee', action.val)
      }
    case ADD_NEW_EMPLOYEE:
      return {
        ...state,
        employees: insertKeyIntoObject(state.employees, 'employee', newEmployee()),
      }
    case DELETE_EMPLOYEE:
      return {
        ...state,
        employees: deleteKeyFromObject(state.employees, 'employee', action.id),
      }
    default:
      return state
  }
}
