import axios from 'axios'
import {addAlert} from "../App/actions"
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import printJS from 'print-js'
import {Buffer} from 'buffer'
import {dataUpload} from "../../common/upload";

export const printPdf = (proposal, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${proposal.token}/pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `proposal.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(proposal ? proposal : {}), source: "proposal pdf"}, false)(dispatch, getState)
        const url = URL.createObjectURL(blob);
        printJS(url);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const fetchMap = (url, token, callback) => {
    const config = {
        method: 'get',
        url,
        headers: {'Authorization': token},
        responseType: "arraybuffer"
    };

    axios.request(config)
        .then(response => {
            const image = new Buffer(response.data, 'binary').toString('base64');
            callback(`data:${response.headers['content-type'].toLowerCase()};base64,${image}`);
        })
};
export const updateUserProposalPrintOptions = (userId, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${userId}/proposal_print_options`,
        data: {
            proposal_print_options: value
        },
        method: 'PUT'
    };

    apiCall("Make print options default", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const resetProposalPrintOptions = (proposalId, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposals/${proposalId}/reset_print_options`,
        data: {
            reset_print_options: value
        },
        method: 'PUT'
    };

    apiCall("Reset print options to default", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const updateProposalPrintOptions = (proposalId, value, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'PUT',
        url: `/api/proposals/${proposalId}/print_options`,
        headers: {Authorization: token},
        data: {
            value
        },
    };

    try {
        const response = await axios.request(config);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update proposal print option" + exception.toString(),
            })
        );
        throw exception
    }
};
export const fetchUser = (userId, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'GET',
        url: `/api/users/${userId}`,
        headers: {Authorization: token},
    };

    try {
        const response = await axios.request(config);
        callback(response.data);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the user " + exception.toString(),
            })
        );
        throw exception
    }
};


export const fetchProposal = (proposalId, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;
    const config = {
        url: `/api/proposals/${proposalId}/print`,
        headers: {Authorization: token},
        params: {
            time: new Date().valueOf(),
        },
    };

    try {
        const response = await axios.request(config);
        callback(response.data);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the customer proposal " + exception.toString(),
            })
        );
        throw exception
    }
};

export const updateTerms = (proposal, callback) => (dispatch, getState) => {
    const config = {
        url: '/api/new_proposals/update_terms/',
        data: proposal,
        method: 'PUT'
    };

    apiCall("Updating proposal terms", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState);
};

export const fetchOptions = (id, page, per_page, isInvoice = false, callback) => (dispatch, getState) => {
    const config = {
        url: isInvoice ? `/api/invoice_print/${id}/get_print_options_history` : `/api/proposals/${id}/get_print_options_history`,
        method: 'GET',
        params: {
            page: page || 1,
            per_page: per_page || 10
        }
    };

    apiCall("Getting options history", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};
