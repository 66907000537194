import React, {useEffect, useState} from "react";
import {Button, Col, Glyphicon, Modal, Row} from "react-bootstrap";
import _ from 'lodash'

const DuplicatesModal = ({customer, findDuplicates, onHide, redirectFunction, mergeCustomer, enableRedirect=false}) => {
    const [duplicates, setDuplicates] = useState({})
    const [show, setShow] = useState({company: false, name: false, phone: false, address: false})

    const mainDuplicatesWrapper = (name, value) => {
        const chosenDuplicates = duplicates[value]

        const singleRow = (value, label, customerValue, i, isPhone = false) => {
            let customerColor = ''
            let valueColor = ''
            let colorText = ''

            if (isPhone) {
                customerColor = label && customerValue?.length > 0 ? chosenDuplicates[i]?.phone_number?.trim() === customerValue || chosenDuplicates[i]?.phone_number2?.trim() === customerValue ? "matching-value" : "not-matching-value" : ""
                valueColor = label && value?.length > 0 ? customer?.phone_number?.trim() === value || customer?.phone_number2?.trim() === value ? "matching-value" : "not-matching-value" : ""
            } else {
                colorText = label && (value?.length > 0 || customerValue?.length > 0) ? value?.trim().toUpperCase() === customerValue?.trim().toUpperCase() ? "matching-value" : "not-matching-value" : ""
            }

            return <div className="single-compare">
                <p className={`single-left-value ${colorText} ${customerColor}`}>{customerValue ? customerValue : "-"}</p>
                <b className="single-center-value">{label}</b>
                <p className={`single-right-value ${colorText} ${valueColor}`}>{value ? value : "-"}</p>
            </div>
        }

        return customer && <div className="chosen-duplicates-main-wrapper">
            <div onClick={() => chosenDuplicates?.length > 0 && setShow({...show, [value]: !show[value]})}
                 className="chosen-duplicates-header">
                <span className={show[value] ? "pb-10" : ""}>{name}: <b>{chosenDuplicates?.length}</b></span>
                {chosenDuplicates?.length > 0 ? show[value] ? <Glyphicon glyph="chevron-up"/> :
                    <Glyphicon glyph="chevron-down"/> : <></>}
            </div>
            {show[value] &&
                <div>
                    {chosenDuplicates?.map((d, i) => {
                        return <div className="single-customer">
                            {singleRow(<b>Duplicate Customer</b>, undefined, <b>Current Customer</b>)}
                            {singleRow(`${d?.id}`, "Customer Id", `${customer?.id}`, i)}
                            {singleRow(`${d?.quickbooks_id}`, "Quickbooks Id", `${customer?.quickbooks_id}`, i)}
                            {singleRow(d?.company_name, "Company Name", customer?.company_name, i)}
                            {singleRow(d?.first_name, "First Name", customer?.first_name, i)}
                            {singleRow(d?.last_name, "Last Name", customer?.last_name, i)}
                            {singleRow(d?.email_address, "Email", customer?.email_address, i)}
                            {singleRow(d?.phone_number, "Phone Number", customer?.phone_number, i, true)}
                            {singleRow(d?.phone_number2, "Phone Number 2", customer?.phone_number2, i, true)}
                            {singleRow(d?.address_1, "Address 1", customer?.address_1, i)}
                            <div className="text-right">
                                <Button bsStyle="info" bsSize="xsmall" className="mr-15"
                                        onClick={() => mergeCustomer(d.id, customer?.id, res => res.merged && redirectFunction())}>Merge into duplicated
                                    customer</Button>
                                <Button bsStyle="info" bsSize="xsmall" className="mr-15"
                                        onClick={() => window.open(`/customer/info/${d.id}`)}>Go to duplicated
                                    customer</Button>
                            </div>
                        </div>
                    })}
                </div>
            }
        </div>
    }

    useEffect(() => {
        if (customer) {
            const value = {
                customer_id: customer?.id,
                company_name: customer?.company_name,
                first_name: customer?.first_name,
                last_name: customer?.last_name,
                phone_number: customer?.phone_number,
                phone_number2: customer?.phone_number2,
                address_1: customer?.address_1
            }
            findDuplicates(value, res => {
                    setDuplicates(res)
                    if (enableRedirect && _.isEmpty(res.company) && _.isEmpty(res.address) && _.isEmpty(res.name) && _.isEmpty(res.phone)) {
                        redirectFunction()
                    }
                }
            )
        }
    }, [])

    return <Modal
        bsSize="large"
        show={true}
        onHide={onHide}
        backdrop="static"
        id="duplicates-modal"
        className="heightAuto fontIOS"
    >
        <Modal.Header closeButton>
            <Modal.Title>Possible Duplicates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {mainDuplicatesWrapper('Company Name', 'company')}
            {mainDuplicatesWrapper('First or Last Name', 'name')}
            {mainDuplicatesWrapper('Phone Number', 'phone')}
            {mainDuplicatesWrapper('Address', 'address')}
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col xs={12}>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                    {enableRedirect && <Button
                        bsSize="small"
                        bsStyle="success"
                        onClick={() => redirectFunction()}
                    >
                        Continue
                    </Button>}
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default DuplicatesModal;