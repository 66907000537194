import axios from 'axios'
import {addAlert} from "../App/actions"

export const updateProposalPrintOptions = (proposalId, value) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'PUT',
        url: `/api/proposals/${proposalId}/print_options`,
        headers: {Authorization: token},
        params: {
            value
        },
    };

    try {
        const response = await axios.request(config);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update proposal print option" + exception.toString(),
            })
        );
        throw exception
    }
};

export const fetchInvoice = (proposalId, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        url: `/api/invoices/${proposalId}/print`,
        headers: {Authorization: token},
        params: {
            time: new Date().valueOf(),
        },
    };

    try {
        const response = await axios.request(config);
        callback(response.data);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the customer proposal " + exception.toString(),
            })
        );
        throw exception
    }
};

