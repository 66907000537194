import {FETCH_PRINT_INVOICE} from '../../CustomerViewPage/constants'
import axios from 'axios'
import {apiCall} from "../../../common/apiActionHandler";


export const fetchInvoice = (invoiceToken, callback, forPdf = null) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${invoiceToken}/load_invoice${forPdf ? '?for_pdf=true' : ''}`,
    };

    apiCall("Fetching invoice", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
        dispatch({type: FETCH_PRINT_INVOICE, invoice: response.data})
    }, dispatch, getState, false);
};


export const loadStripeCredentials = (client_id, callback) => (dispatch, getState) => {


    const config = {
        url: `/api/stripe/stripe_credentials`,
        method: 'GET',
        params: {
            client_id: client_id
        }
    };


    apiCall(null, config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const charge = (token, paymentToken, partialAmount,payWithBalance, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/charge`,
        method: 'POST',
        data: {
            token: token,
            paymentToken: paymentToken,
            partialAmount: partialAmount,
            payWithBalance: payWithBalance
        }
    };
    apiCall("Creating charge", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const requestRefund = (invoiceToken,charge_id, reason, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${invoiceToken}/request_refund`,
        method: 'PUT',
        data: {
            charge_id: charge_id,
            reason: reason
        }
    };
    apiCall("Requesting refund", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getDefaultPaymentMethod = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_default_payment_method`,
        method: 'GET',
        params: {token: token}
    };

    apiCall("Load customer default payment method", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const loadWalletBalance = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_wallet_balance`,
        method: 'GET',
        params: {token: token}
    };

    apiCall("Load customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};