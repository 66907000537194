import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {
    Button,
    Modal,
    OverlayTrigger,
    Tooltip,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Tab,
    Tabs,
    Grid,
    Row
} from "react-bootstrap";
import {defaultDateFormat, merge, select, validateDescription, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Datetime from "react-datetime";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";
import ServicePrioritiesFilters from "./ServicePrioritiesFilters";
import * as actions from "./ServicePrioritiesApi";
import _ from 'lodash';
import './ServicePriorities.scss';
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";

const priorities = createApiService('priorities', 'priority', 'Service priority');
const api = {
    ...priorities,
    ...actions
};

const selectMonths = [
    {label: 'January', value: 1},
    {label: 'February', value: 2},
    {label: 'March', value: 3},
    {label: 'April', value: 4},
    {label: 'May', value: 5},
    {label: 'June', value: 6},
    {label: 'July', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9},
    {label: 'October', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12},
]

class ServicePriorities extends ResourceComponent {
    defaultValues = {
        name: '',
        description: '',
        disabled: false,
        ps_statuses: [2],
        days_to_notification: '',
        days_before_month: '',
        month: null,
        date: null,
        exact_date: false,
        roles: [],
        service: null,
        arborist: null,
        customer: null,
        proposal: null,
        customer_type: null,
        service_type: null,
        accepted_to: null,
        accepted_from: null,
        scheduler_recipients: [],
        completedProposal: false,
        invoicedProposal: false,
        paidProposal: false
    };
    state = {
        rolesList: [{id: 2, role_name: "sales_arborist"}, {id: 3, role_name: "scheduler"}],
        employeeList: [],
        employeeListLoaded: false,
        statusesList: [],
        key: 0,
        filterModalShow: false,
        showEditModal: false,
        errorMessages: {}
    };

    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources, employeeList, statusesList} = this.state;
        if ((prevState.resources !== resources) && resource) {
            const selected = resources.find(r => r.id === resource.id)
            this.setState(() => ({resource: {...resource, modified_at_date: selected?.modified_at_date}}))
        }
        if(_.isEmpty(statusesList)){
            this.props.actions.getPsStatuses(res => {
                this.setState({statusesList: res.filter(s => s.code !== 'NOT_OFFERED')})
            })
        }
        if (!this.state.employeeListLoaded && resource?.roles?.includes(3) && _.isEmpty(employeeList)) {
            this.props.actions.getEmployees(res => {
                this.setState({employeeListLoaded: true, employeeList: res.filter(e => e.user_roles.includes(3)).map(e => ({value: e.person_id, label: e.name}))})
            })
        }
    }

    handleSelect = e => {
        this.setState({key: 0, resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    handleRolesCheck = (e, id) => {
        const roles = this.state.resource.roles || []
        if (e.target.checked) {
            this.state.resource['roles'] = [...roles, id]
        } else {
            this.state.resource['roles'] = roles.filter(r => r !== id)
        }
        this.setState({resource: this.state.resource})
    }
    handleTabSelect = (key) => {
        const {resource} = this.state
        this.setState({key: key});
        if (key === 1) {
            this.props.actions.get(resource.id, this.reloadResources)
        }
    }
    proposalFormatter = (cell, row) => (
        <a href={`/mapview/${row.proposal_id}`} target={'_blank'}>
            <span className={'text-blue pointer'}>{row.proposal_no}</span>
        </a>);

    servicePriorityProposalsCSVFormatter = (dataArray) => {
        dataArray.forEach(data => {
            Object.keys(data).forEach(key => {
                if (['proposal_id'].includes(key)) {
                    delete data[key]
                }
                else {
                    data[key] = data[key]
                }
            })
        })
        return dataArray
    }

    createCustomToolBar = props => {
        return (
            <div className='table-toolbar'>
                <div className='buttons'>
                    {props.components.btnGroup}
                    <Button
                        bsStyle="success"
                        disabled={!this.state.resource.proposals.length > 0}
                        onClick={async () => {
                            const data = this.servicePriorityProposalsCSVFormatter(_.cloneDeep(this.state.resource.proposals))
                            const workbook = new ExcelJS.Workbook();
                            const worksheet = workbook.addWorksheet("Service Priority Proposals")

                            worksheet.addRow(['Customer',
                                'Service',
                                'Priority',
                                'Proposal #',
                                'Arborist',
                                'Site',
                                'Notes']);

                            data.forEach((item)=>{
                                worksheet.addRow([item.customer_name,
                                    item.service_name,
                                    item.priority,
                                    item.proposal_no,
                                    item.arborist,
                                    item.site_address,
                                    item.notes])
                            })

                            worksheet.eachRow({includeEmpty: true}, (row, rowNumber)=>{
                                if(rowNumber===1){
                                    row.eachCell((cell)=>{
                                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffb2b2b2' } };
                                    })
                                }
                                else{
                                    const cellA = row.getCell(1)
                                    cellA.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffdddddd' } };
                                }
                            })

                            worksheet.columns.forEach(column => {
                                const lengths = column.values.map(v => v.toString().length);
                                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                                column.width = maxLength;
                            });

                            const excelBuffer = await workbook.xlsx.writeBuffer();

                            const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
                            saveAs(dataBlob, `service_priority-${this.state.resource.name}-proposals-${moment().format(defaultDateFormat)}.xlsx`);

                            dataBlob.name = `service_priority-${this.state.resource.name}.xlsx`
                            this.props.actions.saveDownloadedFile(dataBlob, this.state.resource, "service-priority")
                        }}>
                        <i class="fa glyphicon glyphicon-export fa-download"/> Export to XLSX
                    </Button>
                </div>


                <div>
                    {props.components.searchPanel}
                </div>
            </div>
        );
    }

    validDaysBeforeMonth = () => {
        const {resource} = this.state

        if (resource && ((resource.month && !resource.days_before_month) || (!resource.month && resource.days_before_month))) {
            return false
        } else {
            return true
        }
    }
    validExactDate = () => {
        const {resource} = this.state
        if (resource && resource.exact_date && resource.date){
            const dateToCheck = moment(resource.date, 'MM/DD/YYYY', true);
            return dateToCheck.isValid() && (dateToCheck.format('MM/DD/YYYY') === resource.date || moment.isMoment(resource.date));
        }else{
            return true
        }

    }

    validate = () => {
        let {resource, errorMessages} = this.state
        let validationName = validateName(resource.name)
        let validationDescription = validateDescription(resource.description)
        if (validationName.is_valid && validationDescription.is_valid) {
            this.setState({errorMessages: {"name": null, "description": null}})
            return true
        } else {
            !validationName.is_valid && this.setState({errorMessages: {...errorMessages, "name": validationName.errorMessage}})
            !validationDescription.is_valid && this.setState({errorMessages: {...errorMessages, "description": validationDescription.errorMessage}})
            return false
        }
    }

    render() {
        const {resource, resources, showEditModal, errorMessages} = this.state;
        const nameValidation = resources && resource && resources.some(r => r.name === resource.name && r.disabled === false && resource.disabled === false && r.id !== resource.id)
        const selectedPriority = resources && resource && resources.find(r => r.id === resource.id)
        const checkPriorityInActive = resources && resource && resources.some(r => r.name === selectedPriority?.name && r.disabled === false)

        return (
            resources ?
                <Grid fluid id="service-priorities">
                    <Modal show={showEditModal}
                           onHide={() => this.setState({showEditModal: false})}
                           className={"heightAuto fontIOS"}>
                        <Modal.Header closeButton><Modal.Title>Service Priority already
                            exist</Modal.Title></Modal.Header>
                        <Modal.Body>
                            <div className='columnDirection rate-modal-body-padding'>
                                <p>You are editing existing Service Priority with the
                                    name <strong>{selectedPriority?.name}</strong>.</p>
                                <p>New Service Priority will be created with the
                                    name <strong>{resource?.name}</strong> and the old
                                    - <strong>{selectedPriority?.name}</strong> Service Priority will be transferred to
                                    Archived Tab</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={() => this.setState({showEditModal: false})}
                                    className="btn btn-default"
                                    data-dismiss="modal">No
                            </Button>
                            <Button className="btn btn-success" onClick={() => {
                                this.props.actions.save(resource, this.reloadResources)
                                this.setState({showEditModal: false})
                            }}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <MasterDetail
                        resourceName="Service priority"
                        resourceNamePlural="Service priorities"
                        resources={resources}
                        resource={resource}
                        blockSave={resource && resources.some(r => r.name === resource.name && r.disabled === false && resource.disabled === false && r.id !== resource.id) || !this.validExactDate() || Object.values(this.state.errorMessages).some((e) => e !== null)}
                        blockDelete={selectedPriority?.assigned_in_ps}
                        select={this.handleSelect}
                        handleTabSelect={this.handleTabSelect}
                        save={() => {
                            if (this.validate()) {
                                if (resource.id) {
                                    this.props.actions.checkIfAssigned(resource.id, (is_assigned) => {
                                        if (is_assigned && !selectedPriority?.disabled && selectedPriority.name !== resource.name) {
                                            this.setState({showEditModal: true})
                                        } else {
                                            this.props.actions.save(resource, this.reloadResources)
                                        }
                                    })
                                } else {
                                    this.props.actions.save(resource, this.reloadResources)
                                }
                            }

                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource &&
                            <Tabs activeKey={this.state.key}
                                  id="controlled-tab-example"
                                  mountOnEnter={true}
                                  onSelect={this.handleTabSelect}
                                  animation={false} id="noanim-tab-example">
                                <Tab eventKey={0} title={`${resource.name || 'New Service Priority'}`}>
                                    {this.state.key === 0 && <div><Row>
                                        <Col md={6}>
                                            <FormGroup
                                                validationState={nameValidation ? "error" : ""}>
                                                {selectedPriority?.assigned_in_ps &&
                                                    <p className="text-danger fontSize10">This Priority is already
                                                        assigned to Proposal Service.</p>}
                                                <ControlLabel>
                                                    Name
                                                </ControlLabel>
                                                <FormControl
                                                    name="name"
                                                    onChange={(e) => {
                                                        this.updateResourceAttr(e)
                                                        let validationName = validateName(resource.name)
                                                        !validationName.is_valid ?
                                                            this.setState({errorMessages: {...errorMessages, "name": validationName.errorMessage}})
                                                            : this.setState({errorMessages: {...errorMessages, "name": null}})
                                                    }}
                                                    value={resource.name}
                                                />
                                                {nameValidation && <span
                                                    className="text-danger">Name already exist.</span>}
                                                {this.state.errorMessages.name &&
                                                <p className="error-message-master-detail ">{this.state.errorMessages["name"]}</p>}
                                            </FormGroup>

                                            <FormGroup>
                                                <ControlLabel>
                                                    Description
                                                </ControlLabel>
                                                <FormControl
                                                    name="description"
                                                    type="text"
                                                    onChange={(e) => {
                                                        this.updateResourceAttr(e)
                                                        let validationDescription = validateDescription(resource.description)
                                                        !validationDescription.is_valid ?
                                                            this.setState({errorMessages: {...errorMessages, "description": validationDescription.errorMessage}})
                                                            : this.setState({errorMessages: {...errorMessages, "description": null}})
                                                    }}
                                                    value={resource.description}
                                                    disabled={selectedPriority?.disabled && selectedPriority?.assigned_in_ps}
                                                />
                                                {this.state.errorMessages.description &&
                                                <p className="error-message-master-detail ">{this.state.errorMessages["description"]}</p>}
                                            </FormGroup>

                                            {selectedPriority?.disabled && checkPriorityInActive ?
                                                <OverlayTrigger placement="bottom" overlay={
                                                    <Tooltip id="tooltip">
                                                        Other equipment with the same name is included in Active Tab!
                                                    </Tooltip>
                                                }>
                                                    <FormGroup style={{width: "fit-content"}}>
                                                        <ColorCheckbox value={resource.disabled}
                                                                       label="Disabled"
                                                                       onChange={this.selectCheckboxAttr('disabled')}
                                                                       disabled={true}
                                                        />
                                                    </FormGroup>
                                                </OverlayTrigger>
                                                :
                                                <FormGroup>
                                                    <ColorCheckbox value={resource.disabled}
                                                                   label="Disabled"
                                                                   onChange={this.selectCheckboxAttr('disabled')}
                                                    />
                                                </FormGroup>
                                            }
                                            <FormGroup>
                                                Last
                                                updated {resource.modified_at_date ? moment(resource.modified_at_date).format('llll') : <i>Unknown</i>} by {resource.modified_by_name}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <ControlLabel>Send notification for services in status</ControlLabel>
                                                        <Select
                                                            className="Select"
                                                            value={select(this.state.statusesList, resource.ps_statuses)}
                                                            onChange={this.selectResourceAttr('ps_statuses')}
                                                            options={this.state.statusesList}
                                                            placeholder="Status"
                                                            isMulti
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <ControlLabel>Send notification every number of days</ControlLabel>
                                                        <FormControl
                                                            name="days_to_notification"
                                                            type="number"
                                                            onChange={this.updateResourceAttr}
                                                            value={resource.days_to_notification}
                                                            placeholder={'Number of days'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Send notification number of days before a month {!this.validDaysBeforeMonth() && <spanp className="text-danger fontSize10"> - notification will be send only if you select both fields</spanp>}</ControlLabel>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <FormControl
                                                            name="days_before_month"
                                                            type="number"
                                                            onChange={this.updateResourceAttr}
                                                            value={resource.days_before_month}
                                                            placeholder={'Days before'}
                                                        />
                                                    </Col>
                                                <Col xs={12} md={6}>
                                                    <Select
                                                        className="Select"
                                                        value={select(selectMonths, resource.month)}
                                                        onChange={this.selectResourceAttr('month')}
                                                        options={selectMonths}
                                                        placeholder="Month"
                                                        isClearable
                                                    />
                                                </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Checkbox
                                                    onChange={() => this.setState({
                                                        resource: {
                                                            ...resource,
                                                            exact_date: !resource.exact_date,
                                                            date: null
                                                        }
                                                    })}
                                                    inline
                                                    checked={resource.exact_date}
                                                >
                                                    Send on an exact date
                                                </Checkbox>
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Notification date</ControlLabel>
                                                <div style={{width: '100%'}}>
                                                    <Datetime
                                                        dateFormat={defaultDateFormat}
                                                        timeFormat={false}
                                                        inputProps={{disabled: !resource.exact_date }}
                                                        value={resource.date}
                                                        onChange={this.dateResourceAttr("date")}
                                                        closeOnSelect
                                                    />
                                                </div>
                                            </FormGroup>
                                            <ControlLabel>Notification for:</ControlLabel>
                                            {this.state.rolesList && this.state.rolesList.map(r => (
                                                <FormGroup className="mt-5" key={r.id}>
                                                    <Checkbox
                                                        checked={resource.roles && resource.roles.includes(r.id)}
                                                        onChange={e => this.handleRolesCheck(e, r.id)}
                                                        inline>
                                                        {" "}
                                                        <div>
                                                            {r.role_name}
                                                        </div>
                                                    </Checkbox>
                                                </FormGroup>)
                                            )}
                                            {resource.roles.includes(3) &&
                                                <FormGroup>
                                                    <h5>Recipients:</h5>
                                                    <Select className="Select mt-5"
                                                            classNamePrefix="select"
                                                            options={this.state.employeeList}
                                                            isClearable
                                                            placeholder="Add recipient"
                                                            value={select(this.state.employeeList, resource.scheduler_recipients)}
                                                            isMulti
                                                            onChange={this.selectResourceAttr('scheduler_recipients')}
                                                    />
                                                    {resource.scheduler_recipients < 1 && <p className="text-danger fontSize10">Please select scheduler, otherwise the notification will be send to all schedulers</p>}
                                                </FormGroup>}
                                            {(_.isEmpty(resource.ps_statuses) || _.isEmpty(resource.roles) || (_.isEmpty(resource.days_to_notification) && _.isEmpty(resource.date) && (!this.validDaysBeforeMonth() || _.isEmpty(resource.days_before_month) && _.isEmpty(resource.month)))) && <p className="text-danger fontSize10">Notification for this priority will not be sent because no notification option has been selected.</p>}
                                            <FormGroup>
                                                <h4><b>How to use:</b></h4>
                                                <p><b>Send notification for services in status</b> - <span>Notification will be sent only for services in selected statuses</span>
                                                </p>
                                                <p><b>Send notification every number of days</b> - <span>Notification will be sent every number of days until service in in selected statuses</span>
                                                </p>
                                                <p><b>Send notification number of days before a month</b> - <span>Notification will be sent a number of days before the selected month. For example, if you select 10 May, the notification will be sent on April 21st</span>
                                                </p>
                                                <p><b>Send on an exact date</b> - <span>Notification will be sent only once at the selected date</span>
                                                </p>
                                                <p><b>Notification for</b> - <span>Notification will be sent only to users who have been assigned selected roles</span>
                                                </p>
                                                <p><b>You can combine more than one notification at a time</b></p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    </div>}
                                </Tab>
                                {resource.id && <Tab eventKey={1} title="Proposals">
                                    {this.state.key === 1 &&
                                        <div>
                                            <Row>
                                                <Col md={3} mdOffset={9} className='align-right margin-top-20'>
                                                    <Button
                                                        className="pointer no-top-margin"
                                                        bsStyle="warning"
                                                        onClick={() => this.setState({
                                                            filterModalShow: !this.state.filterModalShow,
                                                            oldResource: {...resource}
                                                        })}>
                                                        Filter {" "}<FontAwesomeIcon icon="search"
                                                                                     className="small-margin"/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {resource.proposals &&
                                                        <BootstrapTable
                                                            data={resource.proposals}
                                                            striped={true}
                                                            bordered={false}
                                                            hover={true}
                                                            pagination={true}
                                                            search={true}
                                                            options={{
                                                                toolBar: this.createCustomToolBar
                                                            }}
                                                        >
                                                            <TableHeaderColumn
                                                                width={'100'}
                                                                isKey={true}
                                                                dataField="service_name"
                                                                tdStyle={{whiteSpace: 'normal'}}
                                                                thStyle={{whiteSpace: 'normal'}}
                                                                dataSort={true}
                                                                columnClassName='menu-show-fixer'
                                                            >
                                                                Service Name
                                                            </TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataFormat={this.proposalFormatter}
                                                                width={'100'}
                                                                dataField="proposal_no"
                                                                tdStyle={{whiteSpace: 'normal'}}
                                                                thStyle={{whiteSpace: 'normal'}}
                                                                dataSort={true}
                                                                columnClassName='menu-show-fixer'
                                                            >
                                                                Proposal_no
                                                            </TableHeaderColumn>
                                                        </BootstrapTable>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    <LeadsFilterModal
                                        title={'Filter'}
                                        show={this.state.filterModalShow}
                                        onHide={() => {
                                            this.setState({filterModalShow: false})
                                            this.props.actions.getFilteredData(resource.id, (e) => this.setState({
                                                resource: {
                                                    ...resource,
                                                    proposals: e.proposals
                                                }
                                            }), resource)
                                        }}
                                        closeButton={() => this.setState({filterModalShow: false})}
                                        showAcceptBtn
                                        backdrop='static'
                                    >
                                        <ServicePrioritiesFilters fetchFilterData={this.props.actions.fetchFilterData}
                                                                  selectAttr={(e) => this.selectResourceAttr(e)}
                                                                  resource={resource}
                                                                  dateResourceAttr={(e) => this.dateResourceAttr(e)}
                                                                  selectCheckboxAttr={(e) => this.selectCheckboxAttr(e)}
                                        />
                                    </LeadsFilterModal>
                                </Tab>}
                            </Tabs>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(ServicePriorities)
