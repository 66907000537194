import React from 'react';
import {Button, Col, FormGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import './TransferPlant.css';
import Select from "react-select";
import {select} from "../../../common/commonHandlers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import ResourceComponent from "../../../components/ResourceComponent";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "./RemoveProposalApi";

class RemoveProposal extends ResourceComponent {

    constructor(props) {
        super(props);
        this.proposalSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.state = {
            resource: {
                proposal_id: null
            },
        }
    }

    componentDidMount() {
        this.proposalSearch = this.buildSearchComponent('proposals', this.props.actions.searchForProposal);
    }

    removeProposal = (e, resource) => {
        e.preventDefault();
        this.props.actions.removeProposal(resource, callback => this.setState(prevState => ({
            resource: {
                ...prevState.resource,
                proposal_id: null
            }
        })))
    };

    render() {
        const {resource} = this.state;
        const {proposal_id,} = resource;
        const tooltip = (
            <Tooltip id="tooltip">
                <div className="text-left">
                    <strong>Remove Proposal</strong><span> - option that allow removing proposal</span>
                </div>
            </Tooltip>
        );
        const proposalsOptions = this.proposalSearch.searchOptions();
        return (
            <div id="transfer_plant">
                <h4 className="transfer_plant_title">
                    <span className="mr-5">Remove Proposal</span>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mb2 ml-4"/>
                    </OverlayTrigger>
                </h4>
                <div className="transfer_plant_inputs">
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        onInputChange={this.proposalSearch.search}
                                        value={select(proposalsOptions, proposal_id)}
                                        options={proposalsOptions}
                                        placeholder="Proposal #"
                                        onMenuClose
                                        onChange={this.selectResourceAttr('proposal_id')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={10} className='text-right'>
                            <Button className="transfer_btn removal_btn_width"
                                    disabled={!proposal_id}
                                    onClick={e => this.removeProposal(e, resource)}>
                                <div className="vcenter">
                                    <FontAwesomeIcon icon={faTrash} className="mr-9 font16"/>
                                    <span>Remove Proposal</span>
                                </div>
                            </Button>
                        </Col>
                        <Col md={2}/>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientsList: state.admin.clientsList,
        state
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveProposal);