import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWindowClose, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import PropTypes from "prop-types";
import {dateFormatter} from "../../../../components/BootStrapTableCellFormatters";


const VersionHistoryModalHeader = ({modalHeaderData, tableViewOn, onHide, toggleView}) => {

    return (
        <div className='vh-modal-header'>
            <div>
                {tableViewOn
                    ? <p className='font16'>Version History</p>
                    : <>
                        <p className='font16'>{modalHeaderData.activity}</p>
                        <p><strong>Date/Time:</strong> {dateFormatter(modalHeaderData.date_time)}</p>
                        <p><strong>Edited by:</strong> {modalHeaderData.edited_by}</p>
                    </>
                }
            </div>
            <div className='vh-modal-header__btns'>
                {!tableViewOn && <FontAwesomeIcon icon={faArrowLeft}
                                                  onClick={() => toggleView(modalHeaderData)}/>}
                <FontAwesomeIcon icon={faWindowClose}
                                 onClick={() => onHide()}/>
            </div>

        </div>
    );
};


VersionHistoryModalHeader.propTypes = {
    tableViewOn: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    modalHeaderData: PropTypes.object.isRequired,
    toggleView: PropTypes.func
}


export default VersionHistoryModalHeader;