import React, {useEffect, useState} from 'react'
import {Button, Col, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import * as Actions from './EmailsApi'
import {connect} from "react-redux";
import PageHeader from "../../../components/PageTitle";
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import {dateFormatter} from "../../../components/BootStrapTableCellFormatters";
import './Emails.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadsFilterModal from "../../LeadsPage/LeadsFilterModal";
import EmailsFilters from "./EmailsFilters";
import {getClientsSearch} from "../NewAdminPage/AdminPageApi";
import {DocAttachment} from "../../../components/docs/DocAttachment";
import {updateExternalLinks} from "../../../common/commonHandlers";

const actions = {
    ...Actions,
    getClientsSearch
}

const isMobile = window.screen.width <= 1024;

const Emails = ({actions, name}) => {
        const [emails, setEmails] = useState([])
        const [page, setPage] = useState(1)
        const [per_page, setPer_page] = useState(25)
        const [total, setTotal] = useState(0)
        const [isModalOpen, setIsModalOpen] = useState(false)
        const [resource, setResource] = useState({client_ids: []})
        const [usersMapFilterSelectedClients, setUsersMapFilterSelectedClients] = useState([])
        const [clientOptions, setClientOptions] = useState([])

        const loadEmails = () => actions.getEmails(resource, page, per_page, res => {
            setEmails(res)
            setTotal(res[0]?.total || 0)
        })

        useEffect(() => {
            loadEmails()
        }, [page, per_page])

        const renderSizePerPageDropDown = (props) => {
            const {toggleDropDown} = props;

            return (
                <SizePerPageDropDown
                    className='my-size-per-page'
                    variation='dropup'
                    onClick={() => this.onToggleDropDown(toggleDropDown)}
                    currSizePerPage={per_page}
                />
            );
        };

        const onChangeFilters = (key, value) => {
            setResource({...resource, [key]: value})
        };

        const toggleModal = (e) => {
            e.preventDefault();
            setIsModalOpen(!isModalOpen);
        };
        
        const attachmentsFormatter = (cell, row) => {
            return <div className="columnDirection">
                {row.attachments && row.attachments.map((doc, idx) =>
                    <DocAttachment key={idx}
                                   file={doc}
                                   renderName={true}
                                   allowRemove={false}/>
                )}
            </div>;
        }   

        return (
            <div id='emailsList'>
                <Row>
                    <Col md={6} className="emails_header_wrapper">
                        <PageHeader pageName="Emails" pageDetail={`${total} records`}/>
                    </Col>
                    <Col md={6} className="justify-flex-end mt10">
                        <Button className="emails_btn"
                                onClick={e => toggleModal(e)}> Filter {" "}
                            <FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <Col md={12} className="remaining vertical-responsive-height no-padding" style={{width: isMobile ? "90vw" : ""}}>
                    <BootstrapTable
                        data={emails}
                        striped={true}
                        bordered={false}
                        hover={true}
                        pagination={true}
                        remote
                        fetchInfo={{dataTotalSize: total}}
                        options={{
                            onPageChange: (page, per_page) => {
                                setPage(page)
                                setPer_page(per_page)
                            },
                            page,
                            sizePerPageDropDown: renderSizePerPageDropDown,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                }],
                            sizePerPage: per_page,
                        }
                        }
                    >
                        <TableHeaderColumn hidden isKey dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataField="from" width={isMobile ? '100px' : '10%'}>
                            From
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataField="reply_to" width={isMobile ? '100px' : '10%'}>
                            Reply To
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataField="email_subject" width={isMobile ? '150px' : '10%'}>
                            Subject
                        </TableHeaderColumn>
                        {isMobile && <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataField="recipient" width='150px'>
                            Recipient
                        </TableHeaderColumn>}
                        {isMobile && <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}  dataField="created_at" width='120px' dataFormat={dateFormatter}>
                            Send date
                        </TableHeaderColumn>}
                        {isMobile && <TableHeaderColumn dataField="attachments" width='150px' dataFormat={attachmentsFormatter}>
                            Attachments
                        </TableHeaderColumn>}
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataField="message" width={isMobile ? '1000px' : '35%'}
                        dataFormat={(cell) => {
                            cell = updateExternalLinks(null, cell)
                            return <div className='message' dangerouslySetInnerHTML={{__html: cell}}/>
                        }}>
                            Message
                        </TableHeaderColumn>
                        {!isMobile && <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataField="recipient" width='15%'>
                            Recipient
                        </TableHeaderColumn>}
                        {!isMobile && <TableHeaderColumn tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}  dataField="created_at" width='10%' dataFormat={dateFormatter}>
                            Send date
                        </TableHeaderColumn>}
                        {!isMobile && <TableHeaderColumn dataField="attachments" width='10%' dataFormat={attachmentsFormatter}>
                            Attachments
                        </TableHeaderColumn>}
                    </BootstrapTable>
                </Col>
                {isModalOpen &&
                <LeadsFilterModal
                    title="Emails Filter"
                    show={isModalOpen}
                    onHide={() => {
                        setIsModalOpen(false)
                        page===1? loadEmails() : setPage(1)
                    }}
                    closeButton={() => setIsModalOpen(false)}
                >
                    <EmailsFilters
                        resource={resource}
                        onChangeFilters={onChangeFilters}
                        getClientsSearch={actions.getClientsSearch}
                        selectedClients={usersMapFilterSelectedClients}
                        onChangeSelectedClients={e => setUsersMapFilterSelectedClients(e)}
                        clientOptions={clientOptions}
                        onChangeClientOptions={e => setClientOptions(e)}
                    />
                </LeadsFilterModal>
                }
            </div>
        );
    }
;

const mapStateToProps = state => (
    {
        name: state.auth.name,
        client: state.client.customerInfo,
    }
)

const mapDispatchToProps = dispatch => (
    {
        actions: bindActionCreators(actions, dispatch),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Emails)