import React from "react";
import {Col, Glyphicon, MenuItem, Nav, NavDropdown, NavItem, Row} from "react-bootstrap";
import MDSpinner from "react-md-spinner";

const CustomerSitesList = ({customerSites, onSelect, employees, ready, disabled, openMergeSitesModal}) => {
    const arboristColor = (arborist) => {
        if (employees.length > 0) {
            const findEmployee = employees.find(e => e.label === arborist)
            if (findEmployee) {
                const employeeColor = findEmployee.color ? findEmployee.color : "#000"
                return {color: `${employeeColor}`}
            } else {
                return {color: "#000"}
            }
        } else {
            return {color: "#000"}
        }
    }

    return ready ? <Row>
            <Col xs={12} className="no-padding">
                <div className="scroll-y container-h308 customer_list">
                    {customerSites.map(s => {
                        return (
                            <div className='site-container'>
                                <Nav key={s.id}
                                     bsStyle="pills"
                                     stacked
                                     onSelect={() => !disabled && onSelect({show: true, id: s.id})}
                                >
                                    <NavItem key={s.id}
                                             eventKey={s.id}
                                             style={{backgroundColor: s.inactive ? "#ffebee" : ''}}
                                    >
                                        <Row className={`site-row ${disabled ? "disabled" : ""}`}>
                                            <Col xs={7}>
                                                <div style={{marginBottom: '5px'}}>
                                                    <b className={'contact'}>{s.name}</b>
                                                </div>
                                                <Row className="site-description">
                                                    <Col xs={6} className="contact text-right small-padding-right">
                                                        Site Contact
                                                    </Col>
                                                    <Col xs={6} className="small-padding-left contact  font12">
                                                        <b>{s.site_contact_person_name}</b>
                                                    </Col>
                                                </Row>
                                                <Row className="site-description">
                                                    <Col xs={6} className="contact text-right small-padding-right">
                                                        Recent Arborist
                                                    </Col>
                                                    <Col xs={6} className="small-padding-left font12">
                                                        <b className={'font-weight-normal'}
                                                           style={arboristColor(s.last_site_arborist)}>
                                                            {s.last_site_arborist}
                                                        </b>
                                                    </Col>
                                                </Row>
                                                <Row className="site-description">
                                                    <Col xs={6} className="contact text-right small-padding-right">
                                                        Site ID
                                                    </Col>
                                                    <Col xs={6} className="small-padding-left font12"><b>{s.id}</b></Col>
                                                </Row>
                                            </Col>
                                            <Col xs={5} className="text-right d-flex p-5">
                                                <b>{s.full_address}</b>
                                            </Col>
                                        </Row>
                                    </NavItem>
                                </Nav>
                                <div className="site-dropdown">
                                    <NavDropdown
                                        title={<Glyphicon glyph="option-vertical" className="bigger"/>}
                                        noCaret
                                    >
                                        <MenuItem onClick={() => openMergeSitesModal(s)}>
                                            Merge Sites
                                        </MenuItem>
                                    </NavDropdown>
                                </div>
                            </div>
                        )
                    })}
                    {customerSites.length === 0 && <p className="text-center">No Sites Matches</p>}
                </div>
            </Col>
        </Row>
        :
        <div className="vhCenter full-height">
            <MDSpinner size={70} borderSize={2}/>
        </div>
}

export default CustomerSitesList;