import axios from 'axios'
import { addAlert } from '../App/actions'
import { PROPOSAL_FETCHED } from './constants'

export function fetchProposal(proposalId) {
  return function(dispatch, getState) {
    const store = getState()

    const config = {
      method: 'get',
      headers: { Authorization: store.auth.token },
      url: '/api/proposals/' + proposalId
    }

    axios
      .request(config)
      .then(response => {
        dispatch({ type: PROPOSAL_FETCHED, fetchedProposal: response.data })
        // dispatch(addAlert({message: "Proposal loaded", dismissAfter: 1500, mood: 'success'}))
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          dispatch(addAlert({ message: 'Proposal not found' }))
        }
      })
  }
}

export function fetchDeletedProposal(proposalId) {
  return function(dispatch, getState) {
    const store = getState()

    const config = {
      method: 'get',
      headers: { Authorization: store.auth.token },
      url: '/api/proposals/' + proposalId,
      params: {
        isDeleted: true
      }
    }

    axios
        .request(config)
        .then(response => {
          dispatch({ type: PROPOSAL_FETCHED, fetchedProposal: response.data })
          // dispatch(addAlert({message: "Proposal loaded", dismissAfter: 1500, mood: 'success'}))
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            dispatch(addAlert({ message: 'Proposal not found' }))
          }
        })
  }
}
