import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const getUnfinishedRegistrations = (pagination, sorting, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/register/get_registers`,
        params: {
            ...pagination,
            ...sorting
        }
    }

    apiCall("Registers load successfully", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};
