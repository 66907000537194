import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Grid} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import * as actions from "./ReferralReportListApi";
import Dollars from "../../components/Dollars";

class ReferralReportList extends ResourceComponent {
    state = {
        resource: {
            page: 1,
            per_page: 25
        },
        data: [],
        total: 0,
    }

    constructor(props, context) {
        super(props, context);
        this.loadData()
    }

    loadData = () => {
        const query = this.props.location.query;
        const {resource} = this.state
        const filters = {
            referral_id: this.props.params.id,
            from: query.from,
            to: query.to,
            page: resource.page,
            per_page: resource.per_page
        };
        this.props.actions.loadProposals(filters, result => {
            this.setState({...result})
        })
    };

    proposalFormatter = (cell, row) => {
        return <a href={`/mapview/${row.proposal_id}`} className="bold"
                  target="_blank">{row.proposal_no}</a>
    };
    priceFormatter = (cell, row) => cell ? <Dollars amount={cell}/> : 'N/A';

    render() {
        const {data, total, resource} = this.state;
        const {page, per_page} = resource;
        return (
            <Grid fluid>
                <BootstrapTable
                    bordered={false}
                    striped
                    hover
                    search
                    fetchInfo={{dataTotalSize: total}}
                    pagination
                    remote
                    ref="table"
                    data={data}
                    className="wrapped"
                    options={
                        {
                            onPageChange: (page, per_page) => {
                                let {resource} = this.state;
                                resource = {...resource, page, per_page};
                                this.setState({resource}, this.loadData);
                            },
                            page,
                            sizePerPage: per_page,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                }
                            ]
                        }}
                >
                    <TableHeaderColumn
                        dataField="proposal_no"
                        dataFormat={this.proposalFormatter}
                        width={"50%"}
                        isKey
                    >
                        Proposal No
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proposal_price"
                        dataFormat={this.priceFormatter}
                        width={"50%"}
                    >
                        $ Proposal
                    </TableHeaderColumn>
                </BootstrapTable>
            </Grid>
        );
    }
}

ReferralReportList.propTypes = {
    //myProp: PropTypes.object.isRequired
};

ReferralReportList.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralReportList);