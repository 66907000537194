import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import React, {useState} from "react";
import './CustomerMergeSitesModal.scss'

const CustomerMergeSitesModal = ({site, customerSites, onHide, onSuccess, mergeCustomerSites}) => {
    const [selectedSites, setSelectedSites] = useState([])
    const sitesOptions = customerSites.map(site => ({value: site.id, label: `${site.name} (${site.full_address})`}));

    const mergeSites = () => {
        mergeCustomerSites(site.id, selectedSites.map(s => s.value), () => {
            onSuccess()
            onHide()
        })
    }

    return <Modal
        bsSize="large"
        show={true}
        onHide={onHide}
        backdrop="static"
        className="heightAuto fontIOS"
        id='customer-merge-sites-modal'
    >
        <Modal.Header closeButton>
            <Modal.Title>
                Merge Sites: {site.name}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{paddingTop: "10px !important"}}>
            <Col>
                <FormGroup bsSize="small">
                    <ControlLabel>
                        Sites
                    </ControlLabel>
                    <Select className="Select" classNamePrefix="select"
                            name="sites_ids"
                            isMulti
                            isClearable
                            placeholder="Select..."
                            onChange={e => setSelectedSites(e)}
                            options={sitesOptions.filter(s => s.value !== site.id)}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup bsSize="small">
                    <ControlLabel>
                        Merge To
                    </ControlLabel>
                    <FormControl
                        placeholder={'Select'}
                        className={`form-control-xs`}
                        value={`${site.name} - ${site.full_address}`}
                        disabled={true}
                        onChange={e => {
                        }}
                    />
                </FormGroup>
            </Col>
        </Modal.Body>
        <Modal.Footer>
            <Row className="vertical-align">
                <Col className="text-left" xs={6}>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        onClick={onHide}
                    >
                        Cancel
                    </Button>
                </Col>
                <Col className="text-right" xs={6}>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        type="submit"
                        onClick={mergeSites}>
                        Save Site
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default CustomerMergeSitesModal;