import {ADD_ALERT, CLEAR_ALERT, CLEAR_ALL_ALERTS, IS_PROCESSING} from './constants'
import {SET_CLIENT_APP_VERSION, SET_NEW_CLIENT_APP_VERSION} from "../LoginPage/constants";

export const addAlert = (alertMessage) => (dispatch) => {
    alertMessage.id = new Date().valueOf()
    dispatch({
        type: ADD_ALERT,
        alertMessage: alertMessage
    })
}

export const clearAlert = (id) => {
    return {type: CLEAR_ALERT, id: id}
}

export const clearAllAlerts = () => {
    return {type: CLEAR_ALL_ALERTS}
}

export const isProcessing = (isProcessing) => {
    return {type: IS_PROCESSING, isProcessing: isProcessing}
}

export const setClientAppVersion = (version) => dispatch => {
    dispatch({
        type: SET_CLIENT_APP_VERSION,
        version: version
    });
};

export const setNewClientAppVersion = version => dispatch => {
    dispatch({
        type: SET_NEW_CLIENT_APP_VERSION,
        version: version
    });

};
