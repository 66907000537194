import React from 'react';
import PropTypes from "prop-types"
import {Glyphicon} from "react-bootstrap"
import './PdfAttachment.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";


const PdfAttachment = ({pdf, deletePdf, disableRedirect=false, downloadButton = false, downloadResource, allowRemove = true}) => {

    return (
        <div className='pdf_attachment'>
            <div className='pdf_object'>
                <object data={pdf.original || pdf.url} type="application/pdf" height='50px' width="70px"/>
            </div>
            <a className='attachment_link' href={!disableRedirect && pdf.original || pdf.url} title="Open in a new window" target="_blank"/>
            {(deletePdf && allowRemove) &&
                <a
                    className="remove_attachment_icon"
                    title="Delete this image"
                    onClick={() => deletePdf(pdf.id)}
                >
                    {allowRemove && <span className="text-danger">
                              <Glyphicon glyph="remove-sign" className="pointer"/>
                            </span>}
                </a>
            }
            {downloadButton && <a title={'Download'} className='download_attachment_icon' onClick={() => downloadResource(pdf.original, pdf.name)}>
                <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
            </a>}
        </div>
    )
}

PdfAttachment.propTypes = {
    deletePdf: PropTypes.func,
    pdf: PropTypes.object.isRequired,
    disableRedirect: PropTypes.bool
};

export default PdfAttachment;