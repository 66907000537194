import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {handleUpload} from "../../common/upload";
import {addAlert} from "../App/actions";

export const load = (options, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stickies?limit=${options.count}${options.userId
            ? `&user_id=${options.userId}` : ''}${options.arboristId
            ? `&arborist=${options.arboristId}` : ''}&type=${options.type}&unread_only=${options.unreadOnly}${options.proposalId
            ? `&proposal_id=${options.proposalId}` : ''}${options.employeeId
            ? `&employee_id=${options.employeeId}` : ''}${options.employee_arborist
            ? `&employee_arborist=${options.employee_arborist}` : ''}`,
        method: 'GET'
    };

    apiCall("Loading notifications", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const search = (phrase, params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stickies/search?phrase=${phrase}`,
        method: 'GET',
        params: params
    };

    apiCall("Searching", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const markAsRead = (userId, type, employee_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${userId}/mark_notifications_as_read?type=${type}&employee_id=${employee_id}`,
        method: 'PUT'
    };

    apiCall("Marking notification as read", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const removeNote = (sticky, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stickies/${sticky.id}`,
        method: 'DELETE'
    };

    apiCall("Removing sticky", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const hideNote = (sticky, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stickies/${sticky.id}/hide`,
        method: 'PUT'
    };

    apiCall("Hiding sticky", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const readNote = (sticky, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stickies/${sticky.id}/read`,
        method: 'PUT'
    };

    apiCall("Marking notification as read", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const complete = (sticky, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stickies/${sticky.id}/complete`,
        method: 'PUT'
    };

    apiCall("Marking notification as complete", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const addNote = (sticky, data, callback) => (dispatch, getState) => {
    const url = sticky.id === 'new' ? '/api/stickies/' : `/api/stickies/${sticky.id}/reply`;
    const method = sticky.id === 'new' ? 'POST' : 'PUT';
    const config = {
        url,
        data: {
            sticky: data
        },
        method
    };

    apiCall("Adding note", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const uploadImages = (acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload`, acceptedFiles, callback)(dispatch, getState);
};

export const uploadAttachment = (acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload('/api/emails/upload_attachment', acceptedFiles, callback)(dispatch, getState);
};

export const sendIssue = (issue, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/issues/`,
        method: 'POST',
        data: {issue}
    };

    apiCall("Issue sending", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const sendEmail = (email, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/emails/send_email`,
        method: 'POST',
        data: {email}
    };

    apiCall("Email sending", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback);
};

export const getCrewMessages = (type, personId, workOrderId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages_user`,
        params: {type, author: personId, workOrderId}
    };

    apiCall("Getting user crew messages", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getEmployees = (showDisabledUsers, callback) => async (dispatch, getState) => {
    const config = {
        url: showDisabledUsers ? `/api/search/all_employees?disabled=true` : `/api/search/all_employees`
    };

    apiCall("Getting employees", config, async config => {
        const response = await axios.request(config);
        callback && callback({employees: response.data});
    }, dispatch, getState, false);
};

export const markAsReadCrewMessages = (crew_message_user_id, mark_all_as_read, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages/mark_as_read`,
        method: "PUT",
        data: {
            crew_message_user_id: crew_message_user_id,
            mark_all_as_read
        }
    };

    apiCall("Mark message as read", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const markAsReadCrewMessagesWO = (work_order_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages/mark_as_read`,
        method: "PUT",
        data: {
            work_order_id: work_order_id
        }
    };

    apiCall("Reading crew messages", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const getUserStatus = (userId, clientId, callback) => async (dispatch, getState) => {
    const store = getState();
    const config = {
        method: "get",
        url: `/api/users/${userId}/status`,
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        dispatch({type: 'GOT_USER_STATUS', userStatus: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Internal error, please contact the administrator",
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }

    callback && callback()
};

export const getActiveEmployees = (showDisabledUsers, callback) => async (dispatch, getState) => {
    const config = {
        url: showDisabledUsers ? `/api/search/all_employees?disabled=true` : `/api/search/all_employees`
    };

    apiCall("Getting employees", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const saveShowDisabledUsers = (showDisabledUsers, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_disabled_users_for_notes`,
        method: "PUT",
        data: {show_disabled: showDisabledUsers}
    };

    apiCall("Update users", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getShowDisabledUsers = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users/get_disabled_users_for_notes`
    };

    apiCall("Getting users", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getUsers = (show_disabled, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users?show_disabled=${show_disabled}&to_stickies=true`,
    };

    apiCall("Getting users", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
