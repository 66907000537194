import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const getCustomer = (id, page, perPage, sort, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/assigned_arborist/`,
        params: {
            id: id,
            page: page,
            per_page: perPage,
            sort: sort.sort_order,
            sort_by: sort.sort_by
        }
    };

    apiCall("Loading customer", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false, errorCallback);
};

export const filterAssignedArborist = (id, page, perPage, sort, filteredSelectedArborist, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/assigned_arborist/filter_assigned_arborist/`,
        params: {
            id: id,
            page: page,
            per_page: perPage,
            sort: sort.sort_order,
            sort_by: sort.sort_by,
            filteredSelectedArborist: filteredSelectedArborist
        }
    };

    apiCall("Loading proposals", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const isAssignedArborist = ({id, arboristId, eventType, scheduler_event_guests}, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/assigned_arborist/is_assigned_arborist/`,
        params: {
            id: id,
            arborist_id: arboristId,
            eventType: eventType,
            scheduler_event_guests
        }
    };

    apiCall("Loading proposals", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};