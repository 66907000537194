import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, FormControl, FormGroup, Glyphicon, Grid, Panel, Row} from "react-bootstrap";
import {browserHistory, Link, withRouter} from "react-router";
import Select from "react-select";
import {debounce} from 'throttle-debounce';
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment";
import SchedulerEventDialog from "../Scheduler/SchedulerEventDialog";
import {defaultDateFormat, defaultDateTimeFormat, defaultMapParams, mapForSelect, select} from "../../common/commonHandlers";
import ReasonModal from "./ReasonModal";
import * as actions from "./LeadApi";
import * as leadsApi from "./LeadsApi";
import * as findTimeApi from "./FindTimeApi";
import ResourceComponent from "../../components/ResourceComponent";
import * as emailActions from "../../components/email/actions"
import {
    checkboxFormatter,
    dateFormatter,
    descriptionFormatter,
    optionsFormatter
} from "../../components/BootStrapTableCellFormatters";
import {getColorIcon} from "../MapViewPage/IconProvider";
import Dollar from "../../components/Dollars";
import Reason from "../lists/Reason";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AssignedArboristModal from "../AssignedArboristModal/AssignedArboristModal";
import * as AAModalApi from "../AssignedArboristModal/AssignedArboristModalApi"
import {getBasicMarker} from "../../utilities";

class LeadForm extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(500, this.search);
        this.state = {
            showModal: false,
            resource: {
                created_date: moment(),
                scheduler_events: [],
                person_id: null,
                site_id: null,
                full_address: null
            },
            customerSearchResults: [],
            sites: [],
            employees: [],
            referrals: [],
            reasons: [],
            scheduler_event_id: null,
            pendingSiteSelect: false,
            reasonModalShow: false,
            showAssignedArboristModal: false
        };
    }

    addressLead = () => {
        const {resource, customerSearchResults} = this.state;
        let address = customerSearchResults.find(x => x.id === resource.customer_id)?.addresses?.find(x => x.id === resource.full_address)
        resource.full_address = address?.name
        resource.address_longitude = address?.longitude
        resource.address_latitude = address?.latitude
        return resource
    }

    saveAndRedirect = () => {
        const {resource} = this.state;
        let newResource = resource;
        if(resource.full_address) {
            newResource = this.addressLead()
        }
        this.props.actions.saveLead(newResource, result => {
            browserHistory.push(`/lead/${result.id}/time`);
        });
    };

    findTimeForCustomer = () => {
        const {userRoles} = this.props;
        const {resource, customerSearchResults, user, stats} = this.state;
        const customer = customerSearchResults[0];
        resource.customer_id = customer.id;
        resource.person_id = (userRoles.includes('sales_arborist') && user.person_id) || customer.person_id;
        resource.referral_id = stats.referral;
        resource.reason_id = stats.reason;
        const newState = {resource};
        let callback = undefined;
        const sites = customer.sites;
        const addresses = customer.addresses;
        if(sites.length > 0) {
            if (sites.length === 1) {
                resource.site_id = sites[0].id;
                callback = this.saveAndRedirect;
            } else {
                newState.pendingSiteSelect = true;
            }
        } else {
            if (addresses.length === 1) {
                resource.full_address = addresses[0].id;
                callback = this.saveAndRedirect;
            } else {
                newState.pendingSiteSelect = true;
            }
        }
        this.setState(newState, callback);
    };

    setDefaultStateForNew = (result) => {
        const {userRoles} = this.props;
        const {resource} = this.state;
        resource.person_id = userRoles.includes('sales_arborist') && result.user.person_id;
        this.setState({resource});
    };

    componentDidMount() {
        const id = this.props.id;
        const customerId = this.props.location.query.customer_id;
        this.props.actions.load(result => {
            this.setState(result);
            if (customerId) {
                this.props.actions.getCustomer(customerId, result => {
                    const coordsAddress1 = result.customerSearchResults[0].coords_address_1
                    const coordsAddress2 = result.customerSearchResults[0].coords_address_2
                    const sites = result.customerSearchResults[0].sites

                    const addresses = this.props.findTimeApi.addressesFormater(result.customerSearchResults[0], coordsAddress1, coordsAddress2)
                    result.customerSearchResults[0].addresses = addresses
                    this.setState({...result, resource: {...this.state.resource, emptySite: sites.length > 0 ? false : true}}, this.findTimeForCustomer)});
            }
            else if (id) {
                this.reload(id);
            } else {
                this.props.findTimeApi.getAddressCoordination(result, () => this.setDefaultStateForNew(result))
            }
        });
    }

    loadReasons = () => {
        this.props.actions.load(result => {
            this.setState(result);
            this.selectResourceAttr('reason_id')(result.reasons[result.reasons.length - 1])
        });
    }

    reload(id) {
        this.props.actions.getLead(id, (result) => {
            const resource = result.resource;
            resource.created_date = moment(resource.created_date).format(defaultDateTimeFormat);
            this.setState(result);
        });
    }

    guestsFormatter(cell) {
        return <ul>
            {cell.map(guest =>
                <li>{guest.first_name} {guest.last_name}</li>
            )}
        </ul>
    }

    search = e => {
        e !== '' && this.props.actions.searchForCustomer(e, customerSearchResults => {
            this.props.findTimeApi.getAddressCoordination({customerSearchResults: customerSearchResults}, () =>
                this.setState({customerSearchResults: customerSearchResults}))

        })
    };

    remove() {
        const choice = window.confirm('Please be sure! You are about to delete this Lead and all tasks and scheduled events associated with it!')
        const {resource} = this.state;
        if (choice) {
            this.props.leadsApi.remove(resource.id, () => {
                browserHistory.push('/leads');
            });
        }
    };

    emailCustomer = (id) => {
        const {email} = this.props;
        const {email_address} = this.state.resource;
        this.props.emailActions.showEmailDialog(
            {
                emailType: "scheduler_event",
                referenceId: id,
                recipient: email_address,
                defaultEmail: email,
                withFollowUpDate: false
            });
    };

    handleProposalIdChange = (id, proposal_id) => {
        if (!proposal_id) {
            this.reload(id)
        } else {
            browserHistory.push(`/mapview/${proposal_id}`)
        }

    };

    onSaveAddress = resource => {
        let newResource = resource;
        if(resource.full_address) {
            newResource = this.addressLead()
        }
        return newResource
    }

    onSave = () => {
        const {resource} = this.state
        this.props.AAModalApi.isAssignedArborist({id: resource.customer_id, arboristId: resource.person_id}, (result) => {
            if(result) {
                this.props.actions.saveLead(this.onSaveAddress(resource), result => {
                    this.setState({resource: {...resource, id: result.id}}, () => {this.reload(result.id); browserHistory.push(`/lead/${result.id}`)})
                })
            } else {
                this.setState({showAssignedArboristModal: true})
            }
        })
    };

    reasonModalShow = () => {
        this.setState({reasonModalShow: !this.state.reasonModalShow})
    }

    siteAddressValue = (sitesOptions) => {
        const {resource} = this.state
        if(resource.emptySite) {
            return select(sitesOptions, resource.full_address)
        } else {
            return select(sitesOptions, resource.site_id)
        }
    }

    mapPoints = (sites, addresses) => {
        if(sites.length > 0){
            return sites
        } else {
            return addresses
        }
    }

    dateFromFormatter = (cell, row) => {
        if (row?.all_day) {
            return `All Day (${moment(cell).utc().format(defaultDateFormat)})`
        } else {
            return cell ? moment(cell).format(defaultDateTimeFormat) : 'N/A'
        }
    }

    render() {
        const {
            showModal, customerSearchResults, scheduler_event_id,
            resource, employees, referrals, reasons, stats, pendingSiteSelect, showAssignedArboristModal
        } = this.state;
        const {context, client} = this.props;
        const statsSite = resource.customer_id && stats ? stats.sites.find(x => x.id === resource.site_id) : null;
        const statsTarget = statsSite || stats;
        const closeRate = statsTarget ? statsTarget.close_rate : null;
        const lifetimeSpend = statsTarget ? statsTarget.lifetime_spend : null;
        const balanceDue = statsTarget ? statsTarget.balance_due : null;
        const activeJobs = statsTarget ? statsTarget.active_jobs : null;

        const selectedCustomer = customerSearchResults.find(x => x.id === resource.customer_id);
        const sites = (resource.customer_id && selectedCustomer) ? selectedCustomer.sites : [];
        const addresses = (resource.customer_id && selectedCustomer && selectedCustomer.addresses) ? selectedCustomer.addresses : [];
        const site = resource.customer_id && selectedCustomer ? selectedCustomer.sites.find(x => x.id === resource.site_id) : null;
        const address = resource.customer_id && selectedCustomer ? selectedCustomer?.addresses?.find(x => x.id === resource.full_address) : null;
        const sitesOptions = mapForSelect(sites.length > 0 ? sites.filter(s => !s.site_moved) : addresses);
        const sitesOnMap = resource.site_id && site ? [site] : sites;
        const addressOnMap = (resource.full_address && address) ? [address] : addresses;
        const id = this.props.id;
        const customerOptions = mapForSelect(customerSearchResults);
        const primaryUrl = new URL(window.location.href).origin
        const customer_id = resource && resource.customer_id
        const lead_id = resource && resource.id

        let descriptionLine = ""
        if (customer_id && lead_id) {
            descriptionLine = `<br>Proposal: <a href=\"${primaryUrl + `/mapview?customer_id=${customer_id}&lead_id=${lead_id}`}\" target="_blank">Add</a><br/>`;
        }

        if (resource && resource.scheduler_events && resource.scheduler_events.length > 0) {
            resource.scheduler_events.forEach(scheduler_event => {
                if (scheduler_event.scheduler_event_guests && scheduler_event.scheduler_event_guests.length > 0) {
                    scheduler_event.scheduler_event_guests = scheduler_event.scheduler_event_guests.filter(x => x.id !== scheduler_event.person_id)
                }
                if (scheduler_event.description.indexOf(descriptionLine) > -1 && resource.proposal_id) {
                    const descriptionProposalNo = `<br/>Proposal: <a href=\"${primaryUrl + `/mapview/${resource.proposal_id}`}\" target=\"_blank\">${resource.proposal_no}</a><br/>`
                    scheduler_event.description = scheduler_event.description.replace(descriptionLine, descriptionProposalNo)
                } else if (scheduler_event.description.indexOf(descriptionLine) > -1) {
                    scheduler_event.description = scheduler_event.description.replace(descriptionLine, "")
                }
            })
        }

        return <Grid fluid>
            {this.props.menuRenderer(resource, this.onSave)}
            <Row className="top10">
                <Col md={6}>
                    {!id ? <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <b>Customer</b>
                                    <Select className="Select bottom10"
                                            classNamePrefix="select"
                                            value={select(customerOptions, resource.customer_id)}
                                            options={customerOptions}
                                            placeholder="Customer"
                                            onInputChange={this.delayedSearch}
                                            onChange={
                                                this.selectResourceAttr('customer_id', () => {
                                                        const {resource} = this.state
                                                        const {customer_id} = this.state.resource;
                                                        const selectedCustomer = customerSearchResults.find(x => x.id === customer_id);
                                                        this.setState({stats: null, loaded: false});
                                                        if (selectedCustomer != null) {
                                                            if (selectedCustomer.sites.length > 0) {
                                                                if (selectedCustomer.sites.length === 1) {
                                                                    this.selectResourceAttr('site_id')(mapForSelect(selectedCustomer.sites)[0]);
                                                                } else {
                                                                    this.selectResourceAttr('site_id')(null);
                                                                }
                                                                this.setState({resource: {...resource, emptySite: false, full_address: null}})
                                                            } else {
                                                                if (selectedCustomer?.addresses.length === 1) {
                                                                    this.selectResourceAttr('full_address')(mapForSelect(selectedCustomer?.addresses)[0]);
                                                                } else {
                                                                    this.selectResourceAttr('full_address')(null);
                                                                }
                                                                const address = selectedCustomer.addresses.find(a => a.id === resource.full_address)
                                                                this.setState({resource: {...resource, emptySite: true, site_id: null, address_longitude: address?.longitude, address_latitude: address?.latitude}})
                                                            }
                                                            customer_id && this.props.actions.getCustomerStats(customer_id, stats => {
                                                                const {resource} = this.state;
                                                                resource.referral_id = stats.referral;
                                                                resource.reason_id = stats.reason;
                                                                resource.person_id = selectedCustomer.person_id;
                                                                this.setState({stats, resource})
                                                            });
                                                        }
                                                    }
                                                )
                                            }
                                            isClearable
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <b>Site Address</b>
                                    {pendingSiteSelect &&
                                        <h3 className="text-success">Please select a site to find a time</h3>}
                                    <Select className="Select bottom10" classNamePrefix="select"
                                            value={this.siteAddressValue(sitesOptions)}
                                            options={sitesOptions}
                                            onChange={this.selectResourceAttr(resource.emptySite ? 'full_address' : 'site_id', () => {
                                                if (pendingSiteSelect) {
                                                    this.saveAndRedirect();
                                                } else {
                                                    this.setState({loaded: false});
                                                }
                                            })}
                                            placeholder="Site Address"
                                            isClearable
                                    />
                                </FormGroup>
                            </Col>
                        </Row> :
                        <Row>
                            <Col md={6} className="d-flex space-between">
                                <FormGroup>
                                    <b className="text-success">Customer</b>
                                    <h4 className="text-success">
                                        {resource.customer_contact && resource.customer_contact}
                                    </h4>
                                </FormGroup>
                                <FormGroup>
                                    <h4 className="text-danger">
                                        {resource.phone_number && resource.phone_number}
                                    </h4>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <b className="text-success">Site Address</b>
                                    <h4 className="text-success">
                                        {resource.site_address && resource.site_address}
                                    </h4>
                                </FormGroup>
                            </Col>
                        </Row>}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <b>Arborist</b>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(employees, resource.person_id)}
                                        options={employees}
                                        onChange={this.selectResourceAttr('person_id')}
                                        placeholder="Arborist"
                                        isClearable
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <b>Referral</b>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(referrals, resource.referral_id)}
                                        options={referrals}
                                        onChange={this.selectResourceAttr('referral_id')}
                                        placeholder="Referral"
                                        isClearable
                                />
                            </FormGroup>
                            <ReasonModal
                                onHide={this.reasonModalShow}
                                show={this.state.reasonModalShow}
                                modal={true}
                                refresh={this.loadReasons}>
                                <Reason modal={true}/>
                            </ReasonModal>
                            <FormGroup>
                                <b>Reason</b>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(reasons, resource.reason_id)}
                                        options={[...reasons, {label: 'add new', value: 'add_new'}]}
                                        onChange={(callback, action) => {
                                            if (callback && callback.value === 'add_new') {
                                                this.reasonModalShow();
                                            }
                                            this.selectResourceAttr('reason_id')(callback, action)
                                        }}
                                        placeholder="Reason"
                                        isClearable
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <b className="text-primary">Close Rate</b>
                                <h3 className="text-primary">
                                    {closeRate !== null ? `${closeRate}%` : '-'}
                                </h3>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <b className="text-primary">Lifetime Spend</b>
                                <h3 className="text-primary">
                                    {lifetimeSpend !== null ?
                                        <Dollar amount={lifetimeSpend}/> : '-'}
                                </h3>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <b>Notes</b>
                                <FormControl
                                    rows={5}
                                    componentClass="textarea" placeholder="Notes"
                                    name="notes"
                                    onChange={this.updateResourceAttr}
                                    value={resource.notes}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <b className="text-success">Active Jobs</b>
                                <h3 className="text-success">
                                    {activeJobs !== null ? <Dollar amount={activeJobs}/> : 0}
                                </h3>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <b className="text-danger">Balance Due</b>
                                <h3 className="text-danger">
                                    {balanceDue !== null ? <Dollar amount={balanceDue}/> : 0}
                                </h3>
                            </FormGroup>
                        </Col>
                    </Row>
                    {context && context === 'proposal' && <Row className="top-menu">
                        <Col xs={8}>
                            <Link
                                className={`${window.location.href.includes('/time') ? 'active' : ''} ${id ? '' : 'disabled'} small-margin`}
                                onClick={() => (id && browserHistory.push(`/lead/${id}/time`))}>Find a time</Link>
                            <Link className={id ? '' : 'disabled'} onClick={() => {
                                resource && browserHistory.push(`/mapview/${resource.proposal_id || ''}?lead_id=${id}&customer_id=${resource.customer_id}&site_id=${resource.site_id}`)
                            }}>{resource && !resource.proposal_id && 'Create a proposal'}</Link>
                        </Col>
                        <Col md={2} className="text-right">
                            <Link className="text-danger small-margin" onClick={() => {
                                !window.location.href.includes('/time') ? browserHistory.push('/leads') : browserHistory.push(`/lead/${id}`);
                            }}>
                                Cancel
                            </Link>
                            <Link className="text-primary small-margin" onClick={() => this.onSave()}>
                                Save
                            </Link>
                        </Col>
                    </Row>}
                </Col>
                <Col md={6}>
                    <GoogleMapLoader
                        containerElement={
                            <div
                                id="leadMap"
                                className="margin10"
                                style={{
                                    height: "400px",
                                    width: "100%",
                                }}
                            />
                        }
                        googleMapElement={
                            <GoogleMap
                                ref={it => this.refGoogleMap(it, this.mapPoints(sitesOnMap, addressOnMap))}
                                {...defaultMapParams(this.mapPoints(sitesOnMap, addressOnMap), null, null, client)}
                            >
                                {this.mapPoints(sitesOnMap, addressOnMap).map(point => {
                                    const employee = select(employees, resource.person_id);
                                    const employeeColor = (employee ? employee.color : '#3a87ad');
                                    return <Marker
                                        key={point.id}
                                        position={{lat: point.latitude, lng: point.longitude}}
                                        icon={getColorIcon(employeeColor)}
                                        title={point.full_name}
                                        onClick={() => {
                                            sitesOnMap.length > 0 ?
                                            this.setState({resource: {...resource, site_id: point.id}, loaded: false}) :
                                            this.setState({resource: {...resource, full_address: point.id}, loaded: false});
                                        }}
                                    />
                                })}
                            </GoogleMap>
                        }
                    />
                </Col>
            </Row>
            <Panel
                collapsible
                expanded={false}
            />
            <Row>
                <Col md={12}>
                    <Button
                        onClick={() => {
                            this.setState({showModal: true});
                        }}
                        bsSize="sm"
                        bsStyle="success"
                        className="small-button"
                        disabled={!resource.id}
                    >
                        Add Scheduler Event
                    </Button>
                </Col>
                <Col md={12}>
                    <BootstrapTable
                        data={resource.scheduler_events}
                        striped={true}
                        bordered={false}
                        hover={true}
                        search={true}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            hidden
                            isKey
                        />
                        <TableHeaderColumn
                            dataField="scheduler_event_type_name"
                            dataSort={true}
                        >
                            Type
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="name"
                            dataSort={true}
                        >
                            Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="person_name"
                            dataSort={true}
                        >
                            Arborist
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="scheduler_event_guests"
                            dataSort={true}
                            dataFormat={this.guestsFormatter}
                        >
                            Guests
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="description"
                            dataSort={true}
                            tdStyle={{whiteSpace: 'normal'}}
                            dataFormat={descriptionFormatter}
                        >
                            Description
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="on_site"
                            dataSort={true}
                            dataFormat={checkboxFormatter}
                        >
                            On Site
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="date_from"
                            dataSort={true}
                            dataFormat={(cell, row) => (<strong className="text-success">{this.dateFromFormatter(cell, row)}</strong>)}
                        >
                            Task Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="created_at"
                            dataSort={true}
                            dataFormat={dateFormatter}
                        >
                            Created Date
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="completed"
                            dataSort={true}
                            dataFormat={checkboxFormatter}
                        >
                            Completed
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            width={"10%"}
                            dataFormat={optionsFormatter({
                                    onClick: id => this.setState(
                                        {showModal: true, scheduler_event_id: id}),
                                    remove: id => this.props.actions.remove(id, () => this.reload(resource.id))
                                }, (eventId) =>
                                    [
                                        <Link onClick={() => this.emailCustomer(eventId)} className="pointer small-margin">
                                            <Glyphicon glyph="envelope" className="bigger"/>
                                        </Link>,
                                        <Link to={`lead/${id}/time/${eventId}`} className="pointer small-margin">
                                            <FontAwesomeIcon icon="clock" className="bigger"/>
                                        </Link>
                                    ]
                            )}
                        >
                            Options
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="text-right">
                    <Button
                        onClick={() => this.remove()}
                        bsSize="xs"
                        bsStyle="danger"
                        disabled={resource.proposal_id !== null}
                    >
                        Delete Lead
                    </Button>
                </Col>
            </Row>
            {showModal && <SchedulerEventDialog
                handleClose={() => {
                    this.setState({showModal: false, scheduler_event_id: null});
                    this.reload(resource.id);
                }}
                event={{
                    id: scheduler_event_id,
                    scheduler_event_type_resource: 'Lead',
                    scheduler_event_resource_id: resource.id,
                    address_coordination: [address]
                }}
            />}
            {showAssignedArboristModal && <AssignedArboristModal
                showModal={showAssignedArboristModal}
                hideModal={() => this.setState({showAssignedArboristModal: false})}
                selectedCustomer={resource.customer_id}
                onSave={() =>
                    this.props.actions.saveLead(resource, result => {
                        this.setState({resource: {...resource, id: result.id}}, () => {this.reload(result.id); browserHistory.push(`/lead/${result.id}`)})
                    })}
            />}
        </Grid>;
    }
}

LeadForm.propTypes = {
    id: PropTypes.number.isRequired,
    menuRenderer: PropTypes.func.isRequired,
    context: PropTypes.string
    //myProp: PropTypes.object.isRequired
};

LeadForm.defaultProps = {
    context: 'lead'
};

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        users: state.users.employees,
        client: state.client.customerInfo,
        auth: state.auth,
        email: state.auth.email,
        userRoles: state.auth.roles
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        leadsApi: bindActionCreators(leadsApi, dispatch),
        findTimeApi: bindActionCreators(findTimeApi, dispatch),
        AAModalApi: bindActionCreators(AAModalApi, dispatch),
        emailActions: bindActionCreators(emailActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadForm));
