import React, {useState} from "react";
import {Button, ControlLabel, FormControl, FormGroup, Modal} from "react-bootstrap";
import {CrewMessageEditor} from "../Scheduler/CrewMessages/CrewMessagesEditor";

const DutyModal = ({duty, onHide, isOpen, updateDuty}) => {
    const [dutyEdit, setDutyEdit] = useState(duty)

    return (
        <Modal
            id="duty-modal"
            show={isOpen}
            onHide={onHide}
            backdrop="static"
            className={"heightAuto fontIOS"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add duty
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup className="mt5 bottom5">
                    <ControlLabel>Duty</ControlLabel>
                    <FormControl
                        type="text"
                        placeholder="Duty title"
                        value={dutyEdit?.title}
                        onChange={e => setDutyEdit({...dutyEdit, title: e.target.value})}
                    />
                </FormGroup>
                <FormGroup className="mt5 bottom5">
                    <ControlLabel>Description</ControlLabel>
                    <CrewMessageEditor
                        setValue={e => setDutyEdit({...dutyEdit, description: e})}
                        messageId={dutyEdit && dutyEdit.id}
                        reply={dutyEdit?.description}
                        customClass="duty-description-modal"
                    />
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    bsSize="small"
                    bsStyle="warning"
                    onClick={() => onHide()}
                >
                    Cancel
                </Button>
                <Button
                    bsSize="small"
                    bsStyle="success"
                    onClick={() => {
                        updateDuty(dutyEdit)
                        onHide()
                    }}
                    type="submit"
                >
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DutyModal;