import React from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";

const ErrorInfoModal = ({message, onHide, type, errorSites = undefined}) => {
    return (
        <Modal
            id="error-modal"
            show={true}
            onHide={onHide}
            backdrop="static"
            bsSize="large"
            className={"heightAuto fontIOS"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {type} synchronization error
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{message}</h5>
                {errorSites && errorSites.length > 0 &&
                    errorSites.map((site, i) => {
                        return <Row>
                            <Col xs={1} className="text-right">{i + 1}.</Col>
                            <Col xs={3}><p>{site.site_name}</p></Col>
                            <Col xs={7}><p>{site.error_message}</p></Col>
                            <Col xs={1}/>
                        </Row>
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    bsSize="small"
                    bsStyle="success"
                    onClick={() => onHide()}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ErrorInfoModal;