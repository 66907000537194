import React from 'react';
import {Button, Col, FormGroup, Row, Tooltip, OverlayTrigger} from "react-bootstrap";
import * as actions from './TransferCustomerAPI'
import './TransferCustomer.css';
import Select from "react-select";
import {select} from "../../../common/commonHandlers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExchangeAlt, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import ResourceComponent from "../../../components/ResourceComponent";
import {bindActionCreators} from "redux";
import ColorCheckbox from "../../../containers/Scheduler/ColorCheckbox";
import {connect} from "react-redux";

class TransferCustomer extends ResourceComponent {

    constructor(props) {
        super(props);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };

        this.state = {
            resource: {
                customer_id: null,
                client_id: null,
                allowTransferInvoice: false,
            },
            transferred_data: ['Sites', 'Leads', 'Proposals', 'Scheduler Events', 'Assets', 'Services', 'Invoices (optional)'],
        }
    }

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', (searchText, callback) => this.props.actions.searchForCustomer({searchText, clientId: this.props.clientId}, callback));
    }

    transferCustomer = (e, resource) => {
        e.preventDefault();
        this.props.actions.transferCustomer(resource, callback => this.setState(prevState => ({
            resource: {
                ...prevState.resource,
                customer_id: null,
                client_id: null,
                allowTransferInvoice: null
            }
        })))
    };
    copyCustomer = (e, resource) => {
        e.preventDefault();
        this.props.actions.copyCustomer(resource, callback => this.setState(prevState => ({
            resource: {
                ...prevState.resource,
                customer_id: null,
                client_id: null,
                allowTransferInvoice: null
            }
        })))
    };

    render() {
        const {resource, transferred_data} = this.state;
        const {customer_id, client_id, allowTransferInvoice} = resource;
        let clientListArr = [];
        const {clientsList, user_client} = this.props;
        clientListArr = clientsList && clientsList.filter(client => client.name !== user_client);
        const customerOptions = this.customerSearch.searchOptions();
        const clientArr = clientListArr && clientListArr.length > 0 && clientListArr.map((c) => {
            return {value: c.id, label: c.name}
        });

        const tooltip = (
            <Tooltip id="tooltip">
                <div className="text-left">
                    <strong>Customer transfer</strong><span> - option that allow transfer customer from one client to another</span>
                    <p className="mt10">Transferred customer's data: </p>
                    {transferred_data && transferred_data.map(data => (
                        <li className="transfer_tooltip_li">{data}</li>
                    ))}
                </div>
            </Tooltip>
        );

        return (
            <div id="transfer_customer">
                <h4 className="transfer_customer_title">
                    <span className="mr-5">Transfer Customer</span>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mb2"/>
                    </OverlayTrigger>
                </h4>
                <div className="transfer_customer_inputs">
                    <Row>
                        <Col md={5}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(customerOptions, customer_id)}
                                        options={customerOptions}
                                        placeholder="Select customer to transfer"
                                        isClearable
                                        onInputChange={this.customerSearch.search}
                                        onChange={this.selectResourceAttr('customer_id', this.handleFilterChange)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="transfer_customer_input">
                        <Col md={5}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(clientArr, client_id)}
                                        options={clientArr}
                                        placeholder="Select destination client"
                                        isClearable
                                        onChange={this.selectResourceAttr('client_id', this.handleFilterChange)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <ColorCheckbox className='no-left-padding'
                                   value={allowTransferInvoice}
                                   label='Transfer invoices'
                                   onChange={() => this.setState(prevState => ({
                                       resource: {
                                           ...prevState.resource,
                                           allowTransferInvoice: !allowTransferInvoice
                                       }
                                   }))}
                    />
                    <Row>
                        <Col md={5} className='text-right'>
                            <Button className="transfer_btn"
                                    disabled={!customer_id || !client_id}
                                    onClick={e => this.transferCustomer(e, resource)}>
                                <div className="vcenter">
                                    <FontAwesomeIcon icon={faExchangeAlt} className="mr-9 font16"/>
                                    <span>Transfer Customer</span>
                                </div>
                            </Button>
                            <Button className="copy_btn"
                                    disabled={!customer_id || !client_id}
                                    onClick={e => this.copyCustomer(e, resource)}
                                    bsStyle="success"
                            >
                                <div className="vcenter">
                                    <FontAwesomeIcon icon={faExchangeAlt} className="mr-9 font16"/>
                                    <span>Copy Customer</span>
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user_client: state.auth.client
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferCustomer);