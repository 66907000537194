import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import * as MyActions from './actions'
import {addAlert} from '../App/actions'

import {Button, Col, FormControl, FormGroup, Glyphicon, Panel, Row, Well} from 'react-bootstrap'

import moment from 'moment'

const Actions = {...MyActions, addAlert: addAlert}

class Stickies extends Component {

  constructor(props){
    super(props)
    this.state = ({
      replyOpen: true
    })
  }

  componentDidMount() {
  //  console.log("proposal id passed to Stickies was ", this.props.proposalId)
    if (this.props.proposalId !== undefined || this.props.proposalId !== null) {
      this.props.actions.fetchStickies(this.props.proposalId)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.proposalId !== this.props.proposalId) {
    //  console.log("props aren't the same. fetching new")
      this.props.actions.fetchStickies(nextProps.proposalId)
    }
  }


  renderSticky(sticky) {
    if (sticky === null || sticky === undefined) {
      return null
    }

    return (
    <Panel bsStyle="default">
      <Row>
        <Col xs={8}><span className='small'>{moment(sticky.created_at).format('llll')}</span></Col>
        <Col xs={4} className="text-right"><strong>{sticky.created_by}</strong></Col>
      </Row>

      <Row>
        <Col xs={12}>&nbsp;</Col>
      </Row>

      <Row>
        <Col xs={12}>{sticky.body}</Col>
      </Row>

      <Row>
        <Col xs={12}>&nbsp;</Col>
      </Row>

    </Panel>
    )
  }

  submitNewStickyForm(e) {
    e.preventDefault();// so no next page
    this.props.actions.createNewSticky()
    this.props.actions.fetchStickies(this.props.proposalId)
  }

  renderNewStickyForm() {
    return (
    <Well className="hidden-print">
      <form onSubmit={e => this.submitNewStickyForm(e)}>
        <FormGroup>
          <FormControl type="text" value={this.props.newStickyBody} placeholder="Your note"
                       onChange={(e) => this.props.actions.updateNewStickyBody(e.target.value)}/>
        </FormGroup>
        <Row>
        <Col xs={12}>
        <Button type="submit" bsStyle="success" bsSize="xsmall" className="pull-right"
                onClick={e => this.props.actions.submitNewStickyForm(e)}>Submit</Button>
        </Col>
        </Row>
      </form>
    </Well>
    )
  }

  updateReplyStickyBody(body, stickyId){
    this.props.actions.updateReplyStickyBody(body, stickyId)
  }

  submitReplyStickyForm(e) {
    e.preventDefault()
    this.props.actions.replyToSticky()
    this.props.actions.fetchStickies(this.props.proposalId)
  }

  renderThreadedView(sticky) {
    return (
    <Well key={sticky.id}>

        {sticky.sticky_type.includes('messages') && this.renderSticky(sticky)}

        {sticky.replies.map(reply => {
            return (
                reply.sticky_type.includes('messages') ?
                    <Row key={reply.id}>
                        <Col xs={11} xsOffset={1}>{this.renderSticky(reply)}</Col>
                    </Row> : null
            )
        })}


      <Row className="hidden-print">
        <Col xs={12}>{' '}</Col>
      </Row>

      <Row className='hidden-print'>
        <Col xs={12}>
          <Glyphicon glyph='glyphicon-plus' onClick={() => this.setState({replyOpen: !this.state.replyOpen})}/>
          <Panel collapsible expanded={this.state.replyOpen}>
            <form onSubmit={e => this.submitReplyStickyForm(e)}>
              <FormGroup>
                <FormControl type="text" value={this.props.replyStickyBody} placeholder="Your reply"
                             onChange={(e) => this.updateReplyStickyBody(e.target.value, sticky.id)}/>
              </FormGroup>

              <p>&nbsp;</p>

              <Row>
                <Col xs={6} className='text-left'>
                  <Glyphicon glyph="remove" onClick={(e) => this.updateReplyStickyBody('', sticky.id)}/>
                </Col>

                <Col xs={6} className='text-right'>
                  <Button type="submit" bsStyle="warning" bsSize="xsmall" className="pull-right"
                          onClick={e => this.submitReplyStickyForm(e)}>Reply</Button>
                </Col>
              </Row>
            </form>
          </Panel>
        </Col>
      </Row>
    </Well>
    )
  }

  render() {
    //extra wrapper div that rails app doesn't have
    const {proposalId, stickies, heading} = this.props

    if (proposalId === undefined || proposalId === null){
      return (<div>Waiting for Proposal to load...</div>)
    }

    return (<div>
      <h3>{heading ? heading : 'Communication'}</h3>
      {this.renderNewStickyForm()}
      <p>&nbsp;</p>
      {
        stickies.map(sticky => this.renderThreadedView(sticky) )
      }
    </div>)
  }

static propTypes = {
    proposalId: PropTypes.number.isRequired,
    heading: PropTypes.string
  }

}


//own props = what is passed in from the page that insantiates it i guess. or maybe its props. so confused.
const mapStateToProps = (state, ownProps) => {
  return {
    proposalId: ownProps.proposalId,
    stickies: state.stickies.stickies,
    newStickyBody: state.stickies.newStickyBody,
    replyStickyBody: state.stickies.replyStickyBody,
  }
}

const mapDispatchToProps = (dispatch) => ( {actions: bindActionCreators(Actions, dispatch)} )

export default connect(mapStateToProps, mapDispatchToProps)(Stickies)
