import React from 'react';
import {Col, Modal, Row, Button} from "react-bootstrap";
import './LocationModal.scss'

export const LocationModal = ({show, refreshButton, logoutButton}) => {

    return (
        <Modal
            animation={false}
            show={show}
            onHide={refreshButton}
            className="locationModal"
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>Location sharing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>Location sharing is required for the application to function properly</h2>
                <h3>Go to your browser setting and enable location access for application</h3>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className='locationModal-buttons'>
                        <Button type="button" onClick={logoutButton}
                                data-dismiss="modal" bsStyle="primary">Logout
                        </Button>
                        <Button type="button" onClick={refreshButton}>Refresh</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
