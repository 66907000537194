import React from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import {addAlert} from "../App/actions"
import {fetchSalesArborists} from "../../actions/salesArborists"
import * as MyActions from "./MyAcceptedApi"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import Select from 'react-select'
import {
    Button,
    Col,
    ControlLabel,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    Row,
    Well,
} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import moment from "moment/moment"
import {defaultDateFormat, defaultDateFormatShort, select, saveNewCsvDownload} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";
import './MyAccepted.css'
import DateRangeFilter from "../../components/DateRangeFilter";
import {DateRangePicker} from "react-dates";

const Actions = {...MyActions, addAlert, fetchSalesArborists, saveNewCsvDownload}

class MyAccepted extends ResourceComponent {
    state = {
        resource: {},
        myProposals: [],
        totals: [],
        startDate: null,
        endDate: null,
        focusedInput: null
    }

    tableRef = React.createRef()

    componentDidMount() {
        this.props.actions.getArborists(response => this.setState(response))
    }

    render() {
        const {myProposals, totals, salesArborists} = this.state;
        const {startDate, endDate} = this.state;
        const {proposalProposedStart, proposalProposedEnd, salesArboristId} = this.state.resource;
        return (
            <Grid fluid id="my-accepted">
                <Helmet title="Accepted Proposals"/>
                <form onSubmit={this.fetchMyProposals}>
                    <Row>
                        <Col md={12}>
                            <h3 className="text-primary" style={{marginBottom: "0px", marginTop: "0px"}}>
                                {`Accepted Proposals ${myProposals ? `${myProposals.length} found.` : "Find out how you're doing!"} `}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup bsSize="sm">
                            <ControlLabel>
                                {" "}&nbsp;
                            </ControlLabel>
                            <br/>

                            <Col md={4}>
                                <DateRangePicker
                                    startDate={startDate ? moment.utc(startDate) : ""}
                                    startDateId={'P From'}
                                    endDate={endDate ? moment.utc(endDate) : ""}
                                    endDateId={'P To'}
                                    showClearDates={true}
                                    reopenPickerOnClearDate={true}
                                    onDatesChange={({startDate, endDate}) => {
                                        this.setState({startDate, endDate})
                                        this.state.resource["proposalProposedStart"] = startDate
                                        this.state.resource["proposalProposedEnd"] = endDate
                                        this.setState({resource: this.state.resource});
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => {
                                        this.setState({focusedInput})
                                    }}
                                    startDatePlaceholderText="From"
                                    endDatePlaceholderText="To"
                                    isOutsideRange={() => {
                                        return false
                                    }}
                                    displayFormat={this.props.format || defaultDateFormat}
                                />
                            </Col>
                            <Col md={2}>
                                <Select className="Select" classNamePrefix="select"
                                        name="sales-arborist-select"
                                        value={select(salesArborists, salesArboristId)}
                                        options={salesArborists}
                                        isClearable
                                        onChange={this.selectResourceAttr('salesArboristId')}
                                        placeholder="Sales Arborist"
                                />
                            </Col>
                            <Col md={1}>
                                <Button bsSize="small" type="submit" bsStyle="success">
                                    Find Proposals
                                </Button>
                            </Col>


                            <Col md={1}></Col>
                        </FormGroup>
                        {/*jank way of valigning button to bottom*/}
                        <FormGroup bsSize="sm">
                        </FormGroup>
                    </Row>
                </form>
                <p>&nbsp;</p>
                {myProposals && myProposals.length > 0 ? this._renderTable(myProposals) : null}
                <div className="navbar-fixed-bottom"
                        style={{backgroundColor: 'black', opacity: '.8'}}>
                    <div className="footer-container">
                        <div className="row">
                            {myProposals && myProposals.length > 0 ?
                                <Well style={{
                                    paddingBottom: "0px",
                                    marginBottom: "0px",
                                    paddingTop: "5px",
                                    backgroundColor: 'transparent',
                                    opacity: '.8',
                                    borderColor: 'black'
                                }}>
                                    <Row>
                                        <Col md={2} className="text-left text-primary">
                                            <h4 className="text-danger">
                                                <Row>
                                                    {totals ? "Close Rate " : null}
                                                </Row>
                                                <Row>
                                                    {totals ? "By Number: " : null}
                                                    {totals ?
                                                        Math.floor((totals.totals_closed.reduce((sum, p) => sum + +p.count, 0) / (
                                                            totals.totals_closed.reduce((sum, p) => sum + +p.count, 0) +
                                                            totals.totals_proposed.reduce((sum, p) => sum + +p.count, 0) +
                                                            totals.totals_declined.reduce((sum, p) => sum + +p.count, 0) +
                                                            totals.totals_not_offered.reduce((sum, p) => sum + +p.count, 0)
                                                        )) * 100) + "%"
                                                        : null}
                                                </Row>
                                                {" "}

                                                <Row>
                                                    {totals ? "By Dollars: " : null}
                                                    {totals
                                                        ?
                                                        Math.floor((
                                                            totals.totals_closed.reduce((sum, p) => sum + +p.total, 0) / (
                                                                totals.totals_closed.reduce((sum, p) => sum + +p.total, 0) +
                                                                totals.totals_proposed.reduce((sum, p) => sum + +p.total, 0) +
                                                                totals.totals_declined.reduce((sum, p) => sum + +p.total, 0) +
                                                                totals.totals_not_offered.reduce((sum, p) => sum + +p.total, 0)
                                                            )) * 100) + "%"
                                                        : null}
                                                </Row>
                                            </h4>
                                        </Col>
                                        <Col md={10}>
                                            <Row>

                                                <Col md={3}>
                                                    <h4 className="text-info">
                                                        <Row>
                                                            <Col md={8} className="text-right">
                                                                {totals ? `Proposed: ${totals.totals_proposed.reduce((sum, p) => sum + +p.count, 0)}` : null}
                                                            </Col>
                                                            <Col md={4}>
                                                                {totals
                                                                    ? <Dollars
                                                                        amount={parseFloat(
                                                                            totals.totals_proposed.reduce((sum, p) => sum + +p.total, 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>

                                                        </Row>
                                                        {
                                                            totals.totals_proposed.map(t => {
                                                                return <Row>
                                                                    <Col md={8} className="text-right">{t.name}</Col>
                                                                    <Col md={4}>
                                                                        <Dollars
                                                                            amount={parseFloat(t.total, 10)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            })
                                                        }

                                                    </h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-success">
                                                        <Row>
                                                            <Col md={8} className="text-right">
                                                                {totals ? `Closed: ${totals.totals_closed.reduce((sum, p) => sum + +p.count, 0)}` : null}
                                                            </Col>
                                                            <Col md={4}>
                                                                {totals ?
                                                                    <Dollars
                                                                        amount={parseFloat(totals.totals_closed.reduce((sum, p) => sum + +p.total, 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>

                                                        </Row>
                                                        {
                                                            totals.totals_closed.map(t => {
                                                                return <Row>
                                                                    <Col md={8} className="text-right">{t.name}</Col>
                                                                    <Col md={4}>
                                                                        <Dollars
                                                                            amount={parseFloat(t.total, 10)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            })
                                                        }
                                                    </h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-danger">
                                                        <Row>
                                                            <Col md={8} className="text-right">
                                                                {totals ? `Declined: ${totals.totals_declined.reduce((sum, p) => sum + +p.count, 0)}` : null}
                                                            </Col>
                                                            <Col md={4}>
                                                                {totals ?
                                                                    <Dollars
                                                                        amount={parseFloat(totals.totals_declined.reduce((sum, p) => sum + +p.total, 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>

                                                        </Row>
                                                        {
                                                            totals.totals_declined.map(t => {
                                                                return <Row>
                                                                    <Col md={8} className="text-right">{t.name}</Col>
                                                                    <Col md={4}>
                                                                        <Dollars
                                                                            amount={parseFloat(t.total, 10)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            })
                                                        }
                                                    </h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-white">
                                                        <Row>
                                                            <Col md={8} className="text-right">
                                                                {totals ? `Not Offered: ${totals.totals_not_offered.reduce((sum, p) => sum + +p.count, 0)}` : null}
                                                            </Col>
                                                            <Col md={4}>
                                                                {totals ?
                                                                    <Dollars
                                                                        amount={parseFloat(totals.totals_not_offered.reduce((sum, p) => sum + +p.total, 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>

                                                        </Row>
                                                        {
                                                            totals.totals_not_offered.map(t => {
                                                                return <Row>
                                                                    <Col md={8} className="text-right">{t.name}</Col>
                                                                    <Col md={4}>
                                                                        <Dollars
                                                                            amount={parseFloat(t.total, 10)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            })
                                                        }
                                                    </h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <h6 className="text-center text-danger">
                                                {myProposals ?
                                                    (myProposals.filter((openP) => openP.acceptedDate !== null).reduce((sum, p) => sum + p.age, 0)
                                                        /
                                                        myProposals.reduce((a, b) => b.acceptedDate !== null ? ++a : a, 0)).toFixed(1)
                                                    : null}
                                                {myProposals ?
                                                    " average Days To Close "
                                                    : null}
                                                {myProposals ? `${myProposals.reduce((a, b) => b.acceptedDate !== null ? ++a : a, 0)} proposals`
                                                    : null}
                                            </h6>
                                        </Col>
                                    </Row>
                                </Well>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </Grid>
        )
    }

    fetchMyProposals = e => {
        e.preventDefault()
        const {resource} = this.state;
        this.props.actions.fetchMyProposals(resource, response => {
            this.setState({myProposals: response.data, totals: response.totals})
        })
    }

    _filterRenewalsBy = kind => {
        this.props.actions.filterRenewalsBy(kind)
    }

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _dollarFormatter = cell => <Dollars amount={cell}/>

    _csvDollarFormatter = cell => {
        return parseFloat(cell ? cell : 0).toFixed(2)
    }

    _renderTable = data => {
        const options = {
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            page: 1,  //which page you want to show as default
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '25', value: 25
            }, {
                text: '30', value: 30
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }],
            //you can change the dropdown list for size per page
            sizePerPage: 50,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }
        return (
            <BootstrapTable
                ref={this.tableRef}
                data={data}
                striped={true}
                bordered={false}
                hover={true}
                exportCSV={true}
                pagination={true}
                search={true}
                options={options}
                csvFileName={() => {
                    const fileName = `proposals-${moment().format()}.csv`
                    if (this.tableRef?.current) {
                        this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "my accepted")
                    }
                    return fileName
                }}
            >
                <TableHeaderColumn
                    width={"20%"}
                    dataField="name"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataFormat={this._linkFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer Name
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"20%"}
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataField="siteAddress"
                    dataSort={true}
                >
                    Site Address
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"5%"}
                    dataField="proposalNo"
                    dataFormat={this._proposalLinkFormatter}
                    isKey={true}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Prop #
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"5%"}
                    dataField="proposedDate"
                    dataFormat={this._dateFormatter}
                    csvFormat={this._dateFormatter}
                    dataSort={true}>
                    Date
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"10%"}
                    dataField="arborist"
                    dataSort={true}
                >
                    Arborist
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"5%"}
                    dataField="acceptedDate"
                    dataFormat={this._dateFormatter}
                    csvFormat={this._dateFormatter}
                    dataSort={true}>
                    <span className='text-success'><Glyphicon glyph='ok'/> Date</span>
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"10%"}
                    dataField="proposalTotal"
                    dataFormat={this._dollarFormatter}
                    csvFormat={this._csvDollarFormatter}
                    dataSort={true}>
                    Proposal Total
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="acceptedTotal"
                    dataFormat={this._dollarFormatter}
                    csvFormat={this._csvDollarFormatter}
                    dataSort={true}
                    width={"10%"}>
                    <span className='text-success'><Glyphicon glyph='ok'/> Accepted</span>
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"5%"}
                    dataField="acceptedPHC"
                    dataFormat={this._dollarFormatter}
                    csvFormat={this._csvDollarFormatter}
                    dataSort={true}>
                    <span className='text-success'><Glyphicon glyph='ok'/> PHC</span>
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"5%"}
                    dataField="acceptedTree"
                    dataFormat={this._dollarFormatter}
                    csvFormat={this._csvDollarFormatter}
                    dataSort={true}>
                    <span className='text-success'><Glyphicon glyph='ok'/> Tree</span>
                </TableHeaderColumn>

                <TableHeaderColumn
                    width={"5%"}
                    dataField="age"
                    dataSort={true}>
                    Age
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }

    _optCustomer = e => {
        const customerId = e.target.value
        const optedOut = e.target.checked
        this.props.actions.optCustomer(customerId, optedOut)
    }

    _linkFormatter = (cell, row) => (
        // <Link to={`/customer/work_history/${row.id}`}>
        //   {cell}
        // </Link>
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.name}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.id}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.id}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.id}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.id}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.id}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.id}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposalId}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposalId}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _acceptFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'><Glyphicon glyph='ok'/></span>
        </Link>
    )

    _blankFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'></span>
        </Link>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccepted)
