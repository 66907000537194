import {Button, Col, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {mapForSelect, select} from "../../../common/commonHandlers";
import {Creatable} from "react-select";
import createApiService from "../../../common/clientActionsBuilder";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const api = createApiService('hazards', 'hazard', 'Hazard');

function HazardModal({show, onSave, onHide, ...props}) {

    const [resource, setResource] = useState();
    const [hazards, setHazards] = useState();

    useEffect(() => {
        props.actions.load(null, response => {
            setHazards(response)
        })
    }, [])
    const hazardsOptions = hazards ? mapForSelect(hazards) : [];
    return (
        <Modal
            show={show}
            bsSize="big"
            animation={true}
            dialogClassName='modal-smaller'
            onHide={() => onHide()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add hazard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Creatable options={hazardsOptions}
                                   value={select(hazardsOptions, resource && resource.hazard_id)}
                                   onChange={e => {
                                       const doSave = () => {
                                           let newResource = {hazard_id: e.value, name: e.label}
                                           setResource(newResource);
                                       };

                                       if (isNaN(e.value)) {
                                           props.actions.save({name: e.label}, result => {
                                               e.value = result.id;
                                               doSave();
                                           });
                                       } else {
                                           doSave();
                                       }
                                   }}/>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col md={6} className="text-left">
                        <Button bsSize="small" onClick={() => {
                            onHide();
                            setResource(null);
                        }}>Close</Button>
                    </Col>
                    <Col md={6} className="text-right">
                        <Button
                            bsStyle="success"
                            bsSize="small"
                            onClick={() => {
                                props.actions.save({id: resource.hazard_id, name: resource.name});
                                onSave(resource);
                                setResource(null);
                            }}
                        >Save
                        </Button>
                    </Col>
                </Row>

            </Modal.Footer>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(HazardModal);
