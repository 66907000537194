import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios/index";
import {mapForSelect} from "../../../common/commonHandlers";
import {addAlert} from "../../App/actions";
import {handleUpload} from "../../../common/upload";

export const loadAsset = (siteId, id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/new_assets/${id}`
    };

    apiCall("Loading asset", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const checkUniqueLabel = (siteId, asset, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/new_assets/check_unique_label`,
        params: {id: asset.id, label: asset.label}
    };

    apiCall("Check unique label", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadNextNumber = (siteId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/new_assets/next_number`
    };

    apiCall("Loading asset", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveAsset = (siteId, asset, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/new_assets/${asset.id || ''}`,
        method: asset.id ? 'PUT' : 'POST',
        data: {asset}
    };

    apiCall("Saving asset", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const setCwoAndProposalVisibleHazard = (siteId, assetHazardId, image_id, visible, callback) => (dispatch, getState) => {
    const config = {
        method: "GET",
        url: `/api/new_sites/${siteId}/new_assets/set_cwo_and_proposal_display_hazard?asset_hazard_id=${assetHazardId}&image_id=${image_id}/&visible=${visible}`
    }

    apiCall("Visibility changed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const setCwoAndProposalVisible = (siteId, assetId, image_id, visible, callback) => (dispatch, getState) => {
    const config = {
        method: "GET",
        url: `/api/new_sites/${siteId}/new_assets/set_cwo_and_proposal_display?asset_id=${assetId}&image_id=${image_id}/&visible=${visible}`
    }

    apiCall("Visibility changed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const hideImages = (siteId, assetId, ids, onlyFirstImage, callback) => (dispatch, getState) => {
    const config = {
        method: "GET",
        url: `/api/new_sites/${siteId}/new_assets/hide_images`,
        params: {
            asset_id: assetId,
            ids: ids,
            only_first_image: onlyFirstImage
        }
    }

    apiCall("Visibility changed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const changePolygon = (assetId, newPolygon) => (dispatch, getState) => {
    const config = {
        url: `/api/polygons/change_polygon_type`,
        method: 'PUT',
        data: {
            asset_id: assetId,
            type: newPolygon.type_polygon,
            polygons: newPolygon
        }
    };

    apiCall("Updating polygon type", config, async config => {
        const response = await axios.request(config);
    }, dispatch, getState);

};

export const saveNewPolygon = (assetId, newPolygon) => (dispatch, getState) => {
    const config = {
        url: `/api/polygons/new_polygon_type`,
        method: 'POST',
        data: {
            asset_id: assetId,
            polygons: newPolygon
        }
    };

    apiCall("Saving new polygon", config, async config => {
        const response = await axios.request(config);
    }, dispatch, getState);

};


export const deleteAsset = (siteId, assetId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/new_assets/${assetId}`,
        method: 'DELETE'
    };

    apiCall("Deleting asset", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const load = (assetType, callback) => (dispatch, getState) => {
    let allowAssetType = `allow_${assetType}`
    const config = [
        {
            url: '/api/plants/search',
            params: {
                allow: allowAssetType,
            },
        },
        {url: '/api/recent_selects?name=plant'},
        {url: '/api/hazards'}
    ];

    apiCall("Loading plants", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const allPlants = all[0].data;
        const allowedPlants = allPlants.map(p => ({label: p.name, value: p.id}))
        const recentPlants = all[1].data.filter(e => allowedPlants.some(p => p.value === e.value));
        const restPlants = allowedPlants.filter(e => !recentPlants.some(recent => recent.value === e.value));
        callback({
            plants: [
                {label: 'Recent plants', options: recentPlants},
                {label: 'All plants', options: restPlants},
            ], allPlants: mapForSelect(allPlants),
            hazards: mapForSelect(all[2].data)
        });
    }, dispatch, getState, false);
};

export const uploadAssetAttachment = (id, clientTimezone, timestampType, asHidden, acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload?asset_id=${id}`, acceptedFiles, callback, null, null, false, clientTimezone, timestampType, asHidden, true)(dispatch, getState);
};

export const uploadHazardAttachment = (id, acceptedFiles, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload?asset_object_hazard_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const rotateImage = (id, direction, callback) => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "POST",
        url: `/api/images/rotate?id=${id}`,
        headers: {Authorization: store.auth.token},
        data: {direction: direction}
    }

    return axios.request(config).then(response => {
        callback(response);
        dispatch(
            addAlert({
                message: response.data.message,
                mood: "success",
                dismissAfter: 1500,
            })
        )
    })
}

export const removeImage = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: `/api/images/${id}`
    };
    apiCall("Asset image has been removed", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const fetchConditions = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/conditions`
    };

    apiCall("Fetch conditions", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const fetchFactors = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/plants/${id}`
    };

    apiCall("Fetch plant", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const processVoice = (file, callback) => (dispatch, getState) => {
    const formData = new FormData();
    console.log(file)
    formData.append('audio', file, 'audio.wav');
    const config = {
        method: 'POST',
        url: `/api/ai/voice_process`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    };

    apiCall("Voice process", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const addWatermarkToImage = ({image_id, asset_id, selectedWatermark, timezone}, callback) => (dispatch, getState) => {
    const config = {
        method: "POST",
        url: `/api/images/add_watermark_to_image`,
        params: {id: image_id, asset_id, selected_watermark: selectedWatermark, timezone}
    };
    apiCall("Image watermark created", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};